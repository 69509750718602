import React from "react";
import useInternetConnectivity from "hooks/useInternetConnectivity";

import { Container, Text } from "./style";

const NetworkStatus = ({ text }) => {
  const isOnline = useInternetConnectivity();

  return !isOnline ? (
    <Container>
      <Text>{text}</Text>
    </Container>
  ) : null;
};

NetworkStatus.defaultProps = {
  text: "No Internet !!",
};

export default NetworkStatus;
