import { UPLOAD_IMAGE, DELETE_IMAGE } from "./actiontypes";
import { createAsyncActionCreator } from "helper";
import { uploadImageApi, deleteImageApi } from "./api";

const uploadImageGenerator = function* ({ companyId, formData, isAdmin }) {
  yield {
    action: UPLOAD_IMAGE,
    sideEffect: uploadImageApi({ companyId, formData, isAdmin }),
  };
};

const deleteImageGenerator = function* ({ isAdmin, companyId }) {
  yield {
    action: DELETE_IMAGE,
    sideEffect: deleteImageApi({ isAdmin, companyId }),
  };
};

export const [uploadImage, deleteImage] = createAsyncActionCreator([
  uploadImageGenerator,
  deleteImageGenerator,
]);
