import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";

import Paginator from "common/Components/Paginator";
import useForm from "hooks/useForm";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import usePagination from "hooks/usePagination";
import {
  getAllCollaboratorAttendance,
  getSpecificCollaboratorAttendance,
  resetTableData,
  setSearchData,
} from "../../../../actionCreator";
import { validateEndDate, extractDateInYYYYMMDD } from "helper";

import ActionForm from "./ActionForm";

import {
  StyledPaginatorCont,
  StyledActionArea,
} from "../../../../style/display";
import { bindActionCreators } from "redux";

const ActionArea = ({
  onceFetched,
  companyId,
  isAdmin,
  resetTableData,
  setSearchData,
  collaboratorId,
  prevCollaboratorId,
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: getAllCollaboratorAttendance,
    tableName: "followSubordinate.attendance",
  });

  const tempDataRef = useRef({ isSearchOn: false });

  const validator = useCallback(
    (endDate, formValues) => validateEndDate(formValues.startDate, endDate),
    []
  );

  const { formData, isFormValid, values, resetForm } = useForm({
    fields: {
      startDate: {
        required: false,
      },
      endDate: {
        required: false,
        validator,
        invalidMessage: "End Date should be greater than Start Date.",
      },
      status: {
        required: false,
      },
      workOrigin: {}
    },
  });

  const fetchFirstPage = useCallback(
    (startDate, endDate) => {
      getNextPage({
        isFirstPage: true,
        newActionCreator:
          tempDataRef.current.isSearchOn && getSpecificCollaboratorAttendance,
        extraParams: {
          companyId,
          isAdmin,
          collaboratorId,
          startDate: extractDateInYYYYMMDD(values.startDate),
          endDate: extractDateInYYYYMMDD(values.endDate),
          status: values.status,
          workOrigin: values.workOrigin,
        },
      });
    },
    [
      getNextPage,
      companyId,
      isAdmin,
      values.startDate,
      values.endDate,
      values.status,
      values.workOrigin,
      collaboratorId,
    ]
  );

  const fetchNextPage = useCallback(() => {
    getNextPage({
      newActionCreator:
        tempDataRef.current.isSearchOn && getSpecificCollaboratorAttendance,
      extraParams: {
        companyId,
        isAdmin,
        collaboratorId,
        startDate: extractDateInYYYYMMDD(values.startDate),
        endDate: extractDateInYYYYMMDD(values.endDate),
        status: values.status,
        workOrigin: values.workOrigin,
      },
    });
  }, [
    getNextPage,
    companyId,
    isAdmin,
    collaboratorId,
    values.startDate,
    values.endDate,
    values.status,
    values.workOrigin,
  ]);

  const fetchPrevPage = useCallback(() => {
    getNextPage({
      fetchPrevPage: true,
      newActionCreator:
        tempDataRef.current.isSearchOn && getSpecificCollaboratorAttendance,
      extraParams: {
        companyId,
        isAdmin,
        collaboratorId,
        startDate: extractDateInYYYYMMDD(values.startDate),
        endDate: extractDateInYYYYMMDD(values.endDate),
        status: values.status,
        workOrigin: values.workOrigin,
      },
    });
  }, [
    getNextPage,
    companyId,
    isAdmin,
    collaboratorId,
    values.startDate,
    values.endDate,
    values.status,
    values.workOrigin,
  ]);

  const fetchSpecificPage = useCallback(
    (pageNumber) => {
      getNextPage({
        pageNumber,
        extraParams: {
          companyId,
          isAdmin,
          collaboratorId,
          startDate: extractDateInYYYYMMDD(values.startDate),
          endDate: extractDateInYYYYMMDD(values.endDate),
          status: values.status,
          workOrigin: values.workOrigin,
        },
      });
    },
    [
      getNextPage,
      companyId,
      isAdmin,
      collaboratorId,
      values.startDate,
      values.endDate,
      values.status,
      values.workOrigin,
    ]
  );

  useEffect(() => {
    if (!onceFetched || prevCollaboratorId !== collaboratorId) {
      fetchFirstPage();
    }
  }, []);

  const onSearch = useCallback(
    (e) => {
      e.preventDefault();
      if (!isFormValid()) {
        return;
      }
      tempDataRef.current.isSearchOn = true;
      fetchFirstPage();
      setSearchData({
        startDate: extractDateInYYYYMMDD(values.startDate),
        endDate: extractDateInYYYYMMDD(values.endDate),
        status: values.status,
        workOrigin: values.workOrigin,
      });
    },
    [fetchFirstPage, values.startDate, values.endDate, values.status]
  );

  const onReset = () => {
    resetTableData();
    resetForm();
    tempDataRef.current.isSearchOn = false;
  };

  return (
    <StyledActionArea>
      <ActionForm formData={formData} onSearch={onSearch} resetForm={onReset} />
      <StyledPaginatorCont>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </StyledPaginatorCont>
    </StyledActionArea>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetTableData,
      setSearchData,
    },
    dispatch
  );

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    onceFetched: store.followSubordinate?.attendance?.onceFetched,
    prevCollaboratorId: store.followSubordinate?.attendance?.prevCollaboratorId,
    isAdmin,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionArea);
