import {
  CREATE_REIMBURSEMENT,
  GET_REIMBURSEMENTS,
  SEARCH_REIMBURSEMENT,
  UPDATE_SEARCH_PARAMS,
  RESET_SEARCH_PARAMS,
} from "./actiontypes";

import { createInitialStoreState, createNewStoreOnGetData, createNewStoreOnCreate } from "helper";

const initialState = createInitialStoreState({
  isSearchOn: false
});

export const reimbursementsReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${CREATE_REIMBURSEMENT}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);
    case `${GET_REIMBURSEMENTS}_SUCCESS`:
    case `${SEARCH_REIMBURSEMENT}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${UPDATE_SEARCH_PARAMS}`: {
      const { collaborator_id, expense_type, from_date, to_date, status } = payload;
      if (collaborator_id || expense_type || from_date || to_date || status)
        return {
          ...store,
          onceFetched: false,
          collaboratorId: collaborator_id,
          expenseType: expense_type,
          currentFromDate: from_date,
          currentToDate: to_date,
          currentStatus: status,
          isSearchOn: true
        };
      return store;
    }

    case `${RESET_SEARCH_PARAMS}`: {
      return {
        ...store,
        onceFetched: false,
        collaboratorId: "",
        expenseType: "",
        currentFromDate: "",
        currentToDate: "",
        currentStatus: "",
        isSearchOn: false
      };
    }

    default:
      return store;
  }
};
