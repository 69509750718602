import { combineReducers } from "redux";
import { approvedLeavesReducer } from "./approvedLeaves";
import { cancelledLeavesReducer } from "./cancelledLeaves";
import { notifiedLeavesReducer } from "./notifiedLeaves";
import { pendingLeavesReducer } from "./pendingLeaves";
import { rejectedLeavesReducer } from "./rejectedLeaves";

export const leaveManagementReducer = combineReducers({
  pending: pendingLeavesReducer,
  approved: approvedLeavesReducer,
  cancelled: cancelledLeavesReducer,
  rejected: rejectedLeavesReducer,
  notified: notifiedLeavesReducer
});
