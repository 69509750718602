import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const uploadImageApi = ({ companyId, formData, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/update_collaborator`,
  });

  let collaborator = {
    avatar: formData.avatar[0],
  };

  return requestData(authUrl, {
    method: "PUT",
    body: {
      collaborator,
    },
    isFormData: true,
  });
};

export const deleteImageApi = ({ isAdmin, companyId }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/destroy_avatar`,
  });

  return requestData(authUrl, {
    method: "DELETE",
  });
};
