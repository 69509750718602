import {
  FETCH_LEAVES_TODAY,
  FETCH_PRESENT_TODAY,
  FETCH_ABSENT_TODAY,
  RESET_LEAVES,
  RESET_ABSENTEES,
  RESET_PRESENTEES,
} from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import {
  fetchPeopleOnLeaveTodayApi,
  fetchPeoplePresentTodayApi,
  fetchPeopleAbsentTodayApi,
} from "./api";

const fetchPeopleOnLeavesTodayGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: FETCH_LEAVES_TODAY,
    sideEffect: fetchPeopleOnLeaveTodayApi({ companyId, isAdmin, pageNumber }),
  };
};

const fetchPeoplePresentTodayGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: FETCH_PRESENT_TODAY,
    sideEffect: fetchPeoplePresentTodayApi({
      companyId,
      isAdmin,
      pageNumber,
    }),
  };
};

const fetchPeopleAbsentTodayGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: FETCH_ABSENT_TODAY,
    sideEffect: fetchPeopleAbsentTodayApi({
      companyId,
      isAdmin,
      pageNumber,
    }),
  };
};
export const resetLeaves = () => ({
  type: RESET_LEAVES,
});

export const resetAbsentees = () => ({
  type: RESET_ABSENTEES,
});

export const resetPresentees = () => ({
  type: RESET_PRESENTEES,
});

export const [
  fetchPeopleOnLeavesToday,
  fetchPeoplePresentToday,
  fetchPeopleAbsentToday,
] = createAsyncActionCreator([
  fetchPeopleOnLeavesTodayGenrator,
  fetchPeoplePresentTodayGenrator,
  fetchPeopleAbsentTodayGenrator,
]);
