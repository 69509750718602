import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import Icon from "components/Icon";
import Button from "components/Button";

import EmptyScreen from "assets/employee/SeparationBlank.png";

import { isCurrentUserIsExecutive } from "common/Selectors/auth";

import ActionArea from "./ActionArea";
import TableData from "./TableData";

import Form from "../Create";

import {
  Container,
  FormContainer,
  CloseButton,
  ViewContainer,
  ActionAreaWrapper,
  StyledSkeletonLoader,
  EmptyScreenContainer,
  EmptyScreenImage,
  EmptyScreenMessage
} from "../../style/display";

const Separation = ({ isAdmin }) => {
  const [isFormActive, updateFormStatus] = useState(false);
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isApplyButtonDisabled, updteApplyStatus] = useState(true);
  const [isLoading, updateLoading] = useState(false);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  const toggleForm = useCallback(() => {
    updateFormStatus((val) => !val);
  }, []);

  if (isFormActive) {
    return (
      <Container>
        <FormContainer>
          <Form onFormSubmitCompleted={toggleForm} />
          <CloseButton>
            <Icon name="fas fa-times" onClick={toggleForm} />
          </CloseButton>
        </FormContainer>
      </Container>
    );
  }

  return (
    <ViewContainer>
      <ActionAreaWrapper
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
      >
        <ActionArea
          applyActiveStatus={isApplyButtonDisabled}
          toggleForm={toggleForm}
          onLoading={onDbDataChange}
        />
      </ActionAreaWrapper>
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        updteApplyStatus={updteApplyStatus}
        onEmptyData={onEmptyData}
      />
      {!isLoading && isEmpty && (
        <EmptyScreenContainer>
          <EmptyScreenImage src={EmptyScreen} alt="No records found" />
          <EmptyScreenMessage>
            {isAdmin ?
              "There are no separation applications"
              : "Please help us understand where we went wrong"}
          </EmptyScreenMessage>
          {!isAdmin &&
            <Button primary size="lg" onClick={toggleForm}>
              Apply
            </Button>
          }
        </EmptyScreenContainer>
      )}
      {isLoading && <StyledSkeletonLoader times={4} />}
    </ViewContainer>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { isAdmin };
};

export default connect(mapStateToProps, null)(Separation);

