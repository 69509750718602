export const transformRawDataToListData = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }
  return data.map((item) => {
    return {
      id: item.id,
      image: item?.attributes?.avatarUrl?.thumbUrl || null,
      heading: item?.attributes?.name,
      desc: item?.attributes?.workMail,
    };
  });
};
