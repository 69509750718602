import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import TabsRenderer from "./TabsRenderer";
import DrawerLink from "../DrawerLink";
import { generateDrawerLinks } from "helper";

import Not_Img from "assets/Document.svg";
import E_Not_Img from "assets/employee/Document.svg";

const [HolidayTabLinks, HolidayLinkComponents] = generateDrawerLinks(
  Routes.authorized.holiday,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

export const HolidayTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType(
        "holiday_section"
      )}
    >
      {/* <DrawerTitle title="Holiday Section" /> */}
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("holiday")}
        image={isAdmin ? Not_Img : E_Not_Img}
        title="Holiday"
        links={HolidayTabLinks}
      >
        {HolidayLinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
