import { createSelector } from "reselect";
import { getStore } from "helper";

const storeOnceFetched = (tableName) => (store) => {
  const tableStore = getStore(store, tableName);
  return tableStore.onceFetched;
};

export const getOncefetched = (tableName) =>
  createSelector(storeOnceFetched(tableName), (val) => val);
