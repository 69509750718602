import React, { useMemo } from "react";

import { ProfileSkeleton } from "./style";

function ProfileSkeletonGroup({ count }) {
  const list = useMemo(() => {
    const res = [];
    for (let i = 0; i < count; i++) {
      res.push(<ProfileSkeleton key={i} />);
    }
    return res;
  }, [count]);
  return list;
}
export default ProfileSkeletonGroup;
