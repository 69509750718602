import styled, { css, keyframes } from "styled-components";
import ProfilePic from "../ProfilePic";
import Button from "../Button";
import EmptyData from "../EmptyData";
import SomethingWrong from "../SomethingWrong";
import { FadeInVertical } from "globalStyles";
import { Notification_Types } from "./constants";
const TopFade = FadeInVertical("top");

const profileStyle = css`
  width: 36px;
  height: 36px;
  flex-shrink: 0;
`;

const warm = keyframes`
 0%{
   opacity:1;
 }
 50%{
   opacity:0.5;
 }
 100%{
   opacity:1;
 }
`;

export const Container = styled.div`
  position: fixed;
  width: 409px;
  min-height: 300px;
  max-height: 600px;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0 5px 15px -1px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  z-index: 2;
`;

export const Triangle = styled.div`
  position: fixed;
  content: "";
  background-color: #ffffff;
  transform: rotate(45deg);
  z-index: 1;
  ${(props) => props.$pos};
`;

export const ListItem = styled.div`
  min-height: 88px;
  display: flex;
  padding: 16px 35px;
  border-top: 1px solid #0000001a;
  opacity: 0;
  animation: ${TopFade} ease-out 0.3s forwards;
`;

export const ListFooter = styled.div`
  flex-basis: 30px;
  display: flex;
  align-items: center;
  margin-top: 11px;
  & :nth-child(2n) {
    margin-left: 9px;
  }
`;

export const ListTextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0px 10px 0 10px;
  flex: 1;
`;

export const ImageContainer = styled.div`
  width: 58px;
  height: 58px;
  background-color: #e8e8ff;
  border-radius: 5px;
  overflow: hidden;
`;
export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  border: none;
`;

export const StyledProfilePic = styled(ProfilePic)`
  ${profileStyle}
`;

export const LoaderProfilePic = styled.div`
  ${profileStyle}
  border-radius: 50%;
  background-color: #f3f3f3;
`;

export const LoaderText = styled.div`
  height: 40px;
  background-color: #f3f3f3;
  animation: ${warm} ease-in 1s forwards infinite;
`;

export const Message = styled.span`
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.small};
  margin-bottom: 9px;
  &:hover {
    ${({ $type }) => {
      if (Notification_Types.includes($type)) {
        return `
      cursor:pointer;
      text-decoration: underline;
      `;
      }
    }}
  }
`;
export const Time = styled.span`
  font-size: ${(props) => props.theme.text.para.extraSmall};
  color: #5a5a5a;
`;

export const StyledButton = styled(Button)`
  width: 101.52px;
  height: 24.25px;
  border-radius: 5px;
  font-size: ${(props) => props.theme.text.para.small};
`;

export const NotificationHeader = styled.div`
  height: 60px;
  padding: 0 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  ${({ $showShadow }) => {
    if ($showShadow) {
      return `box-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.2);`;
    }
  }}
`;
export const Heading = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.large};
`;

export const ReadBtn = styled(Button)`
  border: none;
  background-color: transparent;
  box-shadow: none !important;
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: 600;
  color: ${(props) => props.theme.colors.primaryColor};
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledEmptyData = styled(EmptyData)`
  & > img {
    width: 100px;
    height: 100px;
  }
  & > span {
    font-size: ${(props) => props.theme.text.para.small};
  }
  margin-top: 80px;
`;

export const LoaderContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledWrong = styled(SomethingWrong)`
  & > i {
    font-size: 60px;
  }
  & > span {
    font-size: ${(props) => props.theme.text.para.small};
  }
  margin-top: 80px;
`;
