import React from "react";

import { PollCreateModal as PostModal } from "components/Posts";

import useCreatePost from "../../../../hooks/Posts/useCreatePost";

function PollCreateModal({ onClose }) {
  const { isLoading, createPoll } = useCreatePost();

  const onCreatePoll = (values) => {
    createPoll(values, (success) => success && onClose());
  };

  return (
    <PostModal
      onClose={onClose}
      onCreatePoll={onCreatePoll}
      isLoading={isLoading}
    />
  );
}

export default PollCreateModal;
