import requestData from "../../ApiHandler";
import { spreadData, createAuthUrl } from "helper";

export const getAllActiveUsersApi = ({ companyId, isAdmin, pageNumber }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/active_collaborators?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getAllInActiveUsersApi = ({ companyId, isAdmin, pageNumber }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/inactive_collaborators?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const deleteUserApi = ({ companyId, isAdmin, userId }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborators/${userId}`,
  });
  return requestData(url, {
    method: "DELETE",
  });
};

export const createUserApi = ({
  companyId,
  isAdmin,
  userData,
  collaboratorData,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborators`,
  });
  return requestData(url, {
    method: "POST",
    body: {
      user: {
        email: userData.email,
        gender: userData.gender,
        password: userData.password,
        password_confirmation: userData.password,
      },
      collaborator: {
        name: collaboratorData.name,
        phone: collaboratorData.phone,
        role_type: collaboratorData.role,
        employee_id: collaboratorData.employeeId,
        joined_on: collaboratorData.joinedOn,
        avatar: collaboratorData.avatar,
        prior_experience: collaboratorData.priorExperience,
        probation_end_date: collaboratorData.probationEndDate,
        employment_status: collaboratorData.employmentStatus,
        employment_type: collaboratorData.employmentType,
        notice_period: collaboratorData.noticePeriod,
        expected_confirmation_date: collaboratorData.expectedConfirmationDate,
        external_resource: collaboratorData.externalResource,
        // work_mail: collaboratorData.email,
        // status: collaboratorData.status,
        // collaborator_type: collaboratorData.type,
        // left_on: collaboratorData.leftOn,
        // is_executive: collaboratorData.isExecutive,
      },
    },
    isFormData: true,
  });
};

export const updateUserApi = ({
  companyId,
  isAdmin,
  userId,
  userData,
  collaboratorData,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborators/${userId}`,
  });
  return requestData(url, {
    method: "PUT",
    body: {
      collaborator: {
        name: collaboratorData.name,
        phone: collaboratorData.phone,
        ...spreadData(collaboratorData.role, "role_type"),
        ...spreadData(collaboratorData.joinedOn, "joined_on"),
        ...spreadData(collaboratorData.avatar, "avatar"),

        // work_email: collaboratorData.email,
        // ...spreadData(collaboratorData.status, "status"),
        // ...spreadData(collaboratorData.type, "collaborator_type"),
        // ...spreadData(collaboratorData.leftOn, "left_on"),
        // ...spreadData(collaboratorData.isExecutive, "is_executive"),
      },
    },
    isFormData: true,
  });
};

export const updateUserStatusApi = ({ companyId, isAdmin, userId, status }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborators/${userId}`,
  });
  return requestData(url, {
    method: "PUT",
    body: {
      collaborator: {
        status: status ? "active" : "inactive",
      },
    },
    isFormData: true,
  });
};
