import styled from "styled-components";
import ProfilePic from "components/ProfilePic";
import { Card, FadeInVertical, FadeInHorizontal, Bounce } from "globalStyles";

const BottomFade = FadeInVertical("bottom");
const LeftFade = FadeInHorizontal("left");

export const CardContainer = styled(Card)`
  padding: 17px 19px 20px 19px;
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 17px;
  align-items: center;
  justify-content: space-between;

  & .down_arrow {
    border: none;
    cursor: pointer;
    background: transparent;
    .angle_icon {
      width: 10px;
      height: 6px;
      font-size: 15px;
    }
  }

  & .content {
    margin-left: 8px;
    text-align: left;
    & .title {
      font-weight: 600;
      font-size: ${(props) => props.theme.text.para.small};
      color: black;
    }
    & .desc {
      font-weight: 600;
      font-size: ${(props) => props.theme.text.para.extraSmall};
      color: #707070;
    }
  }
`;

export const SubHeaderSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledProfilePic = styled(ProfilePic)`
  width: 32px;
  height: 32px;
`;

export const Moreoption = styled.div`
  position: absolute;
  right: -130px;
  top: 35px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  box-shadow: 0px 10px 40px 0px rgba(227, 227, 227, 0.7);
  & .post_delete_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border: none;
    cursor: pointer;
    background-color: white;
    min-width: 110px;
    max-width: 210px;
    padding: 10px 15px;
    border-radius: 5px;
    height: 40px;
    font-size: 14px;
    &:active {
      transform: scale(1);
    }
  }
`;

export const Description = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: ${(props) => props.theme.text.para.small};
  color: black;
  text-align: left;
  line-height: 23px;
  white-space: pre-wrap;
`;

export const Url = styled.a`
  font-weight: bold;
  margin-right: 5px;
`;

export const HashTag = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.colors.primaryColor};
  margin-right: 5px;
`;

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  algn-items: center;
  & > .text {
    font-weight: 600;
    font-size: ${(props) => props.theme.text.para.extraSmall};
    color: #707070;
    margin-right: 15px;
    cursor: pointer;
    &:hover {
      text-decoration: underline #707070;
    }
  }
`;

export const FooterItem = styled.div`
  display: flex;
  position: relative;
  cursor: ${(props) => (props.$showPointer ? "pointer" : "auto")};
  &:hover {
    text-decoration: ${(props) =>
      props.$showPointer ? "underline #707070" : "none"};
  }
  & > img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  & > .text {
    font-weight: 600;
    font-size: ${(props) => props.theme.text.para.extraSmall};
    color: #707070;
  }
  & + div {
    margin-left: 22px;
  }
`;

export const PollContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 14px 12px;
  border-radius: 6px;
  border: 1px solid ${(props) => (props.$active ? "#0CC340" : "#FF4B4B")};
  margin-top: 8px;
`;

export const PollHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  & > .title {
    font-size: ${(props) => props.theme.text.para.small};
    color: black;
    font-weight: 600;
  }

  & > .desc {
    font-size: ${(props) => props.theme.text.para.extraSmall};
    color: #707070;
    font-weight: 600;
    margin-top: 6px;
  }
  margin-bottom: 10px;
`;

export const PostItem = styled.div`
  width: 80px;
  height: 28px;
  margin: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  flex-grow: 1;
  & img {
    width: 15px;
    height: 12px;
  }

  & .title {
    font-weight: 600;
    font-size: ${(props) => props.theme.text.para.small};
    color: #5a5a5a;
    margin-left: 6px;
  }
  border: none;
`;

export const PollFooter = styled.div`
  display: flex;
  margin-top: 12px;
  & > .foot-item {
    font-weight: 600;
    font-size: ${(props) => props.theme.text.para.extraSmall};
    color: #707070;
  }

  & > .foot-item + .foot-item {
    margin-left: 15px;
  }
`;

export const PollButton = styled.button`
  width: 100%;
  min-height: 30px;
  border: 1px solid ${(props) => props.theme.colors.primaryColor};
  color: ${(props) => props.theme.colors.primaryColor};
  box-sizing: border-box;
  border-radius: 22px;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.small};
  & + button {
    margin-top: 5px;
  }
  background-color: white;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryColor};
    color: white;
  }

  ${({ $isCorrect, $isWrong }) => {
    if ($isCorrect) {
      return `
        color: white;
        background-color: #00C108;
        border: none;
       `;
    }

    if ($isWrong) {
      return `
       color: white;
       background-color: #CB0404;
       border: none;
      `;
    }
  }}
`;

export const PollResultContainer = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 5px;
  position: relative;
`;

export const PollResult = styled.div`
  width: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.small};
  color: #707070;
  position: relative;
  z-index: 1;
  & + .poll-result {
    margin-top: 5px;
  }
`;

export const PollResultBarContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 22px;
  background-color: #ededed;
`;

export const PollResultBar = styled.div`
  height: 10px;
  border-radius: 22px;
  background-color: ${(props) =>
    props.$isSelected ? props.theme.colors.primaryColor : "#BBB"};
`;

export const Tag = styled.span`
  width: 80px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.$color};
  background: ${(props) => props.$color}17;
  border-radius: 4px;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.small};
  line-height: ${(props) => props.theme.text.para.small};
  flex-shrink: 0;
  text-transform: capitalize;
`;

export const CommentListContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 22px;
`;

export const CommentContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  aling-items: strecth;
  animation: ${LeftFade} ease-out 0.3s forwards;
  opacity: 0;
`;
export const CommentPic = styled(ProfilePic)`
  width: 38px;
  height: 38px;
  flex-shrink: 0;
`;
export const CommentContent = styled.div`
  flex-grow: 1;
  margin-left: 5px;
  display: flex;
  flex-flow: column nowrap;
  padding: 0px 9px 12px 16px;
  text-align: left;
  border-bottom: 1px solid #f2f2f2;

  & .heading {
    font-weight: bold;
    font-size: ${(props) => props.theme.text.para.small};
    line-height: 16px;
    color: black;
    display: flex;
  }

  & .designation {
    font-weight: 500;
    font-size: ${(props) => props.theme.text.para.extraSmall};
    line-height: 13px;
    color: #5a5a5a;
    margin-top: 3px;
  }

  & .time {
    font-weight: 500;
    font-size: 10px;
    line-height: 161.2%;
    color: #666666;
    margin-left: auto;
  }
`;

export const CommentDescription = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.text.para.small};
  line-height: 18px;
  color: black;
  margin-top: 11px;
  white-space: pre-wrap;
`;

export const ActionLinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  line-height: 13px;
  color: #5a5a5a;
  margin-top: 5px;
`;

export const ActionLinks = styled.span`
  cursor: pointer;
`;

export const CommentLoadMore = styled.button`
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.small};
  line-height: 11px;
  color: #666666;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ModalContent = styled.div`
  width: min(80%, 520px);
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-flow: column nowrap;
  animation: ${BottomFade} ease-out 0.3s forwards;
`;

export const CommentsModalContent = styled(ModalContent)`
  min-height: 400px;
  max-height: 600px;
  overflow-y: auto;
  position: relative;
`;

export const CommentModalHeader = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: ${(props) => props.theme.text.title.large};
  border-bottom: 1px lightgray solid;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;
export const CommentModalList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 20px;
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: ${(props) => props.theme.colors.mediumGrayColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.primaryColor};
    border-radius: 10px;
  }
`;

export const LoaderArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
`;

export const PostReactionsContainer = styled.div`
  position: absolute;
  padding: 10px 15px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 10px -2px gray;
  display: flex;
  align-items: center;
  top: -56px;
  left: -40px;
  z-index: 2;
  & > button + button {
    margin-left: 10px;
  }
`;

export const PostReactionContainer = styled.button`
  background-color: transparent;
  border: none;
  & > img {
    width: 24px;
    height: 25px;
  }
  cursor: pointer;
  animation: ${Bounce} ease-out 0.3s backwards;
  transition: transform ease-out 0.2s;

  &:hover {
    transform: scale(1.3) translateY(-2px);
  }
`;

export const ReactionImage = styled.img`
  cursor: pointer;
`;
