import React, { useMemo } from "react";
import Icon from "../../Icon";

import { MonthIndex } from "constants";

import {
  ActionRowContainer,
  ActionButton,
  PageBox,
  PageActionBtn,
} from "./style";

const ActionRow = ({
  isMonth = true,
  currentYear,
  currentMonth,
  pageStartYear,
  pageEndYear,
  onNextYear,
  onNextMonth,
  onPrevMonth,
  onPrevYear,
  toggleView,
}) => {
  const btnText = useMemo(() => {
    if (isMonth) {
      return `${MonthIndex[currentMonth]} ${currentYear}`;
    } else {
      return `${pageStartYear}-${pageEndYear}`;
    }
  }, [currentMonth, currentYear, isMonth, pageEndYear, pageStartYear]);

  return (
    <ActionRowContainer>
      <ActionButton type="button" onClick={toggleView}>
        {btnText}
      </ActionButton>

      <PageBox>
        <PageActionBtn
          type="button"
          onClick={isMonth ? onPrevMonth : onPrevYear}
        >
          <Icon name="fas fa-chevron-left" />
        </PageActionBtn>

        <PageActionBtn
          type="button"
          onClick={isMonth ? onNextMonth : onNextYear}
        >
          <Icon name="fas fa-chevron-right" />
        </PageActionBtn>
      </PageBox>
    </ActionRowContainer>
  );
};

export default ActionRow;
