import React, { useMemo } from "react";
import { validateUrl, validateHashTag } from "helper";
import { Description, Url, HashTag } from "./style";

export function PostDescription({ description, ...rest }) {
  const modifiedDesc = useMemo(() => {
    const line = description.split("\n");
    const desc = [];
    line.forEach((value) => {
      const words = value.split(" ");
      words.forEach((word, idx) => {
        const isUrl = validateUrl(word);
        const isHash = validateHashTag(word);
        const wordEndingWithNewLine = word.charAt(word.length - 1) == "\r";
        if (isHash) {
          desc.push(<HashTag key={idx}>{word}</HashTag>);
        } else if (isUrl) {
          desc.push(
            <Url key={idx} target="_blank" rel="noopener noreferer" href={word}>
              {word}
            </Url>,
          );
        } else {
          desc.push(word + " ");
        }
        if (wordEndingWithNewLine) {
          desc.push("\n");
        }
      });
    });
    return desc;
  }, [description]);

  return <Description {...rest}>{modifiedDesc}</Description>;
}
