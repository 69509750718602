import React, { useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";

import { RectangleSkeleton } from "components/Skeleton";

import ActionArea from "./ActionArea";
import TableData from "./TableData";
import Tabs from "components/Tabs";
import PageSlider from "components/PageSlider";

import EmptyScreen from "assets/employee/LeadBlank.png";

import InsertInDom from "components/InsertInDom";
import Divider from "components/Divider";
import PageBackHeader from "components/PageBackHeader";

import { isCurrentUserIsExecutive } from "common/Selectors/auth";
import { TabContainer } from "../../style/display";

import { ViewContainer } from "globalStyles";
import {
  EmptyScreenContainer,
  EmptyScreenImage,
  EmptyScreenMessage,
} from "../../style/display";
import AttendanceView from "./Tabs/Attendance";
import BreaksDisplayView from "../../../Breaks/Views/Display";
import HelpdeskDisplayView from "../../../Helpdesk/Views/Display";
import { updateCollaboratorId } from "../../actionCreator";
import { bindActionCreators } from "redux";

const FollowSubbordinateDisplayView = ({
  isAdmin,
  collaboratorId,
  updateCollaboratorId,
}) => {
  const [sliderActive, updateSliderStatus] = useState(false);
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);
  const [collaboratorName, updateCollaboratorName] = useState("");

  const toggleSlider = () => {
    updateSliderStatus((val) => !val);
  };

  const onSliderClose = useCallback(() => {
    toggleSlider();
  }, []);

  const onCellClick = (id, name) => {
    if (id || name) {
      updateCollaboratorId(id);
      updateCollaboratorName(name);
      toggleSlider();
    }
  };

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  const CurrentSliderTab = useCallback(
    (index) => {
      if (index == 1) return <AttendanceView collaboratorId={collaboratorId} />;
      if (index == 2) return <BreaksDisplayView />;
      if (index == 3) return <HelpdeskDisplayView subordinateHelpdesk={true} />;
      else return null;
    },
    [collaboratorId]
  );

  const CurrentTab = useMemo(() => {
    return (
      <ViewContainer>
        <ActionArea
          onLoading={onDbDataChange}
          style={{
            display: isLoading || isEmpty ? "none" : "inherit",
          }}
        />
        <TableData
          style={{
            display: isLoading || isEmpty ? "none" : "inherit",
          }}
          onCellClick={onCellClick}
          onEmptyData={onEmptyData}
        />
        {!isLoading && isEmpty && (
          <EmptyScreenContainer>
            <EmptyScreenImage src={EmptyScreen} alt="No records found" />
            <EmptyScreenMessage>
              {isAdmin
                ? "No subordinate assigned to any one yet"
                : "No subordinate assigned to you yet"}
            </EmptyScreenMessage>
          </EmptyScreenContainer>
        )}
        {isLoading && <RectangleSkeleton times={4} />}
      </ViewContainer>
    );
  }, [isLoading, isEmpty, onDbDataChange]);

  return (
    <>
      {CurrentTab}
      <PageSlider showBackButton={false} active={sliderActive}>
        <PageBackHeader onBack={onSliderClose} title={collaboratorName} />
        <Divider style={{ marginBottom: "6px" }} />

        <TabContainer>
          <Tabs tabNames={["Attendance", "Breaks", "Helpdesk"]}>
            {CurrentSliderTab}
          </Tabs>
        </TabContainer>
      </PageSlider>
    </>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const collaboratorId = store.followSubordinate?.attendance?.collaboratorId;

  return { isAdmin, collaboratorId };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCollaboratorId,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowSubbordinateDisplayView);
