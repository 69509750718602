import requestData from "../../ApiHandler";

export const loginApi = ({ emailOrMobile, password, isAdmin }) => {
  const body = {
    login: {
      email: emailOrMobile,
      password,
    },
  };

  const url = `/${isAdmin ? "executive" : "employee"}/sessions`;
  return requestData(url, {
    method: "POST",
    body,
    isFormData: true,
  });
};
