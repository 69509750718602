import { CREATE_EMERGENCY_CONTACT, GET_EMERGENCY_CONTACTS, GET_EMERGENCY_CONTACTS_BY_COLLABORATOR } from "./actiontypes";
import { RESET_USER_ID } from "../User/actiontypes";

import { createInitialStoreState, createNewStoreOnGetData, createNewStoreOnCreate } from "helper";

const initialState = createInitialStoreState();

export const emergencyContactReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${CREATE_EMERGENCY_CONTACT}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);
    case `${GET_EMERGENCY_CONTACTS}_SUCCESS`:
    case `${GET_EMERGENCY_CONTACTS_BY_COLLABORATOR}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case RESET_USER_ID: {
      return {
        ...store,
        onceFetched: false,
      };
    }

    default:
      return store;
  }
};
