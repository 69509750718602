import React, { useEffect, useMemo, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  SingleChoiceForm,
  FeedbackForm,
  RatingForm,
  MultipleChoiceForm,
} from "components/Survey";

import Description from "./Description";
import LoadingView from "./Loading";
import EndView from "./EndView";

import useSurvey from "../../hooks/useSurvey";
import useGetQuery from "hooks/useGetQuery";

import { Container } from "./style";

function SurveyModal() {
  const [formValues, updateFormValues] = useState({});
  const [formIndex, updateFormIndex] = useState(-1);
  const [isFormActive, updateFormStatus] = useState(false);
  const [isEnd, updateEndStatus] = useState(false);

  const history = useHistory();
  const isFirstTimeRef = useRef(true);

  const [surveyId] = useGetQuery(["survey_id"]);
  const { fetchSurvey, surveyData, submitSurveyData, isLoading } = useSurvey();

  useEffect(() => {
    fetchSurvey(surveyId);
  }, [surveyId]);

  useEffect(() => {
    if (surveyData && isFirstTimeRef.current) {
      updateFormIndex(0);
      isFirstTimeRef.current = false;
    }
  }, [surveyData]);

  const toggleFormStatus = () => {
    updateFormStatus((val) => !val);
  };
  const getCurrentFormIndex = (formId) => {
    return surveyData.surveys.findIndex((item) => item.id === formId);
  };

  const goToPreviousPage = () => {
    history.goBack();
  };

  const onSubmit = async (formId, values, formType) => {
    if (!surveyData) {
      return;
    }

    //  converting multiple choice question answere to a single string
    if (formType === "mcq") {
      values.answere = values.answere
        ? Object.keys(values.answere).join(",")
        : "";
    }

    // storing the current form values
    updateFormValues((prev) => ({
      ...prev,
      [formId]: { ...values },
    }));

    const isSuccess = await submitSurveyData(
      {
        answer: values.answere,
        questionId: formId,
      },
      surveyId
    );

    if (!isSuccess) {
      return;
    }
    const formIdIndex = getCurrentFormIndex(formId);

    // checking if this form the last form in row
    if (formIdIndex === surveyData.surveys.length - 1) {
      updateEndStatus(true);
    } else {
      updateFormIndex(formIdIndex + 1);
    }
  };

  const onPrevForm = (formId) => {
    const formIdIndex = getCurrentFormIndex(formId);
    if (formIndex > 0) {
      updateFormIndex(formIdIndex - 1);
    }
  };

  const currentForm = useMemo(() => {
    return surveyData && formIndex > -1 ? surveyData.surveys[formIndex] : null;
  }, [formIndex, surveyData]);

  //  setting initial value of form if present
  useEffect(() => {
    if (currentForm && currentForm.currentCollaboratorResponse) {
      const { surveyQuestionId, answer } =
        currentForm.currentCollaboratorResponse;
      updateFormValues((prev) => ({
        ...prev,
        [surveyQuestionId]: { answere: answer },
      }));
    }
  }, [currentForm]);

  const modifiedOptions = useMemo(() => {
    if (
      currentForm &&
      (currentForm.questionType === "mcq" || currentForm.questionType === "scq")
    ) {
      return currentForm.activeSurveyQuestionOptions.map((item) => ({
        name: item.option,
        value: item.id,
      }));
    }
    return [];
  }, [currentForm]);

  const transformStringToObjectKeys = (val) => {
    const obj = {};
    val.split(",").forEach((id) => {
      obj[id] = true;
    });
    return obj;
  };

  const initialValue = useMemo(() => {
    if (currentForm && formValues[currentForm.id]) {
      const form = formValues[currentForm.id];
      // transforming the mcq single string data to actual usable data for the component
      if (currentForm.questionType === "mcq") {
        return typeof form.answere === "string"
          ? transformStringToObjectKeys(form.answere)
          : "";
      }
      return form.answere;
    }
    return "";
  }, [currentForm, formIndex, formValues]);

  if (!currentForm || isLoading === "LOADING_SURVEY") {
    return (
      <Container>
        <LoadingView />
      </Container>
    );
  }

  if (!isFormActive) {
    return (
      <Container>
        <Description
          title={surveyData.name}
          desc={surveyData.description}
          onStart={toggleFormStatus}
          onClose={goToPreviousPage}
        />
      </Container>
    );
  }

  if (isEnd) {
    return (
      <Container>
        <EndView onClose={goToPreviousPage} />
      </Container>
    );
  }

  return (
    <Container>
      {currentForm.questionType === "scq" && (
        <SingleChoiceForm
          onSubmit={onSubmit}
          onPrevious={onPrevForm}
          questionId={currentForm.id}
          question={currentForm.question}
          options={modifiedOptions}
          groupName={currentForm.id}
          showPrevBtn={formIndex > 0}
          initialValue={initialValue}
          isLoading={isLoading === "SUBMITTING_RESPONSE"}
          onClose={goToPreviousPage}
          required
        />
      )}

      {currentForm.questionType === "mcq" && (
        <MultipleChoiceForm
          onSubmit={onSubmit}
          onPrevious={onPrevForm}
          questionId={currentForm.id}
          question={currentForm.question}
          options={modifiedOptions}
          groupName={currentForm.id}
          showPrevBtn={formIndex > 0}
          initialValue={initialValue}
          isLoading={isLoading === "SUBMITTING_RESPONSE"}
          onClose={goToPreviousPage}
          required
        />
      )}

      {currentForm.questionType === "theory" && (
        <FeedbackForm
          onSubmit={onSubmit}
          onPrevious={onPrevForm}
          questionId={currentForm.id}
          question={currentForm.question}
          showPrevBtn={formIndex > 0}
          initialValue={initialValue}
          isLoading={isLoading === "SUBMITTING_RESPONSE"}
          onClose={goToPreviousPage}
          required
        />
      )}

      {currentForm.questionType === "rating" && (
        <RatingForm
          onSubmit={onSubmit}
          onPrevious={onPrevForm}
          questionId={currentForm.id}
          question={currentForm.question}
          showPrevBtn={formIndex > 0}
          initialValue={initialValue}
          isLoading={isLoading === "SUBMITTING_RESPONSE"}
          onClose={goToPreviousPage}
          required
        />
      )}
    </Container>
  );
}

export default SurveyModal;
