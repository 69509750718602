import styled from "styled-components";
import Button from "../Button";

export const Container = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PagesBox = styled.div`
  width: auto;
  max-width: 180px;
  margin: 0 5px;
  height: 50px;
  display: flex;
  overflow: auto;
  align-items: center;
  transition: transform ease 0.3s;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const ActionButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 32px;
  border-radius: 4.8px;
  border: solid 1px ${(props) => props.theme.colors.paginator.cta};
  color: ${(props) => props.theme.colors.paginator.color};
`;

export const PageButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 25px;
  margin: 0 5px;
  flex-shrink: 0;
  font-size: ${(props) => props.theme.text.para.medium};
  transition: transform ease 0.3s;
  background-color: white;
  ${({ active, theme }) => {
    if (active) {
      return `
    background-color:${theme.colors.paginator.page};
    color: white;
    border-radius: 5px;
    // box-shadow:${theme.shadow.small};
    `;
    }
  }}
`;
