import React from "react";
import { ViewContainer } from "../../../../GlobalStyles";
import ActionArea from "./ActionArea";
import TableData from "./TableData";

const DepartmentView = () => {
  return (
    <ViewContainer>
      <ActionArea />
      <TableData />
    </ViewContainer>
  );
};

export default DepartmentView;
