import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCurrentUserCompany, isCurrentUserIsExecutive } from "common/Selectors/auth";
import { getUserListData } from "common/Api";

import InputGroup from "components/InputGroup";
import Button from "components/Button";
import Icon from "components/Icon";
import AsyncSearchSelect from "components/Input/AsyncSearchSelect";
import { ListItem } from "components/List";

import useAlert from "hooks/useAlert";

import { publishOptions, emailOptions } from "./options";

import { StyledFormGroup } from "../../../../GlobalStyles";
import {
  Header,
  FormHeading,
  EmailCheckBox,
  FormLink,
  MemberListRadio,
} from "../../styles/create";

import { publishNotice } from "../../actionCreator";
import { generatePublishList } from "../../utils";
import { getCurrentNoticeId } from "../../selector";

const Publish = ({
  updateIsFormSubmitted,
  updateFormVisibility,
  companyId,
  isAdmin,
  noticeId,
  publishNotice,
  creation,
}) => {
  const [isLoading, updateLoading] = useState(false);
  const [currentPostStatus, updatePostStatus] = useState("");
  const [selectedUsers, updateSelectedUsers] = useState({});
  const { showAlert } = useAlert();

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const data = generatePublishList(values);
        const selectedUsersList = Object.keys(selectedUsers);
        await publishNotice({
          companyId,
          isAdmin,
          noticeId,
          formData: data,
          selectedUsersList,
        });
        showAlert({ message: "Notice Created" });
        resetForm();
        updateIsFormSubmitted(false);
      } catch (err) {
        showAlert({ message: "Notice Publish Failed", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [companyId, noticeId, selectedUsers]
  );

  const onUserSelected = useCallback((e) => {
    const { value } = e.target;
    updateSelectedUsers(value);
  }, []);

  const onPostStatusChanged = useCallback((e) => {
    updatePostStatus(e.target.value);
  }, []);

  const onDeselectUsers = useCallback(
    (itemId, item) => {
      const newListData = { ...selectedUsers };
      if (newListData[itemId]) {
        delete newListData[itemId];
      }
      updateSelectedUsers(newListData);
    },
    [selectedUsers]
  );

  const selectedUsersList = useMemo(() => {
    return Object.values(selectedUsers);
  }, [selectedUsers]);

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Publish</FormHeading>
        {!isAdmin && creation &&
          <Icon name="fas fa-times" onClick={updateFormVisibility} />
        }
      </Header>
      <MemberListRadio>
        <InputGroup
          id="noticePublish_noticeSendTo"
          fieldType="radioGroup"
          horizontal={false}
          options={publishOptions}
          label="This post is for..."
          groupName="noticePublish_noticeSendTo"
          required
          onChange={onPostStatusChanged}
          secondaryLabel
        />
      </MemberListRadio>

      {currentPostStatus === "few" && (
        <AsyncSearchSelect
          value={selectedUsers}
          onChange={onUserSelected}
          placeholder="Search for users"
          api={getUserListData}
        />
      )}

      {selectedUsersList.map((item) => {
        return (
          <ListItem
            key={item.id}
            data={item}
            onSelect={onDeselectUsers}
            selected={selectedUsers && selectedUsers[item.id]}
            showDeselectButton={true}
          />
        );
      })}

      <EmailCheckBox>
        <InputGroup
          id="noticePublish_noticeEmail"
          fieldType="checkboxGroup"
          label="Send Email"
          secondaryLabel
          options={emailOptions}
          initialValue={{ emailStatus: true }}
          groupName="noticePublish_noticeEmail"
        />
      </EmailCheckBox>

      {/* <FormLink>Schedule for later...</FormLink> */}

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Send
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const noticeId = getCurrentNoticeId(store, props);
  return { companyId, isAdmin, noticeId };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ publishNotice }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Publish);
