import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const createEnquiryApi = ({ companyId, isAdmin, formData }) => {
  let enquiry = {
    enquiry_type: formData.enquiry_type,
    subject: formData.subject,
    query: formData.query,
    cc_ids: formData.cc_ids
  }

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/enquiries`
  });

  return requestData(url, {
    method: "POST",
    body: {
      enquiry
    },
    isFormData: true,
  });
};

export const getEnquiriesApi = ({ companyId, pageNumber, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/enquiries?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const searchEnquiriesApi = ({ companyId, pageNumber, collaborator, currentFromDate, currentToDate, enquiryType, currentStatus, isAdmin }) => {

  const extraParam = isAdmin && collaborator ? `&collaborator_id=${Object.keys(collaborator)[0] || ''}` : '';
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/search_enquiry?page=${pageNumber}&from_date=${currentFromDate}&to_date=${currentToDate}&enquiry_type=${enquiryType}&status=${currentStatus}${extraParam}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getSubordinateEnquiriesApi = ({ companyId, pageNumber, isAdmin, subordinateId }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/fetch_enquiry_by_collaborator?page=${pageNumber}&collaborator_id=${subordinateId}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
