import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useNodeDimensions from "hooks/useNodeDimensions";
import GenericModal from "components/GenericModal";
import InputGroup from "components/InputGroup/inputGroup2";

import {
  Container,
  Triangle,
} from "./style";
import { getSpacedString } from "../../../../../Helper";

const TRIANGLE_WIDTH = 20;
const TRIANGLE_HEIGHT = 20;

const DateInput = ({
  elemIdOrElem,
  onClose,
  formData,
}) => {
  const [isActive, updateActiveStatus] = useState(false);
  const [myPos, updateMyPos] = useState({ top: 0, left: 0 });
  const targetElemDimensions = useNodeDimensions({ elemIdOrElem });
  const containerRef = useRef(null);

  /**
   * sets the postion of dateInput panel according to the target element position
   */
  const calculatePosition = useCallback(() => {
    if (targetElemDimensions && containerRef.current) {
      const { top, left, height, right } = targetElemDimensions;
      const source = containerRef.current.getBoundingClientRect();
      const startLeft = left;
      const pos = {};
      const windowDim = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      if (startLeft + source.width > windowDim.width) {
        pos.right = windowDim.width - right;

        if (pos.right + source.width > windowDim.width) {
          pos.right = 0;
        }
      } else {
        pos.left = startLeft;
      }
      updateMyPos({ top: top + 10 + height, ...pos, maxWidth: windowDim.width });
    }
  }, [targetElemDimensions]);

  const setRef = useCallback(
    (ref) => {
      if (!ref) {
        return;
      }
      containerRef.current = ref;
      calculatePosition();
    },
    [calculatePosition]
  );

  const toggleDateInput = useCallback((status) => {
    updateActiveStatus((val) => {
      if (typeof status === "boolean") {
        return status;
      }
      return !val;
    });
  }, []);

  useEffect(() => {
    if (!targetElemDimensions) {
      toggleDateInput(false);
    } else {
      toggleDateInput(true);
    }
  }, [targetElemDimensions]);

  const trianglePos = useMemo(() => {
    if (!targetElemDimensions) {
      return {};
    }
    const { left, width, top, height } = targetElemDimensions;
    return {
      left: `${left + width / 2 - TRIANGLE_WIDTH / 2}px`,
      top: `${top + 10 + height - TRIANGLE_HEIGHT / 2}px`,
      width: `${TRIANGLE_WIDTH}px`,
      height: `${TRIANGLE_HEIGHT}px`,
    };
  }, [targetElemDimensions]);

  return isActive ? (
    <GenericModal onClose={onClose}>
      <Container style={myPos} ref={setRef}>
        {Object.keys(formData).map((field) =>
          <InputGroup
            fieldType="datePicker"
            placeholder={`Enter ${getSpacedString(field).toLowerCase()}`}
            {...formData[field]}
          />)}
      </Container>
      <Triangle $pos={trianglePos} />
    </GenericModal>
  ) : null;
};

DateInput.defaultProps = {
  onClose: () => { }
};
export default DateInput;
