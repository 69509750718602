import React, { useMemo, useCallback } from "react";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import { getStatusType } from "helper";

import { Cell, CellIcon } from "../../style/display";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  clearLedgerTransactions,
  setCurrentLedgerId,
} from "../../actionCreator";
import { createTableDataSelector } from "common/Selectors/MTable";
import MultipleUsers from "../../../../Common/Components/MultipleUsers";

const RenderItem = ({
  data,
  columnIndex,
  rowIndex,
  style,
  setCurrentLedgerId,
  clearLedgerTransactions,
  rowIds,
}) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
    onIdClick,
  } = data;

  const cellvalue = rowData[rowIndex][columnIndex];

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["categoryType"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const isFirstColumn = useMemo(() => {
    return columnIndex === 0;
  }, [columnIndex]);

  const isActiveLedgerGroupMembers = useMemo(() => {
    return (
      rowIndex > 0 &&
      cellNameToColumnMap["activeLedgerGroupMembers"] === columnIndex
    );
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const onFirstColumnClick = useCallback(() => {
    if (typeof onIdClick === "function") {
      onIdClick({ rowIndex });
      setCurrentLedgerId({ ledgerId: rowIds[rowIndex] });
      clearLedgerTransactions();
    }
  }, [rowIndex, rowIds]);

  const myChildren = useMemo(() => {
    if (isFirstColumn) {
      return (
        <Cell onClick={onFirstColumnClick} style={{ cursor: "pointer" }}>
          {rowData[rowIndex][0]}
          <CellIcon name="fas fa-arrow-right" />
        </Cell>
      );
    }
    if (isActiveLedgerGroupMembers && cellvalue != "NA") {
      return (
        <Cell onClick={(e) => e.stopPropagation()}>
          <MultipleUsers data={cellvalue} />
        </Cell>
      );
    }
    if (isStatusColumn) {
      return (
        <Cell>
          <Status text={cellvalue} type={getStatusType(cellvalue)} />
        </Cell>
      );
    }
    return null;
  }, [isStatusColumn, cellvalue]);

  return (
    <GridCell
      row={rowIndex}
      showHeading={showHeading}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setCurrentLedgerId, clearLedgerTransactions },
    dispatch
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "ledger.categories",
  });
  const maker = (store, props) => {
    const { rowIdMap } = getUserRows(store, props);
    return {
      rowIds: rowIdMap,
    };
  };
  return maker;
};
export default connect(mapStateToProps, mapDispatchToProps)(RenderItem);
