import {
  FETCH_NEW_JOINEES_TODAY,
  FETCH_UPCOMINGS_JOINEES,
} from "./actionTypes";
import { createNewStoreOnGetData, createInitialStoreState } from "helper";
import { combineReducers } from "redux";

const todayInitialState = createInitialStoreState({}, true);

function todayNewJoineesReducer(store = todayInitialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_NEW_JOINEES_TODAY}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    default:
      return store;
  }
}

const nextInitialState = createInitialStoreState({}, true);
function upcomingNewJoineesReducer(store = nextInitialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_UPCOMINGS_JOINEES}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    default:
      return store;
  }
}

const NewJoineesReducer = combineReducers({
  today: todayNewJoineesReducer,
  upcoming: upcomingNewJoineesReducer,
});

export default NewJoineesReducer;
