import React from "react";
import { CommentsListModal } from "components/Posts";

import useFetchComments from "../../hooks/Posts/useFetchComments";

function CommentsModal({ postId, onClose }) {
  const {
    isLoading,
    comments,
    allCommentsLoaded,
    fetchNextPage: fetchNextComments,
  } = useFetchComments({ postId });

  return (
    <CommentsListModal
      onClose={onClose}
      onEndReached={fetchNextComments}
      allCommnetsLoaded={allCommentsLoaded}
      comments={comments}
      isLoading={isLoading}
    />
  );
}

export default CommentsModal;
