import {
  CREATE_COMPANY_BRANCH,
  GET_COMPANY_BRANCH,
  EDIT_COMPANY_BRANCH,
} from "./actiontypes";
import {
  createCompanyBranchApi,
  getCompanyBranchApi,
  editCompanyBranchApi,
} from "./api";
import { createAsyncActionCreator } from "../../Helper";

const getCompanyBranchGenerator = function* ({
  isAdmin,
  companyId,
  pageNumber,
}) {
  yield {
    action: GET_COMPANY_BRANCH,
    sideEffect: getCompanyBranchApi({
      isAdmin,
      companyId,
      pageNumber,
    }),
  };
};

const createCompanyBranchGenerator = function* ({
  isAdmin,
  companyId,
  formData,
}) {
  yield {
    action: CREATE_COMPANY_BRANCH,
    sideEffect: createCompanyBranchApi({ isAdmin, companyId, formData }),
  };
};

const editCompanyBranchGenerator = function* ({
  branchId,
  companyId,
  isAdmin,
  formData,
}) {
  yield {
    action: EDIT_COMPANY_BRANCH,
    sideEffect: editCompanyBranchApi({
      branchId,
      companyId,
      isAdmin,
      formData,
    }),
  };
};
export const [createCompanyBranch, getCompanyBranch, editCompanyBranch] =
  createAsyncActionCreator([
    createCompanyBranchGenerator,
    getCompanyBranchGenerator,
    editCompanyBranchGenerator,
  ]);
