import requestData from "../../ApiHandler";
import { createAuthUrl } from "../../Helper";

export const createTeamApi = ({ companyId, isAdmin, formData }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/teams`,
  });
  return requestData(url, {
    method: "POST",
    body: {
      team: formData,
    },
    isFormData: true,
  });
};

export const updateTeamApi = ({ companyId, isAdmin, formData, teamId }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/teams/${teamId}`,
  });
  return requestData(url, {
    method: "PUT",
    body: {
      team: formData,
    },
    isFormData: true,
  });
};

export const createTeamMemberApi = ({
  companyId,
  isAdmin,
  teamId,
  formData,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/teams/${teamId}/team_collaborators`,
  });

  return requestData(url, {
    method: "POST",
    body: {
      team_collaborator: formData,
    },
    isFormData: true,
  });
};

export const getTeamsApi = ({ companyId, isAdmin, pageNumber }) => {
  const extension = isAdmin ? "teams" : "get_teams";
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/${extension}?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getTeamMembersApi = ({
  companyId,
  isAdmin,
  pageNumber,
  teamId,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/teams/${teamId}/team_collaborators?member_type[]=normal&member_type[]=owner&page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
