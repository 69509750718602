import firebase from "@firebase/app";
import "@firebase/messaging";
import { IS_DEV } from "../config.local";
import { displayNotification, isNotificationSupported } from "../Helper";

export const authorize = async () => {
  try {
    var firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY,
      projectId: process.env.REACT_APP_PROJECT_ID,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_ID,
    };
    // Initialize Firebase
    if (!firebase.apps.length) {
      return firebase.initializeApp(firebaseConfig);
    } else {
      return firebase.app();
    }
  } catch (error) {
    console.error("Error in initializing firebase app", error);
  }
};

const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    return permission;
  } catch (error) {
    console.error("Error in granting notification permission", error);
    return null;
  }
};

const listenToNotifications = () => {
  try {
    if (!isNotificationSupported()) {
      alert("This browser does not support desktop notification");
      return;
    }

    return firebase.messaging().onMessage((payload) => {
      const { title, body, image } = payload.notification;
      const notificationTitle = title;
      const notificationOptions = {
        body,
        icon: "/alienbrains-logo.webp",
        image,
      };
      displayNotification(notificationTitle, notificationOptions);
    });
  } catch (error) {
    console.error("Error in listening to notifications", error);
  }
};

export const getToken = async () => {
  try {
    if ((await requestNotificationPermission()) !== "granted") {
      return null;
    }

    const token = await firebase.messaging().getToken({
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });

    if (IS_DEV) {
      console.log(token);
    }

    if (token) {
      return token;
    }
    return null;
  } catch (error) {
    console.error("Error in fetching token", error);
    return null;
  }
};

export const deleteToken = async () => {
  try {
    await firebase.messaging()?.deleteToken();
  } catch (error) {
    console.error("Error in deleting token", error);
  }
  return null;
};

export default listenToNotifications;
