import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Grid from "components/Grid";
import RenderItem from "./RenderItem";

import { createTableDataSelector } from "common/Selectors/MTable";

import { TableContainer } from "../../../../GlobalStyles";
import { calculateMaxLength } from "../../../../Helper";

import { getCollaboratorDetails } from "../../utils";

const TableData = ({
  data,
  onCellClick,
  onEmptyData,
  relations,
  cellNameToColumnMap,
  ...other
}) => {
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);

  const maxStatusLength = useMemo(() => {
    return calculateMaxLength(cellNameToColumnMap, data);
  }, [data]);

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (rows === 1) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);

  const onClick = ({ data, columns }) => {
    const collaboratorData = getCollaboratorDetails(
      data,
      cellNameToColumnMap,
      relations
    );
    if (typeof onCellClick === "function" && collaboratorData) {
      onCellClick(collaboratorData.id, collaboratorData.attributes.name);
    }
  };

  return (
    <TableContainer {...other}>
      <Grid
        onClick={onClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        data={{
          rowData: data,
          cellNameToColumnMap,
          maxStatusLength: maxStatusLength,
        }}
        dataKey="rowData"
      >
        {RenderItem}
      </Grid>
    </TableContainer>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "followSubordinate.subordinates",
    blackList: { "Sl No": true },
    columnOrder: [4, 0, 1, 2, 3],
  });
  const maker = (store, props) => {
    const { rows, cellNameToColumnMap } = getUserRows(store, props);
    return {
      data: rows,
      cellNameToColumnMap,
      relations: store.followSubordinate.subordinates.relation,
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
