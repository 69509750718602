import styled from "styled-components";
import ProfilePic from "components/ProfilePic";
import Button from "components/Button";

import { FadeInVertical } from "globalStyles";
import { Card } from "../../../../styles/employee";

const BottomFade = FadeInVertical("bottom");

export const CardContainer = styled(Card)`
  padding: 21px 25px;
  display: flex;
  flex-flow: column nowrap;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 13px;
`;
export const StyledProfilepic = styled(ProfilePic)`
  width: 45px;
  height: 45px;
`;

export const PostCreateButton = styled.button`
  flex-grow: 1;
  margin-left: 12px;
  border: 1px solid #d4d4d4;
  border-radius: 29px;
  background-color: white;
  cursor: pointer;
  padding: 13px 21px;
  font-weight: 400;
  font-size: ${(props) => props.theme.text.para.small};
  color: #5a5a5a;
  text-align: left;
`;

export const Footer = styled.div`
  display: flex;
`;

export const PostItemsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const PostItem = styled.button`
  min-width: 98px;
  min-height: 36px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  & img {
    width: 15px;
    height: 12px;
  }

  & .title {
    font-weight: 600;
    font-size: ${(props) => props.theme.text.para.small};
    color: #5a5a5a;
    margin-left: 6px;
  }
  & + button {
    margin-left: 8px;
  }
  border: none;
  cursor: pointer;

  ${({ $disabled }) => {
    if ($disabled) {
      return `
       cursor: not-allowed;
       opacity: 0.5;
       `;
    }
  }}
`;

export const ImageViewContainer = styled.div`
  margin-bottom: 20px;
  animation: ${BottomFade} ease-out 0.3s forwards;
`;
