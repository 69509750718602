import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import { PaginatorBox } from "../../../../GlobalStyles";
import {
  SearchArea,
  EnhancedActionArea,
  PaginatorAndCreateBox,
} from "../../style/display";
import Paginator from "components/Paginator";
import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";
import Button from "components/Button";

import { getReimbursements, searchReimbursement } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";

import SearchFilter from "./SearchFilter";
import { CreateButton } from "../../style/create";

const ActionArea = ({
  collaboratorId,
  expenseType,
  currentFromDate,
  currentToDate,
  currentStatus,
  isSearchOn,
  onceFetched,
  onLoading,
  style,
  companyId,
  isAdmin,
  showCreateButton,
  updateFormVisibilty,
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: isSearchOn ? searchReimbursement : getReimbursements,
    tableName: "reimbursements",
  });

  const extraParams = {
    companyId,
    collaboratorId,
    expenseType,
    currentFromDate,
    currentToDate,
    currentStatus,
    isAdmin,
  };

  const { fetchFirstPage, fetchNextPage, fetchPrevPage, fetchSpecificPage } =
    usePaginator({ extraParams, paginatorApi: getNextPage });

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched && !isSearchOn) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  const CreateButtonChild = useMemo(() => {
    return (
      <CreateButton>
        <Button primary onClick={updateFormVisibilty}>
          Create New
        </Button>
      </CreateButton>
    );
  }, [updateFormVisibilty]);

  return (
    <EnhancedActionArea style={style}>
      <SearchArea>
        <SearchFilter
          isAdmin={isAdmin}
          collaborator_id={collaboratorId}
          expense_type={expenseType}
          from_date={currentFromDate}
          to_date={currentToDate}
          status={currentStatus}
        />
      </SearchArea>
      <PaginatorAndCreateBox>
        {showCreateButton && CreateButtonChild}
        <PaginatorBox>
          <Paginator
            current={currentPageNumber}
            total={totalPages}
            onNext={fetchNextPage}
            onPrev={fetchPrevPage}
            onGoToPage={fetchSpecificPage}
            nextIsLoading={loadingNextPage}
            prevIsLoading={loadingPrevPage}
          />
        </PaginatorBox>
      </PaginatorAndCreateBox>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    onceFetched: store.reimbursements.onceFetched,
    collaboratorId: store.reimbursements.collaboratorId,
    expenseType: store.reimbursements.expenseType,
    currentFromDate: store.reimbursements.currentFromDate,
    currentToDate: store.reimbursements.currentToDate,
    currentStatus: store.reimbursements.currentStatus,
    isSearchOn: store.reimbursements.isSearchOn,
    isAdmin,
  };
};

export default connect(mapStateToProps, null)(ActionArea);
