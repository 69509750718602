import { extractDateInYYYYMMDD } from "../../../../../Helper";

export const generateFormData = (values) => {
  let collaboratorIndex = 0;
  return {
    collaborator_id: values.collaborator
      ? Object.keys(values.collaborator)[collaboratorIndex]
      : "",
    leave_plan_id: values.leaveType,
    valid_from: extractDateInYYYYMMDD(values.validFrom),
    valid_to: extractDateInYYYYMMDD(values.validTo),
    assigned_quota: values.assignedQuota,
    max_carry: values.maxCarry,
  };
};
