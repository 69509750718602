import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  toggleDrawerAction,
  openDrawerAction,
  closeDrawerAction,
} from "../Common/ActionCreators";

import { isDrawerOpen } from "../Common/Selectors/app";

const useDrawer = () => {
  const dispatch = useDispatch();
  const isDrawerActive = useSelector(isDrawerOpen);

  const toggleDrawer = useCallback(() => {
    dispatch(toggleDrawerAction());
  }, [dispatch]);

  const openDrawer = useCallback(() => {
    dispatch(openDrawerAction());
  }, [dispatch]);

  const closeDrawer = useCallback(() => {
    dispatch(closeDrawerAction());
  }, [dispatch]);

  return {
    isDrawerActive,
    toggleDrawer,
    openDrawer,
    closeDrawer,
  };
};

export default useDrawer;
