import React, { useState } from "react";

import Form from "./Form";
import Publish from "./Publish";
import { MainContainer } from "../../styles/create";

const NoticeCreateView = ({ updateFormVisibility }) => {
  const [isFormSubmitted, updateIsFormSubmitted] = useState(false);

  return (
    <MainContainer>
      {isFormSubmitted ? (
        <Publish updateIsFormSubmitted={updateIsFormSubmitted} creation={true} updateFormVisibility={updateFormVisibility} />
      ) : (
        <Form updateIsFormSubmitted={updateIsFormSubmitted} updateFormVisibility={updateFormVisibility} />
      )}
    </MainContainer>
  );
};

export default NoticeCreateView;
