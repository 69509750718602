import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

export const DaysContainer = styled.div`
  display: flex;
  flex-basis: 100px;
  align-items: stretch;
`;
export const DayBox = styled.div`
  flex-basis: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 8;
  background-color: white;
`;
export const DayName = styled.span`
  font-size: ${(props) => props.theme.text.para.small};
  line-height: 1.18;
  color: #000000;
  margin-bottom: 15px;
`;

export const DayDate = styled.div`
  font-size: ${(props) => props.theme.text.para.large};
  font-weight: 600;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.isTodayDate ? props.theme.colors.primaryColor : "#1c1c1c"};
  background-color: ${(props) =>
    props.isTodayDate ? props.theme.colors.lightPrimaryColor : "white"};
`;

export const WeekColumnContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: auto;
  flex-grow: 1;
  position: relative;
`;

export const WeekColumn = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  min-width: ${(props) => props.theme.dimensions.calendar.column.width};
  flex-flow: column nowrap;
`;

export const TaskListContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  // border-left: 1px #cecece solid;
  border-right: 1px #cecece solid;
`;

export const NewTask = styled.div`
  position: absolute;
  z-index: 3;
  display: flex;
  padding: 10px;
  align-items: center;
  min-height: 30px;
  box-shadow: ${(props) => props.theme.shadow.medium};
  background-color: white;
`;
