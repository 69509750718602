import {
  FETCH_POSTS,
  CREATE_POST,
  CREATE_POLL,
  CREATE_QUIZ,
  CREATE_POST_COMMENT,
  REACT_TO_POST,
  REMOVE_REACT_FROM_POST,
  ANSWERE_TO_QUIZ,
  ANSWERE_TO_POLL,
  DELETE_POST
} from "./actionTypes";
import { createAsyncActionCreator } from "../../../../Helper";
import {
  fetchPostsApi,
  createPostApi,
  createPollApi,
  createQuizApi,
  createPostCommentApi,
  reactToPostApi,
  removeReactFromPostApi,
  answereQuizApi,
  answerePollApi,
  deletePostApi
} from "./api";

import {
  IpageData,
  IcreatePostGenrator,
  IreactToPostGenrator,
  IremoveReactFromPostGenerator,
  IanswereToQuizGenerator,
  IanswereToPollGenerator,
  IcreatePostCommentGenrator,
  IcreatePollGenrator,
  IcreateQuizGenrator,
  IdeletePostGenrator
} from "./types";
import { TactionCreator } from "types/common";

const fetchPostsGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber
}: IpageData) {
  yield {
    action: FETCH_POSTS,
    sideEffect: fetchPostsApi({ companyId, isAdmin, pageNumber })
  };
};

const createPostGenrator = function* ({
  companyId,
  isAdmin,
  formData
}: IcreatePostGenrator) {
  yield {
    action: CREATE_POST,
    sideEffect: createPostApi({ companyId, isAdmin, formData })
  };
};

const reactToPostGenrator = function* ({
  companyId,
  isAdmin,
  formData
}: IreactToPostGenrator) {
  yield {
    action: REACT_TO_POST,
    sideEffect: reactToPostApi({ companyId, isAdmin, formData })
  };
};

const removeReactFromPostGenerator = function* ({
  companyId,
  isAdmin,
  formData
}: IremoveReactFromPostGenerator) {
  yield {
    action: REMOVE_REACT_FROM_POST,
    sideEffect: removeReactFromPostApi({ companyId, isAdmin, formData })
  };
};

const answereToQuizGenerator = function* ({
  companyId,
  isAdmin,
  optionId
}: IanswereToQuizGenerator) {
  yield {
    action: ANSWERE_TO_QUIZ,
    sideEffect: answereQuizApi({ companyId, isAdmin, optionId })
  };
};

const answereToPollGenerator = function* ({
  companyId,
  isAdmin,
  optionId
}: IanswereToPollGenerator) {
  yield {
    action: ANSWERE_TO_POLL,
    sideEffect: answerePollApi({ companyId, isAdmin, optionId })
  };
};

const createPostCommentGenrator = function* ({
  companyId,
  isAdmin,
  postId,
  comment
}: IcreatePostCommentGenrator) {
  yield {
    action: CREATE_POST_COMMENT,
    payload: { postId },
    sideEffect: createPostCommentApi({ companyId, isAdmin, postId, comment })
  };
};

const createPollGenrator = function* ({
  companyId,
  isAdmin,
  formData
}: IcreatePollGenrator) {
  yield {
    action: CREATE_POLL,
    sideEffect: createPollApi({ companyId, isAdmin, formData })
  };
};

const createQuizGenrator = function* ({
  companyId,
  isAdmin,
  formData
}: IcreateQuizGenrator) {
  yield {
    action: CREATE_QUIZ,
    sideEffect: createQuizApi({ companyId, isAdmin, formData })
  };
};

const deletePostGenrator = function* ({
  companyId,
  isAdmin,
  postId
}: IdeletePostGenrator) {
  yield {
    action: DELETE_POST,
    sideEffect: deletePostApi({ companyId, isAdmin, postId })
  };
};

export const [
  fetchPosts,
  createPost,
  createPoll,
  createQuiz,
  createPostComment,
  reactToPost,
  removeReactFromPost,
  answereToQuiz,
  answereToPoll,
  deletePost
] = createAsyncActionCreator([
  fetchPostsGenrator,
  createPostGenrator,
  createPollGenrator,
  createQuizGenrator,
  createPostCommentGenrator,
  reactToPostGenrator,
  removeReactFromPostGenerator,
  answereToQuizGenerator,
  answereToPollGenerator,
  deletePostGenrator
]) as TactionCreator[];
