import { FETCH_SURVEY_DATA, SUBMIT_SURVEY_DATA } from "./actionTypes";
import {
  createInitialStoreState,
  createNewStoreOnGetData,
  createRelationIterator,
} from "helper/index";

const initialStore = createInitialStoreState({});

const surveyReducer = (store = initialStore, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_SURVEY_DATA}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }
    case `${SUBMIT_SURVEY_DATA}_SUCCESS`: {
      const { data } = payload;
      const newRelation = {};
      Object.keys(data).map((key) => {
        const item = data[key];
        if (typeof item !== "function") {
          newRelation[`${key}_${item.type}`] = {
            ...item,
            [Symbol.iterator]: createRelationIterator(),
          };
        }
      });
      return {
        ...store,
        relation: { ...store.relation, ...newRelation },
      };
    }
    default:
      return store;
  }
};

export default surveyReducer;
