import React, { useCallback, useMemo } from "react";
import { FixedSizeGrid } from "react-window";
import DayGridItem from "./CellGridItem";

import useGrid from "hooks/useGrid";
import { GridContent } from "./style";

const RenderItem = ({ data, columnIndex, rowIndex, style }) => {
  const { data: newData, onClick, onViewTask, newTasks } = data;

  const modData = useMemo(() => {
    return newData[rowIndex] && newData[rowIndex][columnIndex]
      ? newData[rowIndex][columnIndex]
      : "";
  }, [newData, rowIndex, columnIndex]);

  const newTaskPos = useMemo(() => {
    const { dateObj } = modData.cellData || {};
    return newTasks && newTasks[dateObj] && newTasks[dateObj].style;
  }, [modData, newTasks]);

  return (
    <DayGridItem
      data={modData}
      style={style}
      onClick={onClick}
      onViewTask={onViewTask}
      newTaskPos={newTaskPos}
    />
  );
};

const CellGrid = ({
  rows,
  requiredColumns,
  rowHeight,
  data,
  dataKey = null, // used to indentify in which key the actual data matrix is stored in the data Object
  onClick = null,
  onViewTask = null,
  newTasks,
}) => {
  const { actualColumnWidth, dimensions, containerRef } = useGrid({
    data,
    dataKey,
    requiredColumns,
  });
  return (
    <GridContent ref={containerRef}>
      <FixedSizeGrid
        width={dimensions.width}
        height={dimensions.height}
        columnCount={requiredColumns}
        rowCount={rows}
        columnWidth={actualColumnWidth}
        itemKey={({ columnIndex, rowIndex }) => `${rowIndex}_${columnIndex}`}
        rowHeight={rowHeight}
        itemData={{
          data,
          onClick,
          onViewTask,
          newTasks,
        }}
      >
        {RenderItem}
      </FixedSizeGrid>
    </GridContent>
  );
};

export default CellGrid;
