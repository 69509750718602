import { FETCH_TODAY_HOLIDAY } from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import { fetchTodaysHolidayApi } from "./api";

const fetchTodayHolidayGenerator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: FETCH_TODAY_HOLIDAY,
    sideEffect: fetchTodaysHolidayApi({ companyId, isAdmin, pageNumber }),
  };
};

export const [fetchTodaysHoliday] = createAsyncActionCreator([
  fetchTodayHolidayGenerator,
]);
