import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const getEmployeeEmailsApi = ({ isAdmin, companyId, pageNumber }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborator_emails?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getUserEmailApi = ({
  isAdmin,
  companyId,
  userId,
  pageNumber,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/fetch_emails_by_collaborator?collaborator_id=${userId}&page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const createUserEmailApi = ({
  isAdmin,
  companyId,
  userId,
  formData,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborator_emails`,
  });
  return requestData(url, {
    method: "POST",
    body: {
      collaborator_email: {
        collaborator_id: userId,
        ...formData,
      },
    },
    isFormData: true,
  });
};
