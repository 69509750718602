import styled from "styled-components";
import { RectangleSkeleton } from "components/Skeleton";
import InputGroup from "components/InputGroup/inputGroup2";
import { ImageComponent } from "../../../Common/Components/Image";

export const StyledInputGroup = styled(InputGroup)`
  flex-basis: 100%;
  @media (min-width: 1024px) {
    flex-grow: 1;
  }
`;

export const Container = styled.div`
  padding: 20px 20px 0 63px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

export const ViewContainer = styled.div`
  padding: 20px 20px 0 63px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
`;

export const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-right: 50px;
  & > button + button {
    margin-left: 10px;
  }
`;
export const WorkHourCont = styled.div`
  width: 428px;
  height: 200px;
  background: white;
  border: 1px solid #dfdfdf;
  box-shadow: ${(props) => props.theme.shadow.medium};
  border-radius: 8px;
  display: flex;
  flex-flow: column nowrap;
`;

export const WorkHourHead = styled.div`
  flex-basis: 60px;
  display: flex;
  padding: 18px 0 18px 30px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.drawer.link.background};
`;

export const WorkHeadText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.title.medium};
  color: ${(props) => props.theme.colors.primaryColor};
`;

export const WorkHourContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  padding: 22px 10px 0 30px;
`;

export const WorkHourData = styled.div`
  display: flex;
  margin-bottom: 14px;
  align-items: center;
`;

export const WorkHourDataHead = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.medium};
  color: ${(props) => props.theme.colors.primaryColor};
`;

export const WorkHourDataText = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.text.para.small};
  color: #333333;
  margin-left: 50px;
`;

export const FormCont = styled.form`
  flex-grow: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  & > div {
    flex-basis: 100%;
    margin-right: 10px;
    & > div {
      height: 32px;
      & > button,
      & > select {
        padding: 0 0 0 13px;
      }
    }
  }

  @media (min-width: 1024px) {
    & > div {
      flex-basis: 135px;
    }
  }
`;

export const FormButtons = styled.div`
  flex-basis: 200px;
  display: flex;
  margin-bottom: 23px;
  & > button + button {
    margin-left: 10px;
  }

  & > button {
    height: 32px;
  }
`;

export const StyledPaginatorCont = styled.div`
  flex-basis: 150px;
`;

export const StyledActionArea = styled.div`
  margin: 10px 0;
  padding: 10px;
  display: flex;
  flex-basis: 50px;
  width: 100%;
  flex-grow: 1;
  flex-flow: row wrap;
`;

export const StyledSkeleton = styled(RectangleSkeleton)`
  height: 20px;
  width: auto;
  margin-left: 50px;
  & > div {
    width: 150px;
    height: 20px;
  }
`;

export const FormContainer = styled.div`
  max-width: 600px;
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

export const ActionAreaWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const StyledSkeletonLoader = styled(RectangleSkeleton)`
  margin-top: 50px;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    height: 35px;
  }
`;

export const EmptyScreenImage = styled(ImageComponent)`
  width: 40%;
  height: 40%;
  object-fit: contain;
`;

export const EmptyScreenMessage = styled.span`
  margin: 30px;
  font-size: ${(props) => props.theme.text.title.medium};
`;
