import React, { useMemo, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";

import Grid from "components/Grid";
import { createTableDataSelector } from "../../../../Common/Selectors/MTable";
import Routes from "../../../../Routes/Path";
import { TableContainer } from "../../../../GlobalStyles";
import { isCurrentUserIsExecutive } from "common/Selectors/auth";

const TableData = ({
  data,
  onCellClick,
  onDeleteHoliday,
  rowIdMap,
  cellNameToColumnMap,
  isDeletingHoliday,
  onEmptyData,
  ...other
}) => {
  const history = useHistory();
  const tempRef = useRef({ rowToDelete: "" });
  const { rows, cols, modifiedData } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    let modifiedData = JSON.parse(JSON.stringify(data)); // doing a deep copy
    const isOptionalIndex = cellNameToColumnMap?.isOptional;
    modifiedData.map((data, index) => {
      if (index > 0) {
        modifiedData[index][isOptionalIndex] =
          modifiedData[index][isOptionalIndex] == "NA" ? "No" : "Yes";
      }
    });
    modifiedData[0] = modifiedData[0].map((column) => {
      if (column == "is Optional") return "optional";
      return column;
    });
    //  converting image data for multiple image viewer
    const cardImageColumnIndex = cellNameToColumnMap["holidayCardImageUrl"];
    for (let i = 1; i < rows; i++) {
      const value = modifiedData[i][cardImageColumnIndex];
      modifiedData[i][cardImageColumnIndex] =
        value && value !== "NA" ? [value] : value;
    }
    return { rows, cols, modifiedData };
  }, [data, cellNameToColumnMap]);

  const onEditHoliday = (e, rowIndex) => {
    history.push(
      `${Routes.authorized.holiday.edit.path}/${rowIdMap[rowIndex]}`
    );
  };

  const onDeleteHolidayClick = (e, rowIndex) => {
    if (window.confirm("Are you sure you want to delete the holiday ?")) {
      tempRef.current.rowToDelete = rowIndex;
      const holidayId = rowIdMap[rowIndex];
      onDeleteHoliday(holidayId);
    }
  };

  const actionCellProps = useMemo(() => {
    return (rowIndex) => ({
      cellName: "actions",
      cellActions: [
        {
          id: "class_edit_btn",
          icon: "fas fa-pen",
          tootltipText: "Edit Holiday",
          btnProps: {
            disabled:
              isDeletingHoliday && tempRef.current.rowToDelete === rowIndex,
            onClick: onEditHoliday,
          },
        },

        {
          id: "class_delete_btn",
          icon: "fas fa-trash",
          tootltipText: "Delete Holiday",
          btnProps: {
            disabled:
              isDeletingHoliday && tempRef.current.rowToDelete === rowIndex,
            onClick: onDeleteHolidayClick,
          },
        },
      ],
    });
  }, [rowIdMap, onDeleteHolidayClick, onEditHoliday]);

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (rows === 1) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);
  return (
    <TableContainer {...other}>
      <Grid
        onClick={onCellClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        actionCellProps={actionCellProps}
        data={modifiedData}
      />
    </TableContainer>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const getUserRows = createTableDataSelector({
    tableName: "holidays",
    blackList: { "Sl No": true, holidayYear: true },
    extraColumns: isAdmin && [{ columnName: "actions", position: 0 }],
    reverse: false,
  });
  const maker = (store, props) => {
    const { rows, rowIdMap, cellNameToColumnMap } = getUserRows(store, props);
    return {
      data: rows,
      rowIdMap,
      cellNameToColumnMap,
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
