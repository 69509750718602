import React from "react";
import { ListItem, ListIcon, ListText } from "./style";

const ProfileItem = ({ icon, text, iconDesc }) => {
  return (
    <ListItem>
      <ListIcon src={icon} alt={iconDesc} />
      <ListText>{text}</ListText>
    </ListItem>
  );
};

export default ProfileItem;
