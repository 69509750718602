import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Grid from "components/Grid";
import RenderItem from "./RenderItem";

import { createTableDataSelector } from "common/Selectors/MTable";

import { TableContainer } from "../../../../GlobalStyles";

const TableData = ({
  data,
  onCellClick,
  cellNameToColumnMap,
  rowIdMap,
  onEmptyData,
  ...other
}) => {
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);

  const onClick = useCallback(
    ({ data: rowData, columns, rowIndex }) => {
      if (typeof onCellClick === "function") {
        onCellClick({ data: rowData, columns, rowIndex, rowIdMap });
      }
    },
    [onCellClick, rowIdMap]
  );

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (rows === 1) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);

  return (
    <TableContainer {...other}>
      <Grid
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        data={{
          rowData: data,
          cellNameToColumnMap,
          onIdClick: onClick,
        }}
        dataKey="rowData"
      >
        {RenderItem}
      </Grid>
    </TableContainer>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "teams.owners",
    blackList: { "Sl No": true },
    reverse: false,
  });
  return (store, props) => {
    const { rows, cellNameToColumnMap, rowIdMap } = getUserRows(store, props);
    return {
      data: rows,
      cellNameToColumnMap,
      rowIdMap,
    };
  };
};

export default connect(mapStateToProps, null)(TableData);
