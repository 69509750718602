import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import ProfileDisplayView from "../../../Modules/Profile/Views/Display";

import { createRouteObject } from "../../../Helper";

const { profile } = RoutePaths.authorized;

const pages = createRouteObject(
  profile,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [ProfileDisplayView]
);

const ProfilePages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact
        path={item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default ProfilePages;
