import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import useAlert from "hooks/useAlert";
import { getWorkingHourLogActivity } from "modules/Attendence/actionCreator";

const useFetchStatusActivity = () => {
  const [isLoading, setLoading] = useState(true);
  const { showAlert } = useAlert();

  const dispatch = useDispatch();
  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const fetchActivity = async ({ attendanceId }) => {
    try {
      setLoading(true);
      await dispatch(
        getWorkingHourLogActivity({
          companyId,
          isAdmin,
          modelableId: attendanceId,
        }),
      );
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return { fetchActivity, isLoading };
};

export default useFetchStatusActivity;
