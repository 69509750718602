import { createSelector } from "reselect";
import {
  generateTableHeading,
  getStore,
  removeSpaceFromString,
} from "../../Helper";

const addAttributeToTarget = (source, target, preDefinedKey = "") => {
  if (!source) {
    return;
  }
  Object.keys(source).forEach((attrKey) => {
    //  checking if value is array, if array then converting it ot string
    const attr = source[attrKey];

    const actKey = preDefinedKey || attrKey;
    target[actKey] = [...(target[actKey] || []), attr || "NA"];
  });
};

const generateTableRows = (
  tableName,
  blackList = {},
  reverse = true,
  showHeading,
  options
) => {
  const { fetchAllData, columnOrder } = options;
  const dataIdKey = fetchAllData ? "allIds" : "dataIds";
  const dataKey = fetchAllData ? "allData" : "data";
  const relationKey = fetchAllData ? "allRelation" : "relation";

  const tableDataIds = (store, props) => getStore(store, tableName)[dataIdKey];
  const tableData = (store, props) => getStore(store, tableName)[dataKey];
  const tableRelation = (store, props) =>
    getStore(store, tableName)[relationKey];
  const paginationData = (store, props) =>
    getStore(store, tableName).paginationData;

  return createSelector(
    tableDataIds,
    tableData,
    tableRelation,
    paginationData,
    (ids, data, relations, pageData) => {
      const matrixObj = {};
      let cellNameToColumnMap = {};
      const rowIdMap = {};
      const idRowMap = {};
      let rows = [];

      //    getting all the relation keys for every object
      const reducedRelation = Object.keys(data).reduce((prev, curr) => {
        const currItem = data[curr];
        const myData = { ...(prev || {}) };
        Object.keys(currItem.relationships || {}).forEach((key) => {
          myData[key] = true;
        });
        return myData;
      }, []);

      const newIds = [...ids];

      if (reverse) {
        newIds.reverse();
      }

      for (let id of newIds) {
        const { relationships, attributes, ...otherData } = data[id];
        let result = {};
        if (relationships) {
          result = data.getRelationData(relationships, relations);
        }

        //  adding the general attributes part
        addAttributeToTarget(attributes, matrixObj);

        //  adding the relations part
        Object.keys(result).forEach((key) => {
          const item = result[key];
          //const relationIsArray = Array.isArray(item);

          matrixObj[key] = [...(matrixObj[key] || []), item];

          // if (relationIsArray) {
          //   matrixObj[key] = [...(matrixObj[key] || []), item];
          // } else {
          //   matrixObj[key] = [...(matrixObj[key] || []), objectToString(item)];
          // }
        });

        Object.keys(reducedRelation).forEach((key) => {
          //   for relation keys which are not present in this data but present in another daataObj
          if (!result[key]) matrixObj[key] = [...(matrixObj[key] || []), "NA"];
        });
      }

      let columns = [];
      const matrixKeys = Object.keys(matrixObj);
      if (matrixKeys.length > 0) {
        columns = ["Sl No", ...Object.keys(matrixObj)];
      }
      const whiteListColumns = [];
      let prevIndex = 0;

      //    filtering out the columns
      columns.forEach((columnName) => {
        if (!blackList[columnName]) {
          whiteListColumns.push(columnName);
          cellNameToColumnMap[columnName] = prevIndex++;
        }
      });

      if (whiteListColumns.length > 0) {
        rows.push(generateTableHeading(...whiteListColumns));
      } else {
        //  adding temporary one row for good ui
        rows.push([]);
      }

      const noOfRows = newIds.length;
      const prevPageCount = (pageData.currentPage - 1) * 10;
      for (let i = 0; i < noOfRows; i++) {
        const result = [];
        let startingIndex = 0;

        if (!blackList["Sl No"]) {
          result.push(options.showId ? newIds[i] : i + 1 + prevPageCount); // first addding the id
          startingIndex = 1;
        }

        rowIdMap[i + 1] = newIds[i]; // +1 because the first row consist of column names
        idRowMap[newIds[i]] = i + 1; // +1 because the first row consist of column names

        for (let j = startingIndex; j < whiteListColumns.length; j++) {
          const columnName = whiteListColumns[j];
          const item = matrixObj[columnName][i];
          const mItem = Array.isArray(item) && item.length === 0 ? "NA" : item;
          result.push(mItem);
        }
        rows.push(result);
      }

      if (
        options.extraColumns &&
        options.extraColumns.length > 0 &&
        rows.length > 1
      ) {
        addExtraColumns(options.extraColumns, rows);
        cellNameToColumnMap = reGenerateColumnIdMap(rows);
      }

      if (columns.length > 0 && columnOrder && columnOrder.length > 0) {
        rows = reorderColumns(columnOrder, rows);
        cellNameToColumnMap = reGenerateColumnIdMap(rows);
      }

      return { rows, rowIdMap, idRowMap, cellNameToColumnMap };
    }
  );
};

const addExtraColumns = (extraColumns, rows) => {
  const isArrayOfObjects = extraColumns.find(
    (item) => item && typeof item === "object"
  );

  if (isArrayOfObjects) {
    extraColumns.forEach(({ columnName, position, value }) => {
      // inserting column header
      rows[0].splice(position, 0, columnName);
      // inserting new cell
      for (let i = 1; i < rows.length; i++) {
        const newVal =
          typeof value === "function" ? value(i, columnName) : value;
        rows[i].splice(position, 0, newVal || "");
      }
    });
    return;
  }

  //  appending at last
  extraColumns.forEach((columnName) => {
    for (let i = 0; i < rows.length; i++) {
      if (i === 0) {
        rows[i].push(columnName);
      } else {
        rows[i].push("");
      }
    }
  });
};

const reGenerateColumnIdMap = (rows) => {
  const header = rows[0];
  const map = {};
  header.forEach((column, idx) => {
    map[removeSpaceFromString(column)] = idx;
  });
  return map;
};

const reorderColumns = (newOrder, rows) => {
  const newData = [];

  const nonOrderedColumns = [...Array(rows[0].length).keys()].filter(
    (item, index) => !newOrder.includes(index)
  );

  for (let i = 0; i < rows.length; i++) {
    newData.push([]);
    newOrder.forEach((oldColumnIndex) => {
      newData[i].push(rows[i][oldColumnIndex]);
    });
    nonOrderedColumns.forEach((oldColumnIndex) => {
      newData[i].push(rows[i][oldColumnIndex]);
    });
  }
  return newData;
};

export const createTableDataSelector = ({
  tableName = "",
  reverse = true,
  blackList = {},
  showHeading = false,
  persistOriginalOrder = false,
  extraColumns = [],
  showId = true,
  fetchAllData = false,
  columnOrder = [], // write the previous column index at their new position
}) => {
  if (!tableName) {
    return () => ({
      rows: [],
      rowIdMap: {},
      idRowMap: {},
      cellNameToColumnMap: {},
    });
  }
  return generateTableRows(tableName, blackList, reverse, showHeading, {
    persistOriginalOrder,
    extraColumns,
    showId,
    fetchAllData,
    columnOrder,
  });
};
