export const enquiryTypeOptions = [
  { name: "Form 16", value: "form_16" },
  { name: "Income Tax", value: "income_tax" },
  { name: "Investment Declarations", value: "investment_declarations" },
  { name: "Investment Proofs", value: "investment_proofs" },
  { name: "Login Related Issues", value: "login_related_issues" },
  { name: "Payroll Queries", value: "payroll_queries" },
  { name: "Payroll Query Types", value: "payroll_query_types" },
  { name: "Payslip Related Issues", value: "payslip_related_issues" },
  { name: "PF Query", value: "pf_query" },
  { name: "PF Related Query", value: "pf_related_query" },
  { name: "Query On Salary Advance", value: "query_on_salary_advance" },
  { name: "Reimbursement Related Query", value: "reimbursement_related_query" },
  { name: "Escalated Payroll", value: "escalated_payroll" },
  { name: "Queries", value: "queries" },
  { name: "Attendance Not Capturing", value: "attendance_not_capturing" },
  { name: "Change In DOB Reporting Line", value: "change_in_dob_reporting_line" },
  { name: "Clarification On Policies", value: "clarification_on_policies" },
  { name: "Compensatory Offs", value: "comp_offs" },
  { name: "Conversion Of Savings Account", value: "conversion_of_savings_account" },
  { name: "Crediting Compensatory Offs", value: "crediting_comp_offs" },
  { name: "Employee Documents", value: "employee_documents" },
  { name: "Employee Profile Changes Up", value: "employee_profile_changes_up" },
  { name: "General", value: "general" },
  { name: "Insurance", value: "insurance" },
  { name: "Insurance Claim Reimbursements", value: "insurance_claim_reimbursements" },
  { name: "Leaves", value: "leaves" },
  { name: "Leaves Attendance", value: "leaves_attendance" },
  { name: "Leaves Clarification Loss Of Pay", value: "leaves_clarification_lop" },
  { name: "Leaves Clarification Maternity Leave", value: "leaves_clarification_ml" },
  { name: "Leaves Clarification Paternity Leave", value: "leaves_clarification_pl" },
  { name: "Opting For Flexible Benefit Plan", value: "opting_for_fbp" },
  { name: "Performance Management System", value: "pms" },
  { name: "Policy", value: "policy" },
  { name: "Services", value: "services" },
  { name: "Biometric Registration", value: "bio_metric_registration_of_exists" },
  { name: "Creating New Bank Accounts", value: "creating_new_bank_accounts" },
  { name: "Exit Formalities", value: "exit_formalities" },
  { name: "Issuing Ticket Restaurant Cards", value: "issuing_ticket_restaurant_cards" },
  { name: "Joining Formalities Providing", value: "joining_formalities_providing" },
  { name: "Letter Requests Employment", value: "letter_requests_employment" },
  { name: "Others", value: "others" }
];

export const statusOptions = [
  { name: "open", value: "open" },
  { name: "closed", value: "closed" },
  { name: "responded", value: "responded" }
]
