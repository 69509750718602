import styled from "styled-components";

import { StyledActionArea } from "globalStyles";

import { RectangleSkeleton } from "components/Skeleton";
import { ImageComponent } from "../../../Common/Components/Image";

export const NoticeBackground = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const StyledSkeleton = styled(RectangleSkeleton)`
  width: 80%;
  margin-top: 50px;
  & > div {
    margin-bottom: 20px;
  }
`;

export const EnhancedActionArea = styled(StyledActionArea)`
  flex-flow: row wrap;
  align-items: center;

  & button {
    height: 35px;
  }

  @media (min-width: 768px) {
    justify-content: center;
  }
`;

export const SearchArea = styled.div`
  flex-basis: 100%;
  align-self: center;

  @media (min-width: 1024px) {
    flex-basis: 80%;
  }

  & > form > button {
    margin-top: 5px;
    flex-basis: 75%;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 20px;

  & div {
    height: 35px;
  }

  & div > select {
    height: 35px;
    padding: 0 10px;
  }

  & button {
    height: 35px;
    padding: 7px;
  }

  @media (min-width: 768px) {
    flex-flow: row;
  }
`;

export const EmptyScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    height: 35px;
  }
`;

export const EmptyScreenImage = styled(ImageComponent)`
  width: 40%;
  height: 40%;
  object-fit: contain;
`;

export const EmptyScreenMessage = styled.span`
  margin: 30px;
  font-size: ${(props) => props.theme.text.title.medium};
`;
