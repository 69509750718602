import React, { useMemo } from "react";
import Popover from "../../PopoverAdvanced2";

import Content from "./Content";
import StringContent from "./StringContent";
import ProfilePopoverContent from "../ProfilePopoverContent";
import { collaboratorFields } from "../../../../Constants";

const ObjectPopover = ({
  data,
  active,
  color,
  onClose,
  anchorElem,
  heading,
}) => {
  const Container = useMemo(() => {
    if (typeof data === "string") {
      return StringContent;
    }
    const columnHeading = heading.toLowerCase();
    if (columnHeading && collaboratorFields.includes(columnHeading)) {
      return ProfilePopoverContent;
    }
    return Content;
  }, [data, heading]);

  return (
    <Popover
      onClose={onClose}
      active={active}
      anchorElem={anchorElem}
      userPreferedDirection="left"
    >
      <Container heading={heading} data={data} color={color} />
    </Popover>
  );
};

ObjectPopover.deaultProps = {
  color: null,
};

export default ObjectPopover;
