import { getIcon } from "../../../../Helper";

export const iconOptions = [
  {
    name: "Short Break",
    value: "short-break",
    renderComponent: (checked) => {
      return getIcon("short-break", checked ? "white" : null);
    },
  },
  {
    name: "Long Break",
    value: "long-break",
    renderComponent: (checked) => {
      return getIcon("long-break", checked ? "white" : null);
    },
  },
  {
    name: "Tea Break",
    value: "tea-break",
    renderComponent: (checked) => {
      return getIcon("tea-break", checked ? "white" : null);
    },
  },
  {
    name: "Lunch Break",
    value: "lunch-break",
    renderComponent: (checked) => {
      return getIcon("lunch-break", checked ? "white" : null);
    },
  },
  {
    name: "Meeting Break",
    value: "meeting-break",
    renderComponent: (checked) => {
      return getIcon("meeting-break", checked ? "white" : null);
    },
  },
];
