import { createSelector } from "reselect";
import { getCurrentDataAttributes } from "helper/selectors";

const storePostData = (store) => store.postDetail.data;
const storePostDataIds = (store) => store.postDetail.dataIds;
const storePostId = (store, props) => props.postId;
const storePostRelations = (store, props) => store.postDetail.relation;

export const getPostData = createSelector(
  storePostData,
  storePostDataIds,
  storePostId,
  storePostRelations,
  (data, postIds, id, relations) => {
    const post = data?.[id];
    return !!post && postIds.includes(id)
      ? getCurrentDataAttributes(post, relations, data)
      : {};
  },
);

const PostsListData = [
  {
    userName: "Joey Natoli",
    postTime: "3 days ago",
    // image: Post_Img,
    pollData: {
      heading: "Which of the following you would FEAR the most?",
      desc: "The author can see how you vote",
      votes: 12000,
      daysLeft: 4,
      options: [
        { name: "Failure", value: "Failure" },
        { name: "Change", value: "Change" },
        { name: "Rejection", value: "Rejection" },
        { name: "Lose of freedom", value: "Lose of freedom" },
      ],
      resultOptions: [
        { name: "Failure", value: 6 },
        { name: "Change", value: 9 },
        { name: "Rejection", value: 27 },
        { name: "Lose of freedom", value: 58 },
      ],
      onPoll: null,
    },
    likes: "22,232 likes",
    comments: "201 comments",
    description: `Instead of worrying about what might happen, anticipate https://www.google.com what might happen. 
Our power to imagine #alienbrains is both a strength and a weakness.
Okay got it #asd #sfgrd
      `,
  },
];
