import React from "react";
import ComingSoon from "common/Components/ComingSoon";
import { Container } from "../../style/display";

const SalarySlip = () => {
  return (
    <Container>
      <ComingSoon />
    </Container>
  );
};

export default SalarySlip;
