import requestData from "../../ApiHandler";
import { appendArrayToUrl, createAuthUrl } from "helper";

export const getAllCalendarsApi = ({ companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/calenders`,
  });
  return requestData(authUrl, {
    method: "GET",
  });
};

export const getMonthScheduleApi = ({
  fromDate,
  toDate,
  calendarIds,
  companyId,
  isAdmin,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/fetch_calender_events?from_date=${fromDate}&to_date=${toDate}`,
  });
  const idsUrl = appendArrayToUrl(authUrl, "calender_ids[]", calendarIds);
  return requestData(idsUrl, {
    method: "GET",
  });
};

export const createCalendarApi = ({
  formData,
  isAdmin,
  companyId,
  userList,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/calenders`,
  });

  const {
    calendarCreate_name,
    calendarCreate_color,
    calendarCreate_description,
    calendarCreate_isPublic,
  } = formData;

  const body = {
    calender: {
      name: calendarCreate_name,
      description: calendarCreate_description,
      is_public: Boolean(Number(calendarCreate_isPublic)),
      color: calendarCreate_color,
      collaborators: userList,
    },
  };
  return requestData(authUrl, {
    method: "POST",
    body,
    isFormData: true,
  });
};

export const createCalendarEventApi = ({
  formData,
  isAdmin,
  companyId,
  userList,
}) => {
  const url = `/companies/${companyId}/calender_events`;
  const authUrl = createAuthUrl({ isAdmin, url });

  const {
    title,
    description,
    startAt,
    endAt,
    calendarId,
    venue,
    latitude,
    longitude,
    meetingLink,
  } = formData;

  const body = {
    calender_event: {
      title,
      description,
      start_at: startAt,
      end_at: endAt,
      calender_id: calendarId,
      meeting_link: meetingLink,
      venue,
      lattitude: latitude,
      longitude,
      ...(userList && userList.length > 0
        ? { calender_event_guest_ids: userList }
        : {}),
    },
  };
  return requestData(authUrl, {
    method: "POST",
    body,
    isFormData: true,
  });
};
