import React, { useState } from "react";
import { useParams } from "react-router";
import WorkshiftDetailCreateView from "./Create";
import WorkshiftDetailView from "./Display";

const WorkshiftDetails = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const updateFormVisibility = (status) => {
    setIsFormVisible(status);
  };
  const params = useParams();
  if (isFormVisible) {
    return (
      <WorkshiftDetailCreateView updateFormVisibility={updateFormVisibility} />
    );
  }
  return <WorkshiftDetailView updateFormVisibility={updateFormVisibility} />;
};

export default WorkshiftDetails;
