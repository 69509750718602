import React, { useMemo, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { ThemeContext } from "./Context/ThemeContext";
import { createTheme } from "./Theme";
import store from "./Store";
import RootRoute from "./Routes";
import Alert from "components/Alert";
import NetworkStatus from "components/NetworkStatus";

import { userThemeType, globalThemeType } from "types/index";

import "./App.css";
import "./OverridesCss/global.css";

interface IAppWrapperProps {
  children: React.ReactNode;
}

export function AppWrapper({ children }: IAppWrapperProps) {
  const [currentTheme, updateTheme] = useState<userThemeType>("admin");
  const theme: globalThemeType = useMemo(() => {
    return createTheme(currentTheme === "admin" || currentTheme === undefined);
  }, [currentTheme]);

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeContext.Provider value={updateTheme}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>{children}</BrowserRouter>
          </ThemeProvider>
        </ThemeContext.Provider>
      </Provider>
    </div>
  );
}

function App() {
  return (
    <AppWrapper>
      <RootRoute />
      <Alert />
      <NetworkStatus />
    </AppWrapper>
  );
}

export default App;
