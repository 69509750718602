import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";

import Grid from "common/Components/Grid";
import { createTableDataSelector } from "common/Selectors/MTable";

import { TableContainer } from "globalStyles";
import RenderItem from "./RenderItem";

const TableData = ({ data, onCellClick, cellNameToColumnMap, rowIdMap }) => {
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);

  const onClick = useCallback(
    ({ rowIndex }) => {
      if (typeof onCellClick === "function") {
        onCellClick({ rowIndex });
      }
    },
    [onCellClick, rowIdMap]
  );

  return (
    <TableContainer>
      <Grid
        onClick={onCellClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        data={{
          rowData: data,
          cellNameToColumnMap,
          onIdClick: onClick
        }}
        dataKey="rowData"
      >
        {RenderItem}
      </Grid>
    </TableContainer>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "followSubordinate.attendance",
    blackList: { "Sl No": true, dailyWorkReport: true },
    reverse: false
  });
  const maker = (store, props) => {
    const { rows, cellNameToColumnMap, rowIdMap } = getUserRows(store, props);
    return {
      data: rows,
      cellNameToColumnMap,
      rowIdMap
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
