import { createSelector } from "reselect";

const leavetypeDataIds = (store) => store.leaveTypes.allIds;
const leavetypeData = (store) => store.leaveTypes.allData;

export const getAllLeaveType = createSelector(
  leavetypeDataIds,
  leavetypeData,
  (ids, data) =>
    ids && data && ids.map((id) => ({ ...data[id].attributes, id })),
);
export const leaveTypeDataSelector = createSelector(
  leavetypeData,
  (_, props) => props.id,
  (data, id) => {
    return data[id] ? data[id].attributes : {};
  },
);
