import {
  CLEAR_SEARCH_WORK_REPORTS_DATA,
  GET_NOTIFIED_WORK_REPORTS,
  SAVE_SEARCH_WORK_REPORTS_DATA,
  FILTERED_SEARCH_WORK_REPORTS,
} from "../actionTypes";

import { createNewStoreOnGetData, createInitialStoreState } from "helper";

const initialSearchData = {
  fromDate: "",
  toDate: "",
  collaboratorIds: [],
};

const initialState = createInitialStoreState({
  prevData: {},
  isSearchOn: false,
  searchData: initialSearchData,
});

export const notifiedWorkReportsReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_NOTIFIED_WORK_REPORTS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    case `${FILTERED_SEARCH_WORK_REPORTS}_SUCCESS`: {
      let prevData;
      //  only updating the prev data at the first search occurence
      if (Object.keys(store.prevData).length === 0) {
        prevData = { ...store };
      } else {
        prevData = store.prevData;
      }

      const searchData = createNewStoreOnGetData(payload, store, true);
      return {
        ...searchData,
        prevData,
      };
    }

    case CLEAR_SEARCH_WORK_REPORTS_DATA: {
      return {
        ...store,
        ...store.prevData,
        searchData: initialSearchData,
        prevData: {},
        isSearchOn: false,
      };
    }

    case SAVE_SEARCH_WORK_REPORTS_DATA: {
      const { fromDate, toDate, collaboratorIds } = payload;
      return {
        ...store,
        searchData: {
          fromDate,
          toDate,
          collaboratorIds,
        },
        isSearchOn: true,
      };
    }

    default:
      return store;
  }
};
