import { useSelector } from "react-redux";
import { getCurrentUserData } from "../Common/Selectors/auth";

const useUserInformation = () => {
  const userInfo = useSelector(getCurrentUserData);
  return {
    userInfo,
  };
};

export default useUserInformation;
