import { useReducer } from "react";

import { ACTIVATE_TAB, RESET_TABS } from "./actionTypes";

let outerDispatch = null;
const initialStore = {
  activeTabId: "",
};

const tabStore = (store, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIVATE_TAB: {
      const { id } = payload;
      return {
        ...store,
        activeTabId: id,
      };
    }

    case RESET_TABS: {
      return { ...store, activeTabId: "" };
    }
    default:
      return store;
  }
};

export const dispatchTabAction = (action, payload) => {
  outerDispatch && outerDispatch({ type: action, payload });
};

export function useTab(reducer, onActionDispatch, initialData) {
  const [store, dispatch] = useReducer(
    reducer || tabStore,
    initialData || initialStore
  );

  outerDispatch = dispatch;

  const dispatchAction = (action, payload) => {
    dispatch({ type: action, payload });
    onActionDispatch && onActionDispatch(action, payload);
  };

  const activateTab = (id) => {
    dispatchAction(ACTIVATE_TAB, { id });
  };
  const resetTabs = () => {
    dispatchAction(RESET_TABS);
  };

  return { store, activateTab, resetTabs };
}
