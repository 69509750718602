import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import useAlert from "hooks/useAlert";
import useForm from "../../../../Hooks/useForm";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";
import { createCompanyBranch, editCompanyBranch } from "../../actionCreator";

import { FormHeading, StyledForm } from "../../styles/create";

import { generateFormData } from "../../utils";
import Routes from "route/Path";

import InputGroup from "../../../../Common/Components/InputGroup/inputGroup2";
import Button from "components/Button";
import { headquarterOptions } from "./options";
import { validateNumber } from "../../../../Helper";
import { StyledBackButton } from "modules/Holiday/styles/create";
import { useHistory } from "react-router-dom";
import { companyBranchDataSelector } from "modules/CompanyBranch/selector";

const Form = ({
  companyId,
  isAdmin,
  createCompanyBranch,
  editId,
  editCompanyBranch,
}) => {
  const [isLoading, setLoading] = useState("");
  const history = useHistory();
  const { showAlert } = useAlert();
  const companyBranchData = useSelector((store) =>
    companyBranchDataSelector(store, { id: editId })
  );
  const isEditMode = !!editId;

  const goBack = () => {
    history.replace(Routes.authorized.companyBranch.view.path, {});
  };
  const { formData, values, isFormValid, resetForm, updateField } = useForm({
    fields: {
      name: {
        required: true,
      },

      address1: {
        required: true,
      },
      address2: {},
      pincode: {
        required: true,
        validator: validateNumber,
        invalidMessage: "Invalid Pincode",
      },
      city: {
        required: true,
      },
      state: {
        required: true,
      },
      country: {
        required: true,
      },
      isHeadquarter: {},
    },
  });

  const onFormSubmit = useCallback(
    async (e) => {
      const action = isEditMode ? editCompanyBranch : createCompanyBranch;
      e.preventDefault();
      if (!isFormValid()) {
        return;
      }
      try {
        setLoading(true);
        const formData = generateFormData(values);
        await action({ branchId: editId, companyId, isAdmin, formData });
        showAlert({
          message: `Company branch ${editId ? "updated" : "created"}`,
        });

        if (isEditMode) {
          goBack();
          return;
        }
        resetForm();
      } catch (err) {
        showAlert({ message: err[0], type: "error" });
      } finally {
        setLoading(false);
      }
    },
    [companyId, isEditMode, editId, values]
  );

  useEffect(() => {
    if (companyBranchData) {
      updateField("name", companyBranchData?.name);
      updateField("address1", companyBranchData?.addressLine1);
      updateField("address2", companyBranchData?.addressLine2);
      updateField("pincode", companyBranchData?.pincode);
      updateField("city", companyBranchData?.city);
      updateField("state", companyBranchData?.state);
      updateField("country", companyBranchData?.country);
      updateField("isHeadquarter", companyBranchData?.isHeadquarter ? {is_headquarter: true} : "");
    }
  }, [companyBranchData]);

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <FormHeading>
        {isEditMode ? "Edit Company Branch" : "Create new one"}
        {isEditMode && <StyledBackButton onLeft showIcon onClick={goBack} />}
      </FormHeading>

      <InputGroup
        label="Name of branch"
        placeholder="Name of company branch"
        {...formData["name"]}
      />
      <InputGroup
        label="Address line 1"
        placeholder="Apartment / Building No."
        {...formData["address1"]}
      />
      <InputGroup
        label="Address Line 2"
        placeholder="Street / Locality name"
        {...formData["address2"]}
      />
      <InputGroup
        label="Pincode"
        placeholder="Enter pincode"
        {...formData["pincode"]}
      />
      <InputGroup label="City" placeholder="Enter city" {...formData["city"]} />
      <InputGroup
        label="State"
        placeholder="Enter State"
        {...formData["state"]}
      />
      <InputGroup
        label="Country"
        placeholder="Enter country"
        {...formData["country"]}
      />
      <InputGroup
        fieldType="checkboxGroup"
        groupName="isHeadQuarter"
        options={headquarterOptions}
        {...formData["isHeadquarter"]}
      />

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        {editId ? "Edit" : "Create"}
      </Button>
    </StyledForm>
  );
};
const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    isAdmin,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createCompanyBranch,
      editCompanyBranch,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Form);
