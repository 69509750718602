import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GridCell from "components/Grid/GridCell";
import { createTableDataSelector } from "common/Selectors/MTable";

import { generateInitialData } from "helper";

import { Cell, UpdateButton, CellIcon } from "../../style/display";
import { resetToPreviousData, setCurrentUserId } from "../../actionCreator";

const RenderItem = ({ data, columnIndex, rowIndex, style, rowIds, setCurrentUserId, resetToPreviousData }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    onIdClick,
    cellNameToColumnMap,
    toggleModal,
    updateCurrentModalData,
  } = data;

  const isUpdateColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["Update"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const isFirstColumn = useMemo(() => {
    return columnIndex === 0;
  }, [columnIndex]);

  const setInitialData = useCallback(
    (e) => {
      e.stopPropagation();
      const mapping = [
        { coulmnName: "name", label: "collab_name" },
        { coulmnName: "phone", label: "collab_phone" },
        { coulmnName: "workMail", label: "collab_workMail" },
        { coulmnName: "status", label: "collab_status" },
        { coulmnName: "collaboratorType", label: "collab_type" },
        { coulmnName: "isExecutive", label: "collab_isExecutive" },
        { coulmnName: "roleType", label: "collab_role" },
        { coulmnName: "joinedOn", label: "collab_joinedOn" },
        { coulmnName: "leftOn", label: "collab_leftOn" },
      ];
      const data = {
        userId: rowData[rowIndex][0],
        initialData: generateInitialData(
          rowData[rowIndex],
          cellNameToColumnMap,
          mapping
        ),
      };
      updateCurrentModalData(data);
      toggleModal();
    },
    [rowIndex, rowData, cellNameToColumnMap, toggleModal]
  );

  const onFirstColumnClick = useCallback(() => {
    if (typeof onIdClick === "function") {
      onIdClick({ data: rowData[rowIndex], columns: rowData[0], rowIndex });
      resetToPreviousData();
      setCurrentUserId({ userId: rowIds[rowIndex] });
    }
  }, [onIdClick, rowData, rowIndex]);

  const myChildren = useMemo(() => {
    if (isFirstColumn) {
      return (
        <Cell onClick={onFirstColumnClick} style={{ cursor: "pointer" }}>
          {rowData[rowIndex][0]}
          <CellIcon name="fas fa-arrow-right" />
        </Cell>
      );
    }
    if (isUpdateColumn) {
      return (
        <Cell>
          <UpdateButton onClick={setInitialData} size="auto">
            Update
          </UpdateButton>
        </Cell>
      );
    }

    return null;
  }, [isUpdateColumn, isFirstColumn, onFirstColumnClick]);

  return (
    <>
      <GridCell
        row={rowIndex}
        showHeading={showHeading}
        key={`${rowIndex}_${columnIndex}`}
        columnName={rowData[0][columnIndex]}
        isInActiveRow={currentActiveRow === rowIndex}
        activateRow={activateRow}
        data={
          rowData[rowIndex] && rowData[rowIndex][columnIndex]
            ? rowData[rowIndex][columnIndex]
            : ""
        }
        style={style}
      >
        {myChildren}
      </GridCell>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentUserId,
      resetToPreviousData,
    },
    dispatch
  );

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "user",
  });
  const maker = (store, props) => {
    const { rowIdMap } = getUserRows(store, props);
    return {
      rowIds: rowIdMap,
    };
  };
  return maker;
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderItem);
