import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import AccessAssetsCreateView from "../../../Modules/CompanyAssets/Views/Create";
import AccessAssetsDisplayView from "../../../Modules/CompanyAssets/Views/Display";

import { createRouteObject } from "../../../Helper";

const { accessAssets } = RoutePaths.authorized;

const pages = createRouteObject(
  accessAssets,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [AccessAssetsCreateView, AccessAssetsDisplayView, AccessAssetsCreateView]
);

const AccessAssetsPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact={item.slugPath ? false : true}
        path={item.slugPath || item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default AccessAssetsPages;
