import {
  CREATE_REIMBURSEMENT,
  GET_REIMBURSEMENTS,
  SEARCH_REIMBURSEMENT,
  UPDATE_SEARCH_PARAMS,
  RESET_SEARCH_PARAMS
} from "./actiontypes";

import { createAsyncActionCreator } from "../../Helper";

import { createReimbursementApi, getReimbursementsApi, searchReimbursementApi } from "./api";

const createReimbursementGenrator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_REIMBURSEMENT,
    sideEffect: createReimbursementApi({ companyId, isAdmin, formData }),
  };
};

const getReimbursementsGenerator = function* ({
  companyId,
  pageNumber,
  isAdmin,
}) {
  yield {
    action: GET_REIMBURSEMENTS,
    sideEffect: getReimbursementsApi({ companyId, pageNumber, isAdmin }),
  };
};

const searchReimbursementGenerator = function* ({ companyId, pageNumber, isAdmin, collaboratorId, expenseType, currentFromDate, currentToDate, currentStatus }) {
  yield {
    action: SEARCH_REIMBURSEMENT,
    sideEffect: searchReimbursementApi({ companyId, pageNumber, isAdmin, collaboratorId, expenseType, currentFromDate, currentToDate, currentStatus }),
  };
};

export const updateSearchParams = ({ filterData }) => ({
  type: UPDATE_SEARCH_PARAMS,
  payload: filterData
})

export const resetSearchParams = () => ({
  type: RESET_SEARCH_PARAMS
})

export const [
  createReimbursement,
  getReimbursements,
  searchReimbursement
] = createAsyncActionCreator([
  createReimbursementGenrator,
  getReimbursementsGenerator,
  searchReimbursementGenerator
]);
