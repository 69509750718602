import React, { useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";

import { PollContainer, PollButton, PollFooter, PollHeader } from "./style";

export function PostQuiz({
  question,
  // desc,
  // votes,
  // timeLeft,
  options,
  onAnswere,
  enabled,
  answeredValue,
  correctOptionValue,
  ...rest
}) {
  const [quizResult, setQuizResult] = useState({
    active: !!answeredValue,
    correctOption: correctOptionValue,
    selectedOption: answeredValue,
  });

  const prevValueRef = useRef({});

  const onAnswereComplete = (success = false) => {
    if (success) {
      return;
    }
    setQuizResult((prevData) => ({
      ...prevData,
      ...(prevValueRef.current || {}),
    }));
  };

  const onPolled = (value) => {
    if (!enabled) {
      return;
    }
    setQuizResult((prevVal) => {
      prevValueRef.current = prevVal;
      return {
        ...prevVal,
        active: true,
        selectedOption: value,
      };
    });
    onAnswere && onAnswere(value, onAnswereComplete);
  };

  const polls = useMemo(() => {
    if (!options) {
      return null;
    }
    return options.map(({ name, value }) => {
      const isCorrect = quizResult.active && quizResult.correctOption === value;
      const isWrong =
        quizResult.active &&
        quizResult.selectedOption === value &&
        value !== quizResult.correctOption;

      const valueType = isCorrect ? "correct" : isWrong ? "wrong" : "";

      return (
        <PollButton
          style={{
            cursor: enabled ? "pointer" : "not-allowed",
            pointerEvents: quizResult.active ? "none" : "",
          }}
          key={`${name}_${value}`}
          onClick={!quizResult.active ? () => onPolled(value) : null}
          $isCorrect={isCorrect}
          $isWrong={isWrong}
          data-value={valueType}
        >
          {name}
        </PollButton>
      );
    });
  }, [options, onPolled, enabled, quizResult]);

  return (
    <PollContainer style={{ border: "none" }} {...rest}>
      <PollHeader>
        <span className="title">{question}</span>
        {/* <span className="desc">{desc}</span> */}
      </PollHeader>

      {polls}
      <PollFooter>
        {/* <span className="foot-item">{votes} votes</span> */}
        {/* <span className="foot-item">{timeLeft} left</span> */}
      </PollFooter>
    </PollContainer>
  );
}

PostQuiz.propTypes = {
  question: PropTypes.string.isRequired,
  // desc: PropTypes.string.isRequired,
  // votes: PropTypes.number.isRequired,
  // timeLeft: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onAnswere: PropTypes.func,
};
