import Correct_Img from "assets/correct.svg";
import InCorrect_Img from "assets/Incorrect.svg";
import Info_Img from "assets/info.svg";
import Warning_Img from "assets/warning.svg";

export const getIconName = (type) => {
  switch (type) {
    case "success":
      return Correct_Img;
    case "error":
      return InCorrect_Img;
    case "warning":
      return Warning_Img;
    case "info":
      return Info_Img;
    default:
      return "";
  }
};
