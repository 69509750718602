import React, { useMemo } from "react";
import Icon from "components/Icon";
import {
  Text,
  StyledProfilePic,
  ProfileContent,
  LoaderImage,
  LoaderText,
  ProfilePicContainer,
} from "./style";
import { getImageUrl, getRandomColor } from "../../../Helper";

export const UserProfile = ({ item, isLoading }) => {
  const randomColor = useMemo(() => {
    return getRandomColor();
  }, []);

  const workOrigin = useMemo(
    () => item.workLog?.workOrigin?.toLowerCase(),
    [item]
  );

  return (
    <ProfileContent title={item.name}>
      {isLoading ? (
        <LoaderImage />
      ) : (
        <ProfilePicContainer>
          <StyledProfilePic
            className="profile-pic"
            color={randomColor}
            img={getImageUrl(item.avatarUrl?.url)}
            userName={item.name}
          />
          {workOrigin === "from home" && (
            <Icon name="fas fa-home" className="icon" />
          )}
          {workOrigin === "with travel" && (
            <Icon name="fas fa-plane" className="icon" />
          )}
        </ProfilePicContainer>
      )}
      {isLoading ? (
        <LoaderText />
      ) : (
        <Text className="username-text">{item.name}</Text>
      )}
    </ProfileContent>
  );
};
