import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";

import Grid from "components/Grid";
import RenderItem from "./RenderItem";

import { createTableDataSelector } from "common/Selectors/MTable";

import { TableContainer } from "../../../../GlobalStyles";
import { isCurrentUserIsExecutive } from "../../../../Common/Selectors/auth";

const TableData = ({
  data,
  onCellClick,
  cellNameToColumnMap,
  onEmptyData,
  ...other
}) => {
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);
  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (rows === 1) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);
  return (
    <TableContainer {...other}>
      <Grid
        onClick={onCellClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        data={{
          rowData: data,
          cellNameToColumnMap,
        }}
        dataKey="rowData"
      >
        {RenderItem}
      </Grid>
    </TableContainer>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const subordinateId = store.breaks.subordinateSnaps.collaboratorId;
  const getUserRows = createTableDataSelector({
    tableName: isAdmin
      ? "breaks.snaps"
      : subordinateId
      ? "breaks.subordinateSnaps"
      : "breaks.collaboratorSnaps",
    blackList: { "Sl No": true },
    reverse: false,
  });
  const { rows, cellNameToColumnMap } = getUserRows(store, props);
  return {
    data: rows,
    cellNameToColumnMap,
  };
};

export default connect(mapStateToProps, null)(TableData);
