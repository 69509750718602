import React, { useMemo } from "react";

import Icon from "../Icon";
import InsertInDom from "../InsertInDom";

import useAlert from "hooks/useAlert";
import { getIconName } from "./utils";

import {
  AlertContainer,
  CloseButton,
  Description,
  IconBox,
  AlertContent,
  AlertIconArea,
  Heading,
} from "./style";

const Alert = () => {
  const { alertsList, hideAlert } = useAlert();
  const alertsData = useMemo(() => {
    return alertsList.map(
      ({ verticalPosition, horizontalPosition, type, message, id }, index) => {
        /**
         * vertical position : one of ['top', 'bottom']
         * horizontal position : one of ['left', 'right']
         */
        const distanceStyle = {
          [verticalPosition]: `${index * 60 + 15 * (index + 1)}px`,
          animationDelay: `${index * 250}ms`,
        };

        return { horizontalPosition, distanceStyle, message, id, type };
      }
    );
  }, [alertsList]);

  return alertsList.length > 0 ? (
    <InsertInDom blockScroll={false} domId="alert">
      {alertsData.map(
        ({ distanceStyle, horizontalPosition, message, id, type }) => {
          return (
            <AlertContainer
              key={id}
              type={type}
              style={distanceStyle}
              $horizontal={horizontalPosition}
              data-testid="alert"
            >
              <CloseButton onClick={() => hideAlert(id)}>
                <Icon name="fas fa-times" />
              </CloseButton>

              <AlertIconArea type={type}>
                <IconBox src={getIconName(type)} type={type}></IconBox>
              </AlertIconArea>

              <AlertContent>
                <Heading>{type}</Heading>
                <Description>{message}</Description>
              </AlertContent>
            </AlertContainer>
          );
        }
      )}
    </InsertInDom>
  ) : null;
};

export default Alert;
