import requestData from "../../ApiHandler";
import { createAuthUrl } from "../../Helper";

export const getCompanyBranchApi = ({ isAdmin, companyId, pageNumber }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/company_branches?page=${pageNumber}`,
  });
  return requestData(url, { method: "GET" });
};

export const editCompanyBranchApi = ({
  branchId,
  isAdmin,
  companyId,
  formData,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/company_branches/${branchId}`,
  });
  return requestData(url, {
    method: "PUT",
    body: {
      company_branch: formData,
    },
    isFormData: true,
  });
};

export const createCompanyBranchApi = ({ isAdmin, companyId, formData }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/company_branches`,
  });
  return requestData(url, {
    method: "POST",
    body: {
      company_branch: formData,
    },
    isFormData: true,
  });
};
