import {
  CREATE_LEAVE,
  GET_ALL_CREATED_LEAVES,
  GET_ALL_COLLABORATOR_LEAVES
} from "../actiontypes";

import {
  CANCEL_LEAVE,
  CREATE_LEAVE_COMMENT
} from "modules/LeaveManagement/redux/actionTypes";

import {
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnUpdate
} from "helper";

import { DELETE_COMMENT, EDIT_COMMENT } from "common/ActionTypes";

const initialState = createInitialStoreState({ collaboratorLeaves: {} }, true);

export const leaveRequisitionReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_ALL_CREATED_LEAVES}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    case `${CREATE_LEAVE}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true);
    }

    case `${GET_ALL_COLLABORATOR_LEAVES}_SUCCESS`: {
      return {
        ...store,
        collaboratorLeaves: payload
      };
    }

    case `${CANCEL_LEAVE}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store, true);
    }

    case `${CREATE_LEAVE_COMMENT}_SUCCESS`: {
      if (payload?.status == "view_leave_details") {
        return createNewStoreOnUpdate(payload, store, true);
      }
      return store;
    }

    case `${DELETE_COMMENT}_SUCCESS`:
    case `${EDIT_COMMENT}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store, true);
    }

    default:
      return store;
  }
};
