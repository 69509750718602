import styled from "styled-components";
import { flexRowCenter } from "../../../GlobalStyles/flex";
import FormGroup from "../../../Common/Components/FormGroup";

export const HeadingBox = styled(flexRowCenter)`
  border-bottom: 1px lightgray solid;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 15px;
`;

export const Heading = styled.h2`
  font-size: ${(props) => props.theme.text.title.large};
`;
export const Container = styled(flexRowCenter)`
  min-height: 100vh;
`;

export const Form = styled(FormGroup)`
  width: 80%;
  display: flex;
  max-width: 500px;
  flex-flow: column nowrap;
  background-color: white;
  border-radius: 6px;
  box-shadow: ${(props) => props.theme.shadow.medium};
  padding: 20px;
  align-items: center;
`;
