import React, { useCallback, useEffect, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  clearNoticeId,
  setNoticeId,
  updateDraftNotice
} from "../../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive
} from "../../../../../Common/Selectors/auth";
import useAlert from "../../../../../Hooks/useAlert";
import {
  MainContainer,
  NoticeDraftHeading,
  NoticeDescriptionInputGroup,
  TextAreaLengthCont,
  StyledFormGroups
} from "../../../styles/draftNotice";
import InputGroup from "../../../../../Common/Components/InputGroup";
import Button from "../../../../../Common/Components/Button";
import Icon from "../../../../../Common/Components/Icon";
import Publish from "../../Create/Publish";
import { StyledInputGroups } from "../../../../../GlobalStyles";

const DraftNoticePage = ({
  onSubmit,
  noticeData,
  companyId,
  isAdmin,
  updateDraftNotice,
  setNoticeId,
  clearNoticeId
}) => {
  const {
    noticeId,
    noticeTitle: title,
    noticeBody: body,
    documents
  } = noticeData;
  const [isLoading, updateLoading] = useState(false);
  const [isFormSent, updateFormStatus] = useState(false);
  const [noticeTitle, setNoticeTitle] = useState(title);
  const [noticeDescription, setNoticeDescription] = useState(body);
  const [noticeDocuments, setNoticeDocuments] = useState(documents);
  const [isFormPendingSubmit, updateisFormPendingSubmit] = useState(true);
  const { showAlert } = useAlert();

  useEffect(() => {
    return () => {
      clearNoticeId();
    };
  }, []);

  useEffect(() => {
    if (!isFormPendingSubmit) {
      onSubmit();
    }
  }, [isFormPendingSubmit, onSubmit]);

  const onChange = useCallback((e) => {
    const name = e.target.name;
    if (name === "title") {
      setNoticeTitle(e.target.value);
    }
    if (name === "description") {
      setNoticeDescription(e.target.value);
    }
    if (name === "documents") {
      setNoticeDocuments(e.target.value);
    }
  }, []);

  const initialDocuments = useMemo(() => {
    return (
      (documents != "NA" &&
        documents.map((file) => {
          return {
            name: file?.fileName,
            url: file?.url,
            id: file?.id
          };
        })) ||
      []
    );
  }, [documents]);

  const slicedNoticeDescription = useMemo(
    () => noticeDescription.slice(0, 1500),
    [noticeDescription]
  );

  const hasChanged = useMemo(() => {
    if (
      noticeTitle != title ||
      noticeDescription != body ||
      noticeDocuments != documents
    ) {
      return true;
    }
    return false;
  }, [title, body, documents, noticeTitle, noticeDescription, noticeDocuments]);

  const onFormSubmit = useCallback(() => {
    setNoticeId({ noticeId });
    updateFormStatus(true);
  }, [noticeId]);

  const onFormUpdate = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        setNoticeId({ noticeId });
        const {
          draftNotice_noticeTitle,
          draftNotice_noticeDescription,
          draftNotice_noticeDocuments
        } = values;

        const prevFiles = Array.isArray(documents)
          ? documents.map((file) => {
              return {
                name: file.fileName,
                url: file.url,
                id: file.id
              };
            })
          : [];
        const purgedFiles = prevFiles?.filter(
          (prevFile) =>
            !draftNotice_noticeDocuments?.some((newFile) => {
              return prevFile.id == newFile.id;
            })
        );
        let purge_files_ids = [];
        if (purgedFiles?.length > 0) {
          purgedFiles.forEach((item) => {
            if (item?.id) {
              purge_files_ids.push(item.id);
            }
          });
        }

        const formData = {
          title: draftNotice_noticeTitle,
          body: draftNotice_noticeDescription,
          documents: draftNotice_noticeDocuments.filter((file) => {
            if (!file?.url) {
              return file;
            }
          }),
          purge_documents_ids: purge_files_ids
        };

        await updateDraftNotice({ companyId, noticeId, isAdmin, formData });
        showAlert({ message: "Notice Updated" });
        updateLoading(false);
        updateFormStatus(true);
      } catch (err) {
        showAlert({ message: "Notice updation failed", type: "error" });
        updateLoading(false);
      }
    },
    [companyId, noticeId, documents, isAdmin]
  );

  return (
    <MainContainer>
      {isFormSent ? (
        <Publish updateIsFormSubmitted={updateisFormPendingSubmit} />
      ) : (
        <StyledFormGroups onSubmit={hasChanged ? onFormUpdate : onFormSubmit}>
          <NoticeDraftHeading>Draft Notice</NoticeDraftHeading>

          <StyledInputGroups>
            <InputGroup
              id="draftNotice_noticeTitle"
              fieldType="input"
              label="Notice title"
              initialValue={noticeTitle}
              name="title"
              onChange={onChange}
            />
          </StyledInputGroups>

          <NoticeDescriptionInputGroup>
            <InputGroup
              id="draftNotice_noticeDescription"
              fieldType="textArea"
              label="Notice Description"
              size="auto"
              fillParent={true}
              initialValue={noticeDescription}
              value={slicedNoticeDescription}
              name="description"
              onChange={onChange}
            />
          </NoticeDescriptionInputGroup>

          <TextAreaLengthCont>
            {slicedNoticeDescription.length}/1500 characters
          </TextAreaLengthCont>

          <InputGroup
            id="draftNotice_noticeDocuments"
            fieldType="googleFilePicker"
            multiple={true}
            label="Documents"
            initialValue={initialDocuments}
            onChange={onChange}
            name="documents"
          />

          <Button
            primary
            type="submit"
            size="lg"
            loading={isLoading}
            disabled={isLoading}
          >
            {hasChanged ? "Update and Send" : "Send"}&nbsp;{" "}
            <Icon name="fas fa-arrow-right" />
          </Button>
        </StyledFormGroups>
      )}
    </MainContainer>
  );
};
const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    isAdmin
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setNoticeId,
      clearNoticeId,
      updateDraftNotice
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(DraftNoticePage);
