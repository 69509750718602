import React from "react";
import PropTypes from "prop-types";

import { Container, Text } from "./style";

const Status = ({ text, type, children, className, ...otherProps }) => {
  return (
    <Container $type={type} {...otherProps} className={className}>
      {!!text && <Text>{text}</Text>}
      {children}
    </Container>
  );
};

Status.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(["pending", "approved", "success", "error", ""])
    .isRequired,
  className: PropTypes.string,
};

Status.defaultProps = {
  text: "",
  className: "",
};
export default Status;
