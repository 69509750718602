import { LeaveContainer } from "../../../style/display";
import { ILeavesContentProps } from "./types";

const LeavesContent = ({
  assignedQuota,
  leavePlan,
  leaveRequisitionCount,
}: ILeavesContentProps) => {
  return (
    <LeaveContainer>
      {leavePlan.leaveType}: {leaveRequisitionCount || 0} / {assignedQuota}
    </LeaveContainer>
  );
};

export default LeavesContent;
