import React, { useMemo } from "react";
import ActivityIndicator from "../ActivityIndicator";

import {
  PrimaryButton,
  SecondaryButton,
  StyledButton,
  OutlineButton,
} from "./style";

const Button = React.forwardRef(
  (
    {
      children,
      loading = false,
      primary = false,
      secondary = false,
      outline = false,
      size = "md",
      indicatorSize = "s",
      className,
      ...otherProps
    },
    ref
  ) => {
    const getIndicatorColor = () => {
      if (primary) {
        return "white";
      }
      return "#007bff";
    };

    const CustomButton = useMemo(() => {
      if (primary) {
        return PrimaryButton;
      }
      if (secondary) {
        return SecondaryButton;
      }

      if (outline) {
        return OutlineButton;
      }
      return StyledButton;
    }, [primary, secondary, outline]);

    return (
      <CustomButton className={className} size={size} ref={ref} {...otherProps}>
        {loading ? (
          <ActivityIndicator color={getIndicatorColor()} size={indicatorSize} />
        ) : (
          children
        )}
      </CustomButton>
    );
  }
);

export default Button;
