import { FETCH_ALL_REACTIONS, RESET_REACTIONS_POST_DATA } from "./actionTypes";
import { createNewStoreOnGetData, createInitialStoreState } from "helper";

const initialState = createInitialStoreState({ reactionsCount: {} }, true);

function celebrateReducer(store = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_ALL_REACTIONS}_SUCCESS`: {
      if (payload?.reactionsType === "celebrate") {
        const { meta } = payload;
        const reactionsCount = meta?.reactionsCount;
        const data = createNewStoreOnGetData(payload, store, true);
        return { ...data, reactionsCount };
      }
      return store;
    }

    case RESET_REACTIONS_POST_DATA: {
      return initialState;
    }

    default:
      return store;
  }
}

export default celebrateReducer;
