import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";

import CommentsModal from "./CommentsModal";
import { InfoCard, PostCreateCard } from "./Cards";

import ActivityIndicator from "components/ActivityIndicator";
import EmptyData from "components/EmptyData";

import useIntersectionObserver from "hooks/useIntersectionObserver";
import { usePostFunctionality } from "../../hooks/Posts/usePostFunctionality";

import { getCurrentUserId } from "common/Selectors/auth";

import {
  PostsContainer,
  PostLoader,
  AnimatedPostCardSkeleton,
  AnimatedPostCard,
} from "../../styles/employee";

function Posts({ data, fetchNewPosts, allPostsLoaded, errorInFetch }) {
  const {
    postActionBtnProps,
    createPollData,
    createQuizData,
    getCreateReactionProps,
    onComment,
    onCloseMoreComments,
    onLoadMoreComments,
    isCreatingComment,
    commentPostId,
    commentsModal,
  } = usePostFunctionality();

  const currentCollaboratorId = useSelector(getCurrentUserId);

  const [connectObserver, removeObserver] = useIntersectionObserver({
    callback: fetchNewPosts,
  });

  const postsList = useMemo(() => {
    if (!data) {
      return null;
    }
    return data.map((postData, index) => {
      const quizData = createQuizData(postData);
      const pollData = createPollData(postData);
      return (
        <AnimatedPostCard
          onLoadMoreComments={onLoadMoreComments}
          onComment={onComment}
          isCreatingComment={isCreatingComment && commentPostId == postData?.id}
          key={`${postData.id}_${index}`}
          getCreateReactionProps={getCreateReactionProps}
          {...postData}
          quizData={quizData}
          pollData={pollData}
          postActionBtnProps={
            currentCollaboratorId == postData.collaboratorId &&
            postActionBtnProps
          }
        />
      );
    });
  }, [data, isCreatingComment, onComment]);

  useEffect(() => {
    if (postsList.length === 0) {
      return;
    }
    connectObserver({ targetId: "#post_laoder" });
    return removeObserver;
  }, [postsList]);

  useEffect(() => {
    if (allPostsLoaded || errorInFetch) {
      removeObserver();
    }
  }, [allPostsLoaded, errorInFetch]);

  return (
    <PostsContainer className="d-f f-c">
      <InfoCard />
      <PostCreateCard />
      {errorInFetch && <EmptyData message="Something went wrong" />}
      {postsList.length === 0 && !errorInFetch && <PostsSkeleton count={5} />}
      {!errorInFetch && postsList}
      <PostLoader id="post_laoder">
        {!allPostsLoaded && !errorInFetch && <ActivityIndicator size="s" />}
      </PostLoader>
      {commentsModal.active && (
        <CommentsModal
          onClose={onCloseMoreComments}
          postId={commentsModal.postId}
        />
      )}
    </PostsContainer>
  );
}

export default Posts;

function PostsSkeleton({ count }) {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(
      <AnimatedPostCardSkeleton
        key={i}
        style={{
          height: "200px",
          animationDelay: `${(i + 1) * 100}ms`,
        }}
      />
    );
  }
  return result;
}
