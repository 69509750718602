import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import {
  AdjustableHeightContainer,
  StyledIcon,
  StyledParagraph,
  StyledButton,
} from "../styles";

const PageTwo = ({ wasItSuccessful, setNextPageStatus }) => {
  const history = useHistory();

  const { iconClassName, text, buttonText, toHome } = useMemo(() => {
    let iconClassName, text, buttonText, toHome;
    if (wasItSuccessful) {
      iconClassName = "fas fa-check-circle";
      text = "Your password successfully updated 🎉";
      buttonText = "Login now";
      toHome = true;
    } else {
      iconClassName = "far fa-sad-cry";
      text = "Sorry! Something went wrong.";
      buttonText = "Try again";
      toHome = false;
    }
    return { iconClassName, text, buttonText, toHome };
  }, [wasItSuccessful]);

  const onClick = useCallback(() => {
    if (toHome) {
      history.push("/");
    } else {
      setNextPageStatus(false);
    }
  }, [wasItSuccessful, toHome]);

  return (
    <>
      <AdjustableHeightContainer height={"40%"}>
        <StyledIcon name={iconClassName} className="fa-5x" />
      </AdjustableHeightContainer>

      <AdjustableHeightContainer height={"30%"}>
        <StyledParagraph>{text}</StyledParagraph>
      </AdjustableHeightContainer>

      <AdjustableHeightContainer height={"30%"}>
        <StyledButton primary onClick={onClick}>
          {buttonText}
        </StyledButton>
      </AdjustableHeightContainer>
    </>
  );
};
export default PageTwo;
