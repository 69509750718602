import {
  CREATE_LEAVE_COMMENT,
  GET_ASSIGNED_LEAVE_REQUISITIONS,
  UPDATE_LEAVE_STATUS
} from "../actionTypes";

import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnUpdate
} from "helper";

import { DELETE_COMMENT, EDIT_COMMENT } from "common/ActionTypes";

const initialState = createInitialStoreState({});

export const cancelledLeavesReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_ASSIGNED_LEAVE_REQUISITIONS}_SUCCESS`: {
      if (payload?.status == "cancelled") {
        return createNewStoreOnGetData(payload, store, true);
      }
      return store;
    }
    case `${UPDATE_LEAVE_STATUS}_SUCCESS`: {
      return initialState;
    }
    case `${CREATE_LEAVE_COMMENT}_SUCCESS`: {
      if (payload?.status == "cancelled") {
        return createNewStoreOnUpdate(payload, store, true);
      }
      return store;
    }
    case `${DELETE_COMMENT}_SUCCESS`:
    case `${EDIT_COMMENT}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store, true);
    }
    default:
      return store;
  }
};
