import React from "react";
import Form from "./Form";

import { MainContainer } from "../style/create";

const LedgerCategoryCreateView = ({ updateFormVisibility }) => {
  return (
    <MainContainer>
      <Form updateFormVisibility={updateFormVisibility} />
    </MainContainer>
  );
};

export default LedgerCategoryCreateView;
