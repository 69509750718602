import React, { useCallback } from "react";

import useInput from "../useInput";

import styles from "./ChipsGroup.module.css";
import Chip from "./Chip";

const ChipsGroup = ({
  options = [],
  groupName,
  fieldClass = "",
  singleSelect = false,
  ...otherprops
}) => {
  const { restInputProps } = useInput(otherprops);
  const { value: values, onChange, name, ...remainingProps } = restInputProps;

  const onChipChange = useCallback(
    (e) => {
      const { value } = e.target;
      let newValues = {
        ...values,
      };
      if (singleSelect) {
        newValues = {};
      }
      if (newValues[value]) {
        delete newValues[value];
      } else {
        newValues[value] = true;
      }
      onChange({
        target: {
          name,
          value: newValues,
        },
      });
    },
    [onChange, values, name, singleSelect]
  );

  return (
    <div className={`${styles.container} ${fieldClass}`}>
      {options.map((item) => {
        return (
          <Chip
            key={item.value}
            name={groupName}
            value={item.value}
            label={item.name}
            renderComponent={item.renderComponent}
            disabled={item.disabled || false}
            checked={values[item.value]}
            onChange={onChipChange}
            {...remainingProps}
          />
        );
      })}
    </div>
  );
};

export default ChipsGroup;
