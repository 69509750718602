import React, { useEffect, useRef, useState } from "react";

import DrawerTab from "../DrawerTab";

const TabsRenderer = ({ children, isAdmin, ...otherProps }) => {
  const [isSingleChild, updateChildStatus] = useState(false);
  const drawerRef = useRef();

  useEffect(() => {
    if (
      !isAdmin &&
      drawerRef &&
      drawerRef.current &&
      drawerRef.current.container &&
      drawerRef.current.container.current &&
      drawerRef.current.container.current.childElementCount === 1
    ) {
      updateChildStatus(true);
    } else {
      updateChildStatus(false);
    }
  }, [children, isAdmin]);

  if (isSingleChild) {
    return React.Children.map(children, (child) => {
      const newProps = {
        ...child.props,
        ...otherProps,
        showEnlarged: true,
      };
      return React.cloneElement(child, newProps);
    });
  }

  return (
    <DrawerTab {...otherProps} ref={drawerRef}>
      {children}
    </DrawerTab>
  );
};

TabsRenderer.defaultProps = {
  isAdmin: false,
};

export default TabsRenderer;
