import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Route, useHistory } from "react-router";

import Grid from "../../../../Common/Components/Grid";
import { createTableDataSelector } from "../../../../Common/Selectors/MTable";
import Routes from "../../../../Routes/Path";
import { TableContainer } from "../../../../GlobalStyles";
import { isCurrentUserIsExecutive } from "common/Selectors/auth";

const TableData = ({
  data,
  onCellClick,
  rowIdMap,
  cellNameToColumnMap,
  onEmptyData,
  ...other
}) => {
  const history = useHistory();
  const { rows, cols, modifiedData } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    let modifiedData = data;
    modifiedData[0] = modifiedData[0].map((column) => {
      if (column == "is Headquarter") return "Headquarter";
      return column;
    });

    return { rows, cols, modifiedData };
  }, [data, cellNameToColumnMap]);

  const onEditCompanyBranch = (e, rowIndex) => {
    history.push(
      `${Routes.authorized.companyBranch.edit.path}/${rowIdMap[rowIndex]}`
    );
  };

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (rows === 1) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);
  const actionCellProps = useMemo(() => {
    return {
      cellName: "actions",
      cellActions: [
        {
          id: "class_edit_btn",
          icon: "fas fa-pen",
          tootltipText: "Edit Company Branch",
          btnProps: {
            onClick: onEditCompanyBranch,
          },
        },
      ],
    };
  }, [onEditCompanyBranch]);

  return (
    <TableContainer {...other}>
      <Grid
        onClick={onCellClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        actionCellProps={actionCellProps}
        data={modifiedData}
      />
    </TableContainer>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const getUserRows = createTableDataSelector({
    tableName: "branch",
    blackList: { "Sl No": true },
    extraColumns: isAdmin && [{ columnName: "actions", position: 0 }],
    reverse: false,
  });
  const maker = (store, props) => {
    const { rows, rowIdMap, cellNameToColumnMap } = getUserRows(store, props);
    return {
      data: rows,
      rowIdMap,
      cellNameToColumnMap,
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
