import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";

import { createWorksihftType, editWorkshiftType } from "../actionCreator";

function useCreateLeaveType() {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const createWorkshiftTypeData = async (values, onFormReset) => {
    try {
      updateLoading(true);
      await dispatch(
        createWorksihftType({
          companyId,
          isAdmin,
          formData: values,
        })
      );
      showAlert({ message: "Workshift Name Created" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
  };
  const editWorkshift = async (values, onFormReset, workshiftId) => {
    try {
      updateLoading(true);
      await dispatch(
        editWorkshiftType({
          companyId,
          isAdmin,
          formData: values,
          workshiftId,
        })
      );
      showAlert({ message: "Workshift Updated" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
  };
  return { isLoading, createWorkshiftTypeData, editWorkshift };
}

export default useCreateLeaveType;
