import React from "react";
import { useParams } from "react-router";
import { MainContainer } from "../../styles/create";
import Form from "./Form";

const HolidayCreateView = () => {
  const params = useParams();
  return (
    <MainContainer>
      <Form editId={params.id} />
    </MainContainer>
  );
};

export default HolidayCreateView;
