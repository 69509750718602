import React, { useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { getUserListData } from "common/Api";

import { assetCategoryOptions, statusOptions } from "./options";

import { StyledForm } from "../../style/create";
import { FormHeading } from "../../style/create";
import { useHistory } from "react-router";
import { StyledBackButton } from "modules/Holiday/styles/create";
import Routes from "route/Path";
import useForm from "../../../../Hooks/useForm";
import { accessAssetsDataSelector } from "modules/CompanyAssets/Selector";
import { useFormDetails } from "./useFormDetails";

const Form = ({
  companyId,
  isAdmin,
  editId,
}) => {
  const history = useHistory();
  const accessAssetsData = useSelector((store) =>
    accessAssetsDataSelector(store, { id: editId })
  );

  const isEditMode = !!editId;

  const goBack = () => {
    history.replace(Routes.authorized.accessAssets.view.path, {});
  };

  const { formData, values, isFormValid, resetForm, updateField } = useForm({
    fields: {
      assignedTo: {
        required: !editId,
      },

      category: {
        required: true,

      },

      status: {
        required: true
      },

      description: {
        required: true,
      },

      issuedOn: {
        required: true,
      },

      submittedOn: {
        required: !editId
      },

      documents: {
        required: !editId,
      },
    },
  });

  const { saveAccessAsset, isLoading } = useFormDetails()
  const onFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isFormValid()) {
        return;
      }
      saveAccessAsset(editId, isAdmin, companyId, isEditMode, values, goBack, resetForm)
    },
    [companyId, isAdmin, isEditMode, editId, values],
  );

  useEffect(() => {
    if (accessAssetsData && editId) {
      updateField("assignedTo", accessAssetsData?.assignedTo);
      updateField("category", accessAssetsData?.category?.toLowerCase());
      updateField("status", accessAssetsData?.status?.toLowerCase().replaceAll(" ", "_"));
      updateField("description", accessAssetsData?.description);
      updateField("issuedOn", accessAssetsData?.issuedOn);
      updateField("submittedOn", accessAssetsData?.submittedOn);
      updateField("documents", accessAssetsData?.documents);
    }
  }, [accessAssetsData, editId]);

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <FormHeading>
        {" "}
        {isEditMode ? "Edit access assets" : "Create new one"}
        {isEditMode && <StyledBackButton onLeft showIcon onClick={goBack} />}
      </FormHeading>

      {!isEditMode && <InputGroup
        id="assetCreate_assetAssignee"
        fieldType="asyncSearchSelect"
        // value={selectedUsers}
        // onChange={onUserSelected}
        placeholder="Search for users"
        api={getUserListData}
        singleSelect
        {...formData["assignedTo"]}
      />}

      <InputGroup
        id="assetCreate_assetCategory"
        fieldType="select"
        options={assetCategoryOptions}
        defaultSelectText="Select Category"
        label="Category"
        {...formData["category"]}
      />

      {isEditMode && <InputGroup
        id="assetCreate_assetStatus"
        fieldType="select"
        options={statusOptions}
        defaultSelectText="Select Status"
        label="Status"
        {...formData["status"]}
      />}


      <InputGroup
        id="assetCreate_assetDescription"
        fieldType="textArea"
        placeholder="Enter description"
        label="Description"
        size="lg"
        {...formData["description"]}
      />

      <InputGroup
        id="assetCreate_assetIssuedOn"
        fieldType="datePicker"
        placeholder="Enter issue date dd/mm/yy"
        label="Issued on"
        {...formData["issuedOn"]}
      />

      {isEditMode && <InputGroup
        id="assetCreate_assetSubmittedOn"
        fieldType="datePicker"
        placeholder="Enter submit date dd/mm/yy"
        label="Submitted on"
        {...formData["submittedOn"]}
      />}

      {!isEditMode && <InputGroup
        id="assetCreate_assetDocuments"
        fieldType="googleFilePicker"
        multiple={true}
        label="Documents"
        {...formData["documents"]}
      />}

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        {isEditMode ? "Edit" : "Create"}
      </Button>
    </StyledForm>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);

  return { companyId, isAdmin };
};


export default connect(mapStateToProps, null)(Form);
