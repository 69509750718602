import { useEffect, useMemo } from "react";
import { getOncefetched } from "common/Selectors/store";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { createTableDataSelector } from "common/Selectors/MTable";

import usePaginator from "hooks/usePaginator";
import usePagination from "hooks/usePagination";

import { fetchPeopleOnLeavesToday } from "../../redux/attendance/actionCreator";
import { useSelector } from "react-redux";
import { transformCollaboratorMatrixToArray } from "../../utils";

const onceFetchedSelector = getOncefetched("dashboard.attendance.leave");
const todayLeavesSelector = createTableDataSelector({
  tableName: "dashboard.attendance.leave",
  blackList: { "Sl No": true },
  fetchAllData: true,
});

export function useFetchLeavesToday() {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    allPagesFetched,
    errorOccured,
  } = usePagination({
    actionCreator: fetchPeopleOnLeavesToday,
    tableName: "dashboard.attendance.leave",
  });

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const isOnceFetched = useSelector(onceFetchedSelector);
  const { rows: data, rowIdMap } = useSelector(todayLeavesSelector);
  const getLeaveCount = useSelector(
    (store) => store.dashboard.attendance.leave.count
  );

  const { fetchFirstPage, fetchNextPage } = usePaginator({
    paginatorApi: getNextPage,
    extraParams: { companyId, isAdmin },
  });

  useEffect(() => {
    if (!isOnceFetched) {
      fetchFirstPage();
    }
  }, [isOnceFetched]);

  const peoplesOnLeaveToday = useMemo(() => {
    const allData = transformCollaboratorMatrixToArray(data, rowIdMap);
    return allData.reverse();
  }, [data]);

  return {
    isLoading: loadingNextPage || loadingPrevPage,
    peoplesOnLeaveToday,
    fetchNextPage,
    allDataFethced: allPagesFetched,
    hasError: errorOccured,
    leaveCount: getLeaveCount,
  };
}
