import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getAllTeamWorklog } from "./redux/selector";

import WorkReportView from "./WorkReportView";

const TableData = ({ onEmptyData, onCellClick, ...other }) => {
  const data = useSelector(getAllTeamWorklog);
  const onClick = (worklog) => {
    if (typeof onCellClick === "function") {
      onCellClick(worklog);
    }
  };

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (!data.length) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [data]);

  return (
    <>
      {data.map((worklog) => {
        return (
          <WorkReportView
            worklog={worklog}
            onClick={() => {
              onClick(worklog);
            }}
          />
        );
      })}
    </>
  );
};

export default TableData;
