import React, { useState, useCallback } from "react";
import { connect } from "react-redux";

import Button from "components/Button";
import { RectangleSkeleton } from "components/Skeleton";

import ActionArea from "./ActionArea";
import TableData from "./TableData";

import EmptyScreen from "assets/employee/ReimbursementBlank.png";
import PageSlider from "common/Components/PageSlider";
import { isCurrentUserIsExecutive } from "common/Selectors/auth";
import { usePageSlider } from "../../../../Hooks/usePageSlider";
import { ViewContainer } from "../../../../GlobalStyles";
import {
  EmptyScreenContainer,
  EmptyScreenImage,
  EmptyScreenMessage,
  DetailTableContainer,
  DetailTableContentsLeft,
  DetailTableContentsRight,
} from "../../style/display";
import PiechartComponent from "components/Charts/PieChart";

import { DetailsTable } from "./DetailsTable";
import GraphView from "./GraphView";

const LedgerTransactionView = ({
  isAdmin,
  usersData,
  toggleSlider,
  chartData,
}) => {
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  return (
    <DetailTableContainer>
      {/* <ActionArea
        toggleSlider={toggleSlider}
        onLoading={onDbDataChange}
        usersData={usersData}
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
      /> */}
      {/* <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        onEmptyData={onEmptyData}
      /> */}

      <DetailTableContentsLeft>
        <DetailsTable />
      </DetailTableContentsLeft>
      <DetailTableContentsRight>
        <GraphView data={chartData} />
      </DetailTableContentsRight>

      {!isLoading && isEmpty && (
        <EmptyScreenContainer>
          <EmptyScreenImage src={EmptyScreen} alt="No records found" />
          <EmptyScreenMessage>
            {"There are no ledger Transactions"}
          </EmptyScreenMessage>
        </EmptyScreenContainer>
      )}
      {/* {isLoading && <RectangleSkeleton times={4} />} */}
    </DetailTableContainer>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { isAdmin };
};

export default connect(mapStateToProps, null)(LedgerTransactionView);
