import Button from "components/Button";
import styled from "styled-components";

export const NoticeContainer = styled.button`
  height: 10%;
  min-height: 55px;
  width: 100%;
  margin: 0;
  padding: 5px 7px;
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  border: none;
  border-bottom: ${({ border }) =>
    border ? "1px solid rgba(167, 170, 184, 0.6)" : "none"};
  cursor: pointer;

  &:hover {
    position: relative;
    bottom: 1px;
    border: ${({ theme, isDraft }) =>
      isDraft ? `2px solid #ff0000` : `2px solid ${theme.colors.primaryColor}`};
    border-radius: 5px;
  }

  @media (min-width: 768px) {
    padding: 5px 15px;
    min-height: 0;
    flex-flow: row nowrap;
  }
`;
export const NoticeTitle = styled.div`
  width: 100%;
  height: 60%;
  margin: none;
  color: #5a5a5a;
  font-size: ${(props) => props.theme.text.para.medium};
  text-align: left;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    width: 55%;
    height: 100%;
    font-size: ${(props) => props.theme.text.title.medium};
  }
`;
export const NoticeDateTimeCont = styled.div`
  width: 60%;
  height: 40%;
  display: flex;
  flex-flow: row nowrap;
  font-size: ${(props) => props.theme.text.para.extraSmall};

  @media (min-width: 768px) {
    width: 40%;
    height: 100%;
    font-size: ${(props) => props.theme.text.para.medium};
  }

  @media (min-width: 1200px) {
    width: 30%;
  }
`;
export const NoticeTimeCont = styled.div`
  width: 40%;
  color: ${({ theme, isDraft }) =>
    isDraft ? "#ff0000" : theme.colors.primaryColor};
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 500px) {
    width: 30%;
  }

  @media (min-width: 768px) {
    width: 40%;
    justify-content: center;
  }
`;
export const NoticeDateCont = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 500px) {
    width: 30%;
  }

  @media (min-width: 768px) {
    width: 60%;
    justify-content: center;
  }
`;

export const DeleteButton = styled(Button)`
  margin-left: auto;
  color: red;
  background-color: transparent;
  border: none;
  &:hover {
    box-shadow: none;
  }
  background-color: ${({ disabled }) => (disabled ? "lightgray" : "")};
`;
