import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const Step = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: ${(props) => (props.$center ? "center" : "flex-start")};
  text-align: center;
  position: relative;
`;

export const StepText = styled.span`
  font-size: ${(props) => props.theme.text.para.small};
  max-width: 150px;
  color: ${(props) => (props.$active ? "black" : "#9e9e9e")};
  @media (min-width: 1024px) {
    font-size: ${(props) => props.theme.text.para.medium};
  }
`;

export const Circle = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.$active ? props.theme.colors.primaryColor : "#9e9e9e"};
  color: white;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 50%;
  position: relative;
  z-index: 1;
`;

export const Divider = styled.div`
  position: absolute;
  top: 15px;
  height: 1px;
  background-color: #bdbdbd;
  width: 100%;
  left: ${(props) => (props.$center ? "calc(50% + 15px)" : "0%")};
`;
