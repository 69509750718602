export const qualificationsOptions = [
  { name: "Academic qualifications", value: "Academic" },
  { name: "Professional qualifications", value: "Professional" },
  { name: "Professional training / certifications", value: "TrainingCertificate" }
]

export const educationOptions = [
  { name: "Class X", value: "class_Xth" },
  { name: "Class XII", value: "class_XIIth" },
  { name: "Graduate", value: "graduate" },
  { name: "Diploma", value: "diploma" },
  { name: "Post Graduate", value: "post_graduate" },
  { name: "Doctorate or PhD", value: "doctorate_PhD" },
  { name: "Other", value: "other" }
]
