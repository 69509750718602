import React, { useMemo } from "react";

import Form from "./Form";
import useNodeDimensions from "hooks/useNodeDimensions";
import useWindowDimensions from "hooks/useWindowDimensions";

import GenericModal from "../../../../Common/Components/GenericModal";
import { getFormStyle } from "../../utils";

const FORM_HEIGHT = 600;
const FORM_WIDTH = 500;
const MAX_LEFT_OFFSET = 10;

const EventCreate = ({ elemIdOrElem, onClose, initialDate }) => {
  const elemPos = useNodeDimensions({ elemIdOrElem });
  const { dimesnions } = useWindowDimensions();

  const formStyle = useMemo(() => {
    if (!elemPos) {
      return;
    }

    return getFormStyle(
      elemPos,
      dimesnions,
      FORM_WIDTH,
      FORM_HEIGHT,
      MAX_LEFT_OFFSET
    );
  }, [elemPos, dimesnions]);

  return elemPos ? (
    <GenericModal onClose={onClose}>
      <Form initialDate={initialDate} style={formStyle} onClose={onClose} />
    </GenericModal>
  ) : null;
};

export default EventCreate;
