import { CREATE_PERSONAL_INFO, GET_PERSONAL_INFO, GET_PERSONAL_INFO_BY_COLLABORATOR, UPDATE_PERSONAL_INFO } from "./actiontypes";
import { RESET_USER_ID } from "../User/actiontypes";

import { createInitialStoreState, createNewStoreOnGetData, createNewStoreOnCreate, createNewStoreOnUpdate } from "helper";

const initialState = createInitialStoreState({
  data: '',
  personalInfoData: null,
  currentStep: ''
});

export const personalInfoReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${CREATE_PERSONAL_INFO}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }
    case `${UPDATE_PERSONAL_INFO}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }
    case `${GET_PERSONAL_INFO}_SUCCESS`:
    case `${GET_PERSONAL_INFO_BY_COLLABORATOR}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    case RESET_USER_ID: {
      return {
        ...store,
        onceFetched: false,
      };
    }

    default:
      return store;
  }
};
