import { AdminColors, EmployeeColors } from "./colors";
import { shadow } from "./shadow";
import { text } from "./text";
import { zIndex } from "./zIndex";
import { dimensions } from "./dimensions";

export const createTheme = (isAdmin) => ({
  colors: isAdmin ? AdminColors : EmployeeColors,
  shadow,
  text,
  zIndex,
  dimensions,
});
