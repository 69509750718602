import requestData from "../../../../ApiHandler";
import { createAuthUrl } from "helper";

export const fetchTodaysHolidayApi = ({ companyId, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_latest_holiday`,
  });
  return requestData(url, {
    method: "GET",
  });
};
