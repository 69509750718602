import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { getAllLeaves } from "../selector";

import Routes from "route/Path";
import LeaveView from "modules/LeaveManagement/Views/leaveView";

const TableData = ({ onEmptyData, ...otherProps }) => {
  const history = useHistory();

  const data = useSelector(getAllLeaves);

  const onLeaveClick = (leaveId) => {
    history.push({
      pathname: `${Routes.authorized.leave.details.path}/${leaveId}`,
      state: { isCollaboratorLeaveView: true }
    });
  };

  const LeaveRecordView = useMemo(() => {
    return data.map((leave) => (
      <LeaveView leave={leave} onClick={onLeaveClick} isCollaboratorLeaveView />
    ));
  }, [data]);

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (!data.length) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [data]);

  return LeaveRecordView;
};

export default TableData;
