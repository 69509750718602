import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import ForgotPasswordPage from "../../Modules/ForgotPassword/View";
import UpdatePasswordPage from "../../Modules/UpdatePasswordPage/View";
import NotFountPage from "../../Modules/NotFoundPage/View";

const LoginPage = lazy(() => import("../../Modules/LoginPage/Views/LoginPage"));

const Fallback = () => <p>Loading Page...</p>;

const UnAuthorizedRoutes = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route path="/forgot_password" component={ForgotPasswordPage} />
        <Route exact path="/update_password" component={UpdatePasswordPage} />
        <Route component={NotFountPage} />
      </Switch>
    </Suspense>
  );
};

export default UnAuthorizedRoutes;
