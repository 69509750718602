import { GET_POST_DETAIL } from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import { getPostDetailsApi } from "./api";

const getPostDetailsGenrator = function* ({ companyId, isAdmin, postId }) {
  yield {
    action: GET_POST_DETAIL,
    sideEffect: getPostDetailsApi({ companyId, isAdmin, postId })
  };
};
export const [getPostDetails] = createAsyncActionCreator([
  getPostDetailsGenrator
]);
