import React, { useCallback, useMemo } from "react";

import { GridCell, GridText, MonthTask, MoreText, NewTask } from "./style";

const MAX_ITEM_TO_SHOW = 2;

const CellGridItem = ({ style, data, onClick, newTaskPos, onViewTask }) => {
  const { cellData, tasks, column, row } = data;

  const onCellClick = useCallback(
    (e) => {
      if (typeof onClick === "function") {
        onClick(e.target, cellData.dateObj);
      }
    },
    [cellData.date]
  );

  const onTaskClick = (taskData, e) => {
    e.stopPropagation();
    typeof onViewTask === "function" && onViewTask(e.target, taskData);
  };

  const { more, taskList } = useMemo(() => {
    if (!Array.isArray(tasks)) {
      return;
    }

    const result = [];

    tasks.forEach((task, index) => {
      if (index > MAX_ITEM_TO_SHOW - 1) {
        return;
      }
      const key = `${index}_${row}_${column}_${task.id}`;
      const clickFunc = onTaskClick.bind(this, task);
      result.push(
        <MonthTask
          onClick={clickFunc}
          style={{ backgroundColor: task.color }}
          key={key}
        >
          {task.title}
        </MonthTask>
      );
    });

    return {
      more:
        tasks.length > MAX_ITEM_TO_SHOW ? tasks.length - MAX_ITEM_TO_SHOW : 0,
      taskList: result,
    };
  }, [tasks, column, row]);
  return (
    <GridCell onClick={onCellClick} style={style}>
      <GridText isTodayDate={cellData.isTodayDate}>{cellData.date}</GridText>
      {taskList}
      {!!more && <MoreText>+{more}more</MoreText>}
      {!!newTaskPos && (
        <NewTask id="calendar_new_task" style={newTaskPos}>
          <span>(No Title)</span>
        </NewTask>
      )}
    </GridCell>
  );
};

export default CellGridItem;
