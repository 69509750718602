import {
  CREATE_TEAM,
  GET_TEAMS,
  SET_CURRENT_TEAM_ID,
  UPDATE_TEAM,
} from "../actiontypes";
import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnUpdate,
} from "../../../Helper";

const initialData = createInitialStoreState({
  teamId: null,
});

export const ownersReducer = (store = initialData, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_TEAMS}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${CREATE_TEAM}_SUCCESS`: {
      const newData = createNewStoreOnCreate(payload, store, false, true);
      const teamId = payload?.dataIds[0];
      return {
        ...newData,
        teamId,
      };
    }

    case `${UPDATE_TEAM}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case SET_CURRENT_TEAM_ID: {
      return {
        ...store,
        teamId: payload?.teamId,
      };
    }

    default:
      return store;
  }
};
