import React from "react";
import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import Icon from "components/Icon";

import { GenderOptions } from "../options";

import { ButtonGroup, StyledForm, FieldInfoTag } from "../../../style/create";

const CollaboratorForm = ({ onSubmit, formData, isFormValid, onBack }) => {
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (isFormValid() && typeof onSubmit === "function") {
      onSubmit();
    }
  };

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <InputGroup label="Name" placeholder="Enter Name" {...formData["name"]} />
      <InputGroup
        label="Phone number"
        inputMode="tel"
        placeholder="Enter phone number"
        {...formData["phoneNumber"]}
      />

      <InputGroup
        label="Gender"
        fieldType="select"
        options={GenderOptions}
        defaultSelectText="Select Gender"
        {...formData["gender"]}
      />

      <InputGroup
        label="Role"
        placeholder="Enter role, eg- HR"
        {...formData["role"]}
      />

      <InputGroup
        fieldType="googleFilePicker"
        accept="image/*"
        label="Upload Avatar"
        secondaryLabel
        {...formData["avatar"]}
      />
      {/****  Info tag for File Size Limit  ****/}
      <FieldInfoTag>
        <Icon name="fas fa-info-circle" />
        <span>File size limit 5MB</span>
      </FieldInfoTag>

      <ButtonGroup>
        <Button onClick={onBack} outline>
          Back
        </Button>
        <Button type="submit" primary>
          Next
        </Button>
      </ButtonGroup>
    </StyledForm>
  );
};

export default CollaboratorForm;
