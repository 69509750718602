export const bloodGroupOptions = [
  { name: "O+", value: "O+" },
  { name: "O-", value: "O-" },
  { name: "AB+", value: "AB+" },
  { name: "AB-", value: "AB-" },
  { name: "B+", value: "B+" },
  { name: "B-", value: "B-" },
  { name: "A+", value: "A+" },
  { name: "A-", value: "A-" },
];

export const maritalStatusOptions = [
  { name: "Single", value: "single" },
  { name: "Married", value: "married" },
  { name: "Widowed", value: "widowed" },
  { name: "Separated", value: "separated" },
  { name: "Divorced", value: "divorced" },
  { name: "Unspecified", value: "unspecified" },
];

export const addressProofType = [
  { name: "Passport", value: "passport" },
  { name: "Aadhar Card", value: "aadhar_card" },
  { name: "Ration Card", value: "ration_card" },
  { name: "Driving License", value: "driving_license" },
  { name: "Voter Id", value: "voter_id" },
  { name: "Rent Agreement", value: "rent_agreement" },
  { name: "Bank Passbook", value: "bank_passbook" },
  { name: "Utility Bills", value: "utility_bills" },
  { name: "Other", value: "other" },
];

export const isChronicOption = [
  { name: "Do you have any chronic diseases?", value: "is_chronic" },
];

export const formTags = {
  "Details": "details",
  "Health Condition": "health_details",
  "Address": "address",
  "Documents": 'documents'
}

export const documentFields = [
  "permanentAddressProofDocumentUrl",
  "presentAddressProofDocumentUrl",
  "aadharFrontUrl",
  "aadharBackUrl",
  "voterIdFrontUrl",
  "voterIdBackUrl",
  "panCardPhotoUrl",
  "passportPhotoUrl",
  "passportSizePhotoUrl",
]
