import React, { useMemo } from "react";

import InsertInDom from "../InsertInDom";

import { mergeProps } from "helper";
import useChildDimensions from "hooks/useChildDimensions";
import useNodeDimensions from "hooks/useNodeDimensions";

import { calulateChildNewPosition } from "./utils";
import { ChildContainer, Overlay } from "./style";
import { PopoverContext } from "./context";

function Popover({
  children,
  elemIdOrElem,
  position,
  className,
  onClose,
  style,
  showOverlay,
  ...rest
}) {
  const targetDimensions = useNodeDimensions({ elemIdOrElem });
  const [setChildRef, childDimensions] = useChildDimensions();

  /**
   * Calaculate child container styles
   */

  const newChildStyle = useMemo(() => {
    if (!targetDimensions || !childDimensions) {
      return {};
    }
    return calulateChildNewPosition(
      childDimensions,
      targetDimensions,
      position
    );
  }, [targetDimensions, childDimensions]);

  const TriangleValue = useMemo(() => {
    return {
      position,
      targetDimensions
    };
  }, [targetDimensions, position]);

  const allStyles = mergeProps(style, newChildStyle);

  return elemIdOrElem ? (
    <InsertInDom {...rest}>
      {showOverlay && <Overlay onClick={onClose} />}
      <ChildContainer
        style={allStyles}
        className={`popover_advanced ${className}`}
        ref={setChildRef}
        {...rest}
      >
        <PopoverContext.Provider value={TriangleValue}>
          {children}
        </PopoverContext.Provider>
      </ChildContainer>
    </InsertInDom>
  ) : null;
}

Popover.defaultProps = {
  className: "",
  style: {},
  position: "bottom",
  showOverlay: false
};

export default React.memo(Popover);
