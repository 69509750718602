import React from "react";
import LeftContent from "./LeftContent";
import Posts from "./Posts";
import RightContent from "./RightContent";

import useFetchPosts from "../../hooks/Posts/useFetchPosts";

import { Container, Content } from "../../styles/employee";

function Employee2() {
  const { posts, fetchNextPage, allPostsLoaded, hasError } = useFetchPosts();

  return (
    <Container>
      <Content>
        <LeftContent />
        <Posts
          allPostsLoaded={allPostsLoaded}
          errorInFetch={hasError}
          data={posts}
          fetchNewPosts={fetchNextPage}
        />
        <RightContent />
      </Content>
    </Container>
  );
}

export default Employee2;
