import styled from "styled-components";
import { FadeInHorizontal } from "globalStyles";
const LeftFade = FadeInHorizontal("left");

export const MainContainer = styled.div`
  height: 100%;
  padding: 0 50px 20px 50px;
  display: flex;
  flex-flow: column nowrap;
  animation: ${LeftFade} ease-out 0.3s forwards;
`;

export const FormHeading = styled.h3`
  font-size: ${(props) => props.theme.text.title.large};
  color: ${(props) => props.theme.colors.primaryColor};
  margin-bottom: 20px;
  margin-top: 45px;
  font-weight: 600;
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & i {
    color: ${(props) => props.theme.colors.primaryColor};
    font-size: ${(props) => props.theme.text.title.large};
    margin: 45px 0 20px;
    cursor: pointer;
  }
`;

export const MemberListRadio = styled.div`
  color: #5a5a5a;
`;

export const EmailCheckBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #5a5a5a;
  padding-left: 10px & label {
    padding: 0;
  }
`;

export const FormLink = styled.a`
  text-align: left;
  text-decoration: underline;
  padding-bottom: 30px;
  cursor: pointer;
`;
