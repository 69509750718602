import React, { useMemo, useRef } from "react";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import { getStatusType } from "helper";
import Routes from "route/Path";

import { Cell } from "../../../../../style/display";
import { useHistory, useParams } from "react-router";

const RenderItem = ({ data, columnIndex, rowIndex, style }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
    maxStatusLength,
    rowIdMap,
    isDeleteingWorkshift,
    onDeleteWorkshift,
  } = data;
  const history = useHistory();
  const tempRef = useRef({ rowToDelete: "" });
  const cellvalue = rowData[rowIndex][columnIndex];
  const params = useParams();

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["status"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const onEditWorkshift = (e, rowIndex) => {
    history.push({
      pathname: `${Routes.authorized.workshift.editDetails.path}/${rowIdMap[rowIndex]}`,
      state: { workshiftId: params.detailId },
    });
  };
  const onDeleteWorkshiftClick = (e, rowIndex) => {
    if (window.confirm("Are you sure you want to delete the workshift ?")) {
      tempRef.current.rowToDelete = rowIndex;
      const collaborator_workshift_id = rowIdMap[rowIndex];

      onDeleteWorkshift({
        workshiftId: params.detailId,
        collaborator_workshift_id,
      });
    }
  };
  const actionCellProps = useMemo(() => {
    return () => ({
      cellName: "actions",
      cellActions: [
        {
          id: "class_edit_btn",
          icon: "fas fa-pen",
          tootltipText: "Edit Workshift",
          btnProps: {
            disabled:
              isDeleteingWorkshift && tempRef.current.rowToDelete === rowIndex,
            onClick: onEditWorkshift,
          },
        },
        {
          id: "class_delete_btn",
          icon: "fas fa-trash",
          tootltipText: "Delete Workshift",
          btnProps: {
            disabled:
              isDeleteingWorkshift && tempRef.current.rowToDelete === rowIndex,
            onClick: onDeleteWorkshiftClick,
          },
        },
      ],
    });
  }, [onEditWorkshift, onDeleteWorkshiftClick, rowIdMap]);

  const myChildren = useMemo(() => {
    if (isStatusColumn) {
      return (
        <Cell>
          <Status
            text={cellvalue}
            type={getStatusType(cellvalue)}
            style={{ width: `${maxStatusLength * 18}px`, padding: "6px" }}
          />
        </Cell>
      );
    }
  }, [isStatusColumn, cellvalue, maxStatusLength]);

  return (
    <GridCell
      row={rowIndex}
      showHeading={showHeading}
      actionCellProps={actionCellProps}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

export default RenderItem;
