import styled from "styled-components";
import ProfilePic from "components/ProfilePic";
import { CircleSkeleton } from "components/Skeleton";

import { Card, hideScrollbar } from "globalStyles";

export const CardContainer = styled(Card)`
  padding: 24px;
  display: flex;
  flex-flow: column nowrap;
`;

export const Section = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  & + div {
    margin-top: 20px;
  }
`;
export const ProfilePicContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  flex-flow: wrap;
`;

export const SectionHeader = styled.span`
  font-weight: bold;
  font-size: ${(props) => props.theme.text.para.medium};
  color: black;
  margin-bottom: 14px;
  text-align: left;
`;

export const ProfilePicWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
`;

export const ProfilePicIndicator = styled.div`
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  padding: 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  box-shadow: ${(props) => props.theme.shadow.large};
  border: 1px lightgray solid;

  & > img {
    max-width: 80%;
    object-fit: contain;
  }
`;
export const StyledProfilePic = styled(ProfilePic)`
  width: 40px;
  height: 40px;
  margin: 0px 5px;
`;

export const ProfileSkeleton = styled(CircleSkeleton)`
  width: 40px;
  height: 40px;
  flex-shrink: 0;

  & + div {
    margin-left: 10px;
  }
`;
export const MorePic = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  border: 1px lightgray solid;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  font-size: 10px;
  font-weight: 700;
  padding-bottom: 7px;
  color: #fffafa;
  margin-left: 5px;
  &:hover {
    box-shadow: ${(props) => props.theme.shadow.medium};
  }
`;

export const SectionText = styled.span`
  font-weight: normal;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  color: #707070;
  text-align: left;
`;
export const Dot = styled.div`
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0px;
  right: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
`;
export const HeaderRefreshContent = styled.div`
  background-color: #01008a;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: transform linear 0.2s;
  &:active {
    transform: scale(0.9);
  }
`;
