import React, { useRef } from "react";

import {
  Card,
  QuestionArea,
  FooterArea,
  BtnArea,
  FooterButton,
  StyledCloseButton,
} from "./style";

export function QuestionCard({
  FormComponent,
  questionId,
  onPrevious,
  onSubmit,
  percent,
  showPrevBtn,
  showNextBtn,
  isLoading,
  onClose,
  ...rest
}) {
  const formRef = useRef(null);

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (!formRef.current.isFormValid()) {
      return;
    }
    onSubmit &&
      onSubmit(questionId, formRef.current.values, formRef.current.formType);
  };

  const onGoBack = () => {
    onPrevious && onPrevious(questionId);
  };

  return (
    <Card onSubmit={onFormSubmit}>
      <QuestionArea>
        <FormComponent ref={formRef} {...rest} />
      </QuestionArea>
      <StyledCloseButton type="button" onClick={onClose} />
      <FooterArea>
        <BtnArea>
          {showPrevBtn && (
            <FooterButton onClick={onGoBack} size="auto" type="button" outline>
              Previous
            </FooterButton>
          )}
          {showNextBtn && (
            <FooterButton
              disabled={isLoading}
              loading={isLoading}
              size="auto"
              type="submit"
              primary
            >
              Next
            </FooterButton>
          )}
        </BtnArea>
      </FooterArea>
    </Card>
  );
}

QuestionCard.defaultProps = {
  onPrevious: null,
  percent: 0,
  showPrevBtn: false,
  showNextBtn: true,
  onClose: null,
};
