import React from "react";
import { Switch } from "react-router";
import AdvancedRoute from "../../Common/Components/AdvancedRoute";
import RoutePaths from "../Path";
import AccessTypes from "../AccessTypes";

import MainRoute from "./MainRoute";
import CompanySelectionView from "../../Modules/CompanySelection/Views";

import { createRouteObject } from "../../Helper";

const { companySelection } = RoutePaths.authorized;
const accessTypeRef = AccessTypes.pages.getPageAccessType.bind(
  AccessTypes.pages
);

const WrapperPageRoutes = createRouteObject(companySelection, accessTypeRef, [
  CompanySelectionView,
]);

const AuthorizedRoute = () => {
  return (
    <Switch>
      {WrapperPageRoutes.map((item) => {
        return (
          <AdvancedRoute
            isAuthorized={true}
            key={item.path}
            exact
            path={item.path}
            accessType={item.accessTypes}
            component={item.component}
          />
        );
      })}
      <MainRoute />
    </Switch>
  );
};

export default AuthorizedRoute;
