import React, { useMemo, useRef, useCallback, useState } from "react";
import ObjectPopover from "./ObjectPopver";
import { ColumnCellContent } from "./CellContent";

import { getRandomColor } from "helper";

import useClipText from "../../../Hooks/useClipText";
import { StyledGridCell } from "./style";
import { checkIfArrayConsistsOfImagesOnly } from "../../../Helper";

const GridCell = React.forwardRef(
  (
    {
      row,
      data = "",
      style,
      isInActiveRow,
      activateRow,
      onClick = null,
      showHeading = false,
      children = null,
      className = "",
      columnName = "",
      actionCellProps = {},
    },
    ref
  ) => {
    const buttonRef = useRef();
    const [buttonAnchor, setAnchor] = useState(null);

    const { clippedText } = useClipText({
      text: data,
    });
    const containsOnlyImages = useMemo(
      () => checkIfArrayConsistsOfImagesOnly(data),
      [data]
    );

    const onCellClick = useCallback(
      (isAnchor) => {
        if (typeof activateRow === "function") {
          activateRow(row);
        }
        if (typeof onClick === "function") {
          onClick(typeof isAnchor === "boolean" && isAnchor ? null : row);
        }
      },
      [row, activateRow, onClick]
    );

    const toggleAnchor = useCallback(() => {
      setAnchor((prevValue) => {
        if (!prevValue) return buttonRef.current;
        else return null;
      });
      onCellClick(true);
    }, [onCellClick]);

    const validText = useMemo(() => {
      if (typeof clippedText === "boolean") {
        return String(clippedText);
      }
      return clippedText;
    }, [clippedText]);

    const onCilck = useMemo(() => {
      if (
        (typeof data === "object" && !containsOnlyImages) ||
        clippedText !== data
      ) {
        return toggleAnchor;
      }
      return onCellClick;
    }, [clippedText, data, toggleAnchor, onCellClick]);

    const randomColor = useMemo(() => {
      return getRandomColor();
    }, []);

    const renderCellContent = useCallback(() => {
      if (children) {
        return children;
      }

      if (row > 0 && columnName) {
        return (
          <ColumnCellContent
            columnName={columnName}
            data={data}
            rowIndex={row}
            randomColor={randomColor}
            validText={validText}
            actionCellProps={actionCellProps}
            containsOnlyImages={containsOnlyImages}
          />
        );
      }

      return validText;
    }, [data, validText, columnName, row, children, actionCellProps]);

    const cursor = useMemo(() => {
      if (
        (typeof data === "object" && !containsOnlyImages) ||
        clippedText !== data
      ) {
        return "pointer";
      }
      return "default";
    }, [clippedText, data]);

    return (
      <>
        <StyledGridCell
          ref={ref || buttonRef}
          onClick={onCilck}
          style={{
            ...style,
            cursor,
          }}
          row={row}
          showHeading={showHeading}
          data={data}
          isInActiveRow={isInActiveRow}
          className={className}
        >
          {renderCellContent()}
        </StyledGridCell>
        <ObjectPopover
          heading={columnName}
          onClose={toggleAnchor}
          data={data}
          color={randomColor}
          active={Boolean(buttonAnchor)}
          anchorElem={buttonAnchor}
        />
      </>
    );
  }
);

export default GridCell;
