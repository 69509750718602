import React, { useCallback } from "react";
import InputGroup from "components/InputGroup";

import { get24HourString } from "helper";

import {
  DateContainer,
  DateTimeContainer,
  TimeContainer,
} from "../../styles/event";

const DateTime = ({
  dateId,
  timeId,
  timeOptions,
  initialDate,
  initialTime,
  onTimeChange,
  onDateChange,
  dateOrder = 1,
  timeOrder = 2,
  showDate = true,
  showTime = true,
}) => {
  const changeTime = useCallback(
    (e) => {
      if (typeof onTimeChange === "function") {
        const res = e.target.value.split(":");
        onTimeChange({ hr: Number(res[0]), min: Number(res[1]) });
      }
    },
    [onTimeChange]
  );

  const changeDate = useCallback(
    (e) => {
      if (typeof onTimeChange === "function") {
        onDateChange(e.target.value);
      }
    },
    [onDateChange]
  );
  return (
    <DateTimeContainer>
      {showDate && (
        <DateContainer style={{ order: dateOrder }}>
          <InputGroup
            id={dateId}
            fieldType="datePicker"
            placeholder="Select Date"
            required
            initialValue={initialDate}
            onChange={changeDate}
            listStyle={{ top: "100%", bottom: "auto" }}
            $borderPosition="bottom"
            showPickerIcon={false}
            showInputIconStatus={false}
          />
        </DateContainer>
      )}
      {showTime && (
        <TimeContainer style={{ order: timeOrder }}>
          <InputGroup
            id={timeId}
            style={{ padding: 0 }}
            fieldType="select"
            options={timeOptions}
            defaultSelectText="End Time"
            initialValue={initialTime}
            onChange={changeTime}
            required
            $borderPosition="bottom"
            showInputIconStatus={false}
          />
        </TimeContainer>
      )}
    </DateTimeContainer>
  );
};

DateTime.defaultProps = {
  initialTime: get24HourString(0, 0),
};

export default DateTime;
