import GenericModal from "components/GenericModal";
import { useDispatch } from "react-redux";
import { ReactionHeader } from "./ReactionHeader";
import { resetReactionPostData } from "../PostReactionsModalView/redux/AllReactions/actionCreator";
import {
  ModalContainer,
  ReactionsModalContent,
  ReactionModalHeader,
  StyleCloseButton,
} from "./style";

export const ReactionsListModal = ({
  onClose,
  postId,
  initialReactionCount,
}) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(resetReactionPostData());
    typeof onClose === "function" && onClose();
  };
  return (
    <GenericModal opacity={0.5}>
      <ModalContainer>
        <ReactionsModalContent>
          <ReactionModalHeader>
            <ReactionHeader
              postId={postId}
              initialReactionCount={initialReactionCount}
            />
            <StyleCloseButton onClick={onClick} />
          </ReactionModalHeader>
        </ReactionsModalContent>
      </ModalContainer>
    </GenericModal>
  );
};
