export const getCollaboratorDetails = (
  data,
  cellNameToColumnMap,
  relations
) => {
  const val = Object.values(relations);
  const idx = cellNameToColumnMap.leadTo;
  const reqData = data[idx];

  const collaboratorData =
    reqData && val
      ? val.find((el) => el?.attributes?.email === reqData.email)
      : null;

  return collaboratorData;
};
