import {
  GET_ALL_JOB_TITLES,
  UPDATE_JOB_TITLE,
  CREATE_JOB_TITLE,
  DELETE_JOB_TITLE,
} from "./actiontypes";

import {
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createInitialStoreState,
} from "../../Helper";

const initialState = createInitialStoreState({}, true);

export const jobTitleReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_ALL_JOB_TITLES}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    case `${CREATE_JOB_TITLE}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true);
    }
    default:
      return store;
  }
};
