import {
  CANCEL_LEAVE,
  CREATE_LEAVE_COMMENT,
  GET_ASSIGNED_LEAVE_REQUISITIONS,
  GET_NOTIFIED_LEAVE_REQUISITIONS,
  UPDATE_LEAVE_STATUS
} from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import {
  cancelLeaveApi,
  createLeaveCommentApi,
  getAssignedLeavesApi,
  getNotifiedLeavesApi,
  updateLeaveStatusApi
} from "./api";

const getAssignedLeavesGenerator = function* ({
  pageNumber,
  companyId,
  status
}) {
  yield {
    action: GET_ASSIGNED_LEAVE_REQUISITIONS,
    sideEffect: getAssignedLeavesApi({ pageNumber, companyId, status }),
    payload: { status }
  };
};

const getNotifiedLeavesGenerator = function* ({ pageNumber, companyId }) {
  yield {
    action: GET_NOTIFIED_LEAVE_REQUISITIONS,
    sideEffect: getNotifiedLeavesApi({ pageNumber, companyId })
  };
};

const updateLeaveStatusGenerator = function* ({ companyId, leaveId, status }) {
  yield {
    action: UPDATE_LEAVE_STATUS,
    sideEffect: updateLeaveStatusApi({ companyId, leaveId, status })
  };
};

const cancelLeaveGenerator = function* ({ companyId, leaveId, status }) {
  yield {
    action: CANCEL_LEAVE,
    sideEffect: cancelLeaveApi({
      companyId,
      leaveId,
      status
    }),
  };
};

const createLeaveCommentGenerator = function* ({
  companyId,
  leaveId,
  message,
  status
}) {
  yield {
    action: CREATE_LEAVE_COMMENT,
    sideEffect: createLeaveCommentApi({ companyId, leaveId, message }),
    payload: { status }
  };
};

export const [
  getAssignedLeaves,
  getNotifiedLeaves,
  updateLeaveStatus,
  createLeaveComment,
  cancelLeave
] = createAsyncActionCreator([
  getAssignedLeavesGenerator,
  getNotifiedLeavesGenerator,
  updateLeaveStatusGenerator,
  createLeaveCommentGenerator,
  cancelLeaveGenerator
]);
