import React from "react";
import { OptionButton } from "./PostOptionButton";
import { Moreoption } from "./style";

export function MoreOption({ postId, postActionBtnProps }) {
  return (
    <Moreoption>
      {postActionBtnProps.map((option) => (
        <OptionButton postId={postId} {...option} />
      ))}
    </Moreoption>
  );
}
