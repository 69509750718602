import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useNodeDimensions from "hooks/useNodeDimensions";
import GenericModal from "../GenericModal";
import NotificationItem from "./NotificationItem";
import ActivityIndicator from "../ActivityIndicator";

import {
  Container,
  NotificationHeader,
  ReadBtn,
  Heading,
  Triangle,
  StyledEmptyData,
  LoaderContainer,
  StyledWrong,
} from "./style";

const TRIANGLE_WIDTH = 20;
const TRIANGLE_HEIGHT = 20;

const NotificationCenter = ({
  elemIdOrElem,
  dataList,
  renderItem,
  onEndReached,
  onClose,
  onReadAll,
  initialLoading,
  loading,
  children,
  showError,
}) => {
  const [isActive, updateActiveStatus] = useState(false);
  const [myPos, updateMyPos] = useState({ top: 0, left: 0 });
  const [hasScrolled, updateScrolledStatus] = useState(false);
  const targetElemDimensions = useNodeDimensions({ elemIdOrElem });
  const containerRef = useRef(null);
  const tempDataRef = useRef({ hasScrolled: false });

  /**
   * sets the postion of notification pannel accordign to the target element position
   */
  const calculatePosition = useCallback(() => {
    if (targetElemDimensions && containerRef.current) {
      const { top, left, height, right } = targetElemDimensions;
      const source = containerRef.current.getBoundingClientRect();
      const startLeft = left - source.width / 2;
      const pos = {};
      const windowDim = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      if (startLeft + source.width > windowDim.width) {
        pos.right = windowDim.width - right;

        if (pos.right + source.width > windowDim.width) {
          pos.right = 0;
        }
      } else {
        pos.left = startLeft;
      }
      updateMyPos({ top: top + height, ...pos, maxWidth: windowDim.width });
    }
  }, [targetElemDimensions]);

  const setRef = useCallback(
    (ref) => {
      if (!ref) {
        return;
      }
      containerRef.current = ref;
      calculatePosition();
    },
    [calculatePosition]
  );

  const onScroll = useCallback(
    (e) => {
      const { scrollHeight, scrollTop } = e.target;

      if (scrollTop > 0) {
        if (!tempDataRef.current.hasScrolled) {
          updateScrolledStatus(true);
        }
        tempDataRef.current.hasScrolled = true;
      } else {
        tempDataRef.current.hasScrolled = false;
        updateScrolledStatus(false);
      }
      const { height } = e.target.getBoundingClientRect();
      if (
        scrollHeight - scrollTop === height &&
        typeof onEndReached === "function"
      ) {
        onEndReached();
      }
    },
    [onEndReached]
  );

  const toggleNotifications = useCallback((status) => {
    updateActiveStatus((val) => {
      if (typeof status === "boolean") {
        return status;
      }
      return !val;
    });
  }, []);

  /**
   * Toggle Notification based on parent elem availability
   */
  useEffect(() => {
    if (!targetElemDimensions) {
      toggleNotifications(false);
    } else {
      toggleNotifications(true);
    }
  }, [targetElemDimensions]);

  const { list, showMarkAsRead } = useMemo(() => {
    if (!Array.isArray(dataList)) {
      return { list: null, showMarkAsRead: false };
    }

    let hasRead = false;
    const list = dataList.map((data, index) => {
      if (typeof renderItem === "function") {
        return renderItem({ data });
      }
      hasRead = hasRead || Boolean(data.readAt);
      return (
        <NotificationItem
          isLoading={initialLoading}
          key={`${data.id}_${index}`}
          data={data}
          style={{
            animationDelay: `${(index + 1) * 50}ms`,
          }}
        />
      );
    });

    return { list, showMarkAsRead: !hasRead && !initialLoading };
  }, [dataList, renderItem, initialLoading]);

  const trianglePos = useMemo(() => {
    if (!targetElemDimensions) {
      return {};
    }
    const { left, width, top, height } = targetElemDimensions;
    return {
      left: `${left + width / 2 - TRIANGLE_WIDTH / 2}px`,
      top: `${top + height - TRIANGLE_HEIGHT / 2}px`,
      width: `${TRIANGLE_WIDTH}px`,
      height: `${TRIANGLE_HEIGHT}px`,
    };
  }, [targetElemDimensions]);

  return isActive ? (
    <GenericModal onClose={onClose}>
      <Container style={myPos} ref={setRef} onScroll={onScroll}>
        {list.length > 0 && !showError && (
          <NotificationHeader $showShadow={hasScrolled}>
            <Heading>Notifications</Heading>
            {showMarkAsRead && (
              <ReadBtn onClick={onReadAll}>Mark all as read</ReadBtn>
            )}
          </NotificationHeader>
        )}
        {list.length > 0 && !showError && list}
        {children}
        {list.length === 0 && !initialLoading && !showError && (
          <StyledEmptyData />
        )}
        {loading && !showError && (
          <LoaderContainer>
            <ActivityIndicator size="s" color="gray" />
          </LoaderContainer>
        )}
        {showError && <StyledWrong />}
      </Container>
      <Triangle $pos={trianglePos} />
    </GenericModal>
  ) : null;
};

NotificationCenter.defaultProps = {
  renderItem: null,
  dataList: [],
  initialLoading: false,
  loading: false,
  onClose: () => {},
  onReadAll: () => {},
  children: null,
  showError: false,
};
export default NotificationCenter;
