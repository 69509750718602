import styled from "styled-components";
import { PageContainer, FadeInHorizontal } from "globalStyles";
const LeftFade = FadeInHorizontal("left");

export const AnimatedPageContainer = styled(PageContainer)`
  animation: ${LeftFade} ease-out 0.3s forwards;
`;
export const UseListContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
`;
export const UserListItem = styled.div`
  display: flex;
`;

export const UserListLeft = styled.div`
  flex-grow: 1;
  padding-right: 10px;
`;

export const UserListRight = styled.div`
  flex-basis: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
