import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Container,
  SlicedUserContainer,
  UserWrapper,
  ProfileOverlay,
} from "./style";
import Popover from "../PopoverAdvanced2";
import { getRandomColor } from "../../../Helper";
import Profile from "./Profile";
import AllUsers from "./AllUsers";

const User = ({
  data,
  randomColor,
  index,
  totalLength,
  remainingCount,
  iconSize,
}) => {
  return (
    <UserWrapper $size={iconSize} $index={index}>
      <Profile
        data={data}
        color={randomColor}
        showUsername={false}
        $size={iconSize}
      />
      {totalLength === index + 1 && remainingCount > 0 && (
        <ProfileOverlay $size={iconSize} $index={index}>
          +{remainingCount}
        </ProfileOverlay>
      )}
    </UserWrapper>
  );
};

const MultipleUsers = ({ data, maxDataCount = 5, iconSize = "m" }) => {
  const [isActive, toggleActive] = useState(false);
  const btnRef = useRef(null);
  const randomColor = useMemo(() => {
    return getRandomColor();
  }, []);

  const toggleModal = useCallback(() => {
    toggleActive((val) => !val);
  }, []);

  const slicedData = useMemo(() => data.slice(0, maxDataCount), [data]);

  return (
    <>
      <Container onClick={toggleModal} ref={btnRef}>
        <SlicedUserContainer>
          {slicedData.map((item, index) => {
            return (
              <User
                data={item}
                randomColor={randomColor}
                index={index}
                totalLength={maxDataCount}
                remainingCount={data.length - maxDataCount}
                key={index}
                iconSize={iconSize}
              />
            );
          })}
        </SlicedUserContainer>
      </Container>
      <Popover
        onClose={toggleModal}
        active={isActive}
        anchorElem={btnRef.current}
        userPreferedDirection="left"
      >
        <AllUsers data={data} color={randomColor} />
      </Popover>
    </>
  );
};

export default MultipleUsers;
