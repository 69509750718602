import { FETCH_COLLABORATOR_DETAILS } from "./actiontypes";

import { createNewStoreOnGetData, createInitialStoreState } from "helper";

const initialState = createInitialStoreState({}, true);

export const userProfileReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${FETCH_COLLABORATOR_DETAILS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    default:
      return store;
  }
};
