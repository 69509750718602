import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import DrawerLink from "../DrawerLink";
import TabsRenderer from "./TabsRenderer";
import { generateDrawerLinks } from "helper";

import Asset_Img from "assets/Folder.svg";
import E_Asset_Img from "assets/employee/Folder.svg";

const [accessAssetsTabLinks, accessAssetsLinkComponents] =
  generateDrawerLinks(
    Routes.authorized.accessAssets,
    AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
    DrawerLink
  );

export const AccessAssetsTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType(
        "access_assets_section"
      )}
    >
      {/* <DrawerTitle title="Access / Asset Section" /> */}
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("accessAssets")}
        image={isAdmin ? Asset_Img : E_Asset_Img}
        title="Access / Asset"
        links={accessAssetsTabLinks}
      >
        {accessAssetsLinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
