import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getOncefetched } from "common/Selectors/store";
import { todayHolidaySelector } from "../../selectors/Holiday";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { fetchTodaysHoliday } from "../../redux/holiday/actionCreator";

const onceFetchedSelector = getOncefetched("anniversaries.today");

export function useFetchTodayHoliday() {
  const [isLoading, updateLoading] = useState(false);
  const [hasError, updateErrorStatus] = useState(false);
  const dispatch = useDispatch();
  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const isOnceFetched = useSelector(onceFetchedSelector);
  const holidayData = useSelector(todayHolidaySelector);

  const fetchData = async () => {
    try {
      updateLoading(true);
      await dispatch(fetchTodaysHoliday({ companyId, isAdmin }));
    } catch (err) {
      updateErrorStatus(true);
    } finally {
      updateLoading(false);
    }
  };

  useEffect(() => {
    if (!isOnceFetched) {
      fetchData();
    }
  }, []);

  return {
    isLoading,
    hasError,
    holidayData,
  };
}
