import InputGroup from "components/InputGroup";

import { bloodGroupOptions, maritalStatusOptions } from "../options";

import { validateEmail } from "helper";

export const DetailsForm = ({
  presentData,
  initialDate,
  initialMaritalStatus,
}) => {
  return (
    <>
      <InputGroup
        id="personalInfoCreate_dateOfBirth"
        fieldType="datePicker"
        required
        placeholder="Enter Date of Birth"
        label="Date of Birth"
        initialValue={initialDate}
      />

      <InputGroup
        id="personalInfoCreate_bloodGroup"
        fieldType="select"
        required
        options={bloodGroupOptions}
        defaultSelectText="Select Blood Group"
        label="Blood Group"
        initialValue={presentData?.bloodGroup}
      />

      <InputGroup
        id="personalInfoCreate_maritalStatus"
        fieldType="select"
        required
        options={maritalStatusOptions}
        defaultSelectText="Select Marital Status"
        label="Marital Status"
        initialValue={initialMaritalStatus}
      />

      <InputGroup
        id="personalInfoCreate_personalEmail"
        fieldType="input"
        required
        placeholder="Enter your Personal Email"
        label="Personal Email"
        validator={validateEmail}
        invalidMessage="Email ID entered is invalid"
        initialValue={presentData?.personalEmail}
      />

      <InputGroup
        id="personalInfoCreate_nationality"
        fieldType="input"
        required
        placeholder="Enter your Nationality"
        label="Nationality"
        initialValue={presentData?.nationality}
      />

      <InputGroup
        id="personalInfoCreate_religion"
        fieldType="input"
        required
        placeholder="Enter your Religion"
        label="Religion"
        initialValue={presentData?.religion}
      />

      <InputGroup
        id="personalInfoCreate_aboutMe"
        fieldType="textArea"
        placeholder="Tell us something about you"
        label="About me"
        size="lg"
        initialValue={presentData?.aboutMe}
      />
    </>
  );
};
