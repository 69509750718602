import React from "react";
import useInput from "./useInput";

import { InputContainer, InputIcon, GlobalInput, SelectOption } from "./style";

const Select = ({
  defaultSelectText = "",
  options = [],
  size = "md",
  success = "",
  secondary = false,
  error = "",
  fieldClass = "",
  ...otherprops
}) => {
  const { icon, restInputProps } = useInput({
    ...otherprops,
    success,
    error,
  });

  success = success ? "true" : undefined;
  error = error ? "true" : undefined;

  return (
    <InputContainer size={size} className={fieldClass}>
      <GlobalInput
        as="select"
        success={success}
        error={error}
        secondary={secondary}
        style={{ cursor: "pointer" }}
        {...restInputProps}
      >
        {defaultSelectText && (
          <SelectOption value="">{defaultSelectText}</SelectOption>
        )}

        {options.map((item) => {
          return (
            <SelectOption key={item.value} value={item.value}>
              {item.name}
            </SelectOption>
          );
        })}
      </GlobalInput>
      {!!icon && <InputIcon success={success} error={error} name={icon} />}
    </InputContainer>
  );
};

export default Select;
