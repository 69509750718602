import React, { useMemo } from "react";
import { ListItem } from "components/List";
import Select from "components/Input/Select";
import { AccessTypeOptions } from "../../constants";

import {
  UseListContainer,
  UserListItem,
  UserListLeft,
  UserListRight,
} from "../../styles/create";

const UserAccessList = ({
  users,
  onDeselect,
  selectedUsers,
  onAccessChange,
}) => {
  const userList = useMemo(() => {
    return users.map((item) => {
      return (
        <UserListItem key={item.id}>
          <UserListLeft>
            <ListItem
              data={item}
              onSelect={onDeselect}
              selected={selectedUsers && selectedUsers[item.id]}
              showDeselectButton={true}
            />
          </UserListLeft>
          <UserListRight>
            <Select
              defaultSelectText="Select Access"
              defaultValue="viewer"
              onChange={(e) => onAccessChange(item.id, e.target.value)}
              options={AccessTypeOptions}
            />
          </UserListRight>
        </UserListItem>
      );
    });
  }, [users, onDeselect, selectedUsers, onAccessChange]);

  return <UseListContainer>{userList}</UseListContainer>;
};

export default UserAccessList;
