import React, { useState } from "react";
import WorkshiftTypeCreateView from "./Create";
import WorkshiftTypeView from "./Display";

const WorkshiftType = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const updateFormVisibility = (status) => {
    setIsFormVisible(status);
  };

  if (isFormVisible) {
    return (
      <WorkshiftTypeCreateView updateFormVisibility={updateFormVisibility} />
    );
  }
  return <WorkshiftTypeView updateFormVisibility={updateFormVisibility} />;
};

export default WorkshiftType;
