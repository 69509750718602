import { getCurrentDataAttributes } from "helper/selectors";
import { createSelector } from "reselect";

const storeActiveTabLeaves = (store, props) =>
  store.leaveManagement?.[props.activeTab]?.data;
const storeActiveTabLeaveIds = (store, props) =>
  store.leaveManagement?.[props.activeTab]?.dataIds;
const storeActiveTabLeavesRelations = (store, props) =>
  store.leaveManagement?.[props.activeTab]?.relation;
const currentLeaveId = (store, props) => props?.leaveId;

export const getAllLeaves = createSelector(
  storeActiveTabLeaves,
  storeActiveTabLeaveIds,
  storeActiveTabLeavesRelations,
  (allLeaves, leaveIds, relations) => {
    let data = [];
    Object.keys(allLeaves).map((key) => {
      if (typeof allLeaves[key] != "function" && leaveIds?.includes(key)) {
        const leave = allLeaves[key];
        data.push(getCurrentDataAttributes(leave, relations, allLeaves));
      }
    });
    return data;
  }
);

export const getCurrentLeaveDetails = createSelector(
  getAllLeaves,
  storeActiveTabLeaveIds,
  currentLeaveId,
  (leaves, leaveIds, leaveId) => {
    let leaveDetails = {};
    leaves.forEach((leave) => {
      if (leaveIds.includes(leaveId) && leave?.id == leaveId) {
        leaveDetails = leave;
      }
    });
    return leaveDetails;
  }
);
