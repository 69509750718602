import React, { useMemo, useState } from "react";

import { useFetchAllReactions } from "../Hooks/useFetchAllReactions";
import SectionListContainer from "./SectionListContainer";
import { AllSectionContainer, ReactionLoader } from "../style";
import {
  ListItem,
  ListTextContainer,
  LoaderProfilePic,
  LoaderText,
} from "../../../../NotificationCenter/style";
import ActivityIndicator from "components/ActivityIndicator";

function AllReactionsSection({
  postId,
  reactionsType,
  initialReactionCount,
  count,
}) {
  const {
    isLoading: loadingReactions,
    allReactions,
    hasError,
    fetchNextPage,
    allDataFethced,
  } = useFetchAllReactions(postId, reactionsType);

  const loadMoreData = () => {
    fetchNextPage();
  };

  const loader = useMemo(() => {
    return [...Array(initialReactionCount ? initialReactionCount : count)].map(
      () => (
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
          }}
        >
          <LoaderProfilePic />
          <ListTextContainer>
            <LoaderText />
          </ListTextContainer>
        </div>
      )
    );
  }, [initialReactionCount, count]);

  return (
    <>
      <AllSectionContainer>
        {loadingReactions && loader}
        {!hasError && allReactions.length > 0 && (
          <SectionListContainer
            data={allReactions}
            showMore={!allDataFethced}
            onMoreClick={() => loadMoreData()}
          />
        )}
      </AllSectionContainer>
    </>
  );
}

export default AllReactionsSection;
