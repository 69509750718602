import styled from "styled-components";
import { StyledActionArea, FadeInVertical } from "../../../GlobalStyles";
const BottomFade = FadeInVertical("bottom");

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  animation: ${BottomFade} ease-out 0.3s forwards;

  @media (min-width: 768px) {
    padding: 0 50px 20px 50px;
  }
`;

export const FormHeading = styled.h3`
  font-size: ${(props) => props.theme.text.title.large};
  color: ${(props) => props.theme.colors.primaryColor};
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 600;
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & i {
    color: ${(props) => props.theme.colors.primaryColor};
    font-size: ${(props) => props.theme.text.title.large};
    margin: 20px 0 20px;
    cursor: pointer;
  }
`;

export const EnhancedActionArea = styled(StyledActionArea)`
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;

  & > button {
    height: 35px;
    padding: 0 20px;
    width: auto;
    margin-left: 10px;
  }
`;

export const PersonalCheckBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #5a5a5a;
  padding-left: 10px;
  margin-bottom: 12px;

  & label {
    padding: 0;
  }

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`;
