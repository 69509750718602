export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_ALL_ACTIVE_USERS = "GET_ALL_ACTIVE_USERS";
export const GET_ALL_IN_ACTIVE_USERS = "GET_ALL_IN_ACTIVE_USERS";
export const SET_EXECUTIVE_STATUS = "SET_EXECUTIVE_STATUS";
export const CHANGE_PAGE_CATEGORY = "CHANGE_PAGE_CATEGORY";
export const SET_CURRENT_USER_ID = "SET_CURRENT_USER_ID";
export const RESET_USER_ID = "RESET_USER_ID";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
