import React, { useCallback, useEffect, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import Icon from "components/Icon";
import useForm from "hooks/useForm";

import { getActiveLeaveTypes } from "../actionCreator";
import {
  assignedLeaveDataSelector,
  getAllLeaveTypesOptions,
} from "../selector";
import { getUserListData } from "common/Api";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { validateNumber } from "helper";
import { Label, StyledForm, StyledProfile } from "../../../../style/create";
import useAssignLeave from "./useAssignLeave";
import Routes from "route/Path";
import ProfilePic from "components/ProfilePic";

const Form = ({
  updateFormVisibility,
  leaveTypesOptions,
  companyId,
  isAdmin,
  editId,
}) => {
  const isEditMode = !!editId;
  const history = useHistory();

  const maxCarryValidator = (maxCarry, formData) => {
    if (validateNumber(maxCarry)) {
      if (Number(maxCarry) <= Number(formData?.assignedQuota)) {
        return true;
      }
    }
    return false;
  };

  const { formData, values, resetForm, isFormValid, updateField } = useForm({
    fields: {
      collaborator: {
        required: !isEditMode
      },
      leaveType: {
        required: true
      },
      validFrom: {
        required: true
      },
      validTo: {
        required: true
      },
      assignedQuota: {
        required: true,
        validator: validateNumber
      },
      maxCarry: {
        validator: maxCarryValidator,
        invalidMessage: "Maximum Carry Forward value should be less than assigned quota"
      }
    }
  })

  const assignedLeaveData = useSelector((store) =>
    assignedLeaveDataSelector(store, { id: editId }),
  );

  const leaveTypeSelectedOption = useMemo(
    () => [
      {
        name: assignedLeaveData?.leavePlan?.leaveType,
        value: assignedLeaveData?.leavePlan?.id,
      },
    ],
    [assignedLeaveData],
  );

  useEffect(() => {
    if (!editId || !assignedLeaveData) {
      return;
    }
    updateField("leaveType", assignedLeaveData?.leavePlan?.id);
    updateField("validFrom", new Date(assignedLeaveData?.validFrom).toString());
    updateField("validTo", new Date(assignedLeaveData?.validTo).toString());
    updateField("assignedQuota", assignedLeaveData?.assignedQuota);
    updateField("maxCarry", assignedLeaveData?.maximumCarryForward);
  }, [editId, assignedLeaveData]);

  const goBack = () => {
    history.replace(Routes.authorized.leaves.assign.path);
  };

  const hideForm = () => {
    typeof updateFormVisibility == "function"
      ? updateFormVisibility(false)
      : goBack();
  };

  const onReset = () => {
    typeof resetForm == "function" && resetForm();
    hideForm();
  };

  const { isLoading, assignLeave, editAssignedLeave } = useAssignLeave();

  const onFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isFormValid()) {
        return
      }
      isEditMode
        ? editAssignedLeave(editId, values, onReset)
        : assignLeave(values, resetForm);
    },
    [isFormValid, values, resetForm],
  );

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <Icon name="fas fa-times" onClick={hideForm} />
      {!isEditMode && (
        <InputGroup
          label="Assign To"
          fieldType="asyncSearchSelect"
          placeholder="Search for users"
          api={getUserListData}
          singleSelect
          {...formData["collaborator"]}
        />
      )}

      {isEditMode && assignedLeaveData && (
        <>
          <Label>Assigned To</Label>
          <StyledProfile>
            <ProfilePic
              img={assignedLeaveData?.collaborator?.avatarUrl}
              userName={assignedLeaveData?.collaborator?.name}
            />
            {assignedLeaveData?.collaborator?.name}
          </StyledProfile>
        </>
      )}

      <InputGroup
        label="Leave Type"
        fieldType="advanceSelect"
        defaultSelectText="Select leave type"
        isSearchable={true}
        pageConfig={{
          api: getActiveLeaveTypes,
          extraParams: { companyId, isAdmin },
          tableName: "assignedLeaves",
        }}
        options={isEditMode ? leaveTypeSelectedOption : leaveTypesOptions}
        disabled={isEditMode}
        style={{ cursor: isEditMode ? "not-allowed" : "pointer" }}
        {...formData["leaveType"]}
      />

      <InputGroup
        label="Valid From"
        fieldType="datePicker"
        placeholder="Enter valid from"
        {...formData["validFrom"]}
      />

      <InputGroup
        label="Valid To"
        fieldType="datePicker"
        placeholder="Enter valid to"
        {...formData["validTo"]}
      />

      <InputGroup
        label="Assigned Quota"
        placeholder="Enter assigned quota"
        {...formData["assignedQuota"]}
      />

      <InputGroup
        label="Maximum Carry Forward"
        placeholder="Enter maximum carry forward"
        {...formData["maxCarry"]}
      />

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        {isEditMode ? "Update" : "Create"}
      </Button>
    </StyledForm>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const leaveTypesOptions = getAllLeaveTypesOptions(store, props)
  return {
    companyId,
    isAdmin,
    leaveTypesOptions
  };
};

export default connect(mapStateToProps, null)(Form);
