import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";

import {
  createAcademicQualification,
  createProfessionalQualification,
  createTrainingCertificationQualification
} from "../../actionCreator";
import { generateFormData } from "../../utils";

function useQualifcationDetails() {
  const dispatch = useDispatch();
  const { showAlert } = useAlert();


  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const createQualification = async (values, academicFormValues, professionalFormValues, trainingFormValues, onFormReset) => {
    try {
      const formData = generateFormData(values, academicFormValues, professionalFormValues, trainingFormValues);
      switch (values.qualificationType) {
        case 'Academic':
          await dispatch(createAcademicQualification({ companyId, isAdmin, formData }));
          break;
        case 'Professional':
          await dispatch(createProfessionalQualification({ companyId, isAdmin, formData }));
          break;
        case 'TrainingCertificate':
          await dispatch(createTrainingCertificationQualification({ companyId, isAdmin, formData }));
          break;
        default: throw "error"
      }
      showAlert({ message: "Qualification Details created" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    }
  };
  return { createQualification };
}

export default useQualifcationDetails;
