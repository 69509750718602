import React, { useMemo } from "react";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import { getStatusType } from "helper";

const RenderItem = ({ data, columnIndex, rowIndex, style }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
    maxStatusLength
  } = data;

  const cellvalue = rowData[rowIndex][columnIndex];

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["status"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const myChildren = useMemo(() => {
    if (isStatusColumn) {
      return (
        <Status text={cellvalue} type={getStatusType(cellvalue)} style={{ width: `${maxStatusLength * 18}px`, padding: '6px' }} />
      );
    }
    return null;
  }, [isStatusColumn, cellvalue, maxStatusLength]);

  return (
    <GridCell
      row={rowIndex}
      showHeading={showHeading}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

export default RenderItem;
