import { css } from "styled-components";

export const placeholderColor = (color) => {
  return css`
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: ${color};
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: ${color};
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: ${color};
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: ${color};
    }
  `;
};
