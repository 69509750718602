import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import WorkshiftType from "../../../Modules/Workshift/View/Display/WorkshiftType";
import WorkshiftTypeCreateView from "modules/Workshift/View/Display/WorkshiftType/Create";

import { createRouteObject } from "../../../Helper";
import WorkshiftDetails from "modules/Workshift/View/Display/WorkshiftType/WorkshiftDetails";
import WorkshiftDetailCreateView from "modules/Workshift/View/Display/WorkshiftType/WorkshiftDetails/Create";

const { workshift } = RoutePaths.authorized;

const pages = createRouteObject(
  workshift,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [
    WorkshiftType,
    WorkshiftTypeCreateView,
    WorkshiftDetails,
    WorkshiftDetailCreateView,
  ]
);

const WorkshiftPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact={item.slugPath ? false : true}
        path={item.slugPath || item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default WorkshiftPages;
