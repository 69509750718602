import { CREATE_ACADEMIC_QUALIFICATION, CREATE_PROFESSIONAL_QUALIFICATION, CREATE_TRAINING_CERTIFICATION_QUALIFICATION, GET_QUALIFICATIONS, } from "./actiontypes";

import { createAsyncActionCreator } from "helper";

import { createAcademicQualificationApi, createProfessionalQualificationApi, createTrainingCertificationQualificationApi, getQualificationsApi, } from "./api";

const createAcademicQualificationGenrator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_ACADEMIC_QUALIFICATION,
    sideEffect: createAcademicQualificationApi({ companyId, isAdmin, formData }),
  };
};

const createProfessionalQualificationGenrator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_PROFESSIONAL_QUALIFICATION,
    sideEffect: createProfessionalQualificationApi({ companyId, isAdmin, formData }),
  };
};

const createTrainingCertificationQualificationGenrator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_TRAINING_CERTIFICATION_QUALIFICATION,
    sideEffect: createTrainingCertificationQualificationApi({ companyId, isAdmin, formData }),
  };
};

const getQualificationsGenerator = function* ({
  companyId,
  qualificationType,
  pageNumber,
  isAdmin,
}) {
  yield {
    action: GET_QUALIFICATIONS,
    sideEffect: getQualificationsApi({ companyId, qualificationType, pageNumber, isAdmin }),
  };
};

export const [
  createAcademicQualification,
  createProfessionalQualification,
  createTrainingCertificationQualification,
  getQualifications,
] = createAsyncActionCreator([
  createAcademicQualificationGenrator,
  createProfessionalQualificationGenrator,
  createTrainingCertificationQualificationGenrator,
  getQualificationsGenerator,
]);
