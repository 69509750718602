import { useMemo } from "react";
import Icon from "components/Icon";
import ProfilePic from "components/ProfilePic";
import {
  convertToTextDateStringMMDDYYYY,
  convertDateToMMDDYYYY
} from "helper/date";
import {
  AttachmentContainer,
  BoldText,
  ReportRecord,
  ReportRecordSubContainer,
  SubText,
  TextContent
} from "modules/NotifiedWorkReport/style";

const WorkReportView = ({ worklog, onClick }) => {
  const logDate = useMemo(() => {
    const dateInMMDDYYY = convertDateToMMDDYYYY(worklog?.logDate, "-");
    const logDate = convertToTextDateStringMMDDYYYY(dateInMMDDYYY, "-") || "";
    return logDate;
  }, [worklog]);
  const collaboratorUpdated = useMemo(() => {
    return !!worklog?.updatedBy;
  }, [worklog]);
  const collaborator = useMemo(() => {
    if (collaboratorUpdated) {
      return worklog.updatedBy;
    } else {
      return worklog.createdBy;
    }
  }, [worklog, collaboratorUpdated]);
  return (
    <ReportRecord onClick={() => onClick(worklog?.id)}>
      <ReportRecordSubContainer>
        <ProfilePic
          img={collaborator?.avatarUrl?.url}
          userName={collaborator?.name}
          style={{ width: "30px", height: "30px" }}
        />
        <TextContent>
          <BoldText>{collaborator?.name}</BoldText>
          <SubText style={{ marginLeft: 5 }}>
            has {collaboratorUpdated ? "updated" : "created"} work for
          </SubText>
          <BoldText style={{ marginLeft: 5 }}>{logDate}</BoldText>
        </TextContent>
      </ReportRecordSubContainer>
      <ReportRecordSubContainer>
        <SubText>{worklog?.updatedAt}</SubText>
        <AttachmentContainer>
          {worklog?.documentUrls?.length > 0 && (
            <Icon name="fas fa-paperclip" style={{ color: "#707070" }} />
          )}
        </AttachmentContainer>
      </ReportRecordSubContainer>
    </ReportRecord>
  );
};

export default WorkReportView;
