import React, { useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "../../../Common/Components/InputGroup";
import Button from "../../../Common/Components/Button";

import LoadingPage from "./LoadingPage";

import { setUserCompany } from "../../../Common/ActionCreators";
import {
  getCurrentUserCompaniesOptions,
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
  getAllUserData,
  isLoggedInAsAdmin,
} from "../../../Common/Selectors/auth";

import Path from "../../../Routes/Path";
import { Container, Form, Heading, HeadingBox } from "../styles";

const { adminWelcome, employeeWelcome } = Path.authorized.welcome;

const CompanySelection = ({
  setUserCompany,
  companies,
  currentCompany,
  history,
  isExecutive,
  loginTypeIsAdmin,
  allUserData,
}) => {
  const navigateToWelcomePage = useCallback(
    (isAdmin, isReplace = false) => {
      const adminPath = adminWelcome.path;
      const employeePath = employeeWelcome.path;
      const operation = isReplace ? history.replace : history.push;
      setTimeout(() => {
        operation(isAdmin ? adminPath : employeePath);
      }, 2000);
    },
    [allUserData]
  );

  const checkIsAdmin = (allUserData, companyId) => {
    const collabType = allUserData[companyId]?.attributes?.collaboratorType;
    return collabType === "admin" || collabType === "super_admin";
  };

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        const { companySelection_comapny } = values;
        setUserCompany({ companyId: companySelection_comapny });
        navigateToWelcomePage(
          loginTypeIsAdmin &&
            checkIsAdmin(allUserData, companySelection_comapny)
        );
      } catch (err) {}
    },
    [allUserData],
    loginTypeIsAdmin
  );

  /**
   * If already company is selected
   */
  if (currentCompany) {
    navigateToWelcomePage(
      loginTypeIsAdmin && checkIsAdmin(allUserData, currentCompany),
      true
    );
    return <LoadingPage />;
  }

  /**
   * if only single company is there for the user
   */
  if (companies.length === 1) {
    setUserCompany({ companyId: companies[0].value });
    navigateToWelcomePage(
      loginTypeIsAdmin && checkIsAdmin(allUserData, companies[0].value)
    );

    /**
     * Navigating to a different page before returning anything from component
     * is not a good idea, to fix it setTimeout ise used
     */
    return <LoadingPage />;
  }

  return (
    <Container>
      <Form onSubmit={onFormSubmit}>
        <HeadingBox>
          <Heading>Company Details</Heading>
        </HeadingBox>
        <InputGroup
          id="companySelection_comapny"
          secondary
          defaultSelectText="Select the comapny"
          fieldType="select"
          options={companies}
          required
        />

        <Button primary type="submit">
          Proceed
        </Button>
      </Form>
    </Container>
  );
};

const mapStateToProps = (store, props) => {
  const allCompanies = getCurrentUserCompaniesOptions(store, props);
  const currentCompany = getCurrentUserCompany(store, props);
  const isExecutive = isCurrentUserIsExecutive(store, props);
  const allUserData = getAllUserData(store, props);
  const loginTypeIsAdmin = isLoggedInAsAdmin(store, props);
  return () => ({
    companies: allCompanies,
    currentCompany,
    isExecutive,
    allUserData,
    loginTypeIsAdmin,
  });
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUserCompany,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelection);
