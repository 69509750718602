import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Icon from "components/Icon";
import InputGroup from "components/InputGroup";
import Button from "components/Button";
import AsyncSearchSelect from "components/Input/AsyncSearchSelect";
import { ListItem } from "components/List";
import useAlert from "hooks/useAlert";

import DateTime from "./DateTime";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { getCalendarList, getAllCalendarColors } from "../../selector";
import { createCalendarEvent } from "../../actionCreator";
import { createTimeOptions, get24HourString, isTwoDateSame } from "helper";
import { createEventFormData } from "../../utils";

import { getUserListData } from "common/Api";

import {
  Container,
  Header,
  CloseButton,
  StyledFormGroup,
  CalendarSelectColor,
  CalendarSelectGroup,
  UserListContainer,
  GroupDateTimeContainer,
  Divider,
  StyledClockIcon,
  CheckBoxWrapper,
} from "../../styles/event";
import { FullDayOptions } from "../../constants";

import { validateUrl } from "../../../../Helper";

export const StartTimeOptions = createTimeOptions();

const EventCreateForm = ({
  style,
  className,
  onClose,
  companyId,
  calendarsOptions,
  isAdmin,
  createCalendarEvent,
  initialDate,
  calendarColors,
  venue,
  ...otherProps
}) => {
  const [currentSelectedHour, updateSelectedHour] = useState(0);
  const [currentSelectedDate, updateSelectedDate] = useState(null);
  const [currentSelectedCalendar, updateSelectedCalendar] = useState("");
  const [selectedUsers, updateSelectedUsers] = useState({});
  const [isLoading, updateLoading] = useState(false);
  const [fullDayStatus, updateFullDayStatus] = useState(null);
  const { showAlert } = useAlert();

  const [endTimeOptions, updateEndTime] = useState(StartTimeOptions);
  const isTimeFirstRef = useRef(true);
  const isDateFirstRef = useRef(true);

  const onFullDayChange = useCallback((e) => {
    updateFullDayStatus(e.target.value);
  }, []);

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        await createCalendarEvent({
          formData: createEventFormData(values, venue),
          isAdmin,
          userList: Object.keys(selectedUsers),
          companyId,
        });
        showAlert({ message: `Calendar Event Created` });
        if (typeof onClose === "function") {
          onClose();
        }
      } catch (err) {
        showAlert({ message: `Calendar Event Creation Failed`, type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [selectedUsers, isAdmin, companyId, venue]
  );

  const onTimeChange = useCallback((val) => {
    updateSelectedHour(val);
  }, []);

  const onDateChange = useCallback((val) => {
    updateSelectedDate(val);
  }, []);

  const onUserSelected = useCallback((e) => {
    const { value } = e.target;
    updateSelectedUsers(value);
  }, []);

  const onDeselectUsers = useCallback(
    (itemId, item) => {
      const newListData = { ...selectedUsers };
      if (newListData[itemId]) {
        delete newListData[itemId];
      }
      updateSelectedUsers(newListData);
    },
    [selectedUsers]
  );

  const onCalendarChange = useCallback((e) => {
    updateSelectedCalendar(e.target.value);
  }, []);

  const getFilteredTimes = useCallback((hour, minute) => {
    if (!hour && !minute) {
      return StartTimeOptions;
    }

    return StartTimeOptions.filter((item) => {
      if (item.hour === hour && item.min > minute) {
        return true;
      } else if (item.hour > hour) {
        return true;
      }
      return false;
    });
  }, []);

  useEffect(() => {
    const hr = initialDate.getHours();
    const min = initialDate.getMinutes();
    updateEndTime(getFilteredTimes(hr, min));
  }, [initialDate]);

  useEffect(() => {
    if (isTimeFirstRef.current) {
      isTimeFirstRef.current = false;
      return;
    }

    updateEndTime(
      getFilteredTimes(currentSelectedHour.hr, currentSelectedHour.min)
    );
  }, [currentSelectedHour]);

  useEffect(() => {
    if (isDateFirstRef.current) {
      isDateFirstRef.current = false;
      return;
    }
    const date = new Date(currentSelectedDate);
    let res;
    if (isTwoDateSame(date, initialDate)) {
      res = getFilteredTimes(currentSelectedHour.hr, currentSelectedHour.min);
    } else {
      res = StartTimeOptions;
    }

    updateEndTime(res);
  }, [currentSelectedDate]);

  const selectedUsersList = useMemo(() => {
    return Object.values(selectedUsers);
  }, [selectedUsers]);

  const startTime = useMemo(() => {
    return get24HourString(initialDate.getHours());
  }, [initialDate]);

  const selectedCalendarStyle = useMemo(() => {
    return {
      backgroundColor: calendarColors[currentSelectedCalendar],
      transform: "translateY(-10px) scale(1)",
    };
  }, [currentSelectedCalendar, calendarColors]);

  const endTime = useMemo(() => {
    return endTimeOptions[0].value;
  }, [endTimeOptions]);

  return (
    <Container {...otherProps} style={style} className={className}>
      <Header>
        <CloseButton onClick={onClose} size="auto">
          <Icon name="fas fa-times" />
        </CloseButton>
      </Header>

      <StyledFormGroup onSubmit={onFormSubmit}>
        <CalendarSelectGroup>
          {!!currentSelectedCalendar && (
            <CalendarSelectColor style={selectedCalendarStyle} />
          )}
          <InputGroup
            id="calendarEvent_calendar"
            fieldType="select"
            options={calendarsOptions}
            defaultSelectText="Select Calendar"
            required
            onChange={onCalendarChange}
          />
        </CalendarSelectGroup>

        <InputGroup
          id="calendarEvent_title"
          placeholder="Enter event title"
          required
        />

        <InputGroup
          id="calendarEvent_description"
          fieldType="textArea"
          placeholder="Enter event description"
          wrapperStyle={{ minHeight: "100px" }}
          fillParent={true}
        />

        <CheckBoxWrapper>
          <InputGroup
            id="calendarEvent_fullDay"
            fieldType="checkboxGroup"
            options={FullDayOptions}
            onChange={onFullDayChange}
          />
        </CheckBoxWrapper>

        <GroupDateTimeContainer>
          <StyledClockIcon color="#24292E" />
          <DateTime
            dateId="calendarEvent_startDate"
            timeId="calendarEvent_startTime"
            initialDate={initialDate}
            initialTime={startTime}
            timeOptions={StartTimeOptions}
            onTimeChange={onTimeChange}
            onDateChange={onDateChange}
            showTime={!fullDayStatus}
          />
          {!fullDayStatus && (
            <>
              <Divider />
              <DateTime
                dateId="calendarEvent_endDate"
                timeId="calendarEvent_endTime"
                timeOptions={endTimeOptions}
                initialTime={endTime}
                initialDate={initialDate}
                dateOrder={2}
                timeOrder={1}
              />
            </>
          )}
        </GroupDateTimeContainer>

        <InputGroup
          id="calendarEvent_meetingLink"
          placeholder="Enter meeting link"
          validator={validateUrl}
          invalidMessage="Invalid meeting link"
        />

        <InputGroup
          id="calendarEvent_latlng"
          fieldType="locationSearch"
          {...otherProps}
        />

        <AsyncSearchSelect
          value={selectedUsers}
          onChange={onUserSelected}
          placeholder="Add collaborators"
          api={getUserListData}
        />

        <UserListContainer>
          {selectedUsersList.map((item) => {
            return (
              <ListItem
                key={item.id}
                data={item}
                onSelect={onDeselectUsers}
                selected={selectedUsers && selectedUsers[item.id]}
                showDeselectButton={true}
              />
            );
          })}
        </UserListContainer>

        <Button disabled={isLoading} loading={isLoading} primary type="submit">
          Save
        </Button>
      </StyledFormGroup>
    </Container>
  );
};

const mapStateToProps = (store, props) => {
  return {
    companyId: getCurrentUserCompany(store, props),
    calendarsOptions: getCalendarList(store, props),
    isAdmin: isCurrentUserIsExecutive(store, props),
    calendarColors: getAllCalendarColors(store, props),
    venue: store.map.venue,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createCalendarEvent,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventCreateForm);
