import { convertMilisecondsToDateArray } from "helper/date";
import { useState, useEffect } from "react";
export const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  if (countDownDate < new Date().getTime()) {
    return ["00", "00", "00", "00"];
  }
  return convertMilisecondsToDateArray(countDown);
};
