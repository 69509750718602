import React, { useState } from "react";

import PostCreateModal from "./PostCreateModal";
import PollCreateModal from "./PollCreateModal";
import PostQuizCreateModal from "./PostQuizCreateModal";

import PostCreateItemList from "./PostCreateItemList";

import useUserInformation from "hooks/useUserInformation";
import useCdnUrl from "hooks/useCdnUrl";

import { PostsTypes } from "./constants";

import {
  CardContainer,
  Header,
  StyledProfilepic,
  PostCreateButton,
  Footer
} from "./style";

export function PostCreateCard() {
  const [modal, updateModalStatus] = useState({ active: false, type: "" });
  const { userInfo } = useUserInformation();
  const { cdnUrl, url } = userInfo.avatarUrl || {};
  const imageUrl = useCdnUrl({ cdnUrl, url });

  const openPostImageModal = () => {
    updateModalStatus({
      active: true,
      type: PostsTypes.image
    });
  };

  const closeModal = () => {
    updateModalStatus({
      active: false,
      type: ""
    });
  };

  const openGenericPostModal = (postType) => {
    updateModalStatus({
      active: true,
      type: postType
    });
  };
  return (
    <CardContainer>
      <Header>
        <StyledProfilepic img={url} userName={userInfo.name} />
        <PostCreateButton onClick={openPostImageModal}>
          Write a post
        </PostCreateButton>
      </Header>
      <Footer>
        <PostCreateItemList onClick={openGenericPostModal} />
      </Footer>
      {modal.active &&
        (modal.type === PostsTypes.image ||
          modal.type === PostsTypes.video) && (
          <PostCreateModal onClose={closeModal} />
        )}
      {modal.active && modal.type === PostsTypes.poll && (
        <PollCreateModal onClose={closeModal} />
      )}
      {modal.active && modal.type === PostsTypes.quiz && (
        <PostQuizCreateModal onClose={closeModal} />
      )}
    </CardContainer>
  );
}
