import requestData from "../../../ApiHandler";
import { createAuthUrl, extractDateInYYYYMMDD } from "helper";

export const createLeaveApi = ({ companyId, formData, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/leave_requisitions`
  });

  let leave_requisition = {
    collaborator_leave_plan_id: formData.collaboratorLeaveType,
    from_date: extractDateInYYYYMMDD(formData.fromDate),
    from_slot: formData?.fromSlot?.half_day ? "half_day" : "full_day",
    to_date: extractDateInYYYYMMDD(formData.toDate),
    to_slot: formData?.toSlot?.half_day ? "half_day" : "full_day",
    reason: formData.reason,
    leave_requisition_editor_ids: Object.keys(formData.applyTo)
  };

  if (formData.notifyTo && Object.keys(formData.notifyTo).length > 0) {
    leave_requisition.leave_requisition_viewer_ids = Object.keys(
      formData.notifyTo
    );
  }

  if (formData.documents) {
    leave_requisition.leave_documents = formData.documents;
  }

  return requestData(authUrl, {
    method: "POST",
    body: {
      leave_requisition: leave_requisition
    },
    isFormData: true
  });
};

export const getAllLeavesApi = ({ pageNumber, companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/leave_requisitions?page=${pageNumber}`
  });

  return requestData(authUrl, {
    method: "GET"
  });
};

export const getAllCollaboratorLeavesApi = ({
  pageNumber,
  companyId,
  isAdmin
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/fetch_current_collaborator_leave_plan?page=${pageNumber}`
  });

  return requestData(authUrl, {
    method: "GET"
  });
};
