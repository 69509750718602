import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import OnlyWhenAuthorized from "../Auth/OnlyWhenAuthorized";

import {
  StyledDrawerLink,
  DrawerLinkTitle,
  DrawerTabImage,
  DrawerIcon,
} from "./style";

const DrawerLink = ({
  path,
  title,
  pageAccessType,
  image,
  icon,
  showEnlarged = false,
  SvgComponent = null,
}) => {
  const location = useLocation();

  const isActive = useMemo(() => {
    return path === location.pathname ? "true" : "";
  }, [location.pathname, path]);

  return (
    <OnlyWhenAuthorized requiredAccess={pageAccessType}>
      <StyledDrawerLink
        $showEnlarged={showEnlarged}
        isactive={isActive}
        to={path}
      >
        {!!image && <DrawerTabImage src={image} />}
        {!!icon && <DrawerIcon name={icon} />}
        {SvgComponent ? SvgComponent : null}
        <DrawerLinkTitle isactive={isActive}>{title}</DrawerLinkTitle>
      </StyledDrawerLink>
    </OnlyWhenAuthorized>
  );
};

export default DrawerLink;
