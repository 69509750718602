import {
  FETCH_TODAY_BIRTHDAYS,
  FETCH_UPCOMINGS_BIRTHDAYS,
} from "./actionTypes";
import { createNewStoreOnGetData, createInitialStoreState } from "helper";
import { combineReducers } from "redux";

const todayInitialState = createInitialStoreState({}, true);

function todayBirthdayReducer(store = todayInitialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_TODAY_BIRTHDAYS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    default:
      return store;
  }
}

const nextInitialState = createInitialStoreState({}, true);
function upcomingBirthdayReducer(store = nextInitialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_UPCOMINGS_BIRTHDAYS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    default:
      return store;
  }
}

const BirthdayReducer = combineReducers({
  today: todayBirthdayReducer,
  upcoming: upcomingBirthdayReducer,
});

export default BirthdayReducer;
