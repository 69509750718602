import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { logoutUser as logoutUserAction } from "../Common/ActionCreators";
import { deleteToken } from "../Firebase/notification";

const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutUser = useCallback(() => {
    if (!window.confirm("Are you sure you want to logout?")) {
      return;
    }
    dispatch(logoutUserAction());
    deleteToken();
    history.replace("/");
  }, [dispatch, history, deleteToken]);

  return {
    logoutUser,
  };
};

export default useLogout;
