import React, { useState, useCallback } from "react";
import { connect } from "react-redux";

import { RectangleSkeleton } from "components/Skeleton";

import ActionArea from "./ActionArea";
import TableData from "./TableData";

import InfoView from "components/InfoView";
import EmptyScreen from "assets/employee/ReimbursementBlank.png";
import { isCurrentUserIsExecutive } from "common/Selectors/auth";
import { ViewContainer } from "../../../../GlobalStyles";

import CountdownCreate from "../Create";
import { useDeleteCountdown } from "modules/Countdown/hooks/useDeleteCountdown";

const CountdownView = ({ isAdmin }) => {
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);
  const [isFormVisible, updateFormVisibility] = useState(false);
  const { isLoading: deleteingCountdown, deleteCountdown } =
    useDeleteCountdown();

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  const displayForm = () => {
    updateFormVisibility(true);
  };

  if (isFormVisible) {
    return <CountdownCreate updateFormVisibility={updateFormVisibility} />;
  }

  return (
    <ViewContainer>
      <ActionArea
        onLoading={onDbDataChange}
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        updateFormVisibility={updateFormVisibility}
      />
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        onEmptyData={onEmptyData}
        isDeletingCountdown={deleteingCountdown}
        onDeleteCountdown={deleteCountdown}
      />
      {!isLoading && isEmpty && (
        <InfoView
          image={EmptyScreen}
          desc={!isAdmin && "There are no countdown entries to display yet"}
          onCta={isAdmin && displayForm}
          ctaText={isAdmin && "Add a new countdown"}
        />
      )}
      {isLoading && <RectangleSkeleton times={4} />}
    </ViewContainer>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { isAdmin };
};

export default connect(mapStateToProps, null)(CountdownView);
