import React, { useState } from "react";
import LeaveTypeCreateView from "./Create";
import LeaveTypeView from "./Display";

const LeaveType = () => {
  const [isFormVisible, setIsFormVisible] = useState(false)

  const updateFormVisibility = (status) => {
    setIsFormVisible(status);
  }

  if (isFormVisible) {
    return <LeaveTypeCreateView updateFormVisibility={updateFormVisibility} />
  }
  return (
    <LeaveTypeView updateFormVisibility={updateFormVisibility} />
  );
};

export default LeaveType;
