import {
  GET_ALL_CALENDARS,
  SET_USER_CALENDAR,
  SET_CALENDAR_TYPE,
  SET_DATE_RANGE,
  CREATE_CALENDAR,
} from "../actionTypes";

import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnCreate,
} from "helper";

const initialState = createInitialStoreState({
  selectedCalendars: {},
  calendarType: "month",
  dateRange: { rangeFirstDate: "", currentDate: "" },
});

export const calendarListReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_ALL_CALENDARS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    case `${CREATE_CALENDAR}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case SET_USER_CALENDAR: {
      const { calendar } = payload;
      return {
        ...store,
        selectedCalendars: calendar,
      };
    }

    case SET_CALENDAR_TYPE: {
      const { calendarType } = payload;
      return {
        ...store,
        calendarType,
      };
    }

    case SET_DATE_RANGE: {
      const { rangeFirstDate, currentDate } = payload;
      return {
        ...store,
        dateRange: { rangeFirstDate, currentDate },
      };
    }
    default:
      return store;
  }
};
