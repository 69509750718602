import Button from "components/Button";
import styled from "styled-components";

export const Container = styled.div`
  padding: 30px;
`;

export const ProfileSection = styled.div`
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  background: linear-gradient(to left, #4ac501, #4fde89, #56dde9, #01008a);
  box-shadow: ${(props) => props.theme.shadow.medium};
`;
export const AboutSection = styled.div`
  width: 30%;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
  margin-top: 20px;
  border: 1px solid #ececec;
  box-shadow: ${(props) => props.theme.shadow.medium};
`;

export const Title = styled.p`
  background: #dc2424;
  background: -webkit-linear-gradient(to left, #4a569d, #dc2424);
  background: linear-gradient(to left, #4a569d, #dc2424);
  color: #fff;
  padding: 8px;
  border-radius: 5px 5px 0px 0px;
  margin: 0px 0px 10px 0px;
`;
export const ListItem = styled.div`
  display: flex;
  margin: 0px 0px 10px 10px;
  align-items: center;
`;

export const ListText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.theme.text.para.medium};
  margin-left: 10px;
`;
export const AboutContent = styled.div`
  text-align: left;
  font-size: ${(props) => props.theme.text.title.large};
  font-weight: 600;
  color: #000;
`;

export const CoverImage = styled.div`
  width: 100%;
  height: 180px;
  & img {
    object-fit: cover;
    width: 100%;
    height: 180px;
  }
`;

export const DetailsConatiner = styled.div`
  padding: 0 30px 15px;
  display: flex;
  gap: 30px;
  align-items: flex-start;
  margin-top: -60px;
`;

export const ProfileImage = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 4px solid #fff;
  object-fit: cover;
`;

export const Content = styled.div`
  text-align: left;
  font-size: ${(props) => props.theme.text.title.medium};
  font-weight: 600;
  color: #f6f6f6;
  margin-top: 75px;
  padding-bottom: 10px;
`;

export const Designation = styled.p`
  color: #eee;
  margin: 2px 0 0px;
  font-size: ${(props) => props.theme.text.para.small};
`;

export const EditButton = styled(Button)`
  margin: 80px 0 10px auto;
`;
