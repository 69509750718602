import styled from "styled-components";

const getBackgroundColor = ({ $type }) => {
  switch ($type) {
    case "pending":
      return "#FF9600";
    case "approved":
      return "#0094E7";
    case "success":
      return "#02C467";
    case "error":
      return "#FF0743";
    default:
      return "#FF9600";
  }
};

export const Container = styled.div`
  padding: 6px 40px;
  border-radius: 20px;
  background-color: ${getBackgroundColor};
  width: max-content;
  text-align: center;
`;

export const Text = styled.span`
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.small};
  color: white;
  line-height: 1.5;
  text-transform: capitalize;
`;
