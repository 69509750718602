import { extractDateInYYYYMMDD } from "../../Helper";

export const generateFormData = ({ values, currentStep }) => {
  const {
    personalInfoCreate_dateOfBirth,
    personalInfoCreate_bloodGroup,
    personalInfoCreate_maritalStatus,
    personalInfoCreate_personalEmail,
    personalInfoCreate_nationality,
    personalInfoCreate_religion,
    personalInfoCreate_currentHealthCondition,
    personalInfoCreate_isChronic,
    personalInfoCreate_aboutMe,
    personalInfoCreate_permanentAddress,
    personalInfoCreate_permanentAddressProofType,
    personalInfoCreate_permanentAddressProof,
    personalInfoCreate_presentAddress,
    personalInfoCreate_presentAddressProofType,
    personalInfoCreate_presentAddressProof,
    personalInfoCreate_aadharCardFront,
    personalInfoCreate_aadharCardBack,
    personalInfoCreate_voterIdCardFront,
    personalInfoCreate_voterIdCardBack,
    personalInfoCreate_panCard,
    personalInfoCreate_passport,
    personalInfoCreate_passportSizePhoto,
  } = values;

  switch (currentStep) {
    case `documents`:
      return {
        aadhar_front: personalInfoCreate_aadharCardFront ? personalInfoCreate_aadharCardFront[0] : "",
        aadhar_back: personalInfoCreate_aadharCardBack ? personalInfoCreate_aadharCardBack[0] : "",
        voter_id_front: personalInfoCreate_voterIdCardFront ? personalInfoCreate_voterIdCardFront[0] : "",
        voter_id_back: personalInfoCreate_voterIdCardBack ? personalInfoCreate_voterIdCardBack[0] : "",
        pan_card_photo: personalInfoCreate_panCard ? personalInfoCreate_panCard[0] : "",
        passport_photo: personalInfoCreate_passport ? personalInfoCreate_passport[0] : "",
        passport_size_photo: personalInfoCreate_passportSizePhoto ? personalInfoCreate_passportSizePhoto[0] : "",
        current_step: currentStep
      }
    case `address`:
      return {
        permanent_address: personalInfoCreate_permanentAddress,
        permanent_address_proof_type: personalInfoCreate_permanentAddressProofType,
        permanent_address_proof_document:
          personalInfoCreate_permanentAddressProof ? personalInfoCreate_permanentAddressProof[0] : "",
        present_address: personalInfoCreate_presentAddress,
        present_address_proof_type: personalInfoCreate_presentAddressProofType,
        present_address_proof_document: personalInfoCreate_presentAddressProof ? personalInfoCreate_presentAddressProof[0] : "",
        current_step: currentStep
      }
    case `health_details`:
      return {
        current_health_condition: personalInfoCreate_currentHealthCondition,
        is_chronic: personalInfoCreate_isChronic?.is_chronic || false,
        current_step: currentStep
      }
    case `details`:
      return {
        date_of_birth: extractDateInYYYYMMDD(personalInfoCreate_dateOfBirth),
        blood_group: personalInfoCreate_bloodGroup,
        marital_status: personalInfoCreate_maritalStatus,
        personal_email: personalInfoCreate_personalEmail.toLowerCase(),
        nationality: personalInfoCreate_nationality,
        religion: personalInfoCreate_religion,
        about_me: personalInfoCreate_aboutMe,
        current_step: currentStep
      }
    default: return null
  }
};
