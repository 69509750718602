import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import CalendarDisplayView from "../../../Modules/Calendar/Views/Display";
import CalendarCreateView from "../../../Modules/Calendar/Views/Create";

import { createRouteObject } from "../../../Helper";

const { calendar } = RoutePaths.authorized;

const pages = createRouteObject(
  calendar,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [CalendarCreateView, CalendarDisplayView]
);

const CalendarPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact
        path={item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default CalendarPages;
