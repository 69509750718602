import React, { useRef, useState } from "react";

import PostReactions from "./PostReactions";

import { postReactionImages } from "../constants";

import Active_Chat_Img from "assets/employee/activeChat.svg";

import InActive_Heart_Img from "assets/employee/inactiveHeart.svg";
import InActive_Chat_Img from "assets/employee/inactiveChat.svg";
import { ReactionsListModal } from "./PostReactionsModalView";

import { Footer, FooterItem, ReactionImage } from "./style";

const REACTION_TIME = 200;

export function PostFooter({
  reactionData,
  comments,
  onReaction,
  onRemovingReaction,
  postId,
  onLoadMoreComments,
  ...rest
}) {
  const [isReactionActive, updateReactionState] = useState(false);
  const [isReactionModalActive, updateReactionModalState] = useState(false);
  const timerRef = useRef(null);

  const { reactionId, reactionType, reactionCount } = reactionData;

  const onReactToPost = (...args) => {
    updateReactionState(false);
    onReaction && onReaction(...args);
  };

  const onRemovingReactionFromPost = () => {
    onRemovingReaction({ reactionType, reactionId });
  };

  const onMouseEnterFooterItem = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      updateReactionState(true);
    }, REACTION_TIME);
  };

  const onMouseLeaveFooterItem = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      updateReactionState(false);
    }, REACTION_TIME);
  };

  const onMouseEnterReactions = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const onReactionsModalOpen = () => {
    updateReactionModalState((val) => !val);
  };
  return (
    <Footer {...rest}>
      <FooterItem
        onMouseLeave={onMouseLeaveFooterItem}
        onMouseEnter={onMouseEnterFooterItem}
      >
        <ReactionImage
          src={
            reactionType ? postReactionImages[reactionType] : InActive_Heart_Img
          }
          alt="heart"
          onClick={onRemovingReactionFromPost}
        />
        {isReactionActive && (
          <PostReactions
            onReaction={onReactToPost}
            onMouseLeave={onMouseLeaveFooterItem}
            onMouseEnter={onMouseEnterReactions}
          />
        )}
      </FooterItem>
      <span className="text" onClick={onReactionsModalOpen}>
        {reactionCount} likes
      </span>

      <FooterItem
        onClick={() => comments !== 0 && onLoadMoreComments(postId)}
        $showPointer
      >
        <img
          src={comments > 0 ? Active_Chat_Img : InActive_Chat_Img}
          alt="message"
        />
        <span className="text">{comments} comments</span>
      </FooterItem>
      {!!isReactionModalActive && reactionCount !== 0 && (
        <ReactionsListModal
          onClose={onReactionsModalOpen}
          postId={postId}
          initialReactionCount={reactionCount}
        />
      )}
    </Footer>
  );
}
