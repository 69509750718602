import { SET_CURRENT_VENUE } from "./actionTypes";

const initialState = {};

export const mapReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${SET_CURRENT_VENUE}`: {
      const { latitude, longitude, address, name } = payload;
      return {
        venue: {
          name,
          address,
          coordinates: {
            latitude,
            longitude,
          },
        },
      };
    }
    default:
      return store;
  }
};
