import React from "react";

import {
  ChildFallbackContainer,
  ChildErrorText,
  SmallErrorIcon,
} from "./style";

const ChildFallback = () => {
  return (
    <ChildFallbackContainer>
      <SmallErrorIcon name="fas fa-exclamation-triangle" />
      <ChildErrorText>Something went wrong !!!</ChildErrorText>
    </ChildFallbackContainer>
  );
};

export default ChildFallback;
