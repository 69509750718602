import styled from "styled-components";
import Icon from "../Icon";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: flex-end;
`;

export const Text = styled.span`
  font-size: ${(props) => props.theme.text.para.small};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
export const StyledIcon = styled(Icon)`
  font-size: 15px;
  margin-right: 5px;
`;
