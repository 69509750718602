import { isDateCombinationValid } from "./date";

export const validateEmail = (emailId) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(emailId);
};

export const validatePassword6CharLong = (pass = "") => {
  // Must be at least 6 char long
  if (pass.length < 6) {
    return false;
  } else {
    return true;
  }
};

export const validatePassword8CharLongWithCharContainCheck = (pass = "") => {
  // Must be atleast 8 chars long and contain at least 1 uppercase char, 1 lowercase char, 1 digit and 1 special char
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
  return re.test(pass);
};

export const validateMobile = (phoneNo) => {
  const re = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
  return re.test(phoneNo);
};

export const validateUrl = (value) => {
  if (!value) {
    return false;
  }

  try {
    new URL(value.trim());
    const re =
      /(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/g;
    return re.test(value);
  } catch {
    return false;
  }
};

export const validateNumber = (value) => {
  if (!value) {
    return false;
  }
  return !isNaN(value);
};

export const validateInteger = (value) => {
  if (validateNumber(value)) {
    return Number.isInteger(Number(value));
  }
  return false;
};

export const isHtml = (value = "") => {
  if (!value) {
    return false;
  }

  return value.includes("<html>");
};

export const validateFileSize = (maxFileSize, value) => {
  let valid = true;
  Object.keys(value).map((index) => {
    if (value[index]?.size > maxFileSize) valid = false;
  });
  return valid;
};

export const validateDate = (value) => {
  if (!value) {
    return false;
  }
  const res = new Date(value);
  return !isNaN(res.getTime());
};

export const validateEndDate = (startDate, endDate) => {
  if (startDate) {
    if (!validateDate(endDate) || !validateDate(startDate)) {
      return false;
    }

    const { isValid } = isDateCombinationValid(startDate, endDate);
    return isValid;
  }
  return true;
};

export const validateHashTag = (value) => {
  if (typeof value !== "string" || value.charAt(0) !== "#") {
    return false;
  }
  return true;
};

export const validateMaximumCollaboratorsAllowed = (value, limit) => {
  if (Object.keys(value).length <= limit) {
    return true;
  }
  return false;
};
