import React, { useCallback, useMemo } from "react";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import Icon from "components/Icon";
import useForm from "hooks/useForm";
import { ListItem } from "components/List";
import { getUserListData } from "common/Api";

import { StyledForm } from "../style/create";
import useCreateLedgerCategory from "./useCreateLedgerCategory";
import { validateNumber } from "helper/validators";

const Form = ({ updateFormVisibility }) => {
  const { formData, values, resetForm, isFormValid, updateField } = useForm({
    fields: {
      name: {
        required: true
      },
      description: {},
      budget: {
        required: true,
        validator: validateNumber,
        invalidMessage: "Enter a valid amount"
      },
      collaborators: {
        required: true
      }
    }
  });

  const { isLoading, createLedgerCategoryData } = useCreateLedgerCategory();

  const onFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isFormValid()) {
        return;
      }
      createLedgerCategoryData(values, resetForm);
    },
    [isFormValid, values, resetForm]
  );

  const onDeselectUsers = useCallback(
    (itemId, item) => {
      const newListData = { ...values.collaborators };
      if (newListData[itemId]) {
        delete newListData[itemId];
      }
      updateField("collaborators", newListData);
    },
    [values]
  );

  const selectedUsersList = useMemo(() => {
    return (
      values && values?.collaborators && Object.values(values.collaborators)
    );
  }, [values]);

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <Icon name="fas fa-times" onClick={() => updateFormVisibility(false)} />
      <InputGroup
        label="Name"
        placeholder="Enter ledger name"
        {...formData["name"]}
      />

      <InputGroup
        label="Description"
        fieldType="textArea"
        placeholder="Enter description"
        size="lg"
        {...formData["description"]}
      />

      <InputGroup
        label="Budget (In Rupee)"
        placeholder="Enter budget  &#8377; XXXX"
        {...formData["budget"]}
      />

      <InputGroup
        label="Assign To"
        fieldType="asyncSearchSelect"
        placeholder="Search for users"
        api={getUserListData}
        {...formData["collaborators"]}
      />
      {selectedUsersList &&
        selectedUsersList?.map((item) => {
          return (
            <ListItem
              key={item.id}
              data={item}
              onSelect={onDeselectUsers}
              showDeselectButton={true}
            />
          );
        })}

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create
      </Button>
    </StyledForm>
  );
};

export default Form;
