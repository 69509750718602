export const createUserMappingBasedOnCompany = (
  collaborators = [],
  isLoggedInAsAdmin
) => {
  const obj = {};
  collaborators.forEach((item) => {
    obj[item?.relationships?.company?.data?.id] = {
      ...item,
      attributes: {
        ...item.attributes,
        actualCollaboratorType: item.attributes.collaboratorType,
        actualIsExecutive: item.attributes.isExecutive,
        collaboratorType: isLoggedInAsAdmin
          ? item.attributes.collaboratorType
          : "employee",
        isExecutive: isLoggedInAsAdmin ? item.attributes.isExecutive : false,
      },
    };
  });
  return obj;
};
