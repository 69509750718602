import Divider from "components/Divider";
import styled from "styled-components";

const getStatusColor = ({ $type }) => {
  switch ($type?.toLowerCase()) {
    case "pending":
      return "#7A3BCB";
    case "approved":
      return "#02C467";
    case "notified":
      return "#FF9600";
    case "cancelled":
      return "#0094E7";
    case "rejected":
      return "#FF0743";
    default:
      return "#FF9600";
  }
};

const getTagBgColor = ({ $type }) => {
  switch ($type?.toLowerCase()) {
    case "notified":
      return "#EFE3FF";
    case "approved":
      return "#DFFFE8";
    case "cancelled":
      return "#FFE1F7";
    case "rejected":
      return "#FFF0E3";
    case "full-time":
      return "#FFE7E7";
    case "half-time":
      return "#D2F4FF";
    default:
      return "#EFE3FF";
  }
};

export const MainContainer = styled.div`
  height: 100%;
  padding: 10px 50px 20px 50px;
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
`;

export const LeaveRecord = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 0 10px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.colors.lightPrimaryColor};
  border-bottom: 0;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.colors.lightPrimaryColor};
  }

  &:hover,
  :last-child:hover {
    border: 1px solid ${(props) => props.theme.colors.primaryColor};
    border-bottom: 1px solid ${(props) => props.theme.colors.primaryColor};
  }

  & * {
    flex-shrink: 0;
  }
`;

export const LeaveRecordSubContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const TextContent = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const SubText = styled.span`
  color: ${(props) => props.theme.colors.darkGrayColor};
`;

export const Tag = styled.span`
  color: ${(props) => props.$leaveTypeColor || getStatusColor(props)};
  background: ${(props) => props.$leaveTypeColor || getStatusColor(props)}20;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.small};
  line-height: ${(props) => props.theme.text.para.small};
  flex-shrink: 0;
  text-transform: capitalize;
`;

export const LeaveDetailsContainer = styled.div`
  display: flex;
`;

export const DetailsLeftContainer = styled.div`
  flex: 0.7;
  max-width: 50vw;
`;

export const DetailsRightContainer = styled.div`
  flex: 0.3;
  padding: 0px 50px;
`;

export const TextContainer = styled.div`
  white-space: pre-wrap;
`;

export const CollaboratorContainer = styled.div`
  display: flex;
  column-gap: 100px;
  flex-wrap: wrap;
`;

export const CollaboratorSubContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  & * {
    flex-shrink: 0;
  }
`;

export const DocumentContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
`;

export const TotalConatiner = styled.div`
  max-width: 100px;
  background-color: ${(props) => props.theme.colors.primaryColor};
  text-align: center;
  border-radius: 5px;
  padding: 5px 16px;
  font-weight: 700;
  font-size: ${(props) => props.theme.text.para.small};
`;

export const CardsContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 5px;

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: ${(props) => props.theme.colors.mediumGrayColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.primaryColor};
    border-radius: 10px;
  }

  & .no-document {
    gap: 10px;
    & img {
      width: 185px;
      height: 125px;
      margin-top: 10px;
    }
    & span {
      text-align: left;
      font-size: ${(props) => props.theme.text.para.medium};
    }
  }
`;

export const EachCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  font-size: ${(props) => props.theme.text.para.medium};
  font-weight: 500;
  word-wrap: break-word;
  margin: 20px 20px 20px 0px;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadow.medium};
`;

export const ImageContainer = styled.div`
  display: flex;
  position: relative;

  & div,
  img {
    width: 150px;
    height: 95px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px 5px 0px 0px;
  }

  & div:hover {
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  z-index: 10;
  display: flex;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.23);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

export const OtherImage = styled.img`
  object-fit: contain;
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`;

export const SmallImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

export const BigImage = styled.img`
  max-width: 500px;
  min-width: 280px;
  min-height: 200px;
  height: 100%;
  object-fit: contain;
  width: 100%;
  max-height: 500px;
`;

export const NameContainer = styled.div`
  background-color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 0px 0px 12px 12px;
  display: flex;
`;

export const Header = styled.div`
  font-weight: bold;
  text-align: left;
  margin: 10px 0;
  color: black;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FileName = styled.div`
  font-size: 1rem;
  margin-left: 8px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StatusContainer = styled.div`
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background: ${getStatusColor};
  color: #fff;
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const StatusUpdateContainer = styled(StatusContainer)`
  background: #fff;
  color: ${(props) => props.theme.colors.darkGrayColor}80;
  border: 1px solid ${(props) => props.theme.colors.mediumGrayColor};
  cursor: pointer;

  &:hover {
    background: ${getStatusColor};
    color: #fff;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 15px 0;
  max-width: 300px;
`;

export const StyledHeading = styled.div`
  max-width: 300px;
  color: ${(props) => props.$leaveTypeColor || props.theme.colors.primaryColor};
  background: ${(props) =>
    props.$leaveTypeColor
      ? `${props.$leaveTypeColor}30`
      : props.theme.colors.lightPrimaryColor};
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const AttachmentContainer = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
`;

export const ActivityMainContainer = styled.div`
  padding: 10px 0;
`;

export const ActivityContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  & :first-child {
    flex-shrink: 0;
  }
`;

export const ActivityContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const StatusChangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  color: #707070;
`;

export const LightText = styled.span`
  color: #707070;
`;

export const CommentDescription = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.text.para.small};
  line-height: 18px;
  color: black;
  white-space: pre-wrap;
  background: ${(props) => props.theme.colors.lightPrimaryColor}70;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  font-style: italic;
  margin: 4px 0;
`;

export const DarkTag = styled(Tag)`
  background: ${(props) => getStatusColor(props)};
  color: #fff;
`;
