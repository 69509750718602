import React, { useCallback, useRef, useState } from "react";
import Popover from "components/PopoverAdvanced2";
import Routes from "route/Path";
import {
  Container,
  UserName,
  StyledProfilePic,
  RedDot,
  ImageContainer,
  Tab,
} from "./style";

const User = ({ id, img, cdnImg, name, isInActive }) => {
  const [isActive, toggleActive] = useState(false);
  const btnRef = useRef(null);

  const toggleModal = useCallback(() => {
    toggleActive((val) => !val);
  }, []);

  return (
    <>
      <Container onClick={toggleModal} ref={btnRef}>
        <ImageContainer>
          <StyledProfilePic cdnImg={cdnImg} img={img} userName={name} />
          {isInActive && <RedDot />}
        </ImageContainer>
        <UserName>{name}</UserName>
      </Container>
      <Popover
        onClose={toggleModal}
        active={isActive}
        anchorElem={btnRef.current}
        userPreferedDirection="bottom"
      >
        <Tab
          to={`${Routes.authorized.userProfile.view.path}/${id}`}
          onClick={toggleModal}
        >
          View Profile
        </Tab>
      </Popover>
    </>
  );
};

export default User;
