import React, { useCallback } from "react";

// Takes in array of Tabs and returns the required tab with the passed data
export const useTabs = ({ allTabs, data }) => {
  const CurrentTab = useCallback(
    (index) => {
      let Container = null;

      if (!allTabs || allTabs.length === 0) return null;

      allTabs.forEach((Element, idx) => {
        if (idx + 1 === index) Container = Element;
      });

      if (Container) return <Container {...data} />;
      return null;
    },
    [data, allTabs]
  );
  return {
    CurrentTab,
  };
};
