import React from "react";

import GenericModal from "components/GenericModal";
import PostQuizCreate from "./PostQuizCreate";

import { ModalContainer, ModalContent } from "./style";

function PostQuizCreateModal({ onClose, className, ...rest }) {
  return (
    <GenericModal opacity={0.5}>
      <ModalContainer className={className} onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <PostQuizCreate onClose={onClose} {...rest} />
        </ModalContent>
      </ModalContainer>
    </GenericModal>
  );
}

PostQuizCreateModal.defaultProps = {
  className: "",
};
export default PostQuizCreateModal;
