import requestData from "ApiHandler";
import { createAuthUrl } from "helper/api";

export const getTeamsWorkLogApi = ({ pageNumber, companyId, modelableId }) => {
  const url = `/employee/companies/${companyId}/get_daily_work_reports_by_modelable?page=${pageNumber}&modelable_type=Team&modelable_id=${modelableId}`;
  return requestData(url, {
    method: "GET",
  });
};

export const createWorkLogApi = ({
  companyId,
  formData,
  modelableId,
  isAdmin,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/daily_work_reports?modelable_type=Team&modelable_id=${modelableId}`,
  });
  
  let daily_work_report = {
    accomplishments: formData.accomplishments,
    challenges: formData?.challenges || "",
    todo_plans: formData?.todo_plans || "",
    log_date: formData?.log_date,
    reference_links:
      formData?.referenceLinks?.length > 0
        ? formData.referenceLinks.split(",").map((item) => item?.trim())
        : "",
  };
  if (formData?.notifyTo && Object.keys(formData?.notifyTo).length > 0) {
    daily_work_report.allocated_carbon_copy_member_ids = Object.keys(
      formData?.notifyTo
    );
  }

  if (formData?.documents) {
    daily_work_report.documents = formData?.documents;
  }

  return requestData(authUrl, {
    method: "POST",
    body: {
      daily_work_report: daily_work_report,
    },
    isFormData: true,
  });
};

export const editWorkLogApi = ({
  companyId,
  formData,
  modelableId,
  dailyReportId,
  isAdmin,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/daily_work_reports/${dailyReportId}?modelable_id=${modelableId}&modelable_type=Team`,
  });

  let daily_work_report = {
    accomplishments: formData.accomplishments,
    challenges: formData?.challenges || "",
    todo_plans: formData?.todo_plans || "",
    log_date: formData?.log_date,
    reference_links:
      formData?.referenceLinks?.length > 0
        ? formData.referenceLinks
            .split(",")
            .map((item) => item?.trim())
            .filter((item) => item.length > 0)
        : "",
  };

  if (formData?.notifyTo && Object.keys(formData?.notifyTo).length > 0) {
    daily_work_report.allocated_carbon_copy_member_ids = Object.keys(
      formData?.notifyTo
    );
  }

  if (formData?.documents) {
    daily_work_report.documents = formData?.documents;
  }

  if (formData?.purgedNotifyTo) {
    daily_work_report.purge_allocated_carbon_copy_member_ids =
      formData?.purgedNotifyTo;
  }

  if (formData?.purgedDocuments) {
    daily_work_report.purge_documents_ids = formData?.purgedDocuments;
  }

  return requestData(authUrl, {
    method: "PUT",
    body: {
      daily_work_report: daily_work_report,
    },
    isFormData: true,
  });
};
