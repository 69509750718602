import React, { useCallback } from "react";

import FormContext from "../../../Context/FormContext";

const FormGroup = ({
  children,
  onSubmit,
  stopBubbling = false,
  onReset,
  ...otherProps
}) => {
  const forms = {};
  const setupInputForm = useCallback(
    (id, validator, reset, focusInput, required) => {
      forms[id] = { validator, reset, focusInput, required };
    },
    [forms]
  );

  const onFormReset = useCallback(() => {
    Object.keys(forms).forEach((id) => {
      if (typeof forms[id].reset === "function") {
        forms[id].reset();
      }
    });
    if (typeof onReset === "function") {
      onReset();
    }
  }, [forms]);

  const onFormSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (stopBubbling) {
        e.stopPropagation();
      }

      let formValid = true;
      let values = {};

      Object.keys(forms).forEach((id) => {
        if (
          typeof forms[id].validator === "function" &&
          typeof forms[id].focusInput === "function"
        ) {
          const result = forms[id].validator();
          const isRequired = forms[id].required;
          if (!result && isRequired) {
            formValid = false;
          } else if (typeof result === "boolean" && !result && !isRequired) {
            formValid = false;
          } else {
            values[id] = result;
          }
          forms[id].focusInput();
        }
      });

      if (formValid) {
        if (typeof onSubmit !== "function") {
          return;
        }
        onSubmit(values, onFormReset);
      }
    },
    [forms, onFormReset, onSubmit]
  );

  return (
    <form {...otherProps} onSubmit={onFormSubmit} onReset={onFormReset}>
      <FormContext.Provider value={setupInputForm}>
        {children}
      </FormContext.Provider>
    </form>
  );
};

export default FormGroup;
