export const getAccessType = function (path) {
  /**
     * should be of structure like -----
     * tabs: {
            admin: {},
            super_admin: {},
        },
     */
  const pageTypes = [];
  Object.keys(this).forEach((type) => {
    if (this[type][path]) {
      pageTypes.push(type);
    }
  });
  return pageTypes;
};

export const createPath = (path, drawerTitle, slug = null) => ({
  path,
  drawerTitle,
  slug,
});

/**
 * Used to get current Route object all paths
 * @returns
 */
export const getMyPaths = function () {
  const paths = {};
  Object.values(this).forEach((item) => {
    paths[item.path] = true;
  });
  return paths;
};

export const setNonIterableProperty = function (obj, key, value) {
  Object.defineProperty(obj, key, {
    value,
    enumerable: false,
  });
};

export const generateAccessType = (values = []) => {
  const res = {};
  values.forEach((item) => {
    res[item] = true;
  });
  return res;
};

/**
 * Order of components should be in order of the route object
 * @param {*} route
 * @param {*} getAccessType
 * @param {*} components
 * @returns
 */
export const createRouteObject = (route, getAccessType, components) => {
  return Object.values(route).map(({ path, slug }, index) => {
    return {
      path,
      ...(slug ? { slugPath: `${path}/:${slug}` } : {}),
      accessTypes: getAccessType(path),
      component: components[index],
    };
  });
};
