import { GET_SUBORDINATE_ENQUIRIES } from "../actiontypes";
import { UPDATE_COLLABORATOR_ID } from "../../FollowSubordinate/actiontypes";

import { createInitialStoreState, createNewStoreOnGetData } from "helper";

const initialState = createInitialStoreState({
  collaboratorId: ""
});

export const subordinateEnquiryReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_SUBORDINATE_ENQUIRIES}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${UPDATE_COLLABORATOR_ID}`:
      return {
        ...store,
        collaboratorId: payload.id,
        onceFetched: false
      };

    default:
      return store;
  }
};
