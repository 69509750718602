import React from "react";
import { TabProvider } from "./context";

export function TabContainer({
  children,
  reducer,
  initialData,
  onActionDispatch,
}) {
  return (
    <TabProvider
      reducer={reducer}
      initialData={initialData}
      onActionDispatch={onActionDispatch}
    >
      {children}
    </TabProvider>
  );
}
