export const statusOptions = [
  { name: "Pending", value: "pending" },
  { name: "Approved", value: "approved" },
  { name: "Rejected", value: "rejected" }
];

export const SignInOptions = [
  { name: "From Office", value: "from_office" },
  { name: "From Home", value: "from_home" },
  { name: "With Travel", value: "with_travel" }
]
