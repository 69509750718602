import React, { useMemo, useEffect, useCallback, useState } from "react";

import Icon from "../../Icon";
import useInput from "../useInput";
import useClipText from "../../../../Hooks/useClipText";

import {
  FilePickerContainer,
  FileInputContainer,
  FileInputButton,
  AttachmentContainer,
  Attachment,
  AttachmentText,
  DeleteAttachment,
  UploadText
} from "./style";
import { InputIcon } from "../style";
import { compressImageList } from "../../../../Helper/compression";

const FileAttachment = ({ name, setDeleteAttachmentName }) => {
  const { clippedText } = useClipText({ text: name, maxChar: 10 });

  const removeAttachment = () => {
    setDeleteAttachmentName(name);
  };

  return (
    <Attachment>
      <Icon name="fas fa-image" />
      <AttachmentText>{clippedText}</AttachmentText>
      <DeleteAttachment>
        <Icon name="fas fa-times" onClick={removeAttachment} />
      </DeleteAttachment>
    </Attachment>
  );
};

const GoogleFilePicker = ({
  size = "md",
  success = "",
  secondary = false,
  error = "",
  fieldClass = "",
  name,
  ...otherprops
}) => {
  const { icon, restInputProps } = useInput({
    ...otherprops,
    success,
    error
  });

  success = success ? "true" : undefined;
  error = error ? "true" : undefined;

  const [maxWidth, updateMaxWidth] = useState("auto");
  const [deleteAttachmentName, setDeleteAttachmentName] = useState("");
  const { value, onChange, multiple, allowCompression, ...remainingProps } =
    restInputProps;

  Array.prototype.removeDuplicates = function () {
    return this.filter((item, i, arr) => arr.indexOf(item) === i);
  };

  const fileName = useMemo(() => {
    if (!value || value.length === 0) {
      return null;
    }

    const names = [];

    Object.keys(value).map((file) => names.push(value[file].name));

    return names;
  }, [value]);

  const setRef = useCallback((r) => {
    if (!r) {
      return;
    }
    updateMaxWidth(r.clientWidth);
  }, []);

  useEffect(() => {
    if (deleteAttachmentName) {
      let filesOnDeletion = [];
      value.map((file) => {
        if (file.name !== deleteAttachmentName) filesOnDeletion.push(file);
      });

      if (filesOnDeletion.length == 0) filesOnDeletion = null;

      onChange({
        target: { value: filesOnDeletion, name }
      });

      setDeleteAttachmentName("");
    }
  }, [deleteAttachmentName, onChange, value]);

  const onFileAddition = useCallback(
    async (e) => {
      let filesOnAddition = Array.from(e.target.files);
      if (allowCompression) {
        // compression starts on file selection process
        filesOnAddition = await compressImageList(filesOnAddition);
      }
      if (!multiple || !value) {
        filesOnAddition = [...filesOnAddition];
      } else {
        filesOnAddition = [...value, ...filesOnAddition];
      }

      onChange({
        ...e,
        target: {
          name: e.target.name,
          value: filesOnAddition
        }
      });
    },
    [onChange, value, multiple]
  );

  return (
    <FilePickerContainer size={size} className={fieldClass}>
      <AttachmentContainer>
        {fileName &&
          fileName.map((fileName) => (
            <FileAttachment
              key={fileName}
              name={fileName}
              setDeleteAttachmentName={setDeleteAttachmentName}
            />
          ))}
      </AttachmentContainer>
      <FileInputContainer
        as="label"
        style={{ maxWidth }}
        ref={setRef}
        htmlFor={restInputProps.id}
        hasFiles={Array.isArray(fileName) && fileName.length > 0}
      >
        <FileInputButton>
          <Icon name="fas fa-cloud-upload-alt" />
          <UploadText>Add File</UploadText>
        </FileInputButton>
      </FileInputContainer>
      <input
        style={{ display: "none" }}
        type="file"
        multiple={multiple}
        name={name}
        {...remainingProps}
        onChange={onFileAddition}
      />
      {!!icon && <InputIcon success={success} error={error} name={icon} />}
    </FilePickerContainer>
  );
};

export default GoogleFilePicker;
