import React from "react";

export const Calendar = ({ color, ...otherProps }) => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        opacity="0.4"
        d="M0.930786 7.30705H16.1137"
        stroke={color}
        strokeWidth="1.27774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.09546 10.634C4.09546 10.2811 4.38149 9.99512 4.73433 9.99512H4.74222C5.09506 9.99512 5.38109 10.2811 5.38109 10.634C5.38109 10.9868 5.09506 11.2729 4.74222 11.2729H4.73433C4.38149 11.2729 4.09546 10.9868 4.09546 10.634ZM8.52216 9.99512C8.16932 9.99512 7.88329 10.2811 7.88329 10.634C7.88329 10.9868 8.16932 11.2729 8.52216 11.2729H8.53005C8.88289 11.2729 9.16892 10.9868 9.16892 10.634C9.16892 10.2811 8.88289 9.99512 8.53005 9.99512H8.52216ZM12.3021 9.99512C11.9493 9.99512 11.6632 10.2811 11.6632 10.634C11.6632 10.9868 11.9493 11.2729 12.3021 11.2729H12.31C12.6628 11.2729 12.9489 10.9868 12.9489 10.634C12.9489 10.2811 12.6628 9.99512 12.31 9.99512H12.3021ZM12.3021 13.3057C11.9493 13.3057 11.6632 13.5917 11.6632 13.9446C11.6632 14.2974 11.9493 14.5834 12.3021 14.5834H12.31C12.6628 14.5834 12.9489 14.2974 12.9489 13.9446C12.9489 13.5917 12.6628 13.3057 12.31 13.3057H12.3021ZM7.88329 13.9446C7.88329 13.5917 8.16932 13.3057 8.52216 13.3057H8.53005C8.88289 13.3057 9.16892 13.5917 9.16892 13.9446C9.16892 14.2974 8.88289 14.5834 8.53005 14.5834H8.52216C8.16932 14.5834 7.88329 14.2974 7.88329 13.9446ZM4.73433 13.3057C4.38149 13.3057 4.09546 13.5917 4.09546 13.9446C4.09546 14.2974 4.38149 14.5834 4.73433 14.5834H4.74222C5.09506 14.5834 5.38109 14.2974 5.38109 13.9446C5.38109 13.5917 5.09506 13.3057 4.74222 13.3057H4.73433Z"
        fill={color}
      />
      <path
        d="M11.9628 1V3.80318"
        stroke={color}
        strokeWidth="1.27774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.08158 1V3.80318"
        stroke={color}
        strokeWidth="1.27774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1285 2.34521H4.91585C2.41429 2.34521 0.851807 3.73875 0.851807 6.30027V14.009C0.851807 16.6108 2.41429 18.0366 4.91585 18.0366H12.1206C14.6301 18.0366 16.1847 16.635 16.1847 14.0735V6.30027C16.1926 3.73875 14.638 2.34521 12.1285 2.34521Z"
        stroke={color}
        strokeWidth="1.27774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
