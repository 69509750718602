import styled from "styled-components";
import Button from "../Button";
import { ImageComponent } from "../Image";

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
`;

export const Image = styled(ImageComponent)`
  width: 355.04px;
  height: 303.35px;
  margin-bottom: 40px;
  object-fit: contain;
`;
export const Heading = styled.span`
  font-weight: 600;
  font-size: ${(props) => props.theme.text.title.medium};
  line-height: 24px;
  text-align: center;
  margin: 0px 0 20px 0;
`;
export const Desc = styled.span`
font-weight: normal;
line-height: 20px
text-align: center;
margin-bottom: 30px;
`;

export const StyledButton = styled(Button)`
  width: 410px;
`;
