import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  padding: 0 50px 20px 50px;
  display: flex;
  background-color: white;
  z-index: 2000;
  position: absolute;
  width: 100%;
  margin: 0px 20px;
  flex-flow: column nowrap;
`;

export const StyledForm = styled.form`
  max-width: 780px;
  width: 100%;

  & > i {
    color: ${(props) => props.theme.colors.primaryColor};
    font-size: ${(props) => props.theme.text.title.large};
    margin: 20px 0 20px;
    cursor: pointer;
    float: right;
  }
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
