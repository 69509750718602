import { CreateComment as CommentCreate } from "components/Comment";
import { CommentCreateContainer } from "./style";

export function CreateComment({
  onComment,
  postId,
  isCreatingComment,
  ...rest
}) {
  const onCreateComment = (comment, onComplete) => {
    onComment && onComment(postId, comment, onComplete);
  };

  return (
    <CommentCreateContainer {...rest}>
      <CommentCreate
        onComment={onCreateComment}
        isCreatingComment={isCreatingComment}
      />
    </CommentCreateContainer>
  );
}
