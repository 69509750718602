export const generateFormData = (values) => {
  const {
    accountCreate_accountHolderName,
    accountCreate_accountNumber,
    accountCreate_accountType,
    accountCreate_accountBranchName,
    accountCreate_accountIFSCCode,
    accountCreate_accountBankName,
  } = values;

  return {
    account_holder_name: accountCreate_accountHolderName,
    account_number: accountCreate_accountNumber,
    account_type: accountCreate_accountType,
    branch_name: accountCreate_accountBranchName,
    ifsc_code: accountCreate_accountIFSCCode,
    bank_name: accountCreate_accountBankName,
  }
};
