import React from "react";

import { TaskContainer, TaskTitle } from "./style";
const Task = ({ title, style, className, hideTitle = false, ...other }) => {
  return (
    <TaskContainer style={style} className={className} {...other}>
      <TaskTitle $hideTitle={hideTitle}>{title}</TaskTitle>
    </TaskContainer>
  );
};

export default Task;
