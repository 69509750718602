import React, { useCallback, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "../../../../Common/Components/InputGroup";
import Button from "../../../../Common/Components/Button";
import useAlert from "../../../../Hooks/useAlert";

import { createDepartment, updateDepartment } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";
import { StyledFormGroup } from "../../../../GlobalStyles";

const Form = ({
  isEditMode = false,
  createDepartment,
  updateDepartment,
  currentCompnay,
  isInPopover = false,
  isAdmin,
}) => {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const { departmentCreate_departmentName } = values;
        const data = {
          companyId: currentCompnay,
          departmentName: departmentCreate_departmentName,
          isAdmin,
        };
        isEditMode
          ? await updateDepartment(data)
          : await createDepartment(data);
        showAlert({ message: "Department Created" });
        resetForm();
      } catch (err) {
        showAlert({ message: "Department Create Failed", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [isEditMode, currentCompnay, isAdmin]
  );

  const [btnSize, formStyle] = useMemo(() => {
    return [
      isInPopover ? "sm" : "md",
      { alignItems: isInPopover ? "center" : "flex-start" },
    ];
  }, [isInPopover]);

  return (
    <StyledFormGroup
      style={formStyle}
      stopBubbling={true}
      onSubmit={onFormSubmit}
    >
      <InputGroup
        label="Department"
        id="departmentCreate_departmentName"
        placeholder="Enter department name"
        required
        secondary
      />

      <Button
        size={btnSize}
        primary
        loading={isLoading}
        disabled={isLoading}
        type="submit"
      >
        {isEditMode ? "Update" : "Create"}
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const currentCompnay = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);

  return {
    currentCompnay,
    isAdmin,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createDepartment,
      updateDepartment,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
