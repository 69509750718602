import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";

import { createLedgerCategory } from "../actionCreator";

function useCreateLedgerCategory() {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const createLedgerCategoryData = async (values, onFormReset) => {
    try {
      updateLoading(true);
      await dispatch(
        createLedgerCategory({
          companyId,
          isAdmin,
          formData: values
        })
      );
      showAlert({ message: "Ledger Category Created" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
  };
  return { isLoading, createLedgerCategoryData };
}

export default useCreateLedgerCategory;
