import { FETCH_COMMENTS, CREATE_LOCAL_COMMENT_STORE } from "./actionTypes";
import { CREATE_POST_COMMENT } from "../Posts/actionTypes";
import { createNewStoreOnGetData, createInitialStoreState } from "helper";

function CommentsReducer(store = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_LOCAL_COMMENT_STORE: {
      const { postId } = payload;
      if (store[postId]) {
        return store;
      }

      return {
        ...store,
        [postId]: createInitialStoreState({}, true),
      };
    }

    case `${FETCH_COMMENTS}_SUCCESS`: {
      const { postId } = payload;
      return {
        ...store,
        [postId]: createNewStoreOnGetData(payload, store[postId], true),
      };
    }

    case `${CREATE_POST_COMMENT}_SUCCESS`: {
      const { postId } = payload;
      return {
        ...store,
        [postId]: createInitialStoreState({}, true),
      };
    }
    default:
      return store;
  }
}

export default CommentsReducer;
