import { keyframes } from "styled-components";

export const FadeInVertical = (position) => keyframes`
 0%{
   opacity:0;
   transform: translateY(${position === "top" ? "-20px" : "20px"});
 }
 100%{
  opacity:1;
  transform: translateY(0px);
 }
`;

export const FadeInHorizontal = (position) => keyframes`
 0%{
   opacity:0;
   transform: translateX(${position === "left" ? "-20px" : "20px"});
 }
 100%{
  opacity:1;
  transform: translateX(0px);
 }
`;

export const Bounce = keyframes`
 0%{
   transform: scale(0);
 }
 50%{
  transform: scale(1.3);
 }
 100% {
  transform: scale(1);
 }
`;
