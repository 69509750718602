import { separateLinksFromText } from "helper/string";
import React, { useMemo } from "react";

const TextWithLinks = ({ children, ...otherProps }) => {
  const updatedData = useMemo(() => {
    let result = "";
    if (typeof children === "string") {
      result = separateLinksFromText(children);
    }

    return result;
  }, [children]);

  return (
    <p {...otherProps} style={{ whiteSpace: "pre" }}>
      {updatedData?.length > 0
        ? updatedData?.map((el) => {
            return el.type == "link" ? (
              <a href={el.text} target="_blank">
                {el.text}
              </a>
            ) : (
              el.text
            );
          })
        : ""}
    </p>
  );
};

export default TextWithLinks;
