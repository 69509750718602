import {
  GET_SENT_NOTICES,
  GET_DRAFT_NOTICES,
  CREATE_NOTICE,
  PUBLISH_NOTICE,
  SET_NOTICE_ID,
  CLEAR_NOTICE_ID,
  GET_EMPLOYER_NOTICES,
  UPDATE_DRAFT_NOTICE,
  GET_SPECIFIC_NOTICE,
  SEARCH_NOTICES,
  UPDATE_SEARCH_PARAMS,
  RESET_SEARCH_PARAMS,
  DELETE_NOTICE,
} from "./actiontypes";

import {
  createNoticeApi,
  publishNoticeApi,
  getSentNoticesApi,
  getDraftNoticesApi,
  getEmployerNoticesApi,
  updateDraftNoticeApi,
  getSpecificNoticeApi,
  searchNoticeApi,
  deleteNoticeApi,
} from "./api";

import { createAsyncActionCreator } from "helper";

const createNoticeGenerator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_NOTICE,
    sideEffect: createNoticeApi({ companyId, isAdmin, formData }),
  };
};

const getSpecificNoticeGenerator = function* ({
  companyId,
  isAdmin,
  noticeId,
}) {
  yield {
    action: GET_SPECIFIC_NOTICE,
    sideEffect: getSpecificNoticeApi({ companyId, isAdmin, noticeId }),
  };
};

const publishNoticeGenerator = function* ({
  companyId,
  isAdmin,
  noticeId,
  formData,
  selectedUsersList,
}) {
  yield {
    action: PUBLISH_NOTICE,
    sideEffect: publishNoticeApi({
      companyId,
      isAdmin,
      noticeId,
      formData,
      selectedUsersList,
    }),
  };
};

const getSentNoticesGenerator = function* ({ companyId, isAdmin, pageNumber }) {
  yield {
    action: GET_SENT_NOTICES,
    sideEffect: getSentNoticesApi({ companyId, isAdmin, pageNumber }),
  };
};

const getDraftNoticesGenerator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: GET_DRAFT_NOTICES,
    sideEffect: getDraftNoticesApi({ companyId, isAdmin, pageNumber }),
  };
};

const getEmployerNoticesGenerator = function* ({ companyId, pageNumber }) {
  yield {
    action: GET_EMPLOYER_NOTICES,
    sideEffect: getEmployerNoticesApi({ companyId, pageNumber }),
  };
};

const updateDraftNoticeGenerator = function* ({
  companyId,
  noticeId,
  isAdmin,
  formData,
}) {
  yield {
    action: UPDATE_DRAFT_NOTICE,
    sideEffect: updateDraftNoticeApi({
      companyId,
      noticeId,
      isAdmin,
      formData,
    }),
  };
};

export const setNoticeId = ({ noticeId }) => {
  return {
    type: SET_NOTICE_ID,
    payload: {
      noticeId,
    },
  };
};

export const clearNoticeId = () => {
  return {
    type: CLEAR_NOTICE_ID,
  };
};

const searchNoticeGenerator = function* ({
  companyId,
  isAdmin,
  pageNumber,
  currentTitle,
  isPublished,
  currentFromDate,
  currentToDate,
}) {
  yield {
    action: SEARCH_NOTICES,
    payload: { isPublished },
    sideEffect: searchNoticeApi({
      companyId,
      isAdmin,
      pageNumber,
      currentTitle,
      isPublished,
      currentFromDate,
      currentToDate,
    }),
  };
};

export const updateSearchParams = ({
  title,
  from_date,
  to_date,
  is_published,
}) => ({
  type: UPDATE_SEARCH_PARAMS,
  payload: { title, from_date, to_date, is_published },
});

export const resetSearchParams = ({ is_published }) => ({
  type: RESET_SEARCH_PARAMS,
  payload: { is_published },
});

const deleteNoticeGenerator = function* ({ companyId, isAdmin, noticeId }) {
  yield {
    action: DELETE_NOTICE,
    sideEffect: deleteNoticeApi({ companyId, isAdmin, noticeId }),
  };
};

export const [
  createNotice,
  publishNotice,
  getSentNotices,
  getDraftNotices,
  getEmployerNotices,
  updateDraftNotice,
  getSpecificNotice,
  searchNotice,
  deleteNotice,
] = createAsyncActionCreator([
  createNoticeGenerator,
  publishNoticeGenerator,
  getSentNoticesGenerator,
  getDraftNoticesGenerator,
  getEmployerNoticesGenerator,
  updateDraftNoticeGenerator,
  getSpecificNoticeGenerator,
  searchNoticeGenerator,
  deleteNoticeGenerator,
]);
