export const generateFormData = (values) => {
  const {
    emergencyContactCreate_relationship,
    emergencyContactCreate_name,
    emergencyContactCreate_phone,
    emergencyContactCreate_address,
    emergencyContactCreate_note,
  } = values;

  return {
    relationship: emergencyContactCreate_relationship,
    name: emergencyContactCreate_name,
    phone: emergencyContactCreate_phone,
    address: emergencyContactCreate_address,
    note: emergencyContactCreate_note || "",
  };
};
