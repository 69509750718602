import React from "react";
import { withTheme } from "styled-components";

const Icon = ({ color, theme, ...otherProps }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      {...otherProps}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M4.80493 11.6508L7.35457 8.33701L10.2629 10.6215L12.7579 7.40137"
        stroke={theme.colors.primaryColor}
        strokeWidth="1.27774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="15.6663"
        cy="2.63738"
        r="1.63738"
        stroke={theme.colors.primaryColor}
        strokeWidth="1.27774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3467 1.71729H5.15583C2.5906 1.71729 1 3.534 1 6.09923V12.984C1 15.5493 2.55941 17.3582 5.15583 17.3582H12.4851C15.0503 17.3582 16.6409 15.5493 16.6409 12.984V6.98809"
        stroke={theme.colors.primaryColor}
        strokeWidth="1.27774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ActivityIcon = withTheme(Icon);
