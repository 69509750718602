import React, { useCallback, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup";
import Button from "components/Button";
import useAlert from "hooks/useAlert";

import { createBreak } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { iconOptions } from "./options";

import { StyledFormGroup } from "../../../../GlobalStyles";
import { FormHeading, Header } from "../../style/create";
import { generateFormData } from "../../utils";

const Form = ({ createBreak, isAdmin, companyId }) => {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const data = generateFormData(values);
        await createBreak({ companyId, isAdmin, formData: data });
        showAlert({ message: `Break Created` });
        resetForm();
      } catch (err) {
        showAlert({ message: "Break Creation Failed", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [companyId]
  );

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Create A Break</FormHeading>
      </Header>

      <InputGroup
        id="snapsCreate_breakTitle"
        required
        placeholder="Enter Title"
        label="Title"
      />

      <InputGroup
        id="snapsCreate_breakIcon"
        fieldType="chipsGroup"
        singleSelect
        required
        options={iconOptions}
        isIconList
        defaultSelectText="Select Icon"
        label="Select Icon Type"
      />

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { companyId, isAdmin };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createBreak,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
