import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import Icon from "components/Icon";
import { ListItem } from "components/List";
import useForm from "hooks/useForm";

import { getAllCollaboratorLeaves } from "../actionCreator";
import { getAllCollaboratorLeaveTypesOptions } from "../selector";
import { getUserListData } from "common/Api";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive
} from "common/Selectors/auth";
import { FormRow, SlotContainer, StyledForm } from "../../style/create";
import { SlotOptions } from "./options";
import useCreateLeave from "./useCreateLeave";
import { validateMaximumCollaboratorsAllowed } from "helper";

const Form = ({
  updateFormVisibility,
  leaveTypesOptions,
  companyId,
  isAdmin
}) => {
  const max3CollaboratorValidator = (values) => {
    return validateMaximumCollaboratorsAllowed(values, 3);
  };

  const { formData, values, resetForm, isFormValid, updateField } = useForm({
    fields: {
      collaboratorLeaveType: {
        required: true,
        invalidMessage: "Maximum 3 collaborators allowed"
      },
      fromDate: {
        required: true
      },
      fromSlot: {},
      toDate: {
        required: true
      },
      toSlot: {},
      reason: {
        required: true
      },
      applyTo: {
        required: true,
        validator: max3CollaboratorValidator,
        invalidMessage: "Maximum 3 collaborators allowed"
      },
      notifyTo: {
        required: false,
      },
      documents: {
        required: false
      }
    }
  });

  const { isLoading, applyLeave } = useCreateLeave();

  const onFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isFormValid()) {
        return;
      }
      applyLeave(values, resetForm);
    },
    [isFormValid, values, resetForm]
  );

  const onDeselectApplyToUsers = useCallback(
    (itemId, item) => {
      const newListData = { ...values.applyTo };
      if (newListData[itemId]) {
        delete newListData[itemId];
      }
      updateField("applyTo", newListData);
    },
    [values]
  );

  const onDeselectNotifyToUsers = useCallback(
    (itemId, item) => {
      const newListData = { ...values.notifyTo };
      if (newListData[itemId]) {
        delete newListData[itemId];
      }
      updateField("notifyTo", newListData);
    },
    [values]
  );

  const applyToList = useMemo(() => {
    if (values.applyTo) {
      return Object.values(values.applyTo).map((item) => {
        return (
          <ListItem
            key={item.id}
            data={item}
            onSelect={onDeselectApplyToUsers}
            selected={values.applyTo && values.applyTo[item.id]}
            showDeselectButton={true}
          />
        );
      });
    }
  }, [values.applyTo]);

  const notifyToList = useMemo(() => {
    if (values.notifyTo) {
      return Object.values(values.notifyTo).map((item) => {
        return (
          <ListItem
            key={item.id}
            data={item}
            onSelect={onDeselectNotifyToUsers}
            selected={values.notifyTo && values.notifyTo[item.id]}
            showDeselectButton={true}
          />
        );
      });
    }
  }, [values.notifyTo]);

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <Icon name="fas fa-times" onClick={() => updateFormVisibility(false)} />
      <InputGroup
        label="Leave Type"
        fieldType="advanceSelect"
        defaultSelectText="Select leave type"
        isSearchable={true}
        pageConfig={{
          api: getAllCollaboratorLeaves,
          extraParams: { companyId, isAdmin },
          tableName: "leaveRequisition"
        }}
        options={leaveTypesOptions}
        {...formData["collaboratorLeaveType"]}
      />

      <FormRow>
        <InputGroup
          label="From Date"
          fieldType="datePicker"
          placeholder="Enter from date"
          {...formData["fromDate"]}
        />

        <SlotContainer>
          <InputGroup
            fieldType="checkboxGroup"
            groupName="fromSlot"
            defaultSelectText="Select Slot"
            options={SlotOptions}
            {...formData["fromSlot"]}
          />
        </SlotContainer>
      </FormRow>

      <FormRow>
        <InputGroup
          label="To Date"
          fieldType="datePicker"
          placeholder="Enter to date"
          {...formData["toDate"]}
        />

        <SlotContainer>
          <InputGroup
            fieldType="checkboxGroup"
            groupName="toSlot"
            defaultSelectText="Select Slot"
            options={SlotOptions}
            {...formData["toSlot"]}
          />
        </SlotContainer>
      </FormRow>

      <InputGroup
        label="Reason"
        fieldType="textArea"
        placeholder="Enter reason for leave"
        size="lg"
        {...formData["reason"]}
      />

      <InputGroup
        label="Apply To"
        fieldType="asyncSearchSelect"
        placeholder="Search for users"
        api={getUserListData}
        {...formData["applyTo"]}
      />

      {applyToList}

      <InputGroup
        label="Notify To"
        fieldType="asyncSearchSelect"
        placeholder="Search for users"
        api={getUserListData}
        {...formData["notifyTo"]}
      />

      {notifyToList}

      <InputGroup
        label="Documents"
        fieldType="googleFilePicker"
        multiple={true}
        {...formData["documents"]}
      />

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create
      </Button>
    </StyledForm>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const leaveTypesOptions = getAllCollaboratorLeaveTypesOptions(store, props);
  return {
    companyId,
    isAdmin,
    leaveTypesOptions
  };
};

export default connect(mapStateToProps, null)(Form);
