export const FETCH_ALL_ATTENDANCE = "FETCH_ALL_ATTENDANCE";
export const GET_WORKING_SHIFT = "GET_WORKING_SHIFT";
export const MARK_SIGN_IN = "MARK_SIGN_IN";
export const MARK_SIGN_OUT = "MARK_SIGN_OUT";
export const RESET_ATTENDANCE_DATA = "RESET_ATTENDANCE_DATA";
export const SEARCH_ATTENDANCE_DATA = "SEARCH_ATTENDANCE_DATA";
export const SET_SEARCH_DATA = "SET_SEARCH_DATA";
export const GET_PRESENTEES = "GET_PRESENTEES";
export const GET_ABSENTEES = "GET_ABSENTEES";
export const RESET_ABSENTEES = "RESET_ABSENTEES";
export const RESET_PRESENTEES = "RESET_PRESENTEES";
export const CREATE_WORK_LOG = "CREATE_WORK_LOG";
export const UPDATE_ATTENDANCE_ID = "UPDATE_ATTENDANCE_ID";
export const EDIT_WORK_LOG = "EDIT_WORK_LOG";
export const GET_WORKING_HOUR_LOG_ACTIVITY = "GET_WORKING_HOUR_LOG_ACTIVITY";
export const CLEAR_WORKING_HOUR_LOG_ACTIVITY =
  "CLEAR_WORKING_HOUR_LOG_ACTIVITY";
