import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  z-index: -1;
`;

export const TimePeriod = styled.div`
  display: flex;
  border-top: 1px #cecece solid;
  position: relative;
`;

export const Time = styled.span`
  position: sticky;
  left: 0;
  top: 50%;
  z-index: 2;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  color: #4f4f4f;
`;
