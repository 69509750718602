import styled from "styled-components";

export const CountdownContainer = styled.div`
  max-width: 280px;
  width: 100%;
  display: flex;
  height: 230px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  margin: 0px;
  background-color: ${(props) => props.theme.colors.primaryColor};
`;

export const Title = styled.p`
  font-size: ${(props) => props.theme.text.title.large};
  line-height: ${(props) => props.theme.text.title.large};
  color: white;
  margin: 10px 0px 20px 0px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const TimerContainer = styled.div`
  background-color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
`;

export const DateCell = styled.div`
  background-color: ${(props) => props.theme.colors.primaryColor};
  width: 35px;
  height: 35px;
  border-radius: 7px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
`;

export const DateType = styled.p`
  text-align: center;
  text-transform: uppercase;
  font-size: 0.4rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primaryColor};
`;

export const TimerCell = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 5px;
`;

export const DateContainer = styled.p`
  font-size: ${(props) => props.theme.text.para.extraSmall};
  color: white;
  margin: 0px;
  margin-top: 10px;
  margin-left: auto;
  font-weight: bold;
`;

export const ToggleButton = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  height: 30px;
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  width: 30px;
  position: absolute;
  border-radius: 50%;
`;

export const MultiCountdownContainer = styled.div`
  position: relative;
  margin: 0px;
  padding: 0px;
  height: 230px;
  max-width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CountdownsContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 230px;
  max-width: 280px;
  width: 100%;
`;

export const CountdownWrapper = styled.div`
  position: absolute;
  width: 100%;
  transition: 1s;
  right: ${(props) => props.$right}px;
`;
