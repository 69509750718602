import {
  CREATE_LEAVE_TYPE,
  DELETE_LEAVE_TYPE,
  EDIT_LEAVE_TYPE,
  GET_ALL_LEAVE_TYPES,
} from "./actiontypes";
import { createAsyncActionCreator } from "helper";
import {
  createLeaveTypeApi,
  deleteLeaveTypeApi,
  editLeaveTypeApi,
  getAllLeaveTypesApi,
} from "./api";

const createLeaveTypeGenrator = function* ({
  companyId,
  leaveType,
  color,
  isAdmin
}) {
  yield {
    action: CREATE_LEAVE_TYPE,
    sideEffect: createLeaveTypeApi({ companyId, leaveType, color, isAdmin })
  };
};

const getAllLeaveTypesGenrator = function* ({
  pageNumber,
  companyId,
  isAdmin
}) {
  yield {
    action: GET_ALL_LEAVE_TYPES,
    sideEffect: getAllLeaveTypesApi({ pageNumber, companyId, isAdmin })
  };
};

const editLeaveTypeGenrator = function* ({
  companyId,
  leaveTypeId,
  leaveType,
  color,
  status,
  isAdmin,
}) {
  yield {
    action: EDIT_LEAVE_TYPE,
    sideEffect: editLeaveTypeApi({
      companyId,
      leaveTypeId,
      leaveType,
      color,
      status,
      isAdmin,
    }),
  };
};

const deleteLeaveTypeGenrator = function* ({
  leaveTypeId,
  companyId,
  isAdmin,
}) {
  yield {
    action: DELETE_LEAVE_TYPE,
    sideEffect: deleteLeaveTypeApi({ leaveTypeId, companyId, isAdmin }),
  };
};

export const [
  createLeaveType,
  getAllLeaveTypes,
  editLeaveType,
  deleteLeaveType,
] = createAsyncActionCreator([
  createLeaveTypeGenrator,
  getAllLeaveTypesGenrator,
  editLeaveTypeGenrator,
  deleteLeaveTypeGenrator,
]);
