import styled from "styled-components";
import { SingleRectangleSkeleton } from "components/Skeleton";

import { Card } from "../../../../styles/employee";
import Icon from "../../../../../../Common/Components/Icon";

export const CardContainer = styled(Card)`
  padding: 24px;
`;
export const Heading1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #efefef;

  & .head {
    font-weight: bold;
    font-size: ${(props) => props.theme.text.para.medium};
    color: black;
  }

  & .small {
    font-weight: bold;
    font-size: ${(props) => props.theme.text.para.small};
    color: #707070;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: 123px;
  object-fit: contain;
  border-radius: 5px;
  margin: 18px auto 18px 0px;
`;

export const ImageSkeleton = styled(SingleRectangleSkeleton)`
  width: 182px;
  height: 123px;
  border-radius: 5px;
  margin: 18px auto 18px 0px;
`;

export const TextSkeleton = styled(SingleRectangleSkeleton)`
  width: 60%;
  height: 10px;
`;
export const InfoIcon = styled(Icon)`
  margin-left: 10px;
  margin-top: 3px;
  font-size: small;
  color: ${(props) => props.theme.colors.button.primary.background};
`;
export const Description = styled.p`
  color: white;
  max-width: 300px;
  margin: 10px 0px;
`;
export const OptionalTextDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  & .head2 {
    font-size: ${(props) => props.theme.text.para.small};
    color: ${(props) => props.theme.colors.primaryColor};
    text-transform: capitalize;
  }
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  & .head {
    font-weight: bold;
    font-size: ${(props) => props.theme.text.para.medium};
    color: ${(props) => props.theme.colors.primaryColor};
    text-transform: capitalize;
  }
`;
