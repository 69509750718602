import { useCallback, useEffect, useMemo, useState } from "react";
import useAlert from "hooks/useAlert";
import useForm from "hooks/useForm";
import { generateCreateTeamFormData } from "../utils";
import { createTeam, updateTeam } from "../actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

export const useTeamCreate = ({
  updateIsFormSubmitted,
  initialData,
  isEditOpen,
}) => {
  const [isLoading, updateLoading] = useState(false);
  const dispatch = useDispatch();

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const teamId = useSelector((store) => store.teams.owners.teamId);

  const { showAlert } = useAlert();
  const { formData, isFormValid, values, resetForm, updateField } = useForm({
    fields: {
      name: {
        required: true,
      },
      owners: {
        required: true,
      },
      status: {
        required: true,
      },
      department: {
        required: false,
      },
    },
  });

  const onDeselectUsers = useCallback(
    (itemId, item) => {
      const newListData = { ...values.owners };
      if (newListData[itemId]) {
        delete newListData[itemId];
      }
      updateField("owners", newListData);
    },
    [values]
  );

  const onFormSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        if (!isFormValid()) {
          return;
        }
        updateLoading(true);
        const data = generateCreateTeamFormData(values);
        if (!isEditOpen) {
          await dispatch(createTeam({ companyId, isAdmin, formData: data }));
        } else {
          await dispatch(
            updateTeam({ companyId, isAdmin, formData: data, teamId })
          );
        }
        showAlert({ message: `Team ${isEditOpen ? "Updated" : "Created"}` });
        resetForm();
        if (typeof updateIsFormSubmitted === "function" && !isEditOpen) {
          updateIsFormSubmitted(true);
        } else {
          updateIsFormSubmitted(false);
        }
      } catch (err) {
        showAlert({ message: "Team creation failed", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [companyId, isAdmin, values, dispatch, teamId, isEditOpen]
  );

  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      const keys = Object.keys(initialData);
      keys.forEach((el) => {
        updateField(el, initialData[el]);
      });
    }
  }, []);

  const selectedUsersList = useMemo(() => {
    return values && values?.owners && Object.values(values.owners);
  }, [values]);

  return {
    formData,
    onFormSubmit,
    onDeselectUsers,
    selectedUsersList,
    isLoading,
  };
};
