import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & button {
    min-width: 350px;
  }

  & button:hover {
    background: ${(props) => props.theme.colors.button.primary.background};
    color: ${(props) => props.theme.colors.button.primary.color};
  }

  & .activity_indicator {
    border-left-color: white !important;
    border-top-color: white !important;
    border-bottom-color: white !important;
  }
`;
