import useAlert from "hooks/useAlert";
import { createAccessAsset, editAccessAssets } from "modules/CompanyAssets/actionCreator";
import { generateFormData } from "modules/CompanyAssets/utils";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

export const useFormDetails = () => {
    const { showAlert } = useAlert();
    const dispatch = useDispatch()
    const [isLoading, updateLoading] = useState(false)

    const saveAccessAsset =
        async (editId, isAdmin, companyId, isEditMode, values, goBack, resetForm) => {
            const action = isEditMode ? editAccessAssets : createAccessAsset;
            try {
                updateLoading(true);
                const formData = generateFormData(values);
                await dispatch(action({ branchId: editId, companyId, isAdmin, formData }));
                showAlert({
                    message: `Company Asset Detail ${editId ? "Updated" : "Created"}`,
                });
                if (isEditMode) {
                    typeof (goBack) === 'function' && goBack()
                    return;
                }
                typeof (resetForm) === 'function' && resetForm()

            } catch (err) {
                showAlert({ message: `Company Asset ${editId ? "Update" : "Create"} Failed`, type: "error" });
            } finally {
                updateLoading(false);
            }
        }
    return { saveAccessAsset, isLoading }
}

