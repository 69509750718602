import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";

import Paginator from "components/Paginator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";
import { getAllLeaves } from "../actionCreator";

import { PaginatorBox, StyledActionArea } from "globalStyles";
import { ButtonContainer, StyledButton } from "../../style/display";

const ActionArea = ({
  updateFormVisibility,
  onceFetched,
  companyId,
  isAdmin,
  updateLeavesApplied,
  isLeavesApplied,
  onLoading,
  style,
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: getAllLeaves,
    tableName: "leaveRequisition",
  });

  const extraParams = {
    companyId,
    isAdmin,
  };

  const { fetchFirstPage, fetchNextPage, fetchPrevPage, fetchSpecificPage } =
    usePaginator({ extraParams, paginatorApi: getNextPage });

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched) {
      onLoading(true);
      fetchFirstPage();
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <StyledActionArea style={style}>
      <ButtonContainer style={{ width: "100%" }}>
        <StyledButton onClick={() => updateFormVisibility(true)} primary>
          Create New
        </StyledButton>
        <StyledButton outline onClick={updateLeavesApplied}>
          {isLeavesApplied ? "Applied Leaves" : "Leaves Balance"}
        </StyledButton>
      </ButtonContainer>

      <PaginatorBox style={{ display: isLeavesApplied ? "none" : "flex" }}>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </StyledActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);

  return {
    companyId,
    onceFetched: store.leaveRequisition.onceFetched,
    isAdmin,
  };
};

export default connect(mapStateToProps, null)(ActionArea);
