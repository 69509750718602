export const generateFormData = (values) => {
  const {
    upiCreate_upiHolderName,
    upiCreate_upiId,
  } = values;

  return {
    upi_holder_name: upiCreate_upiHolderName,
    upi_id: upiCreate_upiId,
  }
};
