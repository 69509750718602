import React from "react";
import Icon from "../../Icon";
import Button from "components/Button";

export function OptionButton({
  postId,
  btnClassName,
  btnText,
  icon,
  btnProps,
  iconProps
}) {
  const { onClick, ...restBtnProps } = btnProps;
  return (
    <Button
      className={`post_delete_btn ${btnClassName}`}
      onClick={() => onClick(postId)}
      {...restBtnProps}
    >
      <Icon name={icon} {...iconProps} /> {btnText}
    </Button>
  );
}
