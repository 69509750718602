import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import { StyledForm } from "../../../style/create";

const ProfessionalForm = ({ formData, isFormValid, onSubmit }) => {
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (isFormValid() && typeof onSubmit === "function") {
      onSubmit();
    }
  };

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <InputGroup
        fieldType="input"
        placeholder="Enter your designation"
        label="Designation"
        {...formData["designation"]}
      />
      <InputGroup
        fieldType="input"
        placeholder="Enter your organization name"
        label="Organization"
        {...formData["organization"]}
      />
      <InputGroup
        fieldType="textArea"
        placeholder="Enter your job description"
        label="Job description"
        size="lg"
        {...formData["jobDescription"]}
      />
      <InputGroup
        fieldType="datePicker"
        placeholder="Enter date"
        label="Working From"
        {...formData["workingFrom"]}
      />
      <InputGroup
        fieldType="datePicker"
        placeholder="Enter date"
        label="Worked till"
        {...formData["workedTill"]}
      />
      <InputGroup
        fieldType="googleFilePicker"
        label="Upload employment proof"
        {...formData["employmentDocument"]}
      />

      <Button primary type="submit">
        Create
      </Button>
    </StyledForm>
  );
};

export default ProfessionalForm;
