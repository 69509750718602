import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import ErrorBoundary from "../ErrorBoundary";
import OnlyWhenAuthorized from "../Auth/OnlyWhenAuthorized";

import {
  StyledDrawerTab,
  DrawerIcon,
  DrawerTabTitle,
  StyledCollapse,
  DrawerTabImage
} from "./style";

const DrawerHead = ({
  icon,
  image,
  SvgComponent,
  title,
  active = false,
  onClick
}) => {
  return (
    <StyledDrawerTab onClick={onClick} active={active}>
      {!!image && <DrawerTabImage src={image} />}
      {!!icon && <DrawerIcon name={icon} />}
      {SvgComponent ? SvgComponent : null}
      <DrawerTabTitle active={active}>{title}</DrawerTabTitle>
    </StyledDrawerTab>
  );
};

DrawerHead.defaultProps = {
  icon: null,
  image: null,
  SvgComponent: null,
  title: "",
  active: false
};

const DrawerTab = React.forwardRef(
  (
    {
      children,
      title,
      icon,
      image,
      SvgComponent,
      links = [],
      accessType,
      ...otherProps
    },
    ref
  ) => {
    const location = useLocation();
    const isTabActive = useMemo(() => {
      const res = links.includes(location.pathname);
      return res;
    }, [links, location.pathname]);
    return (
      <OnlyWhenAuthorized requiredAccess={accessType}>
        <ErrorBoundary type="child">
          {children ? (
            <StyledCollapse
              ref={ref}
              shouldBeCollapsed={isTabActive}
              header={
                <DrawerHead
                  active={isTabActive}
                  title={title}
                  icon={icon}
                  image={image}
                  SvgComponent={SvgComponent}
                />
              }
            >
              {children}
            </StyledCollapse>
          ) : (
            <DrawerHead
              active={isTabActive}
              title={title}
              icon={icon}
              image={image}
              SvgComponent={SvgComponent}
              {...otherProps}
            />
          )}
        </ErrorBoundary>
      </OnlyWhenAuthorized>
    );
  }
);

export default DrawerTab;
