import { createSelector } from "reselect";

const storeData = (store) => store.dashboard.holiday.data;

export const todayHolidaySelector = createSelector(storeData, (data) => {
  let result = {};
  Object.keys(data).forEach((id) => {
    if (typeof data[id] === "function") {
      return;
    }
    const holidayItem = data[id] ? data[id].attributes : {};
    result = {
      name: holidayItem.reason,
      image: holidayItem.holidayCardImageUrl,
      date: holidayItem.holidayDate,
      isOptional: holidayItem.isOptional,
    };
  });
  return result;
});
