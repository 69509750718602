import React, { useMemo } from "react";
import Tooltip from "components/Tooltip2";
import Icon from "components/Icon";

import { Cell, CellActionArea, EditableButton } from "./styles";

const ActionItem = ({
  id,
  icon,
  tootltipText,
  btnProps,
  rowIndex,
  tooltipProps,
}) => {
  return (
    <>
      <EditableButton
        data-tooltip={id}
        {...btnProps}
        onClick={(e) => {
          btnProps.onClick && btnProps.onClick(e, rowIndex);
        }}
      >
        <Icon name={icon} />
      </EditableButton>
      <Tooltip tooltipId={id} {...tooltipProps}>
        {tootltipText}
      </Tooltip>
    </>
  );
};

export const ActionCell = ({ data, rowIndex, cellActions }) => {
  const actions = useMemo(() => {
    return cellActions.map((action, idx) => {
      return (
        <ActionItem
          key={`${action.id}_${idx}`}
          rowIndex={rowIndex}
          {...action}
        />
      );
    });
  }, [cellActions, rowIndex]);
  return (
    <Cell>
      {data}
      <CellActionArea>{actions}</CellActionArea>
    </Cell>
  );
};
