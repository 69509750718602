import styled from "styled-components";

export const MainContainer = styled.div`
  width: 300px;
  min-height: 200px;
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;
`;

export const StringContainer = styled(MainContainer)`
  padding: 0;
`;

export const StyledStringContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  // word-break: break-all;
  line-height: 23px;
`;

export const StringHeadingBox = styled.div`
  width: 100%;
  font-weight: bold;
  text-transform: capitalize;
  background-color: ${(props) => props.theme.colors.warningColor};
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

export const StringDataWrapper = styled.span`
  &:hover {
    background-color: ${({ $isClipped }) =>
      $isClipped ? "lightyellow" : "transparent"};
  }
`;
