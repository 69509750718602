import { FETCH_COLLABORATOR_DETAILS } from "./actiontypes";
import { createAsyncActionCreator } from "helper";
import { fetchCollaboratorDetailsApi } from "./api";

const fetchCollaboratorDetailsGenerator = function* ({
  companyId,
  collaboratorId,
  isAdmin,
}) {
  yield {
    action: FETCH_COLLABORATOR_DETAILS,
    sideEffect: fetchCollaboratorDetailsApi({
      companyId,
      collaboratorId,
      isAdmin,
    }),
  };
};

export const [fetchCollaboratorDetails] = createAsyncActionCreator([
  fetchCollaboratorDetailsGenerator,
]);
