import InputGroup from "components/InputGroup";
import MultipleImageViewer from "components/Grid/MultipleImageViewer";

import { addressProofType } from "../options";

import { StyledImageViewer } from "../../../style/create";

export const AddressForm = ({
  presentData,
  initialPermanentAddressProofType,
  permanentAddressDocumentRequiredStatus,
  initialPresentAddressProofType,
  presentAddressDocumentRequiredStatus
}) => {
  return (
    <>
      <InputGroup
        id="personalInfoCreate_permanentAddress"
        fieldType="textArea"
        required
        placeholder="Enter your Permanent Address"
        label="Permanent Address"
        size="lg"
        initialValue={presentData?.permanentAddress}
      />

      <InputGroup
        id="personalInfoCreate_permanentAddressProofType"
        fieldType="select"
        required
        options={addressProofType}
        defaultSelectText="Select Permanent Address Proof Type"
        label="Permanent Address Proof Type"
        initialValue={initialPermanentAddressProofType}
      />

      {!permanentAddressDocumentRequiredStatus &&
        <StyledImageViewer>
          <label>Previously Uploaded Document</label>
          <MultipleImageViewer data={[presentData?.permanentAddressProofDocumentUrl]} />
        </StyledImageViewer>}

      <InputGroup
        id="personalInfoCreate_permanentAddressProof"
        fieldType="googleFilePicker"
        required={permanentAddressDocumentRequiredStatus}
        accept="image/*,.pdf"
        allowCompression
        label={`Upload Permanent Address Proof ${!permanentAddressDocumentRequiredStatus ? "(Upload new file to update)" : ""}`}
      />

      <InputGroup
        id="personalInfoCreate_presentAddress"
        fieldType="textArea"
        required
        placeholder="Enter your Present Address"
        label="Present Address"
        size="lg"
        initialValue={presentData?.presentAddress}
      />

      <InputGroup
        id="personalInfoCreate_presentAddressProofType"
        fieldType="select"
        required
        options={addressProofType}
        defaultSelectText="Select Present Address Proof Type"
        label="Present Address Proof Type"
        initialValue={initialPresentAddressProofType}
      />

      {!presentAddressDocumentRequiredStatus &&
        <StyledImageViewer>
          <label>Previously Uploaded Document</label>
          <MultipleImageViewer data={[presentData?.presentAddressProofDocumentUrl]} />
        </StyledImageViewer>}

      <InputGroup
        id="personalInfoCreate_presentAddressProof"
        fieldType="googleFilePicker"
        required={presentAddressDocumentRequiredStatus}
        accept="image/*,.pdf"
        allowCompression
        label={`Upload Present Address Proof ${!presentAddressDocumentRequiredStatus ? "(Upload new file to update)" : ""}`}
      />
    </>
  )
}