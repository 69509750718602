import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import TabsRenderer from "./TabsRenderer";
import DrawerLink from "../DrawerLink";
import { generateDrawerLinks } from "helper";

import { TeaIcon } from "../../../../AssetComponents";

const [BreaksTabLinks, BreaksLinkComponents] = generateDrawerLinks(
  Routes.authorized.breaks,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

export const BreaksTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType("breaks_section")}
    >
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("breaks")}
        SvgComponent={<TeaIcon />}
        title="Breaks"
        links={BreaksTabLinks}
      >
        {BreaksLinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
