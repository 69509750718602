import styled from "styled-components";

export const Cell = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const CellActionArea = styled.div`
  display: flex;
  align-items: center;
  & > button ~ button {
    margin-left: 10px;
  }
`;

export const EditableButton = styled.button.attrs(() => ({
  className: "action_icon",
}))`
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: transparent;
  justify-content: center;
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;
  transition: background-color ease-out 0.2s, color ease 0.2s,
    transform ease 0.2s, opacity ease 0.2s;
  &:hover {
    ${({ disabled }) => {
      if (!disabled) {
        return `
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      `;
      } else {
        return "cursor: not-allowed";
      }
    }}
  }
`;
