import React, { useMemo } from "react";

import Tooltip from "components/Tooltip2";

import PostCreateItem from "./PostCreateItem";
import Mountain_Img from "assets/employee/mountain.svg";
import Video_Img from "assets/employee/video.svg";
import Poll_Img from "assets/employee/poll.svg";
import Quiz_Img from "assets/employee/quiz.svg";

import { PostsTypes } from "./constants";
import { PostItemsContainer } from "./style";

const Items = [
  {
    title: "Photo",
    image: Mountain_Img,
    background: "#EBF4FF",
    type: PostsTypes.image,
  },
  {
    title: "Video",
    image: Video_Img,
    background: "#EDF9E5",
    type: PostsTypes.video,
    disabled: true,
    tooltip: "Coming soon",
  },
  {
    title: "Poll",
    image: Poll_Img,
    background: "#FFF3E0",
    type: PostsTypes.poll,
  },
  {
    title: "Quiz",
    image: Quiz_Img,
    background: "#FFEDED",
    type: PostsTypes.quiz,
  },
];

function PostCreateItemList({ onClick }) {
  const list = useMemo(() => {
    return Items.map((item, idx) => {
      return (
        <>
          <PostCreateItem
            key={`${item.title}_${idx}`}
            style={{ backgroundColor: item.background }}
            image={item.image}
            title={item.title}
            onClick={item.disabled ? null : () => onClick(item.type)}
            data-tooltip={`${item.title}_${idx}`}
            $disabled={item.disabled}
          />
          {!!item.tooltip && (
            <Tooltip tooltipId={`${item.title}_${idx}`}>{item.tooltip}</Tooltip>
          )}
        </>
      );
    });
  }, [onClick]);

  return <PostItemsContainer>{list}</PostItemsContainer>;
}

export default PostCreateItemList;
