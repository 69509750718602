import React, { useCallback, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup";
import Icon from "components/Icon";
import Button from "components/Button";
import useAlert from "hooks/useAlert";

import { createFamilyDetails } from "../../actionCreator";
import { getCurrentUserCompany } from "common/Selectors/auth";

import { relationOptions, isMedicalClaimInclusiveOption } from "./options";

import { StyledFormGroup } from "globalStyles";
import { FormHeading, Header } from "../../style/create";
import { generateFormData } from "../../utils";
import { validateEmail, validateMobile } from "helper";

const Form = ({ setIsFormVisible, createFamilyDetails, companyId }) => {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const data = generateFormData(values);
        await createFamilyDetails({ companyId, formData: data });
        showAlert({ message: `Family Details Created` });
        resetForm();
        setIsFormVisible(false);
      } catch (err) {
        showAlert({ message: "Family Details Create Failed", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [companyId]
  );

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Add family details</FormHeading>
        <Icon name="fas fa-times" onClick={updateFormVisibilty} />
      </Header>

      <InputGroup
        id="familyCreate_familyMemberName"
        fieldType="input"
        required
        placeholder="Enter name"
        label="Family Member Name"
      />

      <InputGroup
        id="familyCreate_familyMemberRelation"
        fieldType="select"
        required
        options={relationOptions}
        defaultSelectText="Select Relation"
        label="Relation"
      />

      <InputGroup
        id="familyCreate_familyMemberDOB"
        fieldType="datePicker"
        placeholder="Enter Date of Birth"
        label="Date of Birth (Optional)"
      />

      <InputGroup
        id="familyCreate_familyMemberPhone"
        fieldType="input"
        inputMode="tel"
        required
        placeholder="Enter phone"
        label="Phone"
        validator={validateMobile}
        invalidMessage="Enter a valid phone number"
      />

      <InputGroup
        id="familyCreate_familyMemberEmail"
        fieldType="input"
        inputMode="email"
        placeholder="Enter email"
        label="Email (Optional)"
        validator={validateEmail}
        invalidMessage="Enter a valid email ID"
      />

      <InputGroup
        id="familyCreate_familyMemberOccupation"
        fieldType="input"
        placeholder="Enter Occupation"
        label="Occupation (Optional)"
      />

      <InputGroup
        id="familyCreate_familyMemberMedicalClaimCheck"
        fieldType="checkboxGroup"
        options={isMedicalClaimInclusiveOption}
        groupName="familyCreate_familyMemberMedicalClaimCheck"
      />

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  return { companyId };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createFamilyDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
