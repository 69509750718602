import {
  CREATE_COUNTDOWN,
  GET_COUNTDOWNS,
  EDIT_COUNTDOWN,
  DELETE_COUNTDOWN,
} from "./actiontypes";

import {
  createInitialStoreState,
  createNewStoreOnGetData,
  createNewStoreOnCreate,
  createNewStoreOnUpdate,
  createNewStoreOnDelete,
} from "helper";

const initialState = createInitialStoreState({}, true);

export const countdownReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_COUNTDOWNS}_SUCCESS`:
      return createNewStoreOnGetData(payload, store, true);

    case `${CREATE_COUNTDOWN}_SUCCESS`:
      return createNewStoreOnCreate(payload, store, true, true);

    case `${EDIT_COUNTDOWN}_SUCCESS`:
      return createNewStoreOnUpdate(payload, store, true);

    case `${DELETE_COUNTDOWN}_SUCCESS`:
      return createNewStoreOnDelete(payload, store);

    default:
      return store;
  }
};
