import { StyledActionArea } from "globalStyles/view";
import styled from "styled-components";
import Button from "components/Button";

const getStatusColor = ({ $type }) => {
  switch ($type?.toLowerCase()) {
    case "pending":
      return "#7A3BCB";
    case "approved":
      return "#02C467";
    case "notified":
      return "#FF9600";
    case "cancelled":
      return "#0094E7";
    case "rejected":
      return "#FF0743";
    default:
      return "#FF9600";
  }
};

export const MainContainer = styled.div`
  height: 100%;
  padding: 10px 50px 20px 50px;
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
`;

export const ReportRecord = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 0 10px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.colors.lightPrimaryColor};
  border-bottom: 0;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.colors.lightPrimaryColor};
  }

  &:hover,
  :last-child:hover {
    border: 1px solid ${(props) => props.theme.colors.primaryColor};
    border-bottom: 1px solid ${(props) => props.theme.colors.primaryColor};
  }

  & * {
    flex-shrink: 0;
  }
`;

export const ReportRecordSubContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const TextContent = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const SubText = styled.span`
  color: ${(props) => props.theme.colors.darkGrayColor};
`;

export const Tag = styled.span`
  color: ${(props) => props.$leaveTypeColor || getStatusColor(props)};
  background: ${(props) => props.$leaveTypeColor || getStatusColor(props)}20;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.small};
  line-height: ${(props) => props.theme.text.para.small};
  flex-shrink: 0;
  text-transform: capitalize;
`;

export const AttachmentContainer = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
`;

export const EnhancedActionArea = styled(StyledActionArea)`
  display: flex;
  padding: 10px 0px;
  gap: 10px;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;
export const StyledButton = styled(Button)`
  height: 35px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  & button {
    margin: 10px 0px 20px;
  }
`;

export const SearchContainer = styled.form`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  flex-grow: 1;
  gap: 10px;
  margin-top: 8px;

  & > div,
  > button,
  > div > div > button {
    flex: 1 1 130px;
    height: 35px;

    display: flex;
    align-items: center;

    @media (min-width: 1024px) {
      flex: 0 1 130px;
    }
  }

  & input {
    min-width: 270px;
    height: 35px;
  }

  & * {
    margin-bottom: 0;
  }
`;

export const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 35px;
  border-radius: 50%;
  position: relative;
  background: ${(props) => props.theme.colors.primaryColor};
  color: #fff;
  cursor: pointer;
`;

export const CollaboratorPopover = styled.div`
  padding: 20px;
  & button {
    margin-left: 15px;
  }
`;
