import React from "react";

import { Container, StyledIcon, Text } from "./style";

const EditDetails = ({ onEdit, className, text }) => {
  return (
    <Container className={className}>
      <Text role="button" onClick={onEdit}>
        <StyledIcon name="fas fa-pencil-alt" />
        {text}
      </Text>
    </Container>
  );
};
EditDetails.defaultProps = {
  className: "",
  text: "Edit Details",
};

export default EditDetails;
