import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const createBreakApi = ({ companyId, isAdmin, formData }) => {
  let snap = {
    icon_code: formData.icon_code,
    title: formData.title,
  };

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/snaps`,
  });

  return requestData(url, {
    method: "POST",
    body: {
      snap,
    },
    isFormData: true,
  });
};

export const getBreaksApi = ({ companyId, pageNumber, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/snaps?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getActiveBreaksApi = ({ companyId, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/active_snaps`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getCurrentCollaboratorBreaksApi = ({
  companyId,
  pageNumber,
  isAdmin,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborator_snaps?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const startBreakTimerApi = ({ companyId, snapId, isAdmin }) => {
  let collaborator_snap = {
    snap_id: snapId,
  };

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborator_snaps`,
  });
  return requestData(url, {
    method: "POST",
    body: {
      collaborator_snap,
    },
    isFormData: true,
  });
};

export const updateBreakTimerApi = ({
  companyId,
  collaboratorSnapId,
  isAdmin,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborator_snaps/${collaboratorSnapId}`,
  });
  return requestData(url, {
    method: "PUT",
  });
};

export const getSubordinateBreaksApi = ({ companyId, pageNumber, isAdmin, subordinateId }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/fetch_snaps_by_collaborator?page=${pageNumber}&collaborator_id=${subordinateId}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
