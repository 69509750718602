import React from "react";
import OnlyWhenAuthorized from "../Auth/OnlyWhenAuthorized";
import { Route } from "react-router";

const AdvancedRoute = ({
  accessType = "",
  isAuthorized = false,
  ...otherProps
}) => {
  if (accessType) {
    return (
      <OnlyWhenAuthorized forcePass={isAuthorized} requiredAccess={accessType}>
        <Route {...otherProps} />
      </OnlyWhenAuthorized>
    );
  } else {
    return <Route {...otherProps} />;
  }
};

export default AdvancedRoute;
