import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const MainContent = styled.div`
  padding-top: calc(${(props) => props.theme.dimensions.navbar.height});
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const DrawerWrapper = styled.div`
  flex-basis: auto;
`;
