import { getDistanceBetweenTwoDateInWords } from "helper";

export const generateFormData = (values) => {
  const {
    snapsCreate_breakTitle,
    snapsCreate_breakIcon,
    snapsCreate_breakStatus,
  } = values;

  return {
    title: snapsCreate_breakTitle,
    icon_code: Object.keys(snapsCreate_breakIcon)[0],
    status: snapsCreate_breakStatus,
  };
};

export const tranformToBreaksList = (data, cellNameToColumnMap) => {
  const idColumn = cellNameToColumnMap["Sl No"];
  const iconCodeColumn = cellNameToColumnMap["iconCode"];
  const statusColumn = cellNameToColumnMap["status"];
  const titleColumn = cellNameToColumnMap["title"];

  const res = [];
  data.forEach((row, index) => {
    if (index === 0) {
      return;
    }

    const [snapId, iconCode, status, title] = [
      row[idColumn],
      row[iconCodeColumn],
      row[statusColumn],
      row[titleColumn],
    ];
    if (iconCode && status && title) {
      res.push({
        snapId,
        iconCode,
        status,
        title,
      });
    }
  });
  return res;
};

export const getCurrentActiveSnap = (data = [], ongoingBreak) => {
  const activeData = data.find((el) => el?.snapId === ongoingBreak?.snapId);

  return activeData;
};
