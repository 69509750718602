import { GET_POST_DETAIL } from "./actionTypes";

import {
  CREATE_POST_COMMENT,
  REACT_TO_POST,
  REMOVE_REACT_FROM_POST,
  ANSWERE_TO_QUIZ,
  ANSWERE_TO_POLL,
  DELETE_POST,
} from "modules/WelcomePage/redux/Posts/actionTypes";

import { DELETE_COMMENT, EDIT_COMMENT } from "common/ActionTypes";

import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnUpdate,
  createNewStoreOnDelete,
} from "helper";

const initialState = createInitialStoreState({});

function PostDetailReducer(store = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${GET_POST_DETAIL}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    case `${REMOVE_REACT_FROM_POST}_SUCCESS`:
    case `${CREATE_POST_COMMENT}_SUCCESS`:
    case `${DELETE_COMMENT}_SUCCESS`:
    case `${EDIT_COMMENT}_SUCCESS`:
    case `${REACT_TO_POST}_SUCCESS`:
    case `${ANSWERE_TO_QUIZ}_SUCCESS`:
    case `${ANSWERE_TO_POLL}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store, false);
    }

    case `${DELETE_POST}_SUCCESS`:
      return createNewStoreOnDelete(payload, store, false);

    default:
      return store;
  }
}

export default PostDetailReducer;
