import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const createBankAccountApi = ({ companyId, isAdmin, formData }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/bank_accounts`
  });
  return requestData(url, {
    method: "POST",
    body: {
      bank_account: {
        account_holder_name: formData.account_holder_name,
        account_number: formData.account_number,
        account_type: formData.account_type,
        branch_name: formData.branch_name,
        ifsc_code: formData.ifsc_code,
        bank_name: formData.bank_name,
      },
    },
    isFormData: true,
  });
};

export const getBankAccountsApi = ({ companyId, pageNumber, currentUserId, userId, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/bank_accounts?page=${pageNumber}&holder_id=${isAdmin ? userId : currentUserId}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getIfscBankDetails = (currentIFSC) => {
  return fetch(`https://ifsc.razorpay.com/${currentIFSC}`, {
    "method": "GET"
  }).then(response => { return response.json() })
}
