import React from "react";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import { StyledForm, FieldInfoTag } from "../../style/create";
import { useAvatarUpdate } from "../../Hooks/useAvatarUpdate";
import Icon from "components/Icon";

const UpdateAvatarForm = React.forwardRef(
  ({ initialValue, onComplete }, ref) => {
    const { isLoading, onFormSubmit, formData } = useAvatarUpdate({
      initialValue,
    });

    const onSubmit = async (e) => {
      e.preventDefault();
      const success = await onFormSubmit();
      onComplete(success);
    };

    return (
      <StyledForm ref={ref} onSubmit={onSubmit}>
        <InputGroup
          label="Avatar"
          fieldType="googleFilePicker"
          accept="image/*"
          {...formData["avatar"]}
        />
        <FieldInfoTag>
          <Icon name="fas fa-info-circle" />
          <span>File size limit 5MB</span>
        </FieldInfoTag>

        <Button primary loading={isLoading} disabled={isLoading} type="submit">
          Update
        </Button>
      </StyledForm>
    );
  }
);

export default UpdateAvatarForm;
