import { MainContainer } from "../style";
import Divider from "components/Divider";
import useTabRoutes from "hooks/useTabRoutes";
import AssignedLeavesView from "./assignedLeavesView";
import { TabContainer, TabContent, TabHeader } from "components/Tabs2";

const TabsMap = {
  pending: { name: "Pending", value: "pending" },
  approved: { name: "Approved", value: "approved" },
  notified: { name: "Notified", value: "notified" },
  cancelled: { name: "Cancelled", value: "cancelled" },
  rejected: { name: "Rejected", value: "rejected" }
};

const TabsList = [
  TabsMap.pending,
  TabsMap.approved,
  TabsMap.notified,
  TabsMap.cancelled,
  TabsMap.rejected
];

const LeaveManagementView = () => {
  const { onTabChanged } = useTabRoutes();

  return (
    <MainContainer>
      <TabContainer
        onActionDispatch={onTabChanged}
        initialData={{ activeTabId: TabsMap.pending.value }}
      >
        <TabHeader tabs={TabsList} />
        <Divider />

        <TabContent id={TabsMap.pending.value}>
          <AssignedLeavesView />
        </TabContent>

        <TabContent id={TabsMap.approved.value}>
          <AssignedLeavesView />
        </TabContent>

        <TabContent id={TabsMap.notified.value}>
          <AssignedLeavesView />
        </TabContent>

        <TabContent id={TabsMap.cancelled.value}>
          <AssignedLeavesView />
        </TabContent>

        <TabContent id={TabsMap.rejected.value}>
          <AssignedLeavesView />
        </TabContent>
      </TabContainer>
    </MainContainer>
  );
};

export default LeaveManagementView;
