import React, { useCallback, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup";
import Button from "components/Button";
import useAlert from "hooks/useAlert";
import AsyncSearchSelect from "components/Input/AsyncSearchSelect";

import { getUserListData } from "common/Api";

import { createCalendar } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { FormHeading, StyledFormGroup } from "globalStyles";
import { IsPublicOptions } from "../../constants";
import UserAccessList from "./UserAccessList";

const Form = ({ isEditMode = false, createCalendar, isAdmin, companyId }) => {
  const [isLoading, updateLoading] = useState(false);
  const [isPublic, updatePublicStatus] = useState(null);
  const [selectedUsers, updateSelectedUsers] = useState({});
  const [viewersAccess, updateViewerAccess] = useState({});

  const { showAlert } = useAlert();

  const clearAllRestFields = () => {
    updateSelectedUsers({});
    updateViewerAccess({});
  };

  const onPublicStatusChanged = useCallback((e) => {
    updatePublicStatus(Number(e.target.value));
    clearAllRestFields();
  }, []);

  const onUserSelected = useCallback((e) => {
    const { value } = e.target;
    updateSelectedUsers(value);
  }, []);

  const onDeselectUsers = useCallback(
    (itemId, item) => {
      const newListData = { ...selectedUsers };
      if (newListData[itemId]) {
        delete newListData[itemId];
      }
      updateSelectedUsers(newListData);
    },
    [selectedUsers]
  );

  const onViewerAccessChange = useCallback((id, value) => {
    updateViewerAccess((prev) => ({ ...prev, [id]: value }));
  }, []);

  const getUserListWithAccess = (userList, accessTypeList) => {
    return Object.values(userList).map((user) => {
      return {
        collaborator_id: user.id,
        access_type: accessTypeList[user.id] || "viewer",
      };
    });
  };

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const userList = getUserListWithAccess(selectedUsers, viewersAccess);
        await createCalendar({
          formData: values,
          isAdmin,
          userList,
          companyId,
        });
        showAlert({
          message: `Calendar ${isEditMode ? "Updated" : "Created"}`,
        });
        resetForm();
        clearAllRestFields();
      } catch (err) {
        showAlert({ message: "Calendar Create Failed", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [
      isEditMode,
      companyId,
      createCalendar,
      isAdmin,
      viewersAccess,
      selectedUsers,
    ]
  );

  const selectedUsersList = useMemo(() => {
    return Object.values(selectedUsers);
  }, [selectedUsers]);

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <FormHeading>Create new calendar</FormHeading>

      <InputGroup
        id="calendarCreate_name"
        label="Calendar name"
        placeholder="Enter calendar name"
        required
      />
      <InputGroup
        initialValue="#e66465"
        fieldType="color"
        id="calendarCreate_color"
        label="Calendar color"
        secondary
        required
      />

      <InputGroup
        wrapperStyle={{ minHeight: "250px" }}
        fillParent={true}
        fieldType="textArea"
        id="calendarCreate_description"
        placeholder="Enter calendar description"
        label="Description"
      />
      <InputGroup
        options={IsPublicOptions}
        fieldType="radioGroup"
        id="calendarCreate_isPublic"
        label="Is Public"
        groupName="calendar_public"
        onChange={onPublicStatusChanged}
        required
      />

      {isPublic === 0 && (
        <AsyncSearchSelect
          value={selectedUsers}
          onChange={onUserSelected}
          placeholder="Search for users"
          api={getUserListData}
        />
      )}

      <UserAccessList
        users={selectedUsersList}
        onDeselect={onDeselectUsers}
        selectedUsers={selectedUsers}
        onAccessChange={onViewerAccessChange}
      />

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        {isEditMode ? "Update" : "Create"}
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    isAdmin,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createCalendar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
