import styled from "styled-components";
import { StyledFormGroup, StyledInputGroups } from "../../../GlobalStyles";

export const MainContainer = styled.div`
  width: calc(100% - 20px);
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  @media (min-width: 768px) {
    width: calc(100% - 40px);
  }
`;

export const NoticeDraftHeading = styled.h3`
  font-size: 34px;
  color: ${(props) => props.theme.colors.primaryColor};
  margin: 15px 0 30px 0;
  font-weight: 600;
  text-align: left;
`;

export const NoticeHeadingContainer = styled.input`
  width: 100%;
`;

export const StyledFormGroups = styled(StyledFormGroup)`
  padding: 10px 10px 0 0;
`;

export const NoticeDescriptionInputGroup = styled(StyledInputGroups)`
  height: 250px;
`;

export const TextAreaLengthCont = styled.p`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  font-weight: 400;
  text-align: right;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`;
