import styled, { keyframes } from "styled-components";

const slideInRight = keyframes`
    0%{
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
`;

export const SliderContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => props.theme.zIndex.slider};
  padding: 75px 0 0 20px;
  overflow: auto;
  animation: ${slideInRight} 0.3s ease forwards;
  background-color: white;
  @media (min-width: 768px) {
    padding: 100px 0 0 60px;
  }
`;

export const BackButton = styled.div`
  width: 105px;
  height: 45px;
  position: fixed;
  top: 25px;
  left: 20px;
  background-color: white;
  @media (min-width: 768px) {
    top: 45px;
    left: 60px;
  }
`;
