import React, { useState } from "react";

import GenericModal from "components/GenericModal";
import PostCreate from "./PostCreate";
import PollCreate from "./PollCreate";
import PostQuizCreate from "./PostQuizCreate";

import { postTypes as types } from "../constants";

import { ModalContainer, ModalContent } from "./style";

function PostCreateModal({ onClose, className, ...rest }) {
  const [currentPostType, updatePostType] = useState(types.post);

  const onPostTypeChange = (type) => {
    updatePostType(type);
  };

  return (
    <GenericModal opacity={0.5}>
      <ModalContainer className={className} onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          {currentPostType === types.post && (
            <PostCreate
              onPostTypeChange={onPostTypeChange}
              onClose={onClose}
              {...rest}
            />
          )}
          {currentPostType === types.poll && (
            <PollCreate
              onPostTypeChange={onPostTypeChange}
              onClose={onClose}
              {...rest}
            />
          )}

          {currentPostType === types.quiz && (
            <PostQuizCreate
              onPostTypeChange={onPostTypeChange}
              onClose={onClose}
              {...rest}
            />
          )}
        </ModalContent>
      </ModalContainer>
    </GenericModal>
  );
}

PostCreateModal.defaultProps = {
  className: "",
};
export default PostCreateModal;
