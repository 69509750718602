import { ACTIVATE_TOOLTIP, DE_ACTIVATE_TOOLTIP } from "../redux/actionTypes";
import { calcPosition } from "../utils";

export function useTooltipLogic(dispatch, position) {
  const onMouseEnter = (e) => {
    const style = calcPosition(e.target, position);
    dispatch({ type: ACTIVATE_TOOLTIP, payload: { style, position } });
  };

  const onMouseLeave = () => {
    dispatch({ type: DE_ACTIVATE_TOOLTIP });
  };

  return { onMouseEnter, onMouseLeave };
}
