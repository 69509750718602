import React, { useMemo } from "react";
import WeekDays from "./WeekDays";
import DayGrid from "./CellGrid";
import EventCreate from "modules/Calendar/Views/EventCreate";
import EventView from "modules/Calendar/Views/EventView";

import { useTaskView, useTaskCreate } from "../hooks";
import { createMonthData, MonthHeadData } from "../utils";
import { Container } from "./style";

export const MonthCalendar = ({ data, schedules }) => {
  const {
    activateForm,
    clearNewTask,
    createModalData,
    updateNewTaskPos: updateNewTasks,
    newTaskPos: newTasks,
  } = useTaskCreate();
  const { viewFormData, toggleViewModal, onTaskView } = useTaskView();

  const onClick = (elem, id) => {
    const date = new Date(id);
    date.setHours(0);
    const style = { top: `45%` };
    updateNewTasks(() => ({ [id]: { style, date } }));
    activateForm("calendar_new_task");
  };

  const grdiData = useMemo(() => {
    return createMonthData(data, schedules);
  }, [data, schedules]);

  const clickedDate = useMemo(() => {
    return (newTasks && Object.values(newTasks)[0].date) || new Date();
  }, [newTasks]);

  return (
    <Container>
      <WeekDays data={MonthHeadData} />
      <DayGrid
        rows={grdiData.length}
        requiredColumns={7}
        rowHeight={200}
        data={grdiData}
        onClick={onClick}
        onViewTask={onTaskView}
        newTasks={newTasks}
      />
      {createModalData.active && (
        <EventCreate
          initialDate={clickedDate}
          elemIdOrElem={createModalData.elem}
          onClose={clearNewTask}
        />
      )}

      {viewFormData.active && (
        <EventView
          data={viewFormData.data}
          elemIdOrElem={viewFormData.elem}
          onClose={toggleViewModal}
        />
      )}
    </Container>
  );
};
