import { useCallback, useState } from "react";

import useAlert from "hooks/useAlert";
import useForm from "hooks/useForm";
import { createTeamMember } from "../actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

export const useMemberCreate = ({ updateIsFormSubmitted }) => {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const dispatch = useDispatch();

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const { formData, values, resetForm, updateField, isFormValid } = useForm({
    fields: {
      memberName: {
        required: false,
      },
      members: {
        required: true,
      },
    },
  });

  const teamId = useSelector((store) => store.teams.owners?.teamId);

  const onFormSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        if (!isFormValid()) {
          return;
        }

        updateLoading(true);

        const members = values?.members && Object.keys(values?.members);
        const data = {
          collaborator_ids: members,
        };

        await dispatch(
          createTeamMember({
            companyId,
            isAdmin,
            teamId,
            formData: data,
          })
        );
        showAlert({ message: "Members Added" });
        resetForm();
        if (typeof updateIsFormSubmitted === "function") {
          updateIsFormSubmitted(false);
        }
      } catch (err) {
        showAlert({ message: "Members couldn't be added", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [companyId, isAdmin, values, teamId]
  );

  const onSelectUser = useCallback(
    (itemId, item) => {
      let newListData = { ...values.members };
      if (newListData[itemId]) {
        delete newListData[itemId];
      } else {
        newListData = { ...newListData, [itemId]: item };
      }
      updateField("members", newListData);
    },
    [values]
  );

  return {
    onFormSubmit,
    formData,
    values,
    isLoading,
    onSelectUser,
  };
};
