import { TOGGLE_DRAWER, OPEN_DRAWER, CLOSE_DRAWER } from "../ActionTypes";

export const toggleDrawerAction = () => ({
  type: TOGGLE_DRAWER,
});

export const openDrawerAction = () => ({
  type: OPEN_DRAWER,
});
export const closeDrawerAction = () => ({
  type: CLOSE_DRAWER,
});
