import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useAlert from "hooks/useAlert";
import { getPostDetails } from "./actionCreator";
import { getPostData } from "./selector";
import { transformPostsRawDataToPostData } from "modules/WelcomePage/utils";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

function useGetPostDetail(postId) {
  const [isLoading, updateLoading] = useState(false);
  const rawPostData = useSelector((store) => getPostData(store, { postId }));
  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const { showAlert } = useAlert();
  const dispatch = useDispatch();

  const fetchPostDetails = async () => {
    try {
      updateLoading(true);
      await dispatch(getPostDetails({ companyId, isAdmin, postId }));
    } catch (err) {
    } finally {
      updateLoading(false);
    }
  };

  useEffect(() => {
    if (!postId) {
      return;
    }
    fetchPostDetails();
  }, [postId]);

  const postData = useMemo(() => {
    // if post data is comming in array format then use this logic else just return the data, no need of configuration
    const result =
      Object.keys(rawPostData).length &&
      transformPostsRawDataToPostData([rawPostData]);
    return Array.isArray(result) && result.length ? result[0] : {};
  }, [rawPostData]);

  return [isLoading, postData];
}

export default useGetPostDetail;
