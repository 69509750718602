import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const createUpiApi = ({ companyId, isAdmin, formData }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/upis`
  });
  return requestData(url, {
    method: "POST",
    body: {
      upi: {
        upi_holder_name: formData.upi_holder_name,
        upi_id: formData.upi_id,
      },
    },
    isFormData: true,
  });
};

export const getUpiApi = ({ companyId, pageNumber, currentUserId, userId, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/upis?page=${pageNumber}&holder_id=${isAdmin ? userId : currentUserId}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
