import React, { useMemo } from "react";
import { isLink } from "../../../../Helper";

import {
  MainContainer,
  MultipleListItemBox,
  ListBoxTitle,
  SingleListItemBox,
  ListBoxDesc,
  MultipleListItem,
  ListHeadingBox,
  ListHeadingPosition,
} from "./style";

const ListItem = ({ title, value }) => {
  const type = typeof value;

  const isLinkValue = useMemo(() => {
    return isLink(value);
  }, [value]);

  if (value && Array.isArray(value)) {
    return (
      <MultipleListItemBox>
        <ListBoxTitle>{title}</ListBoxTitle>
        {value.map((item, index) => {
          return (
            <MultipleListItem key={`${index}_${item}`}>{item}</MultipleListItem>
          );
        })}
      </MultipleListItemBox>
    );
  }

  if (value && type === "object") {
    return Object.keys(value).map((key, index) => {
      return (
        <ListItem key={`${index}_${key}`} title={key} value={value[key]} />
      );
    });
  }

  return (
    <SingleListItemBox>
      <ListBoxTitle>{title}</ListBoxTitle>
      {isLinkValue ? (
        <a rel="noopener noreferrer" target="_blank" href={value}>
          {value}
        </a>
      ) : (
        <ListBoxDesc>{String(value) || "----"}</ListBoxDesc>
      )}
    </SingleListItemBox>
  );
};

const Content = ({ data }) => {
  const { columns } = useMemo(() => {
    if (!data) {
      return [];
    }
    return Object.keys(data);
  }, [data]);

  const list = useMemo(() => {
    if (Array.isArray(data)) {
      const result = [];
      data.forEach((item, index) => {
        const objectList = [];

        if (typeof item === "object") {
          Object.keys(item).forEach((key) => {
            objectList.push(
              <ListItem key={`${index}_${key}`} title={key} value={item[key]} />
            );
          });
        } else {
          objectList.push(
            <ListItem key={`${index}_${item}`} title="-----" value={item} />
          );
        }

        result.push(
          <div key={index}>
            <ListHeadingBox>
              <ListHeadingPosition>{index + 1}</ListHeadingPosition>
            </ListHeadingBox>
            {objectList}
          </div>
        );
      });
      return result;
    }

    return Object.keys(data).map((key) => {
      return <ListItem key={key} title={key} value={data[key]} />;
    });
  }, [columns, data]);

  return <MainContainer>{list}</MainContainer>;
};

export default Content;
