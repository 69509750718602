import { MainContainer } from "modules/NotifiedWorkReport/style";
import TeamWorklogView from "./TeamWorkLog";

const Worklog = ({ teamId }) => {
  return (
    <MainContainer>
      <TeamWorklogView teamId={teamId} />
    </MainContainer>
  );
};

export default Worklog;
