import React from "react";

import Form from "./Form";
import { AnimatedPageContainer } from "../../styles/create";

const CalendarCreate = () => {
  return (
    <AnimatedPageContainer>
      <Form />
    </AnimatedPageContainer>
  );
};

export default CalendarCreate;
