import React, { useMemo } from "react";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import { getStatusType } from "helper";

import { Cell } from "../../style/display";

const RenderItem = ({ data, columnIndex, rowIndex, style }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
    maxStatusLength,
    copyDataToClipboard
  } = data;

  const cellvalue = rowData[rowIndex][columnIndex];

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["status"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const myChildren = useMemo(() => {
    if (isStatusColumn) {
      return (
        <Cell>
          <Status
            text={cellvalue}
            type={getStatusType(cellvalue)}
            style={{ width: `${maxStatusLength * 18}px`, padding: "6px" }}
          />
        </Cell>
      );
    }
    return null;
  }, [isStatusColumn, cellvalue, maxStatusLength]);

  const onCopyData = (e, rowIndex) => {
    const columns = rowData[0];
    let data = "";
    columns.forEach((columnName, columnIndex) => {
      data += `${columnName}=${rowData[rowIndex][columnIndex]}, `;
    });
    copyDataToClipboard(data);
  };

  const actionCellProps = useMemo(() => {
    return {
      cellName: "upi Holder Name",
      cellActions: [
        {
          id: "clipboard_copy_btn",
          icon: "fas fa-clipboard",
          tootltipText: "Copy to clipboard",
          btnProps: {
            onClick: onCopyData
          }
        }
      ]
    };
  }, [rowData]);

  return (
    <GridCell
      row={rowIndex}
      showHeading={showHeading}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      actionCellProps={actionCellProps}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

export default RenderItem;
