import React from "react";

import { RightContainer } from "../../styles/employee";
import Countdown from "../../../Countdown/Views/EmployeeCountdownView";

function RightContent() {
  return (
    <RightContainer className="d-f f-c">
      <Countdown />
    </RightContainer>
  );
}

export default RightContent;
