import React from "react";
import { MainContainer } from "../../styles/create";
import Form from "./Form";

const HolidayYearCreateView = () => {
  return (
    <MainContainer>
      <Form />
    </MainContainer>
  );
};
export default HolidayYearCreateView;
