import React, { useCallback, useEffect, useMemo, useRef } from "react";

import GenericModal from "components/GenericModal";
import CloseButton from "components/CloseButton";
import { CommentList } from "./CommentList";
import { CommentSkeleton } from "./CommentSkeleton";

import { debounce } from "helper";

import {
  ModalContainer,
  CommentsModalContent,
  CommentModalHeader,
  CommentModalList,
} from "./style";

export function CommentsListModal({
  onClose,
  className,
  isLoading,
  onEndReached,
  allCommnetsLoaded,
  comments,
  ...rest
}) {
  const debouncedScrollRef = useRef(null);

  const onScroll = useCallback(
    (e) => {
      const { scrollHeight, scrollTop } = e.target;
      const { height } = e.target.getBoundingClientRect();
      if (scrollTop + height >= scrollHeight) {
        onEndReached && onEndReached();
      }
    },
    [onEndReached]
  );

  useEffect(() => {
    debouncedScrollRef.current = debounce(onScroll, 300);
  }, [onScroll]);

  return (
    <GenericModal opacity={0.5}>
      <ModalContainer className={className}>
        <CommentsModalContent
          onScroll={!allCommnetsLoaded ? debouncedScrollRef.current : null}
          onClick={(e) => e.stopPropagation()}
        >
          <CommentModalHeader>
            Comments
            <CloseButton onClick={onClose} />
          </CommentModalHeader>
          <CommentModalList>
            <CommentList comments={comments} {...rest} />
            {!allCommnetsLoaded && <CommentSkeletonList count={3} />}
          </CommentModalList>
        </CommentsModalContent>
      </ModalContainer>
    </GenericModal>
  );
}

export function CommentSkeletonList({ count }) {
  return useMemo(() => {
    const res = [];
    for (let i = 0; i < count; i++) {
      res.push(
        <CommentSkeleton
          style={{ animationDelay: `${(i + 1) * 100}ms` }}
          key={`comment_skeleton_${i}`}
        />
      );
    }
    return res;
  }, [count]);
}

CommentsListModal.defaultProps = {
  className: "",
  allCommnetsLoaded: false,
};
