import React, { useMemo, useRef } from "react";
import { useHistory } from "react-router";

import GridCell from "components/Grid/GridCell";

import { Cell } from "../../../../style/display";
import { useDeleteLeaveAssign } from "modules/Leaves/hooks/useDeleteLeaveAssign";
import Routes from "route/Path";

const RenderItem = ({ data, columnIndex, rowIndex, style }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
    rowIdMap,
  } = data;

  const history = useHistory();
  const tempRef = useRef({ rowToDelete: "" });

  const { isLoading: isDeletingAssignedLeave, deleteAssignedLeave } =
    useDeleteLeaveAssign();

  const onEditAssignedLeave = (e, rowIndex) => {
    history.push(
      `${Routes.authorized.leaves.editAssign.path}/${rowIdMap[rowIndex]}`,
    );
  };

  const onDeleteAssignedLeave = (e, rowIndex) => {
    if (window.confirm("Are you sure you want to delete the leave ?")) {
      tempRef.current.rowToDelete = rowIndex;
      const assignedLeaveId = rowIdMap[rowIndex];
      deleteAssignedLeave(assignedLeaveId);
    }
  };

  const actionCellProps = useMemo(() => {
    return () => ({
      cellName: "actions",
      cellActions: [
        {
          id: "class_edit_btn",
          icon: "fas fa-pen",
          tootltipText: "Edit leave",
          btnProps: {
            disabled:
              isDeletingAssignedLeave &&
              tempRef.current.rowToDelete === rowIndex,
            onClick: onEditAssignedLeave,
          },
        },
        {
          id: "class_delete_btn",
          icon: "fas fa-trash",
          tootltipText: "Delete leave",
          btnProps: {
            disabled:
              isDeletingAssignedLeave &&
              tempRef.current.rowToDelete === rowIndex,
            onClick: onDeleteAssignedLeave,
          },
        },
      ],
    });
  }, [onEditAssignedLeave, onDeleteAssignedLeave, rowIdMap]);

  const cellvalue = rowData[rowIndex][columnIndex];

  const isLeavePlanColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["leavePlan"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const myChildren = useMemo(() => {
    if (isLeavePlanColumn) {
      return (
        <Cell style={{ color: cellvalue?.colorCode }}>
          {cellvalue?.leaveType}
        </Cell>
      );
    }
    return null;
  }, [isLeavePlanColumn, cellvalue]);

  return (
    <GridCell
      row={rowIndex}
      showHeading={showHeading}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      actionCellProps={actionCellProps}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

export default RenderItem;
