let API_URL;
let VERSION_URL;

export const IS_DEV =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";

if (!IS_DEV) {
  API_URL = process.env.REACT_APP_API_URL;
  VERSION_URL = process.env.REACT_APP_API_VERSION;
}

if (IS_DEV) {
  API_URL = "https://systemgrid-staging-v2.herokuapp.com";
  //API_URL = "http://f3e57ce14850.ngrok.io";
  VERSION_URL = "/api/v1";
}
export const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const SERVER_URL = `${API_URL}${VERSION_URL}`;
export const IMAGE_URL = `${API_URL}`;
