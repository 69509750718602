import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import TabsRenderer from "./TabsRenderer";
import DrawerLink from "../DrawerLink";
import { generateDrawerLinks } from "helper";

import Not_Img from "assets/Document.svg";
import E_Not_Img from "assets/employee/Document.svg";

const [noticeTabLinks, NoticeLinkComponents] = generateDrawerLinks(
  Routes.authorized.notice,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

export const NoticeTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType("notice_section")}
    >
      {/* <DrawerTitle title="Notice Section" /> */}
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("notice")}
        image={isAdmin ? Not_Img : E_Not_Img}
        title="Notice"
        links={noticeTabLinks}
      >
        {NoticeLinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
