import { MainContainer } from "../style";
import NotifiedWorkReportsView from "./NotifiedWorkReportsView";

const NotifiedReportView = () => {
  return (
    <MainContainer>
      <NotifiedWorkReportsView />
    </MainContainer>
  );
};

export default NotifiedReportView;
