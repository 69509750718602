import React, { useMemo, useCallback, useRef } from "react";

import Icon from "../Icon";

import { Container, PageButton, PagesBox, ActionButton } from "./style";

const BOX_WIDTH = 35;
// const BOX_HEIGHT = 30;

const Paginator = ({
  current,
  total,
  onNext,
  onPrev,
  onGoToPage,
  nextIsLoading,
  prevIsLoading,
}) => {
  const scrollBoxRef = useRef();
  if (!current && !total) {
    current = 1;
    total = 1;
  }
  const pages = useMemo(() => {
    const res = [];
    for (let i = 1; i <= total; i++) {
      res.push(i);
    }
    return res;
  }, [total]);

  const goToNext = useCallback(() => {
    if (current < total) {
      onNext();
    }
  }, [current, onNext, total]);

  const goToPrev = useCallback(() => {
    if (current > 1) {
      onPrev();
    }
  }, [current, onPrev]);

  const scrollToIndex = useCallback(
    (isSmooth = true) => {
      let moveBy = Math.floor(current / 4) * ((BOX_WIDTH + 10) * 4);

      if (current % 4 === 0) {
        moveBy -= (BOX_WIDTH + 10) * 4;
      }
      if (scrollBoxRef.current) {
        const { scrollWidth } = scrollBoxRef.current;

        scrollBoxRef.current.style.scrollBehavior = isSmooth
          ? "smooth"
          : "auto";

        if (moveBy > scrollWidth) {
          //  this case means that still now not all the elements have rendered
          setTimeout(() => {
            scrollBoxRef.current.scrollTo(moveBy, 0);
          }, 1500);
        } else {
          scrollBoxRef.current.scrollTo(moveBy, 0);
        }
      }
    },
    [current]
  );

  useMemo(() => {
    scrollToIndex();
  }, [scrollToIndex]);

  //  scrolling to the existing position initially
  const setRef = useCallback(
    (ref) => {
      scrollBoxRef.current = ref;
      scrollToIndex();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Container>
      <ActionButton
        disabled={prevIsLoading}
        loading={prevIsLoading}
        size="auto"
        onClick={goToPrev}
        secondary
        indicatorSize="xs"
      >
        <Icon name="fas fa-chevron-left" />
      </ActionButton>
      <PagesBox ref={setRef}>
        {pages.map((page) => {
          return (
            <PageButton
              key={page}
              size="auto"
              onClick={() => onGoToPage(page)}
              active={page === current}
            >
              <span>{page}</span>
            </PageButton>
          );
        })}
      </PagesBox>

      <ActionButton
        disabled={nextIsLoading}
        loading={nextIsLoading}
        size="auto"
        onClick={goToNext}
        secondary
        indicatorSize="xs"
      >
        <Icon name="fas fa-chevron-right" />
      </ActionButton>
    </Container>
  );
};

export default Paginator;
