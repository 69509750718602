import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useAlert from "hooks/useAlert";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { createTableDataSelector } from "common/Selectors/MTable";

import {
  fetchSurveyData as fetchSurveyDataActionCreator,
  submitSurveyData as submitSurveyDataActionCreator,
} from "../redux/actionCreator";
import { reducerStore } from "types/index";
import { transformSurveyMatrixToArray } from "../utils";
import { IsurveyData } from "../types";

type submitFormData = {
  answer: string;
  questionId: string;
};

type LoadingState = "LOADING_SURVEY" | "SUBMITTING_RESPONSE" | "";

const surveyDataTableSelector = createTableDataSelector({
  tableName: "survey",
});

function useSurvey() {
  const [isLoading, updateLoading] = useState<LoadingState>("");
  const { showAlert } = useAlert();
  const dispatch = useDispatch();
  const companyId = useSelector((store: reducerStore) =>
    getCurrentUserCompany(store, null)
  );
  const isAdmin = useSelector((store: reducerStore) =>
    isCurrentUserIsExecutive(store, null)
  );
  const surveyDataTable = useSelector((store: reducerStore) =>
    surveyDataTableSelector(store)
  );

  const fetchSurvey = async (surveyId: string) => {
    try {
      if (!surveyId) {
        return;
      }
      updateLoading("LOADING_SURVEY");
      await dispatch(
        fetchSurveyDataActionCreator({ companyId, isAdmin, surveyId })
      );
    } catch (err) {
      showAlert({ message: "Fetch survey data failed", type: "error" });
    } finally {
      updateLoading("");
    }
  };

  const submitSurveyData = async (
    data: submitFormData,
    surveyId: string
  ): Promise<boolean> => {
    let success = true;
    try {
      if (!surveyId) {
        return false;
      }
      updateLoading("SUBMITTING_RESPONSE");
      await dispatch(
        submitSurveyDataActionCreator({
          companyId,
          isAdmin,
          surveyId,
          formData: data,
        })
      );
    } catch (err) {
      success = false;
      showAlert({ message: "Failed to submit survey response", type: "error" });
    } finally {
      updateLoading("");
    }
    return success;
  };

  const surveyData: IsurveyData | null = useMemo(() => {
    if (isLoading === "LOADING_SURVEY") {
      return null;
    }
    const data = transformSurveyMatrixToArray(
      surveyDataTable.rows,
      surveyDataTable.rowIdMap
    );
    return data as IsurveyData;
  }, [surveyDataTable, isLoading]);

  return {
    isLoading,
    fetchSurvey,
    surveyData,
    submitSurveyData,
  };
}

export default useSurvey;
