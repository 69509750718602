import React, { useMemo } from "react";
import { ItemView } from "components/DetailsView";

import {
  transform2dMatrixTo1D,
  generateTableHeading,
} from "../../../../Helper";
import {
  DetailsContainer,
  DetailHead,
  DataItem,
  Dot,
  DetailContent,
  StyledButton,
} from "../../style/display";
import { connect } from "react-redux";
import { createTableDataSelector } from "common/Selectors/MTable";
import { useStatusUpdate } from "./Hooks/useStatusUpdate";
import { UserAvatar } from "modules/Profile/Views/Display/UserAvatar";

const StatusView = React.memo(
  ({ heading, result, isActive, onStatusClick, loading }) => {
    return (
      <DataItem>
        <DetailHead>{generateTableHeading(heading)}</DetailHead>
        <DetailContent>
          <Dot active={isActive} />
          {result}
        </DetailContent>
        <StyledButton
          size="sm"
          loading={loading}
          active={isActive}
          primary
          onClick={() => onStatusClick(isActive)}
        >
          {isActive ? "Deactivate" : "Activate"}
        </StyledButton>
      </DataItem>
    );
  }
);

const DetailView = ({ columns = [], data = [], userId }) => {
  const { loading, onClick } = useStatusUpdate({ userId });
  let userName = "";
  const modifiedData = useMemo(() => {
    return transform2dMatrixTo1D(columns, data);
  }, [columns, data]);

  const filteredData = useMemo(() => {
    const blacklistColumns = ["avatar Url"];
    let avtarData;
    const filterData = modifiedData.filter((item, index) => {
      if (item.heading === "name") {
        userName = item.result;
      } else if (item.heading === "avatar Url") {
        avtarData = { ...item };
        avtarData.heading = "avatar";
        avtarData.isTable = false;
        avtarData.result = data[index];
      }
      return !blacklistColumns.includes(item.heading);
    });

    return avtarData ? [avtarData, ...filterData] : filterData;
  }, [modifiedData, data]);
  
  const DetailItem = useMemo(() => {
    return filteredData.map((item, index) => {
      if (item.heading === "status") {
        const isActive = item.result === "Active";
        return (
          <StatusView
            key={`${item.heading}_${index}`}
            heading={item.heading}
            result={item.result}
            isActive={isActive}
            loading={loading}
            onStatusClick={onClick}
          />
        );
      }
      if (item.heading === "avatar")
        return (
          <UserAvatar
            key={`${item.heading}_${index}`}
            heading={item.heading}
            result={item.result}
            userName={userName}
          />
        );
      if (item.result) {
        return (
          <ItemView
            key={`${item.heading}_${index}`}
            heading={item.heading}
            isTable={item.isTable}
            result={item.result}
            showUserImage={item.heading === "avatar"}
            userName={userName}
          />
        );
      }
    });
  }, [filteredData]);

  return <DetailsContainer>{DetailItem}</DetailsContainer>;
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "user",
    blackList: { "Sl No": true },
  });
  const maker = (store, props) => {
    const { rows, rowIdMap } = getUserRows(store, props);
    const dataIndex = rowIdMap && Object.values(rowIdMap).indexOf(props.userId);
    return {
      columns: rows[0],
      data: rows[dataIndex + 1],
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(DetailView);
