import { CREATE_BANK_ACCOUNT, GET_BANK_ACCOUNTS } from "./actiontypes";

import { createAsyncActionCreator } from "helper";

import { createBankAccountApi, getBankAccountsApi } from "./api";

const createBankAccountGenrator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_BANK_ACCOUNT,
    sideEffect: createBankAccountApi({ companyId, isAdmin, formData }),
  };
};

export const [createBankAccount] = createAsyncActionCreator([
  createBankAccountGenrator,
]);

const getBankAccountsGenerator = function* ({
  companyId,
  pageNumber,
  currentUserId,
  userId,
  isAdmin,
}) {
  yield {
    action: GET_BANK_ACCOUNTS,
    sideEffect: getBankAccountsApi({ companyId, pageNumber, currentUserId, userId, isAdmin }),
  };
};

export const getBankAccounts = createAsyncActionCreator(
  getBankAccountsGenerator
);
