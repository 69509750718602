import requestData from "../../ApiHandler";
import { createAuthUrl } from "../../Helper";

export const getHolidayYearsApi = ({ companyId, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/holiday_years`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const createHolidayApi = ({ companyId, isAdmin, formData }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/holidays`,
  });
  return requestData(url, {
    method: "POST",
    body: {
      holiday: {
        holiday_year_id: formData.yearId,
        holiday_date: formData.holidayDate,
        reason: formData.holidayReason,
        is_optional: formData.isOptional,
        ...(formData.holidayCardImage
          ? { holiday_card_image: formData.holidayCardImage }
          : {}),
      },
    },
    isFormData: true,
  });
};

export const editHolidayApi = ({ holidayId, companyId, isAdmin, formData }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/holidays/${holidayId}`,
  });
  return requestData(url, {
    method: "PUT",
    body: {
      holiday: {
        holiday_date: formData.holidayDate,
        reason: formData.holidayReason,
        is_optional: formData.isOptional,
        ...(formData.holidayCardImage
          ? { holiday_card_image: formData.holidayCardImage }
          : {}),
      },
    },
    isFormData: true,
  });
};

export const deleteHolidayApi = ({ holidayId, companyId, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/holidays/${holidayId}`,
  });
  return requestData(url, {
    method: "DELETE",
  });
};

export const getHolidaysApi = ({ companyId, pageNumber, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/holidays?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getHolidaysByYearApi = ({
  companyId,
  isAdmin,
  pageNumber,
  currentYearId,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_holidays_by_year?page=${pageNumber}&holiday_year_id=${currentYearId}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const searchHolidaysApi = ({
  companyId,
  isAdmin,
  pageNumber,
  currentYearId,
  currentFromDate,
  currentToDate,
  currentIsOptional,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/search_holiday?page=${pageNumber}&holiday_year_id=${currentYearId}&from_date=${currentFromDate}&to_date=${currentToDate}&is_optional=${currentIsOptional}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
