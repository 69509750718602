import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const getPostDetailsApi = ({ companyId, isAdmin, postId }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/posts/${postId}`
  });
  return requestData(url, {
    method: "GET"
  });
};
