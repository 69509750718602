import styled from "styled-components";
import { CircleSkeleton } from "components/Skeleton";
import ProfilePic from "components/ProfilePic";

import { Card } from "globalStyles";

export const Container = styled(Card)`
  display: flex;
  flex-flow: column nowrap;
`;

export const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #efefef;
  padding: 0 20px;
`;

export const Section = styled.div`
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-flow: column nowrap;
  & + div {
    margin-top: 20px;
  }
`;

export const InfoHeader = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  text-align: left;
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: 600;
  color: black;
`;

export const InfoContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  & .desc {
    font-weight: normal;
    font-size: ${(props) => props.theme.text.para.extraSmall};
    color: #707070;
    // margin-left: 10px;
  }
`;

export const BigCakeImage = styled.img`
  width: 35px;
  height: 43px;
  margin-right: 10px;
`;

export const ProfilePicArea = styled.div`
  --profile-width: 40px;
  --profile-height: 40px;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const StyledProfilePic = styled(ProfilePic)`
  width: var(--profile-width);
  height: var(--profile-height);
`;

export const ProfilePicTagImage = styled.img`
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 1;
  right: 0;
  margin-right: 20px;
  top: 26px;
`;

export const InfoTab = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
  color: ${(props) =>
    props.$active ? props.theme.colors.primaryColor : "#707070"};
  & > img {
    width: 15px;
    height: 15px;
  }
  & .title {
    font-weight: 500;
    font-size: ${(props) => props.theme.text.para.small};
    margin-left: 5px;
  }
  ${(props) => {
    if (props.$active) {
      return `border-bottom: 2px ${props.theme.colors.primaryColor} solid`;
    }
  }}
`;

export const ProfileSkeleton = styled(CircleSkeleton)`
  width: 40px;
  height: 40px;
  align-self: flex-start;
  & + div {
    margin-left: 10px;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ModalContent = styled.div`
  background-color: white;
  border-radius: 7px;
  width: min(400px, 80%);
  max-height: 400px;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
  padding-bottom: 30px;
`;

export const ModalHeader = styled.div`
  flex-basis: 50px;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.text.title.large};
  font-weight: 500;
  border-bottom: 1px lightgray solid;
  margin-bottom: 10px;
  padding: 0px 10px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  padding-top: 20px;
  padding-bottom: 10px;
`;

export const MorePic = styled.button`
  align-self: flex-start;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  border: 1px lightgray solid;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  font-size: 10px;
  font-weight: 700;
  padding-bottom: 7px;
  color: #fffafa;
  margin-left: 10px;
  &:hover {
    box-shadow: ${(props) => props.theme.shadow.medium};
  }
`;

export const ModalListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 20px;

  & > .content {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 10px;
    & > .title {
      font-size: ${(props) => props.theme.text.para.small};
    }
    & > .desc {
      font-size: ${(props) => props.theme.text.para.small};
    }
  }
  & + div {
    margin-top: 10px;
  }
`;

export const LoaderArea = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 20px;
`;

export const NameTag = styled(InfoHeader)`
  width: 80px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
`;

export const SubText = styled.div`
  font-weight: normal;
  font-size: 0.7rem;
  text-align: center;
  color: #707070;
  margin: 0;
`;
