import { useCallback, useMemo } from "react";

import useDebounce from "hooks/useDebounce";
import usePagination from "hooks/usePagination";
import { transformToUserList } from "../utils";
import { createTableDataSelector } from "common/Selectors/MTable";
import { getUserList, getActiveUsers } from "../actionCreator";
import { useSelector } from "react-redux";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { getOncefetched } from "common/Selectors/store";

const onceFetchedSelector = getOncefetched("teams.users");
const getUserRows = createTableDataSelector({
  tableName: "teams.users",
  fetchAllData: true,
});
const getSearchedUserRows = createTableDataSelector({
  tableName: "teams.searchedUsers",
});

export const useGetMembers = ({ values }) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: values?.memberName ? getUserList : getActiveUsers,
    tableName: values?.memberName ? "teams.searchedUsers" : "teams.users",
  });

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const onceFetched = useSelector(onceFetchedSelector);

  const { rows: data, cellNameToColumnMap } = useSelector(getUserRows);

  const { rows: searchedRows, cellNameToColumnMap: searchedColumns } =
    useSelector(getSearchedUserRows);

  const extraParams = useMemo(
    () => ({ companyId, isAdmin, searchText: values?.memberName }),
    [companyId, isAdmin, values?.memberName]
  );

  const fetchFirstPage = useCallback(() => {
    getNextPage({
      extraParams,
      isFirstPage: true,
    });
  }, [getNextPage, extraParams]);

  const fetchNextPage = useCallback(() => {
    getNextPage({ extraParams });
  }, [getNextPage, extraParams]);

  const onEndReached = useCallback(
    (e) => {
      const { scrollTop, scrollHeight } = e.target;
      const { height } = e.target.getBoundingClientRect();

      if (
        scrollTop + height === scrollHeight &&
        currentPageNumber !== totalPages
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, currentPageNumber, totalPages, values?.memberName]
  );

  useDebounce(
    () => {
      if (values?.memberName?.length > 0 || !onceFetched) {
        fetchFirstPage();
      }
    },
    200,
    [values?.memberName]
  );

  const selectedUsersList = useMemo(() => {
    return values && values?.members && Object.values(values.members);
  }, [values]);

  const keys = useMemo(
    () => (values && values?.members ? Object.keys(values?.members) : []),
    [values]
  );

  const notificationList = useMemo(() => {
    const transformedList = values?.memberName
      ? transformToUserList(searchedRows, searchedColumns, values)
      : transformToUserList(data, cellNameToColumnMap, values);
    const filteredList = transformedList.filter(
      (el) => !keys.includes(el.id) && el
    );
    return filteredList;
  }, [data, cellNameToColumnMap, values]);

  return {
    selectedUsersList,
    notificationList,
    loadingNextPage,
    onEndReached,
    onceFetched,
  };
};
