import { SERVER_URL } from "../config.local";
import { TOKEN_LOCAL_NAME, ONLINE_FIELD } from "../Constants";
import { convertObjectToFormData } from "./utils";
import { LocalStorage } from "storage";
import { createErrorMessage } from "helper/api";

const requestData = (url, options) => {
  const {
    body = {},
    isFormData,
    isBlob = false,
    method,
    ...otherOptions
  } = options;

  if (!LocalStorage.getKey(ONLINE_FIELD)) {
    return;
  }

  const newOptions = {
    ...otherOptions,
    method,
    headers: {},
  };

  const bodyMethods = ["POST", "PUT", "PATCH", "DELETE"];

  if (bodyMethods.includes(method)) {
    newOptions.body = JSON.stringify(body);
  }

  if (isFormData) {
    const formData = new FormData();
    convertObjectToFormData(body, formData, "");
    newOptions.body = formData;
  }

  const token = LocalStorage.getKey(TOKEN_LOCAL_NAME);
  if (token) {
    newOptions.headers = {
      ...newOptions.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return fetch(`${SERVER_URL}${url}`, newOptions)
    .then((res) => {
      if (res.status >= 400 || res.status >= 500) {
        return res.json();
      } else if (isBlob) {
        return res.blob();
      } else {
        return res.json();
      }
    })
    .then((response) => {
      if (isBlob) {
        return { blobUrl: URL.createObjectURL(response) };
      }

      if (response.errors || response.error) {
        if (
          typeof response.error === "string" ||
          typeof response.errors === "string"
        ) {
          throw response;
        }

        throw typeof response.errors === "object" &&
          Object.keys(response.errors).length > 0
          ? createErrorMessage(response.errors)
          : response.meta && response.meta.message
          ? response.meta.message
          : "Something went wrong";
      }

      return response;
    });
};

export default requestData;
