import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import FollowSubordinateDisplayView from "../../../Modules/FollowSubordinate/Views/Display";

import { createRouteObject } from "../../../Helper";

const { followSubordinate } = RoutePaths.authorized;

const pages = createRouteObject(
  followSubordinate,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [FollowSubordinateDisplayView]
);

const FollowSubordinatePages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact
        path={item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default FollowSubordinatePages;
