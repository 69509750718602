import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";

import { createLeave, editLeave } from "../actionCreator";
import { generateFormData } from "../utils";

function useAssignLeave() {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const assignLeave = async (values, onFormReset) => {
    try {
      updateLoading(true)
      let formData = generateFormData(values)
      await dispatch(createLeave({ companyId, isAdmin, formData }));
      showAlert({ message: "Leave Assigned" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false)
    }
  };

  const editAssignedLeave = async (assignedLeaveId, values, onFormReset) => {
    try {
      updateLoading(true);
      let formData = generateFormData(values);
      await dispatch(
        editLeave({ companyId, isAdmin, formData, assignedLeaveId }),
      );
      showAlert({ message: "Assigned Leave Updated" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
  };
  return { isLoading, assignLeave, editAssignedLeave };
}

export default useAssignLeave;
