export const transform2dMatrixTo1D = (columns, data) => {
  const result = [];
  columns.forEach((columnName, index) => {
    let rows = data[index];

    if (Array.isArray(data[index])) {
      let headings;
      const row = [];
      data[index].forEach((item) => {
        const res = transform2dMatrixTo1D([columnName], [item]);
        headings = res[0].result[0];
        row.push(res[0].result ? res[0].result[1] : []);
      });
      rows = [headings, ...row];
    } else if (typeof data[index] === 'object') {
      const headings = [];
      const row = [];
      Object.keys(data[index]).forEach((key) => {
        headings.push(key);
        row.push(data[index][key]);
      });
      rows = [headings, row];
    }

    result.push({
      heading: columnName,
      isTable: typeof data[index] === 'object',
      result: rows
    });
  });
  return result;
};
