const SPACE_GAP = 10;

const getStartPoint = (x1, targetWidth, childWidth) => {
  return x1 + Math.round(targetWidth / 2) - Math.round(childWidth / 2);
};

const getMaxWindowLeft = (calculatedLeft, childWidth, windowWidth) => {
  if (calculatedLeft > 0) {
    const diff = calculatedLeft + childWidth - windowWidth;
    return diff > 0 ? calculatedLeft - diff - SPACE_GAP : calculatedLeft;
  } else {
    return SPACE_GAP;
  }
};

const getMaxWindowTop = (calculatedTop, childHeight, windowHeight) => {
  if (calculatedTop > 0) {
    const diff = calculatedTop + childHeight - windowHeight;
    return diff > 0 ? calculatedTop - diff - SPACE_GAP : calculatedTop;
  } else {
    return SPACE_GAP;
  }
};

export const calulateChildNewPosition = (
  childDimension,
  targetDimensions,
  position
) => {
  const { innerHeight: windowHeight, innerWidth: windowWidth } = window;
  switch (position) {
    case "top": {
      const calcTop = targetDimensions.top - childDimension.height - SPACE_GAP;
      const calcLeft = getStartPoint(
        targetDimensions.left,
        targetDimensions.width,
        childDimension.width
      );
      const top = getMaxWindowTop(calcTop, childDimension.height, windowHeight);
      const left = getMaxWindowLeft(
        calcLeft,
        childDimension.width,
        windowWidth
      );

      return { top, left };
    }
    case "bottom": {
      const calcTop =
        targetDimensions.top + targetDimensions.height + SPACE_GAP;
      const calcLeft = getStartPoint(
        targetDimensions.left,
        targetDimensions.width,
        childDimension.width
      );
      const top = getMaxWindowTop(calcTop, childDimension.height, windowHeight);
      const left = getMaxWindowLeft(
        calcLeft,
        childDimension.width,
        windowWidth
      );

      return { top, left };
    }

    case "right": {
      const calcTop = getStartPoint(
        targetDimensions.top,
        targetDimensions.height,
        childDimension.height
      );
      const calcLeft =
        targetDimensions.left + targetDimensions.width + SPACE_GAP;

      const top = getMaxWindowTop(calcTop, childDimension.height, windowHeight);
      const left = getMaxWindowLeft(
        calcLeft,
        childDimension.width,
        windowWidth
      );
      return { top, left };
    }

    case "left": {
      const calcTop = getStartPoint(
        targetDimensions.top,
        targetDimensions.height,
        childDimension.height
      );
      const calcLeft = targetDimensions.left - SPACE_GAP - childDimension.width;

      const top = getMaxWindowTop(calcTop, childDimension.height, windowHeight);
      const left = getMaxWindowLeft(
        calcLeft,
        childDimension.width,
        windowWidth
      );

      return { top, left };
    }
    default:
      return {};
  }
};

export const calculateTriangleRotation = (position) => {
  switch (position) {
    case "top":
      return 0;
    case "bottom":
      return 180;
    case "left":
      return -90;
    case "right":
      return 90;
    default:
      return 0;
  }
};

export const calculateTrianglePosition = (
  position,
  targetDimension,
  borderWidth
) => {
  const triangleHeight = Math.round(borderWidth / 2);
  const halfTriangleHeight = Math.round(triangleHeight / 2);

  switch (position) {
    case "top": {
      const top =
        targetDimension.top - SPACE_GAP - triangleHeight - halfTriangleHeight;
      const left =
        targetDimension.left +
        Math.round(targetDimension.width / 2) -
        triangleHeight;
      return { top, left };
    }
    case "bottom": {
      const top =
        targetDimension.top +
        targetDimension.height +
        SPACE_GAP -
        triangleHeight -
        halfTriangleHeight;
      const left =
        targetDimension.left +
        Math.round(targetDimension.width / 2) -
        triangleHeight;
      return { top, left };
    }
    case "left": {
      const top =
        targetDimension.top +
        Math.round(targetDimension.height / 2) -
        triangleHeight;
      const left =
        targetDimension.left - SPACE_GAP - triangleHeight + halfTriangleHeight;
      return { top, left };
    }
    case "right": {
      const top =
        targetDimension.top +
        Math.round(targetDimension.height / 2) -
        triangleHeight;
      const left =
        targetDimension.left +
        targetDimension.width +
        SPACE_GAP +
        triangleHeight -
        halfTriangleHeight;
      return { top, left };
    }
    default:
      break;
  }
};
