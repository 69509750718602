import { convertDateToAllBrowserReadableFormat } from "../../../Helper";

export const WeekDayData = [
  { day: "", date: "" },
  { day: "Sun", date: "25" },
  { day: "Mon", date: "26" },
  { day: "Tue", date: "27" },
  { day: "Wed", date: "28" },
  { day: "Thur", date: "29" },
  { day: "Fri", date: "30" },
  { day: "Sat", date: "1" },
];

export const MonthHeadData = [
  { day: "Sun", date: "" },
  { day: "Mon", date: "" },
  { day: "Tue", date: "" },
  { day: "Wed", date: "" },
  { day: "Thur", date: "" },
  { day: "Fri", date: "" },
  { day: "Sat", date: "" },
];

export const getDayTime = (count) => {
  if (count < 13) {
    return `${count} AM`;
  }
  return `${count - 12} PM`;
};

export const createMonthData = (actualData, schedules) => {
  const arr = new Array(5);
  for (let i = 0; i < 5; i++) {
    arr[i] = new Array(7);
  }

  for (let i = 0; i < 5; i++) {
    for (let j = 0; j < 7; j++) {
      const cellData = actualData && actualData[i] && actualData[i][j];
      arr[i][j] = {
        row: i,
        column: j,
        cellData,
        tasks: cellData && getTasksOfThisDate(schedules, cellData.dateObj),
      };
    }
  }
  return arr;
};

export const getTasksOfThisDate = (list, currDate) => {
  const result = [];

  list.forEach((item) => {
    const scheduleStart = new Date(
      convertDateToAllBrowserReadableFormat(item.startAt)
    );
    const scheduleEnd = new Date(
      convertDateToAllBrowserReadableFormat(item.endAt)
    );

    if (isDateBetweenDates(currDate, scheduleStart, scheduleEnd)) {
      result.push(item);
    }
  });
  return result;
};

const isDateBetweenDates = (currDate, startDate, endDate) => {
  if (!currDate || !startDate || !endDate) {
    return false;
  }

  const todayTopDate = new Date(currDate);
  todayTopDate.setHours(0, 0, 0, 0);

  const todayBottomDate = new Date(currDate);
  todayBottomDate.setHours(23, 59, 59, 999);

  return (
    (todayTopDate <= startDate && startDate <= todayBottomDate) ||
    (todayTopDate <= endDate && endDate <= todayBottomDate)
  );
};

export const getTaskPositionInWeek = (
  startDate,
  endDate,
  cellHeight,
  todayDate
) => {
  const todayTopDate = new Date(todayDate);
  todayTopDate.setHours(0, 0, 0, 0);

  const todayBottomDate = new Date(todayDate);
  todayBottomDate.setHours(23, 59, 59, 999);

  let newStartDate = new Date(startDate);
  let newEndDate = new Date(endDate);

  if (newStartDate < todayTopDate) {
    newStartDate = todayTopDate;
  } else if (newEndDate > todayBottomDate) {
    newEndDate = todayBottomDate;
  }

  let startTimeHour = newStartDate.getHours() + newStartDate.getMinutes() / 60;

  const diffInMin = (newEndDate - newStartDate) / (1000 * 60);
  const top = startTimeHour * cellHeight;

  const height = Math.min(cellHeight * 24, (diffInMin * cellHeight) / 60);
  return { top, height };
};
