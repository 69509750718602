import React from "react";

import ControlButtons from "./ControlButtons";

import Button from "components/Button";
import Select from "components/Input/AdvanceSelect";

import { ActionAreaContent, SelectContainer } from "../../styles/display";
import { CalendarTypeOptions } from "./constatnts";

const ActionArea = ({
  dateString,
  onCalendarTypeChange,
  calendarType,
  onPrevDateClick,
  onNextDateClick,
  onTodayClicked,
}) => {
  return (
    <ActionAreaContent>
      <Button onClick={onTodayClicked} outline>
        Today
      </Button>

      <ControlButtons
        currentDate={dateString}
        onPrevDateClick={onPrevDateClick}
        onNextDateClick={onNextDateClick}
      />

      <SelectContainer>
        <Select
          options={CalendarTypeOptions}
          value={calendarType}
          onChange={onCalendarTypeChange}
          defaultSelectText="Select Type"
        />
      </SelectContainer>
    </ActionAreaContent>
  );
};
export default ActionArea;
