import { GET_FOLLOW_SUBORDINATES } from "../actiontypes";

import { createInitialStoreState, createNewStoreOnGetData } from "helper";

const initialState = createInitialStoreState();

export const subordinates = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_FOLLOW_SUBORDINATES}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    default:
      return store;
  }
};
