import styled from "styled-components";
import Icon from "../../Icon";
import { flexColumnCenter } from "../../../../GlobalStyles";

export const FallbackContainer = styled(flexColumnCenter)`
  width: 100%;
  height: 100%;
`;

export const ChildFallbackContainer = styled(FallbackContainer)`
  display: flex;
`;

export const ErrorIcon = styled(Icon)`
  font-size: 80px;
  color: ${(props) => props.theme.colors.fallback.text};
`;

export const SmallErrorIcon = styled(ErrorIcon)`
  font-size: 20px;
`;

export const ErrorText = styled.span`
  margin-top: 10px;
  font-size: ${(props) => props.theme.text.para.medium};
  color: ${(props) => props.theme.colors.fallback.text};
`;

export const ChildErrorText = styled(ErrorText)`
  margin-top: 0;
  margin-left: 10px;
  font-size: ${(props) => props.theme.text.para.small};
`;
