import React, { useCallback, useMemo, useState, useRef } from "react";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";

import { CollaboratorPopover, Count, SearchContainer } from "../../style";
import { getUserListData } from "common/Api";
import { ListItem } from "components/List";
import Popover from "components/PopoverAdvanced2";

function Form({
  formData,
  values,
  updateField,
  onSubmit,
  isLoading,
  resetForm,
}) {
  const [isActive, toggleActive] = useState(false);
  const btnRef = useRef(null);
  const toggleModal = useCallback(() => {
    toggleActive((val) => !val);
  }, []);

  const onDeselectUsers = useCallback(
    (itemId, item) => {
      const newListData = { ...values.collaborators };
      if (newListData[itemId]) {
        delete newListData[itemId];
      }
      updateField("collaborators", newListData);
    },
    [values],
  );

  const selectedUsersList = useMemo(() => {
    return (
      values && values?.collaborators && Object.values(values.collaborators)
    );
  }, [values, onDeselectUsers]);

  return (
    <SearchContainer onSubmit={onSubmit}>
      <InputGroup
        fieldType="datePicker"
        placeholder="From date"
        {...formData["fromDate"]}
      />

      <InputGroup
        fieldType="datePicker"
        placeholder="To date"
        {...formData["toDate"]}
      />

      <InputGroup
        fieldType="asyncSearchSelect"
        placeholder="Collaborator"
        api={getUserListData}
        {...formData["collaborators"]}
      />

      {selectedUsersList && (
        <Count onClick={toggleModal} ref={btnRef}>
          {selectedUsersList.length}
        </Count>
      )}

      {selectedUsersList && (
        <Popover
          onClose={toggleModal}
          active={isActive}
          anchorElem={btnRef.current}
        >
          <CollaboratorPopover>
            {selectedUsersList &&
              selectedUsersList?.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    data={item}
                    onSelect={onDeselectUsers}
                    showDeselectButton={true}
                  />
                );
              })}
          </CollaboratorPopover>
        </Popover>
      )}

      <Button type="submit" primary disabled={isLoading}>
        Search
      </Button>
      <Button onClick={resetForm} outline disabled={isLoading}>
        Reset
      </Button>
    </SearchContainer>
  );
}

export default Form;
