import {
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  GET_ALL_DEPARTMENTS,
} from "./actiontypes";
import { createAsyncActionCreator } from "../../Helper";
import {
  createDepartmentApi,
  updateDepartmentApi,
  getAllDepartmentsApi,
  deleteDepartmentApi,
} from "./api";

const createDepartmentGenrator = function* ({
  companyId,
  departmentName,
  isAdmin,
}) {
  yield {
    action: CREATE_DEPARTMENT,
    sideEffect: createDepartmentApi({ companyId, departmentName, isAdmin }),
  };
};

const updateDepartmentGenrator = function* ({
  companyId,
  departmentName,
  departmentId,
  isAdmin,
}) {
  yield {
    action: UPDATE_DEPARTMENT,
    sideEffect: updateDepartmentApi({
      companyId,
      departmentName,
      departmentId,
      isAdmin,
    }),
  };
};

const getAllDepartmentsGenrator = function* ({
  pageNumber,
  companyId,
  isAdmin,
}) {
  yield {
    action: GET_ALL_DEPARTMENTS,
    sideEffect: getAllDepartmentsApi({ pageNumber, companyId, isAdmin }),
  };
};

const deleteDepartmentGenrator = function* ({
  companyId,
  departmentId,
  isAdmin,
}) {
  yield {
    action: DELETE_DEPARTMENT,
    sideEffect: deleteDepartmentApi({ companyId, departmentId, isAdmin }),
  };
};

export const [
  getAllDepartments,
  createDepartment,
  updateDepartment,
  deleteDepartment,
] = createAsyncActionCreator([
  getAllDepartmentsGenrator,
  createDepartmentGenrator,
  updateDepartmentGenrator,
  deleteDepartmentGenrator,
]);
