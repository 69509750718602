import { getImageUrl } from "../../Helper";

export const generateCreateTeamFormData = (values) => {
  const { name, owners, status, department } = values;
  const departmentKeys = department && Object.keys(department);
  const ownersKeys = owners && Object.keys(owners);
  const formData = {
    name,
    status,
    team_owner_ids: ownersKeys,
  };

  if (departmentKeys && departmentKeys.length > 0) {
    formData.department_id = departmentKeys[0];
  }

  return formData;
};

export const transformToUserList = (data, cellNameToColumnMap) => {
  const idColumn = cellNameToColumnMap["Sl No"];
  const empIdColumn = cellNameToColumnMap["employeeId"];
  const nameColumn = cellNameToColumnMap["name"];
  const avatarColumn = cellNameToColumnMap["avatarUrl"];

  const res = [];

  data.forEach((row, index) => {
    if (index === 0) {
      return;
    }

    const [id, empId, name, avatar] = [
      row[idColumn],
      row[empIdColumn],
      row[nameColumn],
      row[avatarColumn],
    ];
    if (id && name) {
      res.push({
        id,
        empId,
        heading: name,
        image: getImageUrl(avatar?.url),
      });
    }
  });
  return res;
};

export const getTeamOwnerCollaboratorData = (data) => {
  const arr = [];
  for (let index = 0; index < data.length; index++) {
    arr.push({ ...data[index].collaborator });
  }
  return arr;
};

export const getInitialData = (data, owners, departments) => {
  return {
    name: data[0],
    status: data[1],
    department: departments,
    owners,
  };
};

export const transformToMemberList = (data, cellNameToColumnMap) => {
  const collaboratorColumn = cellNameToColumnMap["collaborator"];

  const res = [];
  data.forEach((row, index) => {
    if (index === 0) {
      return;
    }
    const [collaborator] = [row[collaboratorColumn]];
    const { employeeId, avatarUrl, email, phone, name, role, joinedOn } =
      collaborator;
    if (name) {
      res.push({
        employeeId: employeeId,
        name,
        phone: phone,
        email: email,
        role: role,
        avatarUrl: avatarUrl,
        joinedOn: joinedOn,
      });
    }
  });
  return res;
};

export const getCurrentIndexRowDataAndColumn = (rows, rowIndex) => {
  return {
    data: rows[rowIndex],
    columns: rows[0],
  };
};
