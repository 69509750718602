import {
  GET_ENQUIRIES,
  SEARCH_ENQUIRIES,
  UPDATE_ENQUIRY_SEARCH_PARAMS,
  RESET_ENQUIRY_SEARCH_PARAMS,
  CREATE_ENQUIRY
} from "../actiontypes";

import {
  createInitialStoreState,
  createNewStoreOnGetData,
  createNewStoreOnCreate
} from "helper";

const initialState = createInitialStoreState({
  prevData: {},
  isSearchOn: false
});

export const collaboratorEnquiryReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_ENQUIRIES}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${SEARCH_ENQUIRIES}_SUCCESS`: {
      let prevData;

      //  only updating the prev data at the first search occurence
      if (Object.keys(store.prevData).length === 0) {
        prevData = { ...store, onceFetched: true };
      } else {
        prevData = store.prevData;
      }

      const searchData = createNewStoreOnGetData(payload, store, true);
      return {
        ...searchData,
        prevData
      };
    }

    case `${CREATE_ENQUIRY}_SUCCESS`:
      return createNewStoreOnCreate(payload, store, true);

    case `${UPDATE_ENQUIRY_SEARCH_PARAMS}`: {
      const { collaborator, from_date, to_date, enquiry_type, status } =
        payload;

      return {
        ...store,
        onceFetched: false,
        collaborator: collaborator,
        currentFromDate: from_date,
        currentToDate: to_date,
        enquiryType: enquiry_type,
        currentStatus: status,
        isSearchOn: true
      };
    }

    case `${RESET_ENQUIRY_SEARCH_PARAMS}`: {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
        collaborator: "",
        currentFromDate: "",
        currentToDate: "",
        enquiryType: "",
        currentStatus: "",
        isSearchOn: false
      };
    }

    default:
      return store;
  }
};
