import { useState, useEffect } from "react";

const useLocationAccessState = () => {
  const [resultState, setResultState] = useState();

  useEffect(() => {
    if (navigator.geolocation) {
      const success = () => {
        setResultState("granted");
      };
      const error = (err) => {
        setResultState("denied");
        console.error(`ERROR(${err.code}): ${err.message}`);
      };
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      setResultState("not available");
    }
  }, []);

  return resultState;
};

export default useLocationAccessState;
