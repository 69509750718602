import React from "react";
import { useParams } from "react-router";
import { MainContainer } from "../../styles/create";
import Form from "./Form";

const BranchCreateView = () => {
  const params = useParams();
  return (
    <MainContainer>
      <Form editId={params.branchId}/>
    </MainContainer>
  );
};
export default BranchCreateView;
