import React, { useState, useCallback } from "react";
import { connect } from "react-redux";

import Button from "components/Button";
import { RectangleSkeleton } from "components/Skeleton";

import LeadsCreateView from "../Create/index";
import ActionArea from "./ActionArea";
import TableData from "./TableData";

import EmptyScreen from "assets/employee/LeadBlank.png";

import { isCurrentUserIsExecutive } from "common/Selectors/auth";

import { ViewContainer } from "globalStyles";
import { CreateButton, EmptyScreenContainer, EmptyScreenImage, EmptyScreenMessage } from "../../style/display";

const LeadsDisplayView = ({ userId, isAdmin }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(true);
  }, []);

  if (isFormVisible) {
    return <LeadsCreateView setIsFormVisible={setIsFormVisible} userId={userId} />;
  }

  return (
    <ViewContainer>
      {isAdmin && !isLoading && !isEmpty &&
        <CreateButton>
          <Button primary onClick={updateFormVisibilty}>
            Create New
          </Button>
        </CreateButton>
      }
      <ActionArea
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        onLoading={onDbDataChange}
        userId={userId}
      />
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        onEmptyData={onEmptyData}
      />
      {
        !isLoading && isEmpty && (
          <EmptyScreenContainer>
            <EmptyScreenImage src={EmptyScreen} alt="No records found" />
            <EmptyScreenMessage>
              {isAdmin ?
                "No lead assigned to any one yet"
                : "No lead assigned to you yet"}
            </EmptyScreenMessage>
            {isAdmin &&
              <Button primary size="lg" onClick={updateFormVisibilty}>
                Create New
              </Button>
            }
          </EmptyScreenContainer>
        )
      }
      {isLoading && <RectangleSkeleton times={4} />}
    </ViewContainer >
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { isAdmin };
};

export default connect(mapStateToProps, null)(LeadsDisplayView);
