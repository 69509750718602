import { companySelection } from "./companySelection";
import { department } from "./department";
import { notice } from "./notice";
import { jobTitle } from "./JobTitle";
import { user } from "./user";
import { accessAssets } from "./companyAssets";
import { welcome } from "./welcome";
import { calendar } from "./calendar";
import { holiday } from "./holiday";
import { holidayYear } from "./holidayYear";
import { reimbursement } from "./reimbursement";
import { profile } from "./profile";
import { leads } from "./leads";
import { followSubordinate } from "./followSubordinate";
import { attendence } from "./attendence";
import { separation } from "./separation";
import { leave } from "./leave";
import { salarySlip } from "./salarySlip";
import { helpdesk } from "./helpdesk";
import { companyBranch } from "./companyBranch";
import { breaks } from "./breaks";
import { teams } from "./teams";
import { posts } from "./posts";
import { leaves } from "./leaves";
import { survey } from "./survey";
import { leaveManagement } from "./leaveManagement";
import { ledger } from "./ledger";
import { notifiedWorkReport } from "./notifiedWorkReport";
import { countdown } from "./countdown";
import { userProfile } from "./userProfile";
import { workshift } from "./workshift";

const Routes = {
  unAuthorized: {
    loginRoute: {
      path: "/",
    },
    forgotPassword: {
      path: "/forgot_password",
    },
    updatePassword: {
      path: "/update_password",
    },
  },

  authorized: {
    welcome,
    companySelection,
    department,
    notice,
    jobTitle,
    user,
    calendar,
    accessAssets,
    holiday,
    holidayYear,
    reimbursement,
    profile,
    leads,
    followSubordinate,
    attendence,
    separation,
    salarySlip,
    helpdesk,
    leave,
    companyBranch,
    breaks,
    teams,
    posts,
    leaves,
    survey,
    leaveManagement,
    ledger,
    notifiedWorkReport,
    countdown,
    userProfile,
    workshift,
  },
};

export default Routes;
