import {
  GET_ALL_CALENDARS,
  GET_MONTH_SCHEDULE,
  SET_USER_CALENDAR,
  SET_CALENDAR_TYPE,
  SET_DATE_RANGE,
  CREATE_CALENDAR,
  CREATE_CALENDAR_EVENT,
} from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import {
  getAllCalendarsApi,
  getMonthScheduleApi,
  createCalendarApi,
  createCalendarEventApi,
} from "./api";

const getAllCalendarsGenerator = function* ({ companyId, isAdmin }) {
  yield {
    action: GET_ALL_CALENDARS,
    sideEffect: getAllCalendarsApi({ companyId, isAdmin }),
  };
};

const getMonthScheduleGenerator = function* ({
  fromDate,
  toDate,
  calendarIds,
  companyId,
  isAdmin,
}) {
  yield {
    action: GET_MONTH_SCHEDULE,
    sideEffect: getMonthScheduleApi({
      fromDate,
      toDate,
      calendarIds,
      companyId,
      isAdmin,
    }),
  };
};

const createCalendarGenerator = function* ({
  formData,
  isAdmin,
  companyId,
  userList,
}) {
  yield {
    action: CREATE_CALENDAR,
    sideEffect: createCalendarApi({
      formData,
      isAdmin,
      userList,
      companyId,
    }),
  };
};

const createCalendarEventGenerator = function* ({
  formData,
  isAdmin,
  companyId,
  userList,
}) {
  yield {
    action: CREATE_CALENDAR_EVENT,
    sideEffect: createCalendarEventApi({
      formData,
      isAdmin,
      userList,
      companyId,
    }),
  };
};

export const updateSelectedCalendars = ({ calendar }) => ({
  type: SET_USER_CALENDAR,
  payload: { calendar },
});
export const updateCalendarType = ({ calendarType }) => ({
  type: SET_CALENDAR_TYPE,
  payload: { calendarType },
});

export const setDateRange = ({ rangeFirstDate, currentDate }) => ({
  type: SET_DATE_RANGE,
  payload: { rangeFirstDate, currentDate },
});

export const [
  getAllCalendars,
  getMonthSchedule,
  createCalendar,
  createCalendarEvent,
] = createAsyncActionCreator([
  getAllCalendarsGenerator,
  getMonthScheduleGenerator,
  createCalendarGenerator,
  createCalendarEventGenerator,
]);
