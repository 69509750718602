import React from "react";
import Form from "./Form";

import { useParams } from "react-router";
import { MainContainer } from "../../../../style/create";

const LeaveTypeCreateView = ({ updateFormVisibility }) => {
  const params = useParams();

  return (
    <MainContainer>
      <Form updateFormVisibility={updateFormVisibility} editId={params.id} />
    </MainContainer>
  );
};
export default LeaveTypeCreateView;
