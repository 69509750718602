import {
  ASSIGN_LEAVES,
  DELETE_ASSIGNED_LEAVE,
  EDIT_ASSIGNED_LEAVE,
  GET_ALL_ACTIVE_LEAVE_TYPES,
  GET_ALL_ASSIGNED_LEAVES,
} from "./actiontypes";

import {
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnUpdate,
  createNewStoreOnDelete,
} from "helper";

const initialState = createInitialStoreState(
  {
    activeLeaveTypes: {},
  },
  true,
);

export const assignedLeavesReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_ALL_ASSIGNED_LEAVES}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    case `${ASSIGN_LEAVES}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true);
    }

    case `${GET_ALL_ACTIVE_LEAVE_TYPES}_SUCCESS`: {
      return {
        ...store,
        activeLeaveTypes: payload,
      };
    }

    case `${EDIT_ASSIGNED_LEAVE}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store, true);
    }

    case `${DELETE_ASSIGNED_LEAVE}_SUCCESS`: {
      return createNewStoreOnDelete(payload, store, true);
    }

    default:
      return store;
  }
};
