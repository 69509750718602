import requestData from "../../../../ApiHandler";
import { createAuthUrl } from "helper";

export const fetchTodaysAnniversaryApi = ({
  companyId,
  isAdmin,
  pageNumber,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_today_work_anniversaries?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const fetchUpcomingAnniversarysApi = ({
  companyId,
  isAdmin,
  pageNumber,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_upcoming_work_anniversaries?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
