import React from "react";
import BackButton from "../BackButton";
import styles from "./PageSlider.module.css";

const PageSlider = ({
  active,
  className = "",
  onClose,
  showBackButton = true,
  children,
  ...otherProps
}) => {
  return active ? (
    <div {...otherProps} className={`${styles.container} ${className}`}>
      {showBackButton && (
        <BackButton className={styles.back} onClose={onClose} showIcon />
      )}
      {children}
    </div>
  ) : null;
};

export default PageSlider;
