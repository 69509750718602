import React, { useState, useCallback } from "react";

import InputGroup from "components/InputGroup";
import Button from "components/Button";

import { isDateCombinationValid } from "helper";

import { isOptionalOptions } from "./options";

import { StyledForm } from "../../style/display";

const SearchFilter = ({
  onFormSubmit,
  onResetClick,
  from_date,
  to_date,
  is_optional
}) => {
  const [startDate, setStartDate] = useState("");
  const [InvalidMessage, setInvalidMessage] = useState("");

  const onStartDateChange = useCallback((e) => {
    setStartDate(e.target.value);
  }, []);

  const endDateValidator = useCallback(
    (endDate) => {
      if (startDate) {
        const { isValid, message } = isDateCombinationValid(
          startDate,
          endDate,
          "Start",
          "End"
        );
        setInvalidMessage(message);
        return isValid;
      }
      return true;
    },
    [startDate]
  );

  return (
    <>
      <StyledForm onSubmit={onFormSubmit} onReset={onResetClick}>
        <InputGroup
          id="holidaySearch_holidayFromDate"
          fieldType="datePicker"
          placeholder="From Date"
          onChange={onStartDateChange}
        />

        <InputGroup
          id="holidaySearch_holidayToDate"
          fieldType="datePicker"
          placeholder="To Date"
          validator={endDateValidator}
          invalidMessage={InvalidMessage}
        />

        <InputGroup
          id="holidaySearch_holidayIsOptional"
          fieldType="select"
          options={isOptionalOptions}
          defaultSelectText="Select Is Optional"
        />

        <Button primary type="submit" size="lg">
          Search
        </Button>
        <Button outline type="reset" size="lg">
          Reset
        </Button>
      </StyledForm>
    </>
  );
};

export default SearchFilter;
