import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";
import { deletePost } from "modules/WelcomePage/redux/Posts/actionCreator";

export const useDeletePost = () => {
  const { showAlert } = useAlert();
  const [isLoading, updateLoading] = useState(false);
  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const dispatch = useDispatch();
  const postDelete = async (postId) => {
    try {
      updateLoading(true);
      await dispatch(deletePost({ companyId, isAdmin, postId }));
      showAlert({
        message: `Post Deleted`,
        type: "success"
      });
      updateLoading(false);
    } catch (err) {
      updateLoading(false);
      showAlert({ message: err[0], type: "error" });
    }
  };
  return {
    isLoading,
    postDelete
  };
};
