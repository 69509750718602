import React, { useMemo } from "react";
import { postReactionImages } from "../../../constants";
import { ReactionsTabsMap } from "../constants";
import DEFAULT_PROFILE_PHOTO from "../../../../../../Assets/common/ProfilePhoto.png";

import {
  SectionContainer,
  SectionProfileContent,
  SectionReactionImage,
} from "../style";

import Tooltip from "components/Tooltip2";

function SectionListContent({ image, name, id, reactionType, ...rest }) {
  const key = `${reactionType}_${id}`;
  return (
    <SectionContainer>
      <SectionProfileContent>
        <img
          className="profileimg"
          src={image ? image : DEFAULT_PROFILE_PHOTO}
        />
        <span>{name}</span>
        <SectionReactionImage
          src={postReactionImages[reactionType]}
          data-tooltip={key}
        />
        <Tooltip tooltipId={key}>
          {ReactionsTabsMap?.[reactionType]?.name}
        </Tooltip>
      </SectionProfileContent>
    </SectionContainer>
  );
}

export default SectionListContent;
