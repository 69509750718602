import styled from "styled-components";
import Icon from "../Icon";
import Button from "../Button";
import ProfilePic from "../ProfilePic";

export const ListItemContainer = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  background-color: transparent;
  margin-bottom: 17px;
`;
export const ListItemContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  padding-left: 15px;
`;

export const ListItemHeading = styled.h5`
  font-weight: 600;
  font-size: ${({ theme }) => theme.text.para.small};
  color: #000000;
  margin: 0;
`;

export const ListItemDesc = styled.span`
  margin-top: 2px;
  font-weight: normal;
  font-size: ${({ theme }) => theme.text.para.extraSmall};
  color: #000000;
`;
export const ListItemImage = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;
`;

export const ListItemImageIcon = styled(Icon)`
  font-size: 36px;
  color: gray;
`;

export const ListItemCheckButton = styled(Button)`
  width: 33px;
  height: 31px;
  border-radius: 6px;
  background-color: ${({ active, theme }) =>
    active ? "white" : theme.colors.primaryColor};
  color: ${({ active, theme }) =>
    active ? theme.colors.primaryColor : "white"};
`;

export const StyledProfilePic = styled(ProfilePic)`
  width: 40px;
  height: 40px;
`;

export const BulletList = styled.span`
  font-size: 25px;
  margin-bottom: 5px;
`;
