import { combineReducers } from "redux";
import { attendanceTableReducer } from "./attendanceTable";
import { absenteesReducer } from "./absentees";
import { presenteesReducer } from "./presentees";
import { statusActivityReducer } from "./statusActivity";

export const attendanceReducer = combineReducers({
  attendanceTable: attendanceTableReducer,
  absentees: absenteesReducer,
  presentees: presenteesReducer,
  statusActivity: statusActivityReducer,
});
