import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 30px 0px;
  overflow-y: auto;
  --cell-inline-padding: 10px;
  --row-content-block-padding: 15px;
  --row-content-inline-padding: 35px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0px;
`;
export const RowContainer = styled.div`
  display: flex;
  padding: var(--row-content-block-padding) var(--row-content-inline-padding);
  width: 100%;
  border-bottom: ${({ $isExpanded }) => ($isExpanded ? "0" : "1px")} solid
    #ededed;
  & > .content {
    display: flex;
    width: 100%;
    overflow: hidden;
  }
`;

export const ExtraDataContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 18px 35px;
  border-bottom: 1px solid #ededed;
  max-width: 100%;
  overflow-x: hidden;
  gap: 10px;
`;

export const ExtraDataContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  & > .label {
    text-transform: capitalize;
  }
`;

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 var(--cell-inline-padding);
  overflow-x: hidden;
  text-align: left;

  &.left-all {
    margin-left: auto;
  }
  & .general {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`;
export const DownButton = styled.button`
  border: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: ${({ $active }) => ($active ? "#01008A" : "#b5b5b5")};
  font-size: 15px;
  background-color: transparent;
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  padding: 15px 35px;
  background-color: white;
`;

export const TableTabsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TableTabsContent = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: 17px;
  padding: 12px;
  cursor: pointer;
  border: none;
  font-weight: 600;
  font-size: 16px;

  background-color: transparent;
  color: ${({ $active, theme }) =>
    $active ? theme.colors.primaryColor : "#929292"};

  &::after {
    content: "";
    width: ${({ $active }) => ($active ? "100%" : "0")};
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 2px;
    background-color: ${(props) => props.theme.colors.primaryColor};
  }
`;

export const PaginatorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PageButton = styled.button`
  border-radius: 50%;
  width: 22px;
  height: 22px;
  border: none;
  font-size: 10px;

  cursor: pointer;

  &.light {
    color: #01008a;
    background-color: ${(props) => props.theme.colors.lightPrimaryColor};
  }

  &.dark {
    color: white;
    background-color: ${(props) => props.theme.colors.primaryColor};
  }
`;

export const ActionCellContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: var(--row-content-block-padding) 0;
  gap: 1px;
  border: 1px blue solid;
  align-items: stretch;
`;
