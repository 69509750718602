export const FETCH_POSTS = "FETCH_POSTS";
export const CREATE_POST = "CREATE_POST";
export const CREATE_POLL = "CREATE_POLL";
export const CREATE_QUIZ = "CREATE_QUIZ";
export const CREATE_POST_COMMENT = "CREATE_POST_COMMENT";
export const REACT_TO_POST = "REACT_TO_POST";
export const REMOVE_REACT_FROM_POST = "REMOVE_REACT_FROM_POST";
export const ANSWERE_TO_QUIZ = "ANSWERE_TO_QUIZ";
export const ANSWERE_TO_POLL = "ANSWERE_TO_POLL";
export const DELETE_POST = "DELETE_POST";
