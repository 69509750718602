import requestData from "../../ApiHandler";
import { createAuthUrl } from "../../Helper";

export const getHolidayYearsForViewApi = ({ companyId, isAdmin, pageNumber }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_holiday_years?page=${pageNumber}`
  });
  return requestData(url, {
    method: "GET",
  }
  );
};
export const createHolidayYearApi = ({ companyId, isAdmin, formData }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/holiday_years`
  });
  return requestData(url, {
    method: "POST",
    body: {
      holiday_year: {
        name: formData.name,
        start_date: formData.startDate,
        end_date: formData.endDate,
        ongoing_year: formData.ongoingYear,
      },
    },
    isFormData: true,
  });
};
