import requestData from "../../ApiHandler";
import { createAuthUrl } from "../../Helper";

export const getLeadsApi = ({ companyId, isAdmin, pageNumber }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_leads?page=${pageNumber}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const getLeadsByCollaboratorApi = ({ companyId, isAdmin, pageNumber, userId }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_leads_by_collaborator?page=${pageNumber}&collaborator_id=${userId}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const createLeadApi = ({ companyId, data, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborator_leads`,
  });

  return requestData(authUrl, {
    method: "POST",
    body: {
      collaborator_lead: {
        collaborator_id: data.collaborator_id,
        lead_by_id: data.lead_by_id,
        lead_type: data.lead_type,
        start_date: data.start_date,
        status: data.status,
      },
    },
    isFormData: true,
  });
};

export const searchLeadsApi = ({
  pageNumber,
  companyId,
  isAdmin,
  userId,
  fromStartDate,
  toStartDate,
  fromEndDate,
  toEndDate,
  status,
  leadType,
  leadById,
}) => {
  let from_start_date, to_start_date, from_end_date, to_end_date, current_status, lead_type, lead_by_id;
  from_start_date = to_start_date = from_end_date = to_end_date = current_status = lead_type = lead_by_id = "";

  if (fromStartDate)
    from_start_date = `&from_start_date=${fromStartDate}`;
  if (toStartDate)
    to_start_date = `&to_start_date=${toStartDate}`;
  if (fromEndDate)
    from_end_date = `&from_end_date=${fromEndDate}`;
  if (toEndDate)
    to_end_date = `&to_end_date=${toEndDate}`;
  if (status)
    current_status = `&status=${status}`;
  if (leadType)
    lead_type = `&lead_type=${leadType}`;
  if (leadById)
    lead_by_id = `&lead_by_id=${leadById}`;

  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/search_collaborator_lead?page=${pageNumber}&collaborator_id=${userId}${from_start_date}${from_end_date}${to_start_date}${to_end_date}${current_status}${lead_type}${lead_by_id}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};
