import { CreateComment } from "components/Comment";
import CommentView from "components/Comment/CommentView";
import Icon from "components/Icon";
import MultipleUsers from "components/MultipleUsers";
import TextWithLinks from "components/TextWithLinks";
import {
  CollaboratorContainer,
  CollaboratorSubContainer,
  ContentContainer,
  ContentHeader,
  LogWorkButton,
  NoDataContainer,
} from "modules/Attendence/style/details";
import React, { useMemo, useState } from "react";
import { DetailItems } from "./DetailItem";
import DocumentView from "./DocumentView";
import WorkLogCreateForm from "./create";
import PageBackHeader from "components/PageBackHeader";

const WorklogView = ({
  isSubordinateAttendance,
  isNotifiedWorkReportAttendance,
  onClick,
  isCreateFormVisible,
  setIsCreateFormVisible,
  dailyWorkReport,
  onCommentWorkLog,
  isCreating,
  showBackButton,
  onBack,
}) => {
  const isWorkLogPresent = useMemo(() => {
    return dailyWorkReport && dailyWorkReport != "NA";
  }, [dailyWorkReport]);

  const toggleFormVisibility = () => {
    setIsCreateFormVisible((prev) => !prev);
  };
  if (isCreateFormVisible) {
    return (
      <WorkLogCreateForm
        dailyWorkReport={dailyWorkReport}
        updateFormVisibility={toggleFormVisibility}
      />
    );
  }
  if (!isWorkLogPresent) {
    return (
      <NoDataContainer>
        {showBackButton && <PageBackHeader onBack={onBack} />}
        <Icon
          name="far fa-clock"
          style={{ fontSize: "3rem", color: "#01008A" }}
        />
        <p>No work has been logged yet.</p>
        {!isSubordinateAttendance && !isNotifiedWorkReportAttendance && (
          <LogWorkButton onClick={toggleFormVisibility}>Log Work</LogWorkButton>
        )}
      </NoDataContainer>
    );
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        {showBackButton && <PageBackHeader onBack={onBack} />}
        {!isSubordinateAttendance && !isNotifiedWorkReportAttendance && (
          <LogWorkButton
            onClick={onClick || toggleFormVisibility}
            style={{ marginLeft: "auto", marginTop: 10 }}
          >
            Edit
          </LogWorkButton>
        )}
      </div>
      <DetailItems
        heading="Accomplishments"
        description={dailyWorkReport?.accomplishments}
        color="#1A4F02"
      />
      {dailyWorkReport?.todoPlans && (
        <DetailItems
          heading="Plans for the Following Day"
          description={dailyWorkReport?.todoPlans}
          color="#67057A"
        />
      )}
      {dailyWorkReport?.challenges && (
        <DetailItems
          heading="Issues or Challenges"
          description={dailyWorkReport?.challenges}
          color="#bd1a08"
        />
      )}

      {dailyWorkReport?.referenceLinks != "NA" &&
        dailyWorkReport?.referenceLinks?.length > 0 && (
          <ContentContainer>
            <ContentHeader $workLogTypeColor="#7863CD">
              Reference Links
            </ContentHeader>
            <ul>
              {dailyWorkReport?.referenceLinks.map((item) => (
                <li>
                  <TextWithLinks>{item}</TextWithLinks>
                </li>
              ))}
            </ul>
          </ContentContainer>
        )}
      <CollaboratorContainer>
        {dailyWorkReport?.allocatedCarbonCopyMembers != "NA" &&
          dailyWorkReport?.allocatedCarbonCopyMembers?.length > 0 && (
            <CollaboratorSubContainer>
              <ContentHeader>Notified To: </ContentHeader>
              <MultipleUsers
                data={dailyWorkReport?.allocatedCarbonCopyMembers}
              />
            </CollaboratorSubContainer>
          )}
      </CollaboratorContainer>
      {dailyWorkReport?.documentUrls != "NA" &&
        dailyWorkReport?.documentUrls?.length > 0 && (
          <>
            <ContentHeader>
              Documents ({dailyWorkReport?.documentUrls?.length})
            </ContentHeader>
            <DocumentView data={dailyWorkReport?.documentUrls} />
          </>
        )}
      <ContentHeader>Comments</ContentHeader>
      <CreateComment
        onComment={onCommentWorkLog}
        isCreatingComment={isCreating}
      />
      <CommentView
        commentableId={dailyWorkReport.id}
        commentableType="DailyWorkReport"
      />
    </>
  );
};

export default WorklogView;
