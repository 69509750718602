import { GET_ALL_COLLABORATOR_LEAVES } from "../actiontypes";

import { createNewStoreOnGetData, createInitialStoreState } from "helper";

const initialState = createInitialStoreState();

export const collaboratorLeavesReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_ALL_COLLABORATOR_LEAVES}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, false);
    }
    default:
      return store;
  }
};
