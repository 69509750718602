import React, { useMemo, useCallback, useEffect } from "react";
import { connect } from "react-redux";

import Grid from "../../../../Common/Components/Grid";
import { createTableDataSelector } from "../../../../Common/Selectors/MTable";

import { TableContainer } from "../../../../GlobalStyles";
import { calculateMaxLength } from "helper";
import RenderItem from "./RenderItem";

const TableData = ({
  data,
  onCellClick,
  cellNameToColumnMap,
  rowIdMap,
  onEmptyData,
  ...other
}) => {
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);

  const maxStatusLength = useMemo(() => {
    return calculateMaxLength(cellNameToColumnMap, data);
  }, [data]);

  const onClick = useCallback(
    ({ data: rowData, columns, rowIndex }) => {
      if (typeof onCellClick === "function") {
        onCellClick({ data: rowData, columns, rowIndex, rowIdMap });
      }
    },
    [onCellClick, rowIdMap]
  );

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (rows === 1) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);
  return (
    <TableContainer {...other}>
      <Grid
        onClick={onClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        data={{
          rowData: data,
          cellNameToColumnMap,
          maxStatusLength: maxStatusLength,
          onIdClick: onClick,
        }}
        dataKey="rowData"
      >
        {RenderItem}
      </Grid>
    </TableContainer>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "attendance.attendanceTable",
    blackList: { "Sl No": true, dailyWorkReport: true },
    reverse: false,
  });
  const maker = (store, props) => {
    const { rows, cellNameToColumnMap, rowIdMap } = getUserRows(store, props);
    return {
      data: rows,
      rowIdMap,
      cellNameToColumnMap,
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
