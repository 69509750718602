import { useEffect, useMemo } from "react";
import { getOncefetched } from "common/Selectors/store";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { createTableDataSelector } from "common/Selectors/MTable";

import usePaginator from "hooks/usePaginator";
import usePagination from "hooks/usePagination";

import { fetchUpcomingJoinees } from "../../redux/Joinee/actionCreator";
import { useSelector } from "react-redux";
import { transformCollaboratorMatrixToArray } from "../../utils";

const onceFetchedSelector = getOncefetched("joinees.upcoming");
const upcomingJoineesSelector = createTableDataSelector({
  tableName: "joinees.upcoming",
  blackList: { "Sl No": true },
  fetchAllData: true,
});

export function useFetchUpcomingJoinees() {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    allPagesFetched,
    errorOccured,
  } = usePagination({
    actionCreator: fetchUpcomingJoinees,
    tableName: "joinees.upcoming",
  });

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const isOnceFetched = useSelector(onceFetchedSelector);
  const { rows: data, rowIdMap } = useSelector(upcomingJoineesSelector);

  const { fetchFirstPage, fetchNextPage } = usePaginator({
    paginatorApi: getNextPage,
    extraParams: { companyId, isAdmin },
  });

  useEffect(() => {
    if (!isOnceFetched) {
      fetchFirstPage();
    }
  }, []);

  const allUpcomingJoinees = useMemo(() => {
    const allData = transformCollaboratorMatrixToArray(data, rowIdMap);
    return allData.reverse();
  }, data);

  return {
    isLoading: loadingNextPage || loadingPrevPage,
    allUpcomingJoinees,
    fetchNextPage,
    allDataFethced: allPagesFetched,
    hasError: errorOccured,
  };
}
