import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { isUserLoggedIn } from "../../../Common/Selectors/auth";
import NOT_FOUND from "../assets/notFound.png";
import GreenIrregular from "../assets/GreenIrregular.png";
import {
  ViewPortContainer,
  TopContainer,
  BottomContainer,
  Box1,
  Box2,
  Heading,
  SubHeading,
  Paragraph,
  Image,
  HomeButton,
  BackgroundImage,
} from "../styles";

const NotFountPage = () => {
  const history = useHistory();
  const isLoggedIn = useSelector(isUserLoggedIn);

  const onClick = useCallback(() => {
    history.push("/");
  }, []);

  return (
    <ViewPortContainer isLoggedIn={isLoggedIn}>
      <BackgroundImage src={GreenIrregular} isLoggedIn={isLoggedIn} />
      <TopContainer isLoggedIn={isLoggedIn}>
        <Box1>
          <Heading isLoggedIn={isLoggedIn}>Oops!</Heading>
          <SubHeading>Not Enough Data</SubHeading>
          <Paragraph>
            You are seeing this page because this URL does not exist. Please
            report to us if you think this is an error.
          </Paragraph>
        </Box1>
        <Box2>
          <Image src={NOT_FOUND} />
        </Box2>
      </TopContainer>
      {!isLoggedIn && (
        <BottomContainer>
          <HomeButton onClick={onClick}>Back to Home</HomeButton>
        </BottomContainer>
      )}
    </ViewPortContainer>
  );
};
export default NotFountPage;
