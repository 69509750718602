import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.notification.countColor};
  right: 0px;
  top: -15px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 8px;
`;
