import styled from "styled-components";
import Button from "components/Button";
import ActivityIndicator from "components/ActivityIndicator";
import { scrollbar1 } from "globalStyles";

export const ViewContainer = styled.div`
  height: 100%;
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
  @media (min-width: 1024px) {
    padding: 5px 50px 10px 50px;
  }
`;

export const ActionAreaContent = styled.div`
  flex-basis: 100px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

export const Content = styled.div`
  flex-grow: 1;
`;

export const SelectContainer = styled.div`
  width: 183px;
  margin-left: auto;
`;

export const ControlArea = styled.div`
  min-height: 30px;
  display: flex;
  margin-left: 30px;
  align-items: center;
`;
export const DateText = styled.span`
  font-size: ${(props) => props.theme.text.title.medium};
  font-weight: 600;
  color: ${(props) => props.theme.colors.primaryColor};
  margin-left: 20px;
`;

export const ArrowButton = styled(Button)`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
`;

export const CalendarListContainer = styled.div`
  min-height: 70px;
  display: flex;
  align-items: center;
  border-top: 1px ${(props) => props.theme.colors.primaryColor} solid;
  border-bottom: 1px ${(props) => props.theme.colors.primaryColor} solid;
  margin-bottom: 10px;
  overflow-x: auto;
  width: calc(100%);
  ${scrollbar1}
`;

export const ListLoader = styled(ActivityIndicator)`
  margin: 0 auto;
`;
