import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
export const getAddressDetails = (address, callback) => {
  geocodeByAddress(address)
    .then(async (results) => {
      let coordinateObj = await getLatLng(results[0]);
      return { coordinateObj, results };
    })
    .then(({ coordinateObj, results }) => {
      if (callback && typeof callback === "function") {
        callback(coordinateObj, results[0].formatted_address, address);
      }
    })
    .catch((error) => console.error("Error", error));
};
