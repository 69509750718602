import { IS_DEV } from '../config.local';
import { COMPRESSION_MAX_WIDTH, COMPRESSION_MAX_HEIGHT } from '../Constants';
import { getConcatenatedFileName } from './string';

export const compressImageList = async (imageList) => {
  try {
    const compressedImageList = await Promise.all(
      imageList.map(async im => {
        if (im.type.indexOf('image') < 0)
          return im;

        const file = await createCompressedImage(im);
        return file;
      })
    );

    return compressedImageList;
  } catch (error) {
    console.error('Error in compressing image list', error);
    return imageList;
  }
}

export const createCompressedImage = (file) => {
  return new Promise(resolve => {
    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;

    img.onerror = function () {
      URL.revokeObjectURL(this.src);
      console.error("Cannot load image");
      // return original file if image fails to load
      resolve(file);
    };

    img.onload = function () {
      URL.revokeObjectURL(this.src);
      // for the time being maxWidth and maxHeight is fixed to 1024 and 768
      const [newWidth, newHeight] = calculateSize(img, COMPRESSION_MAX_WIDTH, COMPRESSION_MAX_HEIGHT);
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        (blob) => {
          // coverting blob to file
          const compressedFile = new File(
            [blob],
            getConcatenatedFileName(file.name, "_Compressed"),
            {
              lastModified: new Date(),
              type: file.type
            });

          if (IS_DEV) {
            displayInfo(file.name, file);
            displayInfo(compressedFile.name, compressedFile);
          }
          resolve(compressedFile)
        },
        file.type
      );
    };
  })
}

function calculateSize(img, maxWidth, maxHeight) {
  let width = img.width;
  let height = img.height;

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
}

function displayInfo(label, file) {
  console.log(`${label} - ${readableBytes(file.size)} -`, file);
}

function readableBytes(bytes) {
  const i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}