import requestData from "../../ApiHandler";

export const sendPasswordResetEmailApi = ({ email }) => {
  return requestData(`/reset_password`, {
    method: "POST",
    body: {
      email: email,
    },
    isFormData: true,
  });
};
