import { GET_SUBORDINATE_BREAKS } from "../actiontypes";
import { UPDATE_COLLABORATOR_ID } from "../../FollowSubordinate/actiontypes";

import {
  createInitialStoreState,
  createNewStoreOnGetData,
} from "helper";

const initialState = createInitialStoreState({
  collaboratorId: ""
});

export const subordinateSnapsReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_SUBORDINATE_BREAKS}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${UPDATE_COLLABORATOR_ID}`:
      return {
        ...store,
        collaboratorId: payload.id,
      };

    default:
      return store;
  }
};
