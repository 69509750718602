import { LocalStorage } from "storage";
import { LOGOUT_USER } from "../Common/ActionTypes";
import { localDbKey } from "../Constants";
/**
 * Used to store and retrive the reducers in/from the local storage
 * @param {*} param0
 */
export const CombinedLocalStore = ({ reducer, whitelist = [] }) => {
  let isFirstTime = true;

  return (store, action) => {
    let storeData = reducer(store, action);
    const isLogout = action.type === LOGOUT_USER;
    if (isFirstTime) {
      const data = LocalStorage.getKey(localDbKey);
      const localData = data || {};
      storeData = { ...storeData, ...localData };
      isFirstTime = false;
    }

    if (isLogout) {
      LocalStorage.clearStorage();
      storeData = reducer(undefined, "");
    } else {
      let dataToSave = {};
      whitelist.forEach((storeName) => {
        if (storeData[storeName]) {
          dataToSave = {
            [storeName]: { ...storeData[storeName] },
          };
        }
      });
      LocalStorage.setKey(localDbKey, dataToSave);
    }
    return storeData;
  };
};

export const createPrevData = (store) => {
  if (Object.keys(store.prevData).length === 0) {
    return { ...store };
  }
  return store.prevData;
};

export const createNewStoreOnGetData = (
  payload,
  store,
  syncPrevData = false
) => {
  let { allData, allIds, allRelation } = store;
  const { dataIds, data, relation, paginationData } = payload;

  if (syncPrevData && allData && allIds && allRelation) {
    allData = { ...allData, ...data };
    allIds = [...allIds, ...dataIds];
    allRelation = { ...allRelation, ...relation };
  }

  return {
    ...store,
    dataIds,
    data,
    relation,
    paginationData,
    onceFetched: true,
    ...(syncPrevData && allData && allIds && allRelation
      ? {
          allData,
          allIds,
          allRelation,
        }
      : {}),
  };
};

export const createNewStoreOnCreate = (
  payload,
  store,
  syncPrevData = false,
  appendDataToTop = false
) => {
  const { data, dataIds, relation } = payload;
  let { allData, allIds, allRelation } = store;

  if (syncPrevData && allData && allIds && allRelation) {
    allData = { ...allData, ...data };
    allIds = !appendDataToTop
      ? [...allIds, ...dataIds]
      : [...dataIds, ...allIds];
    allRelation = { ...allRelation, ...relation };
  }

  return {
    ...store,
    data: { ...store.data, ...data },
    dataIds: !appendDataToTop
      ? [...store.dataIds, ...dataIds]
      : [...dataIds, ...store.dataIds],
    relation: { ...store.relation, ...relation },
    ...(syncPrevData && allData && allIds && allRelation
      ? {
          allData,
          allIds,
          allRelation,
        }
      : {}),
  };
};

export const createInitialStoreState = (
  props,
  shouldContainAllFields = false
) => {
  return {
    dataIds: [],
    data: {},
    relation: {},
    onceFetched: false,
    paginationData: {
      hasNextPage: true,
      hasPrevPage: null,
      prevPageNumber: -1,
      currentPage: 0,
      nextPageNumber: -1,
      currentPageSize: 0,
      totalPages: 1,
    },
    ...(shouldContainAllFields
      ? {
          allData: {},
          allIds: [],
          allRelation: {},
        }
      : {}),
    ...props,
  };
};

export const createNewStoreOnUpdate = (
  payload,
  store,
  syncPrevData = false
) => {
  const { data, relation } = payload;
  let { allData, allIds, allRelation } = store;

  if (syncPrevData && allData && allIds && allRelation) {
    allData = { ...allData, ...data };
    allRelation = { ...allRelation, ...relation };
  }

  return {
    ...store,
    data: { ...store.data, ...data },
    relation: { ...store.relation, ...relation },
    ...(syncPrevData && allData && allIds && allRelation
      ? {
          allData,
          allIds,
          allRelation,
        }
      : {}),
  };
};

export const removeNoticeFromDraftMode = (store) => {
  let { data, dataIds, currentNoticeId } = store;

  const newData = { ...data };
  const newDataIds = [...dataIds];

  delete newData[currentNoticeId];
  const indexOfNoticeId = newDataIds.indexOf(currentNoticeId);
  newDataIds.splice(indexOfNoticeId, 1);

  return {
    ...store,
    data: { ...newData },
    dataIds: [...newDataIds],
  };
};

export const getStore = (store, tableName) => {
  if (!tableName) {
    return store;
  }
  const names = tableName.split(".");
  let currStore = store;

  names.forEach((name) => {
    if (!currStore[name]) {
      throw new Error("table name not present in reducer");
    }
    currStore = currStore[name];
  });
  return currStore;
};

export const createNewStoreOnDelete = (
  payload,
  store,
  syncPrevData = false
) => {
  const { dataIds: idsArray } = payload;
  const idToDelete = idsArray[0];
  let { allData, allIds, dataIds, allRelation } = store;
  const newCurrIds = dataIds.filter((id) => id !== idToDelete);
  if (syncPrevData) {
    allIds = allIds.filter((id) => id !== idToDelete);
  }

  return {
    ...store,
    dataIds: newCurrIds,
    ...(syncPrevData && allData && allIds && allRelation
      ? {
          allData,
          allIds,
          allRelation,
        }
      : {}),
  };
};
