import requestData from "../../../../../../../ApiHandler";
import { createAuthUrl } from "helper";

export const fetchAllReactionsApi = ({
  companyId,
  isAdmin,
  pageNumber,
  reactableType,
  reactableId,
  reactionsType,
}) => {
  let query = "";
  if (reactionsType && reactionsType != "total") {
    query = `&reaction_type=${reactionsType}`;
  }

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_reactions_by_reactable?page=${pageNumber}&reactable_id=${reactableId}&reactable_type=${reactableType}${query}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
