import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { createTableDataSelector } from "common/Selectors/MTable";

import usePaginator from "hooks/usePaginator";
import usePagination from "hooks/usePagination";

import ActionController from "../ActionController";

import { fetchComments, resetComments } from "../Common/ActionCreators";

import { tranformMatrixToArray, mapCommentData } from "helper";
import { FETCH_ALL_COMMENTS } from "../Common/ActionTypes/comment";

const getAllComments = createTableDataSelector({
  tableName: "comments",
  blackList: { "Sl No": true },
  persistOriginalOrder: true,
  fetchAllData: true,
});

const useFetchComments = ({ commentableId, commentableType }) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    allPagesFetched,
    errorOccured,
  } = usePagination({
    actionCreator: fetchComments,
    tableName: "comments",
  });

  const dispatch = useDispatch();
  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const { rows: data, rowIdMap } = useSelector(getAllComments);

  const { fetchFirstPage, fetchNextPage } = usePaginator({
    paginatorApi: getNextPage,
    extraParams: { companyId, isAdmin, commentableId, commentableType },
  });

  useEffect(() => {
    dispatch(resetComments());
    fetchFirstPage();
    return () => {
      ActionController.cancelAction(FETCH_ALL_COMMENTS);
    };
  }, []);

  const comments = useMemo(() => {
    const modifiedData = tranformMatrixToArray(data, rowIdMap);
    return mapCommentData(modifiedData).reverse();
  }, [data]);

  return {
    isLoading: loadingNextPage || loadingPrevPage,
    comments,
    fetchNextPage,
    allDataFethced: allPagesFetched,
    hasError: errorOccured,
  };
};

export default useFetchComments;
