import styled from "styled-components";
import { StyledActionArea } from "globalStyles";
import FormGroup from "components/FormGroup";
import { ImageComponent } from "../../../Common/Components/Image";

export const EnhancedActionArea = styled(StyledActionArea)`
  flex-flow: row wrap;
  align-items: center;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`;

export const SearchArea = styled.div`
  flex-basis: 100%;
  align-self: center;

  & > form > button {
    height: 35px;
    margin-bottom: 10px;
    flex-basis: 75%;
  }
`;

export const StyledForm = styled(FormGroup)`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 15px;

  .enquiry_input_box {
    display: initial;
    height: 35px;
    margin-top: 0px;
  }

  .enquiry_field {
    height: 100%;
  }

  .enquiry_field > select {
    padding: 0 10px;
  }

  & button {
    padding: 7px;
  }

  @media (min-width: 768px) {
    flex-flow: row;
  }
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    height: 35px;
  }
`;

export const EmptyScreenImage = styled(ImageComponent)`
  width: 40%;
  height: 40%;
  object-fit: contain;
`;

export const EmptyScreenMessage = styled.span`
  margin: 30px;
  font-size: ${(props) => props.theme.text.title.medium};
`;
