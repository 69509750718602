import React, { useMemo, useCallback } from "react";
import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import { ResignationOptions } from "../../constants";
import { getUserListData } from "common/Api";
import { ListItem } from "components/List";

import { FormContainer, DateRow } from "../../style/create";

const Form = ({ formData, onApply, isLoading, updateField }) => {
  const [startDate, endDate] = useMemo(() => {
    const today = new Date();
    return [today, new Date().setDate(today.getDate() + 90)];
  }, []);

  const onDeselectUsers = useCallback(
    (itemId, item) => {
      const newListData = { ...formData["cc"].value };
      if (newListData[itemId]) {
        delete newListData[itemId];
      }
      updateField("cc", newListData);
    },
    [formData["cc"]]
  );

  const selectedUsersList = useMemo(() => {
    return formData["cc"]?.value && Object.values(formData["cc"].value);
  }, [formData["cc"]]);

  return (
    <FormContainer onSubmit={onApply}>
      <DateRow>
        <InputGroup
          label="Date of Resignation"
          fieldType="datePicker"
          placeholder="dd/mm/yyyy"
          disabled
          value={startDate}
        />

        <InputGroup
          label="Last Working Date"
          fieldType="datePicker"
          placeholder="dd/mm/yyyy"
          disabled
          value={endDate}
        />
      </DateRow>
      <InputGroup
        label="Reason of resignation"
        fieldType="select"
        defaultSelectText="Select Reason"
        options={ResignationOptions}
        {...formData["reason"]}
      />
      <InputGroup
        label="CC"
        fieldType="asyncSearchSelect"
        placeholder="Add collaborators"
        maxSelectionCount={5}
        api={getUserListData}
        {...formData["cc"]}
      />

      {selectedUsersList &&
        selectedUsersList?.map((item) => {
          return (
            <ListItem
              key={item.id}
              data={item}
              onSelect={onDeselectUsers}
              showDeselectButton={true}
            />
          );
        })}

      <InputGroup
        label="Letter"
        fieldType="textArea"
        placeholder="Write something"
        fillParent={true}
        wrapperStyle={{ height: "200px" }}
        {...formData["letter"]}
      />

      <Button loading={isLoading} disabled={isLoading} primary type="submit">
        Apply
      </Button>
    </FormContainer>
  );
};

export default Form;
