import { validateNumber } from "helper";
import useForm from "hooks/useForm";

function useCreditForm() {
  const { formData, values, resetForm, isFormValid } = useForm({
    fields: {
      date: {
        required: true
      },
      notes: {
        required: false
      },
      amount: {
        required: true,
        validator: validateNumber,
        invalidMessage: "Enter a valid amount"
      },
      invoices: {
        required: true
      }
    }
  });

  return { isFormValid, formData, values, resetForm };
}
export default useCreditForm;
