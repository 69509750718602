import {
  FETCH_SEPARATION_DATA,
  SEARCH_SEPARATION_DATA,
  RESET_SEPARATION_DATA,
  SET_SEARCH_DATA,
  APPLY_FOR_RESIGNATION,
} from "./actionTypes";

import { createAsyncActionCreator } from "../../Helper";
import {
  getAllSeparationApi,
  searchSeparationApi,
  applyForResignationApi,
} from "./api";

const searchSeparationGenrator = function* ({
  pageNumber,
  companyId,
  isAdmin,
  startDate,
  endDate,
  status,
  userId,
}) {
  yield {
    action: SEARCH_SEPARATION_DATA,
    sideEffect: searchSeparationApi({
      pageNumber,
      companyId,
      isAdmin,
      startDate,
      endDate,
      status,
      userId,
    }),
  };
};

const applyForResignationGenrator = function* ({
  ccIds,
  reason,
  letter,
  isAdmin,
  companyId,
}) {
  yield {
    action: APPLY_FOR_RESIGNATION,
    sideEffect: applyForResignationApi({
      ccIds,
      reason,
      letter,
      isAdmin,
      companyId,
    }),
  };
};

const getAllSeparationGenrator = function* ({
  pageNumber,
  companyId,
  isAdmin,
}) {
  yield {
    action: FETCH_SEPARATION_DATA,
    sideEffect: getAllSeparationApi({
      pageNumber,
      companyId,
      isAdmin,
    }),
  };
};

export const resetTableData = () => ({
  type: RESET_SEPARATION_DATA,
});

export const setSearchData = ({ startDate, endDate, status, user }) => ({
  type: SET_SEARCH_DATA,
  payload: { startDate, endDate, status, user },
});

export const [getAllSeparation, searchSeparation, applyForResignation] =
  createAsyncActionCreator([
    getAllSeparationGenrator,
    searchSeparationGenrator,
    applyForResignationGenrator,
  ]);
