import InputGroup from "components/InputGroup";

const ConditionalForm = () => {
  return (
    <>
      <InputGroup
        id="reimbursementCreate_reimbursementFrom"
        fieldType="input"
        required
        placeholder="Enter from"
        label="From"
      />
      <InputGroup
        id="reimbursementCreate_reimbursementTo"
        fieldType="input"
        required
        placeholder="Enter to"
        label="To"
      />
    </>
  )
}

export default ConditionalForm;
