import React from "react";
import { withTheme } from "styled-components";

const Icon = ({ color, theme, ...otherProps }) => {
  return (
    <svg
      {...otherProps}
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          d="M15.0547 5.35116C15.0189 5.3452 14.9771 5.3452 14.9413 5.35116C14.1176 5.32132 13.4609 4.64678 13.4609 3.81108C13.4609 2.95747 14.1474 2.271 15.001 2.271C15.8546 2.271 16.5411 2.96344 16.5411 3.81108C16.5351 4.64678 15.8785 5.32132 15.0547 5.35116Z"
          stroke={color || theme.colors.primaryColor}
          strokeWidth="0.895397"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4398 9.69686C15.2576 9.83416 16.1589 9.69089 16.7917 9.26707C17.6334 8.70596 17.6334 7.78668 16.7917 7.22557C16.153 6.80175 15.2397 6.65848 14.4219 6.80174"
          stroke={color || theme.colors.primaryColor}
          strokeWidth="0.895397"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M3.36908 5.35116C3.4049 5.3452 3.44668 5.3452 3.4825 5.35116C4.30627 5.32132 4.96289 4.64678 4.96289 3.81108C4.96289 2.95747 4.27642 2.271 3.42281 2.271C2.5692 2.271 1.88273 2.96344 1.88273 3.81108C1.8887 4.64678 2.54532 5.32132 3.36908 5.35116Z"
          stroke={color || theme.colors.primaryColor}
          strokeWidth="0.895397"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.98356 9.69686C3.16576 9.83416 2.26439 9.69089 1.63164 9.26707C0.789971 8.70596 0.789971 7.78668 1.63164 7.22557C2.27036 6.80175 3.18367 6.65848 4.00146 6.80174"
          stroke={color || theme.colors.primaryColor}
          strokeWidth="0.895397"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M9.30779 5.28637C9.25795 5.27806 9.1998 5.27806 9.14996 5.28637C8.0036 5.24484 7.08984 4.30615 7.08984 3.14319C7.08984 1.9553 8.04514 1 9.23303 1C10.4209 1 11.3762 1.9636 11.3762 3.14319C11.3679 4.30615 10.4541 5.25314 9.30779 5.28637Z"
        stroke={color || theme.colors.primaryColor}
        strokeWidth="1.0799"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.89017 7.90295C5.7189 8.6838 5.7189 9.96307 6.89017 10.7439C8.21928 11.6328 10.3957 11.6328 11.7248 10.7439C12.8961 9.96307 12.8961 8.6838 11.7248 7.90295C10.404 7.02242 8.21928 7.02242 6.89017 7.90295Z"
        stroke={color || theme.colors.primaryColor}
        strokeWidth="1.0799"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TeamsIcon = withTheme(Icon);
