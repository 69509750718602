import styled from "styled-components";
import { InputContainer, GlobalInput } from "../style";

const getFilePickerHeight = (props) => {
  switch (props.size) {
    case "sm":
      return "80px";
    case "md":
      return "120px";
    case "lg":
      return "150px";
    default:
      return "120px";
  }
};

export const FilePickerContainer = styled(InputContainer)`
  height: ${getFilePickerHeight};
`;

export const Attachment = styled.div`
  width: 150px;
  height: 100%;
  margin: 0 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.text.heading.large};
  color: ${(props) => props.theme.colors.filePicker.attachment};
  flex-shrink: 0;
`;

export const AttachmentText = styled.span`
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: 500px;
  color: ${(props) => props.theme.colors.filePicker.text};
`;

export const FileInputContainer = styled(GlobalInput)`
  border: 1px black dotted;
  color: ${(props) => props.theme.colors.filePicker.text};
  font-size: ${(props) => props.theme.text.title.large};
  display: flex;
  justify-content: ${(props) => (props.hasFiles ? "flex-start" : "center")};
  align-items: center;
  cursor: pointer;
  overflow-x: scroll;
  padding: 10px;
`;

export const UploadText = styled.span`
  font-size: ${(props) => props.theme.text.title.medium};
  font-weight: 400;
  margin-left: 10px;
  color: black;
`;
