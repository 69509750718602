import {
  GET_LEDGER_TRANSACTIONS,
  CREATE_LEDGER_TRANSACTIONS,
  CLEAR_LEDGER_TRANSACTIONS,
  UPDATE_LEDGER_TRANSACTIONS,
} from "../actiontypes";

import {
  createInitialStoreState,
  createNewStoreOnGetData,
  createNewStoreOnCreate,
  createNewStoreOnUpdate,
} from "helper";

const initialState = createInitialStoreState({
  totalCount: null,
  amountDetails: "",
  chartData: [],
});

export const ledgersTransactionsReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_LEDGER_TRANSACTIONS}_SUCCESS`:
      return createNewStoreOnGetData(payload, {
        ...store,
        totalCount: payload.meta.totalCount,
        amountDetails: payload.meta.transactionAmountDetails,
        chartData: payload.meta.expenseDataSet,
      });

    case `${CREATE_LEDGER_TRANSACTIONS}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case `${UPDATE_LEDGER_TRANSACTIONS}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case CLEAR_LEDGER_TRANSACTIONS: {
      return initialState;
    }

    default:
      return store;
  }
};
