import React, { useMemo } from "react";
import Tooltip from "components/Tooltip2";
import { getDateDetails } from "helper/date";

import {
  NameTag,
  ProfilePicArea,
  ProfilePicTagImage,
  StyledProfilePic,
  SubText,
} from "./style";

function PofilePicWithTag({ tagImage, userDate, collaboratorId, ...rest }) {
  const [date, month] = useMemo(() => {
    const dateDetails = getDateDetails(userDate);
    return [dateDetails?.todayDate, dateDetails?.month];
  }, [userDate]);
  return (
    <ProfilePicArea data-tooltip={`username_${collaboratorId}`}>
      <StyledProfilePic showName={false} {...rest} />
      <ProfilePicTagImage src={tagImage} />
      <NameTag style={{ margin: "0" }}>{rest?.userName}</NameTag>
      <Tooltip tooltipId={`username_${collaboratorId}`}>
        {rest?.userName}
      </Tooltip>
      <SubText>{`${date} ${month}`}</SubText>
    </ProfilePicArea>
  );
}

export default PofilePicWithTag;
