import styled from "styled-components";
import { StyledInputGroups } from "../../../GlobalStyles";

export const MainContainer = styled.div`
  height: 100%;
  padding: 0 25px 15px 25px;
  display: flex;
  flex-flow: column no-wrap;

  @media (min-width: 768px) {
    padding: 0 50px 20px 50px;
  }
`;

export const FormHeading = styled.h3`
  font-size: ${(props) => props.theme.text.title.large};
  color: ${(props) => props.theme.colors.primaryColor};
  margin-bottom: 24px;
  margin-top: 16px;
  font-weight: 600;
  text-align: left;

  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.text.title.extraLarge};
    margin-bottom: 40px;
    margin-top: 45px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & i {
    color: ${(props) => props.theme.colors.primaryColor};
    font-size: ${(props) => props.theme.text.title.large};
    margin: 45px 0 20px;
    cursor: pointer;
  }
`;

export const TextAreaLengthCont = styled.p`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  font-weight: 400;
  text-align: right;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const NoticeDescriptionInputGroup = styled(StyledInputGroups)`
  height: 300px;
`;
