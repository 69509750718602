import { useCallback, useMemo, useRef, useState } from "react";
import { getSpacedString, removePrevUnderScoreString } from "../Helper";

/*
  @params of fields type
    const fields = {
         fieldName: {
            initialValue: string,
            required?: boolean;
            validator?: (value:String, formValues:Object):boolean => {};
            invalidMessage?: string;
        }
    }
*/

const setInitialValues = (fields) => {
  const res = {};
  if (fields) {
    Object.keys(fields).forEach((fieldName) => {
      const val = fields[fieldName];
      if (val.initialValue) {
        res[fieldName] = val.initialValue;
      }
    });
  }
  return res;
};

const useForm = ({ fields }) => {
  const [values, updateValues] = useState(() => setInitialValues(fields));
  const [errors, updateErrors] = useState({});

  const blurStatusRef = useRef({});
  const errorMessageRef = useRef({});
  const tempDataRef = useRef({});
  tempDataRef.current = values;

  const fieldNames = Object.keys(fields);

  const checkForNullValues = (value) => {
    let isNull = false;
    if (!value || (typeof value === "string" && !value.trim())) {
      isNull = true;
    }
    return isNull;
  };

  const setErrorMessage = ({
    name,
    isNull,
    isInvalid,
    oldErrors,
    customErrorMessage,
  }) => {
    const { invalidMessage } = fields[name];
    if (isNull) {
      const errorString = getSpacedString(removePrevUnderScoreString(name));
      oldErrors.push(`${errorString} is required`);
    }
    if (isInvalid) {
      oldErrors.push(customErrorMessage || invalidMessage);
    }

    errorMessageRef.current = {
      ...errorMessageRef.current,
      [name]: [...oldErrors],
    };
  };

  /**
   * used for Validating a field in a form
   */
  const validateField = useCallback(
    (fieldName, value) => {
      const { validator, required } = fields[fieldName] || {};
      let inValidity = false;
      let isNull = false;
      const errorsMessage = [];

      isNull = checkForNullValues(value);

      if (required) {
        inValidity = isNull;
        setErrorMessage({
          name: fieldName,
          isNull,
          oldErrors: errorsMessage,
        });
      }
      if (typeof validator === "function" && !isNull) {
        const response = validator(value, tempDataRef.current);
        let customErrorMessage = "";
        if (typeof response === "boolean") {
          inValidity = !response;
        } else if (typeof response === "string") {
          inValidity = !!response;
          customErrorMessage = response;
        }
        setErrorMessage({
          name: fieldName,
          isInvalid: inValidity,
          oldErrors: errorsMessage,
          customErrorMessage,
        });
      }

      updateErrors((prev) => ({ ...prev, [fieldName]: inValidity }));
      return inValidity;
    },
    [fields]
  );

  /**
   * this method update the values and updates the validiity of a field
   */
  const onChange = useCallback(
    (e) => {
      const { name, value, files } = e.target;
      updateValues((prev) => ({ ...prev, [name]: files || value }));
      validateField(name, value);
    },
    [validateField]
  );

  const updateBlurStatus = useCallback((name, status) => {
    if (!blurStatusRef.current[name]) {
      blurStatusRef.current = {
        ...blurStatusRef.current,
        [name]: status,
      };
    }
  }, []);

  /**
   * this methods update the validity of a field upon input blur
   */
  const onBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      updateBlurStatus(name, true);
      validateField(name, value);
    },
    [validateField]
  );

  /**
   * this function is used extrnally update any field as well as its blur status
   */
  const updateField = useCallback(
    (name, value, activateBlur = false) => {
      updateValues((prev) => ({ ...prev, [name]: value }));
      validateField(name, value);
      if (activateBlur) {
        updateBlurStatus(name, true);
      }
    },
    [validateField]
  );

  /**
   * this method triggers the validation of all fields in form and update their blur status
   * @returns boolean
   */

  const isFormValid = () => {
    let isInvalid = false;
    // updating the blur status and validity
    fieldNames.forEach((name) => {
      const status = validateField(name, values[name]);
      isInvalid = isInvalid || status;
      updateBlurStatus(name, true);
    });

    return !isInvalid;
  };

  /**
   * this function is used to reset all the values in a form
   */
  const resetForm = useCallback(() => {
    updateValues({});
    updateErrors({});
    blurStatusRef.current = {};
    errorMessageRef.current = {};
  }, []);

  const [fieldSuccess, fieldErrs] = useMemo(() => {
    const err = {};
    const success = {};
    const blurStatus = blurStatusRef.current;

    Object.keys(errors).forEach((fieldName) => {
      err[fieldName] = !blurStatus[fieldName] ? false : errors[fieldName];
      success[fieldName] =
        blurStatus[fieldName] && !errors[fieldName] ? true : false;
    });

    return [success, err];
  }, [errors]);

  const errorMessage = useMemo(() => {
    const result = {};
    const messages = errorMessageRef.current;
    fieldNames.forEach((name) => {
      result[name] =
        messages[name] && Array.isArray(messages[name]) && messages[name][0]
          ? messages[name][0]
          : "";
    });
    return result;
  }, [fields, errors]);

  /**
   *
   * this function is used to generate props for each field in form
   */
  const props = () => {
    const res = {};
    fieldNames.forEach((name) => {
      res[name] = {
        onChange,
        onBlur,
        value: values[name] ? values[name] : "",
        name,
        errorMessage: errorMessage[name],
        success: fieldSuccess[name],
        error: fieldErrs[name],
      };
    });
    return res;
  };

  return {
    formData: props(),
    updateField,
    isFormValid,
    values,
    success: fieldSuccess,
    errors: fieldErrs,
    errorMessage,
    resetForm,
  };
};

export default useForm;
