import { CREATE_ACADEMIC_QUALIFICATION, CREATE_PROFESSIONAL_QUALIFICATION, CREATE_TRAINING_CERTIFICATION_QUALIFICATION, GET_QUALIFICATIONS } from "./actiontypes";

import {
  createInitialStoreState,
  createNewStoreOnGetData,
  createNewStoreOnCreate,
} from "helper";

const initialState = createInitialStoreState({
  currentQualificationType: ""
});

export const qualificationsReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${CREATE_ACADEMIC_QUALIFICATION}_SUCCESS`:
    case `${CREATE_PROFESSIONAL_QUALIFICATION}_SUCCESS`:
    case `${CREATE_TRAINING_CERTIFICATION_QUALIFICATION}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);
    case `${GET_QUALIFICATIONS}_SUCCESS`:
      return createNewStoreOnGetData(payload, {
        ...store,
        currentQualificationType: payload.data[payload.dataIds[0]]?.type
      });

    default:
      return store;
  }
};
