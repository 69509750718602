export const getFormStyle = (
  elemPos,
  window,
  formWidth,
  formHeight,
  maxLeftOffset
) => {
  const newLeft =
    elemPos.left + elemPos.width + formWidth + maxLeftOffset > window.width
      ? Math.max(0, elemPos.left - formWidth - maxLeftOffset)
      : elemPos.left + elemPos.width + maxLeftOffset;
  return {
    top: `${window.height / 2 - formHeight / 2}px`,
    left: `${newLeft}px`,
    position: "absolute",
    height: formHeight,
    width: formWidth,
    overflowY: "auto",
  };
};

const getCalenderDateAndTime = (date, time) => {
  const dateStr = new Date(date).toDateString();
  return `${dateStr} ${time}`;
};

export const createEventFormData = (values, venue) => {
  const {
    calendarEvent_title,
    calendarEvent_calendar,
    calendarEvent_description,
    calendarEvent_fullDay,
    calendarEvent_startDate,
    calendarEvent_startTime,
    calendarEvent_endDate,
    calendarEvent_endTime,
    calendarEvent_meetingLink,
  } = values;

  let name = "";
  let latitude = "";
  let longitude = "";

  if (venue) {
    name = venue.name;
    latitude = venue.name?.latitude;
    longitude = venue.name?.longitude;
  }

  const isFullDay = calendarEvent_fullDay;

  const endAt = `${
    isFullDay ? calendarEvent_startDate : calendarEvent_endDate
  }`;

  const startTime = `${isFullDay ? "00:00:00" : calendarEvent_startTime}`;
  const endTime = `${isFullDay ? "23:59:59" : calendarEvent_endTime}`;

  const startDate = getCalenderDateAndTime(calendarEvent_startDate, startTime);
  const endDate = getCalenderDateAndTime(endAt, endTime);
  return {
    title: calendarEvent_title,
    description: calendarEvent_description,
    startAt: startDate,
    endAt: endDate,
    calendarId: calendarEvent_calendar,
    meetingLink: calendarEvent_meetingLink,
    venue: name,
    latitude,
    longitude,
  };
};
