import React from "react";
import Cross_Img from "assets/common/cross.svg";

import { Header } from "./style";

function PostHeader({ title, onClose }) {
  return (
    <Header>
      <span className="title">{title}</span>
      <img role="button" onClick={onClose} src={Cross_Img} alt="cross" />
    </Header>
  );
}

export default PostHeader;
