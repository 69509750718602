import React from "react";
import Form from "./Form";
import { MainContainer } from "../../style/create";

const UserCreateView = () => {
  return (
    <MainContainer>
      <Form />
    </MainContainer>
  );
};

export default UserCreateView;
