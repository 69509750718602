export const dimensions = {
  navbar: {
    height: "82px",
  },
  checkBox: {
    width: "20px",
    height: "20px",
  },
  drawer: {
    width: "273px",
    tab: {
      height: "40px",
    },
    link: {
      height: "30px",
    },
  },
  chip: {
    width: "80px",
    height: "40px",
  },
  radio: {
    width: "20px",
    height: "20px",
  },

  datePicker: {
    weekHeight: "40px",
    padding: "10px",
    actionHeight: "60px",
  },

  calendar: {
    column: {
      width: "100px",
    },
    row: {
      height: "100px",
    },
  },
};
