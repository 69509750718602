import React, { useCallback, useState } from "react";
import { useLocation } from "react-router";
import ActionArea from "./ActionArea";
import TableData from "./TableData";

import InfoView from "components/InfoView";
import NoLeaves from "assets/employee/noLeaves.png";
import { RectangleSkeleton } from "components/Skeleton";
import { ViewContainer } from "globalStyles/view";
import PageSlider from "components/PageSlider";
import { usePageSlider } from "hooks/usePageSlider";
import AttendanceDetailsView from "modules/Attendence/Views/Details";

const NotifiedWorkReportsView = () => {
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const { onCellClick, data, isSliderActive, toggleSlider } = usePageSlider();

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  return (
    <>
      <ActionArea
        onLoading={onDbDataChange}
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
      />
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        onEmptyData={onEmptyData}
        onCellClick={onCellClick}
      />

      <PageSlider onClose={toggleSlider} active={isSliderActive}>
        <AttendanceDetailsView
          rowId={data}
          isNotifiedWorkReportAttendance={true}
        />
      </PageSlider>
      {!isLoading && isEmpty && (
        <InfoView
          image={NoLeaves}
          heading={`No notified reports to show here`}
        />
      )}
      {isLoading && (
        <ViewContainer>
          <RectangleSkeleton times={4} />
        </ViewContainer>
      )}
    </>
  );
};

export default NotifiedWorkReportsView;
