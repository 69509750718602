import React from "react";

import Chat_Img from "../../../assets/Chat.svg";
import { Container, Count, SubText, ImageBox, Image } from "./style";

const NotificationCard = ({ count }) => {
  return (
    <Container>
      <ImageBox>
        <Image src={Chat_Img} />
      </ImageBox>
      <Count>{count}</Count>
      <SubText>Total message pending..</SubText>
    </Container>
  );
};
export default NotificationCard;
