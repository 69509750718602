import React from "react";
import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";

import { EmploymentStatusOptions, EmploymentTypeOptions } from "../options";

import { ButtonGroup, StyledForm } from "../../../style/create";

const EmployementForm = ({
  onSubmit,
  formData,
  isFormValid,
  onBack,
  isEditMode,
  loading,
  disabled,
}) => {
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (isFormValid() && typeof onSubmit === "function") {
      onSubmit();
    }
  };

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <InputGroup
        inputMode="number"
        placeholder="Enter prior experience"
        label="Prior Experience (Years)"
        {...formData["priorExperience"]}
      />

      <InputGroup
        fieldType="datePicker"
        placeholder="Enter probation end date dd/mm/yy"
        label="Probation End Date"
        {...formData["probationEndDate"]}
      />

      <InputGroup
        fieldType="select"
        options={EmploymentStatusOptions}
        defaultSelectText="Select employment status"
        label="Employment Status"
        {...formData["employmentStatus"]}
      />

      <InputGroup
        fieldType="select"
        options={EmploymentTypeOptions}
        defaultSelectText="Select employment type"
        label="Employment Type"
        {...formData["employmentType"]}
      />

      <InputGroup
        inputMode="number"
        placeholder="Enter notice period"
        label="Notice Period (Days)"
        {...formData["noticePeriod"]}
      />

      <InputGroup
        fieldType="datePicker"
        placeholder="Enter expected confirmation date dd/mm/yy"
        label="Expected Confirmation Date"
        {...formData["confirmationDate"]}
      />

      <InputGroup
        placeholder="Enter external resource"
        label="External Resource"
        {...formData["externalResource"]}
      />

      <ButtonGroup>
        <Button disabled={disabled} onClick={onBack} outline>
          Back
        </Button>
        <Button loading={loading} disabled={disabled} type="submit" primary>
          {isEditMode ? "Update" : "Create"}
        </Button>
      </ButtonGroup>
    </StyledForm>
  );
};

export default EmployementForm;
