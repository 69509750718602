import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";

import InsertInDom from "../../InsertInDom";
import ActivityIndicator from "../../ActivityIndicator";
import Icon from "../../Icon";

import useInput from "../useInput";

import usePagination from "../../../../Hooks/usePagination";

import { InputContainer, InputIcon } from "../style";
import {
  Overlay,
  OptionBox,
  SelectOption,
  SelectLoader,
  ScrollLoader,
  AdvanceSelectInput,
  DropIconContainer,
} from "./style";

const AdvanceSelect = ({
  defaultSelectText = "",
  isSearchable = false,
  options = [],
  optionStyle = {},
  optionClass = "",
  fixedWidth = 0,
  size = "md",
  success = "",
  secondary = false,
  error = "",
  fieldClass = "",
  name,
  onBlur,
  pageConfig = { api: "", extraParams: {}, tableName: "" },
  ...otherprops
}) => {
  const { loadingNextPage, getNextPage, currentPageNumber, totalPages } =
    usePagination({
      actionCreator: pageConfig.api,
      tableName: pageConfig.tableName,
    });

  const fetchFirstPage = useCallback(() => {
    getNextPage({
      isFirstPage: true,
      extraParams: pageConfig.extraParams,
    });
  }, [getNextPage, pageConfig.extraParams]);

  const fetchNextPage = useCallback(() => {
    getNextPage({
      extraParams: pageConfig.extraParams,
    });
  }, [getNextPage, pageConfig.extraParams]);

  const btnRef = useRef();
  const [isActive, toggleActive] = useState(false);
  const [position, updatePosition] = useState({ x: 0, y: 0, width: 0 });
  const { icon, restInputProps } = useInput({
    ...otherprops,
    success,
    error,
  });
  const { onChange, ...rest } = restInputProps;

  const tempDataRef = useRef(null);
  tempDataRef.current = restInputProps.value;

  useEffect(() => {
    if (isSearchable) {
      fetchFirstPage();
    }
  }, []);

  const toggleSelect = useCallback(
    ({ target }) => {
      toggleActive((val) => {
        if (val && typeof onBlur === "function") {
          onBlur({ target: { name, value: tempDataRef.current } });
        }
        return !val;
      });
      const { x, y } = target.getBoundingClientRect();
      const { clientHeight, clientWidth } = target;
      updatePosition({
        x: x,
        y: y + clientHeight + 10,
        width: fixedWidth || (clientWidth < 200 ? 200 : clientWidth),
      });
      btnRef.current.focus();
    },
    [fixedWidth, onBlur, name]
  );

  const onOptionSelect = useCallback(
    (e) => {
      const { value } = e.target.dataset;
      if (typeof onChange !== "function") {
        return;
      }
      onChange({
        ...e,
        target: {
          value,
          name,
        },
      });

      toggleActive((val) => !val);
      btnRef.current.focus();
    },
    [onChange, rest, name]
  );

  const onOptionBodyScroll = useCallback(
    (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      const isEnd = scrollHeight - Math.abs(scrollTop) === clientHeight;
      if (isEnd) {
        if (totalPages === currentPageNumber) {
          return;
        }
        if (currentPageNumber === 0) {
          fetchFirstPage(0);
        }
        fetchNextPage();
      }
    },
    [
      currentPageNumber,
      totalPages,
      fetchFirstPage,
      fetchNextPage,
      pageConfig.extraParams,
    ]
  );

  const currentValue = useMemo(() => {
    const item = options.find((item) => item.value === restInputProps.value);

    return item ? item.name : null;
  }, [options, restInputProps.value]);

  success = success ? "true" : undefined;
  error = error ? "true" : undefined;

  return (
    <InputContainer size={size} className={fieldClass}>
      <AdvanceSelectInput
        as="button"
        {...rest}
        ref={btnRef}
        success={success}
        error={error}
        secondary={secondary}
        type="button"
        onClick={toggleSelect}
      >
        {currentValue || defaultSelectText}
      </AdvanceSelectInput>

      {isActive && (
        <InsertInDom domId="" isModal={true}>
          <Overlay onClick={toggleSelect} />
          <OptionBox
            onScroll={isSearchable ? onOptionBodyScroll : null}
            style={{
              top: `${position.y}px`,
              left: `${position.x}px`,
              width: `${position.width}px`,
            }}
          >
            <SelectOption
              type="button"
              style={optionStyle}
              className={optionClass}
              data-value={""}
              onClick={onOptionSelect}
            >
              {defaultSelectText}
            </SelectOption>
            {options.map((item) => {
              return (
                <SelectOption
                  style={optionStyle}
                  type="button"
                  data-value={item.value}
                  onClick={onOptionSelect}
                  className={optionClass}
                  key={item.value}
                >
                  {item.name}
                </SelectOption>
              );
            })}

            {isSearchable && totalPages !== currentPageNumber && (
              <ScrollLoader>
                <ActivityIndicator size="s" color="#007bff" />
              </ScrollLoader>
            )}
          </OptionBox>
        </InsertInDom>
      )}

      {loadingNextPage && !isActive && (
        <SelectLoader>
          <ActivityIndicator size="s" color="#007bff" />
        </SelectLoader>
      )}

      <DropIconContainer moveLeft={!!icon}>
        <Icon name="fas fa-chevron-down" />
      </DropIconContainer>

      {!!icon && <InputIcon success={success} error={error} name={icon} />}
    </InputContainer>
  );
};

export default AdvanceSelect;
