import {
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  GET_ALL_DEPARTMENTS,
} from "./actiontypes";

import {
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createInitialStoreState,
} from "../../Helper";

const initialState = createInitialStoreState({}, true);

export const departmentReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_ALL_DEPARTMENTS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    case `${CREATE_DEPARTMENT}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true);
    }
    default:
      return store;
  }
};
