import { extractDateInYYYYMMDD } from "../../Helper";

export const generateFormData = (values) => {
  const {
    holidayYearCreate_name,
    holidayYearCreate_startDate,
    holidayYearCreate_endDate,
    holidayYearCreate_ongoingYear,
  } = values;
  return {
    name: holidayYearCreate_name,
    startDate: extractDateInYYYYMMDD(holidayYearCreate_startDate),
    endDate: extractDateInYYYYMMDD(holidayYearCreate_endDate),
    ongoingYear: holidayYearCreate_ongoingYear?.ongoing_year || false,
  };
};
