import styled from "styled-components";
import { GlobalInput } from "../style";

export const AdvanceSelectInput = styled(GlobalInput)`
  text-align: left;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primaryColor};
  font-size: ${(props) => props.theme.text.para.large};
  font-weight: 400;
  border: 1px ${(props) => props.theme.colors.primaryColor} solid;
  color: ${(props) => props.theme.colors.primaryColor};
  background-color: white;
`;

export const OptionBox = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  max-height: 300px;
  box-shadow: ${(props) => props.theme.shadow.medium};
  padding: 10px 0;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.lightPrimaryColor};
  overflow-y: auto;
`;

export const SelectOption = styled.button`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 0 10px;
  font-size: ${(props) => props.theme.text.para.medium};
  border: none;
  background-color: transparent;
  color: ${(props) => props.theme.colors.primaryColor};
  font-weight: 400;
  &:hover {
    background-color: ${(props) => props.theme.colors.primaryColor};
    color: white;
    cursor: pointer;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
`;

export const ScrollLoader = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectLoader = styled.div`
  position: absolute;
  top: 30%;
  right: 5px;
`;

export const DropIconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: bold;
  top: 35%;
  right: ${(props) => (props.moveLeft ? "40px" : "10px")};
  color: ${(props) => props.theme.colors.primaryColor};
`;
