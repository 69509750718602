import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  text-align: center;
  z-index: ${(props) => props.theme.zIndex.noInternet};
  background-color: ${(props) => props.theme.colors.alert.error};
`;
export const Text = styled.span`
  color: white;
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: 500;
`;
