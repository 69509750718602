import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import SeparationDisplayView from "../../../Modules/Separation/Views/Display";

import { createRouteObject } from "../../../Helper";

const { separation } = RoutePaths.authorized;

const pages = createRouteObject(
  separation,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [SeparationDisplayView]
);

const SeparationPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact
        path={item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default SeparationPages;
