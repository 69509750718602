import React, { useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BreaksCenter from "common/Components/BreaksCenter";

import {
  getActiveBreaks,
  startBreakTimer,
  updateBreakTimer,
} from "../../actionCreator";
import { createTableDataSelector } from "common/Selectors/MTable";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { getCurrentActiveSnap, tranformToBreaksList } from "../../utils";
import useAlert from "hooks/useAlert";
import { MockData } from "../../../Notification/constants";
import { isUserLoggedIn } from "../../selector";

const Break = ({
  elemIdOrElem,
  companyId,
  isAdmin,
  onClose,
  onceFetched,
  data,
  cellNameToColumnMap,
  getActiveBreaks,
  startBreakTimer,
  updateBreakTimer,
  ongoingBreak,
  todaysBreakCount,
  isUserLoggedIn,
}) => {
  const { showAlert } = useAlert();
  useEffect(async () => {
    try {
      if (!companyId || isAdmin) return;
      if (!onceFetched) {
        await getActiveBreaks({ companyId, isAdmin });
      }
    } catch (error) {
      showAlert({ message: "Some error occured", type: "error" });
    }
  }, [elemIdOrElem]);

  const onMessageClick = useCallback(
    async (data) => {
      try {
        const { snapId } = data;
        if (ongoingBreak && ongoingBreak.snapId === snapId) {
          await updateBreakTimer({
            companyId,
            collaboratorSnapId: ongoingBreak.collaboratorSnapId,
            isAdmin,
          });
        } else if (!ongoingBreak) {
          await startBreakTimer({ companyId, snapId, isAdmin });
        }
      } catch (error) {
        showAlert({ message: "Some error occured", type: "error" });
      }
    },
    [companyId, isAdmin, ongoingBreak]
  );

  const breaksList = useMemo(() => {
    return tranformToBreaksList(data, cellNameToColumnMap);
  }, [data, cellNameToColumnMap]);

  const currentActiveSnap = useMemo(() => {
    return getCurrentActiveSnap(breaksList, ongoingBreak);
  }, [breaksList, ongoingBreak]);

  return (
    <BreaksCenter
      currentActiveSnap={currentActiveSnap}
      elemIdOrElem={elemIdOrElem}
      dataList={!onceFetched ? MockData : breaksList}
      initialLoading={!onceFetched}
      onClose={onClose}
      onClick={onMessageClick}
      ongoingBreak={ongoingBreak}
      todaysBreakCount={todaysBreakCount}
      isUserLoggedIn={isUserLoggedIn}
    />
  );
};

Break.defaultProps = {
  onClose: () => {},
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "breaks.snaps",
    reverse: false,
  });

  return (store, props) => {
    const companyId = getCurrentUserCompany(store, props);
    const isAdmin = isCurrentUserIsExecutive(store, props);
    const { rows, cellNameToColumnMap } = getUserRows(store, props);
    return {
      onceFetched: store.breaks.snaps.onceFetched,
      companyId,
      isAdmin,
      data: rows,
      cellNameToColumnMap,
      ongoingBreak: store.breaks.collaboratorSnaps.ongoingBreak,
      todaysBreakCount: store.breaks.collaboratorSnaps.todaysBreakCount,
      isUserLoggedIn: isUserLoggedIn(store, props),
    };
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getActiveBreaks,
      startBreakTimer,
      updateBreakTimer,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Break);
