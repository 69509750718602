import {
  GET_ACTIVE_BREAKS,
  GET_CURRENT_COLLABORATOR_BREAKS,
  START_BREAK_TIMER,
  UPDATE_BREAK_TIMER,
} from "../actiontypes";

import {
  createInitialStoreState,
  createNewStoreOnGetData,
  createNewStoreOnCreate,
  createNewStoreOnUpdate,
} from "helper";

const initialState = createInitialStoreState({
  ongoingBreak: null,
  todaysBreakCount: 0,
});

export const collaboratorSnapsReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${START_BREAK_TIMER}_SUCCESS`: {
      const collaboratorSnapId = payload.dataIds[0] || null;
      const snapId = collaboratorSnapId
        ? payload.data[collaboratorSnapId]?.relationships?.snap?.data?.id
        : null;
      const startTime = collaboratorSnapId
        ? payload.data[collaboratorSnapId]?.attributes?.startTime
        : null;

      const snapData =
        collaboratorSnapId && snapId && startTime
          ? { collaboratorSnapId, snapId, startTime }
          : null;

      const updatedStore = createNewStoreOnCreate(payload, store, true, true);
      return {
        ...updatedStore,
        ongoingBreak: snapData,
        todaysBreakCount: updatedStore.todaysBreakCount + 1,
      };
    }

    case `${GET_ACTIVE_BREAKS}_SUCCESS`: {
      const todaysBreakCount = payload.meta?.todaysCount || 0;
      const ongoingSnap = payload.meta?.ongoingSnap || null;
      const collaboratorSnapId = ongoingSnap ? ongoingSnap.id : null;
      const snapId = ongoingSnap ? ongoingSnap.snap?.id : null;
      const startTime = ongoingSnap ? ongoingSnap.start_time : null;

      const snapData =
        collaboratorSnapId && snapId && startTime
          ? { collaboratorSnapId, snapId, startTime }
          : null;

      return {
        ...store,
        ongoingBreak: snapData,
        todaysBreakCount,
      };
    }

    case `${UPDATE_BREAK_TIMER}_SUCCESS`:
      const updatedStore = createNewStoreOnUpdate(payload, store, false);
      return {
        ...updatedStore,
        ongoingBreak: null,
      };
    case `${GET_CURRENT_COLLABORATOR_BREAKS}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    default:
      return store;
  }
};
