import React, { useMemo } from "react";
import { IMAGE_URL } from "../../../../../../config.local";
import SectionListContent from "./SectionListContent";
import down_icon from "assets/downArrow.svg";

import { MorePic } from "../style";

function SectionListContainer({ data, showMore, onMoreClick }) {
  const list = useMemo(() => {
    const result = data.map((item) => {
      const { url } =
        item?.collaborator?.avatarUrl && item.collaborator.avatarUrl !== "NA"
          ? item.collaborator.avatarUrl
          : {};
      const imageUrl =
        item?.collaborator?.avatarUrl && item.collaborator.avatarUrl !== "NA"
          ? `${IMAGE_URL}${url}`
          : null;
      const name = item?.collaborator?.name;
      const id = item?.collaborator?.id;
      const reactionType = item?.reactionType;
      return (
        <SectionListContent
          image={imageUrl}
          name={name}
          id={id}
          reactionType={reactionType}
        />
      );
    });

    if (showMore) {
      result.push(
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MorePic key={"more_btn_pic"} onClick={onMoreClick}>
            more
            <img
              src={down_icon}
              alt="down arrow"
              style={{
                width: "10px",
                marginTop: "2px",
              }}
            />
          </MorePic>
        </div>
      );
    }
    return result;
  }, [data, showMore]);

  return list;
}

export default SectionListContainer;
