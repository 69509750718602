import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const createPersonalInfoApi = ({ companyId, isAdmin, formData, }) => {
  let personal_information = { current_step: formData.current_step }

  switch (formData.current_step) {
    case 'details':
      personal_information = {
        date_of_birth: formData.date_of_birth,
        blood_group: formData.blood_group,
        marital_status: formData.marital_status,
        personal_email: formData.personal_email,
        nationality: formData.nationality,
        religion: formData.religion,
        about_me: formData.about_me,
        ...personal_information
      }
      break;
    default: personal_information = { ...personal_information }
  }

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/personal_informations`,
  });
  return requestData(url, {
    method: "POST",
    body: {
      personal_information: personal_information
    },
    isFormData: true,
  });
};

export const updatePersonalInfoApi = ({ companyId, isAdmin, formData }) => {
  let personal_information = { current_step: formData.current_step }

  switch (formData.current_step) {
    case 'documents':
      personal_information = {
        aadhar_front: formData.aadhar_front,
        aadhar_back: formData.aadhar_back,
        voter_id_front: formData.voter_id_front,
        voter_id_back: formData.voter_id_back,
        pan_card_photo: formData.pan_card_photo,
        passport_photo: formData.passport_photo,
        passport_size_photo: formData.passport_size_photo,
        ...personal_information
      }
      break;
    case 'address': {
      personal_information = {
        permanent_address: formData.permanent_address,
        permanent_address_proof_type: formData.permanent_address_proof_type,
        present_address: formData.present_address,
        present_address_proof_type: formData.present_address_proof_type,
        ...personal_information
      }
      if (formData.permanent_address_proof_document) {
        personal_information["permanent_address_proof_document"] = formData.permanent_address_proof_document
      }
      if (formData.present_address_proof_document) {
        personal_information["present_address_proof_document"] = formData.present_address_proof_document
      }
    }
      break;
    case 'health_details':
      personal_information = {
        current_health_condition: formData.current_health_condition,
        is_chronic: formData.is_chronic,
        ...personal_information
      }
      break;
    case 'details':
      personal_information = {
        date_of_birth: formData.date_of_birth,
        blood_group: formData.blood_group,
        marital_status: formData.marital_status,
        personal_email: formData.personal_email,
        nationality: formData.nationality,
        religion: formData.religion,
        about_me: formData.about_me,
        ...personal_information
      }
      break;
    default: personal_information = { ...personal_information }
  }

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/update_personal_information`,
  });
  return requestData(url, {
    method: "PUT",
    body: {
      personal_information: personal_information
    },
    isFormData: true,
  });
};

export const getPersonalInfoApi = ({ companyId, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/personal_informations`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getPersonalInfoByCollaboratorApi = ({ companyId, isAdmin, userId }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_personal_information?collaborator_id=${userId}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
