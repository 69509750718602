import { getCurrentDataAttributes } from "helper/selectors";
import { createSelector } from "reselect";

const storeLedgerData = (store) => store.ledger.transactions;

export const ledgerTransactionsSelector = createSelector(
  storeLedgerData,
  (ledgerData) => {
    const ids = ledgerData.dataIds;
    return ids.map((id) => {
      const itemData = ledgerData.data[id];
      return getCurrentDataAttributes(
        itemData,
        ledgerData.relation,
        ledgerData.data
      );
    });
  }
);
