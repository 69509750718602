import {
  CREATE_TEAM,
  CREATE_TEAM_MEMBER,
  GET_TEAMS,
  GET_ACTIVE_USERS,
  GET_SEARCHED_USERS,
  SET_CURRENT_TEAM_ID,
  RESET_MEMBERS_DATA,
  GET_TEAM_MEMBERS,
  UPDATE_TEAM,
} from "./actiontypes";

import {
  createTeamApi,
  createTeamMemberApi,
  getTeamsApi,
  getTeamMembersApi,
  updateTeamApi,
} from "./api";
import { getUserListData } from "common/Api";

import { createAsyncActionCreator } from "helper";
import { getAllActiveUsersApi } from "../User/api";

const createTeamGenerator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_TEAM,
    sideEffect: createTeamApi({ companyId, isAdmin, formData }),
  };
};

const updateTeamGenerator = function* ({
  companyId,
  isAdmin,
  formData,
  teamId,
}) {
  yield {
    action: UPDATE_TEAM,
    sideEffect: updateTeamApi({ companyId, isAdmin, formData, teamId }),
  };
};

const createTeamMemberGenerator = function* ({
  companyId,
  isAdmin,
  teamId,
  formData,
}) {
  yield {
    action: CREATE_TEAM_MEMBER,
    sideEffect: createTeamMemberApi({
      companyId,
      isAdmin,
      teamId,
      formData,
    }),
  };
};

const getTeamsGenerator = function* ({ companyId, isAdmin, pageNumber }) {
  yield {
    action: GET_TEAMS,
    sideEffect: getTeamsApi({ companyId, isAdmin, pageNumber }),
  };
};

const getTeamMembersGenerator = function* ({
  companyId,
  isAdmin,
  pageNumber,
  teamId,
}) {
  yield {
    action: GET_TEAM_MEMBERS,
    sideEffect: getTeamMembersApi({ companyId, isAdmin, pageNumber, teamId }),
  };
};

export const getUserListGenerator = function* ({
  companyId,
  isAdmin,
  searchText,
}) {
  yield {
    action: GET_SEARCHED_USERS,
    sideEffect: getUserListData({ companyId, isAdmin, searchText }),
  };
};

const getAllActiveUsersGenerator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: GET_ACTIVE_USERS,
    sideEffect: getAllActiveUsersApi({ companyId, isAdmin, pageNumber }),
  };
};

export const setCurrentTeamId = ({ teamId }) => ({
  type: SET_CURRENT_TEAM_ID,
  payload: { teamId },
});

export const resetMembersData = () => ({
  type: RESET_MEMBERS_DATA,
});

export const [
  createTeam,
  createTeamMember,
  getTeams,
  getUserList,
  getActiveUsers,
  getTeamMembers,
  updateTeam,
] = createAsyncActionCreator([
  createTeamGenerator,
  createTeamMemberGenerator,
  getTeamsGenerator,
  getUserListGenerator,
  getAllActiveUsersGenerator,
  getTeamMembersGenerator,
  updateTeamGenerator,
]);
