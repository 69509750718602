import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const getFollowSubordinatesApi = ({
  companyId,
  pageNumber,
  isAdmin,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_follows?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getSpecificCollaboratorAttendanceApi = ({
  pageNumber,
  companyId,
  collaboratorId,
  isAdmin,
  startDate,
  endDate,
  status,
  workOrigin,
}) => {
  const fromDateParams =
    startDate && startDate?.trim() ? `&from_date=${startDate}` : "";
  const toDateParams = endDate && endDate?.trim() ? `&to_date=${endDate}` : "";
  const statusParams = status && status?.trim() ? `&status=${status}` : "";
  const workOriginParams =
    workOrigin && workOrigin?.trim() ? `&work_origin=${workOrigin}` : "";

  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/search_working_hour_logs_by_collaborator?page=${pageNumber}&collaborator_id=${collaboratorId}${fromDateParams}${toDateParams}${statusParams}${workOriginParams}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const getAllCollaboratorAttendanceApi = ({
  pageNumber,
  companyId,
  isAdmin,
  collaboratorId,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_working_hour_logs_by_collaborator?page=${pageNumber}&collaborator_id=${collaboratorId}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const updateSubordinateAttendanceStatusApi = ({
  companyId,
  attendanceId,
  subordinateId,
  status,
}) => {
  const url = `/employee/companies/${companyId}/update_working_hour_log_status/${attendanceId}?collaborator_id=${subordinateId}`;
  return requestData(url, {
    method: "PUT",
    body: {
      working_hour_log: {
        status: status,
      },
    },
    isFormData: true,
  });
};
