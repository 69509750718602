import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import NoticeCreateView from "../../../Modules/Notice/Views/Create";
import NoticeDisplayView from "../../../Modules/Notice/Views/Display";

import { createRouteObject } from "../../../Helper";

const { notice } = RoutePaths.authorized;

const pages = createRouteObject(
  notice,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [NoticeCreateView, NoticeDisplayView]
);

const NoticePages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact={item.slugPath ? false : true}
        path={item.slugPath || item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default NoticePages;
