import React, { useCallback, useState, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup";
import Button from "components/Button";
import Icon from "components/Icon";
import useAlert from "hooks/useAlert";

import { createEnquiry } from "../../actionCreator";
import { getCurrentUserCompany, isCurrentUserIsExecutive } from "common/Selectors/auth";

import { enquiryTypeOptions } from "./options";
import { getUserListData } from "common/Api";
import { ListItem } from "components/List";
import AsyncSearchSelect from "components/Input/AsyncSearchSelect";

import { StyledFormGroup } from "../../../../GlobalStyles";
import { FormHeading, Header, Label, SelectGroup } from "../../style/create";
import { generateFormData } from "../../utils";

const Form = ({ setIsFormVisible, createEnquiry, isAdmin, companyId }) => {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();
  const [selectedUsers, updateSelectedUsers] = useState({});
  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const data = generateFormData(values);
        const selectedUsersList = Object.keys(selectedUsers);
        const formData = { ...data, cc_ids: selectedUsersList }

        await createEnquiry({ companyId, isAdmin, formData });
        showAlert({ message: `Enquiry Created` });
        resetForm();
        setIsFormVisible(false);
      } catch (err) {
        showAlert({ message: "Enquiry Creation Failed", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [companyId, selectedUsers]
  );

  const onUserSelected = useCallback((e) => {
    const { value } = e.target;
    updateSelectedUsers(value);
  }, []);

  const onDeselectUsers = useCallback(
    (itemId, item) => {
      const newListData = { ...selectedUsers };
      if (newListData[itemId]) {
        delete newListData[itemId];
      }
      updateSelectedUsers(newListData);
    },
    [selectedUsers]
  );

  const selectedUsersList = useMemo(() => {
    return selectedUsers && Object.values(selectedUsers);
  }, [selectedUsers]);

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Make an enquiry</FormHeading>
        <Icon name="fas fa-times" onClick={updateFormVisibilty} />
      </Header>

      <InputGroup
        id="enquiryCreate_enquiryEnquiryType"
        fieldType="select"
        required
        options={enquiryTypeOptions}
        defaultSelectText="Select Type"
        label="Enquiry type"
      />

      <InputGroup
        id="enquiryCreate_enquirySubject"
        required
        placeholder="Enter subject"
        label="Subject"
        invalidMessage="Enter a valid subject"
      />

      <InputGroup
        id="enquiryCreate_enquiryQuery"
        fieldType="textArea"
        required
        placeholder="Enter query"
        label="Query"
        size="lg"
      />

      <SelectGroup>
        <Label>
          CC
        </Label>
        <AsyncSearchSelect
          value={selectedUsers}
          onChange={onUserSelected}
          placeholder="Add collaborators"
          api={getUserListData}
          maxSelectionCount={5}
        />
      </SelectGroup>

      {selectedUsersList?.map((item) => {
        return (
          <ListItem
            key={item.id}
            data={item}
            onSelect={onDeselectUsers}
            selected={selectedUsers && selectedUsers[item.id]}
            showDeselectButton={true}
          />
        );
      })}

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { companyId, isAdmin };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createEnquiry,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
