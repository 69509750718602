import { getAccessType, generateAccessType } from "../../Helper";

/**
 * Order is set in Inheritance order
 */

const employeeSection = generateAccessType([
  "profile_section",
  "access_assets_section",
  "calendar",
  "reimbursement_section",
  "notice_section",
  "leads_section",
  "holiday_section",
  "follow_subordinate_section",
  "attendence",
  "separation",
  "salarySlip",
  "helpdesk_section",
  "leave",
  "teams_section",
  "ledger_section",
]);
const adminSection = {
  ...employeeSection,
  ...generateAccessType([
    "abc",
    "company",
    "user_section",
    "notice_section",
    "holidayYear_section",
    "companyBranch_section",
    "breaks_section",
    "leaves_section",
    "countdown_section",
    "workshift_section",
  ]),
  leave: false,
  attendance: false,
  profile_section: false,
  follow_subordinate_section: false,
  leads_section: false,
};
const superAdminSection = { ...adminSection };

export const sectionAccessType = {
  employee: employeeSection,
  admin: adminSection,
  super_admin: superAdminSection,
  getSetionAccessType: getAccessType,
};
