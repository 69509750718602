export const generateInitialData = (
  data,
  cellNameToColumnMap,
  outputMapping
) => {
  const result = {};
  outputMapping.forEach(({ coulmnName, label }) => {
    result[label] = data[cellNameToColumnMap[coulmnName]];
  });
  return result;
};

export const getStatusType = (type) => {
  if (typeof type !== "string") {
    return "";
  }

  const val = type.toLowerCase();
  switch (val) {
    case "ongoing":
    case "pending":
    case "in progress":
      return "pending";

    case "approved":
    case "withdrawn":
    case "on hold":
      return "approved";

    case "rejected":
    case "submit not ok":
    case "closed":
    case "inactive":
      return "error";

    case "accepted":
    case "finished":
    case "settled":
    case "reimbursed":
    case "submit ok":
    case "responded":
    case "active":
      return "success";

    default:
      return "";
  }
};

export const calculateMaxLength = (cellNameToColumnMap, data) => {
  let maxLength = 0;
  let statusColumnIndex = [cellNameToColumnMap["status"]];
  data.forEach((key) => {
    if (key[statusColumnIndex]?.length > maxLength)
      maxLength = key[statusColumnIndex]?.length;
  });
  return maxLength;
};

export const getOptionValueByOptionName = (Options, key) => {
  return Options.filter((option) => option.name == key)[0]?.value;
};

export const modifyDataForSingleDocumentField = (data, cellNameToColumnMap) => {
  // Trims "Url" from the heading and save document data object in an array

  let documentIndex = cellNameToColumnMap.degreeDocumentUrl;
  data[0][documentIndex] = "degree Document";

  data.map((arr, index) => {
    if (index > 0 && !Array.isArray(arr[documentIndex])) {
      arr[documentIndex] = [arr[documentIndex]];
    }
  });
  return data;
};
