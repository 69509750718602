import React from "react";
import Form from "./Form";

import { AnimatedViewContainer } from "../../styles/create";

const DepartmentCreateView = () => {
  return (
    <AnimatedViewContainer>
      <Form />
    </AnimatedViewContainer>
  );
};
export default DepartmentCreateView;
