import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import NotifiedWorkReportsView from "modules/NotifiedWorkReport/Views";
import LeaveDetailsView from "modules/LeaveManagement/Views/leaveDetailsView";

import { createRouteObject } from "../../../Helper";

const { notifiedWorkReport } = RoutePaths.authorized;

const pages = createRouteObject(
  notifiedWorkReport,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [NotifiedWorkReportsView]
);

const LeaveManagementPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        path={item.slugPath || item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default LeaveManagementPages;
