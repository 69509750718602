import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnUpdate,
  createNewStoreOnDelete,
  createNewStoreOnCreate,
} from "helper";

import {
  DELETE_COMMENT,
  EDIT_COMMENT,
  FETCH_ALL_COMMENTS,
  CREATE_COMMENT,
  RESET_COMMENTS,
} from "common/ActionTypes";

const initialState = createInitialStoreState({}, true);

export const commentsReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${CREATE_COMMENT}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true, true);
    }
    case `${DELETE_COMMENT}_SUCCESS`: {
      return createNewStoreOnDelete(payload, store, true);
    }
    case `${EDIT_COMMENT}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store, true);
    }
    case `${FETCH_ALL_COMMENTS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }
    case `${RESET_COMMENTS}`: {
      return initialState;
    }
    default:
      return store;
  }
};
