import {
  FETCH_TODAY_BIRTHDAYS,
  FETCH_UPCOMINGS_BIRTHDAYS,
} from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import { fetchTodaysBirthdayApi, fetchUpcomingBirthdaysApi } from "./api";

const fetchTodaysBirthdayGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: FETCH_TODAY_BIRTHDAYS,
    sideEffect: fetchTodaysBirthdayApi({ companyId, isAdmin, pageNumber }),
  };
};

const fetchUpcomingBirthdaysGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: FETCH_UPCOMINGS_BIRTHDAYS,
    sideEffect: fetchUpcomingBirthdaysApi({ companyId, isAdmin, pageNumber }),
  };
};

export const [fetchTodaysBirthday, fetchUpcomingBirthdays] =
  createAsyncActionCreator([
    fetchTodaysBirthdayGenrator,
    fetchUpcomingBirthdaysGenrator,
  ]);
