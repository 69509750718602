import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";

import RenderItem from "./RenderItem";
import Grid from "components/Grid";
import { createTableDataSelector } from "common/Selectors/MTable";

import { TableContainer } from "../../../../GlobalStyles";

// import UpdateModal from "components/ModalTypes/UpdateModal";
// import UserCreateForm from "../Create/Form";

// import useAspectRatio from "hooks/useAspectRatio";

const TableData = ({ data, onCellClick, cellNameToColumnMap, rowIdMap }) => {
  // const [isModalActive, updateModalStatus] = useState(false);
  // const ratioData = useAspectRatio({ aspectRatio: 1.33 });
  // const [currentModalData, updateCurrentModalData] = useState({
  //   userId: "",
  //   initialData: {},
  // });

  // const toggleModal = useCallback(() => {
  //   updateModalStatus((val) => !val);
  // }, []);

  const onClick = useCallback(
    ({ data: rowData, columns, rowIndex }) => {
      if (typeof onCellClick === "function") {
        onCellClick({ data: rowData, columns, rowIndex, rowIdMap });
      }
    },
    [onCellClick, rowIdMap]
  );

  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);

  return (
    <TableContainer>
      <Grid
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        data={{
          rowData: data,
          cellNameToColumnMap,
          // toggleModal,
          // updateCurrentModalData,
          onIdClick: onClick,
        }}
        dataKey="rowData"
      >
        {RenderItem}
      </Grid>

      {/* <UpdateModal
        toggleModal={toggleModal}
        isModalActive={isModalActive}
        ratioData={ratioData}
      >
        {currentModalData.userId && (
          <UserCreateForm
            isEditMode={true}
            closeModal={toggleModal}
            userId={currentModalData.userId}
            initialData={currentModalData.initialData}
          />
        )}
      </UpdateModal> */}
    </TableContainer>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "user",
    // extraColumns: ["Update"],
    blackList: { "Sl No": true },
  });
  const maker = (store, props) => {
    const { rows, cellNameToColumnMap, rowIdMap } = getUserRows(store, props);
    return {
      data: rows,
      cellNameToColumnMap,
      rowIdMap,
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
