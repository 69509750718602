import {
  CREATE_LEDGER_CATEGORIES,
  GET_LEDGER_CATEGORIES,
  SET_CURRENT_LEDGER_ID
} from "../actiontypes";

import {
  createInitialStoreState,
  createNewStoreOnGetData,
  createNewStoreOnCreate
} from "helper";

const initialState = createInitialStoreState({ ledgerId: null });

export const ledgersCategoriesReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_LEDGER_CATEGORIES}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${CREATE_LEDGER_CATEGORIES}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);

    case SET_CURRENT_LEDGER_ID: {
      return {
        ...store,
        ledgerId: action.payload.ledgerId
      };
    }
    default:
      return store;
  }
};
