import requestData from "../../ApiHandler";

export const getUserListData = ({ searchText, companyId, isAdmin }) => {
  const url = `/${
    isAdmin ? "executive" : "employee"
  }/companies/${companyId}/search_collaborators?q=${searchText}`;
  return requestData(url, {
    method: "GET",
  });
};

export const getDepartmentListData = ({ searchText, companyId, isAdmin }) => {
  const url = `/${
    isAdmin ? "executive" : "employee"
  }/companies/${companyId}/search_departments?q=${searchText}`;
  return requestData(url, {
    method: "GET",
  });
};
