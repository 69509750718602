import React, { useMemo, useCallback } from "react";
import { createYearMatrix } from "helper";

import { DataContainer, RowContainer, Cell } from "./style";

const YearRow = ({ data = [], onYearChange, currentYear }) => {
  const onChange = useCallback(
    (e) => {
      const { year } = e.target.dataset;
      onYearChange(year);
    },
    [onYearChange]
  );

  return (
    <RowContainer>
      {data.map((year) => {
        return (
          <Cell
            data-year={year}
            onClick={onChange}
            role="button"
            year={year}
            isActive={year == currentYear}
            cellType="year"
            key={`yearCell_${year}`}
          >
            {year}
          </Cell>
        );
      })}
    </RowContainer>
  );
};

const Years = React.forwardRef(
  (
    { startingYear, endingYear, onYearChange, currentYear, visibility },
    ref
  ) => {
    const { years } = useMemo(() => {
      return {
        years: createYearMatrix({
          startingYear,
          endingYear,
        }),
      };
    }, [endingYear, startingYear]);
    return (
      <DataContainer $visibility={visibility} ref={ref}>
        {years.map((yearRows, index) => {
          return (
            <YearRow
              key={`yearRow_${index}`}
              currentYear={currentYear}
              onYearChange={onYearChange}
              data={yearRows}
            />
          );
        })}
      </DataContainer>
    );
  }
);

export default Years;
