export const MockCollaboratorLeaves = [
  {
    leavePlan: { id: 1, leaveType: "CasualLeave" },
    leaveRequisitionCount: 10,
    assignedQuota: 13,
  },
  {
    leavePlan: { id: 1, leaveType: "CasualLeave" },
    leaveRequisitionCount: 10,
    assignedQuota: 13,
  },
  {
    leavePlan: { id: 1, leaveType: "CasualLeave" },
    leaveRequisitionCount: 10,
    assignedQuota: 13,
  },
  {
    leavePlan: { id: 1, leaveType: "CasualLeave" },
    leaveRequisitionCount: 10,
    assignedQuota: 13,
  },
  {
    leavePlan: { id: 1, leaveType: "CasualLeave" },
    leaveRequisitionCount: 10,
    assignedQuota: 13,
  },
  {
    leavePlan: { id: 1, leaveType: "CasualLeave" },
    leaveRequisitionCount: 10,
    assignedQuota: 13,
  },
];
