import { createSelector } from "reselect";

const storeCalendars = (store, props) => store.calendar.list.data;
const storeSchedule = (store, props) => store.calendar.schedule.data;
const storeScheduleRelation = (store, props) =>
  store.calendar.schedule.relation;

export const getAllCalendarColors = createSelector(
  storeCalendars,
  (calendars) => {
    const res = {};
    Object.values(calendars).forEach((item, index) => {
      if (typeof item === "function") {
        return;
      }
      res[item.id] = item?.attributes?.color || "black";
    });
    return res;
  }
);

export const getCalendarList = createSelector(storeCalendars, (calendars) => {
  const result = [];
  Object.values(calendars).forEach((item, index) => {
    if (typeof item === "function") {
      return;
    }
    result.push({
      ...(item.attributes || {}),
      id: item.id,
      name: item?.attributes?.name || "",
      value: item.id,
      style: {
        backgroundColor: item?.attributes?.color || "initial",
      },
    });
  });

  return result;
});

export const getScheduleList = createSelector(
  storeSchedule,
  storeScheduleRelation,
  (calendars, relation) => {
    const result = [];
    Object.values(calendars).map((item) => {
      if (typeof item === "function") {
        return;
      }

      const itemRelationId = item?.relationships?.calender?.data?.id;
      const relationType = item?.relationships?.calender?.data?.type;
      const key = `${itemRelationId}_${relationType}`;
      const color = relation[key]?.attributes?.color || "purple";
      result.push({
        ...(item.attributes || {}),
        color,
        id: item.id,
      });
    });

    return result;
  }
);
