import styled from "styled-components";
import {
  flexColumnCenter,
  flexRowCenter,
  StyledFormGroup,
} from "../../../GlobalStyles";
import Button from "../../../Common/Components/Button";
import Icon from "../../../Common/Components/Icon";

export const ViewPortContainer = styled(flexColumnCenter)`
  position: relative;
  min-height: 100vh;
  flex-wrap: wrap;
  padding: 20px;
  overflow: hidden;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    height: 100vh;
  }
`;

export const Container = styled(flexColumnCenter)`
  height: ${(props) => (props.nextPageStatus ? "40vh" : "50vh")};
  max-height: ${(props) => (props.nextPageStatus ? "250px" : "350px")};
  width: 90%;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  justify-content: space-between;
  background-color: white;
  @media (min-width: 576px) {
    height: ${(props) => (props.nextPageStatus ? "35vh" : "50vh")};
    width: 65%;
  }
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 1024px) {
    width: 30%;
  }
`;
export const Header = styled(flexRowCenter)`
  height: 15%;
  width: 100%;
  text-align: left;
  padding: 8px 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #01008a;
  color: white;
  justify-content: flex-start;
  font-size: ${(props) => props.theme.text.para.large};
  font-weight: bold;
  @media (min-width: 768px) {
    padding: 8px 30px;
  }
`;
export const AdjustableHeightContainer = styled(flexRowCenter)`
  height: ${(props) => props.height};
  width: 100%;
  padding: 0px 15px;
  @media (min-width: 768px) {
    padding: 10px 30px;
  }
`;
export const StyledIcon = styled(Icon)`
  margin-top: 10px;
  color: #01008a;
`;
export const StyledParagraph = styled.p`
  margin: 0;
  line-height: 1.4;
  @media (min-width: 768px) {
    line-height: 1.6;
  }
`;
export const StyledButton = styled(Button)`
  background-color: #01008a;
`;
export const BackgroundImage = styled.img`
  position: absolute;
  z-index: -2;
  top: 0px;
  right: 0px;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    height: 100%;
  }
`;
export const GroupImage = styled.img`
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 20%;
  width: 60%;
  max-width: 300px;
  @media (min-width: 768px) {
    left: 10%;
  }
  @media (min-width: 1200px) {
    max-width: 350px;
  }
`;
export const ReStyledFormGroup = styled(StyledFormGroup)`
  height: 85%;
  justify-content: space-evenly;
  padding: 30px 15px;
  @media (min-width: 768px) {
    padding: 30px;
  }
`;
