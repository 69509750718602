import { sectionAccessType } from "./section";
import { tabsAccessType } from "./tab";
import { pageAccessType } from "./page";

const AccessTypes = {
  section: sectionAccessType,
  tabs: tabsAccessType,
  pages: pageAccessType,
};

export default AccessTypes;
