import { getDistanceBetweenTwoDateInWords } from "./date";

export const mapCommentData = (data) => {
  let comments = [];

  if (Array.isArray(data)) {
    comments = data.map((item) => {
      const { collaborator, id, message, createdAt } = item;
      return {
        id: id,
        userName: collaborator.name,
        userImage: collaborator?.avatarUrl?.url,
        designation: collaborator.role,
        comment: message,
        time: `${getDistanceBetweenTwoDateInWords(createdAt, new Date())} ago`,
        collaboratorId: collaborator.id,
      };
    });
  }

  return comments;
};
