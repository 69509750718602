import {
  CREATE_TEAMS_WORK_LOG,
  EDIT_TEAMS_WORK_LOG,
  GET_TEAMS_WORK_LOG,
  RESET_TEAMS_WORK_LOG,
} from "../Views/Tabs/Worklog/redux/actionTypes";

import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnUpdate,
  createNewStoreOnCreate,
} from "helper";

const initialState = createInitialStoreState({});

export const teamsWorkLogReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_TEAMS_WORK_LOG}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }
    case `${EDIT_TEAMS_WORK_LOG}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }
    case `${CREATE_TEAMS_WORK_LOG}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true, true);
    }
    case RESET_TEAMS_WORK_LOG: {
      return initialState;
    }

    default:
      return store;
  }
};
