import requestData from "../../../ApiHandler";

export const getNotifiedDailyWorkReportsApi = ({ pageNumber, companyId }) => {
  const url = `/employee/companies/${companyId}/fetch_notified_daily_work_reports?page=${pageNumber}`;
  return requestData(url, {
    method: "GET",
  });
};

export const searchWorkReportsApi = ({
  pageNumber,
  companyId,
  fromDate,
  toDate,
  collaboratorIds,
}) => {
  const from_date = fromDate ? `&from_date=${fromDate}` : "";
  const to_date = toDate ? `&to_date=${toDate}` : "";
  let collaborator_ids = "";

  collaboratorIds?.map((collaborator) => {
    collaborator_ids += `&collaborator_ids[]=${collaborator}`;
  });

  const url = `/employee/companies/${companyId}/search_notified_daily_work_reports?page=${pageNumber}${from_date}${to_date}${collaborator_ids}`;
  return requestData(url, {
    method: "GET",
  });
};
