import React from "react";

import { Container, Title, Icon } from "./style";
import { getIcon } from "helper";

const SnapViewer = ({ data }) => {
  const { iconCode, title } = data;

  const onClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Container onClick={onClick}>
      <Icon>{getIcon(iconCode)}</Icon>
      <Title>{title}</Title>
    </Container>
  );
};

export default SnapViewer;
