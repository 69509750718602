import { useCallback, useEffect, useMemo } from "react";
import usePagination from "hooks/usePagination";
import { getTeamMembers } from "../actionCreator";
import { useSelector } from "react-redux";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { getOncefetched } from "common/Selectors/store";

const onceFetchedSelector = getOncefetched("teams.members");

export const useMembersViewPagination = () => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
    allPagesFetched,
    errorOccured,
  } = usePagination({
    actionCreator: getTeamMembers,
    tableName: "teams.members",
  });

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const onceFetched = useSelector(onceFetchedSelector);

  const teamId = useSelector((store) => store.teams.owners?.teamId);

  const extraParams = useMemo(
    () => ({ companyId, isAdmin, teamId }),
    [companyId, isAdmin, teamId]
  );

  const fetchFirstPage = useCallback(() => {
    getNextPage({
      isFirstPage: true,
      extraParams,
    });
  }, [getNextPage, extraParams]);

  const fetchPrevPage = useCallback(() => {
    getNextPage({ extraParams });
  }, [getNextPage, extraParams]);

  const fetchNextPage = useCallback(() => {
    getNextPage({ extraParams });
  }, [getNextPage, extraParams]);

  const fetchSpecificPage = useCallback(
    (pageNumber) => {
      getNextPage({
        pageNumber,
        extraParams,
      });
    },
    [getNextPage, extraParams]
  );

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
    // onceFetched is added in the dependency as we are using a refresh button to refresh the table data for this particular table only
  }, [onceFetched]);

  return {
    currentPageNumber,
    totalPages,
    fetchNextPage,
    fetchPrevPage,
    fetchSpecificPage,
    loadingNextPage,
    loadingPrevPage,
    onEndReached: !allPagesFetched ? fetchNextPage : null,
    initialLoading: !onceFetched && loadingNextPage,
    loading: onceFetched && loadingNextPage && !allPagesFetched,
    errorOccured,
  };
};
