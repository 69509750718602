import React, { useMemo, useCallback } from "react";
import { createDayMatrix } from "helper";

import { DataContainer, RowContainer, Cell, ExtraContent, CellBox } from "./style";
import { getTimeFromDate } from "../../../../Helper";

const DayRow = ({ data = [], row, currentDay, onDayChange, dateRange, month, year, isFromDate }) => {
  const onChange = useCallback(
    (e) => {
      const { day } = e.target.dataset;
      onDayChange(day, month, year);
    },
    [onDayChange]
  );

  const fromDateDay = useMemo(() => dateRange.fromDate.day, [dateRange.fromDate]);
  const fromDateMonth = useMemo(() => dateRange.fromDate.month, [dateRange.fromDate]);
  const fromDateYear = useMemo(() => dateRange.fromDate.year, [dateRange.fromDate]);

  const toDateDay = useMemo(() => dateRange.toDate.day, [dateRange.toDate]);
  const toDateMonth = useMemo(() => dateRange.toDate.month, [dateRange.toDate]);
  const toDateYear = useMemo(() => dateRange.toDate.year, [dateRange.toDate]);


  const toDateTime = useMemo(() => getTimeFromDate(toDateMonth, toDateDay, toDateYear), [toDateMonth, toDateDay, toDateYear]);

  const fromDateTime = useMemo(() => getTimeFromDate(fromDateMonth, fromDateDay, fromDateYear), [fromDateMonth, fromDateDay, fromDateYear]);

  const isButtonDisabled = useCallback((day) => {
    if (!fromDateDay && !toDateDay)
      return false;
    const selectDateTime = getTimeFromDate(month, day, year);
    if (isFromDate && day) {
      if (toDateTime && selectDateTime >= toDateTime)
        return true;
      return false;
    }

    if (day && fromDateTime) {
      if (selectDateTime <= fromDateTime)
        return true;
    }
    return false;
  }, [fromDateDay, toDateDay, fromDateTime, toDateTime, isFromDate, data, month, year]);

  const isDayBetween = useCallback((day) => {
    const selectDateTime = getTimeFromDate(month, day, year);
    return day && fromDateTime < selectDateTime && selectDateTime < toDateTime;
  }, [data, month, year, fromDateTime, toDateTime])

  const isFromDateActive = useCallback((day) => {
    if (isFromDate === undefined)
      return currentDay == day;

    if (isFromDate)
      return (toDateDay == day && toDateMonth == month && toDateYear == year) || (day == currentDay && fromDateDay == currentDay && fromDateMonth == month && fromDateYear == year)

    return (fromDateDay == day && fromDateMonth == month && fromDateYear == year) || (day == currentDay && toDateDay == currentDay && toDateMonth == month && toDateYear == year)
  }, [isFromDate, currentDay, data, fromDateDay, fromDateMonth, fromDateYear, toDateDay, toDateMonth, toDateYear, month, year]);

  const checkIfFromDate = useCallback((day) => {
    const selectDateTime = getTimeFromDate(month, day, year);
    return day && selectDateTime === fromDateTime && fromDateDay === day && fromDateTime !== toDateTime
  }, [data, fromDateTime, fromDateDay, toDateTime, month, year])

  const checkIfToDate = useCallback((day) => {
    const selectDateTime = getTimeFromDate(month, day, year);
    return day && selectDateTime === toDateTime && toDateDay === day && fromDateTime !== toDateTime
  }, [data, toDateTime, fromDateTime, toDateDay, month, year])

  return (
    <RowContainer>
      {data.map((day, index) => {
        return (
          <CellBox>
            <ExtraContent
              isFromDate={checkIfFromDate(day)}
              isToDate={checkIfToDate(day)}
            />
            <Cell
              disabled={isButtonDisabled(day)}
              onClick={day ? onChange : null}
              data-day={day}
              isBetween={isDayBetween(day)}
              day={day}
              isFromDate={checkIfFromDate(day)}
              isToDate={checkIfToDate(day)}
              isActive={isFromDateActive(day)}
              key={`dayCell_${row}_${index}`}
              cellType="day"
            >
              {day}
            </Cell>
          </CellBox>
        );
      })}
    </RowContainer>
  );
};

const Days = React.forwardRef(
  ({ day, month, year, onDayChange, visibility, dateRange, isFromDate }, ref) => {
    const { days } = useMemo(() => {
      const date = new Date(year, month, 1);
      const startingDay = date.getDay();
      const totalDays = new Date(year, month + 1, 0).getDate();
      return {
        days: createDayMatrix({
          rows: 5,
          columns: 6,
          startingDay,
          totalDays,
        }),
      };
    }, [month, year]);
    return (
      <DataContainer $visibility={visibility} ref={ref}>
        {days.map((dayRows, index) => {
          return (
            <DayRow
              onDayChange={onDayChange}
              dateRange={dateRange}
              currentDay={day}
              month={month}
              year={year}
              isFromDate={isFromDate}
              row={index}
              key={`dayRow_${index}`}
              data={dayRows}
            />
          );
        })}
      </DataContainer>
    );
  }
);
export default Days;
