import { useCallback, useEffect } from "react";
import useForm from "hooks/useForm";
import useCreateCountdown from "./useCreateCountdown";
import { validateInteger } from "helper/validators";
import Routes from "../../../../Routes/Path";

import { useSelector } from "react-redux";
import { countdownDataSelector } from "modules/Countdown/selector";
import { useHistory } from "react-router";

export const useCountdownForm = ({ editId }) => {
  const history = useHistory();
  const { formData, values, resetForm, isFormValid, updateField } = useForm({
    fields: {
      title: {
        required: true,
      },
      description: {},

      happeningAtDate: {
        required: true,
      },
      happeningAtHour: {
        required: true,
      },
      happeningAtMin: {
        required: true,
      },
      happeningAtSec: {
        required: true,
      },
      priority: {
        validator: validateInteger,
        invalidMessage: "Enter a valid integer number",
      },
      status: {
        required: !!editId,
      },
    },
  });

  const { isLoading, createCountdown, editCountdown } = useCreateCountdown();

  const editCountdownData = useSelector((store) =>
    countdownDataSelector(store, { id: editId })
  );
  useEffect(() => {
    if (!editId || !editCountdownData) {
      return;
    }
    updateField("title", editCountdownData?.title);
    updateField("description", editCountdownData?.description);
    updateField("priority", editCountdownData?.priority);
    updateField("happeningAtDate", editCountdownData?.happeningAt);
    updateField("happeningAtHour", editCountdownData.happeningAt.substr(11, 2));
    updateField("happeningAtMin", editCountdownData.happeningAt.substr(14, 2));
    updateField("happeningAtSec", editCountdownData.happeningAt.substr(17, 2));
    updateField("status", editCountdownData?.status);
  }, [editId, editCountdownData]);

  const goBack = () => {
    history.replace(Routes.authorized.countdown.view.path);
  };

  const onFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isFormValid()) {
        return;
      }
      editId
        ? editCountdown(values, resetForm, editId)
        : createCountdown(values, resetForm);
      if (editId) {
        goBack();
        return;
      }
    },

    [isFormValid, values, resetForm, editId]
  );

  return { formData, isLoading, onFormSubmit };
};
