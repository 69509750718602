import MultipleUsers from "components/MultipleUsers";
import React, { useMemo } from "react";
import { CollaboratorsContainer, SentToText } from "../../../styles/sentNotice";

const Collaborators = ({ sentTo, sentToCollaborators }) => {
  const { collaboratorsList, more } = useMemo(() => {
    if (
      !sentToCollaborators ||
      !Array.isArray(sentToCollaborators) ||
      sentToCollaborators.length === 0
    ) {
      return {
        collaboratorsList: [],
        more: false
      };
    } else if (sentToCollaborators.length <= 5) {
      return {
        collaboratorsList: sentToCollaborators,
        more: false
      };
    } else {
      const howMany = `${sentToCollaborators.length - 4}`;
      return {
        collaboratorsList: sentToCollaborators.slice(0, 4),
        more: {
          count: howMany,
          title: `+ ${howMany} more`
        }
      };
    }
  }, [sentToCollaborators]);

  const listExists = useMemo(() => {
    if (sentTo.toLowerCase() === "few" && collaboratorsList.length > 0)
      return true;
    return false;
  }, [sentTo, collaboratorsList]);

  return listExists ? (
    <CollaboratorsContainer>
      <SentToText>Sent To -</SentToText>
      <MultipleUsers data={sentToCollaborators} />
    </CollaboratorsContainer>
  ) : null;
};
export default Collaborators;
