import requestData from "../../ApiHandler";
import { createAuthUrl, extractDateInYYYYMMDD } from "helper";

export const getCountdownsApi = ({ companyId, pageNumber, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/countdowns?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const createCountdownApi = ({ companyId, formData, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/countdowns`,
  });

  return requestData(authUrl, {
    method: "POST",
    body: {
      countdown: {
        title: formData?.title,
        description: formData?.description || "",
        happening_at: `${extractDateInYYYYMMDD(formData?.happeningAtDate)} ${
          formData?.happeningAtHour
        }:${formData?.happeningAtMin}:${formData?.happeningAtSec}`,
        priority: formData?.priority || "",
      },
    },
    isFormData: true,
  });
};

export const editCountdownApi = ({
  countdownId,
  companyId,
  isAdmin,
  formData,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/countdowns/${countdownId}`,
  });
  return requestData(url, {
    method: "PUT",
    body: {
      countdown: {
        title: formData?.title,
        description: formData?.description || "",
        happening_at: `${extractDateInYYYYMMDD(formData?.happeningAtDate)} ${
          formData?.happeningAtHour
        }:${formData?.happeningAtMin}:${formData?.happeningAtSec}`,
        priority: formData?.priority || "",
        status: formData?.status,
      },
    },
    isFormData: true,
  });
};
export const deleteCountdownApi = ({
  countdownId,
  companyId,
  isAdmin,
  formData,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/countdowns/${countdownId}`,
  });
  return requestData(url, {
    method: "DELETE",
  });
};
