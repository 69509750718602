import styled from "styled-components";
import PostCard, { PostCardSkeleton } from "components/Posts";

import { FadeInVertical } from "globalStyles";
const TopFade = FadeInVertical("top");

export const AnimatedPostCardSkeleton = styled(PostCardSkeleton)`
  opacity: 0;
  animation: ${TopFade} ease-out 0.3s forwards;
`;

export const AnimatedPostCard = styled(PostCard)`
  opacity: 0;
  animation: ${TopFade} ease-out 0.3s forwards;
`;
// export const Container = styled.div`
//   min-height: 100vh;
//   padding: 10px;
//   display: flex;
//   align-items: flex-start;
//   flex-flow: row wrap;
//   padding: 20px;
//   justify-content: center;
//   gap: 10px;

//   @media (min-width: 646px) {
//     margin-right: 0;
//     justify-content: space-between;
//   }
//   @media (min-width: 1024px) {
//     padding: 41px 64px 10px 60px;
//     align-content: flex-start;
//   }
// `;

// export const Heading = styled.h1`
//   width: auto;
//   font-size: ${(props) => props.theme.text.heading.large};
//   color: ${(props) => props.theme.colors.primaryColor};
// `;

// export const SubHeading = styled.span`
//   font-size: ${(props) => props.theme.text.heading.medium};
//   color: ${(props) => props.theme.colors.primaryColor};
// `;

// export const Description = styled.span`
//   font-size: ${(props) => props.theme.text.title.medium};
//   color: ${(props) => props.theme.colors.primaryColor};
// `;

export const Container = styled.div`
  height: 100%;
  background-color: #f5f5f7;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: minmax(230px, 0.5fr) 1fr minmax(230px, 0.5fr);
  grid-template-areas: "lt md rt";
  gap: 23px;
  @media (min-width: 1024px) {
    padding: 41px 64px 10px 60px;
  }
`;

export const LeftContainer = styled.div`
  grid-area: lt;
`;
export const PostsContainer = styled.div`
  grid-area: md;
  word-break: break-word;
`;

export const RightContainer = styled.div`
  grid-area: rt;
`;

export const Card = styled.div`
  background-color: white;
  border-radius: 9px;
  & + * {
    margin-top: 15px;
  }
`;

export const PostLoader = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
