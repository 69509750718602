import styled from "styled-components";
import Button from "components/Button";
import { scrollbar } from "../../../GlobalStyles/view";
import { RectangleSkeleton } from "components/Skeleton";
import { ImageComponent } from "../../../Common/Components/Image";

export const StyledButton = styled(Button)`
  height: 35px;
`;

export const Cell = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 600px;
  display: inline-block;
  position: relative;
  border: ${({ theme }) => `1px ${theme.colors.grid.border} solid`};
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryColor};
  color: white;
  font-size: ${({ theme }) => theme.text.para.medium};
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  position: sticky;
  z-index: 2;
  top: 0;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: wrap;
  flex-direction: column;
  ${scrollbar}
  padding: 0px 0px;
`;

export const LeaveContainer = styled.div`
  width: 33%;
  display: inline-block;
  margin: 10px 20px;
  text-align: left;
`;

export const StyledSkeleton = styled(RectangleSkeleton)`
  height: 20px;
  width: auto;
  margin: 10px 20px;
  & > div {
    width: 150px;
    height: 20px;
  }
`;

export const EmptyScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    height: 35px;
  }
`;

export const EmptyScreenImage = styled(ImageComponent)`
  width: 40%;
  height: 40%;
  object-fit: contain;
`;

export const EmptyScreenMessage = styled.span`
  margin: 30px;
  font-size: ${(props) => props.theme.text.title.medium};
`;

export const ButtonContainer = styled.div`
  display: flex;
  & button {
    margin-right: 15px;
    margin-top: 10px;
  }
`;
