import React, { useCallback, useEffect } from "react";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import Icon from "components/Icon";
import useForm from "hooks/useForm";
import Routes from "route/Path";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { workshiftDataSelector } from "../selector";

import { FormRow, StyledForm } from "../../../../style/create";
import { Hours, MinutesOrSeconds, meridiem } from "./constants";
import useCreateWorkshiftType from "./useCreateWorkshift";

const Form = ({ updateFormVisibility, editId }) => {
  const isEditMode = !!editId;
  const history = useHistory();
  const { formData, values, resetForm, isFormValid, updateField } = useForm({
    editId,
    fields: {
      name: {
        required: true,
      },
      startTimeHour: { required: true },
      startTimeMin: { required: true },
      startTimeMeridiem: { required: true },
      endTimeHour: { required: true },
      endTimeMin: { required: true },
      endTimeMeridiem: { required: true },
    },
  });
  const goBack = () => {
    history.replace(Routes.authorized.workshift.view.path);
  };
  const hideForm = () => {
    typeof updateFormVisibility == "function"
      ? updateFormVisibility(false)
      : goBack();
  };
  const onReset = () => {
    typeof resetForm == "function" && resetForm();
    hideForm();
  };
  const { isLoading, createWorkshiftTypeData, editWorkshift } =
    useCreateWorkshiftType();

  const editWorkshiftData = useSelector((store) =>
    workshiftDataSelector(store, { id: editId })
  );
  useEffect(() => {
    if (!editId || !editWorkshiftData) {
      return;
    }
    updateField("name", editWorkshiftData?.name);
    updateField("startTimeHour", editWorkshiftData?.startTime.substr(0, 2));
    updateField("startTimeMin", editWorkshiftData?.startTime.substr(3, 2));
    updateField("startTimeMeridiem", editWorkshiftData?.startTime.substr(6, 2));
    updateField("endTimeHour", editWorkshiftData?.endTime.substr(0, 2));
    updateField("endTimeMin", editWorkshiftData?.endTime.substr(3, 2));
    updateField("endTimeMeridiem", editWorkshiftData?.endTime.substr(6, 2));
  }, [editId, editWorkshiftData]);

  const onFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isFormValid()) {
        return;
      }
      editId
        ? editWorkshift(values, onReset, editId)
        : createWorkshiftTypeData(values, resetForm);
    },
    [isFormValid, values, resetForm, editId]
  );

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <Icon name="fas fa-times" onClick={hideForm} />
      <InputGroup
        label="Workshift Name"
        placeholder="Enter Workshift Name"
        secondary
        {...formData["name"]}
      />

      <FormRow>
        <InputGroup
          label="Start Time"
          fieldType="select"
          options={Hours}
          defaultSelectText="hh"
          {...formData["startTimeHour"]}
        />
        :
        <InputGroup
          style={{ marginTop: "10px" }}
          fieldType="select"
          defaultSelectText="mm"
          options={MinutesOrSeconds}
          {...formData["startTimeMin"]}
        />
        <InputGroup
          style={{ marginTop: "10px" }}
          fieldType="select"
          defaultSelectText="AM"
          options={meridiem}
          {...formData["startTimeMeridiem"]}
        />
      </FormRow>

      <FormRow>
        <InputGroup
          label="End Time"
          fieldType="select"
          options={Hours}
          defaultSelectText="hh"
          {...formData["endTimeHour"]}
        />
        :
        <InputGroup
          style={{ marginTop: "10px" }}
          fieldType="select"
          defaultSelectText="mm"
          options={MinutesOrSeconds}
          {...formData["endTimeMin"]}
        />
        <InputGroup
          style={{ marginTop: "10px" }}
          fieldType="select"
          defaultSelectText="AM"
          options={meridiem}
          {...formData["endTimeMeridiem"]}
        />
      </FormRow>

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        {isEditMode ? "Update" : "Create"}
      </Button>
    </StyledForm>
  );
};

export default Form;
