import {
  CREATE_ACCESS_ASSET,
  GET_ACCESS_ASSETS,
  SEARCH_ACCESS_ASSET,
  UPDATE_SEARCH_PARAMS,
  RESET_SEARCH_PARAMS,
  EDIT_ACCESS_ASSET,
} from "./actiontypes";

import {
  createInitialStoreState,
  createNewStoreOnGetData,
  createNewStoreOnCreate,
  createNewStoreOnUpdate
} from "../../Helper";

const initialState = createInitialStoreState({
  isSearchOn: false,
});

export const accessAssetsReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${CREATE_ACCESS_ASSET}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);
    case `${GET_ACCESS_ASSETS}_SUCCESS`:

    case `${SEARCH_ACCESS_ASSET}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${EDIT_ACCESS_ASSET}_SUCCESS`:
      return createNewStoreOnUpdate(payload, store);

    case `${UPDATE_SEARCH_PARAMS}`: {
      const { assigned_to_id, category, from_date, to_date, status } = payload;
      if (assigned_to_id || category || from_date || to_date || status)
        return {
          ...store,
          onceFetched: false,
          assignedTo: assigned_to_id,
          category: category,
          currentFromDate: from_date,
          currentToDate: to_date,
          currentStatus: status,
          isSearchOn: true,
        };
      return store;
    }

    case `${RESET_SEARCH_PARAMS}`: {
      return {
        ...store,
        onceFetched: false,
        assignedTo: "",
        category: "",
        currentFromDate: "",
        currentToDate: "",
        currentStatus: "",
        isSearchOn: false,
      };
    }

    default:
      return store;
  }
};
