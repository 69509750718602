import { useCallback, useEffect, useState } from "react";
import { LocalStorage } from "storage";
import { ONLINE_FIELD } from "../Constants";

const useInternetConnectivity = () => {
  const [isActive, updateNetowrkStatus] = useState(true);

  useEffect(() => {
    LocalStorage.setKey(ONLINE_FIELD, "true", true);
  }, []);

  const updateStatus = useCallback(() => {
    if (window.navigator.onLine) {
      updateNetowrkStatus(true);
      LocalStorage.setKey(ONLINE_FIELD, "true", true);
    } else {
      updateNetowrkStatus(false);
      LocalStorage.setKey(ONLINE_FIELD, "", true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("offline", updateStatus);
    window.addEventListener("online", updateStatus);
    return () => {
      window.removeEventListener("offline", updateStatus);
      window.removeEventListener("online", updateStatus);
    };
  });

  return isActive;
};

export default useInternetConnectivity;
