import styled from "styled-components";
import { FadeInHorizontal } from "globalStyles";

const getHorizonatlPositionStyle = ({ $horizontal }) => {
  switch ($horizontal) {
    case "left": {
      return "left: 10px;";
    }
    case "right": {
      return "right: 10px;";
    }
    default:
      return "";
  }
};

const getBackground = (props) => {
  const { type, theme } = props;
  const {
    colors: { alert },
  } = theme;
  switch (type) {
    case "success":
      return alert.success;
    case "error":
      return alert.error;
    case "warning":
      return alert.warning;
    case "info":
      return alert.info;
    default:
      return "gray";
  }
};

const getShadow = (props) => {
  const { type } = props;
  switch (type) {
    case "success":
      return "0 14px 38px 0 rgba(74, 197, 1, 0.08)";
    case "error":
      return "0 14px 38px 0 rgba(255, 0, 0, 0.07)";
    case "info":
      return "0 14px 38px 0 rgba(220, 249, 255, 0.46)";
    case "warning":
      return "0 14px 38px 0 rgba(254, 247, 221, 0.67)";
    default:
      return "";
  }
};

export const AlertContainer = styled.div`
  display: flex;
  width: 364px;
  min-height: 60px;
  white-space: normal;
  height: auto;
  position: fixed;
  background-color: white;
  box-shadow: ${getShadow};
  z-index: ${(props) => props.theme.zIndex.alert};
  border-radius: 9px;
  overflow: hidden;
  padding-left: 21px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 7px;
    background-color: ${getBackground};
  }
  opacity: 0;
  animation: ${({ $horizontal }) => FadeInHorizontal($horizontal)} ease 0.2s
    forwards;
  ${getHorizonatlPositionStyle}
`;

export const AlertIconArea = styled.div`
  flex-basis: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AlertContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 45px;
`;

export const Heading = styled.h4`
  font-size: ${(props) => props.theme.text.para.medium};
  font-weight: 600;
  color: ${(props) => props.theme.colors.alert.heading};
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 2px;
`;

export const Description = styled.span`
  font-size: ${(props) => props.theme.text.para.extraSmall};
  font-weight: 500;
  color: ${(props) => props.theme.colors.alert.desc};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: calc(50% - 8px);
  right: 20px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  color: #a0a0a0;
  cursor: pointer;
`;

export const IconBox = styled.img`
  width: 30.3px;
  height: 30.3px;
`;
