export const zIndex = {
  slider: "1000",
  backdrop: "3000",
  drawer: "4000",
  navbar: "5000",
  modal: "6000",
  alert: "10000",
  popoverBackdrop: "18000",
  popover: "20000",
  noInternet: "21000",
};
