import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import Icon from "components/Icon";
import { getDateDetails } from "helper/date";
import { useCountdown } from "../../useCountdown";
import { getCountdowns } from "../../actionCreator";
import { getAllCountdowns } from "../../selector";
import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  CountdownContainer,
  TimerCell,
  TimerContainer,
  Title,
  DateType,
  DateCell,
  DateContainer,
  ToggleButton,
  MultiCountdownContainer,
  CountdownsContainer,
  CountdownWrapper,
} from "./style";
import Tooltip from "components/Tooltip2";

const DateTimeDisplay = ({ value, type }) => {
  return (
    <TimerCell>
      <DateCell>{value}</DateCell>
      <DateType>{type}</DateType>
    </TimerCell>
  );
};

const Countdown = ({ currentCountdown }) => {
  const [days, hours, minutes, seconds] = useCountdown(
    currentCountdown?.happeningAt
  );

  const happeningDate = useMemo(() => {
    const { todayDate, monthIndex, year, hour, min } = getDateDetails(
      currentCountdown?.happeningAt
    );
    const duration = `${todayDate}-${monthIndex}-${year} ${hour}:${min}`;
    return duration;
  }, [currentCountdown]);

  return (
    <CountdownContainer key={currentCountdown?.id}>
      <Title data-tooltip={`countdown-title_${currentCountdown?.id}`}>
        {currentCountdown?.title}
      </Title>
      <Tooltip
        style={{ maxWidth: 300 }}
        tooltipId={`countdown-title_${currentCountdown?.id}`}
      >
        {currentCountdown?.title}
      </Tooltip>
      <TimerContainer>
        <DateTimeDisplay value={days} type="days" />
        <DateTimeDisplay value={hours} type="hours" />
        <DateTimeDisplay value={minutes} type="minutes" />
        <DateTimeDisplay value={seconds} type="seconds" />
      </TimerContainer>
      {happeningDate && <DateContainer>{happeningDate}</DateContainer>}
    </CountdownContainer>
  );
};

const MultiCountdowns = ({
  isAdmin,
  companyId,
  onceFetched,
  countdowns,
  paginationData,
}) => {
  const dispatch = useDispatch();

  const [currentCountdownIndex, setCurrentCountdownIndex] = useState(0);
  const fetchData = async (pageNumber) => {
    await dispatch(getCountdowns({ isAdmin, companyId, pageNumber }));
  };

  useEffect(() => {
    if (!onceFetched) {
      fetchData();
    }
  }, [onceFetched]);

  const showNextCountdown = () => {
    if (countdowns?.length - 1 > currentCountdownIndex) {
      setCurrentCountdownIndex((prev) => prev + 1);
    } else if (paginationData?.hasNextPage) {
      fetchData(paginationData?.nextPageNumber);
    }
  };

  const isNextButtonVisible = useMemo(() => {
    return (
      currentCountdownIndex < countdowns?.length - 1 ||
      paginationData?.hasNextPage
    );
  }, [countdowns, currentCountdownIndex, paginationData]);

  const showPrevCountdown = () => {
    setCurrentCountdownIndex((prev) => prev - 1);
  };

  if (countdowns?.length > 0) {
    return (
      <MultiCountdownContainer>
        {currentCountdownIndex > 0 && (
          <ToggleButton style={{ left: "-20px" }} onClick={showPrevCountdown}>
            <Icon name="fas fa-arrow-left" />
          </ToggleButton>
        )}
        {isNextButtonVisible && (
          <ToggleButton style={{ right: "-20px" }} onClick={showNextCountdown}>
            <Icon name="fas fa-arrow-right" />
          </ToggleButton>
        )}
        <CountdownsContainer>
          {countdowns.map((item, index) => (
            <CountdownWrapper $right={-(index - currentCountdownIndex) * 285}>
              <Countdown currentCountdown={item} />
            </CountdownWrapper>
          ))}
        </CountdownsContainer>
      </MultiCountdownContainer>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const companyId = getCurrentUserCompany(store, props);
  const countdowns = getAllCountdowns(store, props);
  const paginationData = store.countdown?.paginationData;
  const onceFetched = store.countdown.onceFetched;
  return {
    companyId,
    isAdmin,
    onceFetched,
    paginationData,
    countdowns,
  };
};

export default connect(mapStateToProps, null)(MultiCountdowns);
