import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  gap: 23px;
  justify-content: center;
  align-items: stretch;
  //max-width: 1100px;

  @media (min-width: 768px) {
    padding: 35px 60px 10px 60px;
    justify-content: flex-start;
  }
`;

export const Heading = styled.h1`
  width: auto;
  font-size: ${(props) => props.theme.text.heading.large};
  color: ${(props) => props.theme.colors.primaryColor};
`;

export const SubHeading = styled.span`
  font-size: ${(props) => props.theme.text.heading.medium};
  color: ${(props) => props.theme.colors.primaryColor};
`;

export const Description = styled.span`
  font-size: ${(props) => props.theme.text.title.medium};
  color: ${(props) => props.theme.colors.primaryColor};
`;
