import React, { useState, useCallback } from "react";
import { ViewContainer } from "globalStyles";
import LeaveCreateView from "../Create";
import ActionArea from "./ActionArea";
import TableData from "./TableData";
import { useSelector } from "react-redux";
import { isCurrentUserIsExecutive } from "../../../../Common/Selectors/auth";
import {
  EmptyScreenContainer,
  EmptyScreenImage,
  EmptyScreenMessage,
  ButtonContainer
} from "../../style/display";
import { MainContainer } from "modules/Leave/style/create";
import EmptyScreen from "assets/employee/ApplyForLeave.svg";
import Button from "components/Button";
import { RectangleSkeleton } from "components/Skeleton";
import LeavesApplied from "./LeavesApplied";

const Leave = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const [isLeavesApplied, setIsLeavesApplied] = useState(false);

  const updateLeavesApplied = useCallback(() => {
    setIsLeavesApplied((val) => !val);
  }, []);

  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  const updateFormVisibility = useCallback(() => {
    setIsFormVisible((val) => !val);
  }, []);

  if (isFormVisible) {
    return <LeaveCreateView updateFormVisibility={updateFormVisibility} />;
  }

  return (
    <MainContainer>
      <ActionArea
        onLoading={onDbDataChange}
        style={{
          display:
            !isLeavesApplied && (isLoading || isEmpty) ? "none" : "inherit"
        }}
        isLeavesApplied={isLeavesApplied}
        updateLeavesApplied={updateLeavesApplied}
        updateFormVisibility={updateFormVisibility}
      />
      {isLeavesApplied ? (
        <LeavesApplied />
      ) : (
        <TableData
          style={{
            display: isLoading || isEmpty ? "none" : "inherit"
          }}
          onEmptyData={onEmptyData}
        />
      )}

      {!isLoading && isEmpty && !isLeavesApplied && (
        <EmptyScreenContainer>
          <EmptyScreenImage src={EmptyScreen} alt="No records found" />
          <EmptyScreenMessage>
            {isAdmin
              ? "There are no leaves"
              : "You have not applied for any leaves"}
          </EmptyScreenMessage>
          {!isAdmin && (
            <ButtonContainer>
              <Button primary size="lg" onClick={updateFormVisibility}>
                Create New
              </Button>
              <Button outline size="lg" onClick={updateLeavesApplied}>
                {isLeavesApplied ? "Applied Leaves" : "Leaves Balance"}
              </Button>
            </ButtonContainer>
          )}
        </EmptyScreenContainer>
      )}
      {isLoading && (
        <ViewContainer>
          <RectangleSkeleton times={4} />
        </ViewContainer>
      )}
    </MainContainer>
  );
};

export default Leave;
