import React, { useCallback, useState, useRef } from "react";
import Button from "components/Button";
import InputGroup from "components/InputGroup/inputGroup2";

import { getUserListData } from "common/Api";
import { statusOptions } from "../Create/options";
import DateInputModal from "./DateInputModal";

import { DateInputButton, StyledForm, AsyncSearchField } from "../../style/display";

const SearchFilter = ({ formData, dates, onSearch, resetForm }) => {
  const [startDateInputsAnchor, updateStartDateInputAnchor] = useState(null);
  const [endDateInputsAnchor, updateEndDateInputAnchor] = useState(null);
  const startDateInputContainerRef = useRef(null);
  const endDateInputContainerRef = useRef(null);

  const dateInputContent = (dateType, fromDate, toDate) => {
    let content = `Enter ${dateType} date`;
    if (fromDate) {
      content = `from ${fromDate} `
    }
    if (toDate) {
      content = `${fromDate ? content : ''}till ${toDate}`
    }
    return content;
  }

  const toggleStartDateInput = useCallback(() => {
    updateStartDateInputAnchor((val) => {
      return val ? null : startDateInputContainerRef.current;
    });
  }, []);

  const toggleEndDateInput = useCallback(() => {
    updateEndDateInputAnchor((val) => {
      return val ? null : endDateInputContainerRef.current;
    });
  }, []);

  return (
    <StyledForm onSubmit={onSearch} onReset={resetForm}>
      <AsyncSearchField>
        <InputGroup
          fieldType="asyncSearchSelect"
          placeholder="Search for users"
          api={getUserListData}
          singleSelect
          {...formData["leadBy"]}
        />
      </AsyncSearchField>

      <InputGroup
        fieldType="input"
        placeholder="Enter lead type"
        {...formData["leadType"]}
      />

      <InputGroup
        fieldType="select"
        options={statusOptions}
        defaultSelectText="Status"
        {...formData["status"]}
      />

      <DateInputButton onClick={toggleStartDateInput} ref={startDateInputContainerRef}>
        {dateInputContent("start", dates.fromStartDate, dates.toStartDate)}
      </DateInputButton>

      <DateInputButton onClick={toggleEndDateInput} ref={endDateInputContainerRef}>
        {dateInputContent("end", dates.fromEndDate, dates.toEndDate)}
      </DateInputButton>

      <DateInputModal
        onClose={toggleStartDateInput}
        elemIdOrElem={startDateInputsAnchor}
        formData={{ fromStartDate: formData["fromStartDate"], toStartDate: formData["toStartDate"] }}
      />

      <DateInputModal
        onClose={toggleEndDateInput}
        elemIdOrElem={endDateInputsAnchor}
        formData={{ fromEndDate: formData["fromEndDate"], toEndDate: formData["toEndDate"] }}
      />

      <Button primary type="submit">
        Search
      </Button>
      <Button outline type="reset">
        Reset
      </Button>
    </StyledForm>
  );
};

export default SearchFilter;
