import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useNodeDimensions from "hooks/useNodeDimensions";
import GenericModal from "../GenericModal";

import { Container, Triangle, StyledEmptyData } from "./style";

const TRIANGLE_WIDTH = 20;
const TRIANGLE_HEIGHT = 20;

const NavbarPopover = ({
  onClose,
  elemIdOrElem,
  modalClass = "",
  containerClass = "",
  children,
}) => {
  const [isActive, updateActiveStatus] = useState(false);
  const [myPos, updateMyPos] = useState({ top: 0, left: 0 });
  const [hasScrolled, updateScrolledStatus] = useState(false);
  const targetElemDimensions = useNodeDimensions({ elemIdOrElem });
  const containerRef = useRef(null);
  const tempDataRef = useRef({ hasScrolled: false });

  /**
   * sets the postion of the panel according to the target element position
   */

  const calculatePosition = useCallback(() => {
    if (targetElemDimensions && containerRef.current) {
      const { top, left, height, right } = targetElemDimensions;
      const source = containerRef.current.getBoundingClientRect();
      const startLeft = left - source.width / 2;
      const pos = {};
      const windowDim = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      if (startLeft + source.width > windowDim.width) {
        pos.right = windowDim.width - right;

        if (pos.right + source.width > windowDim.width) {
          pos.right = 0;
        }
      } else {
        pos.left = startLeft;
      }
      updateMyPos({ top: top + height, ...pos, maxWidth: windowDim.width });
    }
  }, [targetElemDimensions]);

  const setRef = useCallback(
    (ref) => {
      if (!ref) {
        return;
      }
      containerRef.current = ref;
      calculatePosition();
    },
    [calculatePosition]
  );

  const onScroll = useCallback((e) => {
    const { scrollTop } = e.target;

    if (scrollTop > 0) {
      if (!tempDataRef.current.hasScrolled) {
        updateScrolledStatus(true);
      }
      tempDataRef.current.hasScrolled = true;
    } else {
      tempDataRef.current.hasScrolled = false;
      updateScrolledStatus(false);
    }
  }, []);

  const togglePopover = useCallback((status) => {
    updateActiveStatus((val) => {
      if (typeof status === "boolean") {
        return status;
      }
      return !val;
    });
  }, []);

  /**
   * Toggle Popover based on parent elem availability
   */
  useEffect(() => {
    if (!targetElemDimensions) {
      togglePopover(false);
    } else {
      togglePopover(true);
    }
  }, [targetElemDimensions]);

  const trianglePos = useMemo(() => {
    if (!targetElemDimensions) {
      return {};
    }
    const { left, width, top, height } = targetElemDimensions;
    return {
      left: `${left + width / 2 - TRIANGLE_WIDTH / 2}px`,
      top: `${top + height - TRIANGLE_HEIGHT / 2}px`,
      width: `${TRIANGLE_WIDTH}px`,
      height: `${TRIANGLE_HEIGHT}px`,
    };
  }, [targetElemDimensions]);

  return isActive ? (
    <GenericModal onClose={onClose} className={modalClass}>
      <Container
        style={myPos}
        ref={setRef}
        onScroll={onScroll}
        className={containerClass}
      >
        {children && typeof children === "function" ? (
          children(hasScrolled)
        ) : (
          <StyledEmptyData />
        )}
      </Container>
      <Triangle $pos={trianglePos} />
    </GenericModal>
  ) : null;
};

NavbarPopover.defaultProps = {
  onClose: () => {},
};

export default NavbarPopover;
