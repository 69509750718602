import { getCurrentDataAttributes } from "helper/selectors";
import { createSelector } from "reselect";

const storeWorkReportData = (store, props) => store.teams.worklog.data;
const storeWorkReportIds = (store, props) => store.teams.worklog.dataIds;
const storeWorkReportsRelations = (store, props) =>
  store.teams.worklog.relation;

export const getAllTeamWorklog = createSelector(
  storeWorkReportData,
  storeWorkReportIds,
  storeWorkReportsRelations,
  (allReports, reportIds, relations) => {
    let data = [];
    reportIds.map((key) => {
      if (allReports[key] && typeof allReports[key] != "function") {
        const report = allReports[key];
        data.push(getCurrentDataAttributes(report, relations, allReports));
      }
    });
    return data;
  }
);
