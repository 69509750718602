import {
  CREATE_WORKSHIFT_TYPE,
  DELETE_WORKSHIFT_TYPE,
  EDIT_WORKSHIFT_TYPE,
  GET_ALL_WORKSHIFT_TYPES,
} from "./actionType";
import { createAsyncActionCreator } from "helper";
import {
  createWorkshiftTypeApi,
  deleteWorkshiftTypeApi,
  editWorkshiftTypeApi,
  getAllWorkshiftTypesApi,
} from "./api";

const createWorkshiftTypeGenrator = function* ({
  companyId,
  formData,
  isAdmin,
}) {
  yield {
    action: CREATE_WORKSHIFT_TYPE,
    sideEffect: createWorkshiftTypeApi({
      companyId,
      formData,
      isAdmin,
    }),
  };
};

const getAllWorkshiftTypesGenrator = function* ({
  pageNumber,
  companyId,
  isAdmin,
}) {
  yield {
    action: GET_ALL_WORKSHIFT_TYPES,
    sideEffect: getAllWorkshiftTypesApi({ pageNumber, companyId, isAdmin }),
  };
};

const editWorkshiftTypeGenrator = function* ({
  companyId,
  workshiftId,
  formData,
  status,
  isAdmin,
}) {
  yield {
    action: EDIT_WORKSHIFT_TYPE,
    sideEffect: editWorkshiftTypeApi({
      companyId,
      workshiftId,
      formData,
      status,
      isAdmin,
    }),
  };
};

const deleteWorkshiftTypeGenrator = function* ({
  workshiftId,
  companyId,
  isAdmin,
}) {
  yield {
    action: DELETE_WORKSHIFT_TYPE,
    sideEffect: deleteWorkshiftTypeApi({ workshiftId, companyId, isAdmin }),
  };
};

export const [
  createWorksihftType,
  getAllWorkshiftTypes,
  editWorkshiftType,
  deleteWorkshiftType,
] = createAsyncActionCreator([
  createWorkshiftTypeGenrator,
  getAllWorkshiftTypesGenrator,
  editWorkshiftTypeGenrator,
  deleteWorkshiftTypeGenrator,
]);
