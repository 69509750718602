import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { getOncefetched } from "common/Selectors/store";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { createTableDataSelector } from "common/Selectors/MTable";

import usePaginator from "hooks/usePaginator";
import usePagination from "hooks/usePagination";

import { fetchComments } from "../../redux/PostComments/actionCreator";

import { transformCommentsMatrixToArray } from "../../utils";

const mockComments = [
  {
    id: "1",
    userName: "Mark ANtony",
    designation: "Engineer",
    comment: "I am okay",
  },
];

function useFetchComments({ postId }) {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    allPagesFetched,
    errorOccured,
  } = usePagination({
    actionCreator: fetchComments,
    tableName: `postComments.${postId}`,
  });

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const [commentsSelector, onceFetchedSelector] = useMemo(() => {
    return [
      createTableDataSelector({
        tableName: `postComments.${postId}`,
        blackList: { "Sl No": true },
        reverse: true,
        fetchAllData: true,
      }),
      getOncefetched(`postComments.${postId}`),
    ];
  }, [postId]);

  const isOnceFetched = useSelector(onceFetchedSelector);
  const { rows: data, rowIdMap } = useSelector(commentsSelector);

  const { fetchFirstPage, fetchNextPage } = usePaginator({
    paginatorApi: getNextPage,
    extraParams: { companyId, isAdmin, postId },
  });

  useEffect(() => {
    if (!isOnceFetched) {
      fetchFirstPage();
    }
  }, [isOnceFetched]);

  const userComments = useMemo(() => {
    return transformCommentsMatrixToArray(data, rowIdMap);
  }, [data]);

  return {
    isLoading: loadingNextPage || loadingPrevPage,
    comments: userComments,
    fetchNextPage,
    allCommentsLoaded: allPagesFetched,
    hasError: errorOccured,
  };
}

export default useFetchComments;
