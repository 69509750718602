import styled from "styled-components";
export const PieChartContainer = styled.div`
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  box-shadow: 0px 4.86856px 27.2639px rgba(0, 0, 0, 0.07);
  justify-content: center;
  flex-direction: column;
`;

export const Heading = styled.h3`
  margin: 15px 0px;
  padding: 0px;
`;
export const LegendItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LegendContainer = styled.div`
  border: 1.38312px solid rgba(1, 0, 138, 0.12);
  border-radius: 9px;
  padding: 30px;
`;
export const Square = styled.div`
  height: 10px;
  width: 10px;
  position: absolute;
  z-index: 10;
  background: ${(props) => props.$color};
`;

export const SquareOuter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  background: ${(props) => props.$color}30;
`;

export const LegendName = styled.p`
  margin-right: 30px;
  color: ${(props) => props.$color};
`;

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  margin-left: 10px;
  align-items: center;
  justify-content: space-between;
`;
