import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import InputGroup from "components/InputGroup";
import Button from "components/Button";

import { getUserListData } from "common/Api";
import { expenseTypeOptions, statusOptions } from "../Create/options";
import { updateSearchParams, resetSearchParams } from "../../actionCreator";

import { StyledForm, AsyncSearchField, ButtonGroup } from "../../style/display";
import { isDateCombinationValid } from "../../../../Helper";
import { generateSearchFormData } from "../../utils";

const SearchFilter = ({
  updateSearchParams,
  resetSearchParams,
  collaborator_id,
  expense_type,
  from_date,
  to_date,
  status,
  isAdmin,
}) => {
  const [startDate, setStartDate] = useState("");
  const [InvalidMessage, setInvalidMessage] = useState("");

  const onStartDateChange = useCallback((e) => {
    setStartDate(e.target.value);
  }, []);

  const endDateValidator = useCallback(
    (endDate) => {
      if (startDate) {
        const { isValid, message } = isDateCombinationValid(
          startDate,
          endDate,
          "Start",
          "End"
        );
        setInvalidMessage(message);
        return isValid;
      }
      return true;
    },
    [startDate]
  );

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      const data = generateSearchFormData(values);
      updateSearchParams({ filterData: data });
    },
    [],
  );

  const onResetClick = () => {
    resetSearchParams();
    setStartDate("");
  }

  return (
    <>
      <StyledForm onSubmit={onFormSubmit} onReset={onResetClick}>
        {isAdmin &&
          <AsyncSearchField>
            <InputGroup
              id="reimbursementSearch_reimbursementAssignee"
              fieldType="asyncSearchSelect"
              placeholder="Search for users"
              api={getUserListData}
              singleSelect
            />
          </AsyncSearchField>
        }

        <InputGroup
          id="reimbursementSearch_reimbursementType"
          fieldType="select"
          options={expenseTypeOptions}
          defaultSelectText="Select Expense Type"
        />

        <InputGroup
          id="reimbursementSearch_reimbursementFromDate"
          fieldType="datePicker"
          placeholder="Start date"
          onChange={onStartDateChange}
        />

        <InputGroup
          id="reimbursementSearch_reimbursementToDate"
          fieldType="datePicker"
          placeholder="End date"
          validator={endDateValidator}
          invalidMessage={InvalidMessage}
        />

        <InputGroup
          id="reimbursementSearch_reimbursementStatus"
          fieldType="select"
          options={statusOptions}
          defaultSelectText="Select Status"
        />
        <ButtonGroup>
          <Button primary type="submit" size="lg">
            Search
          </Button>
          <Button
            outline
            disabled={!collaborator_id && !expense_type && !from_date && !to_date && !status}
            type="reset"
            size="lg"
          >
            Reset
          </Button>
        </ButtonGroup>
      </StyledForm>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSearchParams,
      resetSearchParams,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SearchFilter);
