import React, { useMemo, useState } from "react";

import ProfilePicTagLists from "./ProfilePicTagLists";
import ProfileSkeletonGroup from "./ProfileSkeletonGroup";

import {
  useFetchTodayJoinees,
  useFetchUpcomingJoinees,
} from "../../../../hooks/Joinees";

import Intern_Img from "assets/employee/intern.png";
import Briefcase_Img from "assets/employee/briefcase.png";

import { InfoContent, InfoHeader, InfoItem, BigCakeImage } from "./style";

function NewJoineeSection() {
  const {
    isLoading: loadingTodayJoinees,
    allTodayJoinees,
    hasError: errorInToday,
    fetchNextPage: fetchNextPageToday,
    allDataFethced: todayAllDataFethced,
  } = useFetchTodayJoinees();
  const {
    isLoading: loadingUpcomingJoinees,
    allUpcomingJoinees,
    hasError: errorInUpcoming,
    fetchNextPage: fetchNextPageUpComing,
    allDataFethced: upcomingAllDataFethced,
  } = useFetchUpcomingJoinees();

  const loadMoreData = (type) => {
    if (type === "today") {
      fetchNextPageToday();
    } else if (type === "upcoming") {
      fetchNextPageUpComing();
    }
  };

  const isEmpty = (value) => {
    if (!Array.isArray(value) || value.length === 0) {
      return true;
    }
    return false;
  };

  const isTodayEmpty = useMemo(() => {
    return isEmpty(allTodayJoinees);
  }, [allTodayJoinees]);

  const isUpcomingEmpty = useMemo(() => {
    return isEmpty(allUpcomingJoinees);
  }, [allUpcomingJoinees]);
  return (
    <>
      <InfoItem>
        <InfoHeader>New Joinees today</InfoHeader>
        <InfoContent>
          {!loadingTodayJoinees && isTodayEmpty && (
            <>
              <BigCakeImage src={Intern_Img} alt="Intern sitting" />
              <span className="desc">There is no one to join today</span>
            </>
          )}
          {!errorInToday && allTodayJoinees.length > 0 && (
            <ProfilePicTagLists
              data={allTodayJoinees}
              tagImage={Briefcase_Img}
              showMore={!todayAllDataFethced}
              onMoreClick={() => loadMoreData("today")}
            />
          )}
          {loadingTodayJoinees && <ProfileSkeletonGroup count={3} />}
        </InfoContent>
      </InfoItem>

      <InfoItem>
        <InfoHeader>Next 7 days</InfoHeader>
        <InfoContent>
          {!loadingUpcomingJoinees && isUpcomingEmpty && (
            <>
              <BigCakeImage src={Intern_Img} alt="Intern sitting" />
              <span className="desc">
                There is no one to join in upcoming days
              </span>
            </>
          )}
          {!errorInUpcoming && allUpcomingJoinees.length > 0 && (
            <ProfilePicTagLists
              data={allUpcomingJoinees}
              tagImage={Briefcase_Img}
              showMore={!upcomingAllDataFethced}
              onMoreClick={() => loadMoreData("upcoming")}
            />
          )}
          {loadingUpcomingJoinees && <ProfileSkeletonGroup count={3} />}
        </InfoContent>
      </InfoItem>
    </>
  );
}

export default NewJoineeSection;
