import styled from "styled-components";
import Button from "../Button";

export const BackBtn = styled(Button)`
  // width: 50px;
  // height: 50px;
  // border-radius: 50%;
  // box-shadow: ${(props) => props.theme.shadow.medium};
  font-size: ${(props) => props.theme.text.para.small};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  color: ${(props) => props.theme.colors.button.outline.color};
  background-color: ${(props) => props.theme.colors.button.outline.background};
  border: solid 1px ${(props) => props.theme.colors.button.outline.border};
  font-weight: bold;
  padding: 10px 20px;
  z-index: ${(props) => props.theme.zIndex.slider};

  ${({ onTop }) => onTop ? `top: 10px;` : `bottom: 30px;`}
  ${({ onLeft }) => onLeft ? `left: 10px;` : `right: 30px;`}

  & i {
    margin-right: 10px;
  }
`;
