import React from "react";

import { HolidayCard, AttendanceCard } from "./Cards";

import { LeftContainer } from "../../styles/employee";

function LeftContent() {
  return (
    <LeftContainer className="d-f f-c">
      <HolidayCard />
      <AttendanceCard />
    </LeftContainer>
  );
}

export default LeftContent;
