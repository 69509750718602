import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import Button from "components/Button";
import { RectangleSkeleton } from "components/Skeleton";

import ActionArea from "./ActionArea";
import TableData from "./TableData";

import EmptyScreen from "assets/employee/LeadBlank.png";
import { isCurrentUserIsExecutive } from "common/Selectors/auth";

import { ViewContainer } from "globalStyles";
import { EmptyScreenContainer, EmptyScreenImage, EmptyScreenMessage } from "../../../../style/display";

const AssignLeaveView = ({ updateFormVisibility, isAdmin }) => {
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  return (
    <ViewContainer>
      <ActionArea
        onLoading={onDbDataChange}
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        updateFormVisibility={updateFormVisibility} />
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        onEmptyData={onEmptyData}
      />
      {!isLoading && isEmpty && (
        <EmptyScreenContainer>
          <EmptyScreenImage src={EmptyScreen} alt="No records found" />
          <EmptyScreenMessage>
            No leaves assigned yet
          </EmptyScreenMessage>
          {isAdmin &&
            <Button primary size="lg" onClick={updateFormVisibility}>
              Assign
            </Button>
          }
        </EmptyScreenContainer>
      )
      }
      {isLoading && <RectangleSkeleton times={4} />}
    </ViewContainer>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { isAdmin };
};

export default connect(mapStateToProps, null)(AssignLeaveView);
