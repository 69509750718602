import styled from "styled-components";
import Icon from "components/Icon";
import { Link } from "react-router-dom";

export const Container = styled(Link)`
  flex-basis: 300px;
  flex-grow: 1;
  min-height: 178px;
  padding: 25px;
  border-radius: 15px;
  background-color: #e7fdfa;
  position: relative;
  text-decoration: none;
`;

export const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

export const TabName = styled.span`
  font-size: ${(props) => props.theme.text.title.large};
  font-weight: 600;
  text-align: left;
  margin-bottom: 5px;
  text-transform: capitalize;
`;

export const TabDescription = styled.span`
  opacity: 0.6;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  font-weight: 500;
  text-align: left;
  color: #000000;
`;

export const ImageBox = styled.div`
  width: 16px;
  height: 20px;
  position: absolute;
  top: 28px;
  right: 33px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const ForwardIconBox = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 26px;
  right: 33px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ForwardIcon = styled(Icon)`
  font-size: 8px;
  color: white;
`;
