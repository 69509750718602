import {
  GET_NEW_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  MARK_NOTIFICATION_AS_READ,
} from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import { fetchNotificationsApi, markAllAsReadApi, markAsReadApi } from "./api";

const fetchNotificationsGenrator = function* ({
  companyId,
  pageNumber,
  isAdmin,
}) {
  yield {
    action: GET_NEW_NOTIFICATIONS,
    sideEffect: fetchNotificationsApi({ companyId, pageNumber, isAdmin }),
  };
};

const markAllNotificationsAsReadGenrator = function* ({ companyId, isAdmin }) {
  yield {
    action: MARK_ALL_NOTIFICATIONS_AS_READ,
    sideEffect: markAllAsReadApi({ companyId, isAdmin }),
  };
};

const markNotificationAsReadGenrator = function* ({
  companyId,
  isAdmin,
  noticeId,
}) {
  yield {
    action: MARK_NOTIFICATION_AS_READ,
    sideEffect: markAsReadApi({ companyId, isAdmin, noticeId }),
  };
};

export const [
  fetchNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead,
] = createAsyncActionCreator([
  fetchNotificationsGenrator,
  markAllNotificationsAsReadGenrator,
  markNotificationAsReadGenrator,
]);
