import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";

import { updateLedgerTransaction } from "../../actionCreator";

function useUpdateLedgerTransactions() {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const categoryId = useSelector((store) => store.ledger.categories.ledgerId);
  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const updateTransactions = async (formData, transactionId, onFormReset) => {
    try {
      updateLoading(true);
      await dispatch(
        updateLedgerTransaction({
          categoryId,
          companyId,
          isAdmin,
          transactionId,
          formData,
        })
      );
      showAlert({ message: "Transaction updated" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
  };
  return { isLoading, updateTransactions };
}

export default useUpdateLedgerTransactions;
