import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Status from "components/Status";

import {
  TableWrapper,
  TableContent,
  TableHeader,
  Renderers,
  TableTabs,
  TablePaginator,
} from "../../../../Common/Components/Table";

import { getLedgerTransactions } from "../../actionCreator";
import { ledgerTransactionsSelector } from "modules/Ledger/selectors";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";

import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";
import { getStatusType } from "helper";
import {
  CollabroratorCell,
  TableHeaderContent,
} from "modules/Ledger/style/display";

export const DetailsTable = () => {
  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const ledgerStoreData = useSelector((store) => store.ledger);
  const transactions = useSelector(ledgerTransactionsSelector);
  const { onceFetched, ledgerId, collaboratorId, totalCount } = useMemo(() => {
    return {
      onceFetched: ledgerStoreData.transactions.onceFetched,
      ledgerId: ledgerStoreData.categories.ledgerId,
      collaboratorId: ledgerStoreData.transactions.collaboratorId,
      totalCount: ledgerStoreData.transactions.totalCount,
    };
  }, [ledgerStoreData]);

  const { getNextPage, totalPages, currentPageNumber } = usePagination({
    actionCreator: getLedgerTransactions,
    tableName: "ledger.transactions",
  });

  const extraParams = {
    companyId,
    ledgerId,
    collaboratorId,
    isAdmin,
  };

  const { fetchFirstPage, fetchNextPage, fetchPrevPage } = usePaginator({
    extraParams,
    paginatorApi: getNextPage,
  });

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  const cellRenderer = ({ property, value, rowIdx, columnIdx, allData }) => {
    if (property === "status") {
      return (
        <Status
          text={value}
          type={getStatusType(value)}
          style={{ minWidth: "100px", padding: "6px" }}
        />
      );
    }

    if (property === "amount") {
      const isPositive = value.includes("+");
      return (
        <span
          style={{
            color: isPositive ? "green" : "red",
            fontWeight: "bold",
            minWidth: "100px",
          }}
        >
          {value}
        </span>
      );
    }

    if (property === "invoiceUrls") {
      return value ? <Renderers.MultipleImageViewer data={value} /> : "NA";
    }

    if (property === "collaborator") {
      return (
        <CollabroratorCell>
          {allData.colorCode && (
            <div
              className="bar"
              style={{ backgroundColor: allData.colorCode }}
            />
          )}
          <Renderers.CollaboratorPopover data={value} />
        </CollabroratorCell>
      );
    }
    return null;
  };

  const generateColumnOrder = ({ keys }) => {
    return [
      "collaborator",
      "expenseType",
      "amount",
      "status",
      "dateRange",
      "notes",
      "invoiceUrls",
    ];
  };
  const isLastPage = totalPages === currentPageNumber;
  const isFirstPage = currentPageNumber === 1;

  const tabs = [
    { id: "all", data: "All" },
    { id: "pending", data: "Pending" },
  ];

  return (
    <TableWrapper>
      <TableHeader showPagninator={false}>
        {/* Latest Transactions - {totalCount} */}
        <TableHeaderContent>
          <TableTabs tabs={tabs} activeId="all" />
          <TablePaginator
            style={{ marginLeft: "auto" }}
            onPrev={fetchPrevPage}
            onNext={fetchNextPage}
            nextBtnProps={{
              style: {
                cursor: isLastPage ? "not-allowed" : "pointer",
                opacity: isLastPage ? 0.5 : 1,
              },
            }}
            prevBtnProps={{
              style: {
                cursor: isFirstPage ? "not-allowed" : "pointer",
                opacity: isFirstPage ? 0.5 : 1,
              },
            }}
          />
        </TableHeaderContent>
      </TableHeader>
      <TableContent
        data={transactions}
        cellRenderer={cellRenderer}
        generateColumnOrder={generateColumnOrder}
        maxColumnWidth={130}
        // maxColumnsVisible={3}
        blacklistedColumnKeys={{
          id: true,
          colorCode: true,
        }}
      />
    </TableWrapper>
  );
};
