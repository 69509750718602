import Icon from "components/Icon";
import styled from "styled-components";
import { ImageComponent } from "../../../Common/Components/Image";

export const EmptyScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    height: 35px;
  }
`;

export const EmptyScreenImage = styled(ImageComponent)`
  width: 40%;
  height: 40%;
  object-fit: contain;
`;

export const EmptyScreenMessage = styled.span`
  margin: 30px;
  font-size: ${(props) => props.theme.text.title.medium};
`;

export const TabContainer = styled.div`
  width: 100%;
  margin: auto;
  height: 100%;
`;

export const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-flow: column;
`;

export const StyledPaginatorCont = styled.div`
  flex-basis: 150px;
`;

export const StyledActionArea = styled.div`
  margin: 10px 0;
  padding: 10px;
  display: flex;
  flex-basis: 50px;
  width: 100%;
`;

export const FormButtons = styled.div`
  flex-basis: 200px;
  display: flex;
  margin-bottom: 23px;
  & > button + button {
    margin-left: 10px;
  }

  & > button {
    height: 32px;
  }
`;

export const FormCont = styled.form`
  flex-grow: 1;
  display: flex;
  align-items: center;
  & > div {
    margin-right: 10px;
    & > div {
      height: 32px;
      & > button,
      & > select {
        padding: 0 0 0 13px;
      }
    }
  }
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
`;

export const CellIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.primaryColor};
  margin-left: 10px;
  font-size: 15px;
`;
