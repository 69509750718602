import React, { useMemo } from "react";

import { Comment as PostComment } from "./Comment";

import { CommentListContainer } from "./style";

export function CommentList({ comments, maxToShow, ...rest }) {
  const commentsList = useMemo(() => {
    if (!Array.isArray(comments) || comments.length === 0) {
      return null;
    }

    let total = 0;
    if (maxToShow === "*" || comments.length < maxToShow) {
      total = comments.length;
    } else {
      total = maxToShow;
    }

    const result = [];
    for (let i = 0; i < total; i++) {
      const data = comments[i];
      result.push(
        <PostComment
          style={{ animationDelay: `${(i + 1) * 100}ms` }}
          key={data.id}
          {...data}
        />
      );
    }
    return result;
  }, [comments, maxToShow]);

  return <CommentListContainer {...rest}>{commentsList}</CommentListContainer>;
}

CommentList.defaultProps = {
  maxToShow: "*",
};
