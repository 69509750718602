import styled from "styled-components";

export const RadioContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
  align-items: ${(props) => (props.horizontal ? "center" : "flex-start")};
`;

export const RadioBoxWrapper = styled.div`
  width: auto;
`;

export const RadioBox = styled.label`
  min-width: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${(props) => {
    if (props.disabled) {
      return `
          cursor: not-allowed;
          color: lightgray;
          `;
    }
    return ``;
  }}
  &:hover > .circle_out > .overlay {
    transform: scale(2);
  }
`;

export const RadioLabel = styled.span`
  margin-left: 10px;
  font-size: ${(props) => props.theme.text.para.small};
  text-transform: capitalize;
`;

export const CircleWrapper = styled.div`
  width: ${(props) => props.theme.dimensions.radio.width};
  height: ${(props) => props.theme.dimensions.radio.height};
  position: relative;
`;

export const CircleBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px
    ${(props) => {
      return props.checked
        ? props.theme.colors.radio.activeBorderColor
        : props.theme.colors.radio.borderColor;
    }}
    solid;
  z-index: 0;
  background-color: white;
`;

export const Circle = styled.div`
  width: calc(${(props) => props.theme.dimensions.radio.width} / 2);
  height: calc(${(props) => props.theme.dimensions.radio.height} / 2);
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.radio.activeBorderColor};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.radio.overlay};
  transform: scale(1);
  opacity: 0.2;
  transition: transform ease 0.3s;
`;
