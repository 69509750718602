import styled from "styled-components";
import Icon from "../Icon";
import Button from "../Button";

const getHeight = (props) => {
  const { size, fillParent } = props;
  if (fillParent) {
    return "100%";
  }
  switch (size) {
    case "sm":
      return "30px";
    case "md":
      return "50px";
    case "lg":
      return "80px";
    default:
      return "auto";
  }
};

const getBorder = (props) => {
  const { success, error, secondary, theme, $borderless } = props;
  const {
    colors: { alert, input }
  } = theme;

  if ($borderless) {
    return "none";
  }

  if (success) {
    return `2px ${alert.success} solid !important`;
  } else if (error) {
    return `2px ${alert.error} solid !important`;
  } else if (secondary) {
    return `1px ${input.secondary.borderColor} solid;`;
  } else {
    return `1px ${input.primary.borderColor} solid `;
  }
};

const getIconColor = (props) => {
  const { success, error, theme } = props;
  const {
    colors: { alert }
  } = theme;
  if (success) {
    return alert.success;
  } else if (error) {
    return alert.error;
  } else {
    return "black";
  }
};

export const InputContainer = styled.div`
  --input-padding: 10px;
  width: 100%;
  display: block;
  position: relative;
  border-radius: 5px;
  margin-bottom: 6px;
  height: ${getHeight};
`;

export const GlobalInput = styled.input`
  width: 100%;
  padding: 13px 35px 13px 20px;
  height: 100%;
  border-radius: ${(props) => (props.secondary ? "3px" : "6px")};
  border: none;

  ${(props) => {
    if (props.type == "password") return `padding-right: 65px;`;
  }}

  ${(props) => {
    if (props.$borderPosition) {
      return `
      border-${props.$borderPosition}: ${getBorder(props)};
      border-radius: 0px;
      `;
    }
    return `border: ${getBorder(props)};`;
  }}
  font-weight: 400;
  color: ${(props) => props.theme.colors.input.textColor};
  box-sizing: border-box;
  display: block;
  ${({ showOutline }) => {
    if (!showOutline) {
      return `
      &:focus {
        outline: none;
      }
      `;
    }
  }}

  ${(props) => {
    if (props.secondary) {
      return `
            background-color: white;
            color: ${props.theme.colors.input.secondary.textColor};
            font-weight: 500;
          `;
    }
    return ``;
  }}

  ${(props) => {
    if (props.isTextArea) {
      return `
      resize: none;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          `;
    }

    return ``;
  }}
`;

export const ColorInput = styled.input`
  max-width: 95%;
  height: 100%;
  border: none;
  width: 100%;
  background-color: transparent;
`;

export const InputIcon = styled(Icon)`
  position: absolute;
  font-size: 19px;
  right: 10px;
  top: calc(50% - 10px);
  color: ${getIconColor};
`;

export const SecondaryInputIcon = styled(InputIcon)`
  right: 35px;
  color: ${(props) => props.theme.colors.darkGrayColor}aa;
`;

export const ClearButton = styled(Button)`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  right: 10px;
  top: 10px;
`;

export const SelectOption = styled.option`
  padding: 10px;
  font-size: ${(props) => props.theme.text.para.medium};
`;
