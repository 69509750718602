import React, { useCallback, useMemo, useState } from "react";
import TopBar from "./Topbar";
import ActionArea from "./ActionArea";
import TableData from "./TableData";

import InfoView from "components/InfoView";
import EmptyScreen from "assets/employee/AttendenceBlank.png";
import { RectangleSkeleton } from "components/Skeleton";

import { Container } from "../../style/display";
import AttendenceList from "./AttendanceList";
import PageSlider from "components/PageSlider";
import { usePageSlider } from "hooks/usePageSlider";
import Icon from "components/Icon";
import Button from "components/Button";
import AttendanceDetailsView from "../Details";

const Attendence = () => {
  const [toggleAttendanceList, setAttendanceList] = useState(false);
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const { onCellClick, isSliderActive, toggleSlider, data } = usePageSlider();

  const onShowAttendanceList = useCallback(() => {
    setAttendanceList((val) => !val);
  }, []);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  const CurrentTab = useMemo(() => {
    return (
      <Container>
        <TopBar onShowAttendanceList={onShowAttendanceList} />
        {toggleAttendanceList ? (
          <AttendenceList />
        ) : (
          <>
            <ActionArea onLoading={onDbDataChange} />
            <TableData
              onCellClick={onCellClick}
              style={{
                display: isLoading || isEmpty ? "none" : "inherit",
              }}
              onEmptyData={onEmptyData}
            />
            {!isLoading && isEmpty && (
              <InfoView
                image={EmptyScreen}
                desc={"There are no attendence to display yet"}
              />
            )}
            {isLoading && <RectangleSkeleton times={4} />}
          </>
        )}
      </Container>
    );
  }, [toggleAttendanceList, onDbDataChange, isLoading, isEmpty, onEmptyData]);

  return (
    <>
      {CurrentTab}
      <PageSlider onClose={toggleSlider} active={isSliderActive}>
        <AttendanceDetailsView rowId={data} />
      </PageSlider>
    </>
  );
};

export default Attendence;
