import { useRef, useCallback } from "react";

function useIntersectionObserver({ callback }) {
  const intersectionObserverRef = useRef(null);

  const removeObserver = () => {
    const { observer } = intersectionObserverRef.current || {};
    observer && observer.disconnect();
  };

  const OnIntersect = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          typeof callback === "function" && callback();
        }
      });
    },
    [callback]
  );

  const connectObserver = ({ targetId, rootId, options = {} }) => {
    // null refers to viewPort by default
    const rootElem = rootId ? document.querySelector(rootId) : null;

    const targetElem = document.querySelector(targetId);
    if (!targetElem) {
      return;
    }

    let observerOptions = {
      root: rootElem,
      rootMargin: "0px",
      threshold: 1.0,
      ...options,
    };
    let observer = new IntersectionObserver(OnIntersect, observerOptions);
    observer.observe(targetElem);
    intersectionObserverRef.current = { observer, targetElem };
  };

  return [connectObserver, removeObserver];
}

export default useIntersectionObserver;
