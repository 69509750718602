import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import SearchSelect from "../SearchSelect";
import { debounce } from "helper";
import useAlert from "hooks/useAlert";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../Selectors/auth";

import { transformRawDataToListData } from "./utils";

const AsyncSearchSelect = ({
  api,
  data,
  comapnyId,
  isExecutive,
  value,
  ...restProps
}) => {
  const [isLoading, updateLoading] = useState(false);
  const [searchText, updateSearchText] = useState("");
  const [currentListData, updateListData] = useState([...(data || [])]);
  const { showAlert } = useAlert();
  const tempRef = useRef({});
  const prevValueRef = useRef(value);

  let searchFunction = useRef(null);

  const fetchData = async (textToSearch) => {
    try {
      updateLoading(true);
      const response = await api({
        searchText: textToSearch,
        isAdmin: isExecutive,
        companyId: comapnyId,
      });

      updateListData(transformRawDataToListData(response.data));
    } catch (err) {
      showAlert({ message: "Something went wrong", type: "error" });
    } finally {
      updateLoading(false);
    }
  };

  const onSearch = useCallback((e, shouldFetchData = true) => {
    const val = e.target.value;
    tempRef.current["shouldFetchData"] = shouldFetchData;
    updateSearchText(val);
  }, []);

  const emptySearchInput = useCallback(() => {
    updateSearchText("");
  });

  if (!searchFunction.current) {
    searchFunction.current = debounce(fetchData, 200);
  }

  useEffect(() => {
    if (searchText && tempRef.current["shouldFetchData"]) {
      searchFunction.current(searchText);
    }
  }, [searchText]);

  useEffect(() => {
    if (prevValueRef.current !== value && !value) {
      emptySearchInput();
    }
    prevValueRef.current = value;
  }, [value]);

  return (
    <SearchSelect
      data={currentListData}
      isLoading={isLoading}
      onSearch={onSearch}
      searchValue={searchText}
      value={value}
      emptySearchInput={emptySearchInput}
      {...restProps}
    />
  );
};

const mapStateToProps = (store, props) => {
  return {
    comapnyId: getCurrentUserCompany(store, props),
    isExecutive: isCurrentUserIsExecutive(store, props),
  };
};

export default connect(mapStateToProps, null)(AsyncSearchSelect);
