import React from "react";
import AdvancedRoute from "components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import HolidayYearCreateView from "../../../Modules/HolidayYear/Views/Create";
import HolidayYearDisplayView from "../../../Modules/HolidayYear/Views/Display";

import { createRouteObject } from "../../../Helper";

const { holidayYear } = RoutePaths.authorized;

const pages = createRouteObject(
  holidayYear,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [HolidayYearCreateView, HolidayYearDisplayView]
);

const HolidayYearPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact
        path={item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default HolidayYearPages;
