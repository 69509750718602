import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";

import { createWorksihftDetail, editWorkshiftDetail } from "../actionCreator";

function useCreateWorkshiftDetail() {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const createWorkshiftDetailData = async (
    values,
    onFormReset,
    workshiftId
  ) => {
    try {
      updateLoading(true);
      await dispatch(
        createWorksihftDetail({
          companyId,
          workshiftId,
          isAdmin,
          formData: values,
        })
      );
      showAlert({ message: "Workshift Collaborator Created" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
  };
  const editWorkshift = async (
    values,
    onFormReset,
    collaborator_workshift_id,
    workshiftId
  ) => {
    try {
      updateLoading(true);
      await dispatch(
        editWorkshiftDetail({
          companyId,
          isAdmin,
          formData: values,
          workshiftId,
          collaborator_workshift_id,
        })
      );
      showAlert({ message: "Workshift Updated" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
  };
  return { isLoading, createWorkshiftDetailData, editWorkshift };
}

export default useCreateWorkshiftDetail;
