import React, { useEffect, useImperativeHandle } from "react";
import { QuestionCard } from "./QuestionCard";

import InputGroup from "../InputGroup/inputGroup2";
import useForm from "hooks/useForm";

import { QuestionContainer, Question, OptionsBlock } from "./style";

const Feedback = React.forwardRef(
  ({ question, required, initialValue, inputProps, ...rest }, ref) => {
    const { formData, values, isFormValid, updateField } = useForm({
      fields: {
        answere: { required },
      },
    });

    useImperativeHandle(ref, () => ({
      isFormValid,
      values,
      formType: "theory",
    }));

    useEffect(() => {
      //  setting initial value if any
      if (initialValue) {
        updateField("answere", initialValue);
      }
    }, [initialValue]);

    return (
      <QuestionContainer {...rest}>
        <Question>{question}</Question>
        <OptionsBlock>
          <InputGroup
            fieldType="textArea"
            wrapperStyle={{ height: "266px" }}
            fillParent={true}
            placeholder="Feedback ..."
            {...inputProps}
            {...formData["answere"]}
          />
        </OptionsBlock>
      </QuestionContainer>
    );
  }
);

Feedback.defaultProps = {
  inputProps: {},
  required: true,
};

export const FeedbackForm = (props) => {
  return <QuestionCard FormComponent={Feedback} {...props} />;
};
