import React from "react";
import { CreatedByCell, ActionCell } from "../Cells";
import { ObjectCellContent } from "./ObjectCellContent";

import { collaboratorFields } from "../../../../Constants";

export const ColumnCellContent = ({
  columnName,
  data,
  randomColor,
  validText,
  actionCellProps,
  containsOnlyImages,
  rowIndex,
}) => {
  if (collaboratorFields.includes(columnName.toLowerCase())) {
    return <CreatedByCell data={data} color={randomColor} />;
  }

  const { cellName, ...rest } =
    typeof actionCellProps === "function"
      ? actionCellProps(rowIndex)
      : actionCellProps || {};
  if (cellName && columnName.toLowerCase() === cellName.toLowerCase()) {
    return <ActionCell rowIndex={rowIndex} data={data} {...rest} />;
  }

  if (typeof data === "object") {
    return (
      <ObjectCellContent containsOnlyImages={containsOnlyImages} data={data} />
    );
  }

  return validText;
};
