import React, { useCallback, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup";
import Button from "components/Button";
import Icon from "components/Icon";
import useAlert from "hooks/useAlert";

import { createReimbursement } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive
} from "common/Selectors/auth";
import { getUserListData } from "common/Api";

import { expenseTypeOptions } from "./options";
import ConditionalForm from "./ConditionalForm";

import { StyledFormGroup } from "../../../../GlobalStyles";
import { FormHeading, Header } from "../../style/create";
import { generateFormData } from "../../utils";
import { validateNumber, isDateCombinationValid } from "helper";

const Form = ({
  setIsFormVisible,
  createReimbursement,
  isAdmin,
  companyId
}) => {
  const [isLoading, updateLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [InvalidMessage, setInvalidMessage] = useState("");
  const { showAlert } = useAlert();

  const onStartDateChange = useCallback((e) => {
    setStartDate(e.target.value);
  }, []);

  const endDateValidator = useCallback(
    (endDate) => {
      const { isValid, message } = isDateCombinationValid(
        startDate,
        endDate,
        "From",
        "To"
      );
      setInvalidMessage(message);
      return isValid;
    },
    [startDate]
  );

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const onCategoryChange = (e) => {
    if (e.target.value === "travel") setCategory("Travel");
    else setCategory("");
  };

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const data = generateFormData(values);
        await createReimbursement({ companyId, isAdmin, formData: data });
        showAlert({ message: `Reimbursement Created` });
        resetForm();
        setIsFormVisible(false);
      } catch (err) {
        showAlert({ message: "Reimbursement Creation Failed", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [companyId]
  );

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Add your expense</FormHeading>
        {!isAdmin && <Icon name="fas fa-times" onClick={updateFormVisibilty} />}
      </Header>

      {isAdmin && (
        <InputGroup
          id="reimbursementCreate_reimbursementAssignee"
          fieldType="asyncSearchSelect"
          placeholder="Search for users"
          api={getUserListData}
          singleSelect
          required
        />
      )}

      <InputGroup
        id="reimbursementCreate_reimbursementExpenseType"
        fieldType="select"
        required
        options={expenseTypeOptions}
        defaultSelectText="Select Type"
        label="Expense type"
        onChange={onCategoryChange}
      />

      <InputGroup
        id="reimbursementCreate_reimbursementFromDate"
        fieldType="datePicker"
        required
        placeholder="Enter from date dd/mm/yy"
        label="From date"
        onChange={onStartDateChange}
      />

      <InputGroup
        id="reimbursementCreate_reimbursementToDate"
        fieldType="datePicker"
        required
        placeholder="Enter to date dd/mm/yy"
        label="To date"
        validator={endDateValidator}
        invalidMessage={InvalidMessage}
      />

      <InputGroup
        id="reimbursementCreate_reimbursementDescription"
        fieldType="textArea"
        required
        placeholder="Enter description"
        label="Description"
        size="lg"
      />

      <InputGroup
        id="reimbursementCreate_reimbursementAmount"
        inputMode="number"
        required
        placeholder="Enter amount  &#8377; XXXX"
        label="Amount"
        validator={validateNumber}
        invalidMessage="Enter a valid amount"
      />

      {category && <ConditionalForm />}

      <InputGroup
        id="reimbursementCreate_reimbursementInvoices"
        fieldType="googleFilePicker"
        accept="image/*, application/pdf"
        multiple={true}
        required
        label="Invoices"
      />

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { companyId, isAdmin };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createReimbursement
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
