import React, { useEffect, useState } from "react";
import UpdatePassword from "./UpdatePasswordPage";
import PageTwo from "./PageTwo";
import {
  ViewPortContainer,
  Container,
  BackgroundImage,
  GroupImage,
} from "../styles";
import GreenIrregular from "../assets/GreenIrregular.png";
import Group from "../assets/Group.png";

const UpdatePasswordPage = () => {
  const [nextPageStatus, setNextPageStatus] = useState(false);
  const [wasItSuccessful, setSuccessfulStatus] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, [window.location.href]);

  return (
    <ViewPortContainer>
      <BackgroundImage src={GreenIrregular} />
      <GroupImage src={Group} />
      <Container nextPageStatus={nextPageStatus}>
        {!nextPageStatus ? (
          <UpdatePassword
            setNextPageStatus={setNextPageStatus}
            setSuccessfulStatus={setSuccessfulStatus}
            currentUrl={currentUrl}
          />
        ) : (
          <PageTwo
            wasItSuccessful={wasItSuccessful}
            setNextPageStatus={setNextPageStatus}
          />
        )}
      </Container>
    </ViewPortContainer>
  );
};
export default UpdatePasswordPage;
