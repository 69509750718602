import React from "react";
import { Container, Text, StyledIcon } from "./style";

const SomethingWrong = ({ className, ...other }) => {
  return (
    <Container {...other} className={className}>
      <StyledIcon name="fas fa-exclamation-circle" />
      <Text>Something Went wrong !!</Text>
    </Container>
  );
};
export default SomethingWrong;
