import Like_Img from "assets/employee/Reactions/like.svg";
import Love_Img from "assets/employee/Reactions/love.svg";
import Celebrate_Img from "assets/employee/Reactions/celebrate.svg";
import Insightful_Img from "assets/employee/Reactions/insightful.svg";
import Support_Img from "assets/employee/Reactions/support.svg";
import Curious_Img from "assets/employee/Reactions/curious.svg";

export const MAX_MORE_OPTIONS = 2;
export const PollOptions = [
  { name: "1 day", value: 1 },
  { name: "3 days", value: 3 },
  { name: "1 week", value: 7 },
  { name: "2 weeks", value: 14 },
];

export const postTypes = {
  post: "POST",
  poll: "POLL",
  quiz: "QUIZ",
};

export const postReactionImages = {
  like: Like_Img,
  insightful: Insightful_Img,
  love: Love_Img,
  curious: Curious_Img,
  celebrate: Celebrate_Img,
  support: Support_Img,
};

const convertPostReactionToArray = (postReactionImages) => {
  return Object.entries(postReactionImages).map(([key, value]) => {
    return { id: key, image: value };
  });
};

export const postReactions = convertPostReactionToArray(postReactionImages);
