import { useCallback, useState } from "react";

// A hook for Page Slider
// Returns current component data and slider status

export const usePageSlider = () => {
  const [isSliderActive, updateSliderStatus] = useState(false);
  const [data, updateData] = useState(null);

  const onCellClick = useCallback((data) => {
    updateData(data);
    toggleSlider();
  }, []);

  const toggleSlider = useCallback(() => {
    updateSliderStatus((val) => !val);
  }, []);

  return {
    onCellClick,
    toggleSlider,
    isSliderActive,
    data,
  };
};
