import styled from "styled-components";
import { FadeInHorizontal, FadeInVertical, ViewContainer } from "globalStyles";
const LeftFade = FadeInHorizontal("left");
const BottomFade = FadeInVertical("bottom");

export const MainContainer = styled.div`
  height: 100%;
  padding: 0 50px 20px 50px;
  display: flex;
  flex-flow: column nowrap;
  animation: ${LeftFade} ease-out 0.3s forwards;
`;

export const AnimatedViewContainer = styled(ViewContainer)`
  animation: ${BottomFade} ease-out 0.3s forwards;
`;
export const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`;

export const StyledForm = styled.form`
  max-width: 780px;
  width: 100%;

  & > i {
    color: ${(props) => props.theme.colors.primaryColor};
    font-size: ${(props) => props.theme.text.title.large};
    margin: 20px 0 20px;
    cursor: pointer;
    float: right;
  }
`;
export const StyledProfile = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;

  & div {
    width: 30px;
    height: 30px;
  }
`;

export const Label = styled.div`
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: normal;
  margin: 20px 0 10px;
  color: ${(props) => props.theme.colors.label};
  text-align: left;
`;
