import React from "react";
import { SingleRectangleSkeleton } from "components/Skeleton";
import { DescriptionContainer } from "./style";

function LoadingView() {
  return (
    <DescriptionContainer data-testid="survey_loader">
      <SingleRectangleSkeleton
        style={{ width: "400px", height: "30px", backgroundColor: "#d5cfcf" }}
      />
      <SingleRectangleSkeleton
        style={{ width: "250px", height: "30px", backgroundColor: "#d5cfcf" }}
      />
      <div className="footer">
        <SingleRectangleSkeleton
          style={{ width: "100px", height: "30px", backgroundColor: "#d5cfcf" }}
        />
        <SingleRectangleSkeleton
          style={{ width: "100px", height: "30px", backgroundColor: "#d5cfcf" }}
        />
      </div>
    </DescriptionContainer>
  );
}

export default LoadingView;
