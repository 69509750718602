import React from "react";

import { TabContainer, TabHeader, TabContent } from "components/Tabs2";
import BirthdaySection from "./BirthdaySection";
import AnniversarySection from "./AnniversarySection";
import NewJoineeSection from "./NewJoineeSection";

import SmallCake_Img from "assets/employee/birthdayCakeSmall.png";
import Popper_Img from "assets/employee/popper.png";
import Briefcase_Img from "assets/employee/briefcase.png";

import { Container, Header, Section, InfoTab } from "./style";

const Tabs = {
  birthday: "Birthdays",
  anniversary: "Work Anniversaries",
  newJoinee: "New Joinees",
};

export function InfoCard() {
  return (
    <Container>
      <TabContainer
        initialData={{
          activeTabId: Tabs.birthday,
        }}
      >
        <Header>
          <TabHeader>
            <InfoTabHeader
              id={Tabs.birthday}
              image={SmallCake_Img}
              title={Tabs.birthday}
            />
          </TabHeader>
          <TabHeader>
            <InfoTabHeader
              id={Tabs.anniversary}
              image={Popper_Img}
              title={Tabs.anniversary}
            />
          </TabHeader>
          <TabHeader>
            <InfoTabHeader
              id={Tabs.newJoinee}
              image={Briefcase_Img}
              title={Tabs.newJoinee}
            />
          </TabHeader>
        </Header>
        <Section>
          <TabContent id={Tabs.birthday}>
            <BirthdaySection />
          </TabContent>

          <TabContent id={Tabs.anniversary}>
            <AnniversarySection />
          </TabContent>

          <TabContent id={Tabs.newJoinee}>
            <NewJoineeSection />
          </TabContent>
        </Section>
      </TabContainer>
    </Container>
  );
}

function InfoTabHeader({ id, image, title, active, ...rest }) {
  return (
    <InfoTab $active={active === title} id={id} {...rest}>
      <img src={image} />
      <span className="title">{title}</span>
    </InfoTab>
  );
}
