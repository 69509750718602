import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import useAlert from "hooks/useAlert";
import { getDateDetails } from "helper";

import {
  createPost as createPostActionCreator,
  createPoll as createPollActionCreator,
  createQuiz as createQuizActionCreator,
} from "../../redux/Posts/actionCreator";

function useCreatePost() {
  const [isLoading, updateLoading] = useState(false);
  const [hasError, updateError] = useState(false);

  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const createPost = async (data, callback) => {
    let success = false;
    try {
      updateLoading(true);
      await dispatch(
        createPostActionCreator({ companyId, isAdmin, formData: data })
      );
      showAlert({ message: "Post Created", type: "success" });
      success = true;
    } catch (err) {
      showAlert({ message: "Failed to create post", type: "error" });
      updateError(true);
    } finally {
      updateLoading(false);
    }

    typeof callback === "function" && callback(success);
  };

  const createPoll = async (data, callback) => {
    let success = false;
    try {
      updateLoading(true);
      const today = new Date();
      today.setDate(today.getDate() + Number(data.duration));
      const { todayDate, monthIndex, year, hour, min } = getDateDetails(today);
      const duration = `${year}-${monthIndex}-${todayDate} ${hour}:${min}`;
      await dispatch(
        createPollActionCreator({
          companyId,
          isAdmin,
          formData: { ...data, duration },
        })
      );
      showAlert({ message: "Poll Created", type: "success" });
      success = true;
    } catch (err) {
      showAlert({ message: "Failed to create poll", type: "error" });
      updateError(true);
    } finally {
      updateLoading(false);
    }

    typeof callback === "function" && callback(success);
  };

  const createQuiz = async (data, callback) => {
    let success = false;
    try {
      updateLoading(true);
      await dispatch(
        createQuizActionCreator({
          companyId,
          isAdmin,
          formData: data,
        })
      );
      showAlert({ message: "Quiz Created", type: "success" });
      success = true;
    } catch (err) {
      showAlert({ message: "Failed to create quiz", type: "error" });
      updateError(true);
    } finally {
      updateLoading(false);
    }

    typeof callback === "function" && callback(success);
  };

  return {
    isLoading,
    createPost,
    createPoll,
    createQuiz,
    hasError,
  };
}

export default useCreatePost;
