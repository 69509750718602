import styled from "styled-components";
import BackButton from "components/BackButton";

import { FadeInHorizontal } from "globalStyles";
const LeftFade = FadeInHorizontal("left");

export const MainContainer = styled.div`
  height: 100%;
  padding: 0 25px 20px;
  display: flex;
  flex-flow: column nowrap;
  animation: ${LeftFade} ease-out 0.3s forwards;

  @media (min-width: 768px) {
    padding: 0 50px 20px 50px;
  }
`;

export const FormHeading = styled.h3`
  font-size: ${(props) => props.theme.text.title.large};
  color: ${(props) => props.theme.colors.primaryColor};
  margin-bottom: 20px;
  margin-top: 45px;
  font-weight: 600;
  text-align: left;
  display: flex;
`;

export const HolidayCheckbox = styled.div`
  margin-bottom: 15px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const StyledBackButton = styled(BackButton)`
  position: unset;
  margin-left: auto;
`;
