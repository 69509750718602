import React, { useMemo, useCallback } from "react";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import { getStatusType } from "helper";
import { updateAttendanceId } from "../../actionCreator";
import { Cell, CellIcon } from "../../style/display";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createTableDataSelector } from "common/Selectors/MTable";

const RenderItem = ({
  data,
  columnIndex,
  rowIndex,
  style,
  updateAttendanceId,
  rowIds
}) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
    maxStatusLength,
    onIdClick
  } = data;

  const cellvalue = rowData[rowIndex][columnIndex];

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["status"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const isFirstColumn = useMemo(() => {
    return columnIndex === 0;
  }, [columnIndex]);

  const onCellClick = useCallback(() => {
    if (typeof onIdClick === "function") {
      onIdClick({ rowIndex });
      updateAttendanceId({ attendanceId: rowIds[rowIndex] });
    }
  }, [rowIndex, rowIds]);

  const myChildren = useMemo(() => {
    if (isFirstColumn) {
      return (
        <Cell style={{ cursor: "pointer" }}>
          {rowData[rowIndex][0]}
          <CellIcon name="fas fa-arrow-right" />
        </Cell>
      );
    }
    if (isStatusColumn) {
      return (
        <Cell>
          <Status
            text={cellvalue}
            type={getStatusType(cellvalue)}
            style={{ width: `${maxStatusLength * 18}px`, padding: "6px" }}
          />
        </Cell>
      );
    }
    return null;
  }, [isStatusColumn, cellvalue, maxStatusLength]);

  return (
    <GridCell
      row={rowIndex}
      showHeading={showHeading}
      onClick={onCellClick}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateAttendanceId }, dispatch);
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "attendance.attendanceTable",
    reverse: false
  });
  const maker = (store, props) => {
    const { rowIdMap } = getUserRows(store, props);
    return {
      rowIds: rowIdMap
    };
  };
  return maker;
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderItem);
