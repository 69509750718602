import {
  PUBLISH_NOTICE,
  GET_EMPLOYER_NOTICES,
  CREATE_NOTICE,
  UPDATE_DRAFT_NOTICE,
  DELETE_NOTICE,
} from "../actiontypes";
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnDelete,
  createNewStoreOnGetData,
  createNewStoreOnUpdate,
} from "../../../Helper";

const initialData = createInitialStoreState();

export const allNoticesReducer = (store = initialData, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_EMPLOYER_NOTICES}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${CREATE_NOTICE}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);

    case `${DELETE_NOTICE}_SUCCESS`:
      return createNewStoreOnDelete(payload, store);

    case `${PUBLISH_NOTICE}_SUCCESS`:
      return createNewStoreOnUpdate(payload, store);

    case `${UPDATE_DRAFT_NOTICE}_SUCCESS`:
      return createNewStoreOnUpdate(payload, store);

    default:
      return store;
  }
};
