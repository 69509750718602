import React from "react";
import ActionArea from "./ActionArea";
import TableData from "./TableData";
import { ViewContainer } from "globalStyles";
import { usePageSlider } from "hooks/usePageSlider";
import PageSlider from "components/PageSlider";
import AttendanceDetailsView from "modules/Attendence/Views/Details";

const AttendanceView = ({ collaboratorId }) => {
  const { onCellClick, isSliderActive, toggleSlider, data } = usePageSlider();

  return (
    <ViewContainer>
      <ActionArea collaboratorId={collaboratorId} />
      <TableData onCellClick={onCellClick} />
      <PageSlider
        onClose={toggleSlider}
        active={isSliderActive}
        style={{ top: "0", left: "0", zIndex: "2000" }}
      >
        <AttendanceDetailsView
          rowId={data}
          isSubordinateAttendance={true}
          subordinateId={collaboratorId}
        />
      </PageSlider>
    </ViewContainer>
  );
};

export default AttendanceView;
