import React from "react";

import { Container, StyledIcon, Text } from "./style";

const DeleteDetails = ({ onClick, className, text }) => {
  return (
    <Container className={className}>
      <Text role="button" onClick={onClick}>
        <StyledIcon name="fa fa-trash" />
        {text}
      </Text>
    </Container>
  );
};
DeleteDetails.defaultProps = {
  className: "",
  text: "delete Details",
};

export default DeleteDetails;
