import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import { StyledForm } from "../../../style/create";

const TrainingForm = ({ formData, isFormValid, onSubmit }) => {
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (isFormValid() && typeof onSubmit === "function") {
      onSubmit();
    }
  };

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <InputGroup
        fieldType="input"
        placeholder="Enter training / certification name"
        label="Training Name"
        {...formData["name"]}
      />
      <InputGroup
        fieldType="input"
        placeholder="Enter nature of training"
        label="Nature of training"
        {...formData["nature"]}
      />
      <InputGroup
        fieldType="input"
        placeholder="Enter institution name"
        label="Institution"
        {...formData["institution"]}
      />
      <InputGroup
        fieldType="input"
        placeholder="e.g. 95, A+"
        label="Div / class / grade with (%)"
        {...formData["grade"]}
      />
      <InputGroup
        fieldType="datePicker"
        placeholder="Enter start date"
        label="Start date"
        {...formData["startDate"]}
      />
      <InputGroup
        fieldType="datePicker"
        placeholder="Enter end date"
        label="End date"
        {...formData["endDate"]}
      />
      <InputGroup
        fieldType="input"
        placeholder="Enter location of training"
        label="Location"
        {...formData["location"]}
      />
      <InputGroup
        fieldType="googleFilePicker"
        label="Upload certificate"
        {...formData["certificateDocument"]}
      />

      <Button primary type="submit">
        Create
      </Button>
    </StyledForm>
  );
};

export default TrainingForm;
