import React, { useCallback, useMemo, useState } from "react";
import { ItemView } from "components/DetailsView";
import Modal from "components/Modal";
import MultipleImageViewer from "components/Grid/MultipleImageViewer";

import { transform2dMatrixTo1D } from "helper";
import {
  DetailsContainer,
  ModalContent,
  StyledEditDetails,
} from "../../style/display";
import { documentFields, formTags } from "../Create/options";

const DetailView = ({
  columns = [],
  data = [],
  currentStep,
  updateFormVisibilty,
}) => {
  const [isModalActive, setModalActive] = useState(false);
  const modifiedData = useMemo(() => {
    return transform2dMatrixTo1D(columns, data);
  }, [columns, data]);

  const filteredData = useMemo(() => {
    const blacklistColumns = ["currentStep"];

    const filterData = modifiedData.filter((item) => {
      return !blacklistColumns.includes(item.heading);
    });

    return filterData;
  }, [modifiedData]);

  const DocumentView = useCallback(
    (itemHeading) => {
      let documentIndex = columns.indexOf(itemHeading);
      return <MultipleImageViewer data={[data[documentIndex]]} />;
    },
    [columns, data]
  );

  const DetailItem = useMemo(() => {
    return filteredData.map((item, index) => {
      let isOfDocumentType = false;
      if (documentFields.includes(filteredData[index]?.heading)) {
        isOfDocumentType = true;
      }

      if (item.result) {
        return (
          <ItemView
            key={`${item.heading}_${index}`}
            heading={
              isOfDocumentType ? item.heading.replace("Url", "") : item.heading
            }
            isTable={isOfDocumentType ? null : item.isTable}
            result={
              isOfDocumentType &&
              typeof filteredData[index]?.result === "object"
                ? DocumentView(item.heading)
                : item.result
            }
          />
        );
      }
    });
  }, [filteredData]);

  const toggleModal = () => {
    setModalActive((prevValue) => {
      return !prevValue;
    });
  };

  const onEdit = () => {
    const formTagOptions = Object.values(formTags);
    if (
      formTagOptions.slice(0, formTagOptions.length - 1).includes(currentStep)
    ) {
      updateFormVisibilty();
    } else {
      toggleModal();
    }
  };

  return (
    <DetailsContainer>
      <StyledEditDetails onEdit={onEdit} />
      {DetailItem}
      <Modal active={isModalActive} closeModal={toggleModal}>
        <ModalContent>
          Contact your team lead to update your details
        </ModalContent>
      </Modal>
    </DetailsContainer>
  );
};

export default DetailView;
