import { GET_ACTIVE_USERS } from "../actiontypes";
import {
  createInitialStoreState,
  createNewStoreOnGetData,
} from "../../../Helper";

const initialData = createInitialStoreState({}, true);

export const usersReducer = (store = initialData, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_ACTIVE_USERS}_SUCCESS`:
      return createNewStoreOnGetData(payload, store, true);

    default:
      return store;
  }
};
