import React, { useEffect } from "react";
import { connect } from "react-redux";

import Paginator from "components/Paginator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";
import { getAllWorkshiftTypes } from "../actionCreator";

import { PaginatorBox } from "globalStyles";
import { EnhancedActionArea, StyledButton } from "../../../../style/display";

const ActionArea = ({
  updateFormVisibility,
  onceFetched,
  companyId,
  isAdmin,
  onLoading,
  style,
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: getAllWorkshiftTypes,
    tableName: "workshift.type",
  });

  const extraParams = { companyId, isAdmin };

  const { fetchFirstPage, fetchNextPage, fetchPrevPage, fetchSpecificPage } =
    usePaginator({ extraParams, paginatorApi: getNextPage });

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <EnhancedActionArea style={style}>
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
      <StyledButton onClick={() => updateFormVisibility(true)} primary>
        Create New
      </StyledButton>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);

  return {
    companyId,
    onceFetched: store.workshift.type.onceFetched,
    isAdmin,
  };
};

export default connect(mapStateToProps, null)(ActionArea);
