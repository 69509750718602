import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const getSpecificAttendanceApi = ({
  pageNumber,
  companyId,
  isAdmin,
  startDate,
  endDate,
  status,
  workOrigin,
}) => {
  const fromDateParams =
    startDate && startDate?.trim() ? `&from_date=${startDate}` : "";
  const toDateParams = endDate && endDate?.trim() ? `&to_date=${endDate}` : "";
  const statusParams = status && status?.trim() ? `&status=${status}` : "";
  const workOriginParams =
    workOrigin && workOrigin?.trim() ? `&work_origin=${workOrigin}` : "";

  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/search_working_hour_logs?page=${pageNumber}${fromDateParams}${toDateParams}${statusParams}${workOriginParams}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const getAllAttendanceApi = ({ pageNumber, companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_working_hour_logs?page=${pageNumber}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const getCurrentWorkingShiftApi = ({ companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_current_working_shift`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const markSignOutApi = ({ companyId, isAdmin, workHourLogId }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/daily_sign_out?work_hour_log_id=${workHourLogId}`,
  });

  return requestData(authUrl, {
    method: "PUT",
  });
};

export const markSignInApi = ({ companyId, isAdmin, workOrigin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/daily_sign_in?work_origin=${workOrigin}`,
  });

  return requestData(authUrl, {
    method: "POST",
  });
};

export const getPresentees = ({ pageNumber, companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_presentees?page=${pageNumber}`,
  });
  return requestData(authUrl, {
    method: "GET",
  });
};

export const getAbsentees = ({ pageNumber, companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_absentees?page=${pageNumber}`,
  });
  return requestData(authUrl, {
    method: "GET",
  });
};

export const createWorkLogApi = ({
  companyId,
  formData,
  modelableId,
  modelableType,
  isAdmin,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/daily_work_reports?modelable_type=${modelableType}&modelable_id=${modelableId}`,
  });

  let daily_work_report = {
    accomplishments: formData.accomplishments,
    challenges: formData?.challenges || "",
    todo_plans: formData?.todo_plans || "",
    reference_links:
      formData?.referenceLinks?.length > 0
        ? formData.referenceLinks.split(",").map((item) => item?.trim())
        : "",
  };

  if (formData?.notifyTo && Object.keys(formData?.notifyTo).length > 0) {
    daily_work_report.allocated_carbon_copy_member_ids = Object.keys(
      formData?.notifyTo
    );
  }

  if (formData?.documents) {
    daily_work_report.documents = formData?.documents;
  }

  return requestData(authUrl, {
    method: "POST",
    body: {
      daily_work_report: daily_work_report,
    },
    isFormData: true,
  });
};

export const editWorkLogApi = ({
  companyId,
  formData,
  modelableId,
  modelableType,
  dailyReportId,
  isAdmin,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/daily_work_reports/${dailyReportId}?modelable_type=${modelableType}&modelable_id=${modelableId}`,
  });

  let daily_work_report = {
    accomplishments: formData.accomplishments,
    challenges: formData?.challenges || "",
    todo_plans: formData?.todo_plans || "",
    reference_links:
      formData?.referenceLinks?.length > 0
        ? formData.referenceLinks
            .split(",")
            .map((item) => item?.trim())
            .filter((item) => item.length > 0)
        : "",
  };

  if (formData?.notifyTo && Object.keys(formData?.notifyTo).length > 0) {
    daily_work_report.allocated_carbon_copy_member_ids = Object.keys(
      formData?.notifyTo
    );
  }

  if (formData?.documents) {
    daily_work_report.documents = formData?.documents;
  }

  if (formData?.purgedNotifyTo) {
    daily_work_report.purge_allocated_carbon_copy_member_ids =
      formData?.purgedNotifyTo;
  }

  if (formData?.purgedDocuments) {
    daily_work_report.purge_documents_ids = formData?.purgedDocuments;
  }

  return requestData(authUrl, {
    method: "PUT",
    body: {
      daily_work_report: daily_work_report,
    },
    isFormData: true,
  });
};

export const getWorkingHourLogActivityApi = ({
  companyId,
  modelableId,
  isAdmin,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/status_activities?modelable_type=WorkingHourLog&modelable_id=${modelableId}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};
