import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

import Icon from "../Icon";
import PropTypes from "prop-types";

import styles from "./BackButton.module.css";
import { BackBtn } from "./style";

const BackButton = ({
  onTop = true,
  onLeft = false,
  onClose = null,
  showIcon = false,
  ...rest
}) => {
  const { goBack } = useHistory();

  const backStyle = useMemo(() => {
    if (onTop && onLeft) {
      return styles.top_left;
    }

    if (onTop && !onLeft) {
      return styles.top_right;
    }

    if (!onTop && onLeft) {
      return styles.bottom_left;
    }

    if (!onTop && !onLeft) {
      return styles.bottom_right;
    }
  }, [onTop, onLeft]);

  return (
    <BackBtn
      onClick={onClose || goBack}
      size="auto"
      onTop={onTop}
      onLeft={onLeft}
      {...rest}
    >
      {showIcon && <Icon name="fas fa-arrow-left" />}
      Back
    </BackBtn>
  );
};

BackButton.propTypes = {
  onTop: PropTypes.bool,
  onLeft: PropTypes.bool,
};

export default BackButton;
