import React, { useMemo } from "react";
import { isLink } from "../../../../../Helper";

import {
  StringContainer,
  StringHeadingBox,
  StyledStringContent,
} from "./style";

const StringContent = ({ data, heading }) => {
  const isLinkValue = useMemo(() => {
    return isLink(data);
  }, [data]);

  return (
    <StringContainer>
      <StringHeadingBox>
        <h3>{heading}</h3>
      </StringHeadingBox>
      <StyledStringContent>
        {isLinkValue ? (
          <a rel="noopener noreferrer" target="_blank" href={data}>
            {data}
          </a>
        ) : (
          <span>{data}</span>
        )}
      </StyledStringContent>
    </StringContainer>
  );
};

export default StringContent;
