import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";

import { createLedgerTransactions } from "../../actionCreator";

function useCreateLedgerTransactions() {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const categoryId = useSelector((store) => store.ledger.categories.ledgerId);
  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const createTransactions = async (formData, onFormReset) => {
    try {
      updateLoading(true);
      await dispatch(
        createLedgerTransactions({
          categoryId,
          companyId,
          isAdmin,
          formData
        })
      );
      showAlert({ message: "Transaction Created" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
  };
  return { isLoading, createTransactions };
}

export default useCreateLedgerTransactions;
