import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import { PaginatorBox } from "../../../../GlobalStyles";
import {
  EnhancedActionArea,
  ExpenseChartButton,
  PaginatorAndCreateBox,
  TableHeading,
  TotalCount,
} from "../../style/display";
import Paginator from "components/Paginator";
import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";

import { getLedgerTransactions } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";
import Button from "components/Button";

const ActionArea = ({
  collaboratorId,
  onceFetched,
  onLoading,
  ledgerId,
  style,
  usersData = [],
  companyId,
  isAdmin,
  totalCount,
  toggleSlider,
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: getLedgerTransactions,
    tableName: "ledger.transactions",
  });

  const extraParams = {
    companyId,
    ledgerId,
    collaboratorId,
    isAdmin,
  };

  const { fetchFirstPage, fetchNextPage, fetchPrevPage, fetchSpecificPage } =
    usePaginator({ extraParams, paginatorApi: getNextPage });

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <EnhancedActionArea style={style}>
      <PaginatorAndCreateBox style={{ display: "flex" }}>
        <TableHeading>Latest transactions</TableHeading>
        <ExpenseChartButton
          outline
          size="sm"
          onClick={toggleSlider}
          style={{ minWidth: 150, maxWidth: 200, width: "auto" }}
        >
          View Expense Chart
        </ExpenseChartButton>
        <PaginatorBox style={{ display: "flex", alignItems: "center" }}>
          <TotalCount>Total: {totalCount}</TotalCount>
          <Paginator
            current={currentPageNumber}
            total={totalPages}
            onNext={fetchNextPage}
            onPrev={fetchPrevPage}
            onGoToPage={fetchSpecificPage}
            nextIsLoading={loadingNextPage}
            prevIsLoading={loadingPrevPage}
          />
        </PaginatorBox>
      </PaginatorAndCreateBox>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    onceFetched: store.ledger.transactions.onceFetched,
    ledgerId: store.ledger.categories.ledgerId,
    collaboratorId: store.ledger.transactions.collaboratorId,
    totalCount: store.ledger.transactions.totalCount,
    isAdmin,
  };
};

export default connect(mapStateToProps, null)(ActionArea);
