import { ACTIVATE_TOOLTIP, DE_ACTIVATE_TOOLTIP } from "./actionTypes";

export const initialStore = {
  style: {},
  active: false,
  position: "",
};

export const tooltipReducer = (store, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIVATE_TOOLTIP: {
      const { style, position } = payload;
      return { ...store, active: true, style, position };
    }
    case DE_ACTIVATE_TOOLTIP: {
      return initialStore;
    }
  }
};
