import InputGroup from "components/InputGroup/inputGroup2";

export const CreditFields = ({ formData }) => {
  return (
    <>
      <InputGroup
        fieldType="datePicker"
        placeholder="Enter date dd/mm/yy"
        label="Date"
        {...formData["date"]}
      />

      <InputGroup
        fieldType="textArea"
        placeholder="Enter Notes"
        label="Notes"
        size="lg"
        {...formData["notes"]}
      />

      <InputGroup
        inputMode="number"
        placeholder="Enter amount  &#8377; XXXX"
        label="Amount (In Rupee)"
        {...formData["amount"]}
      />

      <InputGroup
        fieldType="googleFilePicker"
        accept="image/*, application/pdf"
        multiple={true}
        label="Invoices"
        {...formData["invoices"]}
      />
    </>
  );
};
