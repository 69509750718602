import styled from "styled-components";
import { StyledActionArea } from "globalStyles";
import FormGroup from "components/FormGroup";

export const EnhancedActionArea = styled(StyledActionArea)`
  flex-flow: row wrap;
  align-items: flex-end;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;

export const SearchArea = styled.div`
  flex-basis: 100%;
  align-self: center;
  margin: 0 10px 0 0;

  @media (min-width: 1024px) {
    flex-basis: 90%;
  }

  & > form > button {
    margin-top: 5px;
    flex-basis: 75%;
  }
`;

export const StyledForm = styled(FormGroup)`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  gap: 20px;

  & {
    margin: 0 0 8px; 0;
  }

  & div {
    height: 35px;
  }

  & div > select {
    height: 35px;
    padding: 0 10px;
  }

  & button {
    height: 35px;
    padding: 7px;
  }

  @media (min-width: 768px) {
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;

    & div, button {
      max-width: 200px;
    }
  }

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

export const AsyncSearchField = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  & > div > div > div {
    height: 100px;
  }

  @media (min-width: 768px) {
    min-width: 420px;

    & div {
      max-width: 420px;
    }
  }
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
`;

export const BackButton = styled.div`
  width: 105px;
  height: 45px;
  background-color: white;
  @media (max-width: 768px) {
    margin: 15px;
  }
`;
