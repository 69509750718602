import {
  SET_USER_COMAPNY,
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_LOGIN_TYPE,
} from "../ActionTypes";
import { createAsyncActionCreator } from "../../Helper";
import { loginApi } from "../Api";

import { LocalStorage } from "storage";
import { TOKEN_LOCAL_NAME } from "../../Constants";

const loginGenerator = function* ({ emailOrMobile, password, isAdmin }) {
  const { error, ...restData } = yield {
    action: LOGIN_USER,
    sideEffect: loginApi({ emailOrMobile, password, isAdmin }),
  };
  if (error) {
    return;
  }
  const { meta } = restData;
  if (meta && meta.token) {
    LocalStorage.setKey(TOKEN_LOCAL_NAME, meta.token);
  }
};

export const loginUser = createAsyncActionCreator(loginGenerator);

export const setUserCompany = ({ companyId }) => ({
  type: SET_USER_COMAPNY,
  payload: { companyId },
});

export const updateLoginType = ({ isLoggedInAsAdmin }) => ({
  type: UPDATE_LOGIN_TYPE,
  payload: { isLoggedInAsAdmin },
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});
