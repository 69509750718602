import React, { useEffect } from "react";
import { connect } from "react-redux";
import Paginator from "components/Paginator";

import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";

import { PaginatorBox } from "globalStyles";
import { getCurrentUserCompany } from "common/Selectors/auth";
import {
  ButtonContainer,
  EnhancedActionArea,
  StyledButton,
} from "modules/NotifiedWorkReport/style";
import { getTeamsWorkLog } from "./redux/actionCreator";

const ActionArea = ({
  companyId,
  updateFormVisibility,
  modelableId,
  onceFetched,
  onLoading,
  style,
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: getTeamsWorkLog,
    tableName: `teams.worklog`,
  });

  const extraParams = {
    companyId,
    modelableId,
  };

  const extraPageConfig = {};

  const { fetchFirstPage, fetchNextPage, fetchPrevPage, fetchSpecificPage } =
    usePaginator({
      extraParams,
      paginatorApi: getNextPage,
      otherProps: extraPageConfig,
    });

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <EnhancedActionArea style={style}>
      <ButtonContainer style={{ width: "100%" }}>
        <StyledButton onClick={() => updateFormVisibility(true)} primary>
          Create New
        </StyledButton>
      </ButtonContainer>
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const activeTab = props.activeTab;
  const companyId = getCurrentUserCompany(store, props);
  return {
    companyId,
    onceFetched: store.teams.worklog.onceFetched,
  };
};

export default connect(mapStateToProps, null)(ActionArea);
