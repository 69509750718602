import React, { useCallback, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup/inputGroup2";
import Icon from "components/Icon";
import Button from "components/Button";
import useAlert from "hooks/useAlert";
import useForm from "hooks/useForm";

import { getUserListData } from "common/Api";

import { generateFormData } from "../../utils";

import { createLead } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { statusOptions } from "./options";
import { FormHeading, Header, StyledForm } from "../../style/create";

const Form = ({ setIsFormVisible, userId, companyId, isAdmin, createLead }) => {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();
  const { formData, isFormValid, values, resetForm } = useForm({
    fields: {
      leadById: {
        required: true,
      },
      leadType: {},
      startDate: {
        required: true,
      },
      status: {
        required: true,
      },
    },
  });

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }
    try {
      updateLoading(true);
      const data = generateFormData(values, userId);
      await createLead({ companyId, data: data, isAdmin });
      showAlert({ message: "Lead Created" });
      resetForm();
      if (typeof setIsFormVisible === "function") {
        setIsFormVisible(false);
      }
    } catch (err) {
      showAlert({ message: "Lead Create Failed", type: "error" });
    } finally {
      updateLoading(false);
    }
  };

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Add new Lead</FormHeading>
        <Icon name="fas fa-times" onClick={updateFormVisibilty} />
      </Header>

      <InputGroup
        label="Lead By"
        placeholder="Enter lead name"
        fieldType="asyncSearchSelect"
        api={getUserListData}
        singleSelect
        {...formData["leadById"]}
      />

      <InputGroup
        label="Lead Type"
        placeholder="Enter lead type"
        {...formData["leadType"]}
      />

      <InputGroup
        label="Start Date"
        fieldType="datePicker"
        placeholder="Enter start date"
        {...formData["startDate"]}
      />

      <InputGroup
        label="Status"
        fieldType="select"
        defaultSelectText="Select Status"
        options={statusOptions}
        {...formData["status"]}
      />

      <Button loading={isLoading} disabled={isLoading} primary type="submit">
        Create
      </Button>
    </StyledForm>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    isAdmin,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createLead,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Form);
