import React, { useEffect } from "react";
import { connect } from "react-redux";
import Paginator from "components/Paginator";

import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";
import SearchForm from "./SearchForm";

import {
  getNotifiedWorkReports,
  searchWorkReports,
} from "../redux/actionCreator";

import { PaginatorBox } from "globalStyles";
import { getCurrentUserCompany } from "common/Selectors/auth";
import useSearchForm from "./SearchForm/useSearchForm";
import { changeToDateFormat } from "helper/date";
import { EnhancedActionArea } from "../style";

const ActionArea = ({
  companyId,
  onceFetched,
  isSearchOn,
  onLoading,
  style,
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: getNotifiedWorkReports,
    tableName: `notifiedWorkReports`,
  });

  const {
    formData,
    isFormValid,
    resetSearchForm,
    values,
    updateField,
    storeSearchData,
  } = useSearchForm();

  const extraParams = {
    companyId,
    fromDate: values.fromDate && changeToDateFormat(values.fromDate),
    toDate: values.toDate && changeToDateFormat(values.toDate),
    collaboratorIds: values.collaborators && Object.keys(values.collaborators),
  };

  const extraPageConfig = {};

  const {
    fetchFirstPage,
    fetchNextPage,
    fetchPrevPage,
    fetchSpecificPage,
    fetchCurrentPage,
  } = usePaginator({
    extraParams,
    paginatorApi: getNextPage,
    otherProps: extraPageConfig,
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }
    storeSearchData();
    extraPageConfig.newActionCreator = searchWorkReports;
    fetchFirstPage();
  };

  const onResetForm = () => {
    Reflect.deleteProperty(extraPageConfig, "newActionCreator");
    resetSearchForm();
  };

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, []);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched && !isSearchOn) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <EnhancedActionArea style={style}>
      <SearchForm
        formData={formData}
        values={values}
        updateField={updateField}
        onSubmit={onSubmit}
        resetForm={onResetForm}
        isLoading={loadingNextPage || loadingPrevPage}
      />
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const activeTab = props.activeTab;
  const companyId = getCurrentUserCompany(store, props);
  return {
    companyId,
    onceFetched: store.notifiedWorkReports.onceFetched,
    isSearchOn: store.notifiedWorkReports.isSearchOn,
  };
};

export default connect(mapStateToProps, null)(ActionArea);
