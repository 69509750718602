import React, { useCallback, useState } from "react";
import { Img, ImageContainer, Loader } from "./style";

export const ImageComponent = ({ className, onLoad, ...props }) => {
  const [loading, setLoading] = useState(true);

  const onImageLoad = useCallback(() => {
    setLoading(false);
    if (typeof onLoad === "function") {
      onLoad();
    }
  }, []);

  return (
    <ImageContainer className={className}>
      <Loader $loading={loading} />
      <Img onLoad={onImageLoad} $loading={loading} {...props} />
    </ImageContainer>
  );
};
