import React from "react";
import { useTab } from "./useTab";
export const TabContext = React.createContext({});

export const TabProvider = ({
  children,
  reducer,
  initialData,
  onActionDispatch,
}) => {
  const { store, activateTab, resetTabs } = useTab(
    reducer,
    onActionDispatch,
    initialData
  );
  const value = { activeTab: store.activeTabId, activateTab, resetTabs };

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};
