import requestData from "../../../../../ApiHandler";
import { createAuthUrl } from "helper";

export const createLeaveTypeApi = ({
  companyId,
  leaveType,
  color,
  isAdmin
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/leave_plans`
  });

  return requestData(authUrl, {
    method: "POST",
    body: {
      leave_plan: {
        leave_type: leaveType,
        color_code: color || "#7A3BCB"
      }
    },
    isFormData: true
  });
};

export const getAllLeaveTypesApi = ({ pageNumber, companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/leave_plans?page=${pageNumber}`
  });

  return requestData(authUrl, {
    method: "GET"
  });
};

export const editLeaveTypeApi = ({
  companyId,
  leaveTypeId,
  leaveType,
  color,
  status,
  isAdmin,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/leave_plans/${leaveTypeId}`,
  });

  return requestData(authUrl, {
    method: "PUT",
    body: {
      leave_plan: {
        leave_type: leaveType,
        color_code: color || "#7A3BCB",
        status: status,
      },
    },
    isFormData: true,
  });
};

export const deleteLeaveTypeApi = ({ leaveTypeId, companyId, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/leave_plans/${leaveTypeId}`,
  });
  return requestData(url, {
    method: "DELETE",
  });
};
