import React from "react";
import Form from "./Form";
import { MainContainer } from "../../style/email";

const EmailCreateView = ({ setIsFormVisible, userId }) => {
  return (
    <MainContainer>
      <Form setIsFormVisible={setIsFormVisible} userId={userId} />
    </MainContainer>
  );
};
export default EmailCreateView;
