import React, { useEffect } from "react";
import { connect } from "react-redux";

import ActionController from "../../../ActionController";
import {
  GET_ASSIGNED_LEAVE_REQUISITIONS,
  GET_NOTIFIED_LEAVE_REQUISITIONS
} from "../redux/actionTypes";

import Paginator from "components/Paginator";

import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";

import { getAssignedLeaves, getNotifiedLeaves } from "../redux/actionCreator";

import { PaginatorBox, StyledActionArea } from "globalStyles";
import { getCurrentUserCompany } from "common/Selectors/auth";

const ActionArea = ({
  activeTab,
  companyId,
  onceFetched,
  onLoading,
  style
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages
  } = usePagination({
    actionCreator:
      activeTab == "notified" ? getNotifiedLeaves : getAssignedLeaves,
    tableName: `leaveManagement.${activeTab}`
  });

  const extraParams = {
    status: activeTab,
    companyId
  };

  const {
    fetchFirstPage,
    fetchNextPage,
    fetchPrevPage,
    fetchSpecificPage,
    fetchCurrentPage
  } = usePaginator({ extraParams, paginatorApi: getNextPage });

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
    return () => {
      activeTab == "notified"
        ? ActionController.cancelAction(GET_NOTIFIED_LEAVE_REQUISITIONS)
        : ActionController.cancelAction(GET_ASSIGNED_LEAVE_REQUISITIONS);
    };
  }, [activeTab]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <StyledActionArea style={style}>
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </StyledActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const activeTab = props.activeTab;
  const companyId = getCurrentUserCompany(store, props);
  return {
    companyId,
    onceFetched: store.leaveManagement[activeTab].onceFetched
  };
};

export default connect(mapStateToProps, null)(ActionArea);
