import styled from "styled-components";
import Icon from "../Icon";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.span`
  font-size: ${(props) => props.theme.text.title.medium};
  font-weight: 400;
`;

export const StyledIcon = styled(Icon)`
  font-size: 40px;
  color: gray;
  margin-bottom: 10px;
`;
