import React from "react";
import WeekColumns from "./WeekColumns";

import { Container } from "./style";

export const WeekCalendar = ({ data, weekData }) => {
  return (
    <Container>
      <WeekColumns
        weekDaysList={weekData}
        tasks={data}
        timesRowCount={24}
        timesRowHeight={100}
      />
    </Container>
  );
};
