import { removeSpaceFromString } from "./string";

export const tranformMatrixToArray = (data, rowIdMap) => {
  const columnNamesAtIndex = data[0];
  const result = [];
  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    const res = {};
    row.forEach((item, idx) => {
      res[removeSpaceFromString(columnNamesAtIndex[idx])] =
        typeof item === "string" ||
        typeof item === "number" ||
        Array.isArray(item)
          ? item
          : { ...item };
    });
    result.push({ id: rowIdMap[i], ...res });
  }
  return result;
};
