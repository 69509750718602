import React from "react";

import { PostItem } from "./style";

function PostCreateItem({ image, title, ...rest }) {
  return (
    <PostItem {...rest}>
      <img src={image} />
      {!!title && <span className="title">{title}</span>}
    </PostItem>
  );
}

export default PostCreateItem;
