import {
  GET_NEW_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  MARK_NOTIFICATION_AS_READ,
} from "./actionTypes";
import { createNewStoreOnGetData, createInitialStoreState } from "../../Helper";

const initialState = createInitialStoreState({}, true);

export const notificationReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_NEW_NOTIFICATIONS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }
    case `${MARK_ALL_NOTIFICATIONS_AS_READ}_SUCCESS`: {
      const { allData, allIds } = store;
      const newData = { ...allData };
      allIds.forEach((id) => {
        if (!allData[id] || !allData[id].attributes) {
          return;
        }
        newData[id] = {
          ...allData[id],
          attributes: {
            ...allData[id].attributes,
            readAt: new Date(),
          },
        };
      });

      return {
        ...store,
        allData: newData,
      };
    }

    case `${MARK_NOTIFICATION_AS_READ}_SUCCESS`: {
      const { data, dataIds } = payload;
      const { allData } = store;
      const newData = { ...allData };

      dataIds.forEach((id) => {
        if (!allData[id] || !allData[id].attributes) {
          return;
        }
        newData[id] = {
          ...data[id],
        };
      });

      return {
        ...store,
        allData: newData,
      };
    }

    default:
      return store;
  }
};
