import React from "react";
import { AllUserContainer } from "./style";
import Profile from "./Profile";

const AllUsers = ({ data, color }) => {
  return (
    <AllUserContainer>
      {data.map((item, index) => {
        return (
          <Profile
            data={item}
            randomColor={color}
            index={index}
            key={index}
            className="all-users"
          />
        );
      })}
    </AllUserContainer>
  );
};

export default AllUsers;
