import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  ActionLinks,
  ActionLinksContainer,
  CommentContainer,
  CommentContent,
  CommentDescription,
  CommentPic,
} from "./style";

import { getCurrentUserId } from "common/Selectors/auth";
import { CreateComment } from "components/Comment";

import useComment from "hooks/useComment";
import ActivityIndicator from "components/ActivityIndicator";

export function Comment({
  id,
  userName,
  userImage,
  designation,
  comment,
  time,
  collaboratorId,
  ...rest
}) {
  const [isEditOn, setEditOn] = useState(false);
  const { deleteComment, editComment, isLoading } = useComment();

  const toggleEdit = () => {
    setEditOn((prev) => !prev);
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want to delete this comment ?")) {
      deleteComment({ commentId: id });
    }
  };

  const onEdit = (comment, onSuccess) => {
    const onCommentSuccess = () => {
      typeof onSuccess == "function" && onSuccess();
      toggleEdit();
    };
    editComment({
      commentId: id,
      message: comment,
      onSuccess: onCommentSuccess,
    });
  };

  const currentCollaboratorId = useSelector(getCurrentUserId);

  return (
    <CommentContainer {...rest}>
      <CommentPic img={userImage} userName={userName} />
      <CommentContent>
        <span className="heading">
          {userName}
          <span className="time">{time}</span>
        </span>
        <span className="designation">{designation}</span>
        {isEditOn ? (
          <CreateComment
            onComment={onEdit}
            isCreatingComment={isLoading}
            initialValue={comment}
          />
        ) : (
          <>
            <CommentDescription>{comment}</CommentDescription>
            {currentCollaboratorId == collaboratorId && (
              <ActionLinksContainer>
                <ActionLinks onClick={toggleEdit}> Edit </ActionLinks>
                &#8226;
                <ActionLinks onClick={onDelete} disabled={isLoading}>
                  Delete
                </ActionLinks>
                {isLoading && <ActivityIndicator size="xs" color="#5a5a5a" />}
              </ActionLinksContainer>
            )}
          </>
        )}
      </CommentContent>
    </CommentContainer>
  );
}

Comment.propTypes = {
  id: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userImage: PropTypes.string,
  designation: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};
