import React from "react";
import Modal from "components/Modal";
import Button from "components/Button/index";

import { SignInOptions } from "../options";
import { StyledContainer } from "./style";

type Props = {
  active: boolean;
  onClose: Function;
  heading: string;
  onSignIn: (value: string) => void;
  showCloseIcon: boolean;
  signInLoadingType: string;
};

export const AttendanceSignInModal = ({
  active,
  onClose,
  heading,
  onSignIn,
  showCloseIcon = true,
  signInLoadingType = "",
}: Props) => {
  return (
    <Modal
      showCloseIcon={showCloseIcon}
      active={active}
      closeModal={onClose}
      heading={heading}
    >
      <StyledContainer>
        {SignInOptions.map((option) => (
          // @ts-ignore
          <Button
            outline
            size="lg"
            title={option.value}
            loading={signInLoadingType === option.value}
            onClick={() => onSignIn(option.value)}
          >
            {option.name}
          </Button>
        ))}
      </StyledContainer>
    </Modal>
  );
};
