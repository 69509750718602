import styled from "styled-components";
import ProfilePic from "components/ProfilePic";
import Button from "components/Button";
import InputGroup from "components/InputGroup/inputGroup2";

import { FadeInVertical, placeholderColor } from "globalStyles";

const BottomFade = FadeInVertical("bottom");

export const StyledProfilepic = styled(ProfilePic)`
  width: 45px;
  height: 45px;
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
`;
export const ModalContent = styled.div`
  width: min(80%, 520px);
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-flow: column nowrap;
  animation: ${BottomFade} ease-out 0.3s forwards;
`;

export const PostCreateContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px lightgray solid;
  padding: 21px 34px;
  & .title {
    font-weight: bold;
    font-size: ${(props) => props.theme.text.para.medium};
    color: black;
  }
  & img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`;

export const Section = styled.div`
  flex-grow: 1;
  padding: 20px 34px;
  display: flex;
  flex-flow: column nowrap;
`;
export const SectionHeader = styled.div`
  display: flex;
  & .title {
    font-weight: 600;
    font-size: ${(props) => props.theme.text.para.medium};
    color: #666666;
    margin-left: 10px;
  }
  align-items: center;
  margin-bottom: 20px;
`;

export const FormArea = styled.form`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  & textarea {
    border: none;
    resize: vertical;
    font-weight: normal;
    font-size: 17px;
    padding-left: 0;
  }
`;

export const PollFormArea = styled.form`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  max-height: 460px;
  overflow-y: auto;
  &.poll-area label {
    width: 100%;
  }
`;

export const StyledPollInputGroup = styled(InputGroup)`
  & > label {
    margin-bottom: 0;
  }
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PostButton = styled(Button).attrs(() => ({ size: "auto" }))`
  min-width: 109px;
  min-height: 39px;
  background: #ebebeb;
  border-radius: 34px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: ${(props) => props.theme.text.para.medium};
  color: #5a5a5a;
  &:hover {
    box-shadow: none;
  }
`;

export const PostBackButton = styled(PostButton)`
  background: transparent;
  color: ${(props) => props.theme.colors.primaryColor};
  border: 1px ${(props) => props.theme.colors.primaryColor} solid;
  margin-right: 10px;
`;

export const PostIconItemContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const PostIconItem = styled.button.attrs(() => ({ type: "button" }))`
  display: flex;
  justify-content: center;
  background: transparent;
  border: none;
  width: 20px;
  height: 20px;
  align-items: center;
  cursor: pointer;
  & img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  & + button {
    margin-left: 32px;
  }

  ${({ $disabled }) => {
    if ($disabled) {
      return `
       cursor: not-allowed;
       opacity: 0.5;
       `;
    }
  }}
`;

export const StyledLabelArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ImageViewContainer = styled.div`
  margin-bottom: 20px;
  animation: ${BottomFade} ease-out 0.3s forwards;
`;

export const AddMoreOption = styled.button`
  border-radius: 8px;
  border: 1px ${(props) => props.theme.colors.primaryColor} solid;
  background-color: white;
  cursor: pointer;
  font-size: ${(props) => props.theme.text.para.medium};
  color: ${(props) => props.theme.colors.primaryColor};
  padding: 10px 15px;
  margin: 20px 0;
`;

export const PollFooter = styled.div`
  display: flex;
  flex-basis: 70px;
  padding: 0px 34px;
  padding-top: 0;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px lightgray solid;
`;

export const OptionInputWrapper = styled.div`
  position: relative;
`;
export const RemoveButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
  font-size: ${(props) => props.theme.text.para.small};
  color: ${(props) => props.theme.colors.primaryColor};
  font-weight: 500;

  position: absolute;
  right: 5px;
  top: -5px;
`;

export const CommentCreateContainer = styled.div`
  margin: 16px 0;
`;
export const CommentInput = styled.textarea`
  width: 100%;
  min-height: 37px;
  border: 1px solid #c7c7c7;
  border-radius: 41px;
  padding: 15px 20px;
  padding-bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  resize: none;
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.primaryColor};
  }
  ${placeholderColor("#666666")}
`;

export const CommentButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.primaryColor};
  color: white;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  font-weight: bold;
  margin: 10px 0;
  padding: 5px 10px;
  border-radius: 15px;
`;
