import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import Collapse from "../Collapse";
import { scrollbar1 } from "globalStyles";

const expand = keyframes`
0% {
    width: 0;
    opacity: 0;
  }

  50% {
    width: calc(${(props) => props.theme.dimensions.drawer.width} / 2);
    opacity: 0.5;
  }

  100% {
    width: ${(props) => props.theme.dimensions.drawer.width};
    opacity: 1;
  }
`;

const shrink = keyframes`
0% {
    width: ${(props) => props.theme.dimensions.drawer.width};
    opacity: 1;
  }
  20%{
    opacity: 0;
  }

  50% {
    width: calc(${(props) => props.theme.dimensions.drawer.width} / 2);
    opacity: 0;
  }

  100% {
    width: 0;
    opacity: 0;
    padding:0;
  }
`;

export const MobileDrawerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const DrawerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: ${(props) => (props.isDrawerActive ? "1" : "0")};
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${(props) => props.theme.zIndex.backdrop};
  transition: opacity ease 0.15s;
`;

export const DrawerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${(props) => props.theme.zIndex.drawer};
  width: ${(props) => props.theme.dimensions.drawer.width};
  height: 100vh;
  overflow-y: auto;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  text-transform: capitalize;
  transition: all ease 0.5s;
  padding-top: calc(${(props) => props.theme.dimensions.navbar.height} + 10px);
  transform: ${({ isDrawerActive, isMobileView }) =>
    isDrawerActive || !isMobileView ? "translateX(0%)" : "translateX(-100%)"};
  animation: ${(props) => (props.isZeroWidth ? shrink : expand)} 0.3s ease
    forwards;
  box-shadow: ${(props) => props.theme.shadow.medium};
  @media (min-width: 800px) {
    position: sticky;
    height: 100vh;
    bottom: unset;
  }
  ${scrollbar1}
`;

export const StyledDrawerLink = styled(Link)`
  width: 100%;
  height: ${({ theme, $showEnlarged }) =>
    $showEnlarged
      ? theme.dimensions.drawer.tab.height
      : theme.dimensions.drawer.link.height};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px;
  position: relative;
  margin-top: ${(props) => (props.$showEnlarged ? "10px" : 0)};
  text-decoration: none;
  padding: 0 6px;
  ${(props) => {
    if (props.isactive) {
      return `background-color: ${props.theme.colors.drawer.link.background};`;
    }
  }}
  &:hover {
    background-color: ${(props) => props.theme.colors.drawer.link.background};
  }
`;

export const DrawerLinkTitle = styled.span`
  font-size: ${(props) => props.theme.text.para.small};
  margin-left: 10px;
  color: ${(props) => props.theme.colors.drawer.tab.text};

  font-weight: ${(props) => (props.isactive ? "bold" : "normal")};
`;

export const DrawerTitleBox = styled.div`
  width: 100%;
  height: ${(props) => props.theme.dimensions.drawer.tab.height};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.drawer.title};
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${(props) => props.theme.text.para.small};
  padding-left: 0px;
`;

export const StyledCollapse = styled(Collapse)`
  margin-bottom: 10px;
`;

export const StyledDrawerTab = styled.button`
  width: 100%;
  height: ${(props) => props.theme.dimensions.drawer.tab.height};
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.active ? props.theme.colors.drawer.tab.activeBackground : "#fff"};
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${(props) =>
      !props.active ? props.theme.colors.drawer.tab.activeBackground : ""};
  }
  & > svg {
    width: 15.6px;
    height: 15.6px;
  }
`;

export const DrawerTabTitle = styled.span`
  margin-left: 20px;
  color: ${(props) => props.theme.colors.drawer.tab.text};
  font-size: ${(props) => props.theme.text.para.medium};
  font-weight: ${(props) => (props.active ? "bolder" : "normal")};
`;

export const DrawerIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.drawer.tab.icon};
  font-size: 20px;
  opacity: 0.2;
`;

export const DrawerTabImage = styled.img`
  width: 15.6px;
  height: 15.6px;
`;
