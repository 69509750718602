import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  @media (min-width: 1024px) {
    padding-left: 50px;
  }
`;

export const GridContent = styled.div`
  flex-grow: 1;
  border: 1px #cecece solid;
`;

export const DaysContainer = styled.div`
  display: flex;
  flex-basis: 20px;
  align-items: stretch;
`;
export const DayBox = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;
export const DayName = styled.span`
  font-size: ${(props) => props.theme.text.para.small};
  line-height: 1.18;
  color: #000000;
  margin-bottom: 15px;
`;

export const DayDate = styled.span`
  font-size: ${(props) => props.theme.text.para.large};
  font-weight: 600;
  color: #1c1c1c;
`;

export const GridCell = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border: ${(props) => (props.isFirstColumn ? "0px" : "1px")} #cecece solid;
  padding-top: 10px;
  align-items: center;
`;

export const GridText = styled.div`
  flex-shrink: 0;
  font-size: ${(props) => props.theme.text.para.large};
  font-weight: ${(props) => (props.isTodayDate ? "600" : "normal")};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isTodayDate ? props.theme.colors.primaryColor : "white"};
  color: ${(props) => (props.isTodayDate ? "white" : "black")};
`;

export const GridTime = styled.span`
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  color: #4f4f4f;
`;

export const MonthTask = styled.div`
  margin-top: 5px;
  width: 90%;
  background-color: blue;
  color: white;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;

export const MoreText = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
`;

export const NewTask = styled.div`
  position: absolute;
  z-index: 3;
  display: flex;
  padding: 10px;
  align-items: center;
  min-height: 30px;
  box-shadow: ${(props) => props.theme.shadow.medium};
  background-color: white;
`;
