export const text = {
  heading: {
    extraLarge: "3.75rem",
    large: "45px",
    medium: "30px",
    small: "2.5rem",
  },
  title: {
    extraLarge: " 2.29rem",
    large: "1.56rem",
    medium: "1.25rem",
  },
  para: {
    large: "1.125rem",
    medium: "1rem",
    small: "0.875rem",
    extraSmall: "0.75rem",
  },
  btn: {
    large: "1.43rem",
    medium: "1rem",
    small: "0.81rem",
  },
};
