import React, { useState, useCallback } from "react";
import PDF_Img from "../../../../Assets/common/pdf.svg";
import {
  Container,
  Image,
  Link,
  OtherImage,
  BigImage,
  Overlay,
  ImageContainer,
} from "./style";
import Modal from "../../Modal";
import { checkIfUrlIsPDF, getImageUrl } from "../../../../Helper";

const MultipleImageViewer = ({ data }) => {
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [isModalActive, setModalActive] = useState(false);

  const toggleModal = useCallback((url = null) => {
    setModalActive((prevValue) => {
      if (typeof url === "string" && !prevValue) {
        setCurrentImageUrl(url);
      }
      return !prevValue;
    });
  }, []);

  return (
    <Container>
      {data.map((item, idx) => {
        const isPDF = checkIfUrlIsPDF(item.url);
        const imageUrl = getImageUrl(item.url);
        const key = `${imageUrl}_${idx}`;
        if (isPDF) {
          return (
            <Link
              key={key}
              href={imageUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image src={PDF_Img} />
            </Link>
          );
        }
        return (
          <ImageContainer key={key} onClick={() => toggleModal(imageUrl)}>
            <OtherImage src={imageUrl} />
            <Overlay />
          </ImageContainer>
        );
      })}
      <Modal active={isModalActive} closeModal={toggleModal}>
        <BigImage src={currentImageUrl} />
      </Modal>
    </Container>
  );
};

export default MultipleImageViewer;
