export const generateFormData = (values, academicFormValues, professionalFormValues, trainingFormValues) => {
  switch (values.qualificationType) {
    case 'Academic':
      return {
        ...values,
        ...academicFormValues
      }
    case 'Professional':
      return {
        ...values,
        ...professionalFormValues
      }
    case 'TrainingCertificate':
      return {
        ...values,
        ...trainingFormValues
      }
    default: return
  }
};
