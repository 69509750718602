import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useForm from "hooks/useForm";

import { validateEndDate } from "helper/validators";
import { storeWorkReportSearchData } from "modules/NotifiedWorkReport/redux/selector";
import {
  clearSearchData,
  saveSearchData,
} from "modules/NotifiedWorkReport/redux/actionCreator";

function validateDateRange(value, allValues) {
  if (allValues.fromDate) {
    return validateEndDate(allValues.fromDate, value);
  } else {
    return true;
  }
}

function useSearchForm() {
  const dispatch = useDispatch();
  const currentSearchData = useSelector(storeWorkReportSearchData);
  const { formData, values, isFormValid, updateField, resetForm } = useForm({
    fields: {
      fromDate: {},
      toDate: {
        validator: validateDateRange,
        invalidMessage: "To date should be greater than from date",
      },
      collaborators: {},
    },
  });

  const resetSearchForm = () => {
    dispatch(clearSearchData());
    resetForm();
  };

  const storeSearchData = () => {
    dispatch(
      saveSearchData({
        fromDate: values?.fromDate,
        toDate: values?.toDate,
        collaboratorIds:
          values.collaboratorIds && Object.keys(values.collaborators),
      }),
    );
  };

  useEffect(() => {
    updateField("fromDate", currentSearchData?.fromDate);
    updateField("toDate", currentSearchData?.toDate);
    updateField("source", currentSearchData?.source);
    updateField("status", currentSearchData?.status);
  }, [currentSearchData]);

  return {
    isFormValid,
    formData,
    values,
    updateField,
    resetSearchForm,
    storeSearchData,
  };
}
export default useSearchForm;
