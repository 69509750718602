import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import HelpdeskDisplayView from "../../../Modules/Helpdesk/Views/Display";

import { createRouteObject } from "../../../Helper";

const { helpdesk } = RoutePaths.authorized;

const pages = createRouteObject(
  helpdesk,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [HelpdeskDisplayView]
);

const HelpdeskPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact
        path={item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default HelpdeskPages;
