import React, { useState } from "react";

import Icon from "../../Icon";
import {
  Header,
  PostItem,
  StyledProfilePic,
  SubHeaderSection,
  Tag,
} from "./style";
import { MoreOption } from "./PostMoreOption";

import Poll_Img from "assets/employee/poll.svg";
import Quiz_Img from "assets/employee/quiz.svg";

import { postTypes } from "../constants";

const Items = {
  poll: { title: postTypes.poll, image: Poll_Img, background: "#FFF3E0" },
  quiz: { title: postTypes.quiz, image: Quiz_Img, background: "#FFEDED" },
};

export function PostHeader({
  userName,
  userImage,
  postTime,
  postActionBtnProps,
  postId,
  postType,
  pollTimeLeft,
  ...rest
}) {
  const [isActive, setIsActive] = useState(false);

  const toggleBtn = () => {
    setIsActive((prev) => !prev);
  };

  return (
    <Header {...rest}>
      <SubHeaderSection>
        <StyledProfilePic userName={userName} img={userImage?.url} />
        <div className="content d-f f-c">
          <span className="title">{userName}</span>
          <span className="desc">{postTime}</span>
        </div>
      </SubHeaderSection>
      <SubHeaderSection>
        {postType == "poll" && !pollTimeLeft && (
          <Tag $color="#bd1a08">Expired</Tag>
        )}
        {postType && (
          <PostItem style={{ background: Items[postType].background }}>
            <img src={Items[postType].image} />
            <span className="title">{Items[postType].title.toLowerCase()}</span>
          </PostItem>
        )}
        {postActionBtnProps && (
          <button className="down_arrow" onClick={toggleBtn}>
            <Icon
              name={isActive ? "fas fa-angle-up" : "fas fa-angle-down"}
              className="angle_icon"
            />
          </button>
        )}
        {isActive && postActionBtnProps && (
          <MoreOption postId={postId} postActionBtnProps={postActionBtnProps} />
        )}
      </SubHeaderSection>
    </Header>
  );
}
