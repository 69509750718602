import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(240, 100%, 95%, 0.3);
  height: 100%;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  text-align: left;
  & > .heading {
    font-size: ${(props) => props.theme.text.heading.large};
  }
  & > .desc {
    font-size: ${(props) => props.theme.text.title.large};
  }
  & > .footer {
    display: flex;
    gap: 10px;
  }
`;
