import styled, { keyframes } from "styled-components";
import Button from "components/Button";

const SlideIn = keyframes`
from {
    transform: translateY(-200%);
  }
  to {
    transform: translateY(0%);
  }
`;

const SlideOut = keyframes`
 from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-200%);
  }
 `;

const FadeIn = keyframes`
 from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
 `;

const FadeOut = keyframes`
 from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
 `;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${(props) => props.theme.zIndex.modal};
`;

export const Content = styled.div`
  z-index: calc(${(props) => props.theme.zIndex.modal} - 100);
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${(props) => props.theme.shadow.medium};
  transform: translateY(-200%);
  animation: ${({ active }) => (active ? SlideIn : SlideOut)} 0.3s linear 0s
    forwards;
`;

export const ModalButtons = styled(Button)`
  position: absolute;
  top: 15px;
  width: 35px;
  height: 35px;
  font-size: 25px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  z-index: calc(${(props) => props.theme.zIndex.modal} + 500);
  background-color: transparent;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: none;
  }
`;

export const CloseButton = styled(ModalButtons)`
  right: 20px;
`;

export const DownloadButton = styled(ModalButtons)`
  right: 70px;
  font-size: 22px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: calc(${(props) => props.theme.zIndex.modal} - 500);
  animation: ${({ active }) => (active ? FadeIn : FadeOut)} 0.3s ease forwards;
`;

export const SliderButton = styled.div`
  position: absolute;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  border-radius: 50%;
  z-index: 10000;
  box-shadow: ${(props) => props.theme.shadow.medium};
  background-color: transparent;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  & > i {
    font-size: 25px;
  }
`;
export const RightSliderButton = styled(SliderButton)`
  right: 20px;
`;

export const LeftSliderButton = styled(SliderButton)`
  left: 20px;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  max-width: 95vw;
  max-height: 95vh;
  object-fit: contain;
`;
