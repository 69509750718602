import { addressProofType } from "../options";
import { textToCamelCase } from "helper";

export const checkObjectEquality = (newData, oldData) => {
  if (newData && oldData) {
    let newKeys = Object.keys(newData).map((key) => textToCamelCase(key));
    let modifiedNewData = {};
    Object.keys(newData).map((key, index) => {
      if (
        key == "permanent_address_proof_type" ||
        key == "present_address_proof_type"
      ) {
        modifiedNewData[newKeys[index]] = addressProofType
          .map((option) => {
            if (option.value == newData[key]) {
              return option.name;
            }
          })
          .filter((item) => !!item)[0];
      } else {
        modifiedNewData[newKeys[index]] = newData[key];
      }
    });
    return !Object.keys(modifiedNewData)
      .map((key) => {
        if (
          !key.toLowerCase().includes("document") &&
          key !== "currentStep" &&
          modifiedNewData[key] !== oldData[key]
        ) {
          return false;
        }
      })
      .includes(false);
  }
  return false;
};
