import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import Icon from "components/Icon";
import Button from "components/Button";
import Stepper from "components/Stepper";

import { formTags } from "./options";
import { getUserPersonalInfo } from "../../selector";
import { DetailsForm } from "./forms/details";
import { HealthDetailsForm } from "./forms/healthDetails";
import { AddressForm } from "./forms/address";
import { DocumentsForm } from "./forms/documents";
import useInitialData from "./hooks/useInitialData";
import usePersonalInfoDetails from "./hooks/usePersonalInfoDetails";

import { StyledFormGroup, Spacer } from "globalStyles";
import { ButtonContainer, FormHeading, Header } from "../../style/create";

const Form = ({ setIsFormVisible, presentData, stepsCompleted }) => {
  const [currentStep, updateStep] = useState(0);
  const [nextStep, setNextStep] = useState(false);
  const [permanentAddressDocumentRequiredStatus, setPermanentAddressDocumentRequiredStatus] = useState(!presentData?.permanentAddressProofDocumentUrl)
  const [presentAddressDocumentRequiredStatus, setPresentAddressDocumentRequiredStatus] = useState(!presentData?.presentAddressProofDocumentUrl)

  const { initialDate, initialMaritalStatus, initialPermanentAddressProofType, initialPresentAddressProofType } = useInitialData();
  const { isLoading, savePersonalInfoData } = usePersonalInfoDetails();

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const onGoBack = () => {
    updateStep((val) => Math.max(0, val - 1));
  };

  const allowNextStep = () => {
    setNextStep(true);
  }

  const DetailsParams = {
    presentData,
    initialDate,
    initialMaritalStatus
  }

  const HealthDetailsParams = {
    presentData
  }

  const AddressParams = {
    presentData,
    initialPermanentAddressProofType,
    permanentAddressDocumentRequiredStatus,
    initialPresentAddressProofType,
    presentAddressDocumentRequiredStatus
  }

  const displayForm = useCallback(
    () => {
      switch (currentStep) {
        case 0: return <DetailsForm {...DetailsParams} />
        case 1: return <HealthDetailsForm {...HealthDetailsParams} />
        case 2: return <AddressForm {...AddressParams} />
        case 3: return <DocumentsForm />
      }
    },
    [currentStep, DetailsParams, HealthDetailsParams, AddressParams],
  )

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      savePersonalInfoData(values, currentStep, presentData, nextStep, stepsCompleted, updateStep, setIsFormVisible, setNextStep)
    },
    [currentStep, presentData, nextStep, stepsCompleted]
  );

  useEffect(() => {
    if (stepsCompleted) {
      updateStep(Object.values(formTags).indexOf(stepsCompleted) + 1)
    }
  }, [])

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Add your Personal Information</FormHeading>
        <Icon name="fas fa-times" onClick={updateFormVisibilty} />
      </Header>

      <Stepper
        currentStep={currentStep}
        steps={Object.keys(formTags)}
      />

      <Spacer />

      {displayForm()}

      <ButtonContainer>
        {currentStep !== 0 &&
          <Button outline onClick={onGoBack} type="back">Back</Button>
        }
        <Button primary loading={isLoading} disabled={isLoading} type="submit" onClick={allowNextStep}>
          Save
        </Button>
      </ButtonContainer>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const { personalInfoData: presentData } = getUserPersonalInfo(store, props);
  return {
    presentData
  };
};

export default connect(mapStateToProps, null)(Form);
