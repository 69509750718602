import React from "react";
import Icon from "../Icon";

import { Container, StyledButton } from "./style";

const EditContainer = ({ children, onClose, className }) => {
  return (
    <Container className={className}>
      <StyledButton onClick={onClose}>
        <Icon name="fas fa-times" />
      </StyledButton>
      {children}
    </Container>
  );
};
EditContainer.defaultProps = {
  className: "",
};

export default EditContainer;
