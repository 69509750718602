import React from "react";
import Form from "./Form";

import { MainContainer } from "../../../../style/create";
import { useParams } from "react-router";

const AssignLeaveCreateView = ({ updateFormVisibility }) => {
  const params = useParams();

  return (
    <MainContainer>
      <Form updateFormVisibility={updateFormVisibility} editId={params.id} />
    </MainContainer>
  );
};
export default AssignLeaveCreateView;
