import React from 'react';
import Grid from '../../Components/Grid';

import { DetailTableContent } from './style';

export const TableView = ({
  data,
  columnWidth = 300,
  rowHeight = 40,
  style = {}
}) => {
  return (
    <DetailTableContent
      style={{
        height: `${40 * data.length + 10}px`,
        ...style
      }}>
      <Grid
        rows={data.length}
        columns={data[0].length}
        columnWidth={columnWidth}
        rowHeight={rowHeight}
        data={data}
      />
    </DetailTableContent>
  );
};
