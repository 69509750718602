import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 20px;
`;

export const IconItem = styled.button.attrs(() => ({ type: "button" }))`
  color: white;
  font-size: 14px;
  border: none;
  background: transparent;
  font-weight: bold;
  & + button {
    margin-left: 10px;
  }
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: white;
    border-radius: 50%;
  }
`;
