import { getCurrentDataAttributes } from "helper/selectors";
import { createSelector } from "reselect";

const leaveTypesData = (store, props) =>
  store.assignedLeaves.activeLeaveTypes?.data;

const assignedLeavesData = (store) => store.assignedLeaves.allData;
const assignedLeavesRelation = (store) => store.assignedLeaves.allRelation;

export const assignedLeaveDataSelector = createSelector(
  assignedLeavesData,
  assignedLeavesRelation,
  (_, props) => props.id,
  (data, relations, id) => {
    return data[id] ? getCurrentDataAttributes(data[id], relations, data) : {};
  },
);

export const getAllLeaveTypesOptions = createSelector(
  leaveTypesData,
  (data = {}) => {
    const result = [];
    if (data) {
      Object.keys(data).forEach((key) => {
        if (typeof data[key] === 'function') {
          return;
        }
        result.push({
          name: data[key]?.attributes?.leaveType,
          value: data[key]?.id
        });
      });
    }
    return result;
  }
);
