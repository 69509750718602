import styled from "styled-components";

import { placeholderColor } from "../../../GlobalStyles";

interface ButtonProps {
  $active: boolean;
}

export const CommentCreateContainer = styled.div`
  margin: 16px 0;
  display: flex;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  &:focus-within {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.primaryColor};
  }
`;
export const CommentInput = styled.textarea`
  width: 100%;
  min-height: 24px;
  border: none;
  border-radius: 41px;
  padding: 14px 20px;
  padding-bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  resize: none;
  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: ${(props) => props.theme.colors.mediumGrayColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.primaryColor};
    border-radius: 10px;
  }
  ${placeholderColor("#666666")}
`;

export const CommentButton = styled.button<ButtonProps>`
  background-color: transparent;
  color: ${(props) =>
    props.$active ? props.theme.colors.primaryColor : "#bbb"};
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: bold;
  margin: 10px;
  padding: 5px 10px;
  border-radius: 15px;
  border: none;
  cursor: pointer;

  & > div {
    border-color: ${(props) => props.theme.colors.primaryColor} !important;
    border-right-color: transparent !important;
  }
`;
