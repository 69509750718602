import React from "react";
import Form from "./Form";
import { useLocation, useParams } from "react-router";

import { MainContainer } from "../../../../../style/create";
import Routes from "route/Path";

const WorkshiftDetailCreateView = ({ updateFormVisibility }) => {
  const params = useParams();

  const location = useLocation();
  const isEditMode = location.pathname.includes(
    Routes.authorized.workshift.editDetails.path
  );
  const workshiftId = isEditMode ? location.state.workshiftId : params.detailId;
  return (
    <MainContainer>
      <Form
        updateFormVisibility={updateFormVisibility}
        workshiftId={workshiftId}
        editId={isEditMode && params.detailId}
      />
    </MainContainer>
  );
};
export default WorkshiftDetailCreateView;
