export const generateFormData = (values) => {
  const { userEmail_email, userEmail_description, userEmail_isPersonal } =
    values;

  const formData = {
    email: userEmail_email.toLowerCase(),
    is_personal: userEmail_isPersonal?.is_personal || false,
  };
  if (userEmail_description) {
    formData.description = userEmail_description;
  }
  return formData;
};
