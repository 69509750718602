import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reducerStore } from "types/index";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import useAlert from "hooks/useAlert";
import { deleteLeave } from "../Views/Display/AssignLeave/actionCreator";

export const useDeleteLeaveAssign = () => {
  const [isLoading, updateLoading] = useState<boolean>(false);
  const { showAlert } = useAlert();
  const dispatch = useDispatch();
  const companyId = useSelector((store: reducerStore) =>
    getCurrentUserCompany(store, null),
  );
  const isAdmin = useSelector((store: reducerStore) =>
    isCurrentUserIsExecutive(store, null),
  );

  const deleteAssignedLeave = async (assignedLeaveId: string) => {
    try {
      updateLoading(true);
      await dispatch(deleteLeave({ assignedLeaveId, companyId, isAdmin }));
      showAlert({ message: "Assigned leave delete success" });
    } catch (err) {
      showAlert({ message: "Failed to delete assigned leave", type: "error" });
    } finally {
      updateLoading(false);
    }
  };

  return {
    isLoading,
    deleteAssignedLeave,
  };
};
