import styled, { keyframes } from "styled-components";

const getWidth = (props) => {
  const { $size } = props;
  switch ($size) {
    case "xs":
      return "15px";
    case "s":
      return "20px";
    case "m":
      return "50px";
    case "l":
      return "80px";
    default:
      return "10px";
  }
};

const getHeight = (props) => {
  const { $size } = props;
  switch ($size) {
    case "xs":
      return "15px";
    case "s":
      return "20px";
    case "m":
      return "50px";
    case "l":
      return "80px";
    default:
      return "10px";
  }
};

const rotate360 = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerContainer = styled.div`
  width: ${getWidth};
  height: ${getHeight};
  border-radius: 50%;
  border: 3px black solid;
  border-right-color: transparent !important;
  animation: ${rotate360} 0.5s linear infinite;
  ${({ $primary, theme }) => {
    if ($primary) {
      return ` border: 3px ${theme.colors.activityIndicator.primary} solid;`;
    }
  }}

  ${({ $secondary, theme }) => {
    if ($secondary) {
      return ` border: 3px ${theme.colors.activityIndicator.secondary} solid ;`;
    }
  }}
`;
