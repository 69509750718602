import React, { useMemo, useState } from "react";

import ProfilePicTagLists from "./ProfilePicTagLists";

import {
  useFetchTodayBirthdays,
  useFetchUpcomingBirthdays,
} from "../../../../hooks/Birthday";

import ProfileSkeletonGroup from "./ProfileSkeletonGroup";
import BigCake_Img from "assets/employee/birthdayCakeBig.png";
import SmallCake_Img from "assets/employee/birthdayCakeSmall.png";

import { InfoContent, InfoHeader, InfoItem, BigCakeImage } from "./style";

function BirthdaySection() {
  const {
    isLoading: loadingTodayBirthdays,
    allTodayBirthdays,
    hasError: errorInToday,
    fetchNextPage: fetchNextPageToday,
    allDataFethced: todayAllDataFethced,
  } = useFetchTodayBirthdays();
  const {
    isLoading: loadingUpcomingBirthdays,
    allUpcomingBirthdays,
    hasError: errorInUpcoming,
    fetchNextPage: fetchNextPageUpComing,
    allDataFethced: upcomingAllDataFethced,
  } = useFetchUpcomingBirthdays();

  const loadMoreData = (type) => {
    if (type === "today") {
      fetchNextPageToday();
    } else if (type === "upcoming") {
      fetchNextPageUpComing();
    }
  };

  const isEmpty = (value) => {
    if (!Array.isArray(value) || value.length === 0) {
      return true;
    }
    return false;
  };

  const isTodayEmpty = useMemo(() => {
    return isEmpty(allTodayBirthdays);
  }, [allTodayBirthdays]);

  const isUpcomingEmpty = useMemo(() => {
    return isEmpty(allUpcomingBirthdays);
  }, [allUpcomingBirthdays]);

  return (
    <>
      <InfoItem>
        <InfoHeader>Birthdays today</InfoHeader>
        <InfoContent>
          {!loadingTodayBirthdays && isTodayEmpty && (
            <>
              <BigCakeImage src={BigCake_Img} alt="Birthday Cake" />
              <span className="desc">No one is having birthday today</span>
            </>
          )}
          {!errorInToday && allTodayBirthdays.length > 0 && (
            <ProfilePicTagLists
              data={allTodayBirthdays}
              tagImage={SmallCake_Img}
              showMore={!todayAllDataFethced}
              onMoreClick={() => loadMoreData("today")}
              showDOB={true}
            />
          )}
          {loadingTodayBirthdays && <ProfileSkeletonGroup count={3} />}
        </InfoContent>
      </InfoItem>

      <InfoItem>
        <InfoHeader>Next 7 days</InfoHeader>
        <InfoContent>
          {!loadingUpcomingBirthdays && isUpcomingEmpty && (
            <>
              <BigCakeImage src={BigCake_Img} alt="Birthday Cake" />
              <span className="desc">
                No one is having birthday in upcoming days
              </span>
            </>
          )}

          {!errorInUpcoming && allUpcomingBirthdays.length > 0 && (
            <ProfilePicTagLists
              data={allUpcomingBirthdays}
              tagImage={SmallCake_Img}
              showMore={!upcomingAllDataFethced}
              onMoreClick={() => loadMoreData("upcoming")}
              showDOB={true}
            />
          )}
          {loadingUpcomingBirthdays && <ProfileSkeletonGroup count={3} />}
        </InfoContent>
      </InfoItem>
    </>
  );
}

export default BirthdaySection;
