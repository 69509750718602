import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { rootReducer } from "../RootReducer";
import generatorMiddleware from "../Middlewares/generatorMiddleware";
import tableMiddleware from "../Middlewares/MTableMiddleware";

const middleware = [generatorMiddleware, thunk, tableMiddleware];

if (
  process.env.NODE_ENV === "development" ||
  window.localStorage.getItem("logger")
) {
  const logger = require("redux-logger").default;
  middleware.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middleware));

export const dummyStore = createStore((store) => {
  return {
    ...store,
    auth: {},
  };
});
export default store;
