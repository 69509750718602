import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";

import {
  createCountdown as createCountdownActionCreator,
  editCountdown as editCountdownActionCreator,
} from "modules/Countdown/actionCreator";

function useCreateCountdown() {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const createCountdown = async (values, onFormReset) => {
    try {
      updateLoading(true);
      await dispatch(
        createCountdownActionCreator({
          companyId,
          isAdmin,
          formData: values,
        })
      );
      showAlert({ message: "Countdown Created" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
  };
  const editCountdown = async (values, onFormReset, countdownId) => {
    try {
      updateLoading(true);
      await dispatch(
        editCountdownActionCreator({
          companyId,
          isAdmin,
          formData: values,
          countdownId,
        })
      );
      showAlert({ message: "Countdown Updated" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
  };
  return { isLoading, createCountdown, editCountdown };
}

export default useCreateCountdown;
