import { useMemo } from "react";
import { useLocation } from "react-router-dom";

function useGetQuery(ids) {
  const location = useLocation();
  const values = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return ids.map((id) => params.get(id));
  }, [ids, location.search]);
  return values;
}

export default useGetQuery;
