import React from "react";

import GenericModal from "components/GenericModal";
import PollCreate from "./PollCreate";

import { ModalContainer, ModalContent } from "./style";

function PollCreateModal({ onClose, className, ...rest }) {
  return (
    <GenericModal opacity={0.5}>
      <ModalContainer className={className} onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <PollCreate onClose={onClose} {...rest} />
        </ModalContent>
      </ModalContainer>
    </GenericModal>
  );
}

PollCreateModal.defaultProps = {
  className: "",
};
export default PollCreateModal;
