import { createPath, getMyPaths, setNonIterableProperty } from "../../Helper";

export const leaves = {
  //   create: createPath("/create_leaves", "Create"),
  view: createPath("/view_leave_types", "Leave Types"),
  edit: createPath("/edit_leave_type", "", "id"),
  assign: createPath("/assign_leave", "Assign"),
  editAssign: createPath("/edit_assign_leave", "", "id"),
};

setNonIterableProperty(leaves, "getMyPaths", getMyPaths);
