export const MockData = [
  {
    avatarUrl: null,
    id: "1",
    name: "Mock Data",
    employeeId: "123",
    phone: "1234567890",
    email: "abc@ds.ddd",
    status: "active",
    role: "Developer",
    joinedOn: "1/1/2021",
  },
  {
    avatarUrl: null,
    id: "2",
    name: "Mock Data",
    employeeId: "123",
    phone: "1234567890",
    email: "abc@ds.ddd",
    status: "active",
    role: "Developer",
    joinedOn: "1/1/2021",
  },
  {
    avatarUrl: null,
    id: "3",
    name: "Mock Data",
    employeeId: "123",
    phone: "1234567890",
    email: "abc@ds.ddd",
    status: "active",
    role: "Developer",
    joinedOn: "1/1/2021",
  },
  {
    avatarUrl: null,
    id: "4",
    name: "Mock Data",
    employeeId: "123",
    phone: "1234567890",
    email: "abc@ds.ddd",
    status: "active",
    role: "Developer",
    joinedOn: "1/1/2021",
  },
  {
    avatarUrl: null,
    id: "5",
    name: "Mock Data",
    employeeId: "123",
    phone: "1234567890",
    email: "abc@ds.ddd",
    status: "active",
    role: "Developer",
    joinedOn: "1/1/2021",
  },
];
