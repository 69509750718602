import React, { useState, useRef, useCallback } from "react";
import Popover from "../../../PopoverAdvanced2";

import StringContent from "./StringContent";

import { StringDataWrapper } from "./style";

const TextPopover = ({ data, color, heading, ...rest }) => {
  const [isActive, setActive] = useState(false);
  const [isClipped, setClippedStatus] = useState(false);
  const textRef = useRef(null);

  const isElementOverflow = (e) => {
    return e.offsetWidth < e.scrollWidth;
  };

  const activatePopover = (e) => {
    e.stopPropagation();
    if (isClipped) {
      setActive((val) => !val);
    }
  };

  const toggleActive = (e) => {
    e.stopPropagation();
    setActive((val) => !val);
  };

  const onRef = useCallback((ref) => {
    if (!ref) {
      return;
    }
    textRef.current = ref;
    if (isElementOverflow(ref)) {
      setClippedStatus(true);
    }
  }, []);

  return (
    <StringDataWrapper
      style={{
        cursor: isClipped ? "pointer" : "text",
      }}
      $isClipped={isClipped}
      ref={onRef}
      onClick={activatePopover}
      {...rest}
    >
      {data}
      <Popover
        onClose={toggleActive}
        active={isActive}
        anchorElem={textRef.current}
        userPreferedDirection="right"
      >
        <StringContent heading={heading} data={data} />
      </Popover>
    </StringDataWrapper>
  );
};

TextPopover.deaultProps = {
  color: null,
};

export default TextPopover;
