import React, { useEffect, useImperativeHandle } from "react";
import { QuestionCard } from "./QuestionCard";

import InputGroup from "../InputGroup/inputGroup2";
import useForm from "hooks/useForm";

import { QuestionContainer, Question, OptionsBlock } from "./style";

const SingleChoice = React.forwardRef(
  (
    {
      question,
      options,
      groupName,
      required,
      initialValue,
      inputProps,
      ...rest
    },
    ref
  ) => {
    const { formData, values, isFormValid, updateField } = useForm({
      fields: {
        answere: { required },
      },
    });

    useImperativeHandle(ref, () => ({
      isFormValid,
      values,
      formType: "scq",
    }));

    useEffect(() => {
      //  setting initial value if any
      if (initialValue) {
        updateField("answere", initialValue);
      }
    }, [initialValue]);

    return (
      <QuestionContainer {...rest}>
        <Question>{question}</Question>
        <OptionsBlock>
          <InputGroup
            fieldType="radioGroup"
            options={options}
            groupName={groupName}
            {...inputProps}
            horizontal={false}
            {...formData["answere"]}
          />
        </OptionsBlock>
      </QuestionContainer>
    );
  }
);

SingleChoice.defaultProps = {
  inputProps: {},
  required: true,
};

export const SingleChoiceForm = (props) => {
  return <QuestionCard FormComponent={SingleChoice} {...props} />;
};
