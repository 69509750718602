import styled from "styled-components";
import ProfilePic from "../ProfilePic";

const getSize = (size) => {
  switch (size) {
    case "xs":
      return 15;
    case "s":
      return 20;
    case "m":
      return 27;
    case "l":
      return 35;
    default:
      return 27;
  }
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SlicedUserContainer = styled.div`
  display: flex;
`;

export const UserWrapper = styled.div`
  display: flex;
  position: relative;
  left: ${({ $index, $size }) => -$index * getSize($size) * 0.25}px;
  border-radius: 50%;
  border: 2px white solid;
  z-index: ${({ $index }) => $index};
`;

export const AllUserContainer = styled.div`
  max-height: 500px;
  padding: 10px 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.primaryColor};
    border-radius: 10px;
  }
  .all-users {
    padding: 10px 30px;
  }
  .all-users > div {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
`;

export const ProfileCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ProfileCellName = styled.span`
  font-weight: normal;
  margin-left: 12px;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.medium};
`;

export const StyledProfilePic = styled(ProfilePic)`
  width: ${({ $size }) => getSize($size)}px;
  height: ${({ $size }) => getSize($size)}px;
`;

export const ProfileOverlay = styled.div`
  position: absolute;
  z-index: ${({ $index }) => $index * 10};
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.85) 0%,
    rgba(0, 0, 0, 0.54) 100%
  );
  min-width: ${({ $size }) => getSize($size)}px;
  max-width: ${({ $size }) => getSize($size)}px;
  height: ${({ $size }) => getSize($size)}px;
  border-radius: 50%;
  font-size: 0.55rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
