import { useCallback, useRef, useState } from "react";

/*

  This hook takes in a date when starting the timer.
  It returns the time (in hours, minutes and seconds) that has elapsed from the date provided to the hook.
  If no date is provided it takes the current time when the timer starts, to calculate the elapsed time.

*/

export const useTimer = () => {
  const [time, setTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const timerId = useRef(null);

  const startTimer = useCallback((date) => {
    const calcDate = date || new Date();
    function tick() {
      const diff = (new Date().getTime() - new Date(calcDate).getTime()) / 1000;
      if (diff > 0) {
        let remainingSeconds;

        const hours = parseInt(diff / (60 * 60));
        remainingSeconds = diff % (60 * 60);

        const minutes = parseInt(remainingSeconds / 60);
        remainingSeconds = Math.floor(remainingSeconds % 60);

        setTime({ hours, minutes, seconds: remainingSeconds });
      }
    }
    timerId.current = setInterval(tick, 1000);
  }, []);

  const stopTimer = useCallback(() => {
    if (timerId.current) {
      clearInterval(timerId.current);
      setTime({
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    }
  }, []);

  const appendZero = useCallback(
    (digit) => (digit < 10 ? `0${digit}` : digit),
    []
  );

  return {
    time: {
      hours: appendZero(time.hours),
      minutes: appendZero(time.minutes),
      seconds: appendZero(time.seconds),
    },
    startTimer,
    stopTimer,
  };
};
