import React from "react";
import Icon from "../Icon";

import { PaginatorContainer, PageButton } from "./style";
export const TablePaginator = ({
  onPrev,
  onNext,
  prevBtnProps = {},
  nextBtnProps = {},
  ...rest
}) => {
  return (
    <PaginatorContainer {...rest}>
      <PageButton
        className={`light ${prevBtnProps.className}`}
        {...prevBtnProps}
        onClick={onPrev}
      >
        <Icon name="fa fa-arrow-left" />
      </PageButton>
      <PageButton
        className={`dark ${nextBtnProps.className}`}
        {...nextBtnProps}
        onClick={onNext}
      >
        <Icon name="fa fa-arrow-right" />
      </PageButton>
    </PaginatorContainer>
  );
};
