import React, { useState, useCallback } from "react";

import { RectangleSkeleton } from "components/Skeleton";

import ActionArea from "./ActionArea";
import TableData from "./TableData";

import EmptyScreen from "assets/employee/BranchesBlank.png";

import { ViewContainer } from "globalStyles";
import {
  EmptyScreenContainer,
  EmptyScreenImage,
  EmptyScreenMessage,
} from "../../styles/display";

const BranchDisplayView = () => {
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  return (
    <ViewContainer>
      <ActionArea
        onLoading={onDbDataChange}
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
      />
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        onEmptyData={onEmptyData}
      />
      {!isLoading && isEmpty && (
        <EmptyScreenContainer>
          <EmptyScreenImage src={EmptyScreen} alt="No records found" />
          <EmptyScreenMessage>
            There are no company branches added yet
          </EmptyScreenMessage>
        </EmptyScreenContainer>
      )}
      {isLoading && <RectangleSkeleton times={4} />}
    </ViewContainer>
  );
};

export default BranchDisplayView;
