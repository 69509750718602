import { getImageUrl } from "helper/common";
import React, { useCallback, useMemo } from "react";

import {
  ListItem,
  ListFooter,
  ListTextContainer,
  StyledProfilePic,
  ImageContainer,
  Message,
  Time,
  StyledImage,
  StyledButton,
  LoaderProfilePic,
  LoaderText
} from "./style";

const NotificationItem = ({ data, isLoading, ...rest }) => {
  const { userName, message, time, image, imageDesc, buttons, type, readAt } =
    data;

  const imageUrl = useMemo(() => {
    return !!image && getImageUrl(image?.url);
  }, [image]);

  const onMessageClick = useCallback(() => {
    if (typeof data.onClick === "function") {
      const { onClick, ...rest } = data;
      onClick(rest);
    }
  }, [data]);

  const buttonsList = useMemo(() => {
    if (!Array.isArray(buttons)) {
      return null;
    }

    return buttons.map((btn, index) => {
      return (
        <StyledButton
          key={`${btn.id}_${index}`}
          size="auto"
          onClick={btn.onClick}
          {...(btn.otherProps || {})}
        >
          {btn.text}
        </StyledButton>
      );
    });
  }, [buttons]);

  return (
    <ListItem {...rest}>
      {isLoading ? (
        <LoaderProfilePic />
      ) : (
        <StyledProfilePic
          userName={userName}
          showDot={!readAt}
          img={imageUrl}
        />
      )}

      <ListTextContainer>
        {isLoading ? (
          <LoaderText />
        ) : (
          <>
            <Message role="button" onClick={onMessageClick} $type={type}>
              {message}
            </Message>
            <Time>{time}</Time>
          </>
        )}
        {!!buttonsList && <ListFooter>{buttonsList}</ListFooter>}
      </ListTextContainer>

      {/* {!!image && (
        <ImageContainer>
          <StyledImage src={imageUrl} alt={imageDesc} />
        </ImageContainer>
      )} */}
    </ListItem>
  );
};

export default NotificationItem;
