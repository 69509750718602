import { createSelector } from "reselect";

const storePersonalInfo = (store, props) => store.personalInfo.data;

export const getUserPersonalInfo = createSelector(
  storePersonalInfo,
  (data) => {
    let result;
    Object.keys(data).forEach((key) => {
      if (typeof data[key] !== 'function') {
        result = data[key]?.attributes;
      }
    })
    return {
      personalInfoData: result,
      currentStep: result?.currentStep
    };
  }
);
