import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";

import { createLeave } from "../actionCreator";

function useCreateLeave() {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const applyLeave = async (values, onFormReset) => {
    try {
      updateLoading(true)
      await dispatch(createLeave({ companyId, isAdmin, formData: values }));
      showAlert({ message: "Leave Created" });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false)
    }
  };
  return { isLoading, applyLeave };
}

export default useCreateLeave;
