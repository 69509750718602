import React from "react";
import PageRoutes from "./PageRoute";

import Navbar from "../../Common/Components/Navbar";
import Drawer from "../../Common/Components/Drawer";

import { MainContainer, MainContent, DrawerWrapper } from "./style";
const MainRoute = () => {
  return (
    <MainContainer>
      <Navbar />
      <DrawerWrapper>
        <Drawer />
      </DrawerWrapper>
      <MainContent>
        <PageRoutes />
      </MainContent>
    </MainContainer>
  );
};

export default MainRoute;
