import { useMemo } from "react";
import { IMAGE_URL } from "../config.local";

type params = { cdnUrl?: string; url?: string };
type cdnUrlFunc = (props: params) => string | null;

const useCdnUrl: cdnUrlFunc = ({ cdnUrl, url }) => {
  const imageUrl = useMemo(() => {
    return cdnUrl || url
      ? `${cdnUrl ? "https://" : IMAGE_URL}${cdnUrl || url}`
      : null;
  }, [cdnUrl, url]);

  return imageUrl;
};

export default useCdnUrl;
