import { GET_MONTH_SCHEDULE, CREATE_CALENDAR_EVENT } from "../actionTypes";

import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnCreate,
} from "helper";

const initialState = createInitialStoreState({});

export const scheduleReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_MONTH_SCHEDULE}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    case `${CREATE_CALENDAR_EVENT}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    default:
      return store;
  }
};
