import { isToday, getCurrentMonthFirstDate } from "helper";

const Week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const Month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getDateObj = (firstDate, day, isAdd) => {
  const dateCopy = new Date(firstDate);
  dateCopy.setDate(isAdd ? dateCopy.getDate() + day : dateCopy.getDate() - day);
  const currentDate = dateCopy.getDate();
  const currentDay = Week[dateCopy.getDay()];
  const result = {
    date: currentDate,
    day: currentDay,
    dateObj: dateCopy,
    isTodayDate: isToday(dateCopy),
  };
  return result;
};

export const getDateString = (firstDate, lastDate) => {
  const firstDateMonth = firstDate.getMonth();
  const lastDateMonth = lastDate.getMonth();

  const firstDateYear = firstDate.getFullYear();
  const lastDateYear = lastDate.getFullYear();

  if (firstDateYear !== lastDateYear) {
    return `${Month[firstDateMonth]} ${firstDateYear} - ${Month[lastDateMonth]} ${lastDateYear}`;
  }

  if (firstDateMonth !== lastDateMonth) {
    return `${Month[firstDateMonth]} - ${Month[lastDateMonth]} ${lastDateYear}`;
  }

  return `${Month[lastDateMonth]} ${lastDateYear}`;
};

export const getDayDataObj = (actionType, date) => {
  const firstDate = new Date(date);
  firstDate.setDate(date.getDate() - date.getDay());

  const newCurrDate = new Date(date);

  if (actionType === "next") {
    newCurrDate.setDate(newCurrDate.getDate() + 1);
  } else if (actionType === "prev") {
    newCurrDate.setDate(newCurrDate.getDate() - 1);
  }

  const dd = newCurrDate.getDate();
  const mm = Month[newCurrDate.getMonth()];
  const yy = newCurrDate.getFullYear();

  return {
    dateString: `${dd} ${mm} ${yy}`,
    date: dd,
    day: Week[date.getDay()],
    firstDate,
    newCurrDate,
  };
};

const calcRangeSundayDate = (lastRangeFirstDate) => {
  const today = new Date();
  const todayDate = today.getDate();
  let firstDate;
  if (lastRangeFirstDate) {
    firstDate = new Date(lastRangeFirstDate);
    firstDate.setDate(
      lastRangeFirstDate.getDate() - lastRangeFirstDate.getDay()
    );
  } else {
    firstDate = new Date(today);
    firstDate.setDate(todayDate - today.getDay());
  }
  return firstDate;
};

export const getWeekRange = (actionType, lastRangeFirstDate) => {
  const firstDate = calcRangeSundayDate(lastRangeFirstDate);

  // calculating next first date times of the scroll
  let newFirstDate = new Date(firstDate);
  if (actionType === "next") {
    newFirstDate.setDate(newFirstDate.getDate() + 7);
  } else if (actionType === "prev") {
    newFirstDate.setDate(newFirstDate.getDate() - 7);
  } else {
    newFirstDate = firstDate;
  }

  const result = [];
  for (let i = 0; i < 7; i++) {
    result.push(getDateObj(newFirstDate, i, true));
  }

  return {
    result,
    dateString: getDateString(
      result[0].dateObj,
      result[result.length - 1].dateObj
    ),
  };
};

export const getMonthRange = (actionType, lastRangeFirstDate) => {
  const currentMonthFirstDate = getCurrentMonthFirstDate(lastRangeFirstDate);

  // calculating next first date times of the scroll
  if (actionType === "prev") {
    currentMonthFirstDate.setMonth(currentMonthFirstDate.getMonth() - 1);
  } else if (actionType === "next") {
    currentMonthFirstDate.setMonth(currentMonthFirstDate.getMonth() + 1);
  }

  const SundayDate = calcRangeSundayDate(currentMonthFirstDate);

  const result = new Array(5);
  for (let i = 0; i < 5; i++) {
    result[i] = new Array(7);
  }

  let k = 0;
  for (let i = 0; i < 5; i++) {
    for (let j = 0; j < 7; j++) {
      result[i][j] = getDateObj(SundayDate, k, true);
      k++;
    }
  }

  return {
    result,
    firstDate: currentMonthFirstDate,
    dateString: getDateStringForMonthCalendar(currentMonthFirstDate),
  };
};

const getDateStringForMonthCalendar = (date) => {
  return `${Month[date.getMonth()]} ${date.getFullYear()}`;
};

export const getLocalDateString = (date) => {
  return new Date(date).toLocaleDateString();
};

export const moveToFirstDate = (date) => {
  const d = new Date(date);
  d.setDate(1);
  return d;
};

export const moveToNextMonth = (date) => {
  const d = new Date(date);
  d.setMonth(d.getMonth() + 1);
  return d;
};
