import React, { useContext, useMemo } from "react";
import { PopoverContext } from "./context";
import { mergeProps } from "helper";

import { Traiangle } from "./style";
import { calculateTrianglePosition, calculateTriangleRotation } from "./utils";

function PopoverTriangle({ width, color, style, ...rest }) {
  const { position, targetDimensions } = useContext(PopoverContext);

  const triangleStyle = useMemo(() => {
    if (!targetDimensions) {
      return {};
    }
    const rotate = calculateTriangleRotation(position);
    const posStyle = calculateTrianglePosition(
      position,
      targetDimensions,
      width * 2
    );
    return {
      width: `${width}px`,
      transform: `rotate(${rotate}deg)`,
      ...posStyle,
    };
  }, [position, targetDimensions, width]);

  const allStyles = mergeProps(style, triangleStyle);

  return <Traiangle $color={color} style={allStyles} {...rest} />;
}

PopoverTriangle.defaultProps = {
  width: 20,
  color: "white",
};
export default PopoverTriangle;
