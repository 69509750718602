import { extractDateInYYYYMMDD, rupeeToPaisa } from "../../Helper";

export const generateFormData = (values) => {
  const {
    reimbursementCreate_reimbursementExpenseType,
    reimbursementCreate_reimbursementFromDate,
    reimbursementCreate_reimbursementToDate,
    reimbursementCreate_reimbursementDescription,
    reimbursementCreate_reimbursementAmount,
    reimbursementCreate_reimbursementFrom,
    reimbursementCreate_reimbursementTo,
    reimbursementCreate_reimbursementInvoices,
  } = values;

  const reimbursementCreate_reimbursementAssignee = values?.reimbursementCreate_reimbursementAssignee ?
    Object.keys(values?.reimbursementCreate_reimbursementAssignee)[0] :
    "";

  return {
    collaborator_id: reimbursementCreate_reimbursementAssignee,
    expense_type: reimbursementCreate_reimbursementExpenseType,
    from_date: extractDateInYYYYMMDD(reimbursementCreate_reimbursementFromDate),
    to_date: extractDateInYYYYMMDD(reimbursementCreate_reimbursementToDate),
    description: reimbursementCreate_reimbursementDescription,
    amount: rupeeToPaisa(reimbursementCreate_reimbursementAmount),
    from: reimbursementCreate_reimbursementFrom,
    to: reimbursementCreate_reimbursementTo,
    invoices: reimbursementCreate_reimbursementInvoices,
  }
};

export const generateSearchFormData = (values) => {
  const {
    reimbursementSearch_reimbursementType,
    reimbursementSearch_reimbursementFromDate,
    reimbursementSearch_reimbursementToDate,
    reimbursementSearch_reimbursementStatus,
  } = values;

  const reimbursementSearch_reimbursementAssignee = values?.reimbursementSearch_reimbursementAssignee ?
    Object.keys(values?.reimbursementSearch_reimbursementAssignee)[0] :
    "";

  return {
    collaborator_id: reimbursementSearch_reimbursementAssignee,
    expense_type: reimbursementSearch_reimbursementType,
    from_date: extractDateInYYYYMMDD(reimbursementSearch_reimbursementFromDate),
    to_date: extractDateInYYYYMMDD(reimbursementSearch_reimbursementToDate),
    status: reimbursementSearch_reimbursementStatus,
  }
};
