import React from "react";
import DraftNoticePage from "./DraftNotices/DraftNoticePage";
import SentNoticePage from "./SentNotices/SentNoticePage";
import SomethingWrong from "components/SomethingWrong";

import { StyledSkeleton } from "../../styles/view";

const NoticeSliderPage = ({
  isDraft,
  noticeData,
  isExecutive,
  toggleSlider,
  isLoading = false,
  hasError = false,
}) => {
  if (isLoading) {
    return <StyledSkeleton times={3} />;
  }

  if (hasError) {
    return <SomethingWrong />;
  }

  return isDraft ? (
    <DraftNoticePage onSubmit={toggleSlider} noticeData={noticeData} />
  ) : (
    <SentNoticePage isExecutive={isExecutive} noticeData={noticeData} />
  );
};
export default NoticeSliderPage;
