import React, { useCallback, useState } from "react";

import InputGroup from "components/InputGroup";
import Button from "components/Button";

import { statusOptions, enquiryTypeOptions } from "../Create/options";
import { getUserListData } from "common/Api";

import { StyledForm } from "../../style/display";
import { isDateCombinationValid } from "../../../../Helper";

const SearchFilter = ({
  isAdmin,
  onFormSubmit,
  onResetClick,
  collaborator,
  from_date,
  to_date,
  enquiry_type,
  status,
  isSearchOn
}) => {
  const [startDate, setStartDate] = useState("");
  const [InvalidMessage, setInvalidMessage] = useState("");

  const onStartDateChange = useCallback((e) => {
    setStartDate(e.target.value);
  }, []);

  const endDateValidator = useCallback(
    (endDate) => {
      if (startDate) {
        const { isValid, message } = isDateCombinationValid(
          startDate,
          endDate,
          "Start",
          "End"
        );
        setInvalidMessage(message);
        return isValid;
      }
      return true;
    },
    [startDate]
  );

  const resetClick = () => {
    if (typeof onResetClick === "function" && isSearchOn) {
      onResetClick();
    }
    setStartDate("");
  };

  return (
    <>
      <StyledForm onSubmit={onFormSubmit} onReset={resetClick}>
        {isAdmin && (
          <InputGroup
            wrapperClass="enquiry_input_box"
            id="enquirySearch_enquiryCollaborator"
            fieldClass="enquiry_field"
            fieldType="asyncSearchSelect"
            singleSelect
            placeholder="Search for users"
            api={getUserListData}
          />
        )}

        <InputGroup
          id="enquirySearch_enquiryFromDate"
          wrapperClass="enquiry_input_box"
          fieldClass="enquiry_field"
          fieldType="datePicker"
          placeholder="Start date"
          onChange={onStartDateChange}
        />

        <InputGroup
          id="enquirySearch_enquiryToDate"
          wrapperClass="enquiry_input_box"
          fieldClass="enquiry_field"
          fieldType="datePicker"
          placeholder="End date"
          validator={endDateValidator}
          invalidMessage={InvalidMessage}
        />

        <InputGroup
          id="enquirySearch_enquiryEnquiryType"
          wrapperClass="enquiry_input_box"
          fieldClass="enquiry_field"
          fieldType="select"
          options={enquiryTypeOptions}
          defaultSelectText="Select Enquiry Type"
        />

        <InputGroup
          id="enquirySearch_enquiryStatus"
          fieldClass="enquiry_field"
          wrapperClass="enquiry_input_box"
          fieldType="select"
          options={statusOptions}
          defaultSelectText="Select Status"
        />

        <Button primary type="submit" size="sm">
          Search
        </Button>
        <Button outline type="reset" size="sm">
          Reset
        </Button>
      </StyledForm>
    </>
  );
};

export default SearchFilter;
