import { CREATE_PERSONAL_INFO, GET_PERSONAL_INFO, GET_PERSONAL_INFO_BY_COLLABORATOR, UPDATE_PERSONAL_INFO } from "./actiontypes";

import { createAsyncActionCreator } from "helper";

import { createPersonalInfoApi, getPersonalInfoApi, getPersonalInfoByCollaboratorApi, updatePersonalInfoApi } from "./api";

const createPersonalInfoGenrator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_PERSONAL_INFO,
    sideEffect: createPersonalInfoApi({ companyId, isAdmin, formData }),
  };
};

export const [createPersonalInfo] = createAsyncActionCreator([
  createPersonalInfoGenrator,
]);

const updatePersonalInfoGenrator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: UPDATE_PERSONAL_INFO,
    sideEffect: updatePersonalInfoApi({ companyId, isAdmin, formData }),
  };
};

export const [updatePersonalInfo] = createAsyncActionCreator([
  updatePersonalInfoGenrator,
]);

const getPersonalInfoGenerator = function* ({
  companyId,
  isAdmin,
}) {
  yield {
    action: GET_PERSONAL_INFO,
    sideEffect: getPersonalInfoApi({ companyId, isAdmin }),
  };
};

export const getPersonalInfo = createAsyncActionCreator(
  getPersonalInfoGenerator
);

const getPersonalInfoByCollaboratorGenerator = function* ({
  companyId,
  userId,
  isAdmin,
}) {
  yield {
    action: GET_PERSONAL_INFO_BY_COLLABORATOR,
    sideEffect: getPersonalInfoByCollaboratorApi({ companyId, userId, isAdmin }),
  };
};

export const getPersonalInfoByCollaborator = createAsyncActionCreator(
  getPersonalInfoByCollaboratorGenerator
);
