import React, { useMemo } from "react";

import Input from "../Input";
import ColorPicker from "../Input/colorPicker";
import TextArea from "../Input/TextArea";
import Select from "../Input/Select";
import AdvanceSelect from "../Input/AdvanceSelect";
import MultipleSelect from "../Input/MultipleSelect";
import AsyncMultipleSelect from "../Input/AsyncMultipleSelect";
import SearchSelect from "../Input/SearchSelect";
import AsyncSearchSelect from "../Input/AsyncSearchSelect";
import DatePicker from "../Input/DatePicker";
import DateRangePicker from "../Input/DateRangePicker";
import RadioGroup from "../Input/RadioGroup";
import Radio from "../Input/RadioGroup/Radio";
import CheckBoxGroup from "../Input/CheckBoxGroup";
import ChipsGroup from "../Input/ChipsGroup";
import FilePicker from "../Input/FilePicker";
import GoogleFilePicker from "../Input/GoogleFilePicker";
import Error from "../Error";

import { InputWrapper, Label } from "./style";
import LocationSearch from "../../../Modules/Map/Views/LocationSearch";
/**
 * 1. id should always contain underscore before actual id name. eg u_title
 */
const InputGroup = ({
  name,
  label,
  invalidMessage,
  success,
  error,
  fieldType,
  wrapperClass,
  className,
  options,
  children,
  secondaryLabel,
  wrapperStyle,
  errorMessage,
  ...otherProps
}) => {
  const Field = useMemo(() => {
    switch (fieldType) {
      case "textArea":
        return TextArea;
      case "select":
        return Select;
      case "advanceSelect":
        return AdvanceSelect;
      case "multiSelect":
        return MultipleSelect;
      case "asyncMultiSelect":
        return AsyncMultipleSelect;
      case "searchSelect":
        return SearchSelect;
      case "asyncSearchSelect":
        return AsyncSearchSelect;
      case "datePicker":
        return DatePicker;
      case "dateRangePicker":
        return DateRangePicker;
      case "radioGroup":
        return RadioGroup;
      case "radio":
        return Radio;
      case "checkboxGroup":
        return CheckBoxGroup;
      case "chipsGroup":
        return ChipsGroup;
      case "file":
        return FilePicker;
      case "googleFilePicker":
        return GoogleFilePicker;
      case "color":
        return ColorPicker;
      case "locationSearch":
        return LocationSearch;
      default:
        return Input;
    }
  }, [fieldType]);

  return (
    <InputWrapper style={wrapperStyle} className={className || wrapperClass}>
      <Label secondaryLabel={secondaryLabel} htmlFor={name}>
        {label}
      </Label>
      <Field
        success={success}
        error={error}
        options={options}
        name={name}
        id={name}
        {...otherProps}
      />
      <Error>{error && errorMessage}</Error>
      {children}
    </InputWrapper>
  );
};

InputGroup.defaultProps = {
  fieldType: "text",
  wrapperClass: "",
  options: [],
  secondaryLabel: false,
  wrapperStyle: {},
};

export default InputGroup;
