import { combineReducers } from "redux";
import { alertReducer, authReducer, appReducer } from "../Common/Reducers";
import { accessAssetsReducer } from "../Modules/CompanyAssets/reducer";
import { CombinedLocalStore } from "../Helper";
import { departmentReducer } from "../Modules/Department/reducer";
import { jobTitleReducer } from "../Modules/JobTitle/reducer";
import { userReducer } from "../Modules/User/reducer";
import { noticesReducer } from "../Modules/Notice/Reducer";
import { calendarReducer } from "../Modules/Calendar/reducer";
import { holidaysReducer } from "../Modules/Holiday/reducer";
import { holidayYearsReducer } from "../Modules/HolidayYear/reducer";
import { reimbursementsReducer } from "../Modules/Reimbursement/reducer";
import { personalInfoReducer } from "../Modules/PersonalInfo/reducer";
import { familyDetailsReducer } from "../Modules/FamilyDetails/reducer";
import { emergencyContactReducer } from "../Modules/EmergencyContact/reducer";
import { bankAccountsReducer } from "../Modules//BankAccounts/reducer";
import { upiReducer } from "../Modules/UpiDetails/reducer";
import { emailsReducer } from "../Modules/Email/reducer";
import { notificationReducer } from "../Modules/Notification/reducer";
import { leadsReducer } from "../Modules/Leads/reducer";
import { mapReducer } from "../Modules/Map/reducer";
import { followSubordinateReducer } from "../Modules/FollowSubordinate/Reducer";
import { enquiryReducer } from "../Modules/Helpdesk/Reducer";
import { attendanceReducer } from "../Modules/Attendence/Reducer";
import { breaksReducer } from "../Modules/Breaks/Reducer";
import { branchReducer } from "../Modules/CompanyBranch/reducer";
import { separationReducer } from "../Modules/Separation/reducer";
import { teamsReducer } from "../Modules/Teams/Reducer";
import { qualificationsReducer } from "../Modules/Qualifications/reducer";
import PostDetailReducer from "../Modules/PostDetail/reducer";
import PostsReducer from "../Modules/WelcomePage/redux/Posts/reducer";
import PostCommentsReducer from "../Modules/WelcomePage/redux/PostComments/reducer";

import BirthdayReducer from "../Modules/WelcomePage/redux/Birthday/reducer";
import AnniversaryReducer from "../Modules/WelcomePage/redux/anniversary/reducer";
import NewJoineeReducer from "../Modules/WelcomePage/redux/Joinee/reducer";
import DashboardReducer from "../Modules/WelcomePage/redux/dashboard";
import { leaveTypeReducer } from "../Modules/Leaves/Views/Display/LeaveType/reducer";
import { assignedLeavesReducer } from "../Modules/Leaves/Views/Display/AssignLeave/reducer";
import { leaveRequisitionReducer } from "../Modules/Leave/Views/Reducer/leavesRequisition";
import { collaboratorLeavesReducer } from "../Modules/Leave/Views/Reducer/collaboratorLeaves";
import surveyReducer from "../Modules/SurveyModal/redux/reducer";
import { leaveManagementReducer } from "modules/LeaveManagement/redux/reducer";
import { ledgersReducer } from "modules/Ledger/Reducer/main";
import { notifiedWorkReportsReducer } from "modules/NotifiedWorkReport/redux/reducer";
import { commentsReducer } from "common/Reducers/comment";
import { countdownReducer } from "modules/Countdown/reducer";
import reactionsReducer from "components/Posts/view/PostReactionsModalView/redux/AllReactions/reducer";
import workshiftReducer from "modules/Workshift/View/Display/WorkshiftType/reducer";
import { userProfileReducer } from "modules/UserProfile/reducer";

export const combinedReducer = combineReducers({
  accessAssets: accessAssetsReducer,
  auth: authReducer,
  app: appReducer,
  alert: alertReducer,
  department: departmentReducer,
  jobTitle: jobTitleReducer,
  user: userReducer,
  notice: noticesReducer,
  calendar: calendarReducer,
  holidays: holidaysReducer,
  holidayYears: holidayYearsReducer,
  reimbursements: reimbursementsReducer,
  personalInfo: personalInfoReducer,
  familyDetails: familyDetailsReducer,
  emergencyContact: emergencyContactReducer,
  bankAccounts: bankAccountsReducer,
  upi: upiReducer,
  email: emailsReducer,
  notifications: notificationReducer,
  leads: leadsReducer,
  map: mapReducer,
  followSubordinate: followSubordinateReducer,
  enquiries: enquiryReducer,
  attendance: attendanceReducer,
  breaks: breaksReducer,
  branch: branchReducer,
  separation: separationReducer,
  teams: teamsReducer,
  qualifications: qualificationsReducer,
  postDetail: PostDetailReducer,
  posts: PostsReducer,
  postComments: PostCommentsReducer,
  birthdays: BirthdayReducer,
  anniversaries: AnniversaryReducer,
  joinees: NewJoineeReducer,
  dashboard: DashboardReducer,
  leaveTypes: leaveTypeReducer,
  workshift: workshiftReducer,
  assignedLeaves: assignedLeavesReducer,
  leaveRequisition: leaveRequisitionReducer,
  collaboratorLeaves: collaboratorLeavesReducer,
  survey: surveyReducer,
  leaveManagement: leaveManagementReducer,
  ledger: ledgersReducer,
  notifiedWorkReports: notifiedWorkReportsReducer,
  comments: commentsReducer,
  countdown: countdownReducer,
  postReactions: reactionsReducer,
  userProfile: userProfileReducer,
});

export const rootReducer = CombinedLocalStore({
  reducer: combinedReducer,
  whitelist: ["auth"],
});
