import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const createFamilyDetailsApi = ({ companyId, formData }) => {
  return requestData(`/employee/companies/${companyId}/family_details`, {
    method: "POST",
    body: {
      family_detail: {
        name: formData.name,
        relationship: formData.relation,
        date_of_birth: formData.date_of_birth,
        phone: formData.phone,
        email: formData.email,
        occupation: formData.occupation,
        medical_claim_inclusion: formData.medical_claim_inclusion,
      },
    },
    isFormData: true,
  });
};

export const getFamilyDetailsApi = ({ companyId, pageNumber, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/family_details?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getFamilyDetailsByCollaboratorApi = ({ companyId, pageNumber, userId, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_family_details_by_collaborator?collaborator_id=${userId}&page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
