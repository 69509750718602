import styled from "styled-components";

export const TabsHeaderContainer = styled.div`
  display: flex;
  min-height: 50px;
  width: auto;
  overflow-x: auto;
`;
export const Tab = styled.button`
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.tab.text};
  font-weight: 500;
  font-size: ${({ theme }) => theme.text.para.small};
  border-radius: 8px 8px 0px 0px;
  border: none;
  background-color: white;
  cursor: pointer;

  ${({ $active, theme }) => {
    if ($active) {
      return `
            background-color: ${theme.colors.tab.active.background};
            border-bottom: 4px solid ${theme.colors.tab.active.border};
           `;
    }
  }}
`;
