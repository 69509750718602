import { useEffect, useMemo } from "react";
import { getOncefetched } from "common/Selectors/store";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { createTableDataSelector } from "common/Selectors/MTable";

import usePaginator from "hooks/usePaginator";
import usePagination from "hooks/usePagination";

import { fetchPeoplePresentToday } from "../../redux/attendance/actionCreator";
import { useSelector } from "react-redux";
import { transformCollaboratorMatrixToArray } from "../../utils";
const onceFetchedSelector = getOncefetched("dashboard.attendance.present");

const todayPresentSelector = createTableDataSelector({
  tableName: "dashboard.attendance.present",
  blackList: { "Sl No": true },
  fetchAllData: true,
});

export function useFetchPresentToday() {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    allPagesFetched,
    errorOccured,
  } = usePagination({
    actionCreator: fetchPeoplePresentToday,
    tableName: "dashboard.attendance.present",
  });

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const isOnceFetched = useSelector(onceFetchedSelector);
  const { rows: data, rowIdMap } = useSelector(todayPresentSelector);
  const getPresenteesCount = useSelector(
    (store) => store.dashboard.attendance.present.count
  );

  const { fetchFirstPage, fetchNextPage } = usePaginator({
    paginatorApi: getNextPage,
    extraParams: { companyId, isAdmin },
  });

  useEffect(() => {
    if (!isOnceFetched) {
      fetchFirstPage();
    }
  }, [isOnceFetched]);

  const peoplesPresentToday = useMemo(() => {
    const allData = transformCollaboratorMatrixToArray(data, rowIdMap);
    return allData.reverse();
  }, [data]);
  return {
    isLoading: loadingNextPage || loadingPrevPage,
    peoplesPresentToday,
    fetchNextPage,
    allDataFethced: allPagesFetched,
    hasError: errorOccured,
    presenteesCount: getPresenteesCount,
  };
}
