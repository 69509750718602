import styled from "styled-components";
import { StyledActionArea } from "globalStyles";
import EditContainer from "components/EditContainer";
import EditDetails from "components/EditDetails";

export const EnhancedActionArea = styled(StyledActionArea)`
  flex-flow: row;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0;
  margin: 0;

  & > button {
    height: 35px;
    padding: 0 20px;
    width: auto;
    margin-left: 10px;
  }

  & div {
    display: flex;
    align-items: center;
    margin: 0;
  }
  
  &  select {
    padding: 0 10px;
    width: 200px;
    height: 35px;
    margin: 0 30px;
  }
`;

export const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-flow: column;
  position: relative;
`;

export const StyledEditContainer = styled(EditContainer)`
  padding: 20px;
  max-width: 800px;
  padding-top: 40px;
`;

export const StyledEditDetails = styled(EditDetails)`
  position: absolute;
  right: 10px;
  top: 10px;
  width: auto;
  z-index: 1000;
`;

export const EmptyScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const EmptyScreenImage = styled.img`
  width: 80%;
  height: 80%;
`;
