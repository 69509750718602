import { GET_SPECIFIC_NOTICE } from "../actiontypes";
import {
  createNewStoreOnGetData,
  createInitialStoreState,
} from "../../../Helper";

const initialData = createInitialStoreState();

export const singleNoticeReducer = (store = initialData, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_SPECIFIC_NOTICE}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    default:
      return store;
  }
};
