import { getCurrentDataAttributes } from "helper/selectors";
import { createSelector } from "reselect";

const leaveTypesData = (store, props) =>
  store.leaveRequisition.collaboratorLeaves;
const storeAllLeaves = (store, props) => store.leaveRequisition.data;
const storeAllLeaveIds = (store, props) => store.leaveRequisition.dataIds;
const storeAllLeavesRelations = (store, props) =>
  store.leaveRequisition.relation;
const currentLeaveId = (store, props) => props?.leaveId;

export const getAllCollaboratorLeaveTypesOptions = createSelector(
  leaveTypesData,
  (data = {}) => {
    const result = [];
    if (data.data) {
      Object.keys(data.data).forEach((key) => {
        if (typeof data.data[key] === "function") {
          return;
        }
        let leavePlanId = data.data[key]?.relationships?.leavePlan?.data?.id;
        result.push({
          name: data?.relation[`${leavePlanId}_leavePlans`]?.attributes
            ?.leaveType,
          value: data.data[key]?.id
        });
      });
    }
    return result;
  }
);

export const getAllLeaves = createSelector(
  storeAllLeaves,
  storeAllLeaveIds,
  storeAllLeavesRelations,
  (allLeaves, leaveIds, relations) => {
    let data = [];
    Object.keys(allLeaves).map((key) => {
      if (typeof allLeaves[key] != "function" && leaveIds?.includes(key)) {
        const leave = allLeaves[key];
        data.push(getCurrentDataAttributes(leave, relations, allLeaves));
      }
    });
    return data;
  }
);

export const getCurrentCollaboratorLeaveDetails = createSelector(
  getAllLeaves,
  currentLeaveId,
  (leaves, leaveId) => {
    let leaveDetails = {};
    leaves.forEach((leave) => {
      if (leaveId && leave?.id == leaveId) {
        leaveDetails = leave;
      }
    });
    return leaveDetails;
  }
);
