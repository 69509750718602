import React, { useMemo } from "react";
import ActivityIndicator from "../../ActivityIndicator";

import EmptyResult from "./EmptyResult";

import { ListItem } from "../../List";
import { ListGroupContainer, LoadingContainer } from "./style";

const ListGroup = React.forwardRef((props, ref) => {
  const {
    data = [],
    selectedData = {},
    onChange,
    isInBottom,
    isLoading,
  } = props;
  const list = useMemo(() => {
    return data.map((item) => {
      return (
        <ListItem
          key={item.id}
          data={item}
          onSelect={onChange}
          selected={selectedData && selectedData[item.id]}
        />
      );
    });
  }, [data, selectedData]);

  return (
    <ListGroupContainer isInBottom={isInBottom} ref={ref}>
      {isLoading && (
        <LoadingContainer>
          <ActivityIndicator size="s" color="#3f6ad8" />
        </LoadingContainer>
      )}
      {!isLoading && list.length === 0 && <EmptyResult />}
      {!isLoading && list}
    </ListGroupContainer>
  );
});

export default ListGroup;
