import styled from "styled-components";
import { StyledActionArea, TableContainer, ViewContainer } from "globalStyles";
import FormGroup from "components/FormGroup";
import Icon from "../../../Common/Components/Icon";
import { ImageComponent } from "../../../Common/Components/Image";
import PageSlider from "components/PageSlider";
import Button from "components/Button";

export const EnhancedActionArea = styled(StyledActionArea)`
  display: flex;
  margin: 0px;
  padding: 0px 10px;
  @media (min-width: 768px) {
    justify-content: flex-end;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: black;
`;

export const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
`;

export const GraphPageSlider = styled(PageSlider)`
  top: 0px;
  left: 0px;
  border: none;
`;

export const ExpenseChartButton = styled(Button)`
  max-width: 200px;
  min-width: 150px;
  width: auto;
  font-weight: normal;
`;

export const TableHeading = styled.p`
  margin: 0px;
  font-size: ${(props) => props.theme.text.title.medium};
  margin-right: 10px;
`;

export const DetailTableContainer = styled(ViewContainer)`
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const DetailTableContentsLeft = styled.div`
  // grid-column: 1/2;
`;

export const DetailTableContentsRight = styled.div`
  // grid-column: 2/3;
`;

export const Description = styled.p`
  color: white;
  max-width: 300px;
  margin: 10px 0px;
`;
export const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  @media (min-width: 768px) {
    min-width: 420px;
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  flex-direction: column;
  text-align: left;

  @media (max-width: 768px) {
    margin-top: 70px;
  }
`;

export const BackButton = styled.button`
  cursor: pointer;
  border: none;
  margin-right: 20px;
  font-size: large;
  color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.button.primary.background};
`;

export const InfoIcon = styled(Icon)`
  margin-left: 20px;
  font-size: large;
  color: ${(props) => props.theme.colors.button.primary.background};
`;

export const Amount = styled.span`
  font-size: ${(props) => props.theme.text.para.large};
  margin-top: 10px;
  font-weight: bold;
`;

export const HeadingLeft = styled.div`
  display: flex;
  text-align: left;
  justify-content: space-between;
  margin: 0px 10px;
`;

export const SubHeading = styled.div`
  display: flex;
  align-items: center;
`;

export const TotalCount = styled.p`
  font-size: ${(props) => props.theme.text.para.medium};
  margin: 0px;
  width: 100%;
  margin-right: 20px;
`;

export const HeadingRight = styled.div`
  display: flex;
  margin-top: 10px;
  height: fit-content;
  border-radius: 12px;
  font-size: 700;
`;

export const Item = styled.div`
  padding: 20px;
  margin: 0px 10px;
  width: 100%;
  overflow: hidden;
  display: flex;
  background: ${(props) => props.$color};
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  flex-direction: column;
`;

export const MemberContainer = styled.div`
  padding: 6px;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const DescriptionEllipsis = styled.div`
  text-overflow: ellipsis;
  text-align: left;
  width: 100%;
`;

export const Heading = styled.p`
  font-size: ${(props) => props.theme.text.btn.large};
  margin: 10px 0px;
`;

export const Circle = styled.div`
  width: 45px;
  height: 45px;
  position: absolute;
  border-radius: 50%;
  top: ${(props) => props.$top && -30}px;
  right: ${(props) => props.$top && 10}px;
  left: ${(props) => !props.$top && 10}px;
  bottom: ${(props) => !props.$top && -30}px;
  background: ${(props) => props.$color};
`;

export const AsyncSearchField = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  & > div > div > div {
    height: 100px;
  }

  @media (min-width: 768px) {
    min-width: 420px;

    & div {
      max-width: 420px;
    }
  }
`;

export const EmptyScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    height: 35px;
  }
`;

export const EmptyScreenImage = styled(ImageComponent)`
  width: 40%;
  height: 40%;
  object-fit: contain;
`;

export const EmptyScreenMessage = styled.span`
  margin: 30px;
  font-size: ${(props) => props.theme.text.title.medium};
`;

export const PaginatorAndCreateBox = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (min-width: 767px) {
    flex-wrap: nowrap;
  }
`;

export const Cell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const CellIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.primaryColor};
  margin-left: 10px;
  font-size: 15px;
`;

export const UpdateStatusButton = styled.button`
  background: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
  font-size: large;
`;

export const StyledTableContainer = styled(TableContainer)`
  min-height: 400px;
`;

export const CollabroratorCell = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  height: 100%;
  & > .bar {
    width: 2px;
    height: 100%;
  }
`;

export const TableHeaderContent = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px #ededed solid;
  width: 100%;
`;
