import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { PostFooter } from "./PostFooter";
import { PostHeader } from "./PostHeader";
import { PostDescription } from "./PostDescription";
import { PostPoll } from "./PostPoll";
import { PostImages } from "./PostImages";
import { PostQuiz } from "./PostQuiz";
import { CommentList } from "./CommentList";

import { CardContainer, CommentLoadMore } from "./style";
import { CreateComment } from "../create/CommentCreate";
import { postTypes } from "../constants";

export function PostCard({
  id,
  userName,
  userImage,
  postTime,
  images,
  reactionData,
  description,
  pollData,
  quizData,
  comments,
  totalCommentsCount,
  onLoadMoreComments,
  onComment,
  getCreateReactionProps,
  isCreatingComment,
  postActionBtnProps,
  ...rest
}) {
  // const commentsCount = Array.isArray(comments) ? comments.length : 0;
  const reactionProps = getCreateReactionProps();

  const onReactToPost = (type) => {
    reactionProps && reactionProps.reactToPost({ type, postId: id });
  };

  const onRemovingReaction = ({ reactionType, reactionId }) => {
    reactionProps &&
      reactionProps.removeReactFromPost({
        reactionType,
        reactionId,
        postId: id,
      });
  };

  const postType = useMemo(() => {
    if (pollData) return postTypes.poll.toLowerCase();
    else if (quizData) return postTypes.quiz.toLowerCase();
    return "";
  }, [pollData, quizData]);

  return (
    <CardContainer {...rest}>
      <PostHeader
        userName={userName}
        userImage={userImage}
        postTime={postTime}
        postActionBtnProps={postActionBtnProps}
        postId={id}
        postType={postType}
        pollTimeLeft={pollData?.timeLeft}
      />
      {!!description && <PostDescription description={description} />}
      {!!pollData && <PostPoll {...pollData} />}
      {!!quizData && <PostQuiz {...quizData} />}
      {!!images && <PostImages images={images} />}
      <PostFooter
        postId={id}
        reactionData={reactionData}
        comments={totalCommentsCount}
        onReaction={onReactToPost}
        onRemovingReaction={onRemovingReaction}
        onLoadMoreComments={onLoadMoreComments}
      />
      <CreateComment
        postId={id}
        onComment={onComment}
        isCreatingComment={isCreatingComment}
      />
      {!!comments && totalCommentsCount > 0 && (
        <CommentList maxToShow={2} comments={comments} />
      )}
      {!!comments && totalCommentsCount > 2 && (
        <CommentLoadMore onClick={() => onLoadMoreComments(id)}>
          Load more comments..
        </CommentLoadMore>
      )}
    </CardContainer>
  );
}

PostCard.defaultProps = {
  onLoadMoreComments: null,
  onComment: null,
  totalCommentsCount: 0,
  isCreatingComment: false,
  getReactionProps: null,
};

PostCard.propTypes = {
  pollData: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    votes: PropTypes.number.isRequired,
    timeLeft: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    onPoll: PropTypes.func,
  }),
  quizData: PropTypes.shape({
    question: PropTypes.string.isRequired,
    // desc: PropTypes.string.isRequired,
    votes: PropTypes.number.isRequired,
    // timeLeft: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    onPoll: PropTypes.func,
  }),
};
