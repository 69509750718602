import { CREATE_LEAD, GET_LEADS, GET_LEADS_BY_COLLABORATOR, RESET_TO_PREV_DATA, SEARCH_LEADS, SET_SEARCH_PARAMS } from "./actiontypes";
import { RESET_USER_ID } from "../User/actiontypes";
import { createInitialStoreState, createNewStoreOnCreate, createNewStoreOnGetData, createPrevData } from "../../Helper";

const initialState = createInitialStoreState(
  {
    prevData: {},
    searchData: {
      fromStartDate: "",
      toStartDate: "",
      fromEndDate: "",
      toEndDate: "",
      status: "",
      leadType: "",
      leadBy: "",
    },
    isSearchOn: false
  },
  false
);

export const leadsReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_LEADS}_SUCCESS`:
    case `${GET_LEADS_BY_COLLABORATOR}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }
    case `${CREATE_LEAD}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case `${SEARCH_LEADS}_SUCCESS`: {
      const prevData = createPrevData(store);
      const data = createNewStoreOnGetData(payload, store, false);
      return {
        ...data,
        prevData,
      };
    }

    case SET_SEARCH_PARAMS: {
      const { fromStartDate, toStartDate, fromEndDate, toEndDate, status, leadType, leadBy } = payload;
      return {
        ...store,
        searchData: {
          fromStartDate,
          toStartDate,
          fromEndDate,
          toEndDate,
          status,
          leadType,
          leadBy,
        },
        isSearchOn: true
      };
    }

    case RESET_TO_PREV_DATA: {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
        searchData: {
          fromStartDate: "",
          toStartDate: "",
          fromEndDate: "",
          toEndDate: "",
          status: "",
          leadType: "",
          leadBy: "",
        },
        isSearchOn: false
      };
    }

    case RESET_USER_ID: {
      return {
        ...store,
        onceFetched: false,
      };
    }

    default:
      return store;
  }
};
