import React, { useMemo } from "react";
import Icon from "../../Icon";

import { MonthIndex } from "constants";

import {
  ActionRowContainer,
  ActionButton,
  PageBox,
  PageActionBtn,
} from "./style";

const ActionRow = ({
  isMonth = true,
  currentYear,
  currentMonth,
  pageStartYear,
  pageEndYear,
  onNextYear,
  onNextMonth,
  onPrevMonth,
  onPrevYear,
  toggleView,
  dateRange,
  isFromDate
}) => {
  const btnText = useMemo(() => {
    if (isMonth) {
      return `${MonthIndex[currentMonth]} ${currentYear}`;
    } else {
      return `${pageStartYear}-${pageEndYear}`;
    }
  }, [currentMonth, currentYear, isMonth, pageEndYear, pageStartYear]);

  const fromDateDay = useMemo(() => dateRange.fromDate.day, [dateRange.fromDate]);
  const fromDateMonth = useMemo(() => dateRange.fromDate.month, [dateRange.fromDate]);
  const fromDateYear = useMemo(() => dateRange.fromDate.year, [dateRange.fromDate]);

  const toDateDay = useMemo(() => dateRange.toDate.day, [dateRange.toDate]);
  const toDateMonth = useMemo(() => dateRange.toDate.month, [dateRange.toDate]);
  const toDateYear = useMemo(() => dateRange.toDate.year, [dateRange.toDate]);

  const isButtonDisabledPrev = useMemo(() => {
    if (!fromDateDay && !toDateDay)
      return false;

    if (isMonth) {
      if (isFromDate === false) {
        if (currentMonth === fromDateMonth && currentYear === fromDateYear)
          return true;
      }
      return false;
    }

    if (isFromDate === false) {
      if (fromDateYear <= pageEndYear && fromDateYear >= pageStartYear)
        return true;
    }
    return false;
  }, [fromDateDay, isMonth, currentMonth, toDateDay, fromDateYear, fromDateMonth, pageEndYear, pageStartYear, isFromDate])

  const isButtonDisabledNext = useMemo(() => {
    if (!fromDateDay && !toDateDay)
      return false;

    if (isMonth) {
      if (isFromDate) {
        if (currentMonth === toDateMonth && currentYear === toDateYear)
          return true;
      }
      return false;
    }

    if (isFromDate) {
      if (toDateYear <= pageEndYear && toDateYear >= pageStartYear)
        return true;
    }
    return false;
  }, [fromDateDay, isMonth, currentMonth, toDateDay, toDateMonth, toDateYear, pageEndYear, pageStartYear, isFromDate])

  return (
    <ActionRowContainer>
      <ActionButton type="button" onClick={toggleView}>
        {btnText}
      </ActionButton>

      <PageBox>
        <PageActionBtn
          disabled={isButtonDisabledPrev}
          type="button"
          onClick={isMonth ? onPrevMonth : onPrevYear}
        >
          <Icon name="fas fa-chevron-left" />
        </PageActionBtn>

        <PageActionBtn
          disabled={isButtonDisabledNext}
          type="button"
          onClick={isMonth ? onNextMonth : onNextYear}
        >
          <Icon name="fas fa-chevron-right" />
        </PageActionBtn>
      </PageBox>
    </ActionRowContainer>
  );
};

export default ActionRow;
