import requestData from "ApiHandler/index";
import { createAuthUrl } from "helper/index";
import { ISurveyDetails, ISubmitSurveyDetails } from "../types";

export const fetchSurveyDataApi = ({
  companyId,
  isAdmin,
  surveyId,
}: ISurveyDetails) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_survey?survey_id=${surveyId}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const submitSurveyDataApi = ({
  companyId,
  isAdmin,
  surveyId,
  formData,
}: ISubmitSurveyDetails) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/surveys/${surveyId}/responses`,
  });
  const body = {
    response: {
      answer: formData.answer,
      survey_question_id: formData.questionId,
    },
  };
  return requestData(url, {
    method: "POST",
    body,
    isFormData: true,
  });
};
