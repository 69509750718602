import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useAlert from "hooks/useAlert";

import { createComment as createCommentActionCreator } from "common/ActionCreators";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

function useCommentDetails() {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const dispatch = useDispatch();
  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const createComment = async ({
    commentableId,
    commentableType,
    message,
    onSuccess,
  }) => {
    try {
      updateLoading(true);
      await dispatch(
        createCommentActionCreator({
          companyId,
          isAdmin,
          commentableId,
          commentableType,
          message,
        }),
      );
      typeof onSuccess == "function" && onSuccess(true);
    } catch (err) {
      showAlert({ message: err, type: "error" });
    } finally {
      updateLoading(false);
    }
  };

  return {
    createComment,
    isCreating: isLoading,
  };
}

export default useCommentDetails;
