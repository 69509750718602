import React, { useCallback, useMemo } from "react";
import { TabButton } from "./style";

const TabLink = ({ item, index, currentTabIndex, setIndex, secondary }) => {
  const active = useMemo(() => {
    if (currentTabIndex === index) {
      return true;
    }
    return false;
  }, [currentTabIndex, index]);

  const onClick = useCallback(() => {
    setIndex(index);
  }, [index]);

  return (
    <TabButton
      role="button"
      onClick={onClick}
      active={active}
      secondary={secondary}
    >
      {item}
    </TabButton>
  );
};
export default TabLink;
