export const relationshipOptions = [
  { name: "Father", value: "father" },
  { name: "Mother", value: "mother" },
  { name: "Sister", value: "sister" },
  { name: "Brother", value: "brother" },
  { name: "Spouce", value: "spouce" },
  { name: "Child", value: "child" },
  { name: "Friend", value: "friend" },
  { name: "Guardian", value: "guardian" },
  { name: "Other", value: "other" },
];
