import React, { useMemo } from "react";

import { useFetchTodayHoliday } from "../../../../hooks/Holiday/useFetchTodayHoliday";

import Bank_img from "assets/common/Bank.png";

import {
  CardContainer,
  Heading,
  Image,
  Footer,
  ImageSkeleton,
  TextSkeleton,
  InfoIcon,
  Description,
  OptionalTextDiv,
  Heading1,
} from "./style";
import Tooltip from "components/Tooltip2";

import { getDateDetails, getImageUrl } from "../../../../../../Helper";

export function HolidayCard() {
  const { holidayData, isLoading, hasError } = useFetchTodayHoliday();
  const dateDetails = useMemo(() => {
    if (!holidayData.date) {
      return null;
    }
    return getDateDetails(holidayData.date);
  }, [holidayData.date]);

  return (
    <CardContainer className="d-f f-c">
      <Heading>
        <Heading1>
          <span className="head">Holiday</span>
        </Heading1>
        {!!dateDetails && (
          <span className="small">{`${dateDetails.todayDate}, ${dateDetails.month}`}</span>
        )}
      </Heading>
      {!isLoading && holidayData && holidayData.name && holidayData.isOptional && (
        <OptionalTextDiv>
          <span className="head2"> Optional</span>

          <InfoIcon data-tooltip="info-button" name="fas fa-info-circle" />

          <Tooltip
            style={{ background: "#0D0543" }}
            position="bottom"
            tooltipId="info-button"
          >
            <Description>
              Employees are eligible for ANY 2 optional holidays. For those
              employees who do not wish to avail this optional holiday - it
              shall lapse for them.
            </Description>
          </Tooltip>
        </OptionalTextDiv>
      )}
      {!isLoading && holidayData && holidayData.image && (
        <Image src={getImageUrl(holidayData.image?.url)} />
      )}
      {!isLoading && holidayData && !holidayData.image && (
        <Image src={Bank_img} />
      )}
      {isLoading && <ImageSkeleton />}
      <Footer>
        {!isLoading && holidayData && holidayData.name && (
          <span className="head">{holidayData.name}</span>
        )}
        {isLoading && <TextSkeleton />}
      </Footer>
    </CardContainer>
  );
}
