import React from "react";
import useInput from "./useInput";
import { InputContainer, InputIcon, GlobalInput } from "./style";

const TextArea = ({
  fillParent = false,
  size = "md",
  success = "",
  secondary = false,
  error = "",
  ...otherprops
}) => {
  const { icon, restInputProps } = useInput({
    ...otherprops,
    success,
    error,
  });

  success = success ? "true" : undefined;
  error = error ? "true" : undefined;
  return (
    <InputContainer size={size} fillParent={fillParent}>
      <GlobalInput
        success={success}
        error={error}
        as="textarea"
        secondary={secondary}
        isTextArea={true}
        {...restInputProps}
      />
      {!!icon && <InputIcon success={success} error={error} name={icon} />}
    </InputContainer>
  );
};

export default TextArea;
