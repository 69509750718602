import {
  GET_HOLIDAY_YEARS,
  GET_HOLIDAYS,
  GET_HOLIDAYS_BY_YEAR,
  UPDATE_YEAR_ID,
  SEARCH_HOLIDAYS,
  UPDATE_SEARCH_PARAMS,
  RESET_SEARCH_PARAMS,
  EDIT_HOLIDAY,
  DELETE_HOLIDAY
} from "./actiontypes";
import {
  createInitialStoreState,
  createNewStoreOnGetData,
  createNewStoreOnUpdate,
  createNewStoreOnDelete
} from "helper";

const initialState = createInitialStoreState({
  holidayYears: {},
  prevData: {}
});

export const holidaysReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_HOLIDAYS}_SUCCESS`: {
      const { meta } = payload;
      return createNewStoreOnGetData(payload, {
        ...store,
        currentHolidayYear: meta.holidayYear
      });
    }

    case `${EDIT_HOLIDAY}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }
    case `${DELETE_HOLIDAY}_SUCCESS`: {
      return createNewStoreOnDelete(payload, store);
    }

    case `${GET_HOLIDAYS_BY_YEAR}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${SEARCH_HOLIDAYS}_SUCCESS`: {
      let prevData;

      //  only updating the prev data at the first search occurence
      if (Object.keys(store.prevData).length === 0) {
        prevData = { ...store, onceFetched: true };
      } else {
        prevData = store.prevData;
      }

      const searchData = createNewStoreOnGetData(payload, store, true);
      return {
        ...searchData,
        prevData
      };
    }

    case `${UPDATE_YEAR_ID}`: {
      const { holiday_year_id } = payload;

      return {
        ...store,
        currentYearId: holiday_year_id
      };
    }

    case `${UPDATE_SEARCH_PARAMS}`: {
      const { from_date, to_date, is_optional } = payload;
      if (from_date || to_date || is_optional)
        return {
          ...store,
          onceFetched: false,
          currentFromDate: from_date,
          currentToDate: to_date,
          currentIsOptional: is_optional
        };
      return store;
    }

    case `${RESET_SEARCH_PARAMS}`: {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
        currentFromDate: "",
        currentToDate: "",
        currentIsOptional: ""
      };
    }

    case `${GET_HOLIDAY_YEARS}_SUCCESS`:
      return {
        ...store,
        holidayYears: payload
      };

    default:
      return store;
  }
};
