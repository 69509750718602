import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const searchSeparationApi = ({
  pageNumber,
  companyId,
  isAdmin,
  startDate,
  endDate,
  status,
  userId,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/search_separation?page=${pageNumber}&status=${status}&from_date=${startDate}&to_date=${endDate}&collaborator_id=${userId}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const getAllSeparationApi = ({ pageNumber, companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/separations?page=${pageNumber}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const applyForResignationApi = ({
  ccIds,
  reason,
  letter,
  isAdmin,
  companyId,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/separations`,
  });
  const separation = {
    reason_of_resignation: reason,
    letter_of_resignation: letter,
  };
  if (ccIds && ccIds.length > 0) {
    separation["cc_ids"] = ccIds;
  }
  return requestData(authUrl, {
    method: "POST",
    isFormData: true,
    body: {
      separation,
    },
  });
};
