import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reducerStore } from "types/index";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { deleteWorkshiftType as deleteWorkshiftActionCreator } from "../View/Display/WorkshiftType/actionCreator";
import useAlert from "hooks/useAlert";

export const useDeleteWorkshift = () => {
  const [isLoading, updateLoading] = useState<boolean>(false);
  const { showAlert } = useAlert();
  const dispatch = useDispatch();
  const companyId = useSelector((store: reducerStore) =>
    getCurrentUserCompany(store, null)
  );
  const isAdmin = useSelector((store: reducerStore) =>
    isCurrentUserIsExecutive(store, null)
  );

  const deleteWorkshift = async (workshiftId: string) => {
    try {
      updateLoading(true);
      await dispatch(
        deleteWorkshiftActionCreator({ workshiftId, companyId, isAdmin })
      );
    } catch (err) {
      showAlert({ message: "Failed to delete workshift", type: "error" });
    } finally {
      updateLoading(false);
    }
  };

  return {
    isLoading,
    deleteWorkshift,
  };
};
