import React, { useCallback, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import useAlert from "../../../Hooks/useAlert";
import { updatePassword } from "../actionCreator";
import { validatePassword8CharLongWithCharContainCheck } from "../../../Helper";
import InputGroup from "../../../Common/Components/InputGroup";
import { Header, StyledButton, ReStyledFormGroup } from "../styles";

const UpdatePassword = ({
  setNextPageStatus,
  setSuccessfulStatus,
  currentUrl,
  updatePassword,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invalidMessage, setInvalidMessage] = useState("");
  const { showAlert } = useAlert();

  const { token, email } = useMemo(() => {
    const url = new URL(currentUrl);
    const token = url.searchParams.get("password_reset_token");
    const email = url.searchParams.get("email");
    return { token, email };
  }, [currentUrl]);

  const onPasswordChange = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const onConfirmPasswordChange = useCallback((e) => {
    setConfirmPassword(e.target.value);
  }, []);

  const confirmPasswordValidator = useCallback(() => {
    if (password) {
      if (confirmPassword === password) {
        setInvalidMessage("");
        return true;
      } else {
        setInvalidMessage("Passwords don't match");
        return false;
      }
    } else {
      setInvalidMessage("");
      return true;
    }
  }, [password, confirmPassword]);

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        setLoading(true);
        const { updatePassword_Password, updatePassword_confirmPassword } =
          values;
        await updatePassword({
          token,
          email,
          password: updatePassword_Password,
          confirmPassword: updatePassword_confirmPassword,
        });
        setSuccessfulStatus(true);
      } catch (err) {
        showAlert({ message: err[0], type: "error" });
        setSuccessfulStatus(false);
      } finally {
        resetForm();
        setLoading(false);
        setNextPageStatus(true);
      }
    },
    [token, email, setNextPageStatus, setSuccessfulStatus]
  );

  return (
    <>
      <Header>Create new password</Header>
      <ReStyledFormGroup onSubmit={onFormSubmit}>
        <InputGroup
          id="updatePassword_Password"
          type="password"
          label="Password"
          validator={validatePassword8CharLongWithCharContainCheck}
          placeholder="Enter new password"
          invalidMessage="Must be atleast 8 chars long and contain at least 1 uppercase char, 1 lowercase char, 1 digit and 1 special char"
          onChange={onPasswordChange}
          required
        />
        <InputGroup
          id="updatePassword_confirmPassword"
          type="password"
          label="Confirm password"
          validator={confirmPasswordValidator}
          placeholder="Confirm new password"
          invalidMessage={invalidMessage}
          onChange={onConfirmPasswordChange}
          required
        />
        <StyledButton
          primary
          size="full"
          loading={isLoading}
          disabled={isLoading}
          type="submit"
        >
          Reset my Password
        </StyledButton>
      </ReStyledFormGroup>
    </>
  );
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updatePassword,
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(UpdatePassword);
