import React from "react";
import Icon from "../Icon";

import useClipText from "../../../Hooks/useClipText";
import { ChipContainer } from "./style";

const Chip = ({ text, maxChar = 10, iconName = "", ...otherProps }) => {
  const { clippedText } = useClipText({ text, maxChar });
  return (
    <ChipContainer {...otherProps}>
      {iconName ? <Icon name={iconName} /> : null}
      <span>{clippedText}</span>
    </ChipContainer>
  );
};
export default Chip;
