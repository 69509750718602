import { createTableDataSelector } from "common/Selectors/MTable";
import Button from "components/Button";
import InputGroup from "components/InputGroup/inputGroup2";
import { StyledFormGroup } from "globalStyles/view";
import useForm from "hooks/useForm";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useUpdateLedgerTransactions from "./useUpdateLedgerTransactions";

const getTransactionRows = createTableDataSelector({
  tableName: "ledger.transactions",
  reverse: false,
});

export const statusOptions = [
  { name: "pending", value: "pending" },
  { name: "Approve", value: "approved" },
  { name: "Reject", value: "rejected" },
];

const StatusUpdateForm = ({ currentId, toggleModal }) => {
  const { formData, values, resetForm, isFormValid, updateField } = useForm({
    fields: {
      status: {
        required: true,
      },
    },
  });

  const { rows, cellNameToColumnMap, rowIdMap } =
    useSelector(getTransactionRows);

  useEffect(() => {
    if (currentId) {
      updateField(
        "status",
        rows[currentId][cellNameToColumnMap["status"]]?.toLowerCase()
      );
    }
  }, [rows, cellNameToColumnMap, currentId]);

  const transactionId = useMemo(() => {
    if (currentId) {
      return rows[currentId][cellNameToColumnMap["Sl No"]];
    }
  }, [rows, cellNameToColumnMap, currentId]);

  const { isLoading, updateTransactions } = useUpdateLedgerTransactions();

  const formSubmit = async () => {
    await updateTransactions(values, transactionId, resetForm);
    toggleModal({ id: null });
  };

  return (
    <div style={{ minWidth: 300 }}>
      <StyledFormGroup style={{ alignItems: "center" }} onSubmit={formSubmit}>
        <InputGroup
          defaultSelectText="Select Status"
          options={statusOptions}
          fieldType="radioGroup"
          {...formData["status"]}
        />
        <Button loading={isLoading} size="sm" primary type="submit">
          Submit
        </Button>
      </StyledFormGroup>
    </div>
  );
};

export default StatusUpdateForm;
