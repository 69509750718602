import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import { PaginatorBox } from "../../../../GlobalStyles";
import { EnhancedActionArea, PaginatorAndCreateBox } from "../../style/display";
import Paginator from "components/Paginator";
import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";
import Button from "components/Button";

import { getLedgersCategories } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive
} from "../../../../Common/Selectors/auth";

const ActionArea = ({
  collaboratorId,
  onceFetched,
  updateFormVisibility,
  onLoading,
  style,
  companyId,
  isAdmin
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages
  } = usePagination({
    actionCreator: getLedgersCategories,
    tableName: "ledger.categories"
  });

  const extraParams = {
    companyId,
    collaboratorId,
    isAdmin
  };

  const { fetchFirstPage, fetchNextPage, fetchPrevPage, fetchSpecificPage } =
    usePaginator({ extraParams, paginatorApi: getNextPage });

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  const viewForm = () => {
    updateFormVisibility(true);
  };

  return (
    <EnhancedActionArea style={style}>
      <PaginatorAndCreateBox>
        {isAdmin && (
          <Button primary onClick={viewForm}>
            Create New
          </Button>
        )}
        <PaginatorBox>
          <Paginator
            current={currentPageNumber}
            total={totalPages}
            onNext={fetchNextPage}
            onPrev={fetchPrevPage}
            onGoToPage={fetchSpecificPage}
            nextIsLoading={loadingNextPage}
            prevIsLoading={loadingPrevPage}
          />
        </PaginatorBox>
      </PaginatorAndCreateBox>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    onceFetched: store.ledger.categories.onceFetched,
    collaboratorId: store.ledger.categories.collaboratorId,
    isAdmin
  };
};

export default connect(mapStateToProps, null)(ActionArea);
