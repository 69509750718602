import attendanceReducer from "../attendance/reducer";
import holidayReducer from "../holiday/reducer";
import { combineReducers } from "redux";

const dashboardReducer = combineReducers({
  attendance: attendanceReducer,
  holiday: holidayReducer,
});

export default dashboardReducer;
