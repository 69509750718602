import React from "react";
import DrawerTab from "../DrawerTab";
import useLogout from "hooks/useLogout";
import Logout_Img from "assets/logout.svg";
import E_Logout_Img from "assets/employee/logout.svg";

export const LogoutTab = ({ isAdmin }) => {
  const { logoutUser } = useLogout();
  return (
    <DrawerTab
      accessType="*"
      onClick={logoutUser}
      image={isAdmin ? Logout_Img : E_Logout_Img}
      title="Logout"
    />
  );
};
