import React from "react";
import { useParams } from "react-router";

import Form from "./Form";

import { MainContainer } from "../../style/create";

const CountdownCreate = ({ updateFormVisibility }) => {
  const params = useParams();

  return (
    <MainContainer>
      <Form updateFormVisibility={updateFormVisibility} editId={params.id} />
    </MainContainer>
  );
};

export default CountdownCreate;
