import styled from "styled-components";
import ActivityIndicator from "../../../Common/Components/ActivityIndicator";

export const AutoCompleteDropdownContainer = styled.div`
  width: 100%;
  margin: auto;
  box-shadow: ${(props) =>
    props.areSuggestionsLoaded ? props.theme.shadow.medium : "none"};
`;

export const SuggestionItem = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#bebebe" : "#ffffff")};
  padding: 10px;
`;

export const SuggestionLoaderContainer = styled(ActivityIndicator)`
  margin: auto;
`;

export const LocationEnablerMessage = styled.p`
  color: red;
  font-size: 12px;
`;
