import React, { useMemo, useRef, useState } from "react";

import Home_Image from "../../../../assets/home.png";
import Travel_Image from "../../../../assets/travel.png";
import down_icon from "assets/downArrow.svg";

import Tooltip from "components/Tooltip2";

import { getCurrentUserId } from "common/Selectors/auth";

import { connect } from "react-redux";

import {
  StyledProfilePic,
  ProfilePicIndicator,
  ProfilePicWrapper,
  Dot,
  MorePic,
  StyledLink,
} from "./style";
import Routes from "route/Path";
import Popover from "components/PopoverAdvanced2";
import ProfilePopoverContent from "components/Grid/ProfilePopoverContent";
import { getRandomColor } from "helper/colors";

function ProfilePicLists({
  data,
  setChildRef,
  showMore,
  onMoreClick,
  isBreakOngoing,
  currentCollaboratorId,
  showDot,
  type,
}) {
  const getIndicatorImage = (type) => {
    switch (type) {
      case "With Travel":
        return Travel_Image;
      case "From Home":
        return Home_Image;
      default:
        return null;
    }
  };

  const [profileAnchor, setAnchor] = useState({ elem: null, id: null });

  const addAnchor = (target, id) => {
    setAnchor({ elem: target, id });
  };

  const removeAnchor = (e) => {
    setAnchor({ elem: null, id: null });
  };

  const randomColor = useMemo(() => {
    return getRandomColor();
  }, []);

  const list = useMemo(() => {
    const result = data.map((item) => {
      const { cdnUrl, url } =
        item.avatarUrl && item.avatarUrl !== "NA" ? item.avatarUrl : {};
      const indicatorImage =
        item.todaysWorkingHourLog &&
        getIndicatorImage(item.todaysWorkingHourLog.workOrigin);

      return (
        <ProfilePicWrapper key={item.id}>
          {/* <StyledLink
            to={`${Routes.authorized.userProfile.view.path}/${item.id}`}
          > */}
          <div
            onClick={(e) => addAnchor(e.target, item.id)}
            style={{ cursor: "pointer" }}
          >
            <StyledProfilePic
              userName={item.name}
              img={url}
              setChildRef={setChildRef}
              cdnImg={cdnUrl}
              data-tooltip={`username_${item.id}_${type}`}
              showName={false}
            />
          </div>
          {showDot &&
            ((item.id === currentCollaboratorId && isBreakOngoing) ||
              item.ongoingBreak != "NA") && (
              <Dot style={{ backgroundColor: "#f03434", zIndex: 1 }} />
            )}

          {showDot &&
            item.todaysWorkingHourLog.startTime &&
            (!item.todaysWorkingHourLog.endTime ? (
              <Dot style={{ backgroundColor: "#228b22" }} />
            ) : (
              <Dot
                style={{
                  background: "radial-gradient(#ffffff 30%, #7f7f7f 40%)",
                }}
              />
            ))}

          {indicatorImage && (
            <ProfilePicIndicator>
              <img src={indicatorImage} alt="work place" />
            </ProfilePicIndicator>
          )}
          <Tooltip tooltipId={`username_${item.id}_${type}`}>{`${item.name}${
            item?.lastWorkingHourLog
              ? `\nLast Sign In : ${
                  (item.lastWorkingHourLog?.startTime &&
                    item.lastWorkingHourLog?.workDate) ||
                  ""
                } ${
                  item.lastWorkingHourLog?.startTime || "---"
                }\nLast Sign Out : ${
                  (item.lastWorkingHourLog?.endTime &&
                    item.lastWorkingHourLog?.workDate) ||
                  ""
                } ${item.lastWorkingHourLog?.endTime || "---"}`
              : item?.todaysWorkingHourLog
              ? `\nSign In: ${
                  item.todaysWorkingHourLog?.startTime || "--"
                }\nSign Out: ${item.todaysWorkingHourLog?.endTime || "--"}`
              : ""
          }`}</Tooltip>
          <Popover
            onClose={removeAnchor}
            active={profileAnchor.id === item.id}
            anchorElem={profileAnchor.elem}
            userPreferedDirection="left"
          >
            <ProfilePopoverContent data={item} color={randomColor} />
          </Popover>
          {/* </StyledLink> */}
        </ProfilePicWrapper>
      );
    });
    if (showMore) {
      result.push(
        <MorePic key={"more_btn_pic"} onClick={onMoreClick}>
          more
          <img
            src={down_icon}
            alt="down arrow"
            style={{ width: "10px", marginTop: "2px" }}
          />
        </MorePic>
      );
    }
    return result;
  }, [
    data,
    showMore,
    currentCollaboratorId,
    isBreakOngoing,
    profileAnchor,
    randomColor,
  ]);

  return list;
}

const mapStateToProps = (store) => {
  const currentCollaboratorId = getCurrentUserId(store);
  const isBreakOngoing = !!store.breaks.collaboratorSnaps.ongoingBreak;
  return {
    isBreakOngoing,
    currentCollaboratorId,
  };
};

export default connect(mapStateToProps, null)(ProfilePicLists);
