import React, { useMemo } from "react";
import { connect } from "react-redux";

import Button from "components/Button";
import Tooltip from "components/Tooltip2";

import { useAttendanceFunctions } from "../../Hooks/useAttendanceFunctions";
import { AttendanceSignInModal } from "./SignInModal";

import {
  WorkHourCont,
  TopBarContainer,
  WorkHeadText,
  WorkHourHead,
  WorkHourContent,
  WorkHourDataHead,
  WorkHourDataText,
  WorkHourData,
  ButtonGroup,
  StyledSkeleton,
  ButtonContainer,
} from "../../style/display";

const TopBar = ({
  workingShiftData,
  currentLogId,
  checkOutStatus,
  onShowAttendanceList,
  ongoingBreak,
}) => {
  const {
    isLoading,
    signInLoadingType,
    isSignOutLoading,
    callSignIn,
    callSignOut,
    isModalActive,
    toggleModal,
  } = useAttendanceFunctions();

  const list = useMemo(() => {
    const { startDate, startTime, endTime } = workingShiftData || {};
    const data = [
      { name: "Start Date", value: startDate || "-/-/-" },
      { name: "Start Time", value: startTime || "00:00:00" },
      { name: "End Time", value: endTime || "00:00:00" },
    ];
    return data.map(({ name, value }, idx) => {
      return (
        <WorkHourData key={`${name}_${value}_${idx}`}>
          <WorkHourDataHead>{name}</WorkHourDataHead>
          {isLoading ? (
            <StyledSkeleton times={1} />
          ) : (
            <WorkHourDataText>{value}</WorkHourDataText>
          )}
        </WorkHourData>
      );
    });
  }, [workingShiftData, isLoading]);

  const signOutTitle = useMemo(() => {
    if (!!ongoingBreak) {
      return "You need to stop the break";
    }

    if (!checkOutStatus.signOut) {
      return "Not allowed";
    }

    return "Sign Out";
  }, [ongoingBreak, checkOutStatus]);

  return (
    <TopBarContainer>
      <WorkHourHead>
        <Button onClick={onShowAttendanceList} outline title="View">
          View
        </Button>
      </WorkHourHead>
      <WorkHourCont>
        <WorkHourContent>{list}</WorkHourContent>
      </WorkHourCont>

      <ButtonGroup>
        <ButtonContainer data-tooltip="signin-btn">
          <Button
            loading={signInLoadingType}
            onClick={toggleModal}
            disabled={!checkOutStatus.signIn}
            primary
          >
            Sign in
          </Button>
        </ButtonContainer>
        <Tooltip tooltipId="signin-btn">
          {!checkOutStatus.signIn ? "Not allowed" : "Sign In"}
        </Tooltip>
        <ButtonContainer data-tooltip="signout-btn">
          <Button
            loading={isSignOutLoading}
            onClick={callSignOut}
            disabled={
              !checkOutStatus.signOut || !currentLogId || !!ongoingBreak
            }
            outline
          >
            Sign Out
          </Button>
        </ButtonContainer>
        <Tooltip tooltipId="signout-btn">{signOutTitle}</Tooltip>
      </ButtonGroup>

      <AttendanceSignInModal
        active={isModalActive}
        onClose={toggleModal}
        heading="Choose your location :"
        onSignIn={callSignIn}
      />
    </TopBarContainer>
  );
};

const mapStateToProps = (store, props) => {
  const workingShiftData = store.attendance.attendanceTable.workingShift;
  const currentLogId = store.attendance.attendanceTable.currentLogId;
  const checkOutStatus = store.attendance.attendanceTable.checkOutStatus;
  const ongoingBreak = store.breaks.collaboratorSnaps.ongoingBreak;

  return {
    workingShiftData,
    currentLogId,
    checkOutStatus,
    ongoingBreak,
  };
};

export default connect(mapStateToProps, null)(TopBar);
