import InputGroup from "components/InputGroup/inputGroup2";
import { expenseTypeOptions } from "./options";

export const DebitFields = ({ formData }) => {
  return (
    <>
      <InputGroup
        fieldType="select"
        options={expenseTypeOptions}
        defaultSelectText="Select Type"
        label="Expense type"
        {...formData["expense_type"]}
      />

      <InputGroup
        fieldType="datePicker"
        placeholder="Enter from date dd/mm/yy"
        label="From date"
        {...formData["from_date"]}
      />

      <InputGroup
        fieldType="datePicker"
        placeholder="Enter to date dd/mm/yy"
        label="To date"
        {...formData["to_date"]}
      />

      <InputGroup
        fieldType="textArea"
        placeholder="Enter Notes"
        label="Notes"
        size="lg"
        {...formData["notes"]}
      />

      <InputGroup
        inputMode="number"
        placeholder="Enter amount  &#8377; XXXX"
        label="Amount (In Rupee)"
        {...formData["amount"]}
      />

      <InputGroup
        fieldType="googleFilePicker"
        accept="image/*, application/pdf"
        multiple={true}
        label="Invoices"
        {...formData["invoices"]}
      />
    </>
  );
};
