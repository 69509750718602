import requestData from "../../ApiHandler";
import { createAuthUrl, extractDateInYYYYMMDD } from "helper";

export const createAcademicQualificationApi = ({ companyId, formData }) => {
  return requestData(`/employee/companies/${companyId}/academics`, {
    method: "POST",
    body: {
      academic: {
        qualifiable_type: formData.qualificationType,
        course: formData.course,
        education: formData.education,
        grade: formData.grade,
        institution: formData.institution,
        specialization: formData.specialization || "",
        year_of_passing: formData.yearOfPassing,
        degree_document: formData.degreeDocument[0]
      },
    },
    isFormData: true,
  });
};

export const createProfessionalQualificationApi = ({ companyId, formData }) => {
  return requestData(`/employee/companies/${companyId}/professionals`, {
    method: "POST",
    body: {
      professional: {
        qualifiable_type: formData.qualificationType,
        designation: formData.designation,
        job_description: formData.jobDescription || "",
        organization: formData.organization,
        working_from: extractDateInYYYYMMDD(formData.workingFrom),
        worked_till: extractDateInYYYYMMDD(formData.workedTill),
        employment_document: formData.employmentDocument[0]
      },
    },
    isFormData: true,
  });
};

export const createTrainingCertificationQualificationApi = ({ companyId, formData }) => {
  return requestData(`/employee/companies/${companyId}/training_certificates`, {
    method: "POST",
    body: {
      training_certificate: {
        qualifiable_type: formData.qualificationType,
        name: formData.name,
        grade: formData.grade,
        start_date: extractDateInYYYYMMDD(formData.startDate),
        end_date: extractDateInYYYYMMDD(formData.endDate),
        nature: formData.nature,
        institution: formData.institution,
        location: formData.location,
        certificate_document: formData.certificateDocument[0]
      },
    },
    isFormData: true,
  });
};

export const getQualificationsApi = ({ companyId, qualificationType, pageNumber, isAdmin }) => {
  let qualification_type = "academics"
  switch (qualificationType) {
    case 'Academic':
      qualification_type = "academics";
      break;
    case 'Professional':
      qualification_type = "professionals";
      break;
    case 'TrainingCertificate':
      qualification_type = "training_certificates"
      break;
    default: qualification_type = "academics";
  }
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/${qualification_type}?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
