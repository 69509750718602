import React from "react";

import { FallbackContainer, ErrorText, ErrorIcon } from "./style";

const PageFallback = () => {
  return (
    <FallbackContainer>
      <ErrorIcon name="fas fa-exclamation-triangle" />
      <ErrorText>Something went wrong !!!</ErrorText>
    </FallbackContainer>
  );
};

export default PageFallback;
