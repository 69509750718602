import React from "react";
import Icon from "../../Icon";
import { ChipContainer, ChipBox, ChipLabel } from "./style";
const Chip = ({
  name,
  disabled,
  value,
  label,
  checked = false,
  renderComponent = null,
  ...restProps
}) => {
  return (
    <ChipContainer>
      <input
        style={{
          display: "none",
        }}
        {...restProps}
        type="checkbox"
        id={label}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={!disabled ? label : ""}>
        <ChipBox checked={checked} disabled={disabled}>
          {checked && <Icon name="fas fa-check" />}
          <ChipLabel
            style={{
              marginLeft: checked ? "5px" : "0px",
            }}
          >
            {renderComponent && typeof renderComponent === "function"
              ? renderComponent(checked)
              : label}
          </ChipLabel>
        </ChipBox>
      </label>
    </ChipContainer>
  );
};
export default Chip;
