function ActionContoller() {
  this.data = {};

  this.addAction = (action, payload) => {
    this.data[action] = {
      ...payload,
      isCancelled: false,
      isFailed: false
    };
  };

  this.removeAction = (action) => {
    if (!this.data[action]) {
      return;
    }
    delete this.data[action];
  };

  this.actionsInProgress = () => {
    return Object.keys(this.data);
  };

  this.cancelAction = (action) => {
    if (!this.data[action]) {
      return;
    }

    this.data[action].isCancelled = true;
  };

  this.failAction = (action, reason) => {
    if (!this.data[action]) {
      return;
    }
    this.data[action].isFailed = true;
    this.data[action].error = reason;
  };

  this.getActions = () => {
    return this.data;
  };

  this.isActionCancelled = (action) => {
    return this.data[action] ? this.data[action].isCancelled : false;
  };
}

const controller = new ActionContoller();

export default controller;
