import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  background-color: #f5f5f7;
  display: grid;
  grid-template-columns: minmax(230px, 500px);
  grid-template-areas: "post";
  gap: 23px;
  justify-content: center;
  @media (min-width: 1024px) {
    padding: 41px 64px 10px 60px;
  }
`;

export const PostContainer = styled.div`
  grid-area: post;
  display: flex;
  flex-flow: column nowrap;
`;
