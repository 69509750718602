import React from "react";
import { connect } from "react-redux";

import { TableContainer } from "../../../../../GlobalStyles";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import PresenteesData from "./PresenteesData";
import AbsenteesData from "./AbsenteesData";

const TableData = ({ companyId, isAdmin }) => {
  return (
    <TableContainer className="attendance-list-container">
      <PresenteesData companyId={companyId} isAdmin={isAdmin} />
      <AbsenteesData companyId={companyId} isAdmin={isAdmin} />
    </TableContainer>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    isAdmin,
  };
};

export default connect(mapStateToProps, null)(TableData);
