import { getCurrentDataAttributes } from "helper/selectors";
import { createSelector } from "reselect";

const storeCompanyBranchDataList = (store) => store.branch.data;
const storeCompanyBranchRelation = (store) => store.branch.relation;

export const companyBranchDataSelector = createSelector(
  storeCompanyBranchDataList,
  storeCompanyBranchRelation,
  (_, props) => props.id,
  (data, relation, id) => {
    if (id && data[id])
      return getCurrentDataAttributes(data[id], relation, data);
  }
);
