import React, { useContext, useMemo } from "react";
import { callAllFunctions } from "../../../Helper/common";
import { TabContext } from "./context";

import { TabsHeaderContainer, Tab } from "./style";

export function TabHeader({ tabs, children, ...other }) {
  const { activeTab, activateTab } = useContext(TabContext);

  const tabsList = useMemo(() => {
    return Array.isArray(tabs)
      ? tabs.map((data, idx) => {
          const [name, value] =
            typeof data === "object" ? [data.name, data.value] : [data, data];
          return (
            <Tab
              key={`${value}_${idx}`}
              onClick={() => activateTab(value)}
              $active={value === activeTab}
            >
              {name}
            </Tab>
          );
        })
      : [];
  }, [activeTab, tabs]);

  const modifiedList = useMemo(() => {
    if (!children) {
      return tabsList;
    }
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        onClick: callAllFunctions(child.props.onClick, () =>
          activateTab(child.props.id)
        ),
        active: activeTab
      });
    });
  }, [children, tabsList]);

  return <TabsHeaderContainer {...other}>{modifiedList}</TabsHeaderContainer>;
}
