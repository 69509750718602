import { useMemo } from "react";

import {
  checkIsImageUrl,
  getFileNameAndExtensionFromUrl,
  getFileTypeIcon,
  getFileTypeThumbnail,
  getImageUrl
} from "helper/common";
import {
  EachCard,
  ImageContainer,
  Image,
  Overlay,
  NameContainer,
  SmallImage,
  FileName
} from "../style";

const DocumentCard = ({ data, toggleModal }) => {
  const fileName = useMemo(() => {
    const [fileName] = getFileNameAndExtensionFromUrl(data?.url);
    return fileName;
  });

  const [isImageFile, fileUrl, thumbnail, fileIcon] = useMemo(() => {
    const isImageFile = checkIsImageUrl(fileName);
    const fileUrl = getImageUrl(data?.url);
    const thumbnail = getFileTypeThumbnail(fileName);
    const fileIcon = getFileTypeIcon(data?.url);
    return [isImageFile, fileUrl, thumbnail, fileIcon];
  }, [data, fileName]);

  return (
    <EachCard>
      {isImageFile ? (
        <ImageContainer onClick={() => toggleModal(fileUrl)}>
          <Image src={fileUrl} />
          <Overlay />
        </ImageContainer>
      ) : (
        <a href={fileUrl} rel="noopener noreferrer" target="_blank">
          <ImageContainer>
            <Image src={thumbnail} />
            <Overlay />
          </ImageContainer>
        </a>
      )}
      <NameContainer>
        <SmallImage src={fileIcon} />
        <FileName>{fileName}</FileName>
      </NameContainer>
    </EachCard>
  );
};

export default DocumentCard;
