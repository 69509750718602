import { useEffect } from "react";
import { connect, useSelector } from "react-redux";

import Activity from "modules/LeaveManagement/Views/ActivityView";
import useFetchStatusActivity from "../../Hooks/useFetchStatusActivity";
import { getAllStatusActivities } from "modules/Attendence/Selectors/statusActivity";
import { StyledSkeleton } from "modules/Attendence/style/details";

const StatusActivityView = ({ attendanceId, onceFetched }) => {
  const statusActivitiesData = useSelector(getAllStatusActivities);

  const { fetchActivity, isLoading } = useFetchStatusActivity();

  useEffect(() => {
    fetchActivity({ attendanceId });
  }, [attendanceId, onceFetched]);

  if (isLoading) {
    return <StyledSkeleton times={2} style={{ margin: "20px 0" }} />;
  }

  return <Activity data={statusActivitiesData} />;
};

const mapStateToProps = (store) => {
  const onceFetched = store.attendance.statusActivity.onceFetched;
  return {
    onceFetched,
  };
};
export default connect(mapStateToProps, null)(StatusActivityView);
