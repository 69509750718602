import Icon from "components/Icon";
import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";

import { useResizeObserver } from "./useResizeObeserver";
import { getDisplayValue } from "./utils";

import { Renderers } from "./Renderers";

import {
  ContentContainer,
  RowContainer,
  ExtraDataContainer,
  RowWrapper,
  CellContainer,
  DownButton,
  ExtraDataContent,
} from "./style";

const EXPANSION_CELL_WIDTH = 40;

const ExpansionCell = ({
  isExpanded,
  onToggle,
  className = "",
  btnProps = {},
  ...rest
}) => {
  return (
    <CellContainer
      style={{ width: `${EXPANSION_CELL_WIDTH}px` }}
      className={`left-all ${className}`}
      {...rest}
    >
      <DownButton $active={isExpanded} onClick={onToggle} {...btnProps}>
        <Icon name={`fa fa-angle-${isExpanded ? "up" : "down"}`} />
      </DownButton>
    </CellContainer>
  );
};

const TableCell = ({
  property,
  value,
  allData,
  renderer,
  rowIdx,
  columnIdx,
  ...rest
}) => {
  const data = useMemo(() => {
    let cellViewValue;
    if (typeof renderer === "function") {
      cellViewValue = renderer({
        property,
        value,
        rowIdx,
        columnIdx,
        allData,
        style: rest.style || {},
      });
    }

    return (
      cellViewValue || (
        <Renderers.TextPopover
          className="general"
          data={getDisplayValue(value)}
          heading={property}
        />
      )
    );
  }, [property, value, renderer, rowIdx, columnIdx, allData]);
  return <CellContainer {...rest}>{data}</CellContainer>;
};

const ExtraDataRow = ({
  data,
  renderer,
  cellRenderer,
  lastColumnIndexVisible,
  rowIdx,
  ...rest
}) => {
  const myData = useMemo(() => {
    if (typeof renderer === "function") {
      return renderer({ data, rowIdx });
    }

    const keys = Object.keys(data);
    return keys.map((key, index) => {
      const columnIndex = lastColumnIndexVisible + index + 1; // +1 because index starts with 0
      return (
        <ExtraDataContent>
          <span className="label">{key}: </span>
          <TableCell
            rowIdx={rowIdx}
            columnIdx={columnIndex}
            key={`${key}_${columnIndex}`}
            property={key}
            value={data[key]}
            renderer={cellRenderer}
            allData={data}
          />
        </ExtraDataContent>
      );
    });
  }, [data, renderer, rowIdx, lastColumnIndexVisible]);

  return <ExtraDataContainer {...rest}>{myData}</ExtraDataContainer>;
};

const Row = ({
  data,
  rowIndex,
  isExpanded,
  toggleRowExpansion,
  forceUpdateCount,
  maxColumnsVisible,
  blacklistedColumnKeys = {},
  className = "",
  maxColumnWidth = {},
  generateColumnOrder = null,
  extraDataRenderer = null,
  cellRenderer = null,
  ...rest
}) => {
  const [rowMaxColumnsVisible, setMaxColumnVisible] = useState(
    maxColumnsVisible - 1
  ); //  -1 beacuse array starts with 0 index
  const rowRef = useRef(null);

  const getMaxColumnWidth = useCallback(
    (key) => {
      if (typeof maxColumnWidth === "number") {
        return maxColumnWidth;
      }
      return maxColumnWidth[key];
    },
    [maxColumnWidth]
  );

  const calculateMaxColumnsVisible = () => {
    let widthAvailable = rowRef.current.offsetWidth;

    //  subtracting the expansion cell width so that id does not get counted in calculation
    widthAvailable -= EXPANSION_CELL_WIDTH;

    const rowChildren = [...rowRef.current.children];

    let i = 0;
    let columnsCanBeViewed = 0;

    while (widthAvailable > 0 && i < rowChildren.length) {
      const child = rowChildren[i];
      const prevDisplay = child.style.display;
      child.style.display = "flex";
      const childWidth = getMaxColumnWidth(i) || child.offsetWidth;
      if (widthAvailable - childWidth > 0) {
        columnsCanBeViewed++;
        widthAvailable -= childWidth;
      } else {
        child.style.display = prevDisplay;
        break;
      }
      i++;
      child.style.display = prevDisplay;
    }

    setMaxColumnVisible(columnsCanBeViewed - 1);
  };

  useLayoutEffect(() => {
    //  if not predefined from parent then only calculate by own
    if (!maxColumnsVisible && forceUpdateCount) {
      calculateMaxColumnsVisible();
    }
  }, [
    maxColumnsVisible,
    forceUpdateCount,
    blacklistedColumnKeys,
    getMaxColumnWidth,
  ]);

  const rowKeys = useMemo(() => {
    if (typeof generateColumnOrder === "function") {
      const originalKeysOrder = Object.keys(data);
      const filteredkeys = originalKeysOrder.filter(
        (key) => !blacklistedColumnKeys[key]
      );
      const newOrder = generateColumnOrder({
        keys: filteredkeys,
        allKeys: originalKeysOrder,
      });
      if (!Array.isArray(newOrder) || filteredkeys.length !== newOrder.length) {
        throw new Error(
          "Invalid new order, data mismatch with the original order"
        );
      }
      return newOrder;
    }
    return Object.keys(data);
  }, [data, generateColumnOrder, blacklistedColumnKeys]);

  const rowList = useMemo(() => {
    const list = [];
    for (let i = 0; i < rowKeys.length; i++) {
      const key = rowKeys[i];
      const value = data[key];
      const hideCell = i > rowMaxColumnsVisible; //because i starts with 0

      if (blacklistedColumnKeys && blacklistedColumnKeys[key]) {
        continue;
      }

      const cellMaxColumn = getMaxColumnWidth(i);

      list.push(
        <TableCell
          rowIdx={rowIndex}
          columnIdx={i}
          key={`${key}_${i}`}
          property={key}
          value={value}
          renderer={cellRenderer}
          allData={data}
          style={{
            display: hideCell ? "none" : "flex",
            ...(cellMaxColumn ? { width: `${cellMaxColumn}px` } : {}),
          }}
          className="tb-cell"
        />
      );
    }
    return list;
  }, [
    data,
    rowMaxColumnsVisible,
    cellRenderer,
    blacklistedColumnKeys,
    getMaxColumnWidth,
  ]);

  //  separating extra data from actual data
  const extraData = useMemo(() => {
    const copyArr = [...rowKeys];
    const extraKeys = copyArr.splice(rowMaxColumnsVisible + 1, copyArr.length);
    const result = {};
    extraKeys.forEach((key) => {
      result[key] = data[key];
    });
    return result;
  }, [data, rowMaxColumnsVisible]);

  return (
    <RowWrapper className={`tb-row-wrapper ${className}`} {...rest}>
      <RowContainer className="tb-row-cont" $isExpanded={isExpanded}>
        <div ref={rowRef} className="content tb-row-content">
          {rowList}
          {rowMaxColumnsVisible + 1 < rowKeys.length && (
            <ExpansionCell
              isExpanded={isExpanded}
              onToggle={() => toggleRowExpansion(rowIndex)}
              className="tb-cell tb-row-content-exp"
            />
          )}
        </div>
      </RowContainer>
      {isExpanded && (
        <ExtraDataRow
          rowIdx={rowIndex}
          renderer={extraDataRenderer}
          data={extraData}
          lastColumnIndexVisible={rowMaxColumnsVisible}
          className="tb-row-cont-extra"
          cellRenderer={cellRenderer}
        />
      )}
    </RowWrapper>
  );
};

export const TableContent = ({
  data,
  cellRenderer,
  extraDataRenderer,
  maxColumnsVisible,
  initialExpandedRows,
  generateColumnOrder,
  expandedRows, // determine which rows will be by default expanded (means it would br controlled from parent)
  maxColumnWidth = {},
  blacklistedColumnKeys = {},
  onExpandRows,
  className = "",
  ...rest
}) => {
  const [tableExpandedRows, setExpandedRows] = useState(
    initialExpandedRows || {}
  );
  const [resizeTimes, setResizeTimes] = useState(0);
  const { observeChanges } = useResizeObserver();
  const containerRef = useRef(null);
  const prevContainerWidthRef = useRef(0);

  const onContainerResized = (entries) => {
    const entry = entries[0];
    if (
      entry.borderBoxSize &&
      entry.borderBoxSize.length > 0 &&
      entry.borderBoxSize[0].inlineSize !== prevContainerWidthRef.current
    ) {
      prevContainerWidthRef.current = entry.borderBoxSize[0].inlineSize;
      setResizeTimes((val) => val + 1);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      observeChanges(containerRef.current, onContainerResized);
    }
  }, []);

  const isValueComingFromParent = () => {
    return expandedRows && Object.keys(expandedRows).length > 0;
  };

  const toggleRowExpansion = (rowIndex) => {
    if (isValueComingFromParent()) {
      onExpandRows(rowIndex);
    } else {
      setExpandedRows((val) => ({ ...val, [rowIndex]: !val[rowIndex] }));
    }
  };

  const list = useMemo(() => {
    const hasParentValue = isValueComingFromParent();
    const rowsWhichAreExpanded = hasParentValue
      ? expandedRows
      : tableExpandedRows;
    return data.map((rowData, index) => {
      const isRowExpanded = rowsWhichAreExpanded[index];
      return (
        <Row
          key={rowData.id}
          data={rowData}
          toggleRowExpansion={toggleRowExpansion}
          isExpanded={isRowExpanded}
          cellRenderer={cellRenderer}
          extraDataRenderer={extraDataRenderer}
          rowIndex={index}
          maxColumnsVisible={maxColumnsVisible}
          forceUpdateCount={resizeTimes}
          generateColumnOrder={generateColumnOrder}
          blacklistedColumnKeys={blacklistedColumnKeys}
          maxColumnWidth={maxColumnWidth}
        />
      );
    });
  }, [
    data,
    tableExpandedRows,
    expandedRows,
    cellRenderer,
    extraDataRenderer,
    maxColumnsVisible,
    resizeTimes,
    generateColumnOrder,
    blacklistedColumnKeys,
    maxColumnWidth,
  ]);

  return (
    <ContentContainer
      className={`tb-cont ${className}`}
      ref={containerRef}
      {...rest}
    >
      {list}
    </ContentContainer>
  );
};
