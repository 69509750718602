// A debounce hook
import { useCallback, useRef, useEffect } from "react";

const useDebounce = (callback, timeout, deps) => {
  const timeoutId = useRef(null);

  useEffect(() => {
    timeoutId.current = setTimeout(callback, timeout);

    return clearId;
  }, deps);

  const clearId = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, [timeoutId]);
};

export default useDebounce;
