import React, { useState, useCallback, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Tabs from "components/Tabs";
import PageSlider from "components/PageSlider";

import ActionArea from "./ActionArea";
import TableData from "./TableData";
import DetailView from "./DetailView";
import PersonalInfoDisplayView from "../../../PersonalInfo/Views/Display";
import FamilyDetailsDisplayView from "../../../FamilyDetails/Views/Display";
import EmergencyContactDisplayView from "../../../EmergencyContact/Views/Display";
import BankAccountsDisplayView from "../../../BankAccounts/Views/Display";
import UpiDisplayView from "../../../UpiDetails/Views/Display";
import EmailDisplayView from "../../../Email/Views/Display";
import LeadsDisplayView from "../../../Leads/Views/Display";

import useAspectRatio from "hooks/useAspectRatio";

import { ViewContainer } from "../../../../GlobalStyles";
import { TabContainer } from "../../style/display";

const UserView = ({ currentUserId }) => {
  const [sliderActive, updateSliderStatus] = useState(false);
  const [detailData, updateDetailData] = useState({ data: [], columns: [] });
  const ratioData = useAspectRatio({ aspectRatio: 1.33 });

  const toggleSlider = () => {
    updateSliderStatus((val) => !val);
  };

  const onSliderClose = useCallback(() => {
    toggleSlider();
  }, []);

  const onCellClick = ({ data, columns, rowIndex, rowIdMap }) => {
    updateDetailData({ data, columns });
    toggleSlider();
  };

  const CurrentTab = useCallback((index) => {
    let category = "active";
    if (index == 1) category = "active";
    else if (index === 2) category = "inactive";
    else return null;
    return (
      <ViewContainer>
        <ActionArea tabCategory={category} />
        <TableData onCellClick={onCellClick} />
      </ViewContainer>
    );
  }, []);

  const CurrentSliderTab = useCallback(
    (index) => {
      if (index == 1) return <DetailView userId={currentUserId} />;
      else if (index == 2)
        return <PersonalInfoDisplayView userId={currentUserId} />;
      else if (index == 3)
        return <FamilyDetailsDisplayView userId={currentUserId} />;
      else if (index == 4)
        return <EmergencyContactDisplayView userId={currentUserId} />;
      else if (index == 5)
        return <BankAccountsDisplayView userId={currentUserId} />;
      else if (index == 6) return <UpiDisplayView userId={currentUserId} />;
      else if (index == 7) return <EmailDisplayView userId={currentUserId} />;
      else if (index == 8) return <LeadsDisplayView userId={currentUserId} />;
      else return null;
    },
    [detailData, currentUserId]
  );

  return (
    <>
      <Tabs tabNames={["Active", "Inactive"]}>{CurrentTab}</Tabs>
      <PageSlider onClose={onSliderClose} active={sliderActive}>
        <TabContainer>
          <Tabs
            tabNames={[
              "Detail",
              "Personal Info",
              "Family Details",
              "Emergency Contact",
              "Bank Account",
              "UPI",
              "Email",
              "Lead",
            ]}
          >
            {CurrentSliderTab}
          </Tabs>
        </TabContainer>
      </PageSlider>
    </>
  );
};

const mapStateToProps = (store, props) => {
  const currentUserId = store.user.currentUserId;
  return {
    currentUserId,
  };
};

export default connect(mapStateToProps, null)(UserView);
