import styled from "styled-components";

export const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-y: auto;
  padding-top: 50px;
  padding-left: 80px;
  border: 2px ${(props) => props.theme.colors.primaryColor} solid;
  display: flex;
  flex-flow: row wrap;
`;

export const DataItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0;
  flex-basis: auto;
  min-width: 200px;

  ${({ isTable }) => isTable && `
  flex-basis: 100%;
  `}
`;

export const TableItem = styled.div`
  flex-basis: 100%;
`;

export const DetailHead = styled.h3`
  font-size: ${(props) => props.theme.text.para.medium};
  text-align: left;
  text-transform: capitalize;
  font-weight: bolder;
  margin-right: 10px;
  width: 200px;
`;

export const DetailTableContent = styled.div`
  flex-basis: 100%;
  height: 200px;
  overflow-y: auto;
`;
