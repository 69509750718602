import { FETCH_COMMENTS, CREATE_LOCAL_COMMENT_STORE } from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import { fetchCommentsApi } from "./api";

const fetchCommentsGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
  postId,
}) {
  yield {
    action: FETCH_COMMENTS,
    payload: { postId },
    sideEffect: fetchCommentsApi({ companyId, isAdmin, pageNumber, postId }),
  };
};

export const createCommentStore = ({ postId }) => ({
  type: CREATE_LOCAL_COMMENT_STORE,
  payload: { postId },
});

export const [fetchComments] = createAsyncActionCreator([
  fetchCommentsGenrator,
]);
