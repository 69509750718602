import {
  FETCH_SEPARATION_DATA,
  RESET_SEPARATION_DATA,
  SEARCH_SEPARATION_DATA,
  SET_SEARCH_DATA,
  APPLY_FOR_RESIGNATION,
} from "./actionTypes";

import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createPrevData,
  createNewStoreOnCreate,
} from "helper";

const initialState = createInitialStoreState(
  {
    prevData: {},
    searchData: {
      startDate: "",
      endDate: "",
      status: "",
      user: "",
    },
    isSearchOn: false
  },
  false
);

export const separationReducer = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_SEPARATION_DATA}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, false);
    }

    case `${SEARCH_SEPARATION_DATA}_SUCCESS`: {
      const prevData = createPrevData(store);
      const d = createNewStoreOnGetData(payload, store, false);
      return {
        ...d,
        prevData,
      };
    }

    case `${APPLY_FOR_RESIGNATION}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store);
    }

    case SET_SEARCH_DATA: {
      const { startDate, endDate, status, user } = payload;
      return {
        ...store,
        searchData: {
          startDate,
          endDate,
          status,
          user,
        },
        isSearchOn: true
      };
    }

    case RESET_SEPARATION_DATA: {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
        searchData: {
          startDate: "",
          endDate: "",
          status: "",
          user: "",
        },
        isSearchOn: false
      };
    }
    default:
      return store;
  }
};
