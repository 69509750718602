import { useMemo } from "react";
import { useLocation } from "react-router-dom";

function useRouteParams(ids) {
  const location = useLocation();
  const params = useMemo(() => {
    const res = {};
    if (!Array.isArray(ids)) {
      return res;
    }
    const { pathname } = location;
    const parts = pathname.split("/");

    ids.forEach((paramId) => {
      const index = parts.indexOf(paramId);
      if (index !== -1) {
        res[paramId] = parts[index + 1] || null;
      }
    });

    return res;
  }, [location.pathname, ids]);

  return params;
}

export default useRouteParams;
