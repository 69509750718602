import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Grid from "components/Grid";
import RenderItem from "./RenderItem";

import { createTableDataSelector } from "common/Selectors/MTable";

import { TableContainer } from "../../../../GlobalStyles";
import { calculateMaxLength } from "../../../../Helper";

const TableData = ({ data, onCellClick, cellNameToColumnMap, isSearchOn, onEmptyData, ...other }) => {
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (rows === 1 && !isSearchOn) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);

  const maxStatusLength = useMemo(
    () => {
      return calculateMaxLength(cellNameToColumnMap, data);
    },
    [data],
  )

  return (
    <TableContainer {...other}>
      <Grid
        onClick={onCellClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        data={{
          rowData: data,
          cellNameToColumnMap,
          maxStatusLength: maxStatusLength
        }}
        dataKey="rowData"
      >
        {RenderItem}
      </Grid>
    </TableContainer>
  );
};

const mapStateToProps = (store, props) => {
  const getUserRows = createTableDataSelector({
    tableName: "enquiries.collaborator",
    blackList: { "Sl No": true },
  });
  const getSubordinateUserRows = createTableDataSelector({
    tableName: "enquiries.subordinate",
    blackList: { "Sl No": true },
  });
  const maker = (store, props) => {
    const subordinateId = store.enquiries.subordinate.collaboratorId;
    let tableData;
    if (subordinateId) {
      tableData = getSubordinateUserRows(store, props);
    }
    else {
      tableData = getUserRows(store, props);
    }
    return {
      data: tableData.rows,
      cellNameToColumnMap: tableData.cellNameToColumnMap,
      isSearchOn: store.enquiries.collaborator.isSearchOn
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
