import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { WeekCalendar, MonthCalendar, DayCalendar } from "components/Calendar";
import ActionArea from "./ActionArea";

import {
  getMonthSchedule,
  updateSelectedCalendars,
  updateCalendarType,
  setDateRange,
} from "../../actionCreator";
import {
  getWeekRange,
  getDayDataObj,
  getMonthRange,
  getLocalDateString,
  moveToNextMonth,
  moveToFirstDate,
} from "./utils";
import useAlert from "hooks/useAlert";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { getScheduleList } from "../../selector";

import { ViewContainer, Content } from "../../styles/display";
import CalendarList from "./CalendarList";
import { loadScript } from "../../../../Helper";
import { MAP_URL } from "../../../../Constants";

const Calendar = ({
  getMonthSchedule,
  companyId,
  schedules,
  updateSelectedCalendars,
  selectedCalendars,
  updateCalendarType,
  calendarType,
  setDateRange,
  dateRange,
  isAdmin,
}) => {
  const [actionState, updateActionState] = useState({ count: 0, type: "" });
  const [weekData, updateWeekData] = useState({ data: [], dateString: "" });
  const [monthData, updateMonthData] = useState({ data: [], dateString: "" });
  const [contentDim, updateContetDim] = useState({ width: 0, height: 0 });

  useEffect(() => {
    loadScript(MAP_URL);
  }, []);
  const [dayData, updateDayData] = useState({
    dateString: "",
    day: "",
    date: "",
    dateObj: "",
  });

  const { showAlert } = useAlert();
  const weekRangeRef = useRef(null);
  const isFirstimeRef = useRef(true);

  if (!weekRangeRef.current) {
    weekRangeRef.current = dateRange;
  }

  const updateRange = useCallback(() => {
    const { rangeFirstDate, currentDate } = weekRangeRef.current;
    setDateRange({ rangeFirstDate, currentDate });
  }, []);

  /**
   * Used to calculate and update the Week Range data
   */
  const calcAndUpdateWeekRange = ({
    doNotUpdate = false,
    isToday = false,
  } = {}) => {
    if (doNotUpdate) {
      return;
    }

    const { rangeFirstDate } = weekRangeRef.current;
    const { result, dateString } = getWeekRange(
      actionState.type,
      isToday ? "" : rangeFirstDate
    );
    weekRangeRef.current.rangeFirstDate = result[0].dateObj;
    weekRangeRef.current.currentDate = result[result.length - 1].dateObj;
    updateRange();
    updateWeekData({ data: result, dateString });
  };

  /**
   * Used to calculate and update the Day data
   */
  const calcAndUpdateDayData = ({
    doNotUpdate = false,
    isToday = false,
  } = {}) => {
    if (doNotUpdate) {
      return;
    }
    const { currentDate } = weekRangeRef.current;
    const { dateString, day, date, firstDate, newCurrDate } = getDayDataObj(
      actionState.type,
      isToday ? new Date() : currentDate
    );
    weekRangeRef.current.rangeFirstDate = firstDate;
    weekRangeRef.current.currentDate = newCurrDate;
    updateRange();
    updateDayData({ dateString, day, date, dateObj: newCurrDate });
  };

  const calcAndUpdateMonthData = ({
    doNotUpdate = false,
    isToday = false,
  } = {}) => {
    if (doNotUpdate) {
      return;
    }
    const { currentDate } = weekRangeRef.current;
    const { dateString, result, firstDate } = getMonthRange(
      actionState.type,
      isToday ? new Date() : currentDate
    );

    weekRangeRef.current.rangeFirstDate = firstDate;
    weekRangeRef.current.currentDate = firstDate;
    updateRange();
    updateMonthData({ dateString, data: result });
  };

  useEffect(() => {
    switch (calendarType) {
      case "week":
        calcAndUpdateWeekRange({ doNotUpdate: true });
        break;
      case "day":
        calcAndUpdateDayData();
        break;
      case "month":
        calcAndUpdateMonthData({ doNotUpdate: true });
        break;
      default:
        break;
    }
  }, [calendarType]);

  useEffect(() => {
    switch (calendarType) {
      case "week":
        calcAndUpdateWeekRange();
        break;
      case "day":
        calcAndUpdateDayData();
        break;
      case "month":
        calcAndUpdateMonthData();
        break;
      default:
        break;
    }
  }, [actionState]);

  /**
   * Used to get all the schedules of current month
   */
  useEffect(async () => {
    if (isFirstimeRef.current) {
      isFirstimeRef.current = false;
      return;
    }

    try {
      const calendarIds = selectedCalendars
        ? Object.keys(selectedCalendars)
        : [];
      const firstDate = moveToFirstDate(weekRangeRef.current.currentDate);
      const fromDate = getLocalDateString(firstDate);
      const toDate = getLocalDateString(moveToNextMonth(firstDate));
      await getMonthSchedule({
        fromDate,
        toDate,
        calendarIds,
        companyId,
        isAdmin,
      });
    } catch (err) {
      showAlert({ message: err, type: "error" });
    } finally {
    }
  }, [selectedCalendars, companyId, monthData]);

  const onCalendarTypeChange = useCallback((e) => {
    updateActionState(() => ({ type: "" }));
    updateCalendarType({ calendarType: e.target.value });
  }, []);

  const onPrevDateClick = useCallback(() => {
    updateActionState(() => ({ type: "prev" }));
  }, []);

  const onNextDateClick = useCallback(() => {
    updateActionState(() => ({ type: "next" }));
  }, []);

  const onTodayClicked = useCallback(() => {
    switch (calendarType) {
      case "week":
        calcAndUpdateWeekRange({ isToday: true });
        break;
      case "day":
        calcAndUpdateDayData({ isToday: true });
        break;
      case "month":
        calcAndUpdateMonthData({ isToday: true });
        break;
      default:
        break;
    }
  }, [calendarType]);

  const onSetContentRef = useCallback((ref) => {
    if (!ref) {
      return;
    }
    const { width, height } = ref.getBoundingClientRect();
    updateContetDim({ width, height });
  }, []);

  const CurrentCalendar = useMemo(() => {
    switch (calendarType) {
      case "day":
        return DayCalendar;
      case "week":
        return WeekCalendar;
      case "month":
        return MonthCalendar;
      default:
        return "";
    }
  }, [calendarType]);

  const [calendarProps, dateString] = useMemo(() => {
    let props = { data: schedules };
    let dateString = "";
    if (calendarType === "week") {
      props = { ...props, weekData: weekData.data };
      dateString = weekData.dateString;
    }

    if (calendarType === "day") {
      props = { ...props, ...dayData };
      dateString = dayData.dateString;
    }

    if (calendarType === "month") {
      props = { schedules, ...monthData };
      dateString = monthData.dateString;
    }
    return [props, dateString];
  }, [calendarType, weekData, dayData, monthData, schedules]);

  return (
    <ViewContainer>
      <ActionArea
        dateString={dateString}
        onCalendarTypeChange={onCalendarTypeChange}
        calendarType={calendarType}
        onPrevDateClick={onPrevDateClick}
        onNextDateClick={onNextDateClick}
        onTodayClicked={onTodayClicked}
      />
      <CalendarList
        selectedCalendars={selectedCalendars}
        onChange={updateSelectedCalendars}
        style={{ width: contentDim.width }}
      />
      <Content style={{ height: contentDim.height }} ref={onSetContentRef}>
        {!!CurrentCalendar && <CurrentCalendar {...calendarProps} />}
      </Content>
    </ViewContainer>
  );
};

const mapStateToProps = (store, props) => {
  return {
    companyId: getCurrentUserCompany(store, props),
    schedules: getScheduleList(store, props),
    isAdmin: isCurrentUserIsExecutive(store, props),
    selectedCalendars: store.calendar.list.selectedCalendars,
    calendarType: store.calendar.list.calendarType,
    dateRange: store.calendar.list.dateRange,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMonthSchedule,
      updateSelectedCalendars,
      updateCalendarType,
      setDateRange,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
