import { FETCH_ALL_REACTIONS, RESET_REACTIONS_POST_DATA } from "./actionTypes";
import { createNewStoreOnGetData, createInitialStoreState } from "helper";
import { combineReducers } from "redux";

import celebrateReducer from "./celebrateReducer";
import curiousReducer from "./curiousReducer";
import insightfulReducer from "./insightfulReducer";
import likeReducer from "./likeReducer";
import loveReducer from "./loveReducer";
import supportReducer from "./supportReducer";

const initialState = createInitialStoreState({ reactionsCount: {} }, true);

function allReactionsReducer(store = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_ALL_REACTIONS}_SUCCESS`: {
      if (payload?.reactionsType === "total") {
        const { meta } = payload;
        const reactionsCount = meta?.reactionsCount;
        const data = createNewStoreOnGetData(payload, store, true);
        return { ...data, reactionsCount };
      }
      return store;
    }

    case RESET_REACTIONS_POST_DATA: {
      return initialState;
    }

    default:
      return store;
  }
}

const reactionsReducer = combineReducers({
  total: allReactionsReducer,
  celebrate: celebrateReducer,
  curious: curiousReducer,
  insightful: insightfulReducer,
  like: likeReducer,
  love: loveReducer,
  support: supportReducer,
});

export default reactionsReducer;
