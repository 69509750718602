import Icon from "components/Icon";
import ProfilePic from "components/ProfilePic";
import {
  ActivityContainer,
  ActivityContent,
  BoldText,
  CommentDescription,
  DarkTag,
  LightText,
  StatusChangeContainer,
  SubText,
  TextContent
} from "../style";

const StatusActivity = ({ activity }) => {
  return (
    <ActivityContainer>
      <ProfilePic
        img={activity?.amendedBy?.avatarUrl?.url}
        userName={activity?.amendedBy?.name}
        style={{ width: "30px", height: "30px" }}
      />
      <ActivityContent>
        <TextContent>
          <BoldText>{activity?.amendedBy?.name}</BoldText>
          <SubText> updated the status</SubText>
          <LightText>&emsp;{activity?.createdAt} </LightText>
        </TextContent>
        <StatusChangeContainer>
          <DarkTag $type={activity?.previousStatus}>
            {activity?.previousStatus}
          </DarkTag>
          <Icon name="fas fa-arrow-right" />
          <DarkTag $type={activity?.currentStatus}>
            {activity?.currentStatus}
          </DarkTag>
        </StatusChangeContainer>
        {activity?.comment?.message && (
          <CommentDescription>{activity?.comment?.message}</CommentDescription>
        )}
      </ActivityContent>
    </ActivityContainer>
  );
};

export default StatusActivity;
