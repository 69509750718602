import {
  CREATE_ENQUIRY,
  GET_ENQUIRIES,
  SEARCH_ENQUIRIES,
  UPDATE_ENQUIRY_SEARCH_PARAMS,
  RESET_ENQUIRY_SEARCH_PARAMS,
  GET_SUBORDINATE_ENQUIRIES
} from "./actiontypes";

import { createAsyncActionCreator } from "../../Helper";

import { createEnquiryApi, getEnquiriesApi, getSubordinateEnquiriesApi, searchEnquiriesApi } from "./api";

const createEnquiryGenerator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_ENQUIRY,
    sideEffect: createEnquiryApi({ companyId, isAdmin, formData }),
  };
};

const getEnquiriesGenerator = function* ({
  companyId,
  pageNumber,
  isAdmin,
}) {
  yield {
    action: GET_ENQUIRIES,
    sideEffect: getEnquiriesApi({ companyId, pageNumber, isAdmin }),
  };
};

const searchEnquiriesGenerator = function* ({ companyId, pageNumber, isAdmin, collaborator, currentFromDate, currentToDate, enquiryType, currentStatus }) {
  yield {
    action: SEARCH_ENQUIRIES,
    sideEffect: searchEnquiriesApi({ companyId, pageNumber, isAdmin, collaborator, currentFromDate, currentToDate, enquiryType, currentStatus }),
  };
};

export const updateSearchParams = ({ collaborator, from_date, to_date, enquiry_type, status }) => ({
  type: UPDATE_ENQUIRY_SEARCH_PARAMS,
  payload: { collaborator, from_date, to_date, enquiry_type, status }
});

export const resetSearchParams = () => ({
  type: RESET_ENQUIRY_SEARCH_PARAMS
})

const getSubordinateEnquiriesGenerator = function* ({
  companyId,
  pageNumber,
  isAdmin,
  subordinateId
}) {
  yield {
    action: GET_SUBORDINATE_ENQUIRIES,
    sideEffect: getSubordinateEnquiriesApi({ companyId, pageNumber, isAdmin, subordinateId }),
  };
};

export const [
  createEnquiry,
  getEnquiries,
  searchEnquiries,
  getSubordinateEnquiries,
] = createAsyncActionCreator([
  createEnquiryGenerator,
  getEnquiriesGenerator,
  searchEnquiriesGenerator,
  getSubordinateEnquiriesGenerator,
]);
