import styled from "styled-components";
import ComingSoon from "components/ComingSoon";
import { Link } from "react-router-dom";

export const Container = styled.div`
  position: fixed;
  width: 409px;
  // min-height: 300px;
  max-height: 600px;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0 5px 15px -1px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  z-index: 2;
`;

export const Triangle = styled.div`
  position: fixed;
  content: "";
  background-color: #ffffff;
  transform: rotate(45deg);
  z-index: 1;
  ${(props) => props.$pos};
`;

export const StyledComingSoon = styled(ComingSoon)`
  & > img {
    width: 200px;
    height: 200px;
  }
  & > span {
    font-size: ${(props) => props.theme.text.para.small};
  }
  border: none;
`;

export const Tab = styled(Link)`
  width: 100%;
  height: 60px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: #000;
  font-size: ${(props) => props.theme.text.title.medium};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightPrimaryColor};
`;

export const Counter = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: ${(props) => props.theme.text.para.medium};
  background: ${(props) => props.theme.colors.primaryColor};
`;
