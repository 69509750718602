import React from "react";
import WeekColumns from "./WeekColumns";
import { Container } from "./style";

export const DayCalendar = ({ data, day, date, dateObj }) => {
  return (
    <Container>
      <WeekColumns
        weekDaysList={[{ day, date, dateObj }]}
        tasks={data}
        timesRowCount={24}
        timesRowHeight={100}
      />
    </Container>
  );
};
