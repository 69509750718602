import {
  GET_COUNTDOWNS,
  CREATE_COUNTDOWN,
  EDIT_COUNTDOWN,
  DELETE_COUNTDOWN,
} from "./actiontypes";

import { createAsyncActionCreator } from "../../Helper";

import {
  createCountdownApi,
  getCountdownsApi,
  editCountdownApi,
  deleteCountdownApi,
} from "./api";

const getCountdownsGenerator = function* ({ companyId, pageNumber, isAdmin }) {
  yield {
    action: GET_COUNTDOWNS,
    sideEffect: getCountdownsApi({ companyId, pageNumber, isAdmin }),
  };
};

const createCountdownGenerator = function* ({ companyId, formData, isAdmin }) {
  yield {
    action: CREATE_COUNTDOWN,
    sideEffect: createCountdownApi({ companyId, formData, isAdmin }),
  };
};

const editCountdownGenerator = function* ({
  countdownId,
  companyId,
  isAdmin,
  formData,
}) {
  yield {
    action: EDIT_COUNTDOWN,
    sideEffect: editCountdownApi({ countdownId, companyId, isAdmin, formData }),
  };
};
const deleteCountdownGenerator = function* ({
  countdownId,
  companyId,
  isAdmin,
  formData,
}) {
  yield {
    action: DELETE_COUNTDOWN,
    sideEffect: deleteCountdownApi({
      countdownId,
      companyId,
      isAdmin,
      formData,
    }),
  };
};
export const [getCountdowns, createCountdown, editCountdown, deleteCountdown] =
  createAsyncActionCreator([
    getCountdownsGenerator,
    createCountdownGenerator,
    editCountdownGenerator,
    deleteCountdownGenerator,
  ]);
