import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import Paginator from "components/Paginator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";
import { getAllWorkshiftDetails, resetWorkshiftDetail } from "../actionCreator";

import { PaginatorBox } from "globalStyles";
import Icon from "components/Icon";
import {
  EnhancedActionArea,
  StyledButton,
  BackButton,
} from "../../../../../style/display";
import { useHistory, useParams } from "react-router";
import Routes from "route/Path";

const ActionArea = ({
  updateFormVisibility,
  onceFetched,
  companyId,
  isAdmin,
  onLoading,
  style,
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: getAllWorkshiftDetails,
    tableName: "workshift.details",
  });

  const params = useParams();
  const extraParams = { companyId, isAdmin, workshiftId: params.detailId };
  const dispatch = useDispatch();
  const history = useHistory();

  const onClose = () => {
    dispatch(resetWorkshiftDetail());
    history.push(Routes.authorized.workshift.view.path);
  };
  const { fetchFirstPage, fetchNextPage, fetchPrevPage, fetchSpecificPage } =
    usePaginator({ extraParams, paginatorApi: getNextPage });

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <EnhancedActionArea style={style}>
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
      <StyledButton onClick={() => updateFormVisibility(true)} primary>
        Create New
      </StyledButton>
      <BackButton onClick={onClose}>
        <Icon name="fas fa-angle-left" />
      </BackButton>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);

  return {
    companyId,
    onceFetched: store.workshift.details.onceFetched,
    isAdmin,
  };
};

export default connect(mapStateToProps, null)(ActionArea);
