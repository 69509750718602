import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import Icon from "components/Icon";
import useForm from "hooks/useForm";
import Routes from "route/Path";

import { StyledForm } from "../../../../style/create";
import useCreateLeaveType from "./useCreateLeaveType";
import { StatusOptions } from "./options";
import { leaveTypeDataSelector } from "../selector";

const Form = ({ updateFormVisibility, editId }) => {
  const isEditMode = !!editId;
  const history = useHistory();

  const { formData, values, resetForm, isFormValid, updateField } = useForm({
    fields: {
      leaveType: {
        required: true
      },
      color: {},
      status: {
        required: isEditMode,
      }
    }
  });

  const leaveTypeData = useSelector((store) =>
    leaveTypeDataSelector(store, { id: editId }),
  );

  useEffect(() => {
    if (!editId || !leaveTypeData) {
      return;
    }
    updateField("leaveType", leaveTypeData?.leaveType);
    updateField("color", leaveTypeData?.colorCode);
    updateField("status", leaveTypeData?.status.toLowerCase());
  }, [editId, leaveTypeData]);

  const { isLoading, createLeaveTypeData, editLeaveTypeData } =
    useCreateLeaveType();

  const goBack = () => {
    history.replace(Routes.authorized.leaves.view.path);
  };

  const hideForm = () => {
    typeof updateFormVisibility == "function"
      ? updateFormVisibility(false)
      : goBack();
  };

  const onReset = () => {
    typeof resetForm == "function" && resetForm();
    hideForm();
  };

  const onFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isFormValid()) {
        return;
      }
      isEditMode
        ? editLeaveTypeData(editId, values, onReset)
        : createLeaveTypeData(values, resetForm);
    },
    [isFormValid, values, resetForm],
  );

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <Icon name="fas fa-times" onClick={hideForm} />
      <InputGroup
        label="Leave Type"
        placeholder="Enter leave type"
        secondary
        {...formData["leaveType"]}
      />

      <InputGroup
        fieldType="color"
        label="Leave Color"
        secondary
        {...formData["color"]}
      />

      {isEditMode && (
        <InputGroup
          label="Status"
          options={StatusOptions}
          fieldType="radioGroup"
          {...formData["status"]}
        />
      )}

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        {isEditMode ? "Update" : " Create"}
      </Button>
    </StyledForm>
  );
};

export default Form;
