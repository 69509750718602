import { createSelector } from "reselect";

const checkoutStatus = (store) =>
  store.attendance.attendanceTable?.checkOutStatus;
const currentLogId = (store) => store.attendance.attendanceTable?.currentLogId;

export const isUserLoggedIn = createSelector(
  currentLogId,
  checkoutStatus,
  (id, status) => {
    return !!id && !!status?.signOut;
  }
);
