import styled from "styled-components";
import Button from "../Button";
import Counter from "../Counter";

export const NavbarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${(props) => props.theme.dimensions.navbar.height};
  z-index: ${(props) => props.theme.zIndex.navbar};
  background-color: ${(props) => props.theme.colors.navbar.background};
  display: flex;
  align-items: center;
  padding: 0px 10px;
  color: white;
`;

export const NavTitle = styled.img`
  width: 89px;
  height: 28px;
  margin: 0px auto;
  @media (min-width: 768px) {
    width: 100px;
    height: 32px;
    margin-left: 10px;
    margin-right: 0px;
  }
`;

export const ToggleButton = styled(Button)`
  padding: 10px;
  width: 50px;
  background-color: transparent;
  &:hover {
    box-shadow: none;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const MenuBar = styled.img`
  width: 22px;
  height: 17px;
`;

export const RightContainer = styled.div`
  align-self: stretch;
  display: flex;
  margin-left: auto;
  .breaks-icon-container {
    width: 80px;
  }
`;

export const FeatureContainer = styled.div`
  align-self: stretch;
  display: flex;
  margin-right: 50px;
  justify-content: center;
  align-items: center;
  position: relative;
  .break-icon {
    width: 26px;
    height: 26px;
  }
`;
export const FeatureIcon = styled.img`
  width: 17px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledCounter = styled(Counter)`
  top: 10px;
  right: 25%;
  width: 20px;
  height: 20px;
`;
