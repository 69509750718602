import React, { useCallback, useEffect, useMemo, useState } from "react";

import ActionArea from "./ActionArea";
import TableData from "./TableData";

import Tabs from "components/Tabs";
import PageSlider from "components/PageSlider";
import { RectangleSkeleton } from "components/Skeleton";
import EmptyScreen from "assets/employee/TeamsBlank.png";
import { isCurrentUserIsExecutive } from "common/Selectors/auth";
import { ViewContainer } from "../../../../GlobalStyles";
import DetailView from "../Tabs/Details";
import {
  TabContainer,
  EmptyScreenContainer,
  EmptyScreenImage,
  EmptyScreenMessage,
} from "../../style/display";
import { connect, useDispatch, useSelector } from "react-redux";
import { usePageSlider } from "../../../../Hooks/usePageSlider";
import { useTabs } from "../../../../Hooks/useTabs";
import TeamMembersDisplayView from "../Tabs/Members";
import { bindActionCreators } from "redux";
import { resetMembersData } from "../../actionCreator";
import EmployeeDetailView from "../Tabs/Details/EmployeeDetails";
import PageBackHeader from "components/PageBackHeader";
import { resetTeamsWorklog } from "../Tabs/Worklog/redux/actionCreator";

const TeamsDisplayView = ({ currentTeamId, resetMembersData }) => {
  const dispatch = useDispatch();
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const { onCellClick, isSliderActive, toggleSlider, data } = usePageSlider();
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const goBack = () => {
    toggleSlider();
    dispatch(resetTeamsWorklog());
  };
  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  const { CurrentTab: CurrentSliderTab } = useTabs({
    allTabs: [DetailView, TeamMembersDisplayView],
    data: { data, currentTeamId },
  });

  useEffect(() => {
    if (isSliderActive) return resetMembersData;
  }, [isSliderActive]);

  const CurrentTab = useMemo(() => {
    return (
      <ViewContainer>
        <ActionArea
          onLoading={onDbDataChange}
          style={{
            display: isLoading || isEmpty ? "none" : "inherit",
          }}
        />
        <TableData
          style={{
            display: isLoading || isEmpty ? "none" : "inherit",
          }}
          onEmptyData={onEmptyData}
          onCellClick={onCellClick}
        />
        {!isLoading && isEmpty && (
          <EmptyScreenContainer>
            <EmptyScreenImage src={EmptyScreen} alt="No records found" />
            <EmptyScreenMessage>There are no teams yet</EmptyScreenMessage>
          </EmptyScreenContainer>
        )}
        {isLoading && <RectangleSkeleton times={4} />}
      </ViewContainer>
    );
  }, [onCellClick, isLoading, isEmpty, onDbDataChange, onEmptyData]);

  return (
    <>
      {CurrentTab}
      <PageSlider
        showBackButton={!!isAdmin}
        onClose={goBack}
        active={isSliderActive}
      >
        {isAdmin ? (
          <TabContainer>
            <Tabs tabNames={["Detail", "Members"]}>{CurrentSliderTab}</Tabs>
          </TabContainer>
        ) : (
          <EmployeeDetailView
            data={data}
            teamId={currentTeamId}
            resetMembersData={resetMembersData}
            onBack={goBack}
          />
        )}
      </PageSlider>
    </>
  );
};

const mapStateToProps = (store, props) => {
  const currentTeamId = store.teams.owners.teamId;
  return {
    currentTeamId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetMembersData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDisplayView);
