import {
  FETCH_ALL_ATTENDANCE,
  RESET_ATTENDANCE_DATA,
  SEARCH_ATTENDANCE_DATA,
  SET_SEARCH_DATA,
  UPDATE_COLLABORATOR_ID,
  UPDATE_SUBORDINATE_ATTENDANCE_STATUS,
} from "../actiontypes";

import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createPrevData,
  createNewStoreOnUpdate,
} from "../../../Helper";

const initialState = createInitialStoreState(
  {
    prevData: {},
    prevCollaboratorId: null,
    collaboratorId: null,
    searchData: {
      startDate: "",
      endDate: "",
      status: "",
    },
  },
  false,
);

export const attendance = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_ALL_ATTENDANCE}_SUCCESS`: {
      const d = createNewStoreOnGetData(payload, store, false);
      return {
        ...d,
        prevCollaboratorId: d.collaboratorId,
        prevData: {},
      };
    }

    case `${UPDATE_SUBORDINATE_ATTENDANCE_STATUS}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case UPDATE_COLLABORATOR_ID: {
      return {
        ...store,
        collaboratorId: payload.id,
      };
    }

    case `${SEARCH_ATTENDANCE_DATA}_SUCCESS`: {
      const prevData = createPrevData(store);
      const d = createNewStoreOnGetData(payload, store, false);
      return {
        ...d,
        prevData,
      };
    }

    case SET_SEARCH_DATA: {
      const { startDate, endDate, status } = payload;
      return {
        ...store,
        searchData: {
          startDate,
          endDate,
          status,
        },
      };
    }

    case RESET_ATTENDANCE_DATA: {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
        searchData: {
          startDate: "",
          endDate: "",
          status: "",
        },
      };
    }
    default:
      return store;
  }
};
