import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 19px;
`;

export const Img = styled.img`
  width: 577px;
  height: 336.5px;
`;

export const Text = styled.span`
  font-size: ${(props) => props.theme.text.title.medium};
  margin-top: 20px;
`;
