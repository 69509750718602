import {
  CREATE_BREAK,
  GET_BREAKS,
  GET_ACTIVE_BREAKS,
  GET_CURRENT_COLLABORATOR_BREAKS,
  START_BREAK_TIMER,
  UPDATE_BREAK_TIMER,
  GET_SUBORDINATE_BREAKS,
} from "./actiontypes";

import { createAsyncActionCreator } from "../../Helper";

import {
  createBreakApi,
  getBreaksApi,
  getActiveBreaksApi,
  getCurrentCollaboratorBreaksApi,
  startBreakTimerApi,
  updateBreakTimerApi,
  getSubordinateBreaksApi,
} from "./api";

const createBreakGenerator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_BREAK,
    sideEffect: createBreakApi({ companyId, isAdmin, formData }),
  };
};

const getBreaksGenerator = function* ({ companyId, pageNumber, isAdmin }) {
  yield {
    action: GET_BREAKS,
    sideEffect: getBreaksApi({ companyId, pageNumber, isAdmin }),
  };
};

const getActiveBreaksGenerator = function* ({ companyId, isAdmin }) {
  yield {
    action: GET_ACTIVE_BREAKS,
    sideEffect: getActiveBreaksApi({ companyId, isAdmin }),
  };
};

const getCurrentCollaboratorBreaksGenerator = function* ({
  companyId,
  pageNumber,
  isAdmin,
}) {
  yield {
    action: GET_CURRENT_COLLABORATOR_BREAKS,
    sideEffect: getCurrentCollaboratorBreaksApi({
      companyId,
      pageNumber,
      isAdmin,
    }),
  };
};

const startBreakTimerGenerator = function* ({ companyId, snapId, isAdmin }) {
  yield {
    action: START_BREAK_TIMER,
    sideEffect: startBreakTimerApi({ companyId, snapId, isAdmin }),
  };
};

const updateBreakTimerGenerator = function* ({
  companyId,
  collaboratorSnapId,
  isAdmin,
}) {
  yield {
    action: UPDATE_BREAK_TIMER,
    sideEffect: updateBreakTimerApi({ companyId, collaboratorSnapId, isAdmin }),
  };
};

const getSubordinateBreaksGenerator = function* ({
  companyId,
  pageNumber,
  isAdmin,
  subordinateId,
}) {
  yield {
    action: GET_SUBORDINATE_BREAKS,
    sideEffect: getSubordinateBreaksApi({ companyId, pageNumber, isAdmin, subordinateId }),
  };
};

export const [
  createBreak,
  getBreaks,
  getActiveBreaks,
  getCurrentCollaboratorBreaks,
  startBreakTimer,
  updateBreakTimer,
  getSubordinateBreaks
] = createAsyncActionCreator([
  createBreakGenerator,
  getBreaksGenerator,
  getActiveBreaksGenerator,
  getCurrentCollaboratorBreaksGenerator,
  startBreakTimerGenerator,
  updateBreakTimerGenerator,
  getSubordinateBreaksGenerator,
]);
