import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  padding: 0 50px 20px 50px;
  display: flex;
  flex-flow: column nowrap;
`;

export const FormHeading = styled.h3`
  font-size: ${(props) => props.theme.text.title.large};
  color: ${(props) => props.theme.colors.primaryColor};
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 600;
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & i {
    color: ${(props) => props.theme.colors.primaryColor};
    font-size: ${(props) => props.theme.text.title.large};
    margin: 20px 0 20px;
    cursor: pointer;
  }
`;

export const StyledForm = styled.form`
  max-width: 780px;
  width: 300px;

  margin: 20px;
  & > i {
    color: ${(props) => props.theme.colors.primaryColor};
    font-size: ${(props) => props.theme.text.title.large};
    margin: 20px 0 20px;
    cursor: pointer;
    float: right;
  }
`;
export const FieldInfoTag = styled.div`
  align-self: flex-start;
  margin-bottom: 25px;
  color: ${(props) => props.theme.colors.label};
  font-size: ${(props) => props.theme.text.para.extraSmall};

  & span {
    margin-left: 10px;
  }
`;
