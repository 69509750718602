import styled from "styled-components";
import { StyledActionArea } from "globalStyles";
import FormGroup from "components/FormGroup";
import { ImageComponent } from "../../../Common/Components/Image";

export const EnhancedActionArea = styled(StyledActionArea)`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;

export const SearchArea = styled.div`
  width: 100%;
  align-self: center;
  margin: 10px;

  & > form > button {
    margin-top: 5px;
    flex-basis: 75%;
  }
`;

export const StyledForm = styled(FormGroup)`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  gap: 20px;

  & {
    margin: 0 0 8px; 0;
  }

  & div {
    height: 35px;
  }

  & div > select {
    height: 35px;
    padding: 0 10px;
  }

  & button {
    height: 35px;
    padding: 7px;
  }

  @media (min-width: 768px) {
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;

    & div, button {
      max-width: 200px;
    }
  }

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  @media (min-width: 768px) {
    min-width: 420px;
  }
`;

export const AsyncSearchField = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  & > div > div > div {
    height: 100px;
  }

  @media (min-width: 768px) {
    min-width: 420px;

    & div {
      max-width: 420px;
    }
  }
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    height: 35px;
  }
`;

export const EmptyScreenImage = styled(ImageComponent)`
  width: 40%;
  height: 40%;
  object-fit: contain;
`;

export const EmptyScreenMessage = styled.span`
  margin: 30px;
  font-size: ${(props) => props.theme.text.title.medium};
`;

export const PaginatorAndCreateBox = styled.div`
  display: flex;
  width: 100%;
`;
