import { CREATE_EMERGENCY_CONTACT, GET_EMERGENCY_CONTACTS, GET_EMERGENCY_CONTACTS_BY_COLLABORATOR } from "./actiontypes";

import { createAsyncActionCreator } from "helper";

import { createEmergencyContactApi, getEmergencyContactsApi, getEmergencyContactsByCollaboratorApi } from "./api";

const createEmergencyContactGenrator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_EMERGENCY_CONTACT,
    sideEffect: createEmergencyContactApi({ companyId, isAdmin, formData }),
  };
};

export const [createEmergencyContact] = createAsyncActionCreator([
  createEmergencyContactGenrator,
]);

const getEmergencyContactsGenerator = function* ({
  companyId,
  pageNumber,
  isAdmin,
}) {
  yield {
    action: GET_EMERGENCY_CONTACTS,
    sideEffect: getEmergencyContactsApi({ companyId, pageNumber, isAdmin }),
  };
};

export const getEmergencyContacts = createAsyncActionCreator(
  getEmergencyContactsGenerator
);

const getEmergencyContactsByCollaboratorGenerator = function* ({
  companyId,
  pageNumber,
  userId,
  isAdmin,
}) {
  yield {
    action: GET_EMERGENCY_CONTACTS_BY_COLLABORATOR,
    sideEffect: getEmergencyContactsByCollaboratorApi({ companyId, pageNumber, userId, isAdmin }),
  };
};

export const getEmergencyContactsByCollaborator = createAsyncActionCreator(
  getEmergencyContactsByCollaboratorGenerator
);
