import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";

import { PaginatorBox, StyledActionArea } from "../../../../GlobalStyles";
import Paginator from "components/Paginator";
import usePagination from "../../../../Hooks/usePagination";

import { getFollowSubordinates } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";

const ActionArea = ({ onLoading, style, onceFetched, companyId, isAdmin }) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: getFollowSubordinates,
    tableName: "followSubordinate.subordinates",
  });

  const fetchFirstPage = useCallback(() => {
    getNextPage({
      isFirstPage: true,
      extraParams: { companyId, isAdmin },
    });
  }, [getNextPage, companyId, isAdmin]);

  const fetchPrevPage = useCallback(() => {
    getNextPage({
      fetchPrevPage: true,
      extraParams: { companyId, isAdmin },
    });
  }, [getNextPage, companyId, isAdmin]);

  const fetchNextPage = useCallback(() => {
    getNextPage({
      extraParams: { companyId, isAdmin },
    });
  }, [getNextPage, companyId, isAdmin]);

  const fetchSpecificPage = useCallback(
    (pageNumber) => {
      getNextPage({
        pageNumber,
        extraParams: { companyId, isAdmin },
      });
    },
    [getNextPage, companyId, isAdmin]
  );

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <StyledActionArea style={style}>
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </StyledActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    onceFetched: store.followSubordinate.subordinates.onceFetched,
    isAdmin,
  };
};
export default connect(mapStateToProps, null)(ActionArea);
