import {
  ContentContainer,
  ContentDescription,
  ContentHeader,
} from "modules/Attendence/style/details";
import React from "react";

export const DetailItems = ({ heading, description, color }) => {
  return (
    <ContentContainer>
      <ContentHeader $workLogTypeColor={color}>{heading}</ContentHeader>
      <ContentDescription $workLogTypeColor={color}>
        {description}
      </ContentDescription>
    </ContentContainer>
  );
};
