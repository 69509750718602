import styled from "styled-components";
import { FadeInHorizontal, FadeInVertical } from "../../../GlobalStyles";

export const Content = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  line-height: 20px;
  font-size: ${(props) => props.theme.text.para.small};
  white-space: pre-wrap;
  animation: ${(props) => getAnimation(props.$position)} ease-out 0.2s forwards;
`;

function getAnimation(position) {
  switch (position) {
    case "top":
      return FadeInVertical("top");
    case "bottom":
      return FadeInVertical("bottom");
    case "left":
      return FadeInHorizontal("left");
    case "right":
      return FadeInHorizontal("right");
    default:
      return "";
  }
}
