export const relationOptions = [
  { name: "Mother", value: "mother" },
  { name: "Father", value: "father" },
  { name: "Brother", value: "brother" },
  { name: "Sister", value: "sister" },
  { name: "Spouse", value: "spouse" },
  { name: "Child", value: "child" },
  { name: "Friend", value: "friend" },
  { name: "Guardian", value: "guardian" },
  { name: "Other", value: "other" },
];

export const isMedicalClaimInclusiveOption = [
  { name: "Is family member included in medical claim?", value: "medical_claim_inclusive" }
];
