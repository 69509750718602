import styled from "styled-components";
import { FadeInHorizontal } from "globalStyles";
const LeftFade = FadeInHorizontal("left");

export const MainContainer = styled.div`
  height: 100%;
  padding: 0 50px 20px 50px;
  display: flex;
  flex-flow: column nowrap;
  animation: ${LeftFade} ease-out 0.3s forwards;
`;

export const FormHeading = styled.h3`
  font-size: ${(props) => props.theme.text.title.large};
  color: ${(props) => props.theme.colors.primaryColor};
  margin-bottom: 20px;
  margin-top: 45px;
  font-weight: 600;
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & i {
    color: ${(props) => props.theme.colors.primaryColor};
    font-size: ${(props) => props.theme.text.title.large};
    margin: 45px 0 20px;
    cursor: pointer;
  }
`;

export const CreateButton = styled.div`
  align-self: flex-end;
  padding-right: 10px;
  margin-bottom: 10px;
  & button {
    height: 35px;
    padding: 0 20px;
  }
`;
