import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Paginator from "components/Paginator";

import usePagination from "../../../../Hooks/usePagination";
import {
  getActiveUsers,
  getInactiveUsers,
  changePageCategory,
} from "../../actionCreator";
import { getCurrentUserCompany, isCurrentUserIsExecutive } from "common/Selectors/auth";

import { PaginatorBox } from "../../../../GlobalStyles";
import { EnhancedActionArea } from "../../style/display";

const ActionArea = ({
  onceFetched,
  changePageCategory,
  currentCategory,
  isAdmin,
  companyId,
  tabCategory,
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator:
      currentCategory === "active" ? getActiveUsers : getInactiveUsers,
    tableName: "user",
  });

  const fetchFirstPage = useCallback(() => {
    getNextPage({
      isFirstPage: true,
      extraParams: { companyId, isAdmin },
    });
  }, [getNextPage, companyId, isAdmin]);

  const fetchNextPage = useCallback(() => {
    getNextPage({
      extraParams: { companyId, isAdmin },
    });
  }, [getNextPage, companyId, isAdmin]);

  const fetchPrevPage = useCallback(() => {
    getNextPage({
      fetchPrevPage: true,
      extraParams: { companyId, isAdmin },
    });
  }, [getNextPage, companyId, isAdmin]);

  const fetchSpecificPage = useCallback(
    (pageNumber) => {
      getNextPage({
        pageNumber,
        extraParams: { companyId, isAdmin },
      });
    },
    [getNextPage, companyId, isAdmin]
  );

  useEffect(() => {
    if (currentCategory !== tabCategory)
      changePageCategory({ category: tabCategory });
  }, [tabCategory, currentCategory]);

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [currentCategory]);

  return (
    <EnhancedActionArea>
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </EnhancedActionArea>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePageCategory,
    },
    dispatch
  );

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);

  return {
    companyId,
    isAdmin,
    onceFetched: store.user.onceFetched,
    currentCategory: store.user.currentPageCategory,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionArea);
