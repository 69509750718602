import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useAlert from "hooks/useAlert";

import {
  updateLeaveStatus,
  cancelLeave as cancelLeaveActionCreator,
} from "../redux/actionCreator";
import { getCurrentUserCompany } from "common/Selectors/auth";

function useUpdateLeaveStatus() {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const companyId = useSelector(getCurrentUserCompany);

  const updateStatus = async ({ leaveId, status }) => {
    try {
      updateLoading(true);
      await dispatch(updateLeaveStatus({ companyId, leaveId, status }));
      setSuccess(true);
    } catch (err) {
      showAlert({ message: err, type: "error" });
    } finally {
      updateLoading(false);
    }
  };

  const cancelLeave = async ({ leaveId, status }) => {
    try {
      updateLoading(true);
      await dispatch(
        cancelLeaveActionCreator({
          companyId,
          leaveId,
          status
        })
      );
      setSuccess(true);
    } catch (err) {
      showAlert({ message: err, type: "error" });
    } finally {
      updateLoading(false);
    }
  };

  return {
    updateStatus,
    cancelLeave,
    isLoading,
    success
  };
}

export default useUpdateLeaveStatus;
