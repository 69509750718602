import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import TabsRenderer from "./TabsRenderer";
import DrawerLink from "../DrawerLink";
import { generateDrawerLinks } from "helper";

import Reimbursement_Img from "assets/Ticket.svg";
import E_Reimbursement_Img from "assets/employee/Ticket.svg";

const [ReimbursementTabLinks, ReimbursementLinkComponents] =
  generateDrawerLinks(
    Routes.authorized.reimbursement,
    AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
    DrawerLink
  );

export const ReimbursementTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType(
        "reimbursement_section"
      )}
    >
      {/* <DrawerTitle title="Reimbursement Section" /> */}
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("reimbursement")}
        image={isAdmin ? Reimbursement_Img : E_Reimbursement_Img}
        title="Reimbursement"
        links={ReimbursementTabLinks}
      >
        {ReimbursementLinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
