import styled from "styled-components";

export const ChipContainer = styled.div`
  width: auto;
`;

export const ChipBox = styled.div`
  min-width: ${(props) => props.theme.dimensions.chip.width};
  height: ${(props) => props.theme.dimensions.chip.height};
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  color: ${(props) => props.theme.colors.primaryColor};
  border: 1px ${(props) => props.theme.colors.chip.borderColor} solid;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${(props) =>
      !props.checked ? "rgba(0, 123, 255, 0.2)" : ""};
  }
  ${(props) => {
    if (props.disabled) {
      return `
          cursor: not-allowed;
          color: black;
          background-color: lightgray;
          border: 1px lightgray solid;
          `;
    }

    if (props.checked) {
      return `
        background-color: ${props.theme.colors.chip.background};
        color: white;
        font-weight: bold;
        `;
    }
    return "";
  }}
`;

export const ChipLabel = styled.span`
  font-size: ${(props) => props.theme.text.para.extraSmall};
  text-transform: capitalize;
`;
