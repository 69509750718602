import { useCallback, useEffect, useMemo } from "react";
import usePagination from "hooks/usePagination";
import { getTeams } from "../actionCreator";
import { useSelector } from "react-redux";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { getOncefetched } from "common/Selectors/store";

const onceFetchedSelector = getOncefetched("teams.owners");

export const useTeamViewPagination = () => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: getTeams,
    tableName: "teams.owners",
  });

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const onceFetched = useSelector(onceFetchedSelector);

  const extraParams = useMemo(
    () => ({ companyId, isAdmin }),
    [companyId, isAdmin]
  );

  const fetchFirstPage = useCallback(() => {
    getNextPage({
      isFirstPage: true,
      extraParams,
    });
  }, [getNextPage, extraParams]);

  const fetchPrevPage = useCallback(() => {
    getNextPage({ extraParams });
  }, [getNextPage, extraParams]);

  const fetchNextPage = useCallback(() => {
    getNextPage({ extraParams });
  }, [getNextPage, extraParams]);

  const fetchSpecificPage = useCallback(
    (pageNumber) => {
      getNextPage({
        pageNumber,
        extraParams,
      });
    },
    [getNextPage, extraParams]
  );

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, []);

  return {
    currentPageNumber,
    totalPages,
    fetchNextPage,
    fetchPrevPage,
    fetchSpecificPage,
    loadingNextPage,
    loadingPrevPage,
  };
};
