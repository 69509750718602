import { GET_HOLIDAY_YEARS_FOR_VIEW, CREATE_HOLIDAY_YEAR } from "./actiontypes";
import { getHolidayYearsForViewApi, createHolidayYearApi } from "./api";

import { createAsyncActionCreator } from "../../Helper";

const createHolidayYearGenerator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_HOLIDAY_YEAR,
    sideEffect: createHolidayYearApi({ companyId, isAdmin, formData }),
  };
};

const getHolidayYearsForViewGenerator = function* ({ companyId, isAdmin, pageNumber }) {
  yield {
    action: GET_HOLIDAY_YEARS_FOR_VIEW,
    sideEffect: getHolidayYearsForViewApi({ companyId, isAdmin, pageNumber }),
  };
};

export const [
  createHolidayYear,
  getHolidayYearsForView,
] = createAsyncActionCreator([
  createHolidayYearGenerator,
  getHolidayYearsForViewGenerator,
]);
