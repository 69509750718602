import React from "react";
import Worker_Img from "assets/common/workers.svg";
import { Container, Img, Text } from "./style";

const ComingSoon = ({ className }) => {
  return (
    <Container className={className}>
      <Img
        src={Worker_Img}
        alt="a group of people working together on a project"
      />
      <Text>Coming Soon</Text>
    </Container>
  );
};

export default ComingSoon;
