import React, { useMemo } from "react";

import { getDayTime } from "../utils";
import { Container, TimePeriod, Time } from "./style";

const TimeArea = ({ rowHeight, topDistance = 0, width = "100%" }) => {
  const time = useMemo(() => {
    const result = [];
    for (let i = 0; i < 24; i++) {
      result.push({ time: getDayTime(i) });
    }
    return result;
  }, []);

  const timeRows = useMemo(() => {
    return time.map((data) => {
      return (
        <TimePeriod key={data.time} style={{ minHeight: `${rowHeight}px` }}>
          <Time>{data.time}</Time>
        </TimePeriod>
      );
    });
  }, [time, rowHeight]);

  return <Container style={{ top: topDistance, width }}>{timeRows}</Container>;
};

export default TimeArea;
