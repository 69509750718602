import styled from "styled-components";

export const MainContainer = styled.div`
  width: 300px;
  min-height: 200px;
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;
`;

export const StringContainer = styled(MainContainer)`
  padding: 0;
`;

export const StyledStringContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  // word-break: break-all;
  line-height: 23px;
`;

export const SingleListItemBox = styled.div`
  width: auto;
  display: flex;
  flex-flow: column nowrap;
`;

export const MultipleListItemBox = styled.div`
  width: auto;
  display: flex;
  flex-flow: row wrap;
  position: relative;
`;

export const MultipleListItem = styled.span`
  padding: 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.popover.item.background};
  color: white;
  font-size: ${(props) => props.theme.text.para.small};
  margin-right: 5px;
`;
export const ListHeadingBox = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding-right: 10px;
`;

export const ListHeadingPosition = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  background-color: ${(props) => props.theme.colors.popover.heading.background};
  font-size: ${(props) => props.theme.text.para.small};
  color: white;
  font-weight: bold;
`;

export const ListBoxTitle = styled.h3`
  font-size: ${(props) => props.theme.text.title.large};
  color: ${(props) => props.theme.colors.popover.item.title};
  text-transform: capitalize;
  font-weight: bolder;
  margin-bottom: 10px;
`;

export const ListBoxDesc = styled.span`
  font-size: ${(props) => props.theme.text.para.small};
  color: ${(props) => props.theme.colors.popover.item.text};
`;

export const StringHeadingBox = styled.div`
  width: 100%;
  font-weight: bold;
  text-transform: capitalize;
  background-color: var(--warning-color);
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
