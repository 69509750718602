import ImageSlider from "components/ImageSlider";
import React, { useState } from "react";
import ImageSliderModal from "./modal";

const FullscreenImageSlider = ({
  imageIndex,
  images,
  renderImage,
  renderSettings,
  ...rest
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModalVisibility = () => {
    setIsModalVisible((prev) => !prev);
  };
  return (
    <>
      <ImageSlider
        onImageClick={toggleModalVisibility}
        imageIndex={imageIndex}
        images={images}
        renderImage={renderImage}
        renderSettings={renderSettings}
        {...rest}
      />
      <ImageSliderModal
        active={isModalVisible}
        closeModal={toggleModalVisibility}
        images={images}
      />
    </>
  );
};

export default FullscreenImageSlider;
