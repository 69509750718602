export const getCurrentIndexRowDataAndColumn = (rows, rowIndex) => {
  return {
    data: rows[rowIndex],
    columns: rows[0],
  };
};

export const arrayToObject = (arr = []) => {
  const res = {};
  arr.forEach((pair) => {
    const [key, value] = pair;
    res[key] = value;
  });

  return res;
};
