import { createPath, getMyPaths, setNonIterableProperty } from "../../Helper";

export const workshift = {
  view: createPath("/view_workshift", "View"),
  edit: createPath("/edit_workshift", "", "id"),
  details: createPath("/workshift_details", "", "detailId"),
  editDetails: createPath("/edit_workshift_details", "", "detailId"),
  createDetails: createPath("/create_workshift_details", ""),
};

setNonIterableProperty(workshift, "getMyPaths", getMyPaths);
