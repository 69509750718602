import styled from "styled-components";

export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const ImageContent = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  border-radius: 6px;
  & > div {
    scroll-snap-align: center;
  }
  margin-top: 9px;
`;
export const ImageBox = styled.div`
  flex-basis: 100%;
  flex-shrink: 0;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
  animation: ease-out 0.4s forwards;
`;

export const SliderButton = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 45%;
  box-shadow: ${(props) => props.theme.shadow.medium};
  background-color: white;
  & > i {
    font-size: 13px;
  }
`;
export const RightSliderButton = styled(SliderButton)`
  right: -12px;
`;

export const LeftSliderButton = styled(SliderButton)`
  left: -12px;
`;
