import { DE_ACTIVATE_ALERT, ACTIVATE_ALERT } from "../ActionTypes/alert";

const initialState = {
  alertsList: {},
};

export const alertReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIVATE_ALERT: {
      const {
        message,
        type: alertType,
        verticalPosition,
        horizontalPosition,
        id,
      } = payload;
      return {
        ...state,
        alertsList: {
          [id]: {
            message,
            type: alertType,
            verticalPosition,
            horizontalPosition,
          },
          ...state.alertsList,
        },
      };
    }

    case DE_ACTIVATE_ALERT: {
      const { id } = payload;
      const newList = { ...state.alertsList };
      if (state.alertsList[id]) {
        delete newList[id];
      }
      return {
        ...state,
        alertsList: newList,
      };
    }
    default:
      return state;
  }
};
