import { FETCH_ALL_REACTIONS, RESET_REACTIONS_POST_DATA } from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import { fetchAllReactionsApi } from "./api";

const fetchAllReactionsGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
  reactableType,
  reactableId,
  reactionsType,
}) {
  yield {
    action: FETCH_ALL_REACTIONS,
    sideEffect: fetchAllReactionsApi({
      companyId,
      isAdmin,
      pageNumber,
      reactableType,
      reactableId,
      reactionsType,
    }),
    payload: { reactionsType },
  };
};

export const resetReactionPostData = () => ({
  type: RESET_REACTIONS_POST_DATA,
});

export const [fetchAllReactions] = createAsyncActionCreator([
  fetchAllReactionsGenrator,
]);
