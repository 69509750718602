export const IsPublicOptions = [
  { name: "Yes", value: "1" },
  { name: "No", value: "0" },
];

export const AccessTypeOptions = [
  { name: "Viewer", value: "viewer" },
  { name: "Editor", value: "editor" },
];

export const FullDayOptions = [{ name: "Full day event", value: "fullDay" }];
