import { CREATE_HOLIDAY_YEAR, GET_HOLIDAY_YEARS_FOR_VIEW } from "./actiontypes";

import { createInitialStoreState, createNewStoreOnCreate, createNewStoreOnGetData } from "../../Helper";

const initialState = createInitialStoreState();

export const holidayYearsReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_HOLIDAY_YEARS_FOR_VIEW}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${CREATE_HOLIDAY_YEAR}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);

    default:
      return store;
  }
};
