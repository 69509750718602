import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import TabsRenderer from "./TabsRenderer";
import DrawerLink from "../DrawerLink";
import { generateDrawerLinks } from "helper";

import { ActivityIcon } from "../../../../AssetComponents";

const [LeadsTabLinks, LeadsLinkComponents] = generateDrawerLinks(
  Routes.authorized.leads,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

export const LeadsTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType("leads_section")}
    >
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("leads")}
        SvgComponent={<ActivityIcon />}
        title="Leads / Manager"
        links={LeadsTabLinks}
      >
        {LeadsLinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
