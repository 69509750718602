import React from "react";
import OnlyWhenAuthorized from "components/Auth/OnlyWhenAuthorized";
import {
  Container,
  TabDescription,
  TabName,
  ImageBox,
  Image,
  ForwardIconBox,
  ForwardIcon,
  TextBox,
} from "./style";
import { getSpacedString } from "../../../../../Helper";
const TabCard = ({
  name,
  desc,
  icon,
  accessType,
  backgroundColor,
  color,
  overlay,
  to,
}) => {
  return (
    <OnlyWhenAuthorized requiredAccess={accessType}>
      <Container
        style={{
          backgroundColor,
        }}
        to={to}
      >
        <TextBox>
          <TabName style={{ color }}>{getSpacedString(name)}</TabName>
          <TabDescription>{desc}</TabDescription>
        </TextBox>
        {icon ? (
          <ImageBox>
            <Image src={icon} />
          </ImageBox>
        ) : null}

        <ForwardIconBox style={{ backgroundColor: overlay }}>
          <ForwardIcon name="fas fa-chevron-right" />
        </ForwardIconBox>
      </Container>
    </OnlyWhenAuthorized>
  );
};

export default TabCard;
