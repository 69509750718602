import { combineReducers } from "redux";
import { searchedUsersReducer } from "./searchedUsers";
import { usersReducer } from "./users";
import { ownersReducer } from "./owners";
import { membersReducer } from "./members";
import { teamsWorkLogReducer } from "./worklog";

export const teamsReducer = combineReducers({
  searchedUsers: searchedUsersReducer,
  users: usersReducer,
  owners: ownersReducer,
  members: membersReducer,
  worklog: teamsWorkLogReducer,
});
