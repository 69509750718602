import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react";

import useTransition from "hooks/useTransition";

import Button from "../Button";
import Icon from "../Icon";
import InsertInDom from "../InsertInDom";
import {
  Container,
  Content,
  CloseButton,
  Overlay,
  Image,
  DownloadButton,
} from "./style";
import SliderButton from "./SliderButton";

const ImageSliderModal = ({ active, images, closeModal }) => {
  const { isVisible } = useTransition({ active, maxWait: 500 });

  const onContentClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const nextIndex = () => {
    if (currentIndex < images?.length) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const prevIndex = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const showLeftBtn = useMemo(() => {
    return currentIndex > 0;
  }, [currentIndex]);

  const showRightBtn = useMemo(() => {
    return currentIndex < images?.length - 1;
  }, [currentIndex, images]);

  return isVisible ? (
    <InsertInDom isModal={true} domId={null}>
      <Container>
        <Content onClick={onContentClick} active={active}>
          <Image src={images[currentIndex]} />
        </Content>
        <CloseButton onClick={closeModal}>
          <Icon name="fas fa-times" />
        </CloseButton>
        <SliderButton
          showLeftBtn={showLeftBtn}
          showRightBtn={showRightBtn}
          onNextClick={nextIndex}
          onPrevClick={prevIndex}
        />
        <Overlay active={active} />
      </Container>
    </InsertInDom>
  ) : null;
};

export default ImageSliderModal;
