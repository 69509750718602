import { FETCH_SURVEY_DATA, SUBMIT_SURVEY_DATA } from "./actionTypes";

import { fetchSurveyDataApi, submitSurveyDataApi } from "./api";
import { createAsyncActionCreator } from "helper/index";
import { TactionCreator } from "types/common";

import { ISurveyDetails, ISubmitSurveyDetails } from "../types";

const fetchSurveyDataGenerator = function* ({
  companyId,
  isAdmin,
  surveyId,
}: ISurveyDetails) {
  yield {
    action: FETCH_SURVEY_DATA,
    sideEffect: fetchSurveyDataApi({ companyId, isAdmin, surveyId }),
  };
};

const submitSurveyDataGenerator = function* ({
  companyId,
  isAdmin,
  surveyId,
  formData,
}: ISubmitSurveyDetails) {
  yield {
    action: SUBMIT_SURVEY_DATA,
    sideEffect: submitSurveyDataApi({ companyId, isAdmin, formData, surveyId }),
  };
};

export const [fetchSurveyData, submitSurveyData] = createAsyncActionCreator([
  fetchSurveyDataGenerator,
  submitSurveyDataGenerator,
]) as TactionCreator[];
