import React, { useMemo, useEffect, useRef } from "react";

import Drawer from "./Drawer";
import MobileDrawer from "./MobileDrawer";

import useDrawer from "../../../Hooks/useDrawer";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

import { debounce } from "../../../Helper";

const MainDrawer = () => {
  const debounceFuncRef = useRef(null);
  const { dimesnions } = useWindowDimensions();
  const { isDrawerActive, toggleDrawer, openDrawer } = useDrawer();
  const isMobile = useMemo(() => {
    return dimesnions.width <= 800;
  }, [dimesnions.width]);

  if (!debounceFuncRef.x) {
    debounceFuncRef.current = debounce(openDrawer, 500);
  }

  useEffect(() => {
    const isDesktop = dimesnions.width > 800;
    if (isDesktop) {
      debounceFuncRef.current();
    }
  }, [dimesnions.width]);

  if (isMobile) {
    return (
      <MobileDrawer
        isDrawerActive={isDrawerActive}
        toggleDrawer={toggleDrawer}
      />
    );
  } else {
    return <Drawer isMobileView={false} isDrawerActive={isDrawerActive} />;
  }
};

export default MainDrawer;
