import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const createEmergencyContactApi = ({ companyId, isAdmin, formData }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/emergency_contacts`,
  });
  return requestData(url, {
    method: "POST",
    body: {
      emergency_contact: {
        relationship: formData.relationship,
        name: formData.name,
        phone: formData.phone,
        address: formData.address,
        remarks: formData.note,
      },
    },
    isFormData: true,
  });
};

export const getEmergencyContactsApi = ({ companyId, pageNumber, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/emergency_contacts?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getEmergencyContactsByCollaboratorApi = ({
  companyId,
  pageNumber,
  userId,
  isAdmin,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_emergency_contacts?collaborator_id=${userId}&page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
