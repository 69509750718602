import React, { useCallback, useEffect } from "react";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import Icon from "components/Icon";
import useForm from "hooks/useForm";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { workshiftDetailDataSelector } from "../selector";
import ProfilePic from "components/ProfilePic";

import {
  FormRow,
  StyledForm,
  Label,
  StyledProfile,
} from "../../../../../style/create";
import { statusOptions } from "./constants";
import useCreateWorkshiftDetail from "./useCreateWorkshiftDetail";
import { getUserListData } from "common/Api";

const Form = ({ updateFormVisibility, editId, workshiftId }) => {
  const isEditMode = !!editId;
  const history = useHistory();
  const { formData, values, resetForm, isFormValid, updateField } = useForm({
    editId,
    fields: {
      name: {
        required: !isEditMode,
      },
      status: { required: isEditMode },
      start_date: { required: true },
      end_date: { required: false },
    },
  });
  const goBack = () => {
    history.goBack();
  };
  const hideForm = () => {
    typeof updateFormVisibility == "function"
      ? updateFormVisibility(false)
      : goBack();
  };
  const onReset = () => {
    typeof resetForm == "function" && resetForm();
    hideForm();
  };
  const { isLoading, createWorkshiftDetailData, editWorkshift } =
    useCreateWorkshiftDetail();

  const editWorkshiftDetailData = useSelector((store) =>
    workshiftDetailDataSelector(store, { id: editId })
  );
  useEffect(() => {
    if (!editId || !editWorkshiftDetailData) {
      return;
    }
    updateField("status", editWorkshiftDetailData?.status);
    updateField(
      "start_date",

      new Date(editWorkshiftDetailData?.startDate).toString()
    );
    updateField(
      "end_date",
      new Date(editWorkshiftDetailData?.endDate).toString()
    );
  }, [editId, editWorkshiftDetailData]);

  const onFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isFormValid()) {
        return;
      }
      console.log(editId);
      editId
        ? editWorkshift(values, onReset, editId, workshiftId)
        : createWorkshiftDetailData(values, resetForm, workshiftId);
    },
    [isFormValid, values, resetForm, editId]
  );

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <Icon name="fas fa-times" onClick={hideForm} />
      {!isEditMode && (
        <InputGroup
          label="Collaborator Name"
          fieldType="asyncSearchSelect"
          placeholder="Enter Collaborator Name"
          api={getUserListData}
          singleSelect
          {...formData["name"]}
        />
      )}
      {isEditMode && editWorkshiftDetailData && (
        <>
          <Label>Assigned To</Label>
          <StyledProfile>
            <ProfilePic
              img={editWorkshiftDetailData?.collaborator?.avatarUrl}
              userName={editWorkshiftDetailData?.collaborator?.name}
            />
            {editWorkshiftDetailData?.collaborator?.name}
          </StyledProfile>
        </>
      )}
      {isEditMode && (
        <InputGroup
          label="Status"
          defaultSelectText="Status"
          options={statusOptions}
          fieldType="radioGroup"
          {...formData["status"]}
        />
      )}
      <FormRow>
        <InputGroup
          label="Start Date"
          fieldType="datePicker"
          placeholder="Enter date"
          {...formData["start_date"]}
        />
        <InputGroup
          label="End Date"
          fieldType="datePicker"
          placeholder="Enter date"
          {...formData["end_date"]}
        />
      </FormRow>

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        {isEditMode ? "Update" : "Create"}
      </Button>
    </StyledForm>
  );
};

export default Form;
