import React from "react";

import { RectangleLoader, Container } from "./style";

export const RectangleSkeleton = ({ times, className }) => {
  const res = [];
  for (let i = 0; i < times; i++) {
    res.push(<RectangleLoader key={`Rectangle_${i}`} />);
  }
  return <Container className={className}>{res}</Container>;
};

RectangleSkeleton.defaultProps = {
  times: 1,
  className: "",
};
