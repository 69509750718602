import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import InsertInDom from "../InsertInDom";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

import styles from "./PopoverAdvanced2.module.css";

import { MainConatiner, Backdrop, PopoverContainer, Triangle } from "./style";

const maxToAndBottomDistanceFromViewport = 10;
const maxLeftAndRightDistanceFromViewport = 10;

const getOppositeDirection = (direction) => {
  switch (direction) {
    case "top":
      return "bottom";
    case "bottom":
      return "top";
    case "left":
      return "right";
    case "right":
      return "left";
    default:
      return "";
  }
};

const Popover = ({
  children,
  anchorElem,
  active = false,
  onClose = null,
  userPreferedDirection = "bottom", // top, left, right, bottom
  maxOffest = 10,
}) => {
  const [popPosition, updatePopPosition] = useState({
    direction: "",
    position: "",
    top: Number.MIN_SAFE_INTEGER,
    left: Number.MIN_SAFE_INTEGER,
  });
  const contentRef = useRef();
  const anchorRef = useRef();

  const { dimesnions: windowDimensions } = useWindowDimensions();

  const getPreferredPositionData = useCallback(
    (preferdDirection, contentDimensions) => {
      const aD = anchorRef.current; // ac = anchor dimensions
      const cD = contentDimensions; // cd = content dimensions

      let data = {
        position: "",
        top: 0,
        left: 0,
        direction: "",
      };
      switch (preferdDirection) {
        case "bottom":
        case "top":
          {
            if (
              windowDimensions.width -
                (aD.left + aD.width / 2 - cD.width / 2) >=
              0
            ) {
              data = {
                position: "center",
                left: aD.left + aD.width / 2 - cD.width / 2,
                top:
                  preferdDirection === "bottom"
                    ? aD.top + aD.height + maxOffest
                    : aD.top - cD.height - maxOffest,
              };
            } else if (windowDimensions.width - (aD.left + cD.width) >= 0) {
              data = {
                position: "left",
                left: aD.left,
                top:
                  preferdDirection === "bottom"
                    ? aD.top + aD.height + maxOffest
                    : aD.top - cD.height - maxOffest,
              };
            } else if (aD.left + aD.width - cD.width >= 0) {
              data = {
                position: "right",
                left: aD.left + aD.width,
                top:
                  preferdDirection === "bottom"
                    ? aD.top + aD.height + maxOffest
                    : aD.top - cD.height - maxOffest,
              };
            }
          }
          break;

        case "left":
          {
            data = {
              position: "center",
              left: aD.left - cD.width - maxOffest,
              top: aD.top + aD.height / 2 - cD.height / 2,
            };
          }
          break;

        case "right":
          {
            data = {
              position: "center",
              left: aD.left + aD.width + maxOffest,
              top: aD.top + aD.height / 2 - cD.height / 2,
            };
          }
          break;

        default:
          break;
      }

      return { ...data, direction: preferdDirection };
    },
    [windowDimensions]
  );

  const getPreferredDirections = useCallback(
    (contentDimensions) => {
      const directions = [];

      const aD = anchorRef.current; // ac = anchor dimensions
      const cD = contentDimensions; // cd = content dimensions

      if (windowDimensions.height - (aD.top + aD.height + cD.height) >= 0) {
        directions.push("bottom");
      }

      if (aD.top - cD.height >= 0) {
        directions.push("top");
      }

      if (
        aD.left - cD.width >= 0 &&
        windowDimensions.height - (aD.top - cD.height / 2 + cD.height) >= 0
      ) {
        directions.push("left");
      }

      if (
        windowDimensions.width - (aD.left + aD.width + cD.width) >= 0 &&
        windowDimensions.height - (aD.top - cD.height / 2 + cD.height) >= 0
      ) {
        directions.push("right");
      }

      return directions;
    },
    [windowDimensions]
  );

  const setDimensionsOfAnchor = useCallback((elem) => {
    const dimensions = elem.getBoundingClientRect();
    anchorRef.current = dimensions;
  }, []);

  const setContentPosition = useCallback(() => {
    if (!contentRef.current) {
      return;
    }

    const dimensions = contentRef.current.getBoundingClientRect();
    const preferredDirections = getPreferredDirections(dimensions);

    if (
      preferredDirections.length === 0 ||
      preferredDirections.includes(userPreferedDirection)
    ) {
      // if the user given direction is  feasible
      const data = getPreferredPositionData(userPreferedDirection, dimensions);
      updatePopPosition({ ...data });
    } else {
      // if the user given direction is not feasible

      const oppositeDirection = getOppositeDirection(userPreferedDirection);
      if (preferredDirections.includes(oppositeDirection)) {
        const data = getPreferredPositionData(oppositeDirection, dimensions);
        updatePopPosition({ ...data });
      } else {
        const data = getPreferredPositionData(
          preferredDirections[0],
          dimensions
        );
        updatePopPosition({ ...data });
      }
    }
  }, [userPreferedDirection, getPreferredDirections]);

  const closePopover = useCallback(
    (e) => {
      if (typeof onClose === "function") {
        onClose(e);
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (!active) {
      return;
    }
    setDimensionsOfAnchor(anchorElem);
    setContentPosition();
  }, [anchorElem, active, setContentPosition]);

  const setContentRef = useCallback((ref) => {
    if (!ref) {
      return;
    }
    contentRef.current = ref;
    setContentPosition();
  }, []);

  const triangleClass = useMemo(() => {
    let styleClass = "";
    switch (popPosition.direction) {
      case "bottom":
        {
          styleClass += styles.top_triangle;

          switch (popPosition.position) {
            case "left":
              styleClass += " " + styles.top_left;
              break;
            case "right":
              styleClass += " " + styles.top_right;
              break;
            case "center":
              styleClass += " " + styles.top_center;
              break;
            default:
              break;
          }
        }
        break;

      case "top":
        {
          styleClass += styles.bottom_triangle;

          switch (popPosition.position) {
            case "left":
              styleClass += " " + styles.bottom_left;
              break;
            case "right":
              styleClass += " " + styles.bottom_right;
              break;
            case "center":
              styleClass += " " + styles.bottom_center;
              break;
            default:
              break;
          }
        }
        break;

      case "left":
        {
          styleClass += styles.right_triangle + " " + styles.left_right;
        }
        break;

      case "right":
        {
          styleClass += styles.left_triangle + " " + styles.left_right;
        }
        break;

      default:
        break;
    }

    return styleClass;
  }, [popPosition.position, popPosition.direction]);

  const [forcedWidth, forcedHeight] = useMemo(() => {
    if (!contentRef.current) {
      return [null, null];
    }
    const cD = contentRef.current.getBoundingClientRect();

    const data = [];

    if (windowDimensions.width - (popPosition.left + cD.width) < 0) {
      data.push(
        cD.width -
          maxLeftAndRightDistanceFromViewport -
          Math.abs(windowDimensions.width - (popPosition.left + cD.width))
      );
    } else {
      data.push(null);
    }

    if (windowDimensions.height - (popPosition.top + cD.height) < 0) {
      data.push(
        cD.height -
          maxToAndBottomDistanceFromViewport -
          Math.abs(windowDimensions.height - (popPosition.top + cD.height))
      );
    } else {
      data.push(null);
    }

    return data;
  }, [windowDimensions, popPosition]);

  return active ? (
    <InsertInDom domId="overlay">
      <MainConatiner>
        <Backdrop role="button" onClick={closePopover} />

        <PopoverContainer
          style={{
            top: Math.max(popPosition.top, 0),
            left: Math.max(popPosition.left, 0),
            maxWidth: forcedWidth || windowDimensions.width,
            maxHeight: forcedHeight || windowDimensions.height,
            opacity: popPosition.top > Number.MIN_SAFE_INTEGER ? 1 : 0,
          }}
          ref={setContentRef}
        >
          <div
            style={{
              overflow: "auto",
              maxWidth: forcedWidth || null,
              maxHeight: forcedHeight || null,
            }}
          >
            {children}
          </div>
          <Triangle className={`${styles.triangle} ${triangleClass}`} />
        </PopoverContainer>
      </MainConatiner>
    </InsertInDom>
  ) : null;
};

export default Popover;
