import React from "react";

import { Container } from "./style";

const Counter = ({ count, className, ...other }) => {
  return (
    <Container {...other} className={className}>
      {count}
    </Container>
  );
};

Counter.defaultProps = {
  className: "",
};

export default Counter;
