export const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        if (callback && typeof callback == "function") callback();
      }
    };
  } else {
    script.onload = () => {
      if (callback && typeof callback == "function") callback();
    };
  }

  script.src = url;
  document.head.appendChild(script);
};
