import React, { useState, useCallback, useMemo } from "react";
import Icon from "components/Icon";
import Button from "components/Button";
import { RectangleSkeleton } from "components/Skeleton";
import InfoView from "components/InfoView";

import MemberCreate from "../../Create/Member";
import ActionArea from "./ActionArea";
import TableData from "./TableData";

import EmptyScreen from "assets/employee/EmptyScreen.png";

import { ViewContainer } from "globalStyles";
import {
  AdvancedActionArea,
  EmptyScreenContainer,
  EmptyScreenImage,
  HeaderRefreshContent,
  Text,
} from "../../../style/display";

import { MainContainer } from "../../../style/create";
import { bindActionCreators } from "redux";
import { resetMembersData } from "../../../actionCreator";
import { isCurrentUserIsExecutive } from "common/Selectors/auth";
import { connect, useSelector } from "react-redux";
import { getOncefetched } from "common/Selectors/store";

const onceFetchedSelector = getOncefetched("teams.members");

const TeamMembersDisplayView = ({ resetMembersData }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const onceFetched = useSelector(onceFetchedSelector);
  const dataIds = useSelector((store) => store.teams.members.dataIds);

  const isEmpty = useMemo(
    () => !(onceFetched && dataIds?.length > 0),
    [dataIds, onceFetched]
  );

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(true);
  }, []);

  const renderEmptyScreen = useCallback(() => {
    if (onceFetched && isEmpty) {
      if (!isAdmin) {
        return (
          <EmptyScreenContainer>
            <EmptyScreenImage src={EmptyScreen} alt="No records found" />
          </EmptyScreenContainer>
        );
      }
      return <InfoView onCta={updateFormVisibilty} ctaText="Add Members" />;
    }
    return null;
  }, [onceFetched, isAdmin, isEmpty, updateFormVisibilty]);

  if (isFormVisible) {
    return (
      <MainContainer>
        <MemberCreate
          setIsFormVisible={setIsFormVisible}
          updateIsFormSubmitted={setIsFormVisible}
        />
      </MainContainer>
    );
  }

  return (
    <ViewContainer>
      <HeaderRefreshContent onClick={resetMembersData}>
        <Icon name="fas fa-sync" />
        <Text>Refresh</Text>
      </HeaderRefreshContent>
      <AdvancedActionArea
        style={{
          display: !onceFetched || isEmpty ? "none" : "flex",
        }}
      >
        <ActionArea />
        {isAdmin && (
          <Button primary onClick={updateFormVisibilty}>
            Create New
          </Button>
        )}
      </AdvancedActionArea>
      <TableData
        style={{
          display: !onceFetched || isEmpty ? "none" : "flex",
        }}
      />

      {renderEmptyScreen()}
      {!onceFetched && (
        <EmptyScreenContainer>
          <RectangleSkeleton times={4} />
        </EmptyScreenContainer>
      )}
    </ViewContainer>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetMembersData,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(TeamMembersDisplayView);
