import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IS_DEV } from "./config.local";

import "./index.css";
import "./css_overrides/css_overrides.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

navigator.serviceWorker.addEventListener("message", function (event) {
  if (IS_DEV) {
    console.log("Received a message from service worker: ", event.data);
  }
});
