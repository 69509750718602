import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import TabsRenderer from "./TabsRenderer";
import DrawerLink from "../DrawerLink";
import { generateDrawerLinks } from "helper";

import Not_Img from "assets/Document.svg";
import E_Not_Img from "assets/employee/Document.svg";

const [HolidayYearTabLinks, HolidayYearLinkComponents] = generateDrawerLinks(
  Routes.authorized.holidayYear,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

export const HolidayYearTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType(
        "holidayYear_section"
      )}
    >
      {/* <DrawerTitle title="Holiday Section" /> */}
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("holidayYear")}
        image={isAdmin ? Not_Img : E_Not_Img}
        title="Holiday Year"
        links={HolidayYearTabLinks}
      >
        {HolidayYearLinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
