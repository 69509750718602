import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import Icon from "components/Icon";
import useForm from "hooks/useForm";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive
} from "common/Selectors/auth";
import { StyledForm } from "../../style/create";
import { transactionTypeOptions } from "./options";
import useCreateLedgerTransactions from "./useCreateLedgerTransactions";
import { getUserListData } from "common/Api";
import { CreditFields } from "./CreditFields";
import { DebitFields } from "./DebitFields";
import useCreditForm from "./useCreditForm";
import useDebitForm from "./useDebitForm";
import { generateFormData } from "./util";

const Form = ({ updateFormVisibility, isAdmin }) => {
  const { formData, values, resetForm, isFormValid, updateField } = useForm({
    fields: {
      transaction_type: {
        required: isAdmin
      },
      collaborator: {
        required: true
      }
    }
  });

  const {
    formData: creditFormData,
    values: creditValues,
    resetForm: creditResetForm,
    isFormValid: isCreditFormValid
  } = useCreditForm();

  const {
    formData: debitFormData,
    values: debitValues,
    resetForm: debitResetForm,
    isFormValid: isDebitFormValid
  } = useDebitForm();

  const isCreditType = useMemo(
    () => isAdmin && values?.transaction_type == "credit",
    [values, isAdmin]
  );

  const { isLoading, createTransactions } = useCreateLedgerTransactions();

  const resetAllForms = useCallback(() => {
    resetForm();
    creditResetForm();
    debitResetForm();
    updateField("transaction_type", "credit");
  }, [resetForm, creditResetForm, debitResetForm, updateField]);

  const onFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (isAdmin && !isFormValid() && isCreditType && !isCreditFormValid()) {
        return;
      } else if (
        isAdmin &&
        !isFormValid() &&
        !isCreditType &&
        !isDebitFormValid()
      ) {
        return;
      } else if (!isAdmin && !isDebitFormValid()) {
        return;
      }
      const formData = generateFormData({
        isAdmin,
        isCreditType,
        values,
        creditValues,
        debitValues
      });
      createTransactions(formData, resetAllForms);
    },
    [
      isAdmin,
      isCreditType,
      isFormValid,
      isCreditFormValid,
      isDebitFormValid,
      values,
      creditValues,
      debitValues,
      resetAllForms
    ]
  );

  useEffect(() => {
    updateField("transaction_type", "credit");
  }, []);

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <Icon name="fas fa-times" onClick={() => updateFormVisibility(false)} />
      {isAdmin && (
        <>
          <InputGroup
            fieldType="radioGroup"
            options={transactionTypeOptions}
            label="Transaction type"
            {...formData["transaction_type"]}
          />
          <InputGroup
            label="Assign To"
            fieldType="asyncSearchSelect"
            placeholder="Search for users"
            api={getUserListData}
            singleSelect
            {...formData["collaborator"]}
          />
        </>
      )}

      {isAdmin && isCreditType ? (
        <CreditFields formData={creditFormData} />
      ) : (
        <DebitFields formData={debitFormData} />
      )}

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create
      </Button>
    </StyledForm>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    isAdmin
  };
};

export default connect(mapStateToProps, null)(Form);
