import React, { useCallback, useMemo } from "react";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import { getStatusType } from "helper";

import { Cell, CellIcon } from "../../../../style/display";

const RenderItem = ({ data, columnIndex, rowIndex, style }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
    onIdClick
  } = data;

  const cellvalue = rowData[rowIndex][columnIndex];

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["status"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const isFirstColumn = useMemo(() => {
    return columnIndex === 0;
  }, [columnIndex]);

  const onCellClick = useCallback(() => {
    if (typeof onIdClick === "function") {
      onIdClick({ rowIndex });
    }
  }, [rowIndex]);

  const myChildren = useMemo(() => {
    if (isFirstColumn) {
      return (
        <Cell style={{ cursor: "pointer" }}>
          {rowData[rowIndex][0]}
          <CellIcon name="fas fa-arrow-right" />
        </Cell>
      );
    }
    if (isStatusColumn) {
      return (
        <Cell>
          <Status text={cellvalue} type={getStatusType(cellvalue)} />
        </Cell>
      );
    }
    return null;
  }, [isStatusColumn, cellvalue]);

  return (
    <GridCell
      row={rowIndex}
      showHeading={showHeading}
      key={`${rowIndex}_${columnIndex}`}
      onClick={onCellClick}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

export default RenderItem;
