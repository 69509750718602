import { FETCH_TODAYS_WORKING_HOUR_LOG } from "../ActionTypes";
import { createAsyncActionCreator } from "../../Helper";
import { getTodaysWorkingLogApi } from "../Api";

const todaysWorkingHourLogGenerator = function* ({ companyId, isAdmin }) {
  yield {
    action: FETCH_TODAYS_WORKING_HOUR_LOG,
    sideEffect: getTodaysWorkingLogApi({ companyId, isAdmin }),
  };
};

export const getTodaysWorkingHourLog = createAsyncActionCreator(
  todaysWorkingHourLogGenerator
);
