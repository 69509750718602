import React, { useEffect } from "react";
import { connect } from "react-redux";

import { PaginatorBox } from "../../../../GlobalStyles";
import { EnhancedActionArea } from "../../style/display";
import Paginator from "components/Paginator";
import { useTeamViewPagination } from "../../Hooks/useTeamViewPagination";

const ActionArea = ({ onceFetched, onLoading, style }) => {
  const {
    currentPageNumber,
    totalPages,
    fetchNextPage,
    fetchPrevPage,
    fetchSpecificPage,
    loadingNextPage,
    loadingPrevPage,
  } = useTeamViewPagination();

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <EnhancedActionArea style={style}>
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  return {
    onceFetched: store.teams.owners.onceFetched,
  };
};

export default connect(mapStateToProps, null)(ActionArea);
