import styled from "styled-components";
import { StyledActionArea } from "globalStyles";
import EditContainer from "components/EditContainer";
import EditDetails from "components/EditDetails";
import { ImageComponent } from "../../../Common/Components/Image";

export const EnhancedActionArea = styled(StyledActionArea)`
  flex-flow: row wrap;
  align-items: flex-end;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;

export const SearchArea = styled.div`
  flex-basis: 100%;
  align-self: center;
  margin: 0 10px 0 0;

  @media (min-width: 1024px) {
    flex-basis: 90%;
  }

  & > form > button {
    margin-top: 5px;
    flex-basis: 75%;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  gap: 20px;

  & {
    margin: 0 0 8px 0;
  }

  & div {
    height: 35px;
  }

  & div > select {
    height: 35px;
    padding: 0 10px;
  }

  & button {
    height: 35px;
    padding: 7px;
  }

  @media (min-width: 768px) {
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;

    & div, button {
      max-width: 200px;
    }

    & span {
      max-width: 410px;
      margin-right: 10px;
    }
  }

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

export const AsyncSearchField = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  
  & > div > div > div {
    height: 100px;
  }

  @media (min-width: 768px) {
    min-width: 420px;

    & div { 
      max-width: 420px;
    }
  }
`;

export const DateInputButton = styled.div`
  width: 100%;
  border: 1px ${(props) => props.theme.colors.input.primary.borderColor} solid;
  border-radius: 5px;
  margin-bottom: 3px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  color: ${(props) => props.theme.colors.input.primary.textColor};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;

export const RangePickerContainer = styled.span`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 20px;

  @media (min-width: 768px) {
    width: 410px;

    & div, button {
      width: 200px;
      gap: 0 10px;
    }
  }
`;

export const CreateButton = styled.div`
  align-self: flex-end;
  padding-right: 10px;

  & button {
    height: 35px;
    padding: 0 20px;
  }
`;

export const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-flow: column;
  position: relative;
`;

export const StyledEditContainer = styled(EditContainer)`
  padding: 20px;
  max-width: 800px;
  padding-top: 40px;
`;

export const StyledEditDetails = styled(EditDetails)`
  position: absolute;
  right: 10px;
  top: 10px;
  width: auto;
  z-index: 1000;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    height: 35px;
  }
`;

export const EmptyScreenImage = styled(ImageComponent)`
  width: 40%;
  height: 40%;
  object-fit: contain;
`;

export const EmptyScreenMessage = styled.span`
  margin: 30px;
  font-size: ${(props) => props.theme.text.title.medium};
`;
