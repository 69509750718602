import React, { useMemo } from "react";

import Left_icon from "assets/leftArrow.svg";
import {
  HeaderContainer,
  Content,
  DescriptionContainer,
  Description,
  Heading,
  BackButton,
} from "./style";
import { useHistory } from "react-router";

function Details({ title, value }) {
  return (
    <Description>
      <span>{title}:</span>
      <b>{value}</b>
    </Description>
  );
}

function Header({ title, details, onBack, showBackButton = true }) {
  const detailsList = useMemo(() => {
    return details?.map((item, idx) => {
      return (
        <Details
          key={`${item.name}_${idx}`}
          title={item.name}
          value={item.value}
        />
      );
    });
  }, [details]);
  const { goBack } = useHistory();

  return (
    <HeaderContainer>
      {!!showBackButton && (
        <BackButton onClick={onBack || goBack}>
          <img src={Left_icon} alt="left arrow" />
        </BackButton>
      )}
      <Content>
        <Heading> {title}</Heading>
        <DescriptionContainer>{detailsList}</DescriptionContainer>
      </Content>
    </HeaderContainer>
  );
}

export default Header;
