import React, { useCallback, useMemo } from "react";

import EventCreate from "../../../../Modules/Calendar/Views/EventCreate";

import WeekDay from "./WeekDay";
import TimeArea from "../TimeArea";

import useElementDimensions from "hooks/useElementDimensions";
import EventView from "modules/Calendar/Views/EventView";
import { useTaskView, useTaskCreate } from "../hooks";

import { WeekColumnContainer } from "./style";

const Head_Height = 80;

const WeekColumns = ({
  weekDaysList,
  tasks,
  timesRowCount,
  timesRowHeight,
}) => {
  const {
    activateForm,
    clearNewTask,
    createModalData,
    updateNewTaskPos,
    newTaskPos,
  } = useTaskCreate();

  const { viewFormData, toggleViewModal, onTaskView } = useTaskView();

  const onColumnClicked = useCallback(
    (e, id) => {
      const top = e.nativeEvent.offsetY + Head_Height;
      const date = new Date(id);
      date.setHours(Math.floor((top - Head_Height) / timesRowHeight), 0, 0);
      const style = { top: `${top}px` };
      updateNewTaskPos(() => ({ [id]: { style, date } }));
      activateForm("calendar_new_task");
    },
    [Head_Height, timesRowHeight]
  );

  const weekDays = useMemo(() => {
    return weekDaysList.map((item) => {
      const style = { height: timesRowCount * timesRowHeight + Head_Height };
      const id = item.dateObj.toString();
      return (
        <WeekDay
          style={style}
          key={id}
          tasks={tasks}
          dateObj={item.dateObj}
          day={item.day}
          date={item.date}
          id={id}
          isTodayDate={item.isTodayDate}
          cellHeight={timesRowHeight}
          totalTimeRows={timesRowCount}
          headingHeight={Head_Height}
          onClick={onColumnClicked}
          onTaskView={onTaskView}
          newTaskPos={newTaskPos && newTaskPos[id] && newTaskPos[id].style}
        />
      );
    });
  }, [weekDaysList, tasks, timesRowHeight, timesRowCount, newTaskPos]);

  const { setRef, elemnentDimension, elemRef } = useElementDimensions({
    updateWith: weekDays,
  });

  const clickedDate = useMemo(() => {
    return (newTaskPos && Object.values(newTaskPos)[0].date) || new Date();
  }, [newTaskPos]);

  const timeWitdh = Math.max(
    elemnentDimension.width,
    elemRef.current?.scrollWidth ?? 0
  );

  return (
    <WeekColumnContainer
      style={{ maxHeight: elemnentDimension.height }}
      ref={setRef}
    >
      <TimeArea
        width={timeWitdh}
        topDistance={Head_Height}
        rowHeight={timesRowHeight}
      />

      {weekDays}

      {createModalData.active && (
        <EventCreate
          initialDate={clickedDate}
          elemIdOrElem={createModalData.elem}
          onClose={clearNewTask}
        />
      )}

      {viewFormData.active && (
        <EventView
          data={viewFormData.data}
          elemIdOrElem={viewFormData.elem}
          onClose={toggleViewModal}
        />
      )}
    </WeekColumnContainer>
  );
};

export default WeekColumns;
