import { useState, useCallback } from "react";

export function useTaskView() {
  const [viewFormData, updateViewFormData] = useState({
    active: false,
    data: {},
    elem: null,
  });

  const toggleViewModal = useCallback((elem, data) => {
    updateViewFormData((val) => ({
      active: !val.active,
      data,
      elem,
    }));
  }, []);

  const onTaskView = (elem, taskData) => {
    toggleViewModal(elem, taskData);
  };

  return { viewFormData, toggleViewModal, onTaskView };
}
