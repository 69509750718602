import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import useGetPostDetail from "../useGetPostDetail";

import PostCard, { PostCardSkeleton } from "components/Posts";
import useRouteParams from "hooks/useRouteParams";

import { usePostFunctionality } from "modules/WelcomePage/hooks/Posts/usePostFunctionality";
import CommentsModal from "modules/WelcomePage/Views/Employee2/CommentsModal";

import { Container, PostContainer } from "../style";
import { getCurrentUserId } from "common/Selectors/auth";

import InfoView from "components/InfoView";
import ErrorIllustration from "assets/employee/AssetsBlank.png";

/**
 * Needs  refractoring after the api is setup, currently its just a boilerplate
 */

function PostView() {
  const params = useRouteParams(["post"]);
  const [isLoading, postData] = useGetPostDetail(params.post);
  const {
    postActionBtnProps,
    createPollData,
    createQuizData,
    getCreateReactionProps,
    onComment,
    onCloseMoreComments,
    onLoadMoreComments,
    isCreatingComment,
    commentPostId,
    commentsModal,
  } = usePostFunctionality();

  const currentCollaboratorId = useSelector(getCurrentUserId);

  const [quizData, pollData] = useMemo(() => {
    return [createQuizData(postData), createPollData(postData)];
  }, [postData]);

  return (
    <Container>
      <PostContainer>
        {!!Object.keys(postData).length ? (
          <PostCard
            onLoadMoreComments={onLoadMoreComments}
            onComment={onComment}
            isCreatingComment={
              isCreatingComment && commentPostId == postData?.id
            }
            getCreateReactionProps={getCreateReactionProps}
            {...postData}
            quizData={quizData}
            pollData={pollData}
            postActionBtnProps={
              currentCollaboratorId == postData.collaboratorId &&
              postActionBtnProps
            }
          />
        ) : !isLoading ? (
          <InfoView
            image={ErrorIllustration}
            heading="You don't have access to the post"
            desc="Make sure the post exists or you have permission to view the post"
          />
        ) : (
          <PostCardSkeleton />
        )}
      </PostContainer>
      {commentsModal.active && (
        <CommentsModal
          onClose={onCloseMoreComments}
          postId={commentsModal.postId}
        />
      )}
    </Container>
  );
}
export default PostView;
