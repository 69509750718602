import styled from "styled-components";

export const Container = styled.div`
  flex-grow: 1;
  border-radius: 15px;
  background-color: #edfde2;
  display: flex;
  max-height: 250px;
  padding: 18px;
  justify-content: space-between;
  @media (min-width: 768px) {
    padding: 48px;
  }
`;

export const Name = styled.span`
  color: #1c1c1c;
  font-size: ${(props) => props.theme.text.para.large};
  font-weight: 600;
  max-width: 207px;
  text-align: left;

  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.text.heading.small};
  }
`;

export const ImageBox = styled.div`
  width: 244.7px;
  height: 159.3px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
