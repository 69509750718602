import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const createReimbursementApi = ({ companyId, isAdmin, formData }) => {
  let reimbursement = {
    expense_type: formData.expense_type,
    from_date: formData.from_date,
    to_date: formData.to_date,
    description: formData.description,
    amount: formData.amount,
    invoices: formData.invoices,
  }

  if (formData.expense_type === "travel") {
    reimbursement["expense_info_fields"] = {
      from: formData.from,
      to: formData.to,
    }
  }

  if (isAdmin) {
    reimbursement["collaborator_id"] = formData.collaborator_id
  }

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/reimbursements`
  });

  return requestData(url, {
    method: "POST",
    body: {
      reimbursement
    },
    isFormData: true,
  });
};

export const getReimbursementsApi = ({ companyId, pageNumber, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/reimbursements?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const searchReimbursementApi = ({ companyId, pageNumber, collaboratorId, expenseType, currentFromDate, currentToDate, currentStatus, isAdmin }) => {
  let collaborator_id, expense_type, from_date, to_date, status;
  collaborator_id = expense_type = from_date = to_date = status = "";

  if (collaboratorId)
    collaborator_id = `&collaborator_id=${collaboratorId}`;
  if (expenseType)
    expense_type = `&expense_type=${expenseType}`;
  if (currentFromDate)
    from_date = `&from_date=${currentFromDate}`;
  if (currentToDate)
    to_date = `&to_date=${currentToDate}`;
  if (currentStatus)
    status = `&status=${currentStatus}`;

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/search_reimbursement?page=${pageNumber}${collaborator_id}${expense_type}${from_date}${to_date}${status}`,
  });
  return requestData(url, {
    method: "GET",
  }
  );
};
