import styled from "styled-components";
import EmptyData from "../EmptyData";
import { commonScrollbarStyle } from "globalStyles";

export const Container = styled.div`
  position: fixed;
  width: 363px;
  max-height: 600px;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0 5px 15px -1px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  z-index: 2;
  .list-item :first-child {
    border-top: none;
  }
  ${commonScrollbarStyle}
`;

export const Triangle = styled.div`
  position: fixed;
  content: "";
  background-color: #ffffff;
  transform: rotate(45deg);
  z-index: 1;
  ${(props) => props.$pos};
`;

export const StyledEmptyData = styled(EmptyData)`
  & > img {
    width: 100px;
    height: 100px;
  }
  & > span {
    font-size: ${(props) => props.theme.text.para.small};
  }
  margin: 80px 0px;
`;
