import React from "react";

export const ClockIcon = ({ color, ...otherProps }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.59375 8.5C1.59375 6.66835 2.32137 4.91171 3.61655 3.61655C4.91171 2.32137 6.66835 1.59375 8.5 1.59375C10.3317 1.59375 12.0883 2.32137 13.3835 3.61655C14.6786 4.91171 15.4062 6.66835 15.4062 8.5C15.4062 10.3317 14.6786 12.0883 13.3835 13.3835C12.0883 14.6786 10.3317 15.4062 8.5 15.4062C6.66835 15.4062 4.91171 14.6786 3.61655 13.3835C2.32137 12.0883 1.59375 10.3317 1.59375 8.5ZM8.5 0C6.24566 0 4.08365 0.895533 2.4896 2.4896C0.895533 4.08365 0 6.24566 0 8.5C0 10.7543 0.895533 12.9164 2.4896 14.5105C4.08365 16.1044 6.24566 17 8.5 17C10.7543 17 12.9164 16.1044 14.5105 14.5105C16.1044 12.9164 17 10.7543 17 8.5C17 6.24566 16.1044 4.08365 14.5105 2.4896C12.9164 0.895533 10.7543 0 8.5 0ZM9.03125 5.04688C9.03125 4.83553 8.94729 4.63284 8.79785 4.4834C8.64841 4.33396 8.44572 4.25 8.23438 4.25C8.02303 4.25 7.82034 4.33396 7.6709 4.4834C7.52146 4.63284 7.4375 4.83553 7.4375 5.04688V8.76562C7.43753 8.92477 7.48523 9.08025 7.57441 9.21206C7.66361 9.34385 7.79022 9.44592 7.93794 9.50513L10.5942 10.5676C10.7887 10.6387 11.0034 10.6312 11.1925 10.5467C11.3816 10.4623 11.5305 10.3075 11.6074 10.1151C11.6843 9.92284 11.6834 9.70812 11.6047 9.51648C11.5261 9.32486 11.3759 9.1714 11.186 9.08862L9.03125 8.22587V5.04688Z"
        fill={color}
      />
    </svg>
  );
};
