import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import useAlert from "hooks/useAlert";

import { createPersonalInfo, updatePersonalInfo } from "../../../actionCreator";

import { formTags } from "../options";
import { generateFormData } from "../../../utils";
import { checkObjectEquality } from "../helper/formDataEqualityCheck";

function usePersonalInfoDetails() {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const savePersonalInfoData = async (
    values,
    currentStep,
    presentData,
    nextStep,
    stepsCompleted,
    updateStep,
    setIsFormVisible,
    setNextStep
  ) => {
    try {
      updateLoading(true);
      const data = generateFormData({
        values,
        currentStep: formTags[Object.keys(formTags)[currentStep]],
      });
      if (presentData) {
        if (!checkObjectEquality(data, presentData)) {
          await dispatch(
            updatePersonalInfo({ companyId, isAdmin, formData: data })
          );
        }
      } else {
        await dispatch(
          createPersonalInfo({ companyId, isAdmin, formData: data })
        );
      }
      showAlert({ message: `Personl Information Detail Saved` });
      if (
        (!checkObjectEquality(data, presentData) &&
          currentStep == Object.values(formTags).indexOf(stepsCompleted)) ||
        nextStep
      ) {
        updateStep((val) => Math.max(0, val + 1));
      }
      if (currentStep == Object.keys(formTags).length - 1) {
        setIsFormVisible(false);
      }
    } catch (err) {
      showAlert({
        message: "Personal Information Create Failed",
        type: "error",
      });
    } finally {
      updateLoading(false);
      setNextStep(false);
    }
  };
  return { isLoading, savePersonalInfoData };
}

export default usePersonalInfoDetails;
