const checkIfDataIsNA = (data) => {
  if (!data || data === "NA") return null;
  return data;
};

export const tranformToAttendanceList = (data, cellNameToColumnMap) => {
  const idColumn = cellNameToColumnMap["Sl No"];
  const empIdColumn = cellNameToColumnMap["employeeId"];
  const nameColumn = cellNameToColumnMap["name"];
  const phoneColumn = cellNameToColumnMap["phone"];
  const emailColumn = cellNameToColumnMap["email"];
  const statusColumn = cellNameToColumnMap["status"];
  const roleColumn = cellNameToColumnMap["role"];
  const avatarUrlColumn = cellNameToColumnMap["avatarUrl"];
  const joinedOnColumn = cellNameToColumnMap["joinedOn"];
  const workLogColumn = cellNameToColumnMap["todaysWorkingHourLog"];

  const res = [];
  data.forEach((row, index) => {
    if (index === 0) {
      return;
    }
    const [
      id,
      employeeId,
      name,
      phone,
      email,
      status,
      role,
      avatarUrl,
      joinedOn,
      workLog,
    ] = [
      row[idColumn],
      row[empIdColumn],
      row[nameColumn],
      row[phoneColumn],
      row[emailColumn],
      row[statusColumn],
      row[roleColumn],
      row[avatarUrlColumn],
      row[joinedOnColumn],
      row[workLogColumn],
    ];

    if (id && name && employeeId) {
      res.push({
        id,
        employeeId: checkIfDataIsNA(employeeId),
        name,
        phone: checkIfDataIsNA(phone),
        email: checkIfDataIsNA(email),
        status: checkIfDataIsNA(status),
        role: checkIfDataIsNA(role),
        avatarUrl: checkIfDataIsNA(avatarUrl),
        joinedOn: checkIfDataIsNA(joinedOn),
        workLog: checkIfDataIsNA(workLog),
      });
    }
  });
  return res;
};

export const getCurrentIndexRowDataAndColumn = (rows, rowIndex) => {
  return {
    data: rows[rowIndex],
    columns: rows[0],
  };
};
