import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import LeaveManagementView from "modules/LeaveManagement/Views";
import LeaveDetailsView from "modules/LeaveManagement/Views/leaveDetailsView";

import { createRouteObject } from "../../../Helper";

const { leaveManagement } = RoutePaths.authorized;

const pages = createRouteObject(
  leaveManagement,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [LeaveManagementView, LeaveDetailsView]
);

const LeaveManagementPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        path={item.slugPath || item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default LeaveManagementPages;
