import React, { useCallback, useEffect, useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import Button from "components/Button";
import { RectangleSkeleton } from "components/Skeleton";

import { isCurrentUserIsExecutive } from "../../../../Common/Selectors/auth";

import HelpdeskCreateView from "../Create";
import ActionArea from "./ActionArea";
import TableData from "./TableData";

import EmptyScreen from "assets/employee/HelpdeskBlank.png";

import { CreateButton } from "../../style/create";
import { ViewContainer } from "../../../../GlobalStyles";
import { EmptyScreenContainer, EmptyScreenImage, EmptyScreenMessage } from "../../style/display";
import { updateCollaboratorId } from "../../../FollowSubordinate/actionCreator";

const HelpdeskDisplayView = ({ subordinateHelpdesk = false, updateCollaboratorId }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const isExecutive = useSelector(isCurrentUserIsExecutive);
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(true);
  }, []);

  useEffect(async () => {
    if (!subordinateHelpdesk) {
      await updateCollaboratorId(null)
    }
  }, [])

  const createAnEnquiry = useMemo(() => {
    if (!isExecutive && !isLoading && !isEmpty && !subordinateHelpdesk)
      return (
        <CreateButton>
          <Button primary onClick={updateFormVisibilty}>
            Create New
          </Button>
        </CreateButton>
      )
    return null;
  }, [isExecutive, isLoading, isEmpty])

  if (isFormVisible) {
    return <HelpdeskCreateView setIsFormVisible={setIsFormVisible} />
  }
  return (
    <ViewContainer>
      {createAnEnquiry}
      <ActionArea
        onLoading={onDbDataChange}
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
      />
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        onEmptyData={onEmptyData}
      />
      {!isLoading && isEmpty && (
        <EmptyScreenContainer>
          <EmptyScreenImage src={EmptyScreen} alt="No records found" />
          <EmptyScreenMessage>
            {isExecutive ?
              "There are no queries"
              : "You have not added any query"}
          </EmptyScreenMessage>
          {!isExecutive && !subordinateHelpdesk &&
            <Button primary size="lg" onClick={updateFormVisibilty}>
              Create New
            </Button>
          }
        </EmptyScreenContainer>
      )}
      {isLoading && <RectangleSkeleton times={4} />}
    </ViewContainer>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCollaboratorId,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(HelpdeskDisplayView);
