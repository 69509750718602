import {
  ASSIGN_LEAVES,
  DELETE_ASSIGNED_LEAVE,
  EDIT_ASSIGNED_LEAVE,
  GET_ALL_ACTIVE_LEAVE_TYPES,
  GET_ALL_ASSIGNED_LEAVES,
} from "./actiontypes";
import { createAsyncActionCreator } from "helper";
import {
  createLeaveApi,
  deleteLeaveApi,
  editLeaveApi,
  getActiveLeaveTypesApi,
  getAssignedLeavesApi,
} from "./api";

const createLeaveGenerator = function* ({ companyId, formData, isAdmin }) {
  yield {
    action: ASSIGN_LEAVES,
    sideEffect: createLeaveApi({ companyId, formData, isAdmin }),
  };
};

const getAssignedLeavesGenerator = function* ({
  pageNumber,
  companyId,
  isAdmin,
}) {
  yield {
    action: GET_ALL_ASSIGNED_LEAVES,
    sideEffect: getAssignedLeavesApi({ pageNumber, companyId, isAdmin }),
  };
};

const getActiveLeaveTypesGenerator = function* ({
  pageNumber,
  companyId,
  isAdmin,
}) {
  yield {
    action: GET_ALL_ACTIVE_LEAVE_TYPES,
    sideEffect: getActiveLeaveTypesApi({ pageNumber, companyId, isAdmin }),
  };
};

const editLeaveGenerator = function* ({
  companyId,
  assignedLeaveId,
  formData,
  isAdmin,
}) {
  yield {
    action: EDIT_ASSIGNED_LEAVE,
    sideEffect: editLeaveApi({
      companyId,
      assignedLeaveId,
      formData,
      isAdmin,
    }),
  };
};

const deleteLeaveGenerator = function* ({
  assignedLeaveId,
  companyId,
  isAdmin,
}) {
  yield {
    action: DELETE_ASSIGNED_LEAVE,
    sideEffect: deleteLeaveApi({ assignedLeaveId, companyId, isAdmin }),
  };
};

export const [
  createLeave,
  getAssignedLeaves,
  getActiveLeaveTypes,
  editLeave,
  deleteLeave,
] = createAsyncActionCreator([
  createLeaveGenerator,
  getAssignedLeavesGenerator,
  getActiveLeaveTypesGenerator,
  editLeaveGenerator,
  deleteLeaveGenerator,
]);
