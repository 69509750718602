import requestData from "../../../../../ApiHandler";
import { createAuthUrl } from "helper";
import { ImportsNotUsedAsValues } from "typescript";

export const createWorkshiftTypeApi = ({ companyId, formData, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/work_hour_shifts`,
  });

  return requestData(authUrl, {
    method: "POST",
    body: {
      work_hour_shift: {
        name: formData?.name,
        start_time: `${formData?.startTimeHour}:${formData?.startTimeMin} ${formData?.startTimeMeridiem}`,
        end_time: `${formData?.endTimeHour}:${formData?.endTimeMin} ${formData?.endTimeMeridiem}`,
      },
    },
    isFormData: true,
  });
};

export const editWorkshiftTypeApi = ({
  workshiftId,
  companyId,
  isAdmin,
  formData,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/work_hour_shifts/${workshiftId}`,
  });
  return requestData(url, {
    method: "PUT",
    body: {
      work_hour_shift: {
        name: formData?.name,
        start_time: `${formData?.startTimeHour}:${formData?.startTimeMin} ${formData?.startTimeMeridiem}`,
        end_time: `${formData?.endTimeHour}:${formData?.endTimeMin} ${formData?.endTimeMeridiem}`,
      },
    },
    isFormData: true,
  });
};
export const deleteWorkshiftTypeApi = ({ workshiftId, companyId, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/work_hour_shifts/${workshiftId}`,
  });
  return requestData(url, {
    method: "DELETE",
  });
};

export const getAllWorkshiftTypesApi = ({ pageNumber, companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/work_hour_shifts?page=${pageNumber}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};
