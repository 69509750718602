import React, { useMemo, useRef } from "react";
import { useHistory } from "react-router";
import Routes from "../../../../../../Routes/Path";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import { getStatusType } from "helper";

import { Cell, ColorBlock } from "../../../../style/display";
import { useDeleteleaveType } from "modules/Leaves/hooks/useDeleteLeaveType";

const RenderItem = ({ data, columnIndex, rowIndex, style }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
    maxStatusLength,
    rowIdMap,
  } = data;

  const history = useHistory();
  const tempRef = useRef({ rowToDelete: "" });

  const { isLoading: isDeletingLeaveType, deleteLeaveType } =
    useDeleteleaveType();

  const onEditleaveType = (e, rowIndex) => {
    history.push(`${Routes.authorized.leaves.edit.path}/${rowIdMap[rowIndex]}`);
  };

  const onDeleteLeaveTypeClick = (e, rowIndex) => {
    if (window.confirm("Are you sure you want to delete the leave type ?")) {
      tempRef.current.rowToDelete = rowIndex;
      const leaveTypeId = rowIdMap[rowIndex];
      deleteLeaveType(leaveTypeId);
    }
  };

  const actionCellProps = useMemo(() => {
    return () => ({
      cellName: "actions",
      cellActions: [
        {
          id: "class_edit_btn",
          icon: "fas fa-pen",
          tootltipText: "Edit leave type",
          btnProps: {
            disabled:
              isDeletingLeaveType && tempRef.current.rowToDelete === rowIndex,
            onClick: onEditleaveType,
          },
        },
        {
          id: "class_delete_btn",
          icon: "fas fa-trash",
          tootltipText: "Delete leave type",
          btnProps: {
            disabled:
              isDeletingLeaveType && tempRef.current.rowToDelete === rowIndex,
            onClick: onDeleteLeaveTypeClick,
          },
        },
      ],
    });
  }, [onEditleaveType, onDeleteLeaveTypeClick, rowIdMap]);

  const cellvalue = rowData[rowIndex][columnIndex];

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["status"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const isColorColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["colorCode"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const myChildren = useMemo(() => {
    if (isStatusColumn) {
      return (
        <Cell>
          <Status
            text={cellvalue}
            type={getStatusType(cellvalue)}
            style={{ width: `${maxStatusLength * 18}px`, padding: "6px" }}
          />
        </Cell>
      );
    }
    if (isColorColumn) {
      return (
        <Cell>
          <ColorBlock $colorCode={cellvalue} />
          {cellvalue}
        </Cell>
      );
    }
    return null;
  }, [isStatusColumn, isColorColumn, cellvalue, maxStatusLength]);

  return (
    <GridCell
      row={rowIndex}
      showHeading={showHeading}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      actionCellProps={actionCellProps}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

export default RenderItem;
