import {
  FETCH_NEW_JOINEES_TODAY,
  FETCH_UPCOMINGS_JOINEES,
} from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import { fetchNewJoineesTodayApi, fetchUpcomingJoineesApi } from "./api";

const fetchNewJoineesTodayGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: FETCH_NEW_JOINEES_TODAY,
    sideEffect: fetchNewJoineesTodayApi({ companyId, isAdmin, pageNumber }),
  };
};

const fetchUpcomingJoineesGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: FETCH_UPCOMINGS_JOINEES,
    sideEffect: fetchUpcomingJoineesApi({
      companyId,
      isAdmin,
      pageNumber,
    }),
  };
};

export const [fetchNewJoinees, fetchUpcomingJoinees] = createAsyncActionCreator(
  [fetchNewJoineesTodayGenrator, fetchUpcomingJoineesGenrator]
);
