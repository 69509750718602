import React, { useMemo, useCallback } from "react";
import { createDayMatrix } from "helper";

import { DataContainer, RowContainer, Cell } from "./style";

const DayRow = ({ data = [], row, currentDay, onDayChange }) => {
  const onChange = useCallback(
    (e) => {
      const { day } = e.target.dataset;
      onDayChange(day);
    },
    [onDayChange]
  );

  return (
    <RowContainer>
      {data.map((day, index) => {
        return (
          <Cell
            onClick={day ? onChange : null}
            data-day={day}
            day={day}
            isActive={day == currentDay}
            key={`dayCell_${row}_${index}`}
            cellType="day"
          >
            {day}
          </Cell>
        );
      })}
    </RowContainer>
  );
};

const Days = React.forwardRef(
  ({ day, month, year, onDayChange, visibility }, ref) => {
    const { days } = useMemo(() => {
      const date = new Date(year, month, 1);
      const startingDay = date.getDay();
      const totalDays = new Date(year, month + 1, 0).getDate();
      return {
        days: createDayMatrix({
          rows: 5,
          columns: 6,
          startingDay,
          totalDays,
        }),
      };
    }, [month, year]);
    return (
      <DataContainer $visibility={visibility} ref={ref}>
        {days.map((dayRows, index) => {
          return (
            <DayRow
              onDayChange={onDayChange}
              currentDay={day}
              row={index}
              key={`dayRow_${index}`}
              data={dayRows}
            />
          );
        })}
      </DataContainer>
    );
  }
);
export default Days;
