export const generateFormData = ({
  isAdmin,
  isCreditType,
  values,
  creditValues,
  debitValues
}) => {
  let formData = {};
  if (isAdmin) {
    formData = { ...formData, ...values };
    formData["collaborator_id"] = Object.keys(values?.collaborator)[0];
    delete formData.collaborator;
  }
  if (isCreditType) {
    formData = { ...formData, ...creditValues };
    formData["from_date"] = formData?.date;
    formData["to_date"] = formData?.date;
  } else {
    formData = { ...formData, ...debitValues };
  }
  formData["notes"] = formData?.notes || "";
  return formData;
};
