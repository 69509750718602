import requestData from "../../../../ApiHandler";
import { createAuthUrl } from "helper";

export const fetchCommentsApi = ({
  companyId,
  isAdmin,
  postId,
  pageNumber,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/comments?page=${pageNumber}&commentable_id=${postId}&commentable_type=Post`,
  });
  return requestData(url, {
    method: "GET",
  });
};
