import React, { useCallback, useMemo } from "react";
import { getImageUrl } from "helper/common";

import Icon from "../Icon";
import {
  ListItemContainer,
  ListItemCheckButton,
  ListItemContent,
  ListItemDesc,
  ListItemHeading,
  ListItemImage,
  StyledProfilePic,
  BulletList,
} from "./style";

export const ListItem = ({
  selected,
  onSelect,
  data,
  showSelectButton = true,
  showDeselectButton = false,
  hideImage = false,
}) => {
  const ListItemClicked = useCallback(() => {
    if (typeof onSelect === "function") {
      onSelect(data.id, data);
    }
  }, [onSelect, data]);

  const imageUrl = useMemo(() => getImageUrl(data?.image), [data]);

  const content = useMemo(() => {
    if (hideImage) {
      return <BulletList>&#8226;</BulletList>;
    }
    if (!!data.image) {
      return <ListItemImage src={imageUrl} />;
    }
    return <StyledProfilePic userName={data.heading} />;
  }, [hideImage, data, imageUrl]);

  return (
    <ListItemContainer onClick={!showSelectButton ? ListItemClicked : null}>
      {content}
      <ListItemContent>
        <ListItemHeading>{data.heading}</ListItemHeading>
        <ListItemDesc>{data.desc}</ListItemDesc>
      </ListItemContent>

      {showSelectButton && (
        <ListItemCheckButton
          type="button"
          outline={showDeselectButton}
          onClick={ListItemClicked}
          active={selected}
        >
          <Icon
            name={
              showDeselectButton
                ? "fas fa-minus"
                : selected
                ? "fas fa-check"
                : "fas fa-plus"
            }
          />
        </ListItemCheckButton>
      )}
    </ListItemContainer>
  );
};
