import React, { useMemo } from "react";

import { DayBox, DayDate, DayName, DaysContainer } from "./style";
const DayItem = ({ day, date, isTodayDate }) => {
  return (
    <DayBox>
      <DayName>{day}</DayName>
      <DayDate isTodayDate={isTodayDate}>{date}</DayDate>
    </DayBox>
  );
};

const WeekDays = ({ data = [] }) => {
  const days = useMemo(() => {
    return data.map((item, index) => {
      return (
        <DayItem
          key={`${item.day}_${item.date}_${index}`}
          day={item.day}
          date={item.date}
          isTodayDate={item.isTodayDate}
        />
      );
    });
  }, [data]);

  return <DaysContainer>{days}</DaysContainer>;
};

export default WeekDays;
