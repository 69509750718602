import React, { useMemo, useCallback } from "react";
import { createMonthMatrix } from "helper";

import { DataContainer, RowContainer, Cell } from "./style";

const MonthRow = ({ data = [], row, onMonthChange, currentMonth }) => {
  const onChange = useCallback(
    (e) => {
      const { month } = e.target.dataset;
      onMonthChange(month);
    },
    [onMonthChange]
  );

  return (
    <RowContainer>
      {data.map((month, index) => {
        return (
          <Cell
            data-month={month.value || ""}
            onClick={month ? onChange : null}
            role="button"
            month={month}
            isActive={month && month.value == currentMonth}
            cellType="month"
            key={`monthCell_${row}_${index}`}
          >
            {month.name || ""}
          </Cell>
        );
      })}
    </RowContainer>
  );
};

const Months = React.forwardRef(
  ({ onMonthChange, currentMonth, visibility }, ref) => {
    const { months } = useMemo(() => {
      return { months: createMonthMatrix({ rows: 5, columns: 4 }) };
    }, []);
    return (
      <DataContainer $visibility={visibility} ref={ref}>
        {months.map((monthRow, index) => {
          return (
            <MonthRow
              key={`monthRow_${index}`}
              onMonthChange={onMonthChange}
              data={monthRow}
              row={index}
              currentMonth={currentMonth}
            />
          );
        })}
      </DataContainer>
    );
  }
);

export default Months;
