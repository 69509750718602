import React from "react";
import Input from "../index";

import { SearchBoxContainer } from "./style";
const SearchBox = (props) => {
  return (
    <SearchBoxContainer>
      <Input secondary placeholder="Enter text to search" {...props} />
    </SearchBoxContainer>
  );
};
export default SearchBox;
