import React from "react";

export const generateDrawerLinks = (routes, getPageAccess, Component) => {
  const tabLinks = Object.values(routes).map((item) => item.path);

  const LinkComponents = [];

  Object.values(routes).map(({ path, drawerTitle }) => {
    const accessType = getPageAccess(path);
    if (path && drawerTitle) {
      LinkComponents.push(
        <Component
          key={path}
          path={path}
          title={drawerTitle}
          pageAccessType={accessType}
        />
      );
    }
  });

  return [tabLinks, LinkComponents];
};
