import React, { useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";

import { RectangleSkeleton } from "components/Skeleton";

import ActionArea from "./ActionArea";
import TableData from "./TableData";

import InfoView from "components/InfoView";
import EmptyScreen from "assets/employee/ReimbursementBlank.png";
import PageSlider from "common/Components/PageSlider";
import { isCurrentUserIsExecutive } from "common/Selectors/auth";
import { usePageSlider } from "../../../../Hooks/usePageSlider";
import { ViewContainer } from "../../../../GlobalStyles";

import LedgerCategoryCreateView from "modules/Ledger/Create";

import DetailView from "../Details/Details";

const LedgerDisplayView = ({ isAdmin, ledgerId }) => {
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);
  const [isFormVisible, updateFormVisibility] = useState(false);

  const { onCellClick, isSliderActive, toggleSlider, data } = usePageSlider();

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  const displayForm = () => {
    updateFormVisibility(true);
  };

  const CurrentTab = useMemo(() => {
    return (
      <ViewContainer>
        <ActionArea
          onLoading={onDbDataChange}
          style={{
            display: isLoading || isEmpty ? "none" : "inherit",
          }}
          updateFormVisibility={updateFormVisibility}
        />
        <TableData
          style={{
            display: isLoading || isEmpty ? "none" : "inherit",
          }}
          onEmptyData={onEmptyData}
          onCellClick={onCellClick}
        />
        {!isLoading && isEmpty && (
          <InfoView
            image={EmptyScreen}
            desc={!isAdmin && "There are no ledger entries to display yet"}
            onCta={isAdmin && displayForm}
            ctaText={isAdmin && "Add a new ledger"}
          />
        )}
        {isLoading && <RectangleSkeleton times={4} />}
      </ViewContainer>
    );
  }, [onEmptyData, onDbDataChange, isLoading, isEmpty]);

  if (isFormVisible) {
    return (
      <LedgerCategoryCreateView updateFormVisibility={updateFormVisibility} />
    );
  }

  return (
    <>
      {CurrentTab}
      <PageSlider
        showBackButton={false}
        onClose={toggleSlider}
        active={isSliderActive}
      >
        <DetailView data={data} isAdmin={isAdmin} onClose={toggleSlider} />
      </PageSlider>
    </>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const ledgerId = store.ledger.categories.ledgerId;
  return { isAdmin, ledgerId };
};

export default connect(mapStateToProps, null)(LedgerDisplayView);
