import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  PollContainer,
  PollButton,
  PollFooter,
  PollHeader,
  PollResult,
  PollResultBar,
  PollResultContainer,
  PollResultBarContainer,
} from "./style";

export function PostPoll({
  heading,
  // desc,
  votes,
  timeLeft,
  options,
  pollStatistics,
  onPoll,
  enabled,
  answereOptionId = "",
  ...rest
}) {
  const [showPollResult, setPollResultStatus] = useState(false);
  const [userSelectedPollId, setUserSelectedPoll] = useState(null);
  const [initialSelectedPollId, setInitialSelectedPoll] = useState(null);

  const onPollComplete = (success = false) => {
    if (success) {
      return;
    }
    setUserSelectedPoll(null);
    setPollResultStatus(false);
  };

  const onPolled = (value) => {
    if (!enabled) {
      return;
    }
    setUserSelectedPoll(value);
    setPollResultStatus(true);
    onPoll && onPoll(value, onPollComplete);
  };

  useEffect(() => {
    if (answereOptionId) {
      setInitialSelectedPoll(answereOptionId);
      setPollResultStatus(true);
    }
  }, [answereOptionId]);

  const polls = useMemo(() => {
    if (!options) {
      return null;
    }
    return options.map(({ name, value }) => {
      return (
        <PollButton
          style={{
            cursor: enabled ? "pointer" : "not-allowed",
          }}
          key={`${name}_${value}`}
          onClick={() => onPolled(value)}
        >
          {name}
        </PollButton>
      );
    });
  }, [options, onPolled, enabled]);

  const getResultOptions = () => {
    let totalVotes = 0;
    pollStatistics.forEach(({ value }) => {
      totalVotes += value;
    });

    /*
        increasing the total votes only when the user has committed poll in local
        and the reponse has not came yet
    */

    if (userSelectedPollId && !initialSelectedPollId) {
      totalVotes++;
    }

    return pollStatistics.map(({ name, value, optionId }) => {
      /**
       * increasing the particular option vote only when the user has committed poll in local
         and the reponse has not came yet
       */
      const vote =
        userSelectedPollId === optionId && !initialSelectedPollId
          ? value + 1
          : value;

      const percentVotes = Math.floor((vote / totalVotes) * 100);
      return {
        value: percentVotes,
        name,
        optionId,
      };
    });
  };

  const pollResults = useMemo(() => {
    if (!showPollResult) {
      return [];
    }
    const resultOptions = getResultOptions();
    return resultOptions.map(({ name, value, optionId }) => {
      return (
        <PollResultView
          key={`${name}_${value}`}
          title={name}
          isSelected={
            initialSelectedPollId === optionId ||
            userSelectedPollId === optionId
          }
          value={value}
        />
      );
    });
  }, [
    pollStatistics,
    showPollResult,
    userSelectedPollId,
    initialSelectedPollId,
  ]);

  return (
    <PollContainer $active={timeLeft} {...rest}>
      <PollHeader>
        <span className="title">{heading}</span>
        {/* <span className="desc">{desc}</span> */}
      </PollHeader>

      {showPollResult ? pollResults : polls}
      <PollFooter>
        <span className="foot-item">
          {votes} {votes > 1 ? "votes" : "vote"}
        </span>
        {<span className="foot-item">{timeLeft || "0 hours"} left</span>}
      </PollFooter>
    </PollContainer>
  );
}

PostPoll.propTypes = {
  heading: PropTypes.string.isRequired,
  // desc: PropTypes.string.isRequired,
  votes: PropTypes.number.isRequired,
  timeLeft: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onPoll: PropTypes.func,
};

function PollResultView({ title, isSelected, value, ...rest }) {
  return (
    <PollResultContainer>
      <PollResult className="poll-result" $isSelected={isSelected} {...rest}>
        {value}% {title}
      </PollResult>
      <PollResultBarContainer>
        <PollResultBar
          style={{ width: `${value}%` }}
          $isSelected={isSelected}
        />
      </PollResultBarContainer>
    </PollResultContainer>
  );
}
