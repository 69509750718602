import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import Counter from "../Counter";
import { getNameInitials, getRandomColor } from "helper";
import { Container, Pic, Initials, Dot } from "./style";
import { getHowManyMore } from "../../../Helper/string";
import { getCDNImageUrl, getImageUrl } from "../../../Helper";

const ProfilePic = ({
  img,
  cdnImg,
  userName,
  more,
  color,
  className,
  showDot,
  count,
  setChildRef,
  showName,
  ...otherProps
}) => {
  const imageContainerRef = useRef(null);
  const tempRef = useRef(null);
  const isImage = typeof img === "string" && img !== "NA" && img !== "";
  const cdnImage = useMemo(() => getCDNImageUrl(cdnImg), [cdnImg]);
  const imageUrl = useMemo(() => getImageUrl(img), [img]);
  const [image, setImage] = useState(imageUrl || cdnImage);
  const initials = useMemo(() => {
    if (isImage) {
      return "";
    }
    if (more) {
      return getHowManyMore(more);
    }
    return getNameInitials(userName);
  }, [isImage, userName]);

  const background = useMemo(() => {
    if (isImage) {
      return "white";
    }
    if (color) {
      return color;
    }
    return getRandomColor();
  }, [isImage, color]);

  useEffect(() => {
    if (tempRef.current) {
      setImage(getImageUrl(img));
    } else {
      tempRef.current = true;
    }
  }, [img]);

  useEffect(() => {
    if (typeof setChildRef === "function") {
      setChildRef(imageContainerRef.current);
    }
  }, []);

  const onImageError = useCallback(() => {
    if (image === cdnImage) {
      setImage(imageUrl);
    }
  }, [imageUrl, cdnImage]);

  return (
    <Container
      ref={imageContainerRef}
      {...otherProps}
      className={className}
      title={showName ? userName : ""}
      $background={background}
    >
      {!!isImage && <Pic src={image} onError={onImageError} />}
      {!!initials && <Initials>{initials}</Initials>}
      {showDot && <Dot />}
      {!!count && <Counter count={count} />}
    </Container>
  );
};
ProfilePic.defaultProps = {
  className: "",
  color: null,
  showDot: false,
  count: 0,
  showName: true,
};

export default ProfilePic;
