import { getImageUrl } from "helper/common";
import { useFetchCollaboratorDetails } from "modules/UserProfile/hooks/useFetchCollaboratorDetails";
import {
  Container,
  Content,
  CoverImage,
  Designation,
  DetailsConatiner,
  EditButton,
  ProfileImage,
  ProfileSection,
  AboutSection,
  AboutContent,
  Title,
} from "modules/UserProfile/style/display";
import { useHistory, useParams } from "react-router";
import CoverImg from "assets/common/CoverPhoto.png";
import ProfileImg from "assets/common/ProfilePhoto.png";
import { useSelector } from "react-redux";
import { getCurrentUserId } from "common/Selectors/auth";
import Routes from "route/Path";
import { useEffect, useRef } from "react";
import ProfileItem from "./ProfileItem";
import { changeToDateFormat } from "helper/date";
import { SingleRectangleSkeleton } from "components/Skeleton";

const UserProfileView = () => {
  const params = useParams();
  const history = useHistory();
  const tempRef = useRef();

  const { isLoading, userData, fetchFirstPage } = useFetchCollaboratorDetails(
    params.id,
  );
  const joinedAt = changeToDateFormat(userData?.joinedOn) || "";
  const dateOfBirth = changeToDateFormat(userData?.dateOfBirth) || "";

  const currentCollaboratorId = useSelector(getCurrentUserId);

  const onEdit = () => {
    history.push(Routes.authorized.profile.view.path);
  };

  useEffect(() => {
    if (!tempRef.current) {
      if (!isLoading && !Object.keys(userData).length) {
        fetchFirstPage();
      }
      tempRef.current = true;
    }
  }, [params, isLoading, userData]);
  const Skeleton = () => {
    return (
      <>
        <SingleRectangleSkeleton style={{ marginBottom: "15px" }} />
        <SingleRectangleSkeleton
          style={{
            marginBottom: "15px",
            width: "30%"
          }}
        />
        <SingleRectangleSkeleton
          style={{ marginBottom: "15px", width: "30%" }}
        />
      </>
    );
  };
  return (
    <Container>
      <ProfileSection>
        <CoverImage>
          <img
            src={getImageUrl(userData?.coverPhotoUrl) || CoverImg}
            key={`${userData?.coverPhotoUrl}`}
            alt="cover"
          />
        </CoverImage>
        <DetailsConatiner>
          <ProfileImage
            src={getImageUrl(userData?.avatarUrl?.url) || ProfileImg}
            key={`${userData?.avatarUrl?.url}`}
          />
          <Content>
            {!isLoading ? (
              <>
                {userData?.name} ({userData?.employeeId})
                <Designation>{userData?.role}</Designation>
                <Designation>Associated from : {joinedAt}</Designation>
              </>
            ) : (
              <div style={{ width: "400px" }}>
                <Skeleton />
              </div>
            )}
          </Content>

          {currentCollaboratorId == params.id && (
            <EditButton onClick={onEdit}>Edit Profile</EditButton>
          )}
        </DetailsConatiner>
      </ProfileSection>
      <AboutSection>
        <AboutContent>
          <Title>About</Title>
          {!isLoading ? (
            <>
              <ProfileItem icon="far fa-envelope" text={userData?.email} />
              <ProfileItem icon="fas fa-mobile-alt" text={userData?.phone} />
              <ProfileItem icon="fas fa-birthday-cake" text={dateOfBirth} />
            </>
          ) : (
            <div style={{ marginLeft: "20px" }}>
              <Skeleton />
            </div>
          )}
        </AboutContent>
      </AboutSection>
    </Container>
  );
};

export default UserProfileView;
