import { extractDateInYYYYMMDD } from "../../Helper";

export const generateFormData = (values) => {
  const {
    familyCreate_familyMemberName,
    familyCreate_familyMemberRelation,
    familyCreate_familyMemberDOB,
    familyCreate_familyMemberPhone,
    familyCreate_familyMemberEmail,
    familyCreate_familyMemberOccupation,
    familyCreate_familyMemberMedicalClaimCheck,
  } = values;

  return {
    name: familyCreate_familyMemberName,
    relation: familyCreate_familyMemberRelation,
    date_of_birth: extractDateInYYYYMMDD(familyCreate_familyMemberDOB),
    phone: familyCreate_familyMemberPhone,
    email: familyCreate_familyMemberEmail.toLowerCase(),
    occupation: familyCreate_familyMemberOccupation,
    medical_claim_inclusion:
      familyCreate_familyMemberMedicalClaimCheck.medical_claim_inclusive
        ? true
        : false,
  };
};
