import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { RootPageRoute } from "../Pages";

const NotFoundPage = lazy(() => import("../../../Modules/NotFoundPage/View"));

const Fallback = () => <p>Loading Page ...</p>;

const PageRoutes = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <Switch>
        {RootPageRoute}
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

export default PageRoutes;
