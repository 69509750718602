import styled from "styled-components";
import Button from "../Button";

export const Container = styled.div`
  background-color: whitesmoke;
  position: relative;
  border-radius: 10px;
`;

export const StyledButton = styled(Button)`
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 20px;
  top: 20px;
  &:hover {
    box-shadow: none;
  }
  border-radius: 50%;
`;
