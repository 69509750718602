import DepartmentPages from "./department";
import Notice from "./notice";
import JobTitlePages from "./jobTitle";
import UserPages from "./user";
import WelcomePages from "./welcome";
import CalendarPages from "./calendar";
import AccessAssetsPages from "./companyAssets";
import HolidayPages from "./holiday";
import HolidayYearPages from "./holidayYear";
import ReimbursementPages from "./reimbursement";
import ProfilePages from "./profile";
import LeadsPages from "./leads";
import FollowSubordinatePages from "./followSubordinate";
import AttendencePages from "./attendence";
import SeparationPages from "./separation";
import LeavePages from "./leave";
import SalarySlipPages from "./salarySlip";
import HelpdeskPages from "./helpdesk";
import BranchPages from "./companyBranch";
import BreaksPages from "./breaks";
import TeamsPages from "./teams";
import PostPages from "./posts";
import LeavesPages from "./leaves";
import SurveyPages from "./survey";
import LeaveManagementPages from "./leaveManagement";
import LedgerPages from "./ledger";
import NotifiedWorkReportPages from "./notifiedWorkReport";
import CountdownPages from "./countdown";
import UserProfilePages from "./userProfile";
import WorkshiftPages from "./workshift";

/**
 * RootPage route is made an array instead of react component because Switch works with
 * only direct children rather than the deeply nested children
 */

export const RootPageRoute = [
  ...WelcomePages(),
  ...DepartmentPages(),
  ...Notice(),
  ...JobTitlePages(),
  ...UserPages(),
  ...CalendarPages(),
  ...AccessAssetsPages(),
  ...HolidayPages(),
  ...HolidayYearPages(),
  ...ReimbursementPages(),
  ...ProfilePages(),
  ...LeadsPages(),
  ...FollowSubordinatePages(),
  ...AttendencePages(),
  ...SeparationPages(),
  ...LeavePages(),
  ...SalarySlipPages(),
  ...HelpdeskPages(),
  ...BranchPages(),
  ...BreaksPages(),
  ...TeamsPages(),
  ...PostPages(),
  ...LeavesPages(),
  ...SurveyPages(),
  ...LeaveManagementPages(),
  ...LedgerPages(),
  ...NotifiedWorkReportPages(),
  ...CountdownPages(),
  ...UserProfilePages(),
  ...WorkshiftPages(),
];
