import React, { KeyboardEvent, useMemo, useState } from "react";
import ActivityIndicator from "components/ActivityIndicator";

import { CommentButton, CommentCreateContainer, CommentInput } from "./style";

const Initial_Rows = 2;

interface ICommentCreateProps {
  onComment:
    | null
    | ((comment: string, onComplete: (success: boolean) => void) => void);
  isCreatingComment: boolean;
  initialValue: string;
}

export function CreateComment({
  onComment,
  isCreatingComment,
  initialValue,
  ...rest
}: ICommentCreateProps) {
  const [comment, updateComment] = useState(initialValue || "");
  const [rows, updateRows] = useState(Initial_Rows);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateComment(e.target.value);
  };

  const onComplete = (success = false) => {
    if (!success) {
      return;
    }
    updateComment("");
    updateRows(Initial_Rows);
  };

  const onCreateComment = () => {
    onComment && onComment(comment.trim(), onComplete);
  };

  const shrinkRows = () => {
    const newRows = comment ? Math.max(comment.split("\n").length + 1, 2) : 2;
    if (newRows === rows) {
      return;
    }
    updateRows(newRows);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      updateRows((val) => val + 1);
    }

    if (e.key === "Backspace") {
      shrinkRows();
    }
  };

  const hasComment = useMemo(() => {
    if (!comment || !comment.trim()) {
      return false;
    }
    return true;
  }, [comment]);

  return (
    <CommentCreateContainer {...rest}>
      <CommentInput
        placeholder="Write a comment..."
        value={comment}
        onChange={onChange}
        rows={rows}
        onKeyDown={onKeyDown}
      />
      <CommentButton
        disabled={isCreatingComment || !hasComment}
        onClick={onCreateComment}
        $active={hasComment && !isCreatingComment}
      >
        {isCreatingComment ? (
          <ActivityIndicator size="xs" color="#4ac501" className="" />
        ) : (
          <i className="far fa-paper-plane"></i>
        )}
      </CommentButton>
    </CommentCreateContainer>
  );
}
