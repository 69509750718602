import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import { isCurrentUserIsExecutive } from "common/Selectors/auth";

import DrawerLink from "../DrawerLink";
import TabsRenderer from "./TabsRenderer";
import { generateDrawerLinks } from "helper";

import Dashboard_Img from "assets/Dashboard.svg";
import E_Dashboard_Img from "assets/employee/Dashboard.svg";

const { employeeWelcome, adminWelcome } = Routes.authorized.welcome;

const [dashboardTabLinks] = generateDrawerLinks(
  Routes.authorized.welcome,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

export const DashboardTab = ({ isAdmin }) => {
  const history = useHistory();
  const isExecutive = useSelector(isCurrentUserIsExecutive);

  const onClick = useCallback(() => {
    history.push(isExecutive ? adminWelcome.path : employeeWelcome.path);
  }, [isExecutive]);

  const accessType = useMemo(() => {
    return AccessTypes.pages.getPageAccessType(
      isExecutive ? adminWelcome.path : employeeWelcome.path
    );
  }, [isExecutive]);

  return (
    <TabsRenderer
      isAdmin={isAdmin}
      onClick={onClick}
      accessType={accessType}
      image={isAdmin ? Dashboard_Img : E_Dashboard_Img}
      title="Dashboard"
      links={dashboardTabLinks}
    />
  );
};
