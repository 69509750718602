type urlParams = { isAdmin: boolean; url: string };
type createAuthUrlFunc = (params: urlParams) => string;
type errors = { [key: string]: Array<string> };

export const createAuthUrl: createAuthUrlFunc = ({ isAdmin, url }) => {
  return `/${isAdmin ? "executive" : "employee"}${url}`;
};

export const createErrorMessage = (errors: errors) => {
  if (Object.keys(errors).length > 0) {
    return Object.keys(errors)
      .map((key) => {
        if (Array.isArray(errors[key])) return errors[key]?.join(", ");
        if (typeof errors[key] == "string") return errors[key];
      })
      .join(", ");
  }
  return null;
};
