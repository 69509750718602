import {
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_ALL_ACTIVE_USERS,
  GET_ALL_IN_ACTIVE_USERS,
  SET_EXECUTIVE_STATUS,
  CHANGE_PAGE_CATEGORY,
  SET_CURRENT_USER_ID,
  RESET_USER_ID,
  UPDATE_USER_STATUS,
} from "./actiontypes";
import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnUpdate,
} from "../../Helper";

const initialState = createInitialStoreState({
  currentPageCategory: "active",
  executiveStatus: false,
  currentUserId: "",
});

export const userReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_ALL_ACTIVE_USERS}_SUCCESS`:
    case `${GET_ALL_IN_ACTIVE_USERS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    case `${CREATE_USER}_SUCCESS`: {
      // const { userCategory } = payload;
      // if (userCategory === store.currentPageCategory) {
      return createNewStoreOnCreate(payload, store);
      // }
      // return store;
    }

    case `${UPDATE_USER_STATUS}_SUCCESS`:
    case `${UPDATE_USER}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }

    case SET_EXECUTIVE_STATUS: {
      const { isExecutive } = payload;
      return {
        ...store,
        executiveStatus: isExecutive,
      };
    }

    case CHANGE_PAGE_CATEGORY: {
      const { category } = payload;
      return {
        ...store,
        currentPageCategory: category,
        onceFetched: false,
      };
    }

    case SET_CURRENT_USER_ID: {
      const { userId } = payload;
      return {
        ...store,
        currentUserId: userId,
      };
    }

    default:
      return store;
  }
};
