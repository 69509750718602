import {
  CREATE_WORKSHIFT_TYPE,
  DELETE_WORKSHIFT_TYPE,
  EDIT_WORKSHIFT_TYPE,
  GET_ALL_WORKSHIFT_TYPES,
} from "./actionType";

import {
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnUpdate,
  createNewStoreOnDelete,
} from "helper";
import { combineReducers } from "redux";
import { workshiftDetailReducer } from "./WorkshiftDetails/reducer";

const initialState = createInitialStoreState({ workshiftId: null }, true);

export const workshiftTypeReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_ALL_WORKSHIFT_TYPES}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    case `${CREATE_WORKSHIFT_TYPE}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true);
    }

    case `${EDIT_WORKSHIFT_TYPE}_SUCCESS`:
      return createNewStoreOnUpdate(payload, store, true);

    case `${DELETE_WORKSHIFT_TYPE}_SUCCESS`:
      return createNewStoreOnDelete(payload, store);
    default:
      return store;
  }
};

const workshiftReducer = combineReducers({
  type: workshiftTypeReducer,
  details: workshiftDetailReducer,
});
export default workshiftReducer;
