export const FormatNumbers = (value) => {
  let formattedNumber = value.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  return formattedNumber;
};

export const Hours = Array.from(Array(13).keys()).map((hour) => {
  return { name: FormatNumbers(hour), value: FormatNumbers(hour) };
});

export const MinutesOrSeconds = Array.from(Array(60).keys()).map((min) => {
  return { name: FormatNumbers(min), value: FormatNumbers(min) };
});
export const meridiem = [
  { name: "AM", value: "AM" },
  { name: "PM", value: "PM" },
];

export const statusOptions = [
  { name: "Active", value: "active" },
  { name: "Inactive", value: "inactive" },
];
