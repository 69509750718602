import styled from "styled-components";
import { GlobalInput } from "../style";
import { Calendar as CalendarSvg } from "../../../../AssetComponents";

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 6px;
  height: auto;
  flex-direction: column;
  @media(min-width:768px) {
    flex-direction: row;
    .from-date-picker {
      margin-right: 10px;
    }
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
`;

export const DatePickerButton = styled(GlobalInput)`
  text-align: left;
  cursor: pointer;
  background-color: white;
`;

export const DatePickerContent = styled.div`
  position: absolute;
  left: 0;
  min-width: 300px;
  z-index: 100000;
  box-shadow: ${(props) => props.theme.shadow.medium};
  border-radius: 6px;
  background-color: white;
  overflow: hidden;
`;

export const ActionRowContainer = styled.div`
  width: 100%;
  height: ${(props) => props.theme.dimensions.datePicker.actionHeight};
  padding: ${(props) => props.theme.dimensions.datePicker.padding};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
`;

export const ActionButton = styled.button`
  width: 80px;
  height: 40px;
  background-color: white;
  border: none;
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: bold;
  cursor: pointer;
`;

export const PageBox = styled.div`
  width: auto;
  height: 40px;
  display: flex;
`;

export const PageActionBtn = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  font-size: ${(props) => props.theme.text.para.medium};
  cursor: pointer;
`;

export const DataContainer = styled.div`
  width: 100%;
  height: calc(
    100% - ${(props) => props.theme.dimensions.datePicker.weekHeight} -
      ${(props) => props.theme.dimensions.datePicker.actionHeight}
  );
  display: ${({ $visibility }) => ($visibility ? "flex" : "none")};
  flex-flow: column nowrap;
  padding: ${(props) => props.theme.dimensions.datePicker.padding};
  background-color: white;
`;

export const RowContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-grow: 1;
  background-color: white;
`;

export const Cell = styled.button`
  flex: 1;
  outline: none;
  background-color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.text.para.small};
  color: ${(props) => props.theme.colors.darkGrayColor};

  ${({ day, isActive, isBetween }) => {
    return day && !isActive
      ? `
      &:hover {
        background-color: ${isBetween ? '' : 'rgba(0, 0, 0, 0.1)'};
        border-radius: ${isBetween ? '0' : '50%'};
        cursor: pointer;
      }
      `
      : "";
  }}

  ${({ month, year, isActive }) => {
    return (month || year) && !isActive
      ? `
      color: #7676DC;
      font-weight: bolder;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
      `
      : "";
  }}

  ${({ isActive, cellType, theme, disabled, isBetween }) => {

    if (cellType === 'day') {
      return `
        background-color: ${isActive ? '#7676DC' : isBetween ? '#E8E8FF' : 'white'};
        color: ${isActive ? 'white' : disabled ? '#E4E4FF' : '#7676DC'};
        border-radius: ${isBetween ? '0' : '50%'};
        font-weight: bolder;
        z-index: 10;
      `;
    }
    return isActive
      ? `
        background-color: ${isActive ? '#7676DC' : 'white'};
        color: ${isActive ? 'white' : disabled ? '#E4E4FF' : '#7676DC'};
        border-radius: 0;
        font-weight: bolder;
      `
      : "";
  }}

  ${({ disabled, isFromDate, isToDate }) => {
    return disabled && !isFromDate && !isToDate && `
      color: #E4E4FF;
      &:hover {
        background-color: transparent;
        cursor: not-allowed;
        border-radius: 0;
      }
    `
  }}
`;

export const ExtraContent = styled.span`
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #E8E8FF;
  display: none;
  ${({ isFromDate, isToDate }) => {
    if (isFromDate) {
      return `
        right: 0;
        display: block;
      `
    }
    if (isToDate) {
      return `
        display: block;
      `
    }
  }}
`;

export const CellBox = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

export const WeekContainer = styled.div`
  width: 100%;
  height: ${(props) => props.theme.dimensions.datePicker.weekHeight};
  border-bottom: 1px lightgray solid;
  display: flex;
  padding: 0 ${(props) => props.theme.dimensions.datePicker.padding};
  background-color: white;

  & > span {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.theme.text.para.small};
    color: ${(props) => props.theme.colors.primaryColor};
  }
`;

export const CalendarIcon = styled(CalendarSvg)`
  position: absolute;
  font-size: 19px;
  right: 10px;
  top: calc(50% - 10px);
  stroke: ${(props) => props.theme.colors.primaryColor};
`;
