import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import TabsRenderer from "./TabsRenderer";
import DrawerLink from "../DrawerLink";
import { generateDrawerLinks } from "helper";

import Profile_Img from "assets/Profile.svg";
import E_Profile_Img from "assets/employee/Profile.svg";

const [tabLinks, LinkComponents] = generateDrawerLinks(
  Routes.authorized.user,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

export const UserTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType("user_section")}
    >
      {/* <DrawerTitle title="User Section" /> */}
      <TabsRenderer
        accessType={AccessTypes.tabs.getTabAccessType("user")}
        image={isAdmin ? Profile_Img : E_Profile_Img}
        title="User"
        links={tabLinks}
      >
        {LinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
