import React from "react";
import Cross_Img from "assets/common/cross.svg";

import { Container } from "./style";

function CloseButton(props) {
  return (
    <Container {...props}>
      <img role="button" src={Cross_Img} alt="cross" />
    </Container>
  );
}

export default CloseButton;
