import React from "react";
import Icon from "../Icon";
import { LeftSliderButton, RightSliderButton } from "./style";

function SliderButton({ showLeftBtn, showRightBtn }) {
  return (
    <>
      {!!showLeftBtn && (
        <LeftSliderButton>
          <Icon name="fas fa-chevron-left" />
        </LeftSliderButton>
      )}
      {!!showRightBtn && (
        <RightSliderButton>
          <Icon name="fas fa-chevron-right" />
        </RightSliderButton>
      )}
    </>
  );
}
export default SliderButton;
