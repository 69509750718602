import React, { useEffect, useCallback, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";

import Paginator from "../../../../Common/Components/Paginator";
import usePagination from "../../../../Hooks/usePagination";
import useForm from "../../../../Hooks/useForm";

import SearchFilter from "./SearchFilter";

import { PaginatorBox } from "../../../../GlobalStyles";
import { EnhancedActionArea, SearchArea } from "../../style/display";
import { getLeads, getLeadsByCollaborator, searchLeads, setSearchParams, resetToPrevData } from "../../actionCreator";
import { extractDateInYYYYMMDD } from "helper";

const ActionArea = ({ isAdmin, onceFetched, companyId, setSearchParams, resetToPrevData, searchData, userId, onLoading, style }) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: isAdmin ? getLeadsByCollaborator : getLeads,
    tableName: "leads",
  });

  const tempDataRef = useRef({ isSearchOn: false });

  const { formData, isFormValid, values, resetForm, updateField } = useForm({
    fields: {
      leadBy: {},
      leadType: {},
      fromStartDate: {},
      toStartDate: {},
      fromEndDate: {},
      toEndDate: {},
      status: {},
    },
  });

  const [fromStartDate, toStartDate, fromEndDate, toEndDate] = useMemo(() => {
    let from_start_date = extractDateInYYYYMMDD(values.fromStartDate);
    let to_start_date = extractDateInYYYYMMDD(values.toStartDate);
    let from_end_date = extractDateInYYYYMMDD(values.fromEndDate);
    let to_end_date = extractDateInYYYYMMDD(values.toEndDate);

    return [
      from_start_date,
      to_start_date,
      from_end_date,
      to_end_date,
    ]
  }, [values])

  const extraParams = {
    companyId,
    isAdmin,
    userId,
    fromStartDate: fromStartDate,
    toStartDate: toStartDate,
    fromEndDate: fromEndDate,
    toEndDate: toEndDate,
    status: values.status,
    leadType: values.leadType,
    leadById: values.leadBy && Object.keys(values.leadBy)[0],
  }

  const searchParams = {
    fromStartDate: fromStartDate,
    toStartDate: toStartDate,
    fromEndDate: fromEndDate,
    toEndDate: toEndDate,
    status: values.status,
    leadType: values.leadType,
    leadBy: values.leadBy,
  }

  const setInitialData = () => {
    updateField("startDate", searchData.startDate);
    updateField("endDate", searchData.endDate);
    updateField("status", searchData.status);
    updateField("leadType", searchData.leadType);
    updateField("leadBy", searchData.leadBy);
  }

  const fetchFirstPage = useCallback(
    (startDate, endDate) => {
      getNextPage({
        isFirstPage: true,
        newActionCreator: tempDataRef.current.isSearchOn && searchLeads,
        extraParams: extraParams,
      });
    },
    [
      getNextPage,
      extraParams
    ]);

  const fetchNextPage = useCallback(() => {
    getNextPage({
      newActionCreator: tempDataRef.current.isSearchOn && searchLeads,
      extraParams: extraParams,
    });
  }, [
    getNextPage,
    extraParams
  ]);

  const fetchPrevPage = useCallback(() => {
    getNextPage({
      fetchPrevPage: true,
      newActionCreator: tempDataRef.current.isSearchOn && searchLeads,
      extraParams: extraParams,
    });
  }, [
    getNextPage,
    extraParams
  ]);

  const fetchSpecificPage = useCallback(
    (pageNumber) => {
      getNextPage({
        pageNumber,
        extraParams: extraParams,
      });
    },
    [
      getNextPage,
      extraParams
    ]);

  useEffect(() => {
    if (onceFetched) {
      setInitialData()
    } else {
      fetchFirstPage();
    }
  }, []);

  const onSearch = useCallback(
    (e) => {
      e.preventDefault();
      if (!isFormValid()) {
        return;
      }
      tempDataRef.current.isSearchOn = true;
      fetchFirstPage();
      setSearchParams(searchParams);
    },
    [fetchFirstPage, values.startDate, values.endDate, values.status]
  );

  const onReset = () => {
    resetToPrevData();
    resetForm();
    tempDataRef.current.isSearchOn = false;
  };

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched && !tempDataRef.current.isSearchOn) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <EnhancedActionArea style={style}>
      {isAdmin && (
        <SearchArea>
          <SearchFilter
            formData={formData}
            dates={{
              fromStartDate: fromStartDate,
              toStartDate: toStartDate,
              fromEndDate: fromEndDate,
              toEndDate: toEndDate
            }}
            onSearch={onSearch}
            resetForm={onReset}
          />
        </SearchArea>
      )}
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);

  return {
    companyId,
    onceFetched: store.leads.onceFetched,
    isAdmin,
    searchData: store.leads.searchData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetToPrevData,
      setSearchParams,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActionArea);
