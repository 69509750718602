import React from "react";
import Form from "./Form";
import { MainContainer } from "../../style/create";

const PersonalInfoCreateView = ({ setIsFormVisible, stepsCompleted }) => {
  return (
    <MainContainer>
      <Form setIsFormVisible={setIsFormVisible} stepsCompleted={stepsCompleted} />
    </MainContainer>
  );
};

export default PersonalInfoCreateView;
