import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

export const Img = styled.img`
  width: 237px;
  height: 185px;
`;
export const Text = styled.span`
  font-size: ${(props) => props.theme.text.title.small};
  font-weight: 400;
`;
