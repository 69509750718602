import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import Icon from "components/Icon";
import Routes from "../../../../Routes/Path";

import { useCountdownForm } from "./useCountdownForm";
import { FormRow, StyledForm } from "../../style/create";
import { Hours, MinutesOrSeconds, statusOptions } from "./constants";
import { useHistory } from "react-router";

const Form = ({ updateFormVisibility, editId }) => {
  const isEditMode = !!editId;
  const history = useHistory();
  const { formData, isLoading, onFormSubmit } = useCountdownForm({
    editId,
  });

  const goBack = () => {
    history.replace(Routes.authorized.countdown.view.path);
  };
  const hideForm = () => {
    typeof updateFormVisibility == "function"
      ? updateFormVisibility(false)
      : goBack();
  };

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <Icon name="fas fa-times" onClick={hideForm} />
      <InputGroup
        label="Title"
        placeholder="Enter countdown title"
        {...formData["title"]}
      />

      <InputGroup
        label="Description"
        fieldType="textArea"
        placeholder="Enter description"
        wrapperStyle={{ height: "200px" }}
        fillParent={true}
        {...formData["description"]}
      />

      <FormRow>
        <InputGroup
          label="Happening at Date"
          fieldType="datePicker"
          placeholder="Enter date"
          {...formData["happeningAtDate"]}
        />
        -
        <InputGroup
          label="Hour"
          fieldType="select"
          options={Hours}
          defaultSelectText="hh"
          {...formData["happeningAtHour"]}
        />
        :
        <InputGroup
          label="Min"
          fieldType="select"
          defaultSelectText="mm"
          options={MinutesOrSeconds}
          {...formData["happeningAtMin"]}
        />
        :
        <InputGroup
          label="Sec"
          fieldType="select"
          defaultSelectText="ss"
          options={MinutesOrSeconds}
          {...formData["happeningAtSec"]}
        />
      </FormRow>

      <InputGroup
        label="Priority"
        placeholder="Enter priority"
        {...formData["priority"]}
      />
      {isEditMode && (
        <InputGroup
          label="Status"
          defaultSelectText="Select Status"
          options={statusOptions}
          fieldType="radioGroup"
          {...formData["status"]}
        />
      )}

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        {isEditMode ? "Update" : "Create"}
      </Button>
    </StyledForm>
  );
};

export default Form;
