import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import useAlert from "hooks/useAlert";

import {
  deleteComment as deleteCommentActionCreator,
  editComment as editCommentActionCreator,
} from "common/ActionCreators";

const useComment = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const deleteComment = async ({ commentId }) => {
    try {
      setLoading(true);
      await dispatch(
        deleteCommentActionCreator({ companyId, isAdmin, commentId }),
      );
      showAlert({ message: "Delete comment successful" });
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const editComment = async ({ commentId, message, onSuccess }) => {
    try {
      setLoading(true);
      await dispatch(
        editCommentActionCreator({ companyId, isAdmin, commentId, message }),
      );
      typeof onSuccess == "function" && onSuccess(true);
    } catch (err) {
      showAlert({ message: err, type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return {
    deleteComment,
    editComment,
    isLoading,
  };
};

export default useComment;
