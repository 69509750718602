import React from "react";

import { WeekContainer } from "./style";

const Week = () => {
  return (
    <WeekContainer>
      <span>Sun</span>
      <span>Mon</span>
      <span>Tue</span>
      <span>Wed</span>
      <span>Thu</span>
      <span>Fri</span>
      <span>Sat</span>
    </WeekContainer>
  );
};

export default Week;
