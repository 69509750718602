let cache = {};

const storeInCache = (container, key, value) => {
  cache = {
    ...cache,
    [container]: {
      ...(cache[container] || {}),
      [key]: value,
    },
  };
};

const isInCache = (container, key) => {
  return cache[container] && cache[container][key];
};

export const getDateInLocal = (date) => {
  const d = new Date(String(date));
  return d.toLocaleDateString();
};

const createJoinedString = (value, joinWith = " ") => {
  if (!value) {
    return " ";
  }
  let res = "";
  for (let i = 0; i < value.length; i++) {
    if (value.charCodeAt(i) < 97) {
      res += joinWith + value.charAt(i);
    } else {
      res += value.charAt(i);
    }
  }

  return res;
};

export const replaceCharacter = (value = "", replace = " ", replaceWith = " ") => {
  if (!value) {
    return "";
  }
  const stringArr = value.split(replace);
  return stringArr.join(replaceWith);
};

export const replaceUnderScoreWithSpace = (value) => {
  const cachedValue = isInCache("replaceUnderscore", value);
  if (cachedValue) {
    return cachedValue;
  }
  const result = replaceCharacter(value, "_", " ");
  storeInCache("replaceUnderscore", value, result);
  return result;
};

const removeGroupBy = (value) => {
  if (!value) {
    return "";
  }
  return value.replace("group by", "");
};

export const removeGroupByFromChartTitle = (value) => {
  const cachedValue = isInCache("removeGroupBy", value);
  if (cachedValue) {
    return cachedValue;
  }
  const result = removeGroupBy(value);
  storeInCache("removeGroupBy", value);
  return result;
};

export const getUnderScoreString = (value) => {
  const cachedValue = isInCache("underscore", value);

  if (cachedValue) {
    return cachedValue;
  }
  const result = createJoinedString(value, "_");
  storeInCache("underscore", value, result);
  return result;
};

export const removePrevUnderScoreString = (value = "") => {
  const cachedValue = isInCache("prevUnderscore", value);

  if (cachedValue) {
    return cachedValue;
  }

  if (typeof value !== "string") {
    return value;
  }

  const lastIndex = value.lastIndexOf("_");
  const result = value.substring(lastIndex + 1);
  storeInCache("prevUnderscore", value, result);
  return result;
};

export const getSpacedString = (value) => {
  const cachedValue = isInCache("space", value);

  if (cachedValue) {
    return cachedValue;
  }
  const result = createJoinedString(value, " ");
  storeInCache("space", value, result);
  return result;
};

export const generateTableHeading = (...value) => {
  if (value.length === 0) {
    return "---";
  }
  let result = [];
  for (let j = 0; j < value.length; j++) {
    const cachedValue = isInCache("heading", value[j]);

    if (cachedValue) {
      result.push(cachedValue);
      continue;
    }

    const res = createJoinedString(value[j]);
    storeInCache("heading", value[j], res);

    result.push(res);
  }

  return result;
};

export const arrayToString = (array = []) => {
  if (array.length === 0) {
    return "";
  }
  const itemType = typeof array[0];
  if (itemType === "string") {
    return array.join(", ");
  }

  if (itemType === "object") {
    let result = "";
    array.forEach((arrItem) => {
      Object.keys(arrItem).forEach((key) => {
        const item = arrItem[key];
        result += `
${key}: ${item}`;
      });
      result += `
-------------`;
    });
    return result;
  }
};

export const objectToString = (obj = {}) => {
  let result = "";

  Object.keys(obj).forEach((key) => {
    const item = obj[key];
    result += `
${key}: ${item}`;
  });

  return result;
};

export const getSearchParams = (url = "", key) => {
  if (!url) {
    return null;
  }

  const queryParams = new URL(url).search;
  const searchData = new URLSearchParams(queryParams);
  return searchData.has(key) ? searchData.get(key) : null;
};

export const stringToArray = (text = "", delimeter = ",") => {
  return [...text.split(delimeter)];
};

export const isLink = (url) => {
  if (!url) {
    return false;
  }

  try {
    const urlData = new URL(url);
    return urlData.origin === "null" ? false : true;
  } catch (err) {
    return false;
  }
};

export const getShortString = (
  str,
  index,
  character = " ",
  relaxation = 10
) => {
  if (str.length < index) return str;
  str = str + character;
  const indexOfCharacter = str.indexOf(character, index);
  if (indexOfCharacter < index + relaxation) {
    return `${str.slice(0, indexOfCharacter)}...`;
  } else {
    return `${str.slice(0, index)}... `;
  }
};

export const getNameInitials = (name) => {
  if (typeof name !== "string") {
    return "";
  }

  const arr = name.split(" ");
  return arr.length > 1
    ? `${arr[0].charAt(0)}${arr[arr.length - 1].charAt(0)}`
    : `${arr[0].charAt(0)}`;
};

export const getHowManyMore = (count) => {
  if (count < 1000) {
    return `+${count}`;
  } else if (count >= 1000 && count <= 100000) {
    return `+${Math.floor(count / 1000)}K`;
  } else {
    return `+${Math.floor(count / 100000)}L`;
  }
};

export const textToCamelCase = (value, separator = "_") => {
  return value
    .split(separator)
    .map((text, index) => {
      if (index == 0) {
        return text;
      }
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    })
    .join("");
};

export const getConcatenatedFileName = (fileName, string = "") => {
  // adds string at the end of the file's original name preserving the file extension
  return `${fileName.substring(
    0,
    fileName.lastIndexOf(".")
  )}${string}${fileName.substring(fileName.lastIndexOf("."))}`;
};

export const removeSpaceFromString = (val) => {
  return val.split(" ").reduce((acc, curr) => {
    return acc + curr;
  }, "");
};

export const separateLinksFromText = (textToCheck) => {
  let regex =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{0,256}\.[a-z]{1,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  let match = "";
  let splitText = [];
  let startIndex = 0;
  while ((match = regex.exec(textToCheck)) != null) {
    splitText.push({
      text: textToCheck.substr(startIndex, match.index - startIndex),
      type: "text",
    });

    let cleanedLink = textToCheck.substr(match.index, match[0].length);
    splitText.push({ text: cleanedLink, type: "link" });

    startIndex = match.index + match[0].length;
  }
  if (startIndex < textToCheck.length)
    splitText.push({ text: textToCheck.substr(startIndex), type: "text" });

  return splitText;
};
