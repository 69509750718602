import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  cursor: default;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  align-items: center;
  overflow-x: scroll;
  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.primaryColor};
    border-radius: 10px;
  }
`;

export const Icon = styled.div`
  min-width: 32px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.theme.text.para.medium};
  margin-left: 15px;
  white-space: nowrap;
  color: black;
`;
