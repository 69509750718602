import styled from "styled-components";
import ProfilePic from "../../../ProfilePic";

export const ProfileCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  &:hover > span {
    text-decoration: underline;
  }
`;

export const ProfileCellName = styled.span`
  font-weight: normal;
  // font-size: ${(props) => props.theme.text.para.medium};
  margin-left: 12px;
`;

export const StyledProfilePic = styled(ProfilePic)`
  width: 27px;
  height: 27px;
`;
