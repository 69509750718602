import {
  FETCH_ALL_ATTENDANCE,
  GET_WORKING_SHIFT,
  MARK_SIGN_IN,
  MARK_SIGN_OUT,
  RESET_ATTENDANCE_DATA,
  SEARCH_ATTENDANCE_DATA,
  SET_SEARCH_DATA,
  UPDATE_ATTENDANCE_ID,
  CREATE_WORK_LOG,
  EDIT_WORK_LOG
} from "../actiontypes";

import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnUpdate,
  createPrevData
} from "../../../Helper";

import { FETCH_TODAYS_WORKING_HOUR_LOG } from "common/ActionTypes";

const initialState = createInitialStoreState(
  {
    workingShift: null,
    checkOutStatus: { signIn: false, signOut: false },
    currentLogId: null,
    prevData: {},
    attendanceId: null,
    searchData: {
      startDate: "",
      endDate: "",
      status: ""
    }
  },
  false
);

export const attendanceTableReducer = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_ALL_ATTENDANCE}_SUCCESS`: {
      const { meta } = payload;
      const { checkInOutStatus } = meta || {};
      const { signIn, signOut, todayLogId } = checkInOutStatus || {};
      const d = createNewStoreOnGetData(payload, store, false);
      return {
        ...d,
        checkOutStatus: {
          signIn: Boolean(signIn),
          signOut: Boolean(signOut)
        },
        currentLogId: todayLogId
      };
    }

    case `${SEARCH_ATTENDANCE_DATA}_SUCCESS`: {
      const prevData = createPrevData(store);
      const d = createNewStoreOnGetData(payload, store, false);
      return {
        ...d,
        prevData
      };
    }

    case `${GET_WORKING_SHIFT}_SUCCESS`: {
      const { data, dataIds } = payload;
      const res = dataIds.map((id) => {
        const { attributes } = data[id] || {};
        return attributes;
      });
      return {
        ...store,
        workingShift: res[0]
      };
    }

    case `${MARK_SIGN_IN}_SUCCESS`: {
      const { meta } = payload;
      const { checkInOutStatus } = meta || {};
      const { signIn, signOut, todayLogId } = checkInOutStatus || {};
      const d = createNewStoreOnCreate(payload, store, false, true);
      return {
        ...d,
        checkOutStatus: {
          signIn: Boolean(signIn),
          signOut: Boolean(signOut)
        },
        currentLogId: todayLogId
      };
    }

    case `${MARK_SIGN_OUT}_SUCCESS`: {
      const { meta } = payload;
      const { checkInOutStatus } = meta || {};
      const { signIn, signOut, todayLogId } = checkInOutStatus || {};
      const d = createNewStoreOnUpdate(payload, store);
      return {
        ...d,
        checkOutStatus: {
          signIn: Boolean(signIn),
          signOut: Boolean(signOut)
        },
        currentLogId: todayLogId
      };
    }

    case SET_SEARCH_DATA: {
      const { startDate, endDate, status } = payload;
      return {
        ...store,
        searchData: {
          startDate,
          endDate,
          status
        }
      };
    }

    case RESET_ATTENDANCE_DATA: {
      return {
        ...store,
        ...store.prevData,
        prevData: {},
        searchData: {
          startDate: "",
          endDate: "",
          status: ""
        }
      };
    }

    case UPDATE_ATTENDANCE_ID: {
      return {
        ...store,
        attendanceId: payload.attendanceId
      };
    }

    case `${EDIT_WORK_LOG}_SUCCESS`:
    case `${CREATE_WORK_LOG}_SUCCESS`: {
      const d = createNewStoreOnUpdate(payload, store);
      return {
        ...d
      };
    }

    case `${FETCH_TODAYS_WORKING_HOUR_LOG}_SUCCESS`: {
      const { meta } = payload;
      const { checkInOutStatus } = meta || {};
      const { signIn, signOut, todayLogId } = checkInOutStatus || {};
      return {
        ...store,
        checkOutStatus: {
          signIn: Boolean(signIn),
          signOut: Boolean(signOut)
        },
        currentLogId: todayLogId
      };
    }

    default:
      return store;
  }
};
