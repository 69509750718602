import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import TabsRenderer from "./TabsRenderer";
import DrawerLink from "../DrawerLink";
import { generateDrawerLinks } from "helper";

import Profile_Img from "assets/Profile.svg";
import E_Profile_Img from "assets/employee/Profile.svg";

const [ProfileTabLinks, ProfileLinkComponents] =
  generateDrawerLinks(
    Routes.authorized.profile,
    AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
    DrawerLink
  );

export const ProfileTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType(
        "profile_section"
      )}
    >
      {/* <DrawerTitle title="Profile Section" /> */}
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("profile")}
        image={isAdmin ? Profile_Img : E_Profile_Img}
        title="Profile"
        links={ProfileTabLinks}
      >
        {ProfileLinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
