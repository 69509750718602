import React, { useCallback, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup";
import Icon from "components/Icon";
import Button from "components/Button";
import useAlert from "hooks/useAlert";

import { createEmergencyContact } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { StyledFormGroup } from "globalStyles";
import { FormHeading, Header } from "../../style/create";
import { generateFormData } from "../../utils";
import { validateMobile } from "helper";
import { relationshipOptions } from "./options";

const Form = ({
  setIsFormVisible,
  createEmergencyContact,
  isAdmin,
  companyId,
}) => {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const data = generateFormData(values);
        await createEmergencyContact({ companyId, isAdmin, formData: data });
        showAlert({ message: `Emergency Contact Detail Created` });
        resetForm();
        setIsFormVisible(false);
      } catch (err) {
        showAlert({
          message: "Emergency Contact Create Failed",
          type: "error",
        });
      } finally {
        updateLoading(false);
      }
    },
    [companyId]
  );

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Add new Emergency Contact</FormHeading>
        <Icon name="fas fa-times" onClick={updateFormVisibilty} />
      </Header>

      <InputGroup
        id="emergencyContactCreate_relationship"
        fieldType="select"
        required
        options={relationshipOptions}
        defaultSelectText="Select Relationship"
        label="Relationship"
      />

      <InputGroup
        id="emergencyContactCreate_name"
        fieldType="input"
        required
        placeholder="Enter Name"
        label="Name"
      />

      <InputGroup
        id="emergencyContactCreate_phone"
        inputMode="tel"
        required
        placeholder="Enter Phone Number"
        label="Phone Number"
        validator={validateMobile}
        invalidMessage="Enter a valid Phone number"
      />

      <InputGroup
        id="emergencyContactCreate_address"
        fieldType="textArea"
        required
        placeholder="Enter your Address"
        label="Address"
        size="lg"
      />

      <InputGroup
        id="emergencyContactCreate_note"
        fieldType="textArea"
        placeholder="Note"
        label="Note"
        size="lg"
      />

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { companyId, isAdmin };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createEmergencyContact,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
