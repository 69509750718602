import {
  FETCH_POSTS,
  CREATE_POST,
  CREATE_POLL,
  CREATE_QUIZ,
  CREATE_POST_COMMENT,
  REACT_TO_POST,
  REMOVE_REACT_FROM_POST,
  ANSWERE_TO_QUIZ,
  ANSWERE_TO_POLL,
  DELETE_POST
} from "./actionTypes";
import {
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnUpdate,
  createNewStoreOnDelete
} from "helper";

import { DELETE_COMMENT, EDIT_COMMENT } from "common/ActionTypes";

const initialState = createInitialStoreState({}, true);

function PostsReducer(store = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_POSTS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    case `${CREATE_POST}_SUCCESS`:
    case `${CREATE_POLL}_SUCCESS`:
    case `${CREATE_QUIZ}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true, true);
    }

    case `${REMOVE_REACT_FROM_POST}_SUCCESS`:
    case `${CREATE_POST_COMMENT}_SUCCESS`:
    case `${DELETE_COMMENT}_SUCCESS`:
    case `${EDIT_COMMENT}_SUCCESS`:
    case `${REACT_TO_POST}_SUCCESS`:
    case `${ANSWERE_TO_QUIZ}_SUCCESS`:
    case `${ANSWERE_TO_POLL}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store, true);
    }

    case `${DELETE_POST}_SUCCESS`:
      return createNewStoreOnDelete(payload, store, true);
    default:
      return store;
  }
}

export default PostsReducer;
