import React from "react";
import { HalfHeightContainer, StyledIcon, StyledParagraph } from "../styles";

const SuccessfulPage = () => {
  return (
    <>
      <HalfHeightContainer>
        <StyledIcon name="fas fa-check-circle" className="fa-5x" />
      </HalfHeightContainer>
      <HalfHeightContainer>
        <StyledParagraph>
          If your email exists in our database, you’ll receive a password
          recovery link at your email address in a few minutes.
        </StyledParagraph>
      </HalfHeightContainer>
    </>
  );
};
export default SuccessfulPage;
