import React, { useCallback } from "react";
import useInput from "../useInput";
import CheckBox from "./CheckBox";

import { CheckBoxGroupContainer } from "./style";

const CheckBoxGroup = ({
  horizontal = true,
  options = [],
  groupName,
  value,
  onChange,
  className = "",
  name,
  ...otherprops
}) => {
  const { restInputProps } = useInput(otherprops);

  const onToggle = useCallback(
    (e) => {
      const currValue = e.target.value;
      const obj = {
        [currValue]: e.target.checked,
      };

      let newValue;
      if (!value) {
        newValue = obj;
      } else if (value[currValue]) {
        newValue = { ...value };
        delete newValue[currValue];
      } else {
        newValue = { ...value, ...obj };
      }

      if (Object.keys(newValue).length === 0) {
        newValue = null;
      }

      onChange({ target: { value: newValue, name } });
    },
    [value, name]
  );

  return (
    <CheckBoxGroupContainer className={className} horizontal={horizontal}>
      {options.map((item) => {
        return (
          <CheckBox
            key={item.value}
            name={groupName}
            value={item.value}
            label={item.name}
            disabled={item.disabled || false}
            isChecked={value ? value[item.value] : false}
            onChange={onToggle}
            style={item.style}
            {...restInputProps}
          />
        );
      })}
    </CheckBoxGroupContainer>
  );
};

export default CheckBoxGroup;
