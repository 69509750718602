import { getCurrentDataAttributes } from "helper/selectors";
import { createSelector } from "reselect";

const workshiftDetailIds = (store) => store.workshift.details.allIds;
const workshiftDetailData = (store) => store.workshift.details.allData;
const workshiftDetailRelation = (store) => store.workshift.details.allRelation;

export const getAllWorkshiftDetails = createSelector(
  workshiftDetailIds,
  workshiftDetailData,
  (ids, data) =>
    ids && data && ids.map((id) => ({ ...data[id].attributes, id }))
);
export const workshiftDetailDataSelector = createSelector(
  workshiftDetailData,
  workshiftDetailRelation,
  (_, props) => props.id,
  (data, relation, id) => {
    return data[id] ? getCurrentDataAttributes(data[id], relation, data) : {};
  }
);
