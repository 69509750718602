import { useCallback, useState } from "react";

import { updateUserStatus } from "modules/User/actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { useDispatch, useSelector } from "react-redux";
import useAlert from "hooks/useAlert";
import { reducerStore } from "types/common";

interface useStatusUpdateProps {
  userId: string;
}

export const useStatusUpdate = ({ userId }: useStatusUpdateProps) => {
  const [loading, setLoading] = useState(false);
  const companyId = useSelector((store: reducerStore) =>
    getCurrentUserCompany(store, null)
  );
  const isAdmin = useSelector((store: reducerStore) =>
    isCurrentUserIsExecutive(store, null)
  );
  const dispatch = useDispatch();
  const { showAlert } = useAlert();

  const onClick = useCallback(
    async (active) => {
      try {
        let proceed = true;
        setLoading(true);
        if (active) {
          proceed = window.confirm(
            "Are you sure you want to deactivate this member?"
          );
        }

        proceed &&
          (await dispatch(
            updateUserStatus({ companyId, isAdmin, userId, status: !active })
          ));
      } catch (error) {
        showAlert({ message: "Failed to change user status", type: "error" });
      } finally {
        setLoading(false);
      }
    },
    [companyId, isAdmin, userId]
  );
  return {
    loading,
    onClick,
  };
};
