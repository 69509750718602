import React, { useEffect, useMemo, useCallback, useState } from "react";
import { connect } from "react-redux";

import Grid from "components/Grid";

import { createTableDataSelector } from "common/Selectors/MTable";
import RenderItem from "./RenderItem";
import { StyledTableContainer } from "../../style/display";
import { calculateMaxLength } from "../../../../Helper";
import Modal from "components/Modal";
import StatusUpdateForm from "./StatusUpdateForm";

const TableData = ({
  data,
  cellNameToColumnMap,
  onEmptyData,
  rowIdMap,
  ...other
}) => {
  const [currentId, setCurrentId] = useState(null);
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);

  const maxStatusLength = useMemo(() => {
    return calculateMaxLength(cellNameToColumnMap, data);
  }, [data]);

  const {} = useMemo(() => {
    return {};
  }, [currentId]);

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (rows === 1) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);

  const toggleModal = ({ id }) => {
    setCurrentId(id ? id?.toString() : null);
  };

  return (
    <StyledTableContainer {...other}>
      <Grid
        rows={rows}
        columns={cols}
        columnWidth={200}
        rowHeight={60}
        data={{
          rowData: data,
          cellNameToColumnMap,
          toggleModal,
          maxStatusLength: maxStatusLength,
        }}
        dataKey="rowData"
      >
        {RenderItem}
      </Grid>
      <Modal
        heading="Update Status"
        closeModal={toggleModal}
        active={!!currentId}
      >
        <StatusUpdateForm currentId={currentId} toggleModal={toggleModal} />
      </Modal>
    </StyledTableContainer>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "ledger.transactions",
    blackList: { "Sl No": true },
    reverse: false,
    columnOrder: [6, 0, 1, 2, 3, 4, 5],
  });
  const maker = (store, props) => {
    const { rows, cellNameToColumnMap, rowIdMap } = getUserRows(store, props);
    return {
      data: rows,
      cellNameToColumnMap,
      rowIdMap,
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
