import { createSelector } from "reselect";

const countdownsDataIds = (store) => store.countdown.allIds;
const countdownsData = (store) => store.countdown.allData;

export const getAllCountdowns = createSelector(
  countdownsDataIds,
  countdownsData,
  (ids, data) =>
    ids && data && ids.map((id) => ({ ...data[id].attributes, id }))
);
export const countdownDataSelector = createSelector(
  countdownsData,
  (_, props) => props.id,
  (data, id) => {
    return data[id] ? data[id].attributes : {};
  }
);
