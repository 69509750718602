import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import useAlert from "hooks/useAlert";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";
import { generateFormData } from "../../utils";
import { createNotice } from "../../actionCreator";
import { StyledFormGroup, StyledInputGroups } from "../../../../GlobalStyles";
import {
  Header,
  FormHeading,
  TextAreaLengthCont,
  NoticeDescriptionInputGroup,
} from "../../style/create";
import InputGroup from "components/InputGroup";
import Button from "components/Button";
import Icon from "components/Icon";

const Form = ({
  updateIsFormSubmitted,
  updateFormVisibility,
  companyId,
  isAdmin,
  createNotice,
}) => {
  const [textAreaValue, updateTextAreaValue] = useState("");
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const slicedNoticeDescription = useMemo(
    () => textAreaValue.slice(0, 1500),
    [textAreaValue]
  );

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const data = generateFormData(values);
        await createNotice({ companyId, isAdmin, formData: data });
        showAlert({ message: "Notice Created" });
        resetForm();
        updateTextAreaValue("");
        updateIsFormSubmitted(true);
      } catch (err) {
        showAlert({ message: "Notice creation failed", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [companyId]
  );

  const onTextAreaChange = useCallback((event) => {
    updateTextAreaValue(event.target.value);
  }, []);

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Create Notice</FormHeading>
        {!isAdmin && (
          <Icon name="fas fa-times" onClick={updateFormVisibility} />
        )}
      </Header>
      <StyledInputGroups>
        <InputGroup
          id="noticeCreate_noticeTitle"
          fieldType="input"
          label="Notice title"
          placeholder="Your notice title"
          required
        />
      </StyledInputGroups>
      <NoticeDescriptionInputGroup>
        <InputGroup
          id="noticeCreate_noticeDescription"
          fieldType="textArea"
          label="Notice Description"
          size="auto"
          fillParent={true}
          placeholder="Add description here"
          value={slicedNoticeDescription}
          onChange={onTextAreaChange}
        />
      </NoticeDescriptionInputGroup>
      <TextAreaLengthCont>
        {slicedNoticeDescription.length}/1500 characters
      </TextAreaLengthCont>

      <InputGroup
        id="noticeCreate_noticeDocuments"
        fieldType="googleFilePicker"
        multiple={true}
        label="Documents"
      />

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create&nbsp; <Icon name="fas fa-arrow-right" />
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    isAdmin,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createNotice }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Form);
