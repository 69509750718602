import { useEffect, useMemo } from "react";
import { getOncefetched } from "common/Selectors/store";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { createTableDataSelector } from "common/Selectors/MTable";

import usePaginator from "hooks/usePaginator";
import usePagination from "hooks/usePagination";

import { fetchAllReactions } from "../redux/AllReactions/actionCreator";
import { useSelector } from "react-redux";
import { transformCollaboratorMatrixToArray } from "../../../../../../Modules/WelcomePage/utils";

export function useFetchAllReactions(postId, reactionsType) {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    allPagesFetched,
    errorOccured,
  } = usePagination({
    actionCreator: fetchAllReactions,
    tableName: `postReactions.${reactionsType}`,
  });

  const onceFetchedSelector = getOncefetched(`postReactions.${reactionsType}`);
  const allReactionSelector = createTableDataSelector({
    tableName: `postReactions.${reactionsType}`,
    blackList: { "Sl No": true },
    fetchAllData: true,
  });

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const isOnceFetched = useSelector(onceFetchedSelector);
  const { rows: data, rowIdMap } = useSelector(allReactionSelector);
  const reactionsCount = useSelector(
    (store) => store.postReactions?.total?.reactionsCount
  );

  const { fetchFirstPage, fetchNextPage } = usePaginator({
    paginatorApi: getNextPage,
    extraParams: {
      companyId,
      isAdmin,
      reactableId: postId,
      reactableType: "Post",
      reactionsType: reactionsType,
    },
  });

  useEffect(() => {
    if (!isOnceFetched) {
      fetchFirstPage();
    }
  }, [isOnceFetched]);

  const allReactions = useMemo(() => {
    const allData = transformCollaboratorMatrixToArray(data, rowIdMap);
    return allData.reverse();
  }, [data]);

  return {
    isLoading: loadingNextPage || loadingPrevPage,
    allReactions,
    fetchNextPage,
    allDataFethced: allPagesFetched,
    hasError: errorOccured,
    reactionsCount,
  };
}
