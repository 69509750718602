export const CREATE_NOTICE = "CREATE_NOTICE";
export const PUBLISH_NOTICE = "PUBLISH_NOTICE";
export const GET_SENT_NOTICES = "GET_SENT_NOTICES";
export const GET_DRAFT_NOTICES = "GET_DRAFT_NOTICES";
export const GET_EMPLOYER_NOTICES = "GET_EMPLOYER_NOTICES";
export const UPDATE_DRAFT_NOTICE = "UPDATE_DRAFT_NOTICE";
export const SET_NOTICE_ID = "SET_NOTICE_ID";
export const CLEAR_NOTICE_ID = "CLEAR_NOTICE_ID";
export const GET_SPECIFIC_NOTICE = "GET_SPECIFIC_NOTICE";
export const SEARCH_NOTICES = "SEARCH_NOTICES";
export const UPDATE_SEARCH_PARAMS = "UPDATE_NOTICE_SEARCH_PARAMS";
export const RESET_SEARCH_PARAMS = "RESET_NOTICE_SEARCH_PARAMS";
export const DELETE_NOTICE = "DELETE_NOTICE";
