export const ApproveStatusOption = [
  { name: "Approve Attendance", value: "approved" }
];

export const RejectStatusOption = [
  { name: "Reject Attendance", value: "rejected" }
];

export const PendingStatusOption = [
  ...ApproveStatusOption,
  ...RejectStatusOption
];
