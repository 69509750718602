import requestData from "../../../../../ApiHandler";
import { createAuthUrl } from "helper";

export const createLeaveApi = ({ companyId, formData, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborator_leave_plans`,
  });

  let collaborator_leave_plan = {
    collaborator_id: formData.collaborator_id,
    leave_plan_id: formData.leave_plan_id,
    valid_from: formData.valid_from,
    valid_to: formData.valid_to,
    assigned_quota: formData.assigned_quota,
  };

  if (!!formData.max_carry) {
    collaborator_leave_plan["maximum_carry_forward"] = formData.max_carry;
  }

  return requestData(authUrl, {
    method: "POST",
    body: {
      collaborator_leave_plan: collaborator_leave_plan,
    },
    isFormData: true,
  });
};

export const getAssignedLeavesApi = ({ pageNumber, companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborator_leave_plans?page=${pageNumber}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const getActiveLeaveTypesApi = ({ pageNumber, companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/fetch_leave_plan_by_status?page=${pageNumber}&status=active`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const editLeaveApi = ({
  companyId,
  assignedLeaveId,
  formData,
  isAdmin,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborator_leave_plans/${assignedLeaveId}`,
  });

  let collaborator_leave_plan = {
    valid_from: formData.valid_from,
    valid_to: formData.valid_to,
    assigned_quota: formData.assigned_quota,
  };

  if (!!formData.max_carry) {
    collaborator_leave_plan["maximum_carry_forward"] = formData.max_carry;
  }

  return requestData(authUrl, {
    method: "PUT",
    body: {
      collaborator_leave_plan: collaborator_leave_plan,
    },
    isFormData: true,
  });
};

export const deleteLeaveApi = ({ assignedLeaveId, companyId, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/collaborator_leave_plans/${assignedLeaveId}`,
  });
  return requestData(url, {
    method: "DELETE",
  });
};
