import React from "react";
import Form from "./Form";
import { MainContainer } from "../../style/create";

const HelpdeskCreateView = ({ setIsFormVisible }) => {
  return (
    <MainContainer>
      <Form setIsFormVisible={setIsFormVisible} />
    </MainContainer>
  );
};

export default HelpdeskCreateView;
