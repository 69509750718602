import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { PaginatorBox } from "../../../../GlobalStyles";
import { EnhancedActionArea } from "../../style/display";
import Paginator from "components/Paginator";
import usePagination from "../../../../Hooks/usePagination";

import {
  getBreaks,
  getCurrentCollaboratorBreaks,
  getSubordinateBreaks,
} from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";
import Button from "../../../../Common/Components/Button";
import { BackButton } from "../../style/display";
import Icon from "../../../../Common/Components/Icon";

const ActionArea = ({
  companyId,
  isAdmin,
  subordinateId,
  onLoading,
  style,
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: isAdmin
      ? getBreaks
      : subordinateId
      ? getSubordinateBreaks
      : getCurrentCollaboratorBreaks,
    tableName: isAdmin
      ? "breaks.snaps"
      : subordinateId
      ? "breaks.subordinateSnaps"
      : "breaks.collaboratorSnaps",
  });
  const history = useHistory();
  const fetchFirstPage = useCallback(() => {
    getNextPage({
      isFirstPage: true,
      extraParams: { companyId, isAdmin, subordinateId },
    });
  }, [getNextPage, companyId, isAdmin, subordinateId]);

  const fetchPrevPage = useCallback(() => {
    getNextPage({ extraParams: { companyId, isAdmin, subordinateId } });
  }, [getNextPage]);

  const fetchNextPage = useCallback(() => {
    getNextPage({ extraParams: { companyId, isAdmin, subordinateId } });
  }, [getNextPage, companyId, isAdmin, subordinateId]);

  const fetchSpecificPage = useCallback(
    (pageNumber) => {
      getNextPage({
        pageNumber,
        extraParams: { companyId, isAdmin, subordinateId },
      });
    },
    [getNextPage, companyId, isAdmin, subordinateId]
  );
  useEffect(() => {
    fetchFirstPage();
  }, []);

  const onBackPress = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (loadingNextPage) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [loadingNextPage]);

  return (
    <EnhancedActionArea>
      {!subordinateId && !loadingNextPage && (
        <BackButton>
          <Button outline size="full" onClick={onBackPress}>
            <Icon name="fas fa-arrow-left" /> &nbsp;Back
          </Button>
        </BackButton>
      )}
      <PaginatorBox style={style}>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    isAdmin,
    subordinateId: store.breaks.subordinateSnaps.collaboratorId,
  };
};

export default connect(mapStateToProps, null)(ActionArea);
