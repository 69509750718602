import AttendanceListBox from "components/AttendanceListBox";
import { ListContainer } from "modules/Teams/style/display";
import React from "react";

const TeamMembersList = ({
  resetMembersData,
  initialLoading,
  loading,
  errorOccured,
  onEndReached,
  onceFetched,
  listCount,
  MockData,
  list,
}) => {
  return (
    <ListContainer>
      <AttendanceListBox
        headingText="Members"
        onEndReached={onEndReached}
        initialLoading={initialLoading}
        loading={loading}
        errorOccured={errorOccured}
        data={!onceFetched ? MockData : list}
        refreshData={resetMembersData}
        listCount={listCount}
      />
    </ListContainer>
  );
};

export default TeamMembersList;
