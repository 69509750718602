export const generateTableData = (data, cellNameToColumnMap, isExecutive) => {
  const titleColumnNo = cellNameToColumnMap["title"];
  const SlNoCoulmnNo = cellNameToColumnMap["Sl No"];
  const bodyColumnNo = cellNameToColumnMap["body"];
  const publishColumnNo = cellNameToColumnMap["published"];
  const sentToColumnNo = cellNameToColumnMap["sentTo"];
  const sentToCollabColNo = cellNameToColumnMap["sentToCollaborators"];
  const documentColNo = cellNameToColumnMap["documentUrls"];

  let i;

  const tableData = [];
  for (i = 1; i < data.length; i++) {
    let dateTimeColumnNo;
    const published = data[i][publishColumnNo];
    if (published === "NA") dateTimeColumnNo = cellNameToColumnMap["updatedAt"];
    else dateTimeColumnNo = cellNameToColumnMap["publishedOn"];
    const noticeId = data[i][SlNoCoulmnNo];
    const noticeTitle = data[i][titleColumnNo];
    const noticeBody = data[i][bodyColumnNo];
    const time = getTime(data[i][dateTimeColumnNo]);
    const date = getDate(data[i][dateTimeColumnNo]);

    const documents = data[i][documentColNo];

    const newSubArray = {
      noticeId,
      noticeTitle,
      noticeBody,
      time,
      date,
      documents,
    };

    newSubArray.published = published;
    if (published == true) {
      const sentTo = data[i][sentToColumnNo];
      newSubArray.sentTo = sentTo;
      let sentToCollaborators;
      if (sentTo.toLowerCase() === "few") {
        sentToCollaborators = data[i][sentToCollabColNo];
      } else {
        sentToCollaborators = [];
      }
      newSubArray.sentToCollaborators = sentToCollaborators;
    }
    tableData.push(newSubArray);
  }
  return tableData;
};

const getTime = (str) => {
  try {
    const time = str.slice(str.indexOf(" ") + 1);
    return (time.slice(0, -2) + " " + time.slice(-2)).toLowerCase();
  } catch (err) {
    return "NA";
  }
};

const getDate = (str) => {
  const monthsArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  try {
    const indexOfFirstHyphen = str.indexOf("-");
    const indexOfSecondHyphen = str.indexOf("-", indexOfFirstHyphen + 1);
    const indexOfSpace = str.indexOf(" ");
    const monthCode = parseInt(
      str.slice(indexOfFirstHyphen + 1, indexOfSecondHyphen)
    );
    const year = str.slice(0, indexOfFirstHyphen);
    const month = monthsArray[monthCode - 1];
    const day = str.slice(indexOfSecondHyphen + 1, indexOfSpace);
    return day + " " + month + ", " + year;
  } catch (err) {
    return "NA";
  }
};

export const generateFormData = (values, fileList) => {
  const {
    noticeCreate_noticeTitle,
    noticeCreate_noticeDescription,
    noticeCreate_noticeDocuments,
  } = values;

  let formData = {
    title: noticeCreate_noticeTitle,
    body: noticeCreate_noticeDescription,
  };

  if (noticeCreate_noticeDocuments && noticeCreate_noticeDocuments.length > 0)
    formData.documents = noticeCreate_noticeDocuments;

  return formData;
};

export const generatePublishList = (values) => {
  const { noticePublish_noticeSendTo, noticePublish_noticeEmail } = values;
  return {
    sent_to: noticePublish_noticeSendTo,
    email: noticePublish_noticeEmail,
  };
};
