import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";

import Button from "components/Button";
import Paginator from "../../../../Common/Components/Paginator";
import useForm from "../../../../Hooks/useForm";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";

import usePagination from "../../../../Hooks/usePagination";
import {
  getAllSeparation,
  searchSeparation,
  resetTableData,
  setSearchData,
} from "../../actionCreator";
import { extractDateInYYYYMMDD } from "helper";

import ActionForm from "./ActionForm";

import { StyledPaginatorCont, StyledActionArea } from "../../style/display";
import { bindActionCreators } from "redux";

const ActionArea = ({
  onceFetched,
  companyId,
  isAdmin,
  resetTableData,
  setSearchData,
  searchData,
  onLoading,
  toggleForm,
  applyActiveStatus,
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: getAllSeparation,
    tableName: "separation",
  });

  const tempDataRef = useRef({ isSearchOn: false });

  const { formData, isFormValid, values, resetForm, updateField } = useForm({
    fields: {
      user: {
        required: true,
      },
      startDate: {
        required: true,
      },
      endDate: {
        required: true,
      },
      status: {
        required: true,
      },
    },
  });

  const fetchFirstPage = useCallback(
    (startDate, endDate) => {
      getNextPage({
        isFirstPage: true,
        newActionCreator: tempDataRef.current.isSearchOn && searchSeparation,
        extraParams: {
          companyId,
          isAdmin,
          startDate: extractDateInYYYYMMDD(values.startDate),
          endDate: extractDateInYYYYMMDD(values.endDate),
          status: values.status,
          userId: values.user && Object.keys(values.user)[0],
        },
      });
    },
    [
      getNextPage,
      companyId,
      isAdmin,
      values.startDate,
      values.endDate,
      values.status,
      values.user,
    ]
  );

  const fetchNextPage = useCallback(() => {
    getNextPage({
      newActionCreator: tempDataRef.current.isSearchOn && searchSeparation,
      extraParams: {
        companyId,
        isAdmin,
        startDate: extractDateInYYYYMMDD(values.startDate),
        endDate: extractDateInYYYYMMDD(values.endDate),
        status: values.status,
        userId: values.user && Object.keys(values.user)[0],
      },
    });
  }, [
    getNextPage,
    companyId,
    isAdmin,
    values.startDate,
    values.endDate,
    values.status,
    values.user,
  ]);

  const fetchPrevPage = useCallback(() => {
    getNextPage({
      fetchPrevPage: true,
      newActionCreator: tempDataRef.current.isSearchOn && searchSeparation,
      extraParams: {
        companyId,
        isAdmin,
        startDate: extractDateInYYYYMMDD(values.startDate),
        endDate: extractDateInYYYYMMDD(values.endDate),
        status: values.status,
        userId: values.user && Object.keys(values.user)[0],
      },
    });
  }, [
    getNextPage,
    companyId,
    isAdmin,
    values.startDate,
    values.endDate,
    values.status,
    values.user,
  ]);

  const fetchSpecificPage = useCallback(
    (pageNumber) => {
      getNextPage({
        pageNumber,
        extraParams: {
          companyId,
          isAdmin,
          startDate: extractDateInYYYYMMDD(values.startDate),
          endDate: extractDateInYYYYMMDD(values.endDate),
          status: values.status,
          userId: values.user && Object.keys(values.user)[0],
        },
      });
    },
    [
      getNextPage,
      companyId,
      isAdmin,
      values.startDate,
      values.endDate,
      values.status,
      values.user,
    ]
  );

  useEffect(() => {
    if (onceFetched) {
      updateField("startDate", searchData.startDate);
      updateField("endDate", searchData.endDate);
      updateField("status", searchData.status);
      updateField("user", searchData.user);
    } else {
      fetchFirstPage();
    }
  }, []);

  const onSearch = useCallback(
    (e) => {
      e.preventDefault();
      if (!isFormValid()) {
        return;
      }
      tempDataRef.current.isSearchOn = true;
      fetchFirstPage();
      setSearchData({
        startDate: extractDateInYYYYMMDD(values.startDate),
        endDate: extractDateInYYYYMMDD(values.endDate),
        status: values.status,
        user: values.user,
      });
    },
    [fetchFirstPage, values.startDate, values.endDate, values.status]
  );

  useEffect(() => {
    if (typeof onLoading !== "function" || isAdmin) {
      return;
    }

    if (!onceFetched && !tempDataRef.current.isSearchOn) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  const onReset = () => {
    resetTableData();
    resetForm();
    tempDataRef.current.isSearchOn = false;
  };

  return (
    <StyledActionArea>
      {isAdmin && (
        <ActionForm
          formData={formData}
          onSearch={onSearch}
          resetForm={onReset}
        />
      )}

      {!isAdmin && (
        <Button
          disabled={applyActiveStatus}
          style={{ marginRight: "auto" }}
          onClick={toggleForm}
          primary
        >
          Apply
        </Button>
      )}
      <StyledPaginatorCont>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </StyledPaginatorCont>
    </StyledActionArea>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetTableData,
      setSearchData,
    },
    dispatch
  );

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const searchData = store.separation.searchData;
  return {
    companyId,
    onceFetched: store.separation.onceFetched,
    isAdmin,
    searchData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionArea);
