import React, { useMemo } from "react";
import { createTableDataSelector } from "common/Selectors/MTable";

import {
  DetailsContainer,
  Dot,
  Header,
  Item,
  TabContainer,
} from "../../../style/display";
import {
  getCurrentIndexRowDataAndColumn,
  transformToMemberList,
} from "../../../utils";
import { getTeamDepartments, getTeamOwners } from "../../../selector";
import MultipleUsers from "../../../../../Common/Components/MultipleUsers";
import { useMembersViewPagination } from "../../../Hooks/useMembersViewPagination";
import { MockData } from "../../../../Attendence/constants";
import { useSelector } from "react-redux";
import { getOncefetched } from "common/Selectors/store";
import { useTabs } from "hooks/useTabs";
import Worklog from "../Worklog";
import Tabs from "components/Tabs";
import TeamMembersList from "./TeamMembersList";
import PageBackHeader from "components/PageBackHeader";

const onceFetchedSelector = getOncefetched("teams.members");
const getOwnerUserRows = createTableDataSelector({
  tableName: "teams.owners",
  reverse: false,
  blackList: { "Sl No": true },
});

const getMemberUserRows = createTableDataSelector({
  tableName: "teams.members",
  blackList: { "Sl No": true },
  reverse: false,
  fetchAllData: true,
});

const EmployeeDetailView = ({
  data: info,
  resetMembersData,
  teamId,
  onBack,
}) => {
  const { rowIndex } = info;

  const { initialLoading, loading, errorOccured, onEndReached } =
    useMembersViewPagination();

  const { rows, cellNameToColumnMap } = useSelector(getOwnerUserRows);

  const { rows: memberRows, cellNameToColumnMap: memberCellName } =
    useSelector(getMemberUserRows);

  const { data = [] } = useMemo(
    () => getCurrentIndexRowDataAndColumn(rows, rowIndex),
    [rows, rowIndex]
  );

  const list = useMemo(
    () => transformToMemberList(memberRows, memberCellName),
    [memberRows, memberCellName]
  );

  const onceFetched = useSelector(onceFetchedSelector);
  const listCount = useSelector((store) => store.teams.members.count);

  const teamOwners = useSelector(getTeamOwners);
  const teamDepartments = useSelector(getTeamDepartments);

  const isDepartmentValid = useMemo(
    () => teamDepartments && Object.values(teamDepartments).length > 0,
    [teamDepartments]
  );
  const { CurrentTab: EmployeeSiderTab } = useTabs({
    allTabs: [TeamMembersList, Worklog],
    data: {
      data,
      resetMembersData,
      initialLoading,
      loading,
      errorOccured,
      onEndReached,
      onceFetched,
      listCount,
      MockData,
      list,
      teamId,
    },
  });
  return (
    <DetailsContainer>
      <Header>
        <PageBackHeader onBack={onBack} />
        <Item>Team Name: {data[cellNameToColumnMap?.name]}</Item>
        <Dot />
        {isDepartmentValid && (
          <>
            <Item>{Object.values(teamDepartments)[0].heading}</Item>
            <Dot />
          </>
        )}
        <Item style={{ cursor: "pointer" }}>
          <MultipleUsers data={Object.values(teamOwners)} />
        </Item>
      </Header>
      <TabContainer>
        <Tabs tabNames={[`Members`, "Worklog"]}>{EmployeeSiderTab}</Tabs>
      </TabContainer>
    </DetailsContainer>
  );
};

export default EmployeeDetailView;
