import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup/inputGroup2";
import Icon from "components/Icon";
import useForm from "hooks/useForm";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import AcademicForm from "./Forms/AcademicForm";
import TrainingForm from "./Forms/TrainingForm";
import ProfessionalForm from "./Forms/ProfessionalForm";
import useQualifcationDetails from "./useQualificationDetails";
import { qualificationsOptions } from "./options";

import { FormContainer, FormHeading, Header } from "../../style/create";
import { isDateCombinationValid } from "helper";

const Form = ({ setIsFormVisible, companyId, isAdmin }) => {
  const { formData, isFormValid, values, resetForm } = useForm({
    fields: {
      qualificationType: {
        required: true,
      },
    },
  });

  const {
    formData: academicFormData,
    isFormValid: academicFormValidity,
    values: academicFormValues,
    resetForm: academicFormReset,
  } = useForm({
    fields: {
      course: {
        required: true,
      },
      education: {
        required: true,
      },
      grade: {
        required: true,
      },
      institution: {
        required: true,
      },
      specialization: {},
      yearOfPassing: {
        required: true,
      },
      degreeDocument: {
        required: true,
      },
    },
  });

  const dateValidator = useCallback((date, values) => {
    if (values?.workingFrom || values?.startDate) {
      const { isValid } = isDateCombinationValid(
        values?.workingFrom || values?.startDate,
        date
      );
      return isValid;
    }
  }, []);

  const {
    formData: professionalFormData,
    isFormValid: professionalFormValidity,
    values: professionalFormValues,
    resetForm: professionalFormReset,
  } = useForm({
    fields: {
      designation: {
        required: true,
      },
      jobDescription: {},
      organization: {
        required: true,
      },
      workingFrom: {
        required: true,
      },
      workedTill: {
        required: true,
        validator: dateValidator,
        invalidMessage: "working till should be greater",
      },
      employmentDocument: {
        required: true,
      },
    },
  });

  const {
    formData: trainingFormData,
    isFormValid: trainingFormValidity,
    values: trainingFormValues,
    resetForm: trainingFormReset,
  } = useForm({
    fields: {
      name: {
        required: true,
      },
      nature: {
        required: true,
      },
      grade: {
        required: true,
      },
      startDate: {
        required: true,
      },
      endDate: {
        required: true,
        validator: dateValidator,
        invalidMessage: "end date should be greater",
      },
      institution: {
        required: true,
      },
      location: {
        required: true,
      },
      certificateDocument: {
        required: true,
      },
    },
  });

  const onFormReset = () => {
    resetForm();
    academicFormReset();
    professionalFormReset();
    trainingFormReset();
  };

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const { createQualification } = useQualifcationDetails();

  const onFormSubmit = async (e) => {
    if (!isFormValid()) {
      return;
    }
    createQualification(
      values,
      academicFormValues,
      professionalFormValues,
      trainingFormValues,
      onFormReset
    );
  };

  const conditionalForm = useCallback(() => {
    switch (values.qualificationType) {
      case "Academic":
        return (
          <AcademicForm
            formData={academicFormData}
            isFormValid={academicFormValidity}
            onSubmit={onFormSubmit}
          />
        );
      case "Professional":
        return (
          <ProfessionalForm
            formData={professionalFormData}
            isFormValid={professionalFormValidity}
            onSubmit={onFormSubmit}
          />
        );
      case "TrainingCertificate":
        return (
          <TrainingForm
            formData={trainingFormData}
            isFormValid={trainingFormValidity}
            onSubmit={onFormSubmit}
          />
        );
      default:
        return;
    }
  }, [
    values.qualificationType,
    academicFormData,
    academicFormValidity,
    professionalFormData,
    professionalFormValidity,
    trainingFormData,
    trainingFormValidity,
  ]);

  return (
    <FormContainer>
      <Header>
        <FormHeading>Add qualifications</FormHeading>
        <Icon name="fas fa-times" onClick={updateFormVisibilty} />
      </Header>

      <InputGroup
        fieldType="select"
        options={qualificationsOptions}
        defaultSelectText="Select qualification type"
        {...formData["qualificationType"]}
      />

      {conditionalForm()}
    </FormContainer>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { companyId, isAdmin };
};

export default connect(mapStateToProps, null)(Form);
