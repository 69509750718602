import { createSelector } from "reselect";

const storeHolidayDataList = (store) => store.holidays.data;
export const storeCurrentYearIdSelector = (store) =>
  store.holidays.currentYearId;

export const holidayDataSelector = createSelector(
  storeHolidayDataList,
  (_, props) => props.id,
  (data, id) => {
    return data[id] ? data[id].attributes : {};
  }
);
