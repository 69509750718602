import {
  CREATE_ACCESS_ASSET,
  GET_ACCESS_ASSETS,
  SEARCH_ACCESS_ASSET,
  UPDATE_SEARCH_PARAMS,
  RESET_SEARCH_PARAMS,
  EDIT_ACCESS_ASSET,
  DELETE_ACCESS_ASSET,
} from "./actiontypes";

import { createAsyncActionCreator } from "../../Helper";

import {
  createAccessAssetApi,
  editAccessAssetApi,
  getAccessAssetsApi,
  searchAccessAssetApi,
  deleteAccessAssetApi,
} from "./api";

const createAccessAssetGenrator = function* ({ companyId, formData, isAdmin }) {
  yield {
    action: CREATE_ACCESS_ASSET,
    sideEffect: createAccessAssetApi({ companyId, formData, isAdmin }),
  };
};

const editAccessAssetGenerator = function* ({
  branchId,
  companyId,
  isAdmin,
  formData,
}) {
  yield {
    action: EDIT_ACCESS_ASSET,
    sideEffect: editAccessAssetApi({ branchId, companyId, isAdmin, formData }),
  };
};

const getAccessAssetsGenerator = function* ({
  companyId,
  pageNumber,
  isAdmin,
}) {
  yield {
    action: GET_ACCESS_ASSETS,
    sideEffect: getAccessAssetsApi({ companyId, pageNumber, isAdmin }),
  };
};

const deleteAccessAssetsGenrator = function* ({ companyId, isAdmin, assetId }) {
  yield {
    action: DELETE_ACCESS_ASSET,
    sideEffect: deleteAccessAssetApi({ companyId, isAdmin, assetId }),
  };
};

const searchAccessAssetGenerator = function* ({
  companyId,
  pageNumber,
  isAdmin,
  assignedTo,
  category,
  currentFromDate,
  currentToDate,
  currentStatus,
}) {
  yield {
    action: SEARCH_ACCESS_ASSET,
    sideEffect: searchAccessAssetApi({
      companyId,
      pageNumber,
      isAdmin,
      assignedTo,
      category,
      currentFromDate,
      currentToDate,
      currentStatus,
    }),
  };
};

export const updateSearchParams = ({ filterData }) => ({
  type: UPDATE_SEARCH_PARAMS,
  payload: filterData,
});

export const resetSearchParams = () => ({
  type: RESET_SEARCH_PARAMS,
});

export const [
  createAccessAsset,
  editAccessAssets,
  getAccessAssets,
  searchAccessAsset,
  deleteAccessAsset,
] = createAsyncActionCreator([
  createAccessAssetGenrator,
  editAccessAssetGenerator,
  getAccessAssetsGenerator,
  searchAccessAssetGenerator,
  deleteAccessAssetsGenrator,
]);
