import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Grid from "components/Grid";
import RenderItem from "./RenderItem";

import { createTableDataSelector } from "common/Selectors/MTable";

import { TableContainer } from "../../../../GlobalStyles";
import { calculateMaxLength } from "../../../../Helper";
import { useHistory } from "react-router";
import Routes from "route/Path";
import { getCurrentUserCompany, isCurrentUserIsExecutive } from "common/Selectors/auth";
import { deleteAccessAsset } from "modules/CompanyAssets/actionCreator";
import useAlert from "hooks/useAlert";
import { bindActionCreators } from "redux";


const TableData = ({
  data,
  onCellClick,
  cellNameToColumnMap,
  isSearchOn,
  rowIdMap,
  onEmptyData,
  editId,
  companyId,
  isAdmin,
  rowIndex,
  deleteAccessAsset,
  ...other
}) => {
  const history = useHistory();
  const { showAlert } = useAlert();
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data, cellNameToColumnMap]);

  const maxStatusLength = useMemo(() => {
    return calculateMaxLength(cellNameToColumnMap, data);
  }, [data]);

  const onEditCompanyAssets = (e, rowIndex) => {
    history.push(
      `${Routes.authorized.accessAssets.edit.path}/${rowIdMap[rowIndex]}`
    );
  };


  const OnDelete = useCallback(
    async (e, rowIndex) => {
    try {
      await deleteAccessAsset({ assetId: rowIdMap[rowIndex], isAdmin,  companyId, });
      showAlert({
        message: `Company Asset Detail Deleted`,
      });

    }
    catch (err) {
      showAlert({ message: "Company Asset Delete Failed", type: "error" });
    }

   }, [ companyId, isAdmin, rowIdMap],

  

  );

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (rows === 1 && !isSearchOn) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);

  const actionCellProps = useMemo(() => {
    return {
      cellName: "actions",
      cellActions: [
        {
          id: "class_edit_btn",
          icon: "fas fa-pen",
          tootltipText: "Edit Company-Assests",
          btnProps: {
            onClick: onEditCompanyAssets,
          },
        },

        {
          id: "delete_botton",
          icon: "fas fa-trash",
          tootltipText: "Delete",
          btnProps: {
            onClick: OnDelete,
          },
        }
      ],
    };
  }, [onEditCompanyAssets, OnDelete]);

  // const actionCellPropsDelete = useMemo(() => {
  //   return {
  //     cellName: "Delete",
  //     cellActions: [
  //       {
  //         id: "delete_botton",
  //         icon: "fas fa-pen",
  //         tootltipText: "Delete",
  //         btnProps: {
  //           onClick: OnDelete,
  //         },
  //       },
  //     ],
  //   };
  // }, [OnDelete]);
  return (
    <TableContainer {...other}>
      <Grid
        onClick={onCellClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        actionCellProps={actionCellProps}
        // actionCellPropsDelete={actionCellPropsDelete}
        data={{
          rowData: data,
          cellNameToColumnMap,
          maxStatusLength: maxStatusLength,
        }}
        dataKey="rowData"
      >
        {RenderItem}
      </Grid>
    </TableContainer>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const companyId = getCurrentUserCompany(store, props);
  const getUserRows = createTableDataSelector({
    tableName: "accessAssets",
    blackList: { "Sl No": true },
    extraColumns: isAdmin && [{ columnName: "actions", position: 0 }],
    // extraColumns: isAdmin && [{ columnName: "delete", position: 8 }],
    reverse: false,
  });
  const maker = (store, props) => {
    const { rows, rowIdMap, cellNameToColumnMap } = getUserRows(store, props);
    return {
      data: rows,
      rowIdMap,
      cellNameToColumnMap,
      isSearchOn: store.accessAssets.isSearchOn,
      companyId,
      isAdmin
    };
  };
  return maker;
};


 const mapDispatchToProps = (dispatch) =>
   bindActionCreators(
    {
      deleteAccessAsset,
    }, 
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TableData);
