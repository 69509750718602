import {
  CREATE_LEAVE_TYPE,
  DELETE_LEAVE_TYPE,
  EDIT_LEAVE_TYPE,
  GET_ALL_LEAVE_TYPES,
} from "./actiontypes";

import {
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnUpdate,
  createNewStoreOnDelete,
} from "helper";

const initialState = createInitialStoreState({}, true);

export const leaveTypeReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_ALL_LEAVE_TYPES}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    case `${CREATE_LEAVE_TYPE}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true);
    }

    case `${EDIT_LEAVE_TYPE}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store, true);
    }

    case `${DELETE_LEAVE_TYPE}_SUCCESS`: {
      return createNewStoreOnDelete(payload, store, true);
    }

    default:
      return store;
  }
};
