import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reducerStore } from "types/index";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import useAlert from "hooks/useAlert";
import { deleteLeaveType as deleteLeaveTypeActionCreator } from "../Views/Display/LeaveType/actionCreator";

export const useDeleteleaveType = () => {
  const [isLoading, updateLoading] = useState<boolean>(false);
  const { showAlert } = useAlert();
  const dispatch = useDispatch();
  const companyId = useSelector((store: reducerStore) =>
    getCurrentUserCompany(store, null),
  );
  const isAdmin = useSelector((store: reducerStore) =>
    isCurrentUserIsExecutive(store, null),
  );

  const deleteLeaveType = async (leaveTypeId: string) => {
    try {
      updateLoading(true);
      await dispatch(
        deleteLeaveTypeActionCreator({ leaveTypeId, companyId, isAdmin }),
      );
      showAlert({ message: "Leave type delete success" });
    } catch (err) {
      showAlert({ message: "Failed to delete leave type", type: "error" });
    } finally {
      updateLoading(false);
    }
  };

  return {
    isLoading,
    deleteLeaveType,
  };
};
