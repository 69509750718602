import styled from "styled-components";
import Button from "components/Button";
import Icon from "components/Icon";
import { StyledActionArea } from "globalStyles";
import { ImageComponent } from "components/Image";

export const EnhancedActionArea = styled(StyledActionArea)`
  flex-flow: row wrap;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0;
  margin: 0;

  & > button {
    height: 35px;
    padding: 0 20px;
    width: auto;
    margin-left: 10px;
  }
`;
export const Container = styled.div`
  padding: 20px 63px 0 63px;
  min-height: 800px;
  display: flex;
  flex-flow: column nowrap;
  .attendance-list-container {
    display: flex;
    flex-grow: 0;
  }
  @media (max-width: 1024px) {
    .attendance-list-container {
      flex-direction: column;
    }
  }
`;
export const CellIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.primaryColor};
  margin-left: 10px;
  font-size: 15px;
`;

export const StyledButton = styled(Button)`
  height: 35px;
`;
export const BackButton = styled.button`
  cursor: pointer;
  border: none;
  margin-right: 20px;
  font-size: large;
  color: white;
  height: 30px;
  width: 30px;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.button.primary.background};
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    height: 35px;
  }
`;

export const EmptyScreenImage = styled(ImageComponent)`
  width: 40%;
  height: 40%;
  object-fit: contain;
`;

export const EmptyScreenMessage = styled.span`
  margin: 30px;
  font-size: ${(props) => props.theme.text.title.medium};
`;

export const ColorBlock = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 20px;
  background: ${(props) => props?.$colorCode};
`;
