import React, { useCallback, useState } from "react";
import ActionArea from "./ActionArea";
import TableData from "./TableData";

import InfoView from "components/InfoView";
import NoLeaves from "assets/employee/noLeaves.png";
import { RectangleSkeleton } from "components/Skeleton";
import { ViewContainer } from "globalStyles/view";
import PageSlider from "components/PageSlider";
import { usePageSlider } from "hooks/usePageSlider";
import WorklogView from "modules/Attendence/Views/Details/WorklogView";
import PageBackHeader from "components/PageBackHeader";
import Create from "./create";
import useCommentDetails from "hooks/useCommentDetails";

const TeamWorklogView = ({ teamId }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const { onCellClick, data, isSliderActive, toggleSlider } = usePageSlider();

  const { createComment, isCreating } = useCommentDetails();

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);
  const updateFormVisibility = useCallback(() => {
    setIsFormVisible((val) => !val);
  }, []);
  const editFormVisibility = useCallback(() => {
    setIsEditFormVisible((val) => !val);
  }, []);

  if (isFormVisible) {
    return (
      <Create teamId={teamId} updateFormVisibility={updateFormVisibility} />
    );
  } else if (isEditFormVisible) {
    return (
      <Create
        teamId={teamId}
        dailyWorkReport={data}
        updateFormVisibility={editFormVisibility}
        toggleSlider={toggleSlider}
      />
    );
  }
  const onCommentWorkLog = (comment, onSuccess) => {
    createComment({
      commentableId: data.id,
      commentableType: "DailyWorkReport",
      message: comment,
      onSuccess
    });
  };
  return (
    <>
      <ActionArea
        onLoading={onDbDataChange}
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
          margin: "0px",
          padding: "0px"
        }}
        modelableId={teamId}
        updateFormVisibility={updateFormVisibility}
      />
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit"
        }}
        onEmptyData={onEmptyData}
        onCellClick={onCellClick}
      />

      <PageSlider
        showBackButton={false}
        active={isSliderActive}
        style={{ top: 95, left: 0, padding: "5px 5%" }}
      >
        <WorklogView
          dailyWorkReport={data}
          onBack={toggleSlider}
          showBackButton={true}
          onCommentWorkLog={onCommentWorkLog}
          isCreating={isCreating}
          onClick={() => editFormVisibility(true)}
        />
      </PageSlider>
      {!isLoading && isEmpty && (
        <InfoView
          image={NoLeaves}
          ctaText="Log Work"
          onCta={updateFormVisibility}
          heading={`No team worklog to show here`}
        />
      )}
      {isLoading && (
        <ViewContainer>
          <RectangleSkeleton times={4} />
        </ViewContainer>
      )}
    </>
  );
};

export default TeamWorklogView;
