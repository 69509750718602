import {
  CREATE_JOB_TITLE,
  UPDATE_JOB_TITLE,
  DELETE_JOB_TITLE,
  GET_ALL_JOB_TITLES,
} from "./actiontypes";
import { createAsyncActionCreator } from "../../Helper";
import {
  createJobTitleApi,
  updateJobTitleApi,
  getAllJobTitlesApi,
  deleteJobTitleApi,
} from "./api";

const createJobTitleGenrator = function* ({ companyId, jobTitle, isAdmin }) {
  yield {
    action: CREATE_JOB_TITLE,
    sideEffect: createJobTitleApi({ companyId, jobTitle, isAdmin }),
  };
};

const updateJobTitleGenrator = function* ({
  companyId,
  jobTitle,
  jobId,
  isAdmin,
}) {
  yield {
    action: UPDATE_JOB_TITLE,
    sideEffect: updateJobTitleApi({ companyId, jobTitle, jobId, isAdmin }),
  };
};

const getAllJobTitleGenrator = function* ({ pageNumber, companyId, isAdmin }) {
  yield {
    action: GET_ALL_JOB_TITLES,
    sideEffect: getAllJobTitlesApi({ pageNumber, companyId, isAdmin }),
  };
};

const deleteJobTitleGenrator = function* ({ companyId, jobId, isAdmin }) {
  yield {
    action: DELETE_JOB_TITLE,
    sideEffect: deleteJobTitleApi({ companyId, jobId, isAdmin }),
  };
};

export const [getAllJobTitles, createJobTitle, updateJobTitle, deleteJobTitle] =
  createAsyncActionCreator([
    getAllJobTitleGenrator,
    createJobTitleGenrator,
    updateJobTitleGenrator,
    deleteJobTitleGenrator,
  ]);
