import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import useAlert from "hooks/useAlert";

import { answereToQuiz as answereToQuizActionCreator } from "../../redux/Posts/actionCreator";
import { reducerStore } from "types/index";

function useAnswereToQuiz() {
  const [isLoading, updateLoading] = useState<boolean>(false);
  const [hasError, updateError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const companyId = useSelector((store: reducerStore) =>
    getCurrentUserCompany(store, null)
  );
  const isAdmin = useSelector((store: reducerStore) =>
    isCurrentUserIsExecutive(store, null)
  );

  const answereToQuiz = async (
    optionId: string,
    callback: (success: boolean) => void | null
  ) => {
    let success = false;
    try {
      updateLoading(true);
      await dispatch(
        answereToQuizActionCreator({
          companyId,
          isAdmin,
          optionId,
        })
      );
      success = true;
    } catch (err) {
      showAlert({ message: "Failed to answere to quiz", type: "error" });
      updateError(true);
    } finally {
      updateLoading(false);
    }

    typeof callback === "function" && callback(success);
  };

  return {
    isLoading,
    answereToQuiz,
    hasError,
  };
}

export default useAnswereToQuiz;
