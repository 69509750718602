import styled from "styled-components";
import Icon from "../Icon";
import { scrollbar } from "globalStyles";

import { FixedSizeGrid } from "react-window";

import ProfilePic from "../ProfilePic";
import { checkIfArrayConsistsOfImagesOnly } from "../../../Helper";

export const StyledGridCell = styled.div`
  flex: 1;
  word-break: break-all;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 10px;
  // border: 1px lightgray solid;
  width: 100%;
  height: 100%;
  background-color: white;
  font-size: ${(props) => props.theme.text.para.small};
  color: ${(props) => props.theme.colors.grid.cell.text};

  ${({ row, showHeading, theme }) => {
    const headCellStyle = `
    background-color:${theme.colors.grid.header.color};
    color: white;
    font-size: ${theme.text.para.medium};
    text-transform: capitalize;
    font-weight: 600;
    justify-content: flex-start;`;

    const activeStyle = `
    background-color:${theme.colors.grid.cell.activeBackground};
    flex: 1;
    `;

    if (showHeading) {
      if (row === 0) {
        return `
          ${headCellStyle}
          border: none;
          `;
      } else if (row === 1) {
        return headCellStyle;
      } else if (row % 2 === 0) {
        return activeStyle;
      }
    } else {
      if (row === 0) {
        return headCellStyle;
      } else if (row % 2 !== 0) {
        return activeStyle;
      }
    }
  }}

  ${({ data, isInActiveRow, theme }) => {
    let result = "";
    const containsOnlyImages = checkIfArrayConsistsOfImagesOnly(data)
    if (typeof data === "object" && !containsOnlyImages) {
      result += `
      font-weight: bold;
      color: ${theme.colors.grid.cell.objectText} ;
      `;
    }

    if (isInActiveRow) {
      result += `
        border: 2px ${theme.colors.grid.cell.activeBorder} solid;
        border-left: none;
        border-right: none;
        `;
    }

    return result;
  }}
`;

export const DropIcon = styled(Icon)`
  margin-left: 5px;
  margin-top: 5px;
`;

export const StyledFixedSizedGrid = styled(FixedSizeGrid)`
  border: 1px ${(props) => props.theme.colors.grid.border} solid;
  border-radius: 3px;
  ${scrollbar}
  overflow: auto;
`;

export const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
  border: ${({ theme, $showBorder }) =>
    $showBorder ? `1px ${theme.colors.grid.border} solid` : "none"};
`;

export const ProfileCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  &:hover > span {
    text-decoration: underline;
  }
`;

export const ProfileCellName = styled.span`
  font-weight: normal;
  // font-size: ${(props) => props.theme.text.para.medium};
  margin-left: 12px;
`;

export const StyledProfilePic = styled(ProfilePic)`
  width: 27px;
  height: 27px;
`;
