import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InsertInDom from "../InsertInDom";
import { useTooltipData } from "./hooks/useTooltipData";
import { Content } from "./style";
import { getModifiedStyle } from "./utils";

export function TooltipContent({
  children,
  className,
  style,
  applyDefaultStyle,
}) {
  const data = useTooltipData();
  const [myStyle, updateStyle] = useState(data.style);
  const contentData = useRef(null);

  contentData.current = data;

  const setRef = useCallback((ref) => {
    if (!ref) {
      return;
    }
    const contentStyle = ref.getBoundingClientRect();
    const data = contentData.current;
    const style = getModifiedStyle(data.style, data.position, contentStyle);
    updateStyle(style);
  }, []);

  const combinedStyles = useMemo(() => {
    return {
      ...style,
      ...myStyle,
    };
  }, [myStyle, style]);

  if (!data.active) {
    return null;
  }

  return (
    <InsertInDom>
      <Content
        ref={setRef}
        $applyDefaultStyle={applyDefaultStyle}
        style={combinedStyles}
        className={className}
      >
        {children}
      </Content>
    </InsertInDom>
  );
}

TooltipContent.displayName = "TooltipContent";
TooltipContent.defaultProps = {
  className: "",
  applyDefaultStyle: false,
};
