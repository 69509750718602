import { GET_EMPLOYEE_EMAIL, GET_USER_EMAIL, CREATE_USER_EMAIL } from "./actiontypes";

import { createAsyncActionCreator } from "helper";

import { getEmployeeEmailsApi, getUserEmailApi, createUserEmailApi } from "./api";

const getEmployeeEmailGenerator = function* ({
  isAdmin,
  companyId,
  pageNUmber,
}) {
  yield {
    action: GET_EMPLOYEE_EMAIL,
    sideEffect: getEmployeeEmailsApi({ isAdmin, companyId, pageNUmber }),
  };
};
const getUserEmailGenerator = function* ({
  isAdmin,
  companyId,
  userId,
  pageNumber,
}) {
  yield {
    action: GET_USER_EMAIL,
    sideEffect: getUserEmailApi({ isAdmin, companyId, userId, pageNumber }),
  };
};

const createUserEmailGenerator = function* ({
  isAdmin,
  companyId,
  userId,
  formData,
}) {
  yield {
    action: CREATE_USER_EMAIL,
    sideEffect: createUserEmailApi({ isAdmin, companyId, userId, formData }),
  };
};

export const [createUserEmail, getUserEmail, getEmployeeEmail] = createAsyncActionCreator([
  createUserEmailGenerator,
  getUserEmailGenerator,
  getEmployeeEmailGenerator
]);
