import styled from "styled-components";
import ComingSoon from "components/ComingSoon";

export const Container = styled.div`
  position: fixed;
  width: 409px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 20px 0;
  min-height: 100px;
  max-height: 600px;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0 5px 15px -1px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  z-index: 2;

  & > div > div {
    height: 35px;
    max-width: 200px;
  }

  & button {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
`;

export const Triangle = styled.div`
  position: fixed;
  content: "";
  background-color: #ffffff;
  transform: rotate(45deg);
  box-shadow: 0 5px 15px -1px rgba(0, 0, 0, 0.2);
  z-index: 1;
  ${(props) => props.$pos};
`;

export const StyledComingSoon = styled(ComingSoon)`
  & > img {
    width: 200px;
    height: 200px;
  }
  & > span {
    font-size: ${(props) => props.theme.text.para.small};
  }
  border: none;
`;

