import React from "react";
import useInput from "./useInput";
import Icon from "../Icon";

import {
  InputContainer,
  InputIcon,
  GlobalInput,
  ClearButton,
  ColorInput,
} from "./style";

const Input = ({
  onGivenKeyPressed = null,
  size = "md",
  success = "",
  secondary = false,
  error = "",
  ...otherprops
}) => {
  const { icon, restInputProps, showClearButton, onClear } = useInput({
    ...otherprops,
    success,
    error,
  });
  success = success ? "true" : undefined;
  error = error ? "true" : undefined;
  return (
    <InputContainer size={size}>
      <GlobalInput
        as="div"
        style={{
          display: "flex",
          alignItems: "stretch",
          padding: "5px",
        }}
        success={success}
        error={error}
        type="color"
        secondary={secondary}
      >
        <ColorInput type="color" secondary={secondary} {...restInputProps} />
      </GlobalInput>
      {!!icon && <InputIcon success={success} error={error} name={icon} />}
      {showClearButton && (
        <ClearButton secondary type="button" size="auto" onClick={onClear}>
          <Icon name="fas fa-times" />
        </ClearButton>
      )}
    </InputContainer>
  );
};

export default Input;
