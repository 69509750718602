import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react";

import useTransition from "hooks/useTransition";

import Button from "../Button";
import Icon from "../Icon";
import InsertInDom from "../InsertInDom";

import styles from "./Modal.module.css";
import { Container, Content, CloseButton, Overlay, HeadingBox } from "./style";

const Modal = ({
  active,
  children,
  closeModal,
  heading,
  showCloseIcon = true,
  showHeadingBox = true,
}) => {
  const childRef = useRef(null);
  const [position, updatePosition] = useState({
    top: window.innerHeight / 2,
    left: window.innerWidth / 2,
  });
  const { isVisible } = useTransition({ active, maxWait: 500 });

  const [childDimensions, updateChildDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const { width, height } = childDimensions;

    updatePosition({
      top: window.innerHeight / 2 - height / 2,
      left: window.innerWidth / 2 - width / 2,
    });
  }, [childDimensions]);

  const onContentClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const onChildRefSet = (ref) => {
    if (!ref) {
      return;
    }
    const { width, height } = ref.getBoundingClientRect();
    childRef.current = ref;
    updateChildDimensions({ width, height });
  };

  const newChildren = useMemo(() => {
    return React.cloneElement(children, { ref: onChildRefSet });
  }, [children]);

  return isVisible ? (
    <InsertInDom isModal={true} domId={null}>
      <Container>
        <Content
          onClick={onContentClick}
          active={active}
          style={{
            top: `${position.top}px`,
            left: `${position.left}px`,
          }}
          className={styles.content}
        >
          {showHeadingBox && (
            <HeadingBox>
              <span>{heading}</span>
              {showCloseIcon && (
                <CloseButton type="button" size="auto" onClick={closeModal}>
                  <Icon name="fas fa-times" />
                </CloseButton>
              )}
            </HeadingBox>
          )}
          {newChildren}
        </Content>

        <Overlay active={active} />
      </Container>
    </InsertInDom>
  ) : null;
};

export default Modal;
