import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import LeaveType from "../../../Modules/Leaves/Views/Display/LeaveType";
import AssignLeave from "../../../Modules/Leaves/Views/Display/AssignLeave";
import LeaveTypeCreateView from "modules/Leaves/Views/Display/LeaveType/Create";
import AssignLeaveCreateView from "modules/Leaves/Views/Display/AssignLeave/Create";

import { createRouteObject } from "../../../Helper";

const { leaves } = RoutePaths.authorized;

const pages = createRouteObject(
  leaves,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [LeaveType, LeaveTypeCreateView, AssignLeave, AssignLeaveCreateView],
);

const LeavesPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact={item.slugPath ? false : true}
        path={item.slugPath || item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default LeavesPages;
