import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useLayoutEffect,
} from "react";

import Week from "./Week";
import Days from "./Days";
import ActionRow from "./ActionRow";
import Years from "./Years";
import Months from "./Months";

import useInput from "../useInput";
import useWindowDimensions from "hooks/useWindowDimensions";
import { validateDate } from "helper";

import { InputContainer, InputIcon } from "../style";
import { DatePickerButton, DatePickerContent, CalendarIcon } from "./style";
import GenericModal from "../../GenericModal";

const Views = {
  DAY: "day",
  MONTH: "month",
  YEAR: "year",
};

const DatePicker = ({
  size = "md",
  success = "",
  secondary = false,
  error = "",
  placeholder = "",
  listStyle = {},
  showPickerIcon = true,
  fieldClass = "",
  onBlur,
  name,
  ...otherprops
}) => {
  const btnRef = useRef();
  const [isActive, toggleActive] = useState(false);
  const [currentView, updateView] = useState(Views["DAY"]);
  const [currentDate, updateDate] = useState(() => ({
    currentYear: new Date().getFullYear(),
    currentMonth: new Date().getMonth(),
    currentDay: new Date().getDate(),
  }));

  const [pageYear, updatePageYear] = useState({
    pageCurrentYear: 2020,
    pageLastYear: 2039,
  });
  const { icon, restInputProps } = useInput({
    ...otherprops,
    success,
    error,
  });
  success = success ? "true" : undefined;
  error = error ? "true" : undefined;

  const { onChange, value, ...rest } = restInputProps;
  const [contentPos, updateContentPos] = useState({ top: 0, left: 0 });
  const { dimesnions } = useWindowDimensions();
  const datePickerContentRef = useRef(null);
  const tempDataRef = useRef(null);
  tempDataRef.current = value;

  /**
   * Updates the position of datePicker popup box according to the window dimensions
   */
  const updatePosition = useCallback(() => {
    if (!datePickerContentRef.current) {
      return;
    }
    const { height, width } =
      datePickerContentRef.current.getBoundingClientRect();
    const {
      top: inputTop,
      height: inputHeight,
      left: inputLeft,
    } = btnRef.current.getBoundingClientRect();

    let top = 0;
    let left = 0;
    if (inputTop + inputHeight + height > dimesnions.height) {
      top = Math.max(0, inputTop - height);
    } else {
      top = inputTop + inputHeight;
    }

    if (inputLeft + width > dimesnions.width) {
      left = Math.max(0, inputLeft - width);
    } else {
      left = inputLeft;
    }

    updateContentPos({
      top,
      left,
    });
  }, []);

  const setContentRef = useCallback((ref) => {
    if (!ref) {
      return;
    }
    datePickerContentRef.current = ref;
    updatePosition();
  }, []);

  const toggleDatePicker = useCallback(
    (status = null) => {
      let myNextval = false;
      toggleActive((val) => {
        if (typeof status === "boolean") {
          myNextval = status;
          return status;
        }
        myNextval = !val;
        return !val;
      });

      if (!myNextval && typeof onBlur === "function") {
        onBlur({ target: { name, value: tempDataRef.current } });
      }
    },
    [name]
  );

  useLayoutEffect(() => {
    if (isActive) {
      updatePosition();
    }
  }, [dimesnions, isActive]);

  useEffect(() => {
    if (!value) {
      return;
    }

    if (!validateDate(value)) {
      console.error("Invalid date format");
      return;
    }
    const date = new Date(value);
    updateDate({
      currentYear: date.getFullYear(),
      currentMonth: date.getMonth(),
      currentDay: date.getDate(),
    });
  }, [value]);

  const SwitchToMonthView = useCallback(() => {
    updateView(Views["MONTH"]);
  }, []);

  const SwitchToDayView = useCallback(() => {
    updateView(Views["DAY"]);
  }, []);

  const toggleYearAndDayView = useCallback(() => {
    updateView((prevData) => {
      return prevData === Views["DAY"] ? Views["YEAR"] : Views["DAY"];
    });
  }, []);

  const calcNextMonth = useCallback(() => {
    if (currentDate.currentMonth === 11) {
      updateDate((prevData) => ({
        ...prevData,
        currentMonth: 0,
        currentYear: prevData.currentYear + 1,
      }));
    } else {
      updateDate((prevData) => ({
        ...prevData,
        currentMonth: prevData.currentMonth + 1,
      }));
    }
  }, [currentDate.currentMonth]);

  const calcPrevMonth = useCallback(() => {
    if (currentDate.currentMonth === 0) {
      updateDate((prevData) => ({
        ...prevData,
        currentMonth: 11,
        currentYear: prevData.currentYear - 1,
      }));
    } else {
      updateDate((prevData) => ({
        ...prevData,
        currentMonth: prevData.currentMonth - 1,
      }));
    }
  }, [currentDate.currentMonth]);

  const calcNextSetOfYears = useCallback(() => {
    updatePageYear((prevData) => ({
      pageCurrentYear: prevData.pageLastYear + 1,
      pageLastYear: prevData.pageLastYear + 20,
    }));
  }, []);

  const calcPrevSetOfYears = useCallback(() => {
    updatePageYear((prevData) => ({
      ...prevData,
      pageCurrentYear: prevData.pageCurrentYear - 20,
      pageLastYear: prevData.pageCurrentYear - 1,
    }));
  }, []);

  const onYearChange = useCallback(
    (year) => {
      updateDate((prevData) => ({
        ...prevData,
        currentYear: Number(year),
      }));

      SwitchToMonthView();
    },
    [SwitchToMonthView]
  );

  const onDayChange = useCallback(
    (day) => {
      updateDate((prevData) => ({
        ...prevData,
        currentDay: Number(day),
      }));

      if (typeof onChange !== "function") {
        return;
      }

      const value = new Date(
        currentDate.currentYear,
        currentDate.currentMonth,
        day
      ).toString();
      tempDataRef.current = value;
      onChange({
        target: {
          value,
          name,
        },
      });

      toggleDatePicker();
    },
    [
      currentDate.currentMonth,
      currentDate.currentYear,
      onChange,
      toggleDatePicker,
    ]
  );

  const onMonthChange = useCallback(
    (month) => {
      updateDate((prevData) => ({
        ...prevData,
        currentMonth: Number(month),
      }));
      SwitchToDayView();
    },
    [SwitchToDayView]
  );

  const dateText = useMemo(() => {
    const { currentDay, currentMonth, currentYear } = currentDate;
    return value
      ? `${currentDay}/${currentMonth + 1}/${currentYear}`
      : placeholder;
  }, [currentDate, placeholder, value]);

  return (
    <InputContainer size={size} className={fieldClass}>
      <DatePickerButton
        as="button"
        ref={btnRef}
        {...rest}
        type="button"
        success={success}
        error={error}
        secondary={secondary}
        onClick={toggleDatePicker}
      >
        {dateText}
      </DatePickerButton>

      {isActive && (
        <GenericModal onClose={toggleDatePicker}>
          <DatePickerContent style={contentPos} ref={setContentRef}>
            <ActionRow
              toggleView={toggleYearAndDayView}
              onNextYear={calcNextSetOfYears}
              onPrevYear={calcPrevSetOfYears}
              onNextMonth={calcNextMonth}
              onPrevMonth={calcPrevMonth}
              isMonth={
                currentView === Views["MONTH"] || currentView === Views["DAY"]
              }
              currentMonth={currentDate.currentMonth}
              currentYear={currentDate.currentYear}
              pageStartYear={pageYear.pageCurrentYear}
              pageEndYear={pageYear.pageLastYear}
            />
            {currentView === Views["DAY"] && <Week />}

            <Days
              visibility={currentView === Views["DAY"]}
              onDayChange={onDayChange}
              year={currentDate.currentYear}
              month={currentDate.currentMonth}
              day={currentDate.currentDay}
            />

            <Months
              visibility={currentView === Views["MONTH"]}
              onMonthChange={onMonthChange}
              currentMonth={currentDate.currentMonth}
            />

            <Years
              visibility={currentView === Views["YEAR"]}
              currentYear={currentDate.currentYear}
              onYearChange={onYearChange}
              startingYear={pageYear.pageCurrentYear}
              endingYear={pageYear.pageLastYear}
            />
          </DatePickerContent>
        </GenericModal>
      )}

      {!!icon ? (
        <InputIcon success={success} error={error} name={icon} />
      ) : (
        showPickerIcon && <CalendarIcon />
      )}
    </InputContainer>
  );
};

export default DatePicker;
