import {
  CREATE_LEAVE,
  GET_ALL_CREATED_LEAVES,
  GET_ALL_COLLABORATOR_LEAVES,
} from "./actiontypes";
import { createAsyncActionCreator } from "helper";
import {
  createLeaveApi,
  getAllCollaboratorLeavesApi,
  getAllLeavesApi,
} from "./api";

const createLeaveGenrator = function* ({ companyId, formData, isAdmin }) {
  yield {
    action: CREATE_LEAVE,
    sideEffect: createLeaveApi({ companyId, formData, isAdmin }),
  };
};

const getAllLeavesGenrator = function* ({ pageNumber, companyId, isAdmin }) {
  yield {
    action: GET_ALL_CREATED_LEAVES,
    sideEffect: getAllLeavesApi({ pageNumber, companyId, isAdmin }),
  };
};

const getAllCollaboratorLeavesGenerator = function* ({
  pageNumber,
  companyId,
  isAdmin,
}) {
  yield {
    action: GET_ALL_COLLABORATOR_LEAVES,
    sideEffect: getAllCollaboratorLeavesApi({ pageNumber, companyId, isAdmin }),
  };
};

export const [createLeave, getAllLeaves, getAllCollaboratorLeaves] =
  createAsyncActionCreator([
    createLeaveGenrator,
    getAllLeavesGenrator,
    getAllCollaboratorLeavesGenerator,
  ]);
