import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ChildContainer = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.popover};
`;

export const Traiangle = styled.div`
  position: fixed;
  z-index: 1;
  border-style: solid;

  border-top-width: 20px;
  border-top-color: ${(props) => props.$color};

  border-bottom-width: 20px;
  border-bottom-color: transparent;

  border-right-width: 20px;
  border-right-color: transparent;

  border-left-width: 20px;
  border-left-color: transparent;
`;
