import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PlacesAutocomplete from "react-places-autocomplete";
import InputGroup from "components/InputGroup";
import { setCurrentVenue } from "../../actionCreator";
import { LocationEnablerMessage } from "../../styles/autocomplete";
import { getAddressDetails } from "../../../../Helper";
import LocationSearchDropdown from "./LocationSearchDropdown";
import useLocationAccessState from "../../../../Hooks/useLocationAccessState";
import useAlert from "../../../../Hooks/useAlert";

const AutoComplete = ({ setCurrentVenue }) => {
  const [address, setAddress] = useState("");
  const [locationAccess, setLocationAccess] = useState(false);
  const resultState = useLocationAccessState();
  const { showAlert } = useAlert();
  const enableLocationAccess = () => {
    setLocationAccess(true);
  };

  useEffect(() => {
    if (resultState === "granted") {
      enableLocationAccess();
    } else if (resultState === "prompt" || resultState === "denied") {
      showAlert({
        message:
          "Enable location access to select your venue and refresh the page.",
        type: "error",
      });
    } else if (resultState === "not available") {
      showAlert({
        message: "There was some error. Please try again later.",
        type: "error",
      });
    }
  }, [resultState]);
  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    getAddressDetails(address, setCurrentVenue);
  };

  return locationAccess ? (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      debounce
    >
      {({ getInputProps, suggestions, loading }) => (
        <>
          <InputGroup
            {...getInputProps({
              placeholder: "Search Places ...",
            })}
          />
          <LocationSearchDropdown suggestions={suggestions} loading={loading} />
        </>
      )}
    </PlacesAutocomplete>
  ) : (
    <>
      <InputGroup disabled placeholder="Search Places ..." />
      <LocationEnablerMessage>
        Please enable location to search for places
      </LocationEnablerMessage>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentVenue,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AutoComplete);
