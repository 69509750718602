import React, { useMemo, useRef } from "react";
import { useHistory } from "react-router";
import Routes from "../../../../Routes/Path";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import { getStatusType } from "helper";

import { Cell } from "../../style/display";

const RenderItem = ({ data, columnIndex, rowIndex, style }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    rowIdMap,
    cellNameToColumnMap,
    onDeleteCountdown,
    isDeletingCountdown,
  } = data;
  const history = useHistory();
  const tempRef = useRef({ rowToDelete: "" });

  const cellvalue = rowData[rowIndex][columnIndex];

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["status"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const onEditCountdown = (e, rowIndex) => {
    history.push(
      `${Routes.authorized.countdown.edit.path}/${rowIdMap[rowIndex]}`
    );
  };

  const onDeleteCountdownClick = (e, rowIndex) => {
    if (window.confirm("Are you sure you want to delete the countdown ?")) {
      tempRef.current.rowToDelete = rowIndex;
      const countdownId = rowIdMap[rowIndex];
      onDeleteCountdown(countdownId);
    }
  };
  const actionCellProps = useMemo(() => {
    return () => ({
      cellName: "actions",
      cellActions: [
        {
          id: "class_edit_btn",
          icon: "fas fa-pen",
          tootltipText: "Edit Countdown",
          btnProps: {
            disabled:
              isDeletingCountdown && tempRef.current.rowToDelete === rowIndex,
            onClick: onEditCountdown,
          },
        },
        {
          id: "class_delete_btn",
          icon: "fas fa-trash",
          tootltipText: "Delete Countdown",
          btnProps: {
            disabled:
              isDeletingCountdown && tempRef.current.rowToDelete === rowIndex,
            onClick: onDeleteCountdownClick,
          },
        },
      ],
    });
  }, [onEditCountdown, onDeleteCountdownClick, rowIdMap]);

  const myChildren = useMemo(() => {
    if (isStatusColumn) {
      return (
        <Cell>
          <Status text={cellvalue} type={getStatusType(cellvalue)} />
        </Cell>
      );
    }
    return null;
  }, [isStatusColumn, cellvalue]);

  return (
    <GridCell
      row={rowIndex}
      actionCellProps={actionCellProps}
      showHeading={showHeading}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

export default RenderItem;
