import React, { useEffect, useImperativeHandle, useMemo } from "react";
import { QuestionCard } from "./QuestionCard";
import Error from "../Error";

import useForm from "hooks/useForm";
import {
  QuestionContainer,
  Question,
  OptionsBlock,
  RatingWrapper,
  RatingContainer,
  RatingTextArea,
  RatingItem,
} from "./style";

const Rating = React.forwardRef(
  ({ question, required, initialValue, inputProps, ...rest }, ref) => {
    const { formData, values, isFormValid, updateField, errorMessage } =
      useForm({
        fields: {
          answere: { required },
        },
      });

    useImperativeHandle(ref, () => ({
      isFormValid,
      values,
      formType: "rating",
    }));

    useEffect(() => {
      //  setting initial value if any
      if (initialValue) {
        updateField("answere", initialValue);
      }
    }, [initialValue]);

    const onRatingClicked = (val) => {
      formData["answere"].onChange({
        target: {
          name: "answere",
          value: String(val),
        },
      });
    };

    const itemList = useMemo(() => {
      return Array(5)
        .fill("")
        .map((val, idx) => {
          return (
            <RatingItem
              $active={values["answere"] == idx + 1}
              onClick={() => onRatingClicked(idx + 1)}
              type="button"
              key={`Rating_${idx}`}
            >
              {idx + 1}
            </RatingItem>
          );
        });
    }, [values["answere"]]);

    return (
      <QuestionContainer {...rest}>
        <Question style={{ textAlign: "center" }}>{question}</Question>
        <OptionsBlock>
          <RatingWrapper>
            <RatingContainer>{itemList}</RatingContainer>
            <RatingTextArea>
              <span className="title">Strongly Disagree</span>
              <span className="title">Strongly Agree</span>
            </RatingTextArea>
          </RatingWrapper>
          <Error style={{ marginTop: "20px" }}>{errorMessage["answere"]}</Error>
        </OptionsBlock>
      </QuestionContainer>
    );
  }
);

Rating.defaultProps = {
  inputProps: {},
  required: true,
};

export const RatingForm = (props) => {
  return <QuestionCard FormComponent={Rating} {...props} />;
};
