import { CREATE_UPI, GET_UPIS } from "./actiontypes";
import { RESET_USER_ID } from "../User/actiontypes";

import {
  createInitialStoreState,
  createNewStoreOnGetData,
  createNewStoreOnCreate,
} from "helper";

const initialState = createInitialStoreState();

export const upiReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${CREATE_UPI}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);
    case `${GET_UPIS}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case RESET_USER_ID: {
      return {
        ...store,
        onceFetched: false,
      };
    }

    default:
      return store;
  }
};
