import React, { useCallback, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup";
import Icon from "components/Icon";
import Button from "components/Button";
import useAlert from "hooks/useAlert";

import { createBankAccount } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { accountTypeOptions } from "./options";

import { StyledFormGroup } from "globalStyles";
import { FormHeading, Header } from "../../style/create";
import { generateFormData } from "../../utils";
import { validateNumber } from "helper";
import { getIfscBankDetails } from "../../api";

const Form = ({ setIsFormVisible, createBankAccount, companyId, isAdmin }) => {
  const [isLoading, updateLoading] = useState(false);
  const [accountNumber, setAccountNumber] = useState();
  const [isAcNoFocused, setAcNoFocused] = useState();
  const [suggestedBankDetail, updateSuggestedBankDetails] = useState({
    branchName: "",
    bankName: "",
  });
  const [currentIFSC, setCurrentIFSC] = useState("");
  const [isIfscValid, updateIsIfscValid] = useState(true);
  const { showAlert } = useAlert();

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const data = generateFormData(values);
        await createBankAccount({ companyId, isAdmin, formData: data });
        showAlert({ message: `Bank Account Detail Created` });
        resetForm();
        setIsFormVisible(false);
      } catch (err) {
        showAlert({ message: "Bank Account Create Failed", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [companyId]
  );

  const setAccNo = useCallback((e) => {
    setAccountNumber(e.target.value);
  }, []);

  const equalityCheck = useCallback(
    (e) => {
      if (accountNumber !== e) {
        return false;
      }
      return true;
    },
    [accountNumber]
  );

  const searchIfscCode = useCallback(
    async (ifsc) => {
      setCurrentIFSC(ifsc);

      try {
        const result = await getIfscBankDetails(currentIFSC);

        if (result.BANK && result.BRANCH) {
          updateSuggestedBankDetails({
            branchName: result.BRANCH,
            bankName: result.BANK,
          });
          updateIsIfscValid(true);
        } else {
          updateSuggestedBankDetails({
            branchName: "",
            bankName: "",
          });
          updateIsIfscValid(false);
        }
      } catch (err) {
        updateIsIfscValid(false);
      }

      return isIfscValid;
    },
    [currentIFSC, isIfscValid]
  );

  const onFocus = useCallback(() => {
    setAcNoFocused(true);
  }, []);

  const onBlur = useCallback((event) => {
    setAcNoFocused(false);
    setAccNo(event);
  }, []);

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Add new bank account</FormHeading>
        <Icon name="fas fa-times" onClick={updateFormVisibilty} />
      </Header>

      <InputGroup
        id="accountCreate_accountHolderName"
        fieldType="input"
        required
        placeholder="Enter account holder name"
        label="Account Holder Name"
      />

      <InputGroup
        id="accountCreate_accountNumber"
        fieldType="input"
        inputMode="numeric"
        required
        placeholder="Enter account number"
        label="Account Number"
        validator={validateNumber}
        invalidMessage="Enter a valid account number"
        onFocus={onFocus}
        onBlur={onBlur}
        style={isAcNoFocused ? null : { webkitTextSecurity: "disc" }}
      />

      <InputGroup
        id="accountNumber"
        fieldType="input"
        inputMode="numeric"
        required
        placeholder="Re-enter account number"
        label="Re-enter Account Number"
        validator={equalityCheck}
        invalidMessage="Account Number must be same as before"
      />

      <InputGroup
        id="accountCreate_accountType"
        fieldType="select"
        required
        options={accountTypeOptions}
        defaultSelectText="Select Account Type"
        label="Account Type"
      />

      <InputGroup
        id="accountCreate_accountIFSCCode"
        fieldType="input"
        required
        placeholder="Enter IFSC code"
        label="IFSC Code"
        style={{ textTransform: "uppercase" }}
        validator={searchIfscCode}
        invalidMessage="Enter a valid IFSC code"
      />

      <InputGroup
        id="accountCreate_accountBranchName"
        fieldType="input"
        required
        placeholder="Enter branch name"
        label="Branch Name"
        initialValue={suggestedBankDetail.branchName}
        disabled
      />

      <InputGroup
        id="accountCreate_accountBankName"
        fieldType="input"
        required
        placeholder="Enter bank name"
        label="Bank Name"
        initialValue={suggestedBankDetail.bankName}
        disabled
      />

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { companyId, isAdmin };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createBankAccount,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
