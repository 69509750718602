import React, { useMemo, useState } from "react";
import { createTableDataSelector } from "common/Selectors/MTable";

import {
  DetailsContainer,
  Header,
  Item,
  Heading,
  Description,
  HeadingLeft,
  HeadingRight,
  Amount,
  SubHeading,
  MemberContainer,
  Circle,
  BackButton,
  InfoIcon,
  GraphPageSlider,
} from "../../style/display";
import { arrayToObject, getCurrentIndexRowDataAndColumn } from "../../util";
import LedgerTransactionView from "./index";
import MultipleUsers from "common/Components/MultipleUsers";
import { useSelector } from "react-redux";
import TransactionCreateView from "../Create";
import Button from "components/Button";
import Icon from "components/Icon";
import Tooltip from "components/Tooltip2";
import PageSlider from "components/PageSlider";
import { usePageSlider } from "hooks/usePageSlider";
import GraphView from "./GraphView";

const getLedgerRows = createTableDataSelector({
  tableName: "ledger.categories",
  blackList: { "Sl No": true },
});

const AmountCard = ({ backgroundColor, circleColor, heading, amount }) => {
  return (
    <Item $color={backgroundColor}>
      {heading} <Amount>{amount}</Amount>
      <Circle $color={circleColor} $top={true} />
      <Circle $color={circleColor} />
    </Item>
  );
};

const DetailView = ({ data: info, isAdmin, onClose }) => {
  const { rowIndex } = info;
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { rows, cellNameToColumnMap } = useSelector(getLedgerRows);

  const { data = [] } = useMemo(
    () => getCurrentIndexRowDataAndColumn(rows, rowIndex),
    [rows, rowIndex]
  );

  const { isSliderActive, onCellClick, toggleSlider } = usePageSlider();

  const amountDetails = useSelector(
    (store) => store.ledger.transactions.amountDetails
  );

  const chartData = useSelector((store) => store.ledger.transactions.chartData);

  const amounts = useMemo(() => {
    const amountsInString = amountDetails;
    const strArr = amountsInString?.split(";") || [];
    const newStrArr = strArr.map((item) => {
      return item.trim().split(":");
    });

    return arrayToObject(newStrArr);
  }, [amountDetails]);

  const updateFormVisibility = () => {
    setIsFormVisible((prev) => !prev);
  };

  const usersData = useMemo(() => {
    return data[cellNameToColumnMap?.activeLedgerGroupMembers];
  }, [data, cellNameToColumnMap]);

  const currentTab = useMemo(() => {
    return (
      <DetailsContainer>
        <Header>
          <HeadingLeft>
            <SubHeading>
              <BackButton onClick={onClose}>
                <Icon name="fas fa-angle-left" />
              </BackButton>
              <Heading>{data[cellNameToColumnMap?.name]}</Heading>
              {data[cellNameToColumnMap?.description] !== "NA" && (
                <InfoIcon
                  data-tooltip="info-button"
                  name="fas fa-info-circle"
                />
              )}
              <Tooltip
                style={{ background: "#0D0543" }}
                position="bottom"
                tooltipId="info-button"
              >
                <Description>
                  {data[cellNameToColumnMap?.description]}
                </Description>
              </Tooltip>
            </SubHeading>

            {/* <Description>
            <DescriptionEllipsis>
              
            </DescriptionEllipsis>
          </Description> */}
            <SubHeading>
              <MemberContainer>
                <span style={{ marginRight: 10 }}>Members:</span>
                {usersData != "NA" ? (
                  <MultipleUsers data={usersData} />
                ) : (
                  usersData
                )}
              </MemberContainer>
              <Button primary onClick={updateFormVisibility}>
                Add {isAdmin ? "a Transaction" : "an Expense"}
              </Button>
            </SubHeading>
          </HeadingLeft>
          <div>
            <HeadingRight>
              <AmountCard
                backgroundColor="#EFFFF5"
                circleColor="#D0F7E0"
                heading="Budget"
                amount={data[cellNameToColumnMap?.budget]}
              />
              <AmountCard
                backgroundColor="#F2FEFF"
                circleColor="#D4F2F5"
                heading="Amount Credited"
                amount={amounts.total_credited_amount || "NA"}
              />
              <AmountCard
                backgroundColor="#FFF4F3"
                circleColor="#FCDCD9"
                heading="Amount Debited"
                amount={amounts.total_debited_amount || "NA"}
              />
              <AmountCard
                backgroundColor="#FFFAE8"
                circleColor="#FBEEC1"
                heading="Balance"
                amount={amounts.total_remaining_balance || "NA"}
              />
            </HeadingRight>
          </div>
        </Header>
        <LedgerTransactionView
          toggleSlider={toggleSlider}
          chartData={chartData}
          usersData={data[cellNameToColumnMap?.activeLedgerGroupMembers]}
        />
      </DetailsContainer>
    );
  }, [
    data,
    cellNameToColumnMap,
    isAdmin,
    isFormVisible,
    amountDetails,
    amounts,
    usersData,
    onClose,
  ]);

  if (isFormVisible) {
    return (
      <TransactionCreateView updateFormVisibility={updateFormVisibility} />
    );
  }

  return (
    <>
      {currentTab}
      <GraphPageSlider
        showBackButton={false}
        onClose={toggleSlider}
        active={isSliderActive}
      >
        <GraphView onClose={toggleSlider} data={chartData} />
      </GraphPageSlider>
    </>
  );
};

export default DetailView;
