import React, { useMemo, useState } from "react";
import ProfilePicTagLists from "./ProfilePicTagLists";

import {
  useFetchTodayAnniversaries,
  useFetchUpcomingAnniversaries,
} from "../../../../hooks/Anniversary";

import ProfileSkeletonGroup from "./ProfileSkeletonGroup";
import Party_Img from "assets/employee/party.png";
import Popper_Img from "assets/employee/popper.png";

import { InfoContent, InfoHeader, InfoItem, BigCakeImage } from "./style";

function AnniversarySection() {
  const {
    isLoading: loadingTodayAnniversaries,
    allTodayAnniversaries,
    hasError: errorInToday,
    fetchNextPage: fetchNextPageToday,
    allDataFethced: todayAllDataFethced,
  } = useFetchTodayAnniversaries();
  const {
    isLoading: loadingUpcomingAnniversaries,
    allUpcomingAnniversaries,
    hasError: errorInUpcoming,
    fetchNextPage: fetchNextPageUpComing,
    allDataFethced: upcomingAllDataFethced,
  } = useFetchUpcomingAnniversaries();

  const loadMoreData = (type) => {
    if (type === "today") {
      fetchNextPageToday();
    } else if (type === "upcoming") {
      fetchNextPageUpComing();
    }
  };

  const isEmpty = (value) => {
    if (!Array.isArray(value) || value.length === 0) {
      return true;
    }
    return false;
  };

  const isTodayEmpty = useMemo(() => {
    return isEmpty(allTodayAnniversaries);
  }, [allTodayAnniversaries]);

  const isUpcomingEmpty = useMemo(() => {
    return isEmpty(allUpcomingAnniversaries);
  }, [allUpcomingAnniversaries]);
  return (
    <>
      <InfoItem>
        <InfoHeader>Work Anniversaries today</InfoHeader>
        <InfoContent>
          {!loadingTodayAnniversaries && isTodayEmpty && (
            <>
              <BigCakeImage src={Party_Img} alt="People partying" />
              <span className="desc">
                No one is having work anniversary today
              </span>
            </>
          )}
          {!errorInToday && allTodayAnniversaries.length > 0 && (
            <ProfilePicTagLists
              data={allTodayAnniversaries}
              tagImage={Popper_Img}
              showMore={!todayAllDataFethced}
              onMoreClick={() => loadMoreData("today")}
            />
          )}
          {loadingTodayAnniversaries && <ProfileSkeletonGroup count={3} />}
        </InfoContent>
      </InfoItem>

      <InfoItem>
        <InfoHeader>Next 7 days</InfoHeader>
        <InfoContent>
          {!loadingUpcomingAnniversaries && isUpcomingEmpty && (
            <>
              <BigCakeImage src={Party_Img} alt="People partying" />
              <span className="desc">
                No one is having anniversary in upcoming days
              </span>
            </>
          )}
          {!errorInUpcoming && allUpcomingAnniversaries.length > 0 && (
            <ProfilePicTagLists
              data={allUpcomingAnniversaries}
              tagImage={Popper_Img}
              showMore={!upcomingAllDataFethced}
              onMoreClick={() => loadMoreData("upcoming")}
            />
          )}
          {loadingUpcomingAnniversaries && <ProfileSkeletonGroup count={3} />}
        </InfoContent>
      </InfoItem>
    </>
  );
}

export default AnniversarySection;
