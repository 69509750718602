import React, { useCallback, useRef, useState, useMemo } from "react";

import User from "./User";

import { TooltipContainer, TooltipContent } from "../Tooltip";
import Tooltip from "../Tooltip2";

import useDrawer from "hooks/useDrawer";
import useUserInformation from "hooks/useUserInformation";

import NotificationModule from "../../../Modules/Notification/View";
import BreaksModule from "../../../Modules/Breaks/Views/Navbar";
import SettingsModule from "../SettingsCenter";
import {
  getCurrentUserId,
  getCurrentUserNotificationCount,
  isCurrentUserIsExecutive,
} from "../../Selectors/auth";

import Menu_Img from "assets/menuBar.svg";
import Software_Img from "assets/OMS_light.svg";
import Notification_Icon from "assets/common/Notification.svg";
import Break_Icon from "assets/employee/Tea.svg";
import Settings_Icon from "assets/common/setting.svg";

import {
  NavbarContainer,
  NavTitle,
  ToggleButton,
  MenuBar,
  FeatureContainer,
  FeatureIcon,
  RightContainer,
  StyledCounter,
} from "./style";
import { connect, useSelector } from "react-redux";

const Navbar = ({ notificationCount, isAdmin, isBreakOngoing }) => {
  const [notificationAnchor, updateNotifiactionAnchor] = useState(null);
  const [breaksAnchor, updateBreaksAnchor] = useState(null);
  const [settingsAnchor, updateSettingsAnchor] = useState(null);
  const { openDrawer, closeDrawer, isDrawerActive } = useDrawer();
  const { userInfo } = useUserInformation();
  const userId = useSelector(getCurrentUserId);

  const notificationContainerRef = useRef(null);
  const settingContainerRef = useRef(null);
  const breaksContainerRef = useRef(null);

  const toggleNotification = useCallback(() => {
    updateNotifiactionAnchor((val) => {
      return val ? null : notificationContainerRef.current;
    });
  }, []);

  const toggleBreaks = useCallback(() => {
    updateBreaksAnchor((val) => {
      return val ? null : breaksContainerRef.current;
    });
  }, []);

  const toggleSettings = useCallback(() => {
    updateSettingsAnchor((val) => {
      return val ? null : settingContainerRef.current;
    });
  }, []);

  const userImage = useMemo(() => {
    let res = { cdn: "", img: "" };
    if (userInfo && userInfo.avatarUrl) {
      const { cdnThumbUrl, thumbUrl } = userInfo.avatarUrl;
      res = {
        cdn: cdnThumbUrl,
        img: thumbUrl,
      };
    }
    return res;
  }, [userInfo]);
  return (
    <NavbarContainer>
      <ToggleButton
        size="auto"
        onClick={isDrawerActive ? closeDrawer : openDrawer}
      >
        <MenuBar src={Menu_Img} />
      </ToggleButton>

      <NavTitle src={Software_Img} />

      <RightContainer>
        {!isAdmin && (
          <FeatureContainer
            ref={breaksContainerRef}
            className="breaks-icon-container"
          >
            <FeatureIcon
              onClick={toggleBreaks}
              src={Break_Icon}
              alt="break icon"
              className="break-icon"
              data-tooltip="nav-breaks"
            />

            <Tooltip tooltipId="nav-breaks">Breaks</Tooltip>
          </FeatureContainer>
        )}
        <FeatureContainer ref={notificationContainerRef}>
          <FeatureIcon
            onClick={toggleNotification}
            src={Notification_Icon}
            alt="bell icon"
            data-tooltip="nav-notifications"
          />
          <Tooltip tooltipId="nav-notifications">Notifications</Tooltip>

          {!!notificationCount && <StyledCounter count={notificationCount} />}
        </FeatureContainer>
        <FeatureContainer ref={settingContainerRef}>
          <FeatureIcon
            onClick={toggleSettings}
            src={Settings_Icon}
            alt="settings icon"
            data-tooltip="nav-settings"
          />
          <Tooltip tooltipId="nav-settings">Settings</Tooltip>
        </FeatureContainer>
        <User
          id={userId}
          name={userInfo ? userInfo.name : ""}
          img={userImage.img}
          cdnImg={userImage.cdn}
          isInActive={isBreakOngoing}
        />
      </RightContainer>

      <BreaksModule onClose={toggleBreaks} elemIdOrElem={breaksAnchor} />
      <NotificationModule
        onClose={toggleNotification}
        elemIdOrElem={notificationAnchor}
      />
      <SettingsModule onClose={toggleSettings} elemIdOrElem={settingsAnchor} />
    </NavbarContainer>
  );
};

const mapStateToProps = (store) => {
  const notificationCount = getCurrentUserNotificationCount(store);
  const isBreakOngoing = !!store.breaks.collaboratorSnaps.ongoingBreak;
  const isAdmin = isCurrentUserIsExecutive(store);
  return {
    notificationCount,
    isAdmin,
    isBreakOngoing,
  };
};

export default connect(mapStateToProps, null)(Navbar);
