import styled, { keyframes, css } from "styled-components";

import Button from "components/Button";
import FormGroup from "components/FormGroup";
import { ClockIcon } from "../../../AssetComponents";
import { scrollbar1 } from "globalStyles";

const contentCss = css`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  padding: 20px;
`;

const expand = keyframes`
 0%{
  transform: translateY(-10px) scale(0);
 }

 33%{
  transform: translateY(-10px) scale(1);
 }

 66%{
  transform: translateY(-10px) scale(0.5);
 }

 100%{
  transform: translateY(-10px) scale(1);
 }
`;

export const Container = styled.div`
  z-index: 3;
  background-color: white;
  border-radius: 6px;
  box-shadow: ${(props) => props.theme.shadow.large};
  min-height: 300px;
  display: flex;
  flex-flow: column nowrap;
  ${scrollbar1}
`;

export const Header = styled.div`
  flex-basis: 50px;
  display: flex;
  align-items: center;
  padding-right: 15px;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.colors.primaryColor};
  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const CloseButton = styled(Button)`
  margin-left: auto;
  width: 20px;
  height: 20px;
  background-color: transparent;
  font-size: 20px;
  color: white;
`;

export const StyledFormGroup = styled(FormGroup)`
  ${contentCss}
`;

export const CheckBoxWrapper = styled.div`
  & input + label {
    padding-left: 2px;
  }
`;

export const Divider = styled.div`
  width: 20px;
  height: 2px;
  background-color: black;
  margin: 0 10px;
  position: relative;
  top: 35px;
  left: 0;
`;
export const GroupDateTimeContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledClockIcon = styled(ClockIcon)`
  position: relative;
  top: 25px;
  left: 0;
  flex-basis: auto;
  height: 17px;
  flex-shrink: 0;
`;

export const DateTimeContainer = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`;
export const DateContainer = styled.div`
  flex-grow: 1;
  max-width: 200px;
`;
export const TimeContainer = styled.div`
  flex-basis: 80px;
`;

export const CombinedDateTime = styled.div`
  display: flex;
`;
export const CombinedItem = styled.div`
  flex-gorw: 1;
`;

export const UserListContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding: 10px 20px;
  margin-bottom: 20px;
`;

export const CalendarSelectGroup = styled.div`
  display: flex;
  align-items: center;
`;
export const CalendarSelectColor = styled.div`
  flex-basis: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
  transform: translateY(-10px) scale(0);
  animation: ${expand} ease 0.5s forwards;
`;

export const EventContentArea = styled.div`
  ${contentCss}
`;

export const Heading = styled.span`
  color: ${(props) => props.theme.colors.primaryColor};
  font-size: ${(props) => props.theme.text.title.large};
  font-weight: 600;
  margin-bottom: 14px;
  text-transform: capitalize;
`;

export const EventItem = styled.div`
  display: flex;
  margin-bottom: 16px;
  font-size: ${(props) => props.theme.text.para.small};
  align-items: center;
`;

export const ItemIcon = styled.div`
  flex-basis: 20px;
  & > img {
    width: 16px;
    height: 16px;
  }
`;

export const ItemContent = styled.div`
  flex-grow: 1;
  padding-left: 23px;
  max-width: 350px;
  line-height: 24px;
`;
