import styled from "styled-components";
import { InputContainer, GlobalInput } from "../style";

export const FilePickerContainer = styled(InputContainer)`
  height: auto;
  width: auto;
  padding: 0 80px 0 0;
`;

export const Attachment = styled.div`
  width: 200px;
  height: 100%;
  margin: 10px 20px 0 0;
  padding: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.text.title.large};
  color: ${(props) => props.theme.colors.filePicker.attachment};
  flex-shrink: 0;
  border: 1px ${(props) => props.theme.colors.input.primary.borderColor} solid;
  border-radius: 5px;

  & * {
    margin-right: 10px;
  }
`;

export const AttachmentText = styled.span`
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: 500px;
  color: ${(props) => props.theme.colors.filePicker.text};
`;

export const DeleteAttachment = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.text.para.large};
  color: ${(props) => props.theme.colors.filePicker.attachment};
  margin: 0;
  cursor: pointer;
  opacity: 0.8;
`;

export const AttachmentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FileInputContainer = styled(GlobalInput)`
  border: none;
  color: ${(props) => props.theme.colors.filePicker.text};
  font-size: ${(props) => props.theme.text.title.large};
  cursor: pointer;
  padding: 0;
  height: auto;
  width: 150px;
`;

export const FileInputButton = styled.div`
  border: 1px ${(props) => props.theme.colors.input.primary.borderColor} solid;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 10px;
  width: 150px;
`;

export const UploadText = styled.span`
  font-size: ${(props) => props.theme.text.para.large};
  font-weight: 400;
  margin-left: 10px;
  color: black;
`;
