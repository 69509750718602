import styled, { css } from "styled-components";
import { flexColumnCenter } from "./flex";
import FormGroup from "../Common/Components/FormGroup";

export const PageContainer = styled.div`
  height: 100%;
  padding: 0 50px 20px 50px;
  display: flex;
  flex-flow: column nowrap;
`;

export const ViewContainer = styled(flexColumnCenter)`
  height: 100%;
  padding: 10px;
  @media (min-width: 1024px) {
    padding: 10px 58px;
  }
`;

export const StyledActionArea = styled.div`
  margin: 10px 0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  flex-basis: 50px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
`;

export const PaginatorBox = styled.div`
  margin: 0 auto;
  @media (min-width: 1024px) {
    margin-right: 0;
    margin-left: auto;
  }
`;

export const TableContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  padding: 10px;
`;

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  width: 100%;
  max-width: 780px;
`;

export const StyledInputGroups = styled.div`
  display: flex;
  flex-flow: ${(props) => (props.column ? "column" : "row")} wrap;

  & > * {
    flex-grow: 1;
    flex-basis: 365px;
  }

  @media (min-width: 768px) {
    ${(props) => {
      if (!props.column) {
        return `
         & > div {
          margin-right: 5px;
        }
        `;
      }
    }}
  }
`;
export const NoticeViewList = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding-bottom: 30px;
  }
`;

export const FormHeading = styled.h3`
  font-size: ${(props) => props.theme.text.title.large};
  color: ${(props) => props.theme.colors.primaryColor};
  margin-bottom: 20px;
  margin-top: 45px;
  font-weight: 600;
  text-align: left;
`;

export const commonScrollbarStyle = css`
  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: whitesmoke;
    border-radius: 10px;
  }
`;

export const scrollbar = css`
  ${commonScrollbarStyle}
`;

export const scrollbar1 = css`
  ${commonScrollbarStyle}
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray;
  }
  &:hover::-webkit-scrollbar-thumb {
    display: block;
  }
  &:hover::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
`;

export const Spacer = styled.div`
  height: 1px;
  margin-bottom: 30px;
`;

export const hideScrollbar = css`
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
