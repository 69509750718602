import styled, { css } from "styled-components";
import { RectangleSkeleton } from "components/Skeleton";
import Icon from "components/Icon";

export const Container = styled.div`
  padding: 20px 63px 0 63px;
  min-height: 800px;
  display: flex;
  flex-flow: column nowrap;
  .attendance-list-container {
    display: flex;
    flex-grow: 0;
  }
  @media (max-width: 1024px) {
    .attendance-list-container {
      flex-direction: column;
    }
  }
`;

export const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  & > button + button {
    margin-left: 10px;
  }
`;
export const WorkHourCont = styled.div`
  margin: 0 50px;
  width: 100%;
  min-width: 250px;
  min-height: 50px;
  background: white;
  border: 1px solid #dfdfdf;
  box-shadow: ${(props) => props.theme.shadow.medium};
  border-radius: 8px;
  display: flex;
  padding: 0 20px;
  flex-flow: column nowrap;
`;

export const WorkHourHead = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;

export const WorkHeadText = styled.span`
  width: 150px;
  padding: 18px;
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.large};
  color: ${(props) => props.theme.colors.primaryColor};
`;

export const WorkHourContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
`;

export const WorkHourData = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
`;

export const WorkHourDataHead = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.medium};
  color: ${(props) => props.theme.colors.primaryColor};
  margin-right: 20px;
  text-align: left;
`;

export const WorkHourDataText = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.text.para.small};
  color: #333333;
  text-align: left;
`;

export const FormCont = styled.form`
  flex-grow: 1;
  display: flex;
  // flex-flow: row wrap;
  align-items: center;
  & > div {
    margin-right: 10px;
    & > div {
      height: 32px;
      & > button,
      & > select {
        padding: 0 0 0 13px;
      }
    }
  }
`;

export const FormButtons = styled.div`
  flex-basis: 200px;
  display: flex;
  margin-bottom: 23px;
  & > button + button {
    margin-left: 10px;
  }

  & > button {
    height: 32px;
  }
`;

export const StyledPaginatorCont = styled.div`
  flex-basis: 150px;
`;

export const StyledActionArea = styled.div`
  margin: 10px 0;
  padding: 10px;
  display: flex;
  flex-basis: 50px;
  width: 100%;
`;

export const StyledSkeleton = styled(RectangleSkeleton)`
  height: 20px;
  width: auto;
  & > div {
    width: 80px;
    height: 20px;
  }
`;

export const StyledContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & button {
    min-width: 350px;
  }

  & button:hover {
    background: ${(props) => props.theme.colors.button.primary.background};
    color: ${(props) => props.theme.colors.button.primary.color};
  }
`;

export const ButtonContainer = styled.div`
  margin: 0px 5px;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
`;

export const CellIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.primaryColor};
  margin-left: 10px;
  font-size: 15px;
`;
