import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import DrawerLink from "../DrawerLink";
import TabsRenderer from "./TabsRenderer";

import { generateDrawerLinks } from "helper";
import { LeaveIcon } from "../../../../AssetComponents";

const [tabLinks, LinkComponents] = generateDrawerLinks(
  Routes.authorized.leave,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

export const LeaveTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType("leave")}
    >
      {/* <DrawerTitle title="User Section" /> */}
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("leave")}
        SvgComponent={<LeaveIcon />}
        title="Apply for leave"
        links={tabLinks}
      >
        {LinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
