import {
  FETCH_TODAY_ANNIVERSARY,
  FETCH_UPCOMINGS_ANNIVERSARY,
} from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import { fetchTodaysAnniversaryApi, fetchUpcomingAnniversarysApi } from "./api";

const fetchTodaysAnniversaryGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: FETCH_TODAY_ANNIVERSARY,
    sideEffect: fetchTodaysAnniversaryApi({ companyId, isAdmin, pageNumber }),
  };
};

const fetchUpcomingAnniversariesGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: FETCH_UPCOMINGS_ANNIVERSARY,
    sideEffect: fetchUpcomingAnniversarysApi({
      companyId,
      isAdmin,
      pageNumber,
    }),
  };
};

export const [fetchTodaysAnniversaries, fetchUpcomingAnniversaries] =
  createAsyncActionCreator([
    fetchTodaysAnniversaryGenrator,
    fetchUpcomingAnniversariesGenrator,
  ]);
