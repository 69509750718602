import { useEffect } from "react";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import usePaginator from "hooks/usePaginator";
import usePagination from "hooks/usePagination";

import { useSelector } from "react-redux";
import { fetchCollaboratorDetails } from "../actionCreator";
import { userProfileDataSelector } from "../selector";

export function useFetchCollaboratorDetails(collaboratorId) {
  const { loadingNextPage, loadingPrevPage, getNextPage } = usePagination({
    actionCreator: fetchCollaboratorDetails,
    tableName: "userProfile",
  });

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const data = useSelector((store) =>
    userProfileDataSelector(store, { id: collaboratorId }),
  );

  const { fetchFirstPage } = usePaginator({
    paginatorApi: getNextPage,
    extraParams: { companyId, collaboratorId, isAdmin },
  });

  useEffect(() => {
    fetchFirstPage();
  }, []);

  return {
    isLoading: loadingNextPage || loadingPrevPage,
    userData: data,
    fetchFirstPage,
  };
}
