import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import DrawerLink from "../DrawerLink";
import TabsRenderer from "./TabsRenderer";

import { generateDrawerLinks } from "helper";

import Dep_Img from "assets/Activity.svg";
import Job_Img from "assets/Bookmark.svg";

import E_Dep_Img from "assets/employee/Activity.svg";
import E_Job_Img from "assets/employee/Bookmark.svg";

const [departmentTabLinks, DepartmentLinkComponents] = generateDrawerLinks(
  Routes.authorized.department,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

const [jobTitleTabLinks, JobTitleLinkComponents] = generateDrawerLinks(
  Routes.authorized.jobTitle,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

export const AbcTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType("abc")}
    >
      {/* <DrawerTitle title="ABC" /> */}
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("department")}
        image={isAdmin ? Dep_Img : E_Dep_Img}
        title="Department"
        links={departmentTabLinks}
      >
        {DepartmentLinkComponents}
      </TabsRenderer>

      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("jobTitle")}
        image={isAdmin ? Job_Img : E_Job_Img}
        title="Job Title"
        links={jobTitleTabLinks}
      >
        {JobTitleLinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
