import React from "react";
import { PaginatorBox } from "../../../../../GlobalStyles";
import { EnhancedActionArea } from "../../../style/display";
import Paginator from "components/Paginator";
import { useMembersViewPagination } from "../../../Hooks/useMembersViewPagination";

const ActionArea = () => {
  const {
    currentPageNumber,
    totalPages,
    fetchNextPage,
    fetchPrevPage,
    fetchSpecificPage,
    loadingNextPage,
    loadingPrevPage,
  } = useMembersViewPagination();

  return (
    <EnhancedActionArea>
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </EnhancedActionArea>
  );
};

export default ActionArea;
