import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useForm from "hooks/useForm";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { uploadImage } from "../actionCreator";
import useAlert from "hooks/useAlert";
import { validateFileSize } from "helper";

const validateFile = validateFileSize.bind(this, 5242880);

export const useAvatarUpdate = ({ initialValue = "" }) => {
  const { formData, values, isFormValid, updateField } = useForm({
    fields: {
      avatar: {
        required: true,
        validator: validateFile,
        invalidMessage: "File uploaded is exceeding size limit",
      },
    },
  });

  const { showAlert } = useAlert();
  const [isLoading, updateLoading] = useState(false);
  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const dispatch = useDispatch();

  const setInitialAvatarData = () => {
    const avatarData = [
      {
        name: initialValue,
      },
    ];
    updateField("avatar", avatarData);
  };

  useEffect(() => {
    if (initialValue) setInitialAvatarData();
  }, []);

  const onFormSubmit = useCallback(async () => {
    let success = false;
    if (!isFormValid()) {
      return success;
    }

    try {
      updateLoading(true);
      await dispatch(uploadImage({ companyId, isAdmin, formData: values }));
      showAlert({ message: "Avatar Uploaded" });
      success = true;
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
    return success;
  }, [isFormValid, values]);

  return {
    formData,
    isLoading,
    onFormSubmit,
  };
};
