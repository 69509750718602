import { useEffect, useRef } from "react";

const debounce = (func) => {
  let timer = null;
  return (props) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func(props);
    }, 500);
  };
};

export const useResizeObserver = () => {
  const observerRef = useRef(null);
  const observeChanges = (domIdOrElem, onChange) => {
    const debouncedFunc = debounce(onChange);
    const resizeObserver = new ResizeObserver((entries) => {
      onChange && debouncedFunc(entries);
    });
    observerRef.current = resizeObserver;
    const elem =
      typeof domIdOrElem === "string"
        ? document.getElementById(domIdOrElem)
        : domIdOrElem;
    resizeObserver.observe(elem);
  };

  const unobserveChanges = () => {
    observerRef.current && observerRef.current.disconnect();
  };

  useEffect(() => {
    return () => {
      unobserveChanges();
    };
  }, []);

  return {
    observeChanges,
    unobserveChanges,
  };
};
