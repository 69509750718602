import { useMemo } from "react";

const useClipText = ({ text = "", maxChar = 40 }) => {
  const newText = useMemo(() => {
    if (!text || typeof text !== "string" || text.length <= maxChar) {
      return text;
    }
    return `${text.substring(0, maxChar)}...`;
  }, [text, maxChar]);

  return {
    clippedText: newText,
  };
};

export default useClipText;
