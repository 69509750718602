import InputGroup from "components/InputGroup";

export const DocumentsForm = () => {
  return (
    <>
      <InputGroup
        id="personalInfoCreate_aadharCardFront"
        fieldType="googleFilePicker"
        required
        accept="image/*,.pdf"
        allowCompression
        label="Upload Aadhar Card Front Side"
      />

      <InputGroup
        id="personalInfoCreate_aadharCardBack"
        fieldType="googleFilePicker"
        required
        accept="image/*,.pdf"
        allowCompression
        label="Upload Aadhar Card Back Side"
      />

      <InputGroup
        id="personalInfoCreate_voterIdCardFront"
        fieldType="googleFilePicker"
        required
        accept="image/*,.pdf"
        allowCompression
        label="Upload Voter ID Card Front Side"
      />

      <InputGroup
        id="personalInfoCreate_voterIdCardBack"
        fieldType="googleFilePicker"
        required
        accept="image/*,.pdf"
        allowCompression
        label="Upload Voter ID Card Back Side"
      />

      <InputGroup
        id="personalInfoCreate_panCard"
        fieldType="googleFilePicker"
        required
        accept="image/*,.pdf"
        allowCompression
        label="Upload Pan Card"
      />

      <InputGroup
        id="personalInfoCreate_passport"
        fieldType="googleFilePicker"
        required
        accept="image/*,.pdf"
        allowCompression
        label="Upload Passport"
      />

      <InputGroup
        id="personalInfoCreate_passportSizePhoto"
        fieldType="googleFilePicker"
        required
        accept="image/*"
        allowCompression
        label="Upload Passport Size Photo"
      />
    </>
  )
}