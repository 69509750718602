import {
  PUBLISH_NOTICE,
  GET_SENT_NOTICES,
  GET_EMPLOYER_NOTICES,
  SEARCH_NOTICES,
  UPDATE_SEARCH_PARAMS,
  RESET_SEARCH_PARAMS,
} from "../actiontypes";
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData,
} from "../../../Helper";

const initialData = createInitialStoreState({
  isSearchOn: false
});

export const sentNoticesReducer = (store = initialData, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_SENT_NOTICES}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${GET_EMPLOYER_NOTICES}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${PUBLISH_NOTICE}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);

    case `${SEARCH_NOTICES}_SUCCESS`:
      const { isPublished } = payload;
      if (isPublished) {
        return createNewStoreOnGetData(payload, store);
      }
      return store;

    case `${UPDATE_SEARCH_PARAMS}`: {
      const { title, is_published, from_date, to_date } = payload;
      if (is_published && (title || from_date || to_date)) {
        return {
          ...store,
          onceFetched: false,
          currentTitle: title,
          isPublished: is_published,
          currentFromDate: from_date,
          currentToDate: to_date,
          isSearchOn: true
        };
      }
      return store;
    }

    case `${RESET_SEARCH_PARAMS}`: {
      const { is_published } = payload;
      if (is_published) {
        return {
          ...store,
          onceFetched: false,
          currentTitle: "",
          isPublished: "",
          currentFromDate: "",
          currentToDate: "",
          isSearchOn: false
        };
      }
      return store;
    }

    default:
      return store;
  }
};
