import {
  CREATE_LEDGER_CATEGORIES,
  GET_LEDGER_CATEGORIES,
  SET_CURRENT_LEDGER_ID,
  GET_LEDGER_TRANSACTIONS,
  CREATE_LEDGER_TRANSACTIONS,
  CLEAR_LEDGER_TRANSACTIONS,
  UPDATE_LEDGER_TRANSACTIONS,
} from "./actiontypes";

import { createAsyncActionCreator } from "../../Helper";

import {
  createLedgerCategoryApi,
  createLedgerTransactionsApi,
  getLedgersCategoriesApi,
  getLedgersTransactionsApi,
  updateLedgerTransactionsApi,
} from "./api";

const getLedgerCategoriesGenerator = function* ({
  companyId,
  pageNumber,
  isAdmin,
}) {
  yield {
    action: GET_LEDGER_CATEGORIES,
    sideEffect: getLedgersCategoriesApi({ companyId, pageNumber, isAdmin }),
  };
};

const createLedgerCategoryGenerator = function* ({
  companyId,
  formData,
  isAdmin,
}) {
  yield {
    action: CREATE_LEDGER_CATEGORIES,
    sideEffect: createLedgerCategoryApi({ companyId, formData, isAdmin }),
  };
};

const getLedgerTransactionsGenerator = function* ({
  companyId,
  ledgerId,
  pageNumber,
  isAdmin,
}) {
  yield {
    action: GET_LEDGER_TRANSACTIONS,
    sideEffect: getLedgersTransactionsApi({
      companyId,
      ledgerId,
      pageNumber,
      isAdmin,
    }),
  };
};

const createLedgerTransactionsGenerator = function* ({
  companyId,
  categoryId,
  isAdmin,
  formData,
}) {
  yield {
    action: CREATE_LEDGER_TRANSACTIONS,
    sideEffect: createLedgerTransactionsApi({
      companyId,
      categoryId,
      isAdmin,
      formData,
    }),
  };
};

const updateLedgerTransactionGenerator = function* ({
  companyId,
  categoryId,
  transactionId,
  isAdmin,
  formData,
}) {
  yield {
    action: UPDATE_LEDGER_TRANSACTIONS,
    sideEffect: updateLedgerTransactionsApi({
      companyId,
      categoryId,
      transactionId,
      isAdmin,
      formData,
    }),
  };
};

export const setCurrentLedgerId = ({ ledgerId }) => ({
  type: SET_CURRENT_LEDGER_ID,
  payload: { ledgerId },
});

export const clearLedgerTransactions = () => ({
  type: CLEAR_LEDGER_TRANSACTIONS,
});

export const [
  getLedgersCategories,
  getLedgerTransactions,
  createLedgerTransactions,
  createLedgerCategory,
  updateLedgerTransaction,
] = createAsyncActionCreator([
  getLedgerCategoriesGenerator,
  getLedgerTransactionsGenerator,
  createLedgerTransactionsGenerator,
  createLedgerCategoryGenerator,
  updateLedgerTransactionGenerator,
]);
