import React from "react";
import { withTheme } from "styled-components";

const Icon = ({ color, theme, ...otherProps }) => {
  return (
    <svg
      {...otherProps}
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill={theme.colors.primaryColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.95172 0.009552H9.68707C9.86312 0.009552 10.0316 0.0813433 10.1535 0.208344L14.5199 4.75662C14.6355 4.87703 14.7 5.03747 14.7 5.20438V13.1061C14.7 15.2605 12.9537 17.1242 10.8388 17.1242H3.95172C1.7281 17.1242 0 15.348 0 13.1061V3.87076C0 1.74906 1.8075 0.009552 3.95172 0.009552ZM13.4063 5.46449L9.41059 1.30242L3.95171 1.30319C2.51079 1.30319 1.29309 2.47509 1.29309 3.8713V13.1066C1.29309 14.6422 2.45032 15.8316 3.95171 15.8316H10.8388C12.22 15.8316 13.4069 14.565 13.4069 13.1066L13.4063 5.46449Z"
        fill={theme.colors.primaryColor}
      />
      <path
        d="M9.46058 0C9.7879 0 10.0584 0.243236 10.1012 0.558818L10.1071 0.646552V3.15431C10.1071 4.02194 10.8091 4.72576 11.6758 4.72759L14.0068 4.72726C14.3631 4.70377 14.671 4.97357 14.6945 5.32988C14.718 5.68618 14.4482 5.99407 14.0919 6.01756L12.6516 6.02247C12.364 6.02213 12.0368 6.02152 11.673 6.02069C10.1475 6.01746 8.90259 4.8218 8.81855 3.31671L8.81403 3.15431V0.646552C8.81403 0.289471 9.1035 0 9.46058 0Z"
        fill={theme.colors.primaryColor}
      />
      <path
        d="M9.29682 11.041C9.6539 11.041 9.94337 11.3305 9.94337 11.6876C9.94337 12.0149 9.70013 12.2854 9.38455 12.3282L9.29682 12.3341H4.64423C4.28715 12.3341 3.99768 12.0447 3.99768 11.6876C3.99768 11.3603 4.24092 11.0897 4.5565 11.0469L4.64423 11.041H9.29682Z"
        fill={theme.colors.primaryColor}
      />
      <path
        d="M7.53691 6.77191C7.89399 6.77191 8.18346 7.06138 8.18346 7.41846C8.18346 7.74579 7.94022 8.0163 7.62464 8.05911L7.53691 8.06502H4.64381C4.28672 8.06502 3.99725 7.77554 3.99725 7.41846C3.99725 7.09114 4.24049 6.82063 4.55607 6.77781L4.64381 6.77191H7.53691Z"
        fill={theme.colors.primaryColor}
      />
    </svg>
  );
};

export const LeaveIcon = withTheme(Icon);
