import React, { useCallback, useState, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "../../../../Common/Components/InputGroup";
import Button from "../../../../Common/Components/Button";
import useAlert from "../../../../Hooks/useAlert";

import { createJobTitle, updateJobTitle } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";

import { StyledFormGroup } from "../../../../GlobalStyles";

const Form = ({
  isEditMode = false,
  createJobTitle,
  updateJobTitle,
  currentCompnay,
  isInPopover = false,
  isAdmin,
}) => {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const { jobTitleCreate_jobTitle } = values;
        const data = {
          companyId: currentCompnay,
          jobTitle: jobTitleCreate_jobTitle,
          isAdmin,
        };
        isEditMode ? await updateJobTitle(data) : await createJobTitle(data);
        showAlert({ message: "Job Title Created" });
        resetForm();
      } catch (err) {
        showAlert({ message: "Job Title Create Failed", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [isEditMode, currentCompnay, isAdmin]
  );

  const [btnSize, formStyle] = useMemo(() => {
    return [
      isInPopover ? "sm" : "md",
      { alignItems: isInPopover ? "center" : "flex-start" },
    ];
  }, [isInPopover]);

  return (
    <StyledFormGroup
      style={formStyle}
      stopBubbling={true}
      onSubmit={onFormSubmit}
    >
      <InputGroup
        id="jobTitleCreate_jobTitle"
        label="Job Title"
        placeholder="Enter Job Title"
        required
        secondary
      />

      <Button
        size={btnSize}
        primary
        loading={isLoading}
        disabled={isLoading}
        type="submit"
      >
        {isEditMode ? "Update" : "Create"}
      </Button>
    </StyledFormGroup>
  );
};
const mapStateToProps = (store, props) => {
  const currentCompnay = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);

  return {
    currentCompnay,
    isAdmin,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createJobTitle,
      updateJobTitle,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
