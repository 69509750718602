import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { PaginatorBox } from "../../../../GlobalStyles";
import { SearchArea, EnhancedActionArea } from "../../style/display";
import Paginator from "components/Paginator";
import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";

import { getEnquiries, searchEnquiries, updateSearchParams, resetSearchParams, getSubordinateEnquiries } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";

import SearchFilter from "./SearchFilter";
import { extractDateInYYYYMMDD } from "../../../../Helper";

const ActionArea = ({
  updateSearchParams,
  resetSearchParams,
  collaborator,
  currentFromDate,
  currentToDate,
  enquiryType,
  currentStatus,
  isSearchOn,
  onceFetched,
  onLoading,
  style,
  companyId,
  isAdmin,
  subordinateId
}) => {
  const countRef = useRef({ isFirstTimeCheck: true });

  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: isSearchOn ? searchEnquiries : subordinateId ? getSubordinateEnquiries : getEnquiries,
    tableName: subordinateId ? "enquiries.subordinate" : "enquiries.collaborator",
  });

  const extraParams = { companyId, collaborator, currentFromDate, currentToDate, enquiryType, currentStatus, isAdmin, subordinateId }

  const { fetchFirstPage, fetchNextPage, fetchPrevPage, fetchSpecificPage } =
    usePaginator({ extraParams, paginatorApi: getNextPage });

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched && !isSearchOn) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  const formatDate = useCallback(
    (date) => {
      if (date)
        return extractDateInYYYYMMDD(date);
      else
        return ""
    },
    [],
  )

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      updateSearchParams({
        collaborator: values.enquirySearch_enquiryCollaborator,
        from_date: formatDate(values.enquirySearch_enquiryFromDate),
        to_date: formatDate(values.enquirySearch_enquiryToDate),
        enquiry_type: values.enquirySearch_enquiryEnquiryType,
        status: values.enquirySearch_enquiryStatus,
      })
    },
    [],
  );

  const onResetClick = () => {
    resetSearchParams();
  }

  return (
    <EnhancedActionArea style={style}>
      {!subordinateId &&
        <SearchArea isAdmin={isAdmin}>
          <SearchFilter
            companyId={companyId}
            isAdmin={isAdmin}
            onFormSubmit={onFormSubmit}
            onResetClick={onResetClick}
            collaborator={collaborator}
            from_date={currentFromDate}
            to_date={currentToDate}
            enquiry_type={enquiryType}
            status={currentStatus}
            isSearchOn={isSearchOn}
          />
        </SearchArea>
      }
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const subordinateId = store.enquiries.subordinate.collaboratorId;
  return {
    companyId,
    onceFetched: subordinateId ? store.enquiries.subordinate.onceFetched : store.enquiries.collaborator.onceFetched,
    collaborator: store.enquiries.collaborator.collaborator,
    currentFromDate: store.enquiries.collaborator.currentFromDate,
    currentToDate: store.enquiries.collaborator.currentToDate,
    enquiryType: store.enquiries.collaborator.enquiryType,
    currentStatus: store.enquiries.collaborator.currentStatus,
    isSearchOn: store.enquiries.collaborator.isSearchOn,
    isAdmin,
    subordinateId
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSearchParams,
      resetSearchParams,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActionArea);
