import React from "react";
import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";

import { ButtonGroup, StyledForm } from "../../../style/create";

const EmployeeForm = ({ onSubmit, formData, isFormValid, onBack }) => {
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (isFormValid() && typeof onSubmit === "function") {
      onSubmit();
    }
  };

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <InputGroup
        placeholder="Enter employee ID"
        label="Employee ID"
        {...formData["employeeId"]}
      />

      <InputGroup
        fieldType="datePicker"
        placeholder="Enter joining date dd/mm/yy"
        label="Joining Date"
        {...formData["joiningDate"]}
      />

      <ButtonGroup>
        <Button onClick={onBack} outline>
          Back
        </Button>
        <Button type="submit" primary>
          Next
        </Button>
      </ButtonGroup>
    </StyledForm>
  );
};

export default EmployeeForm;
