import styled from "styled-components";

export const MainConatiner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => props.theme.zIndex.popover};
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => props.theme.zIndex.popoverBackdrop};
`;

export const PopoverContainer = styled.div`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.popover};
  display: inline-block;
  width: auto;
  height: auto;
  background-color: white;
  box-shadow: ${(props) => props.theme.shadow.medium};
  --triangle-width: 30px;
  --triangle-height: 30px;
  border-radius: 6px;
`;

export const Triangle = styled.div`
  position: absolute;
  width: var(--triangle-width);
  height: var(--triangle-height);
  background-color: white;
  z-index: -1;
`;
