import React, { useCallback, useEffect, useState } from "react";

import { Popover } from "../PopoverAdvanced3";
import { Content } from "./style";

/**
 *
 * add data-tooltip="<id>" on the element where you want to show the tip
 * and then use the  tooltipId
 */

export function Tooltip({ tooltipId, position, children, ...rest }) {
  const [targetElem, setTargetElem] = useState(null);

  const activateTooltip = useCallback(
    (e) => {
      setTargetElem(e.target);
    },
    [tooltipId]
  );

  const deActivateTooltip = useCallback(() => {
    setTargetElem(null);
  }, [tooltipId]);

  useEffect(() => {
    if (!tooltipId) {
      return;
    }

    const elems = document.querySelectorAll(`[data-tooltip=${tooltipId}]`);
    for (const elem of elems) {
      elem.addEventListener("mouseenter", activateTooltip);
      elem.addEventListener("mouseleave", deActivateTooltip);
    }

    return () => {
      for (const elem of elems) {
        elem.removeEventListener("mouseenter", activateTooltip);
        elem.removeEventListener("mouseleave", deActivateTooltip);
      }
    };
  }, [tooltipId]);

  return targetElem ? (
    <Popover
      elemIdOrElem={targetElem}
      onClose={deActivateTooltip}
      position={position}
      blockScroll={false}
    >
      <Content $position={position} {...rest}>
        {children}
      </Content>
    </Popover>
  ) : null;
}

Tooltip.defaultProps = {
  position: "bottom" // top, bottom, left, right
};
