import React, { useMemo } from "react";
import { connect } from "react-redux";

import Grid from "components/Grid";
import { createTableDataSelector } from "../../../../Common/Selectors/MTable";

import { TableContainer } from "../../../../GlobalStyles";

const TableData = ({ data, onCellClick }) => {
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);

  return (
    <TableContainer>
      <Grid
        onClick={onCellClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        data={data}
      />
    </TableContainer>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "holidayYears",
    blackList: { "Sl No": true },
  });
  const maker = (store, props) => {
    const { rows } = getUserRows(store, props);
    return {
      data: rows,
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
