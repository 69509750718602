import { CREATE_FAMILY_DETAILS, GET_FAMILY_DETAILS, GET_FAMILY_DETAILS_BY_COLLABORATOR } from "./actiontypes";

import { createAsyncActionCreator } from "helper";

import { createFamilyDetailsApi, getFamilyDetailsApi, getFamilyDetailsByCollaboratorApi } from "./api";

const createFamilyDetailsGenrator = function* ({ companyId, formData }) {
  yield {
    action: CREATE_FAMILY_DETAILS,
    sideEffect: createFamilyDetailsApi({ companyId, formData }),
  };
};

export const [createFamilyDetails] = createAsyncActionCreator([
  createFamilyDetailsGenrator,
]);

const getFamilyDetailsGenerator = function* ({
  companyId,
  pageNumber,
  isAdmin,
}) {
  yield {
    action: GET_FAMILY_DETAILS,
    sideEffect: getFamilyDetailsApi({ companyId, pageNumber, isAdmin }),
  };
};

export const getFamilyDetails = createAsyncActionCreator(
  getFamilyDetailsGenerator
);

const getFamilyDetailsByCollaboratorGenerator = function* ({
  companyId,
  pageNumber,
  userId,
  isAdmin,
}) {
  yield {
    action: GET_FAMILY_DETAILS_BY_COLLABORATOR,
    sideEffect: getFamilyDetailsByCollaboratorApi({ companyId, pageNumber, userId, isAdmin }),
  };
};

export const getFamilyDetailsByCollaborator = createAsyncActionCreator(
  getFamilyDetailsByCollaboratorGenerator
);
