import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";

import { createWorkLog, editWorkLog } from "../actionCreator";

function useCreateEditWorkLog() {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const logWork = async (
    values,
    onFormReset,
    id,
    isEditMode = false,
    worklogId = ""
  ) => {
    try {
      updateLoading(true);
      if (isEditMode) {
        await dispatch(
          editWorkLog({
            companyId,
            isAdmin,
            formData: values,
            modelableType: "WorkingHourLog",
            modelableId: id,
            dailyReportId: worklogId
          })
        );
      } else {
        await dispatch(
          createWorkLog({
            companyId,
            isAdmin,
            formData: values,
            modelableType: "WorkingHourLog",
            modelableId: id
          })
        );
      }

      showAlert({ message: `Work log ${isEditMode ? "updated" : "added"}` });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
  };
  return { isLoading, logWork };
}

export default useCreateEditWorkLog;
