import Icon from "components/Icon";
import PiechartComponent, { LegendCell } from "components/Charts/PieChart";
import { BackButton, DetailsContainer } from "modules/Ledger/style/display";
import React, { useMemo } from "react";
import { Sector } from "recharts";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy - 15} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fill={fill}>
        {payload.formattedValue}
      </text>
      <text x={cx} y={cy + 40} dy={8} textAnchor="middle" fill={fill}>
        {`${(percent * 100).toFixed(2)}%`}
      </text>
      <circle
        cx={cx}
        cy={cy}
        stroke={fill}
        r={innerRadius - 10}
        fill={`${fill}15`}
      />

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {/* <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text> */}
    </g>
  );
};

const GraphView = ({ data, onClose }) => {
  const convertToINR = (value) => {
    return value.toLocaleString("en-IN", {
      maximumFractionDigits: 2,
      style: "currency",
      currency: "INR",
    });
  };

  const modifiedData = useMemo(() => {
    return data.map((item) => ({
      ...item,
      formattedValue: convertToINR(item.value),
    }));
  }, [data]);

  return (
    <DetailsContainer>
      {/* <BackButton onClick={onClose}>
        <Icon name="fas fa-angle-left" />
      </BackButton> */}
      <PiechartComponent data={modifiedData} heading="All Expenses">
        {renderActiveShape}
      </PiechartComponent>
      <div
        style={{
          backgroundColor: "white",
          width: "90%",
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {modifiedData.map((item) => {
          return <LegendCell color={item.colorCode} name={item.name} />;
        })}
      </div>
    </DetailsContainer>
  );
};

export default GraphView;
