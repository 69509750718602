import ProfileSkeletonGroup from "./ProfileSkeletonGroup";
import ProfilePicLists from "./ProfilePicLists";
import Icon from "components/Icon";

import {
  useFetchLeavesToday,
  useFetchPresentToday,
  useFetchAbsentToday,
} from "../../../../hooks/Attendance";

import {
  CardContainer,
  Section,
  ProfilePicContainer,
  SectionHeader,
  SectionText,
  HeaderRefreshContent,
} from "./style";
import { useDispatch } from "react-redux";
import {
  resetLeaves,
  resetAbsentees,
  resetPresentees,
} from "modules/WelcomePage/redux/attendance/actionCreator";

export const AttendanceCard = () => {
  const dispatch = useDispatch();
  const refreshData = () => {
    dispatch(resetLeaves());
    dispatch(resetPresentees());
    dispatch(resetAbsentees());
  };

  return (
    <CardContainer>
      <HeaderRefreshContent onClick={refreshData}>
        <Icon name="fas fa-sync" />
      </HeaderRefreshContent>

      <LeaveSection />
      <PresentSection />
      <AbsentSection />
    </CardContainer>
  );
};

function LeaveSection() {
  const {
    isLoading,
    fetchNextPage,
    peoplesOnLeaveToday,
    allDataFethced,
    leaveCount,
  } = useFetchLeavesToday();

  return (
    <Section>
      <SectionHeader>
        On leave {!!leaveCount && <span>({leaveCount})</span>}
      </SectionHeader>
      <ProfilePicContainer>
        <>
          {peoplesOnLeaveToday.length > 0 && (
            <ProfilePicLists
              data={peoplesOnLeaveToday}
              showMore={!allDataFethced && !isLoading}
              onMoreClick={fetchNextPage}
              type="leave"
            />
          )}
          {isLoading && !allDataFethced && <ProfileSkeletonGroup count={3} />}

          {!isLoading && peoplesOnLeaveToday.length === 0 && (
            <SectionText>No one is one leave today</SectionText>
          )}
        </>
      </ProfilePicContainer>
    </Section>
  );
}

function PresentSection() {
  const {
    isLoading,
    fetchNextPage,
    peoplesPresentToday,
    allDataFethced,
    presenteesCount,
  } = useFetchPresentToday();

  return (
    <Section>
      <SectionHeader>
        Present today {!!presenteesCount && <span>({presenteesCount})</span>}
      </SectionHeader>

      <ProfilePicContainer>
        <>
          {peoplesPresentToday.length > 0 && (
            <ProfilePicLists
              data={peoplesPresentToday}
              showMore={!allDataFethced && !isLoading}
              onMoreClick={fetchNextPage}
              showDot={true}
              type="present"
            />
          )}
          {isLoading && !allDataFethced && <ProfileSkeletonGroup count={3} />}

          {!isLoading && peoplesPresentToday.length === 0 && (
            <SectionText>No one is present today</SectionText>
          )}
        </>
      </ProfilePicContainer>
    </Section>
  );
}

function AbsentSection() {
  const {
    isLoading,
    fetchNextPage,
    peoplesAbsentToday,
    allDataFethced,
    absenteesCount,
  } = useFetchAbsentToday();

  return (
    <Section>
      <SectionHeader>
        Absent today {!!absenteesCount && <span>({absenteesCount})</span>}
      </SectionHeader>

      <ProfilePicContainer>
        <>
          {peoplesAbsentToday.length > 0 && (
            <ProfilePicLists
              data={peoplesAbsentToday}
              showMore={!allDataFethced && !isLoading}
              onMoreClick={fetchNextPage}
              type="absent"
            />
          )}
          {isLoading && !allDataFethced && <ProfileSkeletonGroup count={3} />}

          {!isLoading && peoplesAbsentToday.length === 0 && (
            <SectionText>No one is absent today</SectionText>
          )}
        </>
      </ProfilePicContainer>
    </Section>
  );
}
