import React from "react";
import ActivityIndicator from "../../../Common/Components/ActivityIndicator";
import { Container } from "../styles/loading";

const LoadingPage = () => {
  return (
    <Container>
      <ActivityIndicator size="l" />
      <h3>Please wait ...</h3>
    </Container>
  );
};
export default LoadingPage;
