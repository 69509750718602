import styled from "styled-components";
import Button from "../Button";
import CloseButton from "../CloseButton";

import { FadeInVertical } from "globalStyles/index";

const FadeInBottom = FadeInVertical("bottom");

export const Card = styled.form`
  background: white;
  border: 1px solid #ececec;
  border-radius: 12px;
  display: flex;
  flex-flow: column nowrap;
  animation: ${FadeInBottom} ease-out 0.3s forwards;
  min-width: min(80%, 794px);
  position: relative;
`;

export const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  & > img {
    width: 30px;
    height: 30px;
  }
`;

export const QuestionArea = styled.div`
  padding: 48px 53px 45px 53px;
  flex-grow: 1;
`;

export const FooterArea = styled.div`
  flex-basis: 105px;
  flex-shrink: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  padding: 32px 53px;
`;

export const BtnArea = styled.div`
  display: flex;
  gap: 18px;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const Question = styled.span`
  margin-bottom: 40px;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.large};
  line-height: 22px;
  color: black;
  text-align: left;
`;

export const OptionsBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const FooterButton = styled(Button)`
  padding: 12px 24px;
  width: auto;
  min-width: 116px;
`;

export const RatingWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-self: center;
`;

export const RatingContainer = styled.div`
  min-width: 365px;
  display: flex;
  gap: 6px;
  margin: 0 auto;
`;
export const RatingItem = styled.button`
  border: none;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 5.2px;
  color: black;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.large};
  height: 72px;
  cursor: pointer;
  ${({ $active, theme }) => {
    if ($active) {
      return `
      color: white;
      background-color: ${theme.colors.primaryColor};
      box-shadow: 0px 4px 15px rgba(1, 0, 138, 0.31);
      `;
    }
  }}
`;

export const RatingTextArea = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  justify-content: space-between;

  & > .title {
    font-weight: 600;
    font-size: ${(props) => props.theme.text.para.small};
    color: #707070;
  }
`;
