import React, { useEffect, useMemo, useRef, useState } from "react";

import SliderButton from "./SliderButton";
import { debounce } from "helper";
import useChildDimensions from "hooks/useChildDimensions";
import useForceReRender from "hooks/useForceReRender";

import { ImageContainer, ImageContent, ImageBox, Image } from "./style";

function ImageSlider({
  imageIndex,
  images,
  renderImage,
  renderSettings,
  onImageClick = () => {},
  ...rest
}) {
  const [currentImageIndex, updateImageIndex] = useState(0);
  const [setRef, elemPosition] = useChildDimensions();
  const debouncedScrollListner = useRef(null);
  const scrollContainerRef = useRef(null);
  const initialImageListRef = useRef(null);
  const tempDataRef = useRef({ deletedUrls: [], addedUrls: [] });

  const [tempVal, forceReRender] = useForceReRender();

  const widthToSet = elemPosition?.width;
  const heightToSet = elemPosition?.height;

  /**
   *  used for listning scroll ofcontainer
   */
  useEffect(() => {
    debouncedScrollListner.current = debounce((e) => {
      const { scrollWidth, scrollLeft } = e.target;
      const perImageWidth = Math.floor(
        scrollWidth / initialImageListRef.current.length
      );
      const idx = scrollLeft > 0 ? Math.floor(scrollLeft / perImageWidth) : 0;
      updateImageIndex(idx);
    }, 200);
  }, [images]);

  const getAppropriateImageIndex = (index) => {
    const images = initialImageListRef.current;
    if (index < 0) {
      return 0;
    } else if (index > images.length - 1) {
      return images.length - 1;
    } else {
      return index;
    }
  };

  /**
   * Used to control the component image index from parent
   */
  useEffect(() => {
    const container = scrollContainerRef.current;
    const images = initialImageListRef.current;
    if (
      typeof imageIndex !== "number" ||
      imageIndex > images.length - 1 ||
      !container
    ) {
      return;
    }
    const { scrollWidth } = container;
    const itemWidth = Math.round(scrollWidth / images.length);
    container.scrollTo(itemWidth * imageIndex, 0);
    updateImageIndex(getAppropriateImageIndex(imageIndex));
  }, [imageIndex]);

  const getDeletedUrls = (newImages) => {
    return initialImageListRef.current.filter(
      (url) => !newImages.includes(url)
    );
  };

  const addNewImagesToInitialList = (newImages) => {
    const addeddUrls = newImages.filter(
      (url) => !initialImageListRef.current.includes(url)
    );
    initialImageListRef.current = [
      ...addeddUrls,
      ...initialImageListRef.current,
    ];
    return addeddUrls;
  };

  const onAnimationEnd = (e) => {
    if (e.animationName === "del") {
      deleteOldImagesFromList();
      forceReRender();
    }

    if (e.animationName === "add") {
      deleteUrlsFromAddedList();
      forceReRender();
    }
  };

  const deleteUrlsFromAddedList = () => {
    tempDataRef.current.addedUrls = [];
  };

  const deleteOldImagesFromList = () => {
    initialImageListRef.current = initialImageListRef.current.filter(
      (url) => !tempDataRef.current.deletedUrls.includes(url)
    );
    tempDataRef.current.deletedUrls = [];
  };

  const imageList = useMemo(() => {
    if (!Array.isArray(images)) {
      return null;
    }

    if (!initialImageListRef.current) {
      initialImageListRef.current = images;
    }

    if (images.length < initialImageListRef.current.length) {
      tempDataRef.current.deletedUrls = getDeletedUrls(images);
    }

    if (images.length > initialImageListRef.current.length) {
      tempDataRef.current.addedUrls = addNewImagesToInitialList(images);
    }

    return initialImageListRef.current.map((url, idx) => {
      const isDeleted = tempDataRef.current.deletedUrls.includes(url);
      const isAdded = tempDataRef.current.addedUrls.includes(url);

      return (
        <ImageBox key={`${url}_${idx}`}>
          {typeof renderImage === "function" ? (
            renderImage({
              url,
              width: widthToSet || 100,
              height: heightToSet || 100,
              index: idx,
              onClick: onImageClick,
            })
          ) : (
            <Image
              style={{
                animationName: isAdded ? "add" : isDeleted ? "del" : "",
              }}
              onClick={onImageClick}
              width={widthToSet || 100}
              height={heightToSet || 100}
              src={url}
              onAnimationEnd={onAnimationEnd}
            />
          )}
        </ImageBox>
      );
    });
  }, [images, widthToSet, heightToSet, renderImage, tempVal]);

  const imageSettings = useMemo(() => {
    return (
      typeof renderSettings === "function" && renderSettings(currentImageIndex)
    );
  }, [renderSettings, currentImageIndex]);

  return (
    <ImageContainer ref={setRef} {...rest}>
      <ImageContent
        ref={scrollContainerRef}
        onScroll={debouncedScrollListner.current}
      >
        {imageList}
      </ImageContent>
      <SliderButton
        showLeftBtn={currentImageIndex > 0}
        showRightBtn={currentImageIndex < images.length - 1}
      />
      {imageSettings}
    </ImageContainer>
  );
}

export default ImageSlider;
