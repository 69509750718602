import React from "react";
import Empty_img from "assets/common/empty.svg";
import { Container, Text, Img } from "./style";

const EmptyData = ({ className, img, message, ...other }) => {
  return (
    <Container {...other} className={className}>
      <Img src={img || Empty_img} alt="not found icon" />
      <Text>{message}</Text>
    </Container>
  );
};

EmptyData.defaultProps = {
  message: "We don’t have anything to show here",
};

export default EmptyData;
