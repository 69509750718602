import styled from "styled-components";

export const Container = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background-color: lightgray;
  }
`;
