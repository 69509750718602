import React from "react";

import Hello_Img from "../../../assets/hello.svg";
import { Container, Name, ImageBox, Image } from "./style";

const WelcomeCard = ({ name }) => {
  return (
    <Container>
      <Name>
        {`Welcome 
        ${name} 👋`}
      </Name>
      <ImageBox>
        <Image src={Hello_Img} />
      </ImageBox>
    </Container>
  );
};
export default WelcomeCard;
