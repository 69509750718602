import React from "react";

import { RectangleLoader2 } from "./style";

export const SingleRectangleSkeleton = (props) => {
  return <RectangleLoader2 {...props} />;
};

SingleRectangleSkeleton.defaultProps = {
  times: 1,
};
