import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reducerStore } from "types/index";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { deleteNotice as deleteNoticeActionCreator } from "../actionCreator";
import useAlert from "hooks/useAlert";

export const useDeleteNotice = () => {
  const [isLoadingId, updateLoading] = useState<string>('');
  const { showAlert } = useAlert();
  const dispatch = useDispatch();
  const companyId = useSelector((store: reducerStore) =>
    getCurrentUserCompany(store, null)
  );
  const isAdmin = useSelector((store: reducerStore) =>
    isCurrentUserIsExecutive(store, null)
  );

  const deleteNotice = async (noticeId: string) => {
    try {
      updateLoading(noticeId);
      await dispatch(
        deleteNoticeActionCreator({ noticeId, companyId, isAdmin })
      );

      showAlert({ message: "Notice deleted successfully", type: "success" });
    } catch (err) {
      showAlert({ message: "Failed to delete notice", type: "error" });
    } finally {
      updateLoading('');
    }
  };

  return {
    isLoadingId,
    deleteNotice,
  };
};
