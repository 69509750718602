import styled from "styled-components";

export const TaskContainer = styled.button`
  position: relative;
  background-color: blue;
  padding: 5px;
  box-shadow: ${(props) => props.theme.shadow.medium};
  flex-basis: 100px;
  border: none;
  cursor: pointer;
  overflow: hidden;
`;

export const TaskTitle = styled.span`
  color: white;
  font-size: 14px;
  text-wrap: wrap;
  word-break: break-word;
  visibility: ${(props) => (props.$hideTitle ? "hidden" : "visible")};
`;
