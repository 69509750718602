import { MAP_API_KEY } from "../config.local";

export const TOKEN_LOCAL_NAME = "wheels_AJ((@CI(*%#*&#";
export const localDbKey = "wheel_dbb_lc";
export const ONLINE_FIELD = "isOnline";

export const MonthIndex = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC"
];

export const RandColors = [
  "#EC5224",
  "#2474EC",
  "#24EC8C",
  "#AC24EC",
  "#EC2484",
  "#ECA824"
];

export const MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=places`;

export const COMPRESSION_MAX_WIDTH = 1024;
export const COMPRESSION_MAX_HEIGHT = 768;

export const collaboratorFields = [
  "collaborator",
  "created by",
  "lead by",
  "lead to",
  "issued by",
  "assigned to",
  "submitted to"
];

export const multipleUsersField = ["owner team collaborators"];
