import React from "react";

import { ProfileCell, ProfileCellName, StyledProfilePic } from "./style";

export const CreatedByCell = ({ data, color }) => {
  const { avatarUrl, name } = data;

  if (data === "NA" || data === "Data Missing") {
    return data;
  }
  if (!data) {
    return "";
  }
  return (
    <ProfileCell>
      <StyledProfilePic color={color} img={avatarUrl?.url} userName={name} />
      <ProfileCellName>{name}</ProfileCellName>
    </ProfileCell>
  );
};

export default CreatedByCell;
