import React from "react";
import useInput from "../useInput";
import Radio from "./Radio";

import { RadioContainer } from "./style";

const RadioGroup = ({
  horizontal = true,
  options = [],
  groupName,
  fieldClass = '',
  ...otherprops
}) => {
  const { restInputProps } = useInput(otherprops);

  const { value, ...remainingProps } = restInputProps;
  return (
    <RadioContainer horizontal={horizontal} className={fieldClass}>
      {options.map((item) => {
        return (
          <Radio
            key={item.value}
            name={groupName}
            value={item.value}
            label={item.name}
            disabled={item.disabled || false}
            checked={value === item.value}
            {...remainingProps}
          />
        );
      })}
    </RadioContainer>
  );
};

export default RadioGroup;
