export const generateFormData = (values) => {
  const {
    enquiryCreate_enquiryEnquiryType,
    enquiryCreate_enquiryQuery,
    enquiryCreate_enquirySubject,
  } = values;

  return {
    enquiry_type: enquiryCreate_enquiryEnquiryType,
    subject: enquiryCreate_enquirySubject,
    query: enquiryCreate_enquiryQuery,
  }
};
