export const statusOptions = [
  { name: "Active", value: "active" },
  { name: "In Active", value: "in_active" },
];

export const collaboratorAdminOptions = [
  { name: "Super Admin", value: "super_admin" },
  { name: "Admin", value: "admin" },
];
export const collaboratorEmployeeOptions = [
  { name: "Employee", value: "employee" },
];

export const GenderOptions = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
  { name: "Transgender", value: "transgender" },
  { name: "Prefer not to disclose", value: "prefer_not_to_disclose" },
];

export const executiveOptions = [{ name: "Is Executive", value: "executive" }];

export const EmploymentStatusOptions = [
  { name: "On probation", value: "on_probation" },
  { name: "Confirmed", value: "confirmed" },
  { name: "Released", value: "released" },
  { name: "Abscounded", value: "abscounded" },
];

export const EmploymentTypeOptions = [
  { name: "Regular", value: "regular" },
  { name: "Freelancer", value: "freelancer" },
]
