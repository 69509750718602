import React, { useState, useCallback } from "react";

import { RectangleSkeleton } from "components/Skeleton";

import EmptyScreen from "assets/employee/NoticeBlank.png";
import {
  EmptyScreenContainer,
  EmptyScreenImage,
  EmptyScreenMessage,
} from "../../../styles/view";
import { ViewContainer } from "../../../../../GlobalStyles";

import ActionArea from "./ActionArea";
import TableData from "./TableData";
import NoticeCreateView from "../../Create";
import Button from "components/Button";

const SentNotices = ({ isExecutive, onCellClick }) => {
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);
  const [formVisibilty, setFormVisibilty] = useState(false);

  const updateFormVisibility = () => {
    setFormVisibilty((prevValue) => !prevValue);
  };

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  if (formVisibilty) {
    return <NoticeCreateView updateFormVisibility={updateFormVisibility} />;
  } else {
    return (
      <ViewContainer>
        <ActionArea
          onLoading={onDbDataChange}
          style={{
            display: isLoading || isEmpty ? "none" : "inherit",
          }}
          isAdmin={isExecutive}
          updateFormVisibility={updateFormVisibility}
        />
        <TableData
          style={{
            display: isLoading || isEmpty ? "none" : "inherit",
          }}
          onEmptyData={onEmptyData}
          isExecutive={isExecutive}
          onCellClick={onCellClick}
        />
        {!isLoading && isEmpty && (
          <EmptyScreenContainer>
            <EmptyScreenImage src={EmptyScreen} alt="No records found" />
            <EmptyScreenMessage>There are no notices</EmptyScreenMessage>
            <Button primary onClick={updateFormVisibility}>
              Create
            </Button>
          </EmptyScreenContainer>
        )}
        {isLoading && <RectangleSkeleton times={4} />}
      </ViewContainer>
    );
  }
};
export default SentNotices;
