import {
  CLEAR_SEARCH_WORK_REPORTS_DATA,
  GET_NOTIFIED_WORK_REPORTS,
  SAVE_SEARCH_WORK_REPORTS_DATA,
  FILTERED_SEARCH_WORK_REPORTS,
} from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import { getNotifiedDailyWorkReportsApi, searchWorkReportsApi } from "./api";

const getNotifiedWorkReportsGenerator = function* ({ pageNumber, companyId }) {
  yield {
    action: GET_NOTIFIED_WORK_REPORTS,
    sideEffect: getNotifiedDailyWorkReportsApi({ pageNumber, companyId }),
  };
};

const searchWorkReportsGenerator = function* ({
  pageNumber,
  companyId,
  fromDate,
  toDate,
  collaboratorIds,
}) {
  yield {
    action: FILTERED_SEARCH_WORK_REPORTS,
    sideEffect: searchWorkReportsApi({
      pageNumber,
      companyId,
      fromDate,
      toDate,
      collaboratorIds,
    }),
  };
};

export const clearSearchData = () => ({ type: CLEAR_SEARCH_WORK_REPORTS_DATA });

export const saveSearchData = ({ fromDate, toDate, collaboratorIds }) => ({
  type: SAVE_SEARCH_WORK_REPORTS_DATA,
  payload: { fromDate, toDate, collaboratorIds },
});

export const [getNotifiedWorkReports, searchWorkReports] =
  createAsyncActionCreator([
    getNotifiedWorkReportsGenerator,
    searchWorkReportsGenerator,
  ]);
