import React, { useMemo } from "react";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import { getStatusType } from "helper";
import { Cell, CellIcon } from "modules/FollowSubordinate/style/display";
import { CreatedByCell } from "components/Grid/Cells";

const RenderItem = ({ data, columnIndex, rowIndex, style }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
    maxStatusLength,
    onClick
  } = data;

  const cellvalue = rowData[rowIndex][columnIndex];

  const isFirstColumn = useMemo(() => {
    return columnIndex === 0;
  }, [columnIndex]);

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["status"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const myChildren = useMemo(() => {
    if (isFirstColumn) {
      return (
        <Cell style={{ cursor: "pointer" }}>
          {typeof cellvalue == "object" ? (
            <CreatedByCell data={cellvalue} />
          ) : (
            cellvalue
          )}
          <CellIcon name="fas fa-arrow-right" />
        </Cell>
      );
    }
    if (isStatusColumn) {
      return (
        <Status
          text={cellvalue}
          type={getStatusType(cellvalue)}
          style={{ width: `${maxStatusLength * 18}px`, padding: "6px" }}
        />
      );
    }
    return null;
  }, [isFirstColumn, isStatusColumn, cellvalue, maxStatusLength]);

  return (
    <GridCell
      onClick={onClick}
      row={rowIndex}
      showHeading={showHeading}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

export default RenderItem;
