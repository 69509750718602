import React from "react";
import Form from "./Form";

import { MainContainer } from "../../style/create";

const LeaveCreateView = ({ updateFormVisibility }) => {
  return (
    <MainContainer>
      <Form updateFormVisibility={updateFormVisibility} />
    </MainContainer>
  );
};
export default LeaveCreateView;
