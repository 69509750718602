import {
  GET_TEAMS_WORK_LOG,
  CREATE_TEAMS_WORK_LOG,
  EDIT_TEAMS_WORK_LOG,
  RESET_TEAMS_WORK_LOG,
} from "./actionTypes";
import { createAsyncActionCreator } from "helper";
import { getTeamsWorkLogApi, createWorkLogApi, editWorkLogApi } from "./api";

const getTeamsWorkLogGenerator = function* ({
  pageNumber,
  companyId,
  modelableId,
}) {
  yield {
    action: GET_TEAMS_WORK_LOG,
    sideEffect: getTeamsWorkLogApi({ pageNumber, companyId, modelableId }),
  };
};
const createTeamsWorkLogGenerator = function* ({
  companyId,
  isAdmin,
  formData,
  modelableId,
  modelableType,
}) {
  yield {
    action: CREATE_TEAMS_WORK_LOG,
    sideEffect: createWorkLogApi({
      companyId,
      isAdmin,
      formData,
      modelableId,
      modelableType,
    }),
  };
};
const editTeamsWorkLogGenerator = function* ({
  companyId,
  isAdmin,
  formData,
  dailyReportId,
  modelableId,
}) {
  yield {
    action: EDIT_TEAMS_WORK_LOG,
    sideEffect: editWorkLogApi({
      companyId,
      isAdmin,
      formData,
      modelableId,
      dailyReportId,
    }),
  };
};

export const resetTeamsWorklog = () => ({
  type: RESET_TEAMS_WORK_LOG,
});

export const [getTeamsWorkLog, createTeamsWorklog, editTeamsWorklog] =
  createAsyncActionCreator([
    getTeamsWorkLogGenerator,
    createTeamsWorkLogGenerator,
    editTeamsWorkLogGenerator,
  ]);
