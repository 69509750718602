import requestData from "../../ApiHandler";
import { createAuthUrl } from "../../Helper";

export const createNoticeApi = ({ companyId, isAdmin, formData }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/notices`,
  });
  return requestData(url, {
    method: "POST",
    body: {
      notice: formData,
    },
    isFormData: true,
  });
};

export const publishNoticeApi = ({
  companyId,
  isAdmin,
  noticeId,
  formData,
  selectedUsersList,
}) => {
  let emailStatus = false;
  formData.email && (emailStatus = true);

  let notice = {
    sent_to: formData.sent_to,
    send_email_flag: emailStatus,
  };

  if (formData.sent_to == "few") notice["collaborator_ids"] = selectedUsersList;

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/notices/publish/${noticeId}`,
  });

  return requestData(url, {
    method: "PUT",
    body: {
      notice,
    },
    isFormData: true,
  });
};

export const getSentNoticesApi = ({ companyId, isAdmin, pageNumber }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_published_notices?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getDraftNoticesApi = ({ companyId, isAdmin, pageNumber }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_unpublished_notices?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const getEmployerNoticesApi = ({ companyId, isAdmin, pageNumber }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/notices?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const updateDraftNoticeApi = ({
  companyId,
  noticeId,
  isAdmin,
  formData,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/notices/${noticeId}`,
  });
  return requestData(url, {
    method: "PUT",
    body: {
      notice: formData,
    },
    isFormData: true,
  });
};

export const getSpecificNoticeApi = ({ companyId, isAdmin, noticeId }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/notices/${noticeId}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const searchNoticeApi = ({
  companyId,
  isAdmin,
  pageNumber,
  currentTitle,
  currentFromDate,
  currentToDate,
  isPublished,
}) => {
  let title, from_date, to_date;
  title = from_date = to_date = "";

  if (currentTitle) title = `&q=${currentTitle}`;
  if (currentFromDate) from_date = `&from_date=${currentFromDate}`;
  if (currentToDate) to_date = `&to_date=${currentToDate}`;

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/search_notices?page=${pageNumber}${title}&published=${isPublished}${from_date}${to_date}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const deleteNoticeApi = ({ companyId, isAdmin, noticeId }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/notices/${noticeId}`,
  });
  return requestData(url, {
    method: "DELETE",
  });
};
