export const ResignationOptions = [
  { name: "Health", value: "health" },
  { name: "Higher studies", value: "higher_studies" },
  { name: "Intercompany movement", value: "intercompany_movement" },
  { name: "Involuntary exit abscond", value: "involuntary_exit_abscond" },
  {
    name: "Long travelling time to office",
    value: "long_travelling_time_to_office",
  },
  { name: "Marriage", value: "marriage" },
  { name: "Onsite opportunity", value: "onsite_opportunity" },
  { name: "Personal reasons", value: "personal_reasons" },
  { name: "Better carrier prospect", value: "better_carrier_prospect" },
  { name: "Better role", value: "better_role" },
  { name: "Better salary", value: "better_salary" },
  { name: "Better technology or domain", value: "better_technology_or_domain" },
  { name: "Contract ended", value: "contract_ended" },
  { name: "Role redundancy", value: "role_redundancy" },
  { name: "Relocation", value: "relocation" },
  { name: "Position with competitor", value: "position_with_competitor" },
  { name: "Other", value: "other" },
];

export const StatusOptions = [
  { name: "Applied", value: "applied" },
  { name: "Rejected", value: "rejected" },
  { name: "Accepted", value: "accepted" },
  { name: "Withdrawn", value: "withdrawn" },
];
