import CloseButton from "components/CloseButton";
import styled from "styled-components";
import { Card, FadeInVertical, FadeInHorizontal, Bounce } from "globalStyles";
const BottomFade = FadeInVertical("bottom");
const LeftFade = FadeInHorizontal("left");

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  width: min(80%, 520px);
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-flow: column nowrap;
  animation: ${BottomFade} ease-out 0.3s forwards;
`;

export const ReactionsModalContent = styled(ModalContent)`
  max-height: 600px;
  overflow-y: auto;
  position: relative;
`;

export const ReactionModalHeader = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: ${(props) => props.theme.text.title.large};
  border-bottom: 1px lightgray solid;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-height: 400px;
`;

export const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #efefef;
  margin: 0 30px 0 10px;
`;

export const Section = styled.div`
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-flow: column nowrap;
  & + div {
    margin-top: 20px;
  }
`;

export const InfoHeader = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  text-align: left;
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: 600;
  color: black;
`;

export const InfoContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  & .desc {
    font-weight: normal;
    font-size: ${(props) => props.theme.text.para.extraSmall};
    color: #707070;
    // margin-left: 10px;
  }
`;

export const InfoTab = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
  color: ${(props) =>
    props.$active ? props.theme.colors.primaryColor : "#707070"};
  & > img {
    width: 15px;
    height: 15px;
  }
  & .title {
    font-weight: 500;
    font-size: ${(props) => props.theme.text.para.small};
    margin-left: 5px;
  }
  ${(props) => {
    if (props.$active) {
      return `border-bottom: 2px ${props.theme.colors.primaryColor} solid`;
    }
  }}
`;

export const MorePic = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  color: #fffafa;
  border: none;
  font-size: 10px;
  font-weight: 700;
  padding-bottom: 7px;
  margin-left: 10px;
  &:hover {
    box-shadow: ${(props) => props.theme.shadow.medium};
  }
`;
export const AllSectionContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: ${(props) => props.theme.colors.mediumGrayColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.primaryColor};
    border-radius: 10px;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const SectionProfileContent = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 10px 0;
  & .profileimg {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 40px;
    margin-right: 15px;
  }
  & > span {
    font-size: 15px;
  }
`;

export const SectionReactionImage = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 20px 0 auto;
`;
export const StyleCloseButton = styled(CloseButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const ReactionLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
