import React, { useEffect, useMemo, useState } from "react";

import PostHeader from "./PostHeader";
import InputGroup from "components/InputGroup/inputGroup2";

import useForm from "hooks/useForm";
import { generateRandomId } from "helper";

import { MAX_MORE_OPTIONS, PollOptions, postTypes } from "../constants";

import {
  PollFooter,
  PollFormArea,
  Section,
  PostButton,
  AddMoreOption,
  PostCreateContainer,
  OptionInputWrapper,
  RemoveButton,
  PostBackButton,
} from "./style";

function PollCreate({
  onClose,
  onCreatePoll,
  onPostTypeChange, // indicates whether back button should be present or not
  isLoading,
  ...rest
}) {
  const [moreOptions, updateMoreOptions] = useState([]);
  const [moreOptionValues, updateMoreOptionValues] = useState({});

  const { formData, values, isFormValid } = useForm({
    fields: {
      question: {
        required: true,
      },
      description: {
        required: false,
      },
      option1: {
        required: true,
      },
      option2: {
        required: true,
      },
      duration: {
        required: true,
      },
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }
    const extraOptions = moreOptions.map((id) => moreOptionValues[id]);
    const options = [values.option1, values.option2, ...extraOptions];
    onCreatePoll &&
      onCreatePoll({
        duration: values.duration,
        question: values.question,
        description: values.description,
        options,
      });
  };

  /**
   * Effect to create a new place for options values based upon
   * last id entered into moreOptions
   */
  useEffect(() => {
    if (moreOptions.length === 0) {
      return;
    }

    const lastId = moreOptions[moreOptions.length - 1];
    updateMoreOptionValues((prevVal) => {
      if (prevVal[lastId]) {
        return prevVal;
      }
      return { ...prevVal, [lastId]: "" };
    });
  }, [moreOptions]);

  const onAddMoreOption = () => {
    let id = "";
    updateMoreOptions((prevVal) => {
      id = generateRandomId();
      while (prevVal[id]) {
        id = generateRandomId();
      }
      return [...prevVal, id];
    });
  };

  const onRemoveOption = (idToDelete) => {
    updateMoreOptions((prev) => {
      const val = prev.filter((id) => id !== idToDelete);
      return val;
    });
    updateMoreOptionValues((prevVal) => {
      const newVal = { ...prevVal };
      delete newVal[idToDelete];
      return newVal;
    });
  };

  const onOptionFieldChange = (id, e) => {
    updateMoreOptionValues((prevVal) => {
      return { ...prevVal, [id]: e.target.value };
    });
  };

  const extraFields = useMemo(() => {
    let start_count = 3; // because already 2 options are required
    return moreOptions.map((key, index) => {
      const onChange = onOptionFieldChange.bind(this, key);
      return (
        <OptionInputWrapper key={key}>
          <InputGroup
            label={`Option ${index + start_count}`}
            fieldClass="poll_options_field"
            size="sm"
            value={moreOptionValues[key] || ""}
            onChange={onChange}
            name={`Option ${index + start_count}`}
          />
          <RemoveButton onClick={() => onRemoveOption(key)}>
            Remove
          </RemoveButton>
        </OptionInputWrapper>
      );
    });
  }, [moreOptions, moreOptionValues]);

  const moreOptionsCount = Object.keys(moreOptions).length;

  return (
    <PostCreateContainer {...rest}>
      <PostHeader title="Create a poll" onClose={onClose} />

      <Section>
        <PollFormArea>
          <InputGroup
            label="Description"
            placeholder="Enter a description"
            fieldType="textArea"
            fillParent={true}
            wrapperStyle={{ minHeight: "100px", marginBottom: "10px" }}
            {...formData["description"]}
          />

          <InputGroup
            label="Your question"
            placeholder="Enter your question"
            fieldType="textArea"
            fillParent={true}
            wrapperStyle={{ minHeight: "100px", marginBottom: "30px" }}
            {...formData["question"]}
          />

          <InputGroup size="sm" label="Option 1" {...formData["option1"]} />
          <InputGroup size="sm" label="Option 2" {...formData["option2"]} />

          {extraFields}

          {moreOptionsCount < MAX_MORE_OPTIONS && (
            <AddMoreOption type="button" onClick={onAddMoreOption}>
              Add option
            </AddMoreOption>
          )}

          <InputGroup
            fieldType="select"
            options={PollOptions}
            label="Poll duration"
            defaultSelectText="Select duration"
            {...formData["duration"]}
          />
        </PollFormArea>
      </Section>
      <PollFooter>
        {!!onPostTypeChange && (
          <PostBackButton onClick={() => onPostTypeChange(postTypes.post)}>
            Back
          </PostBackButton>
        )}
        <PostButton
          onClick={onSubmit}
          loading={isLoading}
          disabled={isLoading}
          type="button"
        >
          Post
        </PostButton>
      </PollFooter>
    </PostCreateContainer>
  );
}

export default PollCreate;
