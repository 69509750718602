import React, { useMemo, useCallback } from "react";

import Tabs from "components/Tabs";
import DetailView from "./DetailView";
import PersonalInfoDisplayView from "../../../PersonalInfo/Views/Display";
import FamilyDetailsDisplayView from "../../../FamilyDetails/Views/Display";
import EmergencyContactDisplayView from "../../../EmergencyContact/Views/Display";
import QualificationsDisplayView from "../../../Qualifications/Views/Display";
import BankAccountsDisplayView from "../../../BankAccounts/Views/Display";
import UpiDisplayView from "../../../UpiDetails/Views/Display";
import EmailDisplayView from "../../../Email/Views/Display";

import useUserInformation from "hooks/useUserInformation";

const ProfileDisplayView = () => {
  const { userInfo } = useUserInformation();

  const detailData = useMemo(() => {
    return {
      data: Object.values(userInfo).map((value) => {
        if (typeof value !== "boolean") {
          if (!value) return "NA";
        } else return value.toString();
        return value;
      }),
      columns: Object.keys(userInfo),
    };
  }, [userInfo]);

  const CurrentTab = useCallback(
    (index) => {
      if (index == 1)
        return (
          <DetailView data={detailData.data} columns={detailData.columns} />
        );
      else if (index == 2) return <PersonalInfoDisplayView />;
      else if (index == 3) return <FamilyDetailsDisplayView />;
      else if (index == 4) return <EmergencyContactDisplayView />;
      else if (index == 5) return <QualificationsDisplayView />;
      else if (index == 6) return <BankAccountsDisplayView />;
      else if (index == 7) return <UpiDisplayView />;
      else if (index === 8) return <EmailDisplayView />;
      else return null;
    },
    [detailData]
  );

  return (
    <Tabs
      tabNames={[
        "Detail",
        "Personal Information",
        "Family Details",
        "Emergency Contact",
        "Qualifications",
        "Bank Account",
        "UPI",
        "Email",
      ]}
    >
      {CurrentTab}
    </Tabs>
  );
};

export default ProfileDisplayView;
