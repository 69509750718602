import { CREATE_UPI, GET_UPIS } from "./actiontypes";

import { createAsyncActionCreator } from "helper";

import { createUpiApi, getUpiApi } from "./api";

const createUpiGenrator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_UPI,
    sideEffect: createUpiApi({ companyId, isAdmin, formData }),
  };
};

export const [createUpi] = createAsyncActionCreator([
  createUpiGenrator,
]);

const getUpisGenerator = function* ({
  companyId,
  pageNumber,
  currentUserId,
  userId,
  isAdmin,
}) {
  yield {
    action: GET_UPIS,
    sideEffect: getUpiApi({ companyId, pageNumber, currentUserId, userId, isAdmin }),
  };
};

export const getUpis = createAsyncActionCreator(
  getUpisGenerator
);
