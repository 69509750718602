import React, { useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";

import Routes from "route/Path";

import NotificationCenter from "common/Components/NotificationCenter";

import usePagination from "hooks/usePagination";

import {
  fetchNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from "../actionCreator";
import { createTableDataSelector } from "common/Selectors/MTable";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { MockData } from "../constants";
import { tranformToNotificationList } from "../utils";
import useAlert from "hooks/useAlert";

const Notification = ({
  elemIdOrElem,
  companyId,
  isAdmin,
  onClose,
  onceFetched,
  data,
  cellNameToColumnMap,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  ...otherProps
}) => {
  const history = useHistory();
  const { showAlert } = useAlert();

  const { loadingNextPage, getNextPage, allPagesFetched, errorOccured } =
    usePagination({
      actionCreator: fetchNotifications,
      tableName: "notifications",
    });

  const fetchFirstPage = useCallback(() => {
    getNextPage({
      isFirstPage: true,
      extraParams: { companyId, isAdmin },
    });
  }, [getNextPage, companyId, isAdmin]);

  const fetchNextPage = useCallback(() => {
    getNextPage({
      extraParams: { companyId, isAdmin },
    });
  }, [getNextPage, companyId, isAdmin]);

  const onReadAll = useCallback(() => {
    markAllNotificationsAsRead({ companyId, isAdmin }).catch((err) => {
      showAlert({ message: err[0], type: "error" });
    });
  }, [companyId, isAdmin]);

  const goToPage = (type, notifiableId) => {
    const notifyType = type.toLowerCase();
    let path;
    switch (notifyType) {
      case "notice":
        const NoticePath = Routes.authorized.notice.view.path;
        path = `${NoticePath}#${notifiableId}`;
        break;
      case "survey":
        const SurveyPath = Routes.authorized.survey.view.path;
        path = `${SurveyPath}?survey_id=${notifiableId}`;
        break;
      case "post":
        const PostPath = Routes.authorized.posts.detail.path;
        path = `${PostPath}/${notifiableId}`;
        break;
      default:
        break;
    }
    if (path) {
      history.push(path);
    }
  };

  const onMessageClick = useCallback(
    (data) => {
      markNotificationAsRead({
        companyId,
        isAdmin,
        noticeId: data.noticeId,
      }).catch(() => console.error("failed to mark notification as read"));
      goToPage(data.type, data.notifiableId);
      onClose();
    },
    [history, onClose, companyId, isAdmin]
  );

  useEffect(() => {
    if (!elemIdOrElem || allPagesFetched) {
      return;
    }

    if (!onceFetched) {
      fetchFirstPage();
    } else {
      fetchNextPage();
    }
  }, [elemIdOrElem]);

  const notificationList = useMemo(() => {
    return tranformToNotificationList(
      data,
      cellNameToColumnMap,
      onMessageClick
    );
  }, [data, cellNameToColumnMap]);

  return (
    <NotificationCenter
      elemIdOrElem={elemIdOrElem}
      dataList={!onceFetched ? MockData : notificationList}
      showError={errorOccured}
      initialLoading={!onceFetched && loadingNextPage}
      onEndReached={!allPagesFetched ? fetchNextPage : null}
      onClose={onClose}
      loading={onceFetched && loadingNextPage && !allPagesFetched}
      onReadAll={onReadAll}
      {...otherProps}
    />
  );
};

Notification.defaultProps = {
  onClose: () => {},
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "notifications",
    fetchAllData: true,
    reverse: false,
  });

  return (store, props) => {
    const companyId = getCurrentUserCompany(store, props);
    const isAdmin = isCurrentUserIsExecutive(store, props);
    const { rows, cellNameToColumnMap } = getUserRows(store, props);

    return {
      onceFetched: store.notifications.onceFetched,
      companyId,
      isAdmin,
      data: rows,
      cellNameToColumnMap,
    };
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      markAllNotificationsAsRead,
      markNotificationAsRead,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
