import styled from "styled-components";
import Icon from "../Icon";

export const CollapseContainer = styled.div`
  width: 100%;
  height: 50px;
  transition: height ease 0.2s;
  overflow: hidden;
`;

export const CollapseHeaderWrapper = styled.div`
  position: relative;
`;
export const CollapseHeader = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px;
`;

export const DropIcon = styled(Icon)`
  position: absolute;
  top: 30%;
  right: 10px;
  margin-left: auto;
  color: ${(props) => props.theme.colors.collapse.text};
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  padding-left: 20px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  &::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    //border-left: 2px ${(props) => props.theme.colors.collapse.border} solid;
  }
`;
