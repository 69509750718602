import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";

import Paginator from "components/Paginator";
import { getCurrentUserCompany } from "common/Selectors/auth";

import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";
import { getDraftNotices, searchNotice } from "../../../actionCreator";

import { PaginatorBox, StyledActionArea } from "../../../../../GlobalStyles";

import SearchFilter from "../SearchFilter";

import { EnhancedActionArea, SearchArea } from "../../../styles/view";

const ActionArea = ({
  currentTitle,
  currentFromDate,
  currentToDate,
  isSearchOn,
  isPublished,
  onceFetched,
  companyId,
  isAdmin,
  onLoading,
  style,
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: isSearchOn ? searchNotice : getDraftNotices,
    tableName: "notice.draft",
  });

  const extraParams = { companyId, currentTitle, isPublished, currentFromDate, currentToDate, isAdmin }

  const { fetchFirstPage, fetchNextPage, fetchPrevPage, fetchSpecificPage } =
    usePaginator({ extraParams, paginatorApi: getNextPage });

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched && !isSearchOn) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <EnhancedActionArea style={style}>
      {isAdmin &&
        <SearchArea>
          <SearchFilter
            title={currentTitle}
            is_published={false}
            from_date={currentFromDate}
            to_date={currentToDate}
          />
        </SearchArea>
      }
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);

  return {
    companyId,
    onceFetched: store.notice.draft.onceFetched,
    currentTitle: store.notice.draft.currentTitle,
    isPublished: store.notice.draft.isPublished,
    currentFromDate: store.notice.draft.currentFromDate,
    currentToDate: store.notice.draft.currentToDate,
    isSearchOn: store.notice.draft.isSearchOn
  };
};

export default connect(mapStateToProps, null)(ActionArea);
