import React from "react";
import InsertInDom from "../InsertInDom";

import { Container, OverLay } from "./style";

const GenericModal = ({
  children,
  opacity,
  onClose,
  className,
  ...otherProps
}) => {
  return (
    <InsertInDom>
      <Container {...otherProps} className={className}>
        <OverLay onClick={onClose} $opacity={opacity} />
        {children}
      </Container>
    </InsertInDom>
  );
};

GenericModal.defaultProps = {
  opacity: 0,
  className: "",
};

export default GenericModal;
