import { useMemo } from "react";
import Icon from "components/Icon";
import ProfilePic from "components/ProfilePic";
import {
  convertToTextDateStringMMDDYYYY,
  convertDateToMMDDYYYY,
} from "helper/date";
import {
  AttachmentContainer,
  BoldText,
  ReportRecord,
  ReportRecordSubContainer,
  SubText,
  Tag,
  TextContent,
} from "../style";

const WorkReportView = ({ workReport, onClick }) => {
  const logDate = useMemo(() => {
    const dateInMMDDYYY = convertDateToMMDDYYYY(workReport?.logDate, "-");
    const logDate = convertToTextDateStringMMDDYYYY(dateInMMDDYYY, "-") || "";
    return logDate;
  });

  return (
    <ReportRecord onClick={() => onClick(workReport?.id)}>
      <ReportRecordSubContainer>
        <ProfilePic
          img={workReport?.modelable?.collaborator?.avatarUrl?.url}
          userName={workReport?.modelable?.collaborator?.name}
          style={{ width: "30px", height: "30px" }}
        />
        <TextContent>
          <BoldText>{workReport?.modelable?.collaborator?.name}</BoldText>
          <SubText style={{ marginLeft: 5 }}>has logged work for</SubText>
          <BoldText style={{ marginLeft: 5 }}>{logDate}</BoldText>
        </TextContent>
      </ReportRecordSubContainer>
      <ReportRecordSubContainer>
        <Tag>{workReport?.modelable?.workHourShift}</Tag>
        <AttachmentContainer>
          {workReport?.documentUrls?.length > 0 && (
            <Icon name="fas fa-paperclip" style={{ color: "#707070" }} />
          )}
        </AttachmentContainer>
      </ReportRecordSubContainer>
    </ReportRecord>
  );
};

export default WorkReportView;
