import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Grid from "components/Grid";
import { createTableDataSelector } from "common/Selectors/MTable";

import { TableContainer } from "globalStyles";
import { modifyDataForSingleDocumentField } from "helper";

const TableData = ({
  data,
  onCellClick,
  cellNameToColumnMap,
  onEmptyData,
  ...other
}) => {
  const modifiedData = useMemo(() => {
    return modifyDataForSingleDocumentField(data, cellNameToColumnMap);
  }, [data]);

  const { rows, cols } = useMemo(() => {
    const rows = modifiedData.length;
    const cols =
      modifiedData[0] && modifiedData[0].length > 0
        ? modifiedData[0].length
        : 6;
    return { rows, cols };
  }, [modifiedData]);

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }
    if (rows === 1) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);

  return (
    <TableContainer {...other}>
      <Grid
        onClick={onCellClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={40}
        data={modifiedData}
      />
    </TableContainer>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "qualifications",
    blackList: { "Sl No": true },
  });
  const maker = (store, props) => {
    const { rows, cellNameToColumnMap } = getUserRows(store, props);
    return {
      data: rows,
      cellNameToColumnMap,
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
