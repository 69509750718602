import { getAccessType } from "../../Helper";
import Path from "../Path";
const authPaths = Path.authorized;

/**
 * Order is set in Inheritance order
 */

const employeePages = {
  [authPaths.welcome.employeeWelcome.path]: true,
  ...authPaths.profile.getMyPaths(),
  [authPaths.accessAssets.view.path]: true,
  [authPaths.calendar.view.path]: true,
  [authPaths.notice.view.path]: true,
  [authPaths.attendence.view.path]: true,
  [authPaths.separation.view.path]: true,
  [authPaths.leave.view.path]: true,
  [authPaths.salarySlip.view.path]: true,
  [authPaths.reimbursement.view.path]: true,
  [authPaths.breaks.view.path]: true,
  [authPaths.teams.view.path]: true,
  [authPaths.posts.detail.path]: true,
  [authPaths.ledger.view.path]: true,
  ...authPaths.survey.getMyPaths(),
  ...authPaths.helpdesk.getMyPaths(),
  ...authPaths.leads.getMyPaths(),
  [authPaths.holiday.view.path]: true,
  ...authPaths.followSubordinate.getMyPaths(),
  ...authPaths.leaveManagement.getMyPaths(),
  ...authPaths.notifiedWorkReport.getMyPaths(),
  ...authPaths.userProfile.getMyPaths(),
};
const adminPages = {
  ...employeePages,
  [authPaths.welcome.adminWelcome.path]: true,
  ...authPaths.companySelection.getMyPaths(),
  ...authPaths.department.getMyPaths(),
  ...authPaths.notice.getMyPaths(),
  ...authPaths.jobTitle.getMyPaths(),
  ...authPaths.user.getMyPaths(),
  ...authPaths.calendar.getMyPaths(),
  ...authPaths.accessAssets.getMyPaths(),
  ...authPaths.holiday.getMyPaths(),
  ...authPaths.holidayYear.getMyPaths(),
  ...authPaths.companyBranch.getMyPaths(),
  ...authPaths.reimbursement.getMyPaths(),
  ...authPaths.breaks.getMyPaths(),
  ...authPaths.teams.getMyPaths(),
  ...authPaths.leaves.getMyPaths(),
  ...authPaths.ledger.getMyPaths(),
  ...authPaths.countdown.getMyPaths(),
  ...authPaths.workshift.getMyPaths(),
  [authPaths.leave.view.path]: false,
  [authPaths.attendence.view.path]: false,
  [authPaths.profile.view.path]: false,
  [authPaths.posts.detail.path]: false,
  [authPaths.followSubordinate.view.path]: false,
  ["/view_leads"]: false,
};
const superAdminPages = { ...adminPages };

export const pageAccessType = {
  employee: employeePages,
  admin: adminPages,
  super_admin: superAdminPages,
  getPageAccessType: getAccessType,
};
