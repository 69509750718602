import styled from "styled-components";

export const TabContainer = styled.div`
  height: calc(100% - 20px);
  width: calc(100% - 30px);
  margin: 10px 15px;
  overflow-x: scroll;
  overflow-y: scroll;
  font-size: 70%;

  &::-webkit-scrollbar{
      display: none;
  }

  @media (min-width: 768px) {
    width: calc(100% - 40px);
    margin: 10px 20px;
    font-size: 100%;
`;

export const Navigator = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${(props) => props.theme.colors.tab.border};
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
`;

export const TabButton = styled.div`
  position: relative;
  top: 1px;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 120px;
  cursor: pointer;
  color: ${(props) =>
    props.active ? props.theme.colors.tab.active.text : `#000000`};
  font-weight: ${(props) => (props.active ? `600` : `normal`)};
  font-size: ${(props) => props.theme.text.para.small};
  border: ${(props) =>
    props.active ? (props.secondary ? `3px solid #4ac501` : `0`) : `0`};
  border-bottom: ${(props) =>
    props.active
      ? props.secondary
        ? `2px solid white`
        : `3px solid ${props.theme.colors.tab.active.border}`
      : `0`};
  border-radius: ${(props) =>
    props.active ? (props.secondary ? `5px 5px 0 0` : `0`) : `0`};
  @media (min-width: 768px) {
    padding: 0 10px;
    min-width: 150px;
  }
`;
