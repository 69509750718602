import { getAccessType, generateAccessType } from "../../Helper";

/**
 * Order is set in Inheritance order
 */

const employeeTabs = generateAccessType([
  "profile",
  "accessAssets",
  "calendar",
  "reimbursement",
  "notice",
  "leads",
  "holiday",
  "followSubordinate",
  "attendance",
  "separation",
  "salarySlip",
  "helpdesk",
  "leave",
  "teams",
  "ledger",
]);
const adminTabs = {
  ...employeeTabs,
  ...generateAccessType([
    "user",
    "department",
    "jobTitle",
    "notice",
    "holidayYear",
    "companyBranch",
    "breaks",
    "leaves",
    "countdown",
    "workshift",
  ]),
  leave: false,
  attendance: false,
  profile: false,
  followSubordinate: false,
  leads: false,
};
const superAdminTabs = { ...adminTabs };

export const tabsAccessType = {
  employee: employeeTabs,
  admin: adminTabs,
  super_admin: superAdminTabs,
  getTabAccessType: getAccessType,
};

export const getAllTabs = () => {
  return Object.keys(superAdminTabs);
};
