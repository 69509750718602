import requestData from "../../../../ApiHandler";
import { createAuthUrl } from "helper";

export const fetchPostsApi = ({ companyId, isAdmin, pageNumber }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/posts?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const createPostApi = ({ companyId, isAdmin, formData }) => {
  const body = {
    regular_post: {
      description: formData.description,
      ...(formData.video ? { video: formData.video } : {}),
      ...(formData.photos ? { photos: formData.photos } : {}),
    },
  };
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/regular_posts`,
  });
  return requestData(url, {
    method: "POST",
    body,
    isFormData: true,
  });
};

export const reactToPostApi = ({ companyId, isAdmin, formData }) => {
  const body = {
    reaction: {
      reaction_type: formData.type,
      reactable_id: formData.postId,
      reactable_type: "Post",
    },
  };
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/reactions/toggle`,
  });
  return requestData(url, {
    method: "POST",
    body,
    isFormData: true,
  });
};

export const removeReactFromPostApi = ({ companyId, isAdmin, formData }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/reactions/${formData.reactionId}?reactable_id=${formData.postId}&reactable_type=${formData.reactableType}`,
  });
  return requestData(url, {
    method: "DELETE",
  });
};

export const createPollApi = ({ companyId, isAdmin, formData }) => {
  const body = {
    poll_post: {
      question: formData.question,
      description: formData.description,
      enable_till: formData.duration,
      poll_post_option_attributes: formData.options,
    },
  };
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/poll_posts`,
  });
  return requestData(url, {
    method: "POST",
    body,
    isFormData: true,
  });
};

export const createQuizApi = ({ companyId, isAdmin, formData }) => {
  const result = {};
  formData.options.forEach((option, idx) => {
    result[option] = String(formData.answereIndex == idx);
  });
  const body = {
    qa_post: {
      question: formData.question,
      qa_post_options_attributes: result,
    },
  };
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/qa_posts`,
  });
  return requestData(url, {
    method: "POST",
    body,
    isFormData: true,
  });
};

export const answereQuizApi = ({ companyId, isAdmin, optionId }) => {
  const body = {
    qa_post_answer: {
      qa_post_option_id: optionId,
    },
  };
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/qa_post_answers`,
  });
  return requestData(url, {
    method: "POST",
    body,
    isFormData: true,
  });
};

export const answerePollApi = ({ companyId, isAdmin, optionId }) => {
  const body = {
    poll_post_answer: {
      poll_post_option_id: optionId,
    },
  };
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/poll_post_answers`,
  });
  return requestData(url, {
    method: "POST",
    body,
    isFormData: true,
  });
};

export const createPostCommentApi = ({
  companyId,
  isAdmin,
  postId,
  comment,
}) => {
  const body = {
    comment: {
      message: comment,
      commentable_id: postId,
      commentable_type: "Post",
    },
  };
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/comments`,
  });
  return requestData(url, {
    method: "POST",
    body,
    isFormData: true,
  });
};

export const deletePostApi = ({ companyId, isAdmin, postId }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/posts/${postId}`,
  });
  return requestData(url, {
    method: "DELETE",
  });
};
