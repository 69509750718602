export const GET_HOLIDAY_YEARS = "GET_HOLIDAY_YEARS";
export const CREATE_HOLIDAY = "CREATE_HOLIDAY";
export const EDIT_HOLIDAY = "EDIT_HOLIDAY";
export const GET_HOLIDAYS = "GET_HOLIDAYS";
export const GET_HOLIDAYS_BY_YEAR = "GET_HOLIDAYS_BY_YEAR";
export const UPDATE_YEAR_ID = "UPDATE_YEAR_ID";
export const SEARCH_HOLIDAYS = "SEARCH_HOLIDAYS";
export const UPDATE_SEARCH_PARAMS = "UPDATE_HOLIDAYS_SEARCH_PARAMS";
export const RESET_SEARCH_PARAMS = "RESET_HOLIDAYS_SEARCH_PARAMS";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";
