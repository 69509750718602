import { tranformMatrixToArray } from "helper/index";

export const transformSurveyMatrixToArray = (data, rowIdMap) => {
  if (!data || !data[0]) {
    return null;
  }
  const result = tranformMatrixToArray(data, rowIdMap);
  return transformSurveyRawDataToSurveyData(result);
};

const transformSurveyRawDataToSurveyData = (data) => {
  let result = {};
  data.forEach(({ name, description, id, activeSurveyQuestions }) => {
    result = {
      name,
      description,
      surveyId: id,
      surveys: activeSurveyQuestions.sort(
        (a, b) => a.questionOrder - b.questionOrder
      ),
    };
  });
  return result;
};
