import styled from "styled-components";

const getWidth = (props) => {
  const { size } = props;
  switch (size) {
    case "sm":
      return "80px";
    case "md":
      return "150px";
    case "lg":
      return "200px";
    case "full":
      return "100%";
    default:
      return "auto";
  }
};

const getHeight = (props) => {
  const { size } = props;
  switch (size) {
    case "sm":
      return "40px";
    case "md":
    case "full":
      return "50px";
    case "lg":
      return "50px";
    default:
      return "auto";
  }
};

export const StyledButton = styled.button`
  font-size: ${(props) => props.theme.text.btn.medium};
  font-weight: 500;
  border: none;
  border-radius: 5px;
  width: ${getWidth};
  height: ${getHeight};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    box-shadow: 0 15px 23px 0
      ${(props) => props.theme.colors.button.shadowColor};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform linear 0.2s;
  &:active {
    transform: scale(0.8);
  }
`;

export const PrimaryButton = styled(StyledButton)`
  background-color: ${(props) => props.theme.colors.button.primary.background};
  color: ${(props) => props.theme.colors.button.primary.color};
  font-weight: bold;
  font-size: ${(props) => props.theme.text.btn.medium};
  width: 100%;

  @media (min-width: 768px) {
    width: ${getWidth};
  }
`;

export const SecondaryButton = styled(StyledButton)`
  background-color: ${(props) =>
    props.theme.colors.button.secondary.background};
  color: white;
  border: 1px #e0e5e9 solid;
  border-radius: 3px;
  color: var(--secondary-color);
  font-weight: bold;
  width: 100%;

  @media (min-width: 768px) {
    width: ${getWidth};
  }
`;

export const OutlineButton = styled(StyledButton)`
  background-color: ${(props) => props.theme.colors.button.outline.background};
  color: white;
  border: solid 1px ${(props) => props.theme.colors.button.outline.border};
  border-radius: 8.1px;
  color: ${(props) => props.theme.colors.button.outline.color};
  font-weight: bold;
  width: 100%;

  @media (min-width: 768px) {
    width: ${getWidth};
  }
`;
