import React, { useState, useCallback, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";

import Tabs from "common/Components/Tabs";
import NoticePageSlider from "common/Components/NoticePageSlider";
import SentNotices from "./SentNotices";
import DraftNotices from "./DraftNotices";
import NoticeSliderPage from "./NoticeSliderPage";

import { createTableDataSelector } from "common/Selectors/MTable";
import {
  isCurrentUserIsExecutive,
  getCurrentUserCompany,
} from "common/Selectors/auth";

import { getSpecificNotice } from "../../actionCreator";
import { generateTableData } from "../../utils";
import { NoticeBackground } from "../../styles/view";

const NoticeDisplayView = ({
  getSpecificNotice,
  isExecutive,
  companyId,
  singleNoticeData,
  cellNameToColumnMap,
}) => {
  const [isDraft, setDraftStatus] = useState(false);
  const [isSliderActive, setSliderActive] = useState(false);
  const [noticeData, setNoticeData] = useState({});
  const [isHashDataLoading, updateLoadingStatus] = useState(false);
  const location = useLocation();
  const hasApiErrorRef = useRef(false);

  const onHashLoadData = useCallback(async () => {
    if (!location.hash) {
      return;
    }
    try {
      setSliderActive(true);
      updateLoadingStatus(true);
      await getSpecificNotice({
        companyId,
        isAdmin: isExecutive,
        noticeId: location.hash.substring(1, location.hash.length),
      });
      updateLoadingStatus(false);
    } catch (err) {
      hasApiErrorRef.current = true;
      updateLoadingStatus(false);
    }
  }, [location.hash]);

  /**
   * Check for Hash in url
   * If there is a hash then open the modal with that hashId
   */
  useEffect(() => {
    onHashLoadData();
  }, [onHashLoadData]);

  useEffect(() => {
    if (!location.hash) {
      return;
    }
    const res = generateTableData(
      singleNoticeData,
      cellNameToColumnMap,
      isExecutive
    );
    if (Array.isArray(res) && res.length > 0) {
      setNoticeData(res[0]);
    }
  }, [singleNoticeData]);

  const toggleSlider = useCallback(() => {
    setSliderActive((val) => !val);
  }, []);

  const onCellClick = useCallback(
    ({ notice, index }) => {
      hasApiErrorRef.current = false;
      setNoticeData(notice);
      if (notice.published === "NA") {
        setDraftStatus(true)
      } else {
        setDraftStatus(false)
      }
      toggleSlider();
    },
    [setNoticeData, toggleSlider]
  );

  const children = useCallback(
    (index) => {
      if (index == 1) {
        setDraftStatus(false);
        return (
          <SentNotices isExecutive={isExecutive} onCellClick={onCellClick} />
        );
      } else if (index == 2) {
        setDraftStatus(true);
        return (
          <DraftNotices isExecutive={isExecutive} onCellClick={onCellClick} />
        );
      } else return null;
    },
    [isExecutive]
  );

  return (
    <NoticeBackground>
      {isExecutive ? (
        <Tabs tabNames={["Sent Notices", "Draft Notices"]}>{children}</Tabs>
      ) : (
        <SentNotices isExecutive={isExecutive} onCellClick={onCellClick} />
      )}
      <NoticePageSlider active={isSliderActive} onClose={toggleSlider}>
        <NoticeSliderPage
          isDraft={isDraft}
          noticeData={noticeData}
          isExecutive={isExecutive}
          toggleSlider={toggleSlider}
          isLoading={isHashDataLoading}
          hasError={hasApiErrorRef.current}
        />
      </NoticePageSlider>
    </NoticeBackground>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "notice.single",
  });
  return (store) => {
    const { rows, cellNameToColumnMap } = getUserRows(store);
    const isExecutive = isCurrentUserIsExecutive(store);
    const companyId = getCurrentUserCompany(store);
    return {
      isExecutive,
      companyId,
      singleNoticeData: rows,
      cellNameToColumnMap,
    };
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSpecificNotice,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NoticeDisplayView);
