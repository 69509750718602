import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import TabsRenderer from "./TabsRenderer";
import DrawerLink from "../DrawerLink";
import { generateDrawerLinks } from "helper";
import { TeamsIcon } from "../../../../AssetComponents";

const [TeamsTabLinks, TeamsLinkComponents] = generateDrawerLinks(
  Routes.authorized.teams,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

export const TeamsTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType("teams_section")}
    >
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("teams")}
        SvgComponent={<TeamsIcon />}
        title="Teams"
        links={TeamsTabLinks}
      >
        {TeamsLinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
