import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import LeadsDisplayView from "../../../Modules/Leads/Views/Display";

import { createRouteObject } from "../../../Helper";

const { leads } = RoutePaths.authorized;

const pages = createRouteObject(
  leads,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [LeadsDisplayView]
);

const LeadsPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact
        path={item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default LeadsPages;
