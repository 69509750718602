import React, { useMemo, useRef, useCallback } from "react";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import { getStatusType } from "helper";
import Routes from "route/Path";

import { Cell, CellIcon } from "../../../../style/display";
import { connect } from "react-redux";
import { createTableDataSelector } from "common/Selectors/MTable";
import { useHistory } from "react-router";

const RenderItem = ({ data, columnIndex, rowIndex, style, rowIds }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
    maxStatusLength,
    rowIdMap,
    isDeleteingWorkshift,
    onDeleteWorkshift,
  } = data;
  const history = useHistory();
  const tempRef = useRef({ rowToDelete: "" });
  const cellvalue = rowData[rowIndex][columnIndex];

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["status"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const isFirstColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["name"] === columnIndex;
  }, [columnIndex]);

  const onCellClick = useCallback(() => {
    history.push(
      `${Routes.authorized.workshift.details.path}/${rowIds[rowIndex]}`
    );
  }, [rowIndex, rowIds]);

  const onEditWorkshift = (e, rowIndex) => {
    history.push(
      `${Routes.authorized.workshift.edit.path}/${rowIdMap[rowIndex]}`
    );
  };

  const onDeleteWorkshiftClick = (e, rowIndex) => {
    if (window.confirm("Are you sure you want to delete the workshift ?")) {
      tempRef.current.rowToDelete = rowIndex;
      const workshiftId = rowIdMap[rowIndex];
      onDeleteWorkshift(workshiftId);
    }
  };
  const actionCellProps = useMemo(() => {
    return () => ({
      cellName: "actions",
      cellActions: [
        {
          id: "class_edit_btn",
          icon: "fas fa-pen",
          tootltipText: "Edit Workshift",
          btnProps: {
            disabled:
              isDeleteingWorkshift && tempRef.current.rowToDelete === rowIndex,
            onClick: onEditWorkshift,
          },
        },
        {
          id: "class_delete_btn",
          icon: "fas fa-trash",
          tootltipText: "Delete Workshift",
          btnProps: {
            disabled:
              isDeleteingWorkshift && tempRef.current.rowToDelete === rowIndex,
            onClick: onDeleteWorkshiftClick,
          },
        },
      ],
    });
  }, [onEditWorkshift, onDeleteWorkshiftClick, rowIdMap]);

  const myChildren = useMemo(() => {
    if (isFirstColumn) {
      return (
        <Cell style={{ cursor: "pointer" }} onClick={onCellClick}>
          {cellvalue}
          <CellIcon name="fas fa-arrow-right" />
        </Cell>
      );
    }
    if (isStatusColumn) {
      return (
        <Cell>
          <Status
            text={cellvalue}
            type={getStatusType(cellvalue)}
            style={{ width: `${maxStatusLength * 18}px`, padding: "6px" }}
          />
        </Cell>
      );
    }
  }, [isStatusColumn, isFirstColumn, cellvalue, maxStatusLength]);

  return (
    <GridCell
      row={rowIndex}
      showHeading={showHeading}
      actionCellProps={actionCellProps}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "workshift.type",
    reverse: false,
  });
  const maker = (store, props) => {
    const { rowIdMap } = getUserRows(store, props);
    return {
      rowIds: rowIdMap,
    };
  };
  return maker;
};
export default connect(mapStateToProps, null)(RenderItem);
