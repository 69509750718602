import styled from "styled-components";

export const FormContainer = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;
export const DateRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
`;
