import React from "react";
import { withTheme } from "styled-components";

const Icon = ({ color, theme, ...otherProps }) => {
  return (
    <svg
      width="17"
      height="28"
      viewBox="0 0 17 28"
      fill={color || theme.colors.primaryColor}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M3.99508 0C4.37865 0.264475 4.4349 0.398174 4.31581 0.844566C4.20622 1.2537 4.24202 1.65479 4.35381 2.05516C4.5328 2.69808 4.57298 3.34247 4.38157 3.99196C4.2749 4.35361 4.01116 4.54502 3.70504 4.46977C3.38065 4.39014 3.21773 4.09059 3.32659 3.72018C3.48001 3.19854 3.39746 2.69443 3.2601 2.18886C3.11691 1.65918 3.11983 1.13023 3.24403 0.596895C3.30467 0.336073 3.41864 0.12347 3.66632 0C3.7759 0 3.88549 0 3.99508 0Z"
          fill={color || theme.colors.primaryColor}
        />
        <path
          d="M8.37865 -3.65661e-06C8.76221 0.265202 8.81847 0.39817 8.69938 0.844563C8.58979 1.25443 8.62559 1.65479 8.73737 2.05516C8.91637 2.69808 8.95582 3.34246 8.76514 3.99123C8.65847 4.35287 8.39472 4.54356 8.08861 4.46831C7.76422 4.38867 7.6013 4.08913 7.71089 3.71799C7.86431 3.19634 7.78176 2.69223 7.6444 2.18666C7.50048 1.65698 7.50413 1.12803 7.62833 0.5947C7.68897 0.333878 7.80294 0.121275 8.05062 -0.00146484C8.15947 -3.65661e-06 8.26906 -3.65661e-06 8.37865 -3.65661e-06Z"
          fill={color || theme.colors.primaryColor}
        />
        <path
          d="M12.762 0C13.1573 0.276164 13.2018 0.386484 13.0762 0.869406C12.9724 1.2705 13.0112 1.6621 13.12 2.0537C13.299 2.69662 13.3392 3.341 13.1485 3.9905C13.0418 4.35287 12.7796 4.54429 12.4734 4.46977C12.1491 4.39086 11.9847 4.09059 12.0935 3.72091C12.247 3.19927 12.1644 2.69516 12.0278 2.18959C11.8824 1.65114 11.886 1.11415 12.019 0.572054C12.0811 0.317808 12.2009 0.12274 12.434 0.000730593C12.5428 0 12.6524 0 12.762 0Z"
          fill={color || theme.colors.primaryColor}
        />
        <path
          d="M1.11936 9.40489C0.900179 9.40489 0.718261 9.41293 0.536344 9.40343C0.212691 9.38516 -0.0247522 9.15357 0.00227974 8.83283C0.0819144 7.89841 0.177622 6.96544 0.281366 6.0332C0.30986 5.77384 0.526115 5.60361 0.792782 5.58169C0.874608 5.57512 0.957166 5.57877 1.03899 5.57877C5.82292 5.57877 10.6068 5.57877 15.39 5.57877C15.9694 5.57877 16.1104 5.70224 16.1725 6.27868C16.2594 7.08452 16.342 7.89037 16.4231 8.69694C16.4691 9.16014 16.2478 9.40124 15.7824 9.40635C15.637 9.40781 15.4909 9.40635 15.3119 9.40635C15.2856 9.7943 15.2571 10.1713 15.2344 10.549C15.0532 13.6043 14.8735 16.6597 14.6938 19.715C14.544 22.2611 14.3935 24.8073 14.2459 27.3534C14.2196 27.8085 14.0326 27.9992 13.5818 27.9992C10.0034 28 6.4242 28 2.84575 27.9992C2.39497 27.9992 2.2094 27.8093 2.18237 27.3527C2.00703 24.3974 1.82803 21.4422 1.65415 18.4869C1.49854 15.8495 1.34803 13.2127 1.19461 10.5753C1.17269 10.1954 1.14566 9.81548 1.11936 9.40489ZM13.9844 13.193C10.1144 13.193 6.29342 13.193 2.44319 13.193C2.64338 16.5888 2.8421 19.9664 3.04155 23.3424C6.50968 23.3424 9.93762 23.3424 13.3868 23.3424C13.5869 19.9539 13.7849 16.583 13.9844 13.193ZM2.37598 12.0621C6.28538 12.0621 10.159 12.0621 14.0531 12.0621C14.1042 11.1751 14.1546 10.3064 14.205 9.42169C10.2014 9.42169 6.2284 9.42169 2.22401 9.42169C2.27516 10.3057 2.32484 11.1744 2.37598 12.0621ZM13.3195 24.4748C9.89233 24.4748 6.51187 24.4748 3.10876 24.4748C3.15698 25.2924 3.20447 26.0887 3.25196 26.8938C6.56959 26.8938 9.86091 26.8938 13.1771 26.8938C13.2246 26.0865 13.2713 25.2909 13.3195 24.4748ZM1.15004 8.2922C5.87917 8.2922 10.5732 8.2922 15.2797 8.2922C15.222 7.74133 15.1672 7.21165 15.1124 6.68854C10.4929 6.68854 5.90621 6.68854 1.31223 6.68854C1.25817 7.22991 1.20484 7.75521 1.15004 8.2922Z"
          fill={color || theme.colors.primaryColor}
        />
        <path
          d="M10.9562 18.3391C10.9189 19.2487 10.6457 20.0699 10.0079 20.745C9.0391 21.77 7.60129 21.8211 6.55508 20.8743C5.14138 19.595 5.12165 16.9809 6.51709 15.6827C7.80366 14.4852 9.7865 14.928 10.5697 16.5886C10.8298 17.1417 10.9365 17.7283 10.9562 18.3391ZM7.48878 16.3307C6.72896 16.8823 6.40969 17.9409 6.65955 18.9382C6.82028 19.5789 7.13663 20.0962 7.85627 20.4052C7.85627 19.9552 7.89791 19.5724 7.84823 19.2012C7.7233 18.2609 7.32659 17.3594 7.48878 16.3307ZM8.63508 16.1546C8.48677 16.7515 8.53937 17.2907 8.67892 17.8233C8.87691 18.5817 9.04494 19.343 8.96969 20.1349C8.96896 20.1437 8.9938 20.1554 9.02522 20.1795C9.75362 19.4978 9.98741 18.6591 9.79527 17.7028C9.65791 17.0284 9.32622 16.4754 8.63508 16.1546Z"
          fill={color || theme.colors.primaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="16.4289"
            height="28"
            fill={color || theme.colors.primaryColor}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BigCupIcon = withTheme(Icon);
