import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import TabsRenderer from "./TabsRenderer";
import DrawerLink from "../DrawerLink";
import { generateDrawerLinks } from "helper";

import Reimbursement_Img from "assets/Ticket.svg";
import E_Reimbursement_Img from "assets/employee/Ticket.svg";

const [ledgerTabLinks, ledgerLinkComponents] = generateDrawerLinks(
  Routes.authorized.ledger,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

export const LedgerTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType("ledger_section")}
    >
      {/* <DrawerTitle title="Ledger Section" /> */}
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("ledger")}
        image={isAdmin ? Reimbursement_Img : E_Reimbursement_Img}
        title="Ledger"
        links={ledgerTabLinks}
      >
        {ledgerLinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
