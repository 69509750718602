import React from "react";

import Button from "components/Button";
import useFetchComments from "hooks/useFetchComments";

import { CommentList, CommentSkeletonList } from "components/Posts";

const CommentView = ({ commentableId, commentableType }) => {
  const { isLoading, comments, fetchNextPage, allDataFethced } =
    useFetchComments({
      commentableId: commentableId,
      commentableType: commentableType,
    });

  const fetchMoreComments = () => {
    fetchNextPage();
  };
  return (
    <>
      <CommentList comments={comments} />
      {isLoading && <CommentSkeletonList count={3} />}
      {!allDataFethced && !isLoading && (
        <Button onClick={fetchMoreComments}>Load More</Button>
      )}
    </>
  );
};

export default CommentView;
