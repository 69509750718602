import { useEffect, useMemo } from "react";
import { getOncefetched } from "common/Selectors/store";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { createTableDataSelector } from "common/Selectors/MTable";

import usePaginator from "hooks/usePaginator";
import usePagination from "hooks/usePagination";

import { fetchPeopleAbsentToday } from "../../redux/attendance/actionCreator";
import { useSelector } from "react-redux";
import { transformCollaboratorMatrixToArray } from "../../utils";
const onceFetchedSelector = getOncefetched("dashboard.attendance.absent");

const todayAbsentSelector = createTableDataSelector({
  tableName: "dashboard.attendance.absent",
  blackList: { "Sl No": true },
  fetchAllData: true,
});

export function useFetchAbsentToday() {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    allPagesFetched,
    errorOccured,
  } = usePagination({
    actionCreator: fetchPeopleAbsentToday,
    tableName: "dashboard.attendance.absent",
  });

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const isOnceFetched = useSelector(onceFetchedSelector);
  const { rows: data, rowIdMap } = useSelector(todayAbsentSelector);
  const getAbsenteesCount = useSelector(
    (store) => store.dashboard.attendance.absent.count
  );

  const { fetchFirstPage, fetchNextPage } = usePaginator({
    paginatorApi: getNextPage,
    extraParams: { companyId, isAdmin },
  });

  useEffect(() => {
    if (!isOnceFetched) {
      fetchFirstPage();
    }
  }, [isOnceFetched]);

  const peoplesAbsentToday = useMemo(() => {
    const allData = transformCollaboratorMatrixToArray(data, rowIdMap);
    return allData.reverse();
  }, [data]);
  return {
    isLoading: loadingNextPage || loadingPrevPage,
    peoplesAbsentToday,
    fetchNextPage,
    allDataFethced: allPagesFetched,
    hasError: errorOccured,
    absenteesCount: getAbsenteesCount,
  };
}
