import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  AbcTab,
  UserTab,
  AccessAssetsTab,
  LogoutTab,
  DashboardTab,
  NoticeTab,
  HolidayTab,
  HolidayYearTab,
  CalendarTab,
  ReimbursementTab,
  ProfileTab,
  LeadsTab,
  FollowSubordinatesTab,
  AttendenceTab,
  SeparationTab,
  SalarySlipTab,
  LeaveTab,
  HelpdeskTab,
  CompanyBranchTab,
  BreaksTab,
  TeamsTab,
  LeavesTab,
  LedgerTab,
  CountdownTab,
  WorkshiftTab,
} from "./Tabs";

import { isLoggedInAsAdmin } from "../../Selectors/auth";
import { DrawerContainer } from "./style";

const Drawer = ({ isDrawerActive, isMobileView }) => {
  const isAdmin = useSelector(isLoggedInAsAdmin);
  const isZeroWidth = useMemo(() => {
    return !isDrawerActive && !isMobileView;
  }, [isDrawerActive, isMobileView]);

  return (
    <DrawerContainer
      isDrawerActive={isDrawerActive}
      isMobileView={isMobileView}
      isZeroWidth={isZeroWidth}
    >
      <DashboardTab isAdmin={isAdmin} />
      <UserTab isAdmin={isAdmin} />
      <ProfileTab isAdmin={isAdmin} />
      <CompanyBranchTab isAdmin={isAdmin} />
      <LeadsTab isAdmin={isAdmin} />
      <FollowSubordinatesTab isAdmin={isAdmin} />
      <TeamsTab isAdmin={isAdmin} />
      <CountdownTab isAdmin={isAdmin} />
      <AccessAssetsTab isAdmin={isAdmin} />
      <AbcTab isAdmin={isAdmin} />
      <NoticeTab isAdmin={isAdmin} />
      <CalendarTab isAdmin={isAdmin} />
      <HolidayYearTab isAdmin={isAdmin} />
      <HolidayTab isAdmin={isAdmin} />
      <WorkshiftTab isAdmin={isAdmin} />
      <LeavesTab isAdmin={isAdmin} />
      <ReimbursementTab isAdmin={isAdmin} />
      <LedgerTab isAdmin={isAdmin} />
      <BreaksTab isAdmin={isAdmin} />
      <AttendenceTab isAdmin={isAdmin} />
      <LeaveTab isAdmin={isAdmin} />
      <SalarySlipTab isAdmin={isAdmin} />
      <HelpdeskTab isAdmin={isAdmin} />
      <SeparationTab isAdmin={isAdmin} />

      <LogoutTab isAdmin={isAdmin} />
    </DrawerContainer>
  );
};

export default Drawer;
