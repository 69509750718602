import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import PostDetailView from "../../../Modules/PostDetail/Views";

import { createRouteObject } from "../../../Helper";

const { posts } = RoutePaths.authorized;

const pages = createRouteObject(
  posts,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [PostDetailView]
);

const PostPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact={item.slugPath ? false : true}
        path={item.slugPath || item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default PostPages;
