import { createAsyncActionCreator } from "helper";
import { UPDATE_PASSWORD } from "./actionTypes";
import { updatePasswordApi } from "./api";

const updatePasswordGenerator = function* ({
  token,
  email,
  password,
  confirmPassword,
}) {
  yield {
    action: UPDATE_PASSWORD,
    sideEffect: updatePasswordApi({ token, email, password, confirmPassword }),
  };
};

export const updatePassword = createAsyncActionCreator(updatePasswordGenerator);
