import React, { useCallback, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Button from "components/Button";
import { RectangleSkeleton } from "components/Skeleton";

import ActionArea from "./ActionArea";
import TableData from "./TableData";

import EmptyScreen from "assets/employee/LeadBlank.png";
import { isCurrentUserIsExecutive } from "common/Selectors/auth";
import { useDeleteWorkshiftDetail } from "../../../../../hooks/useDeleteWorkshiftDetail";

import { ViewContainer } from "globalStyles";
import {
  EmptyScreenContainer,
  EmptyScreenImage,
  EmptyScreenMessage,
} from "../../../../../style/display";
import Icon from "components/Icon";
import { useHistory } from "react-router";
import { resetWorkshiftDetail } from "../actionCreator";
import Routes from "route/Path";

const WorkshiftDetailView = ({ updateFormVisibility, isAdmin }) => {
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);
  const { isLoading: deleteingWokrshift, deleteWorkshift } =
    useDeleteWorkshiftDetail();
  const dispatch = useDispatch();
  const history = useHistory();

  const onClose = () => {
    dispatch(resetWorkshiftDetail());
    history.push(Routes.authorized.workshift.view.path);
  };
  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  return (
    <ViewContainer>
      <ActionArea
        onLoading={onDbDataChange}
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        updateFormVisibility={updateFormVisibility}
      />
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        onEmptyData={onEmptyData}
        isDeleteingWorkshift={deleteingWokrshift}
        onDeleteWorkshift={deleteWorkshift}
      />
      {!isLoading && isEmpty && (
        <EmptyScreenContainer>
          <EmptyScreenImage src={EmptyScreen} alt="No records found" />
          <EmptyScreenMessage>
            No collaborator workshift created yet
          </EmptyScreenMessage>
          {isAdmin && (
            <Button primary size="lg" onClick={updateFormVisibility}>
              Create New
            </Button>
          )}
          <Button
            outline
            size="lg"
            onClick={onClose}
            style={{ marginTop: "10px" }}
          >
            Go Back
            <Icon name="fas fa-arrow-left" style={{ marginLeft: "10px" }} />
          </Button>
        </EmptyScreenContainer>
      )}
      {isLoading && <RectangleSkeleton times={4} />}
    </ViewContainer>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { isAdmin };
};

export default connect(mapStateToProps, null)(WorkshiftDetailView);
