import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const createAccessAssetApi = ({ companyId, formData, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/company_assets`,
  });

  return requestData(authUrl, {
    method: "POST",
    body: {
      company_asset: {
        assigned_to_id: formData.assigned_to_id,
        category: formData.category,
        description: formData.description,
        status: formData.status,
        issued_on: formData.issued_on,
        documents: formData.documents,
      },
    },
    isFormData: true,
  });
};


export const getAccessAssetsApi = ({ companyId, pageNumber, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/company_assets?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const editAccessAssetApi = ({
  branchId,
  isAdmin,
  companyId,
  formData,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/company_assets/${branchId}`,
  });
  return requestData(url, {
    method: "PUT",
    body: {
      company_asset : formData,
    },
    isFormData: true,
  });
};

export const deleteAccessAssetApi = ({ companyId, isAdmin, assetId }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/company_assets/${assetId}`,
  });
  return requestData(url, {
    method: "DELETE",
  });
};

export const searchAccessAssetApi = ({
  companyId,
  pageNumber,
  assignedTo,
  category,
  currentFromDate,
  currentToDate,
  currentStatus,
  isAdmin,
}) => {
  let assigned_to_id, asset_category, from_date, to_date, status;
  assigned_to_id = asset_category = from_date = to_date = status = "";

  if (assignedTo) assigned_to_id = `&assigned_to_id=${assignedTo}`;
  if (category) asset_category = `&category=${category}`;
  if (currentFromDate) from_date = `&from_date=${currentFromDate}`;
  if (currentToDate) to_date = `&to_date=${currentToDate}`;
  if (currentStatus) status = `&status=${currentStatus}`;

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/search_company_assets?page=${pageNumber}${assigned_to_id}${asset_category}${from_date}${to_date}${status}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
