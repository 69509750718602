const getDate = (date) => {
  const monthsArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const indexOfFirstHyphen = date.indexOf("-");
  const indexOfSecondHyphen = date.indexOf("-", indexOfFirstHyphen + 1);
  const day = date.slice(indexOfSecondHyphen);
  const monthCode = date.slice(indexOfFirstHyphen + 1, indexOfSecondHyphen) - 1;
  const year = date.slice(0, indexOfFirstHyphen);
  const dateString = `${monthsArray[monthCode]} ${day}, ${year}`;
  return new Date(dateString);
};

export const isDateValid = (value, minDate, maxDate) => {
  const dateValue = new Date(value);
  if (dateValue >= getDate(minDate) && dateValue <= getDate(maxDate)) {
    return true;
  }
  return false;
};

export const generateFormData = (values) => {
  const yearId = values.holidayCreate_holidayYear;
  const holidayDate = values.holidayCreate_holidayDate;
  const holidayReason = values.holidayCreate_holidayReason;
  let isOptional = values.holidayCreate_optionalStatus?.isOptional || false;

  return {
    yearId,
    holidayDate,
    holidayReason,
    isOptional,
    holidayCardImage: values?.holidayCreate_holidayCardImage[0],
  };
};
