import React, { useState, useCallback } from "react";
import { connect } from "react-redux";

import { isCurrentUserIsExecutive } from "common/Selectors/auth";

import Button from "components/Button";
import InfoView from "components/InfoView";
import { RectangleSkeleton } from "components/Skeleton";

import EmptyScreen from "assets/employee/EmptyScreen.png";
import FamilyImage from "assets/common/Family.png";

import QualificationsCreateView from "../Create/index";
import ActionArea from "./ActionArea";
import TableData from "./TableData";

import { ViewContainer } from "globalStyles";
import { EnhancedActionArea } from "../../style/display";

const QualificationsDisplayView = ({ isAdmin, dataIds }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(true);
  }, []);

  if (isFormVisible)
    return <QualificationsCreateView setIsFormVisible={setIsFormVisible} />;
  else
    return (
      <ViewContainer>
        <EnhancedActionArea
          style={{
            display: isLoading ? "none" : "inherit",
          }}
        >
          <ActionArea onLoading={onDbDataChange} />
          {!isAdmin && dataIds.length > 0 && (
            <Button primary onClick={updateFormVisibilty}>
              Add Information
            </Button>
          )}
        </EnhancedActionArea>
        <TableData
          style={{
            display: isLoading || isEmpty ? "none" : "inherit",
          }}
          onEmptyData={onEmptyData}
        />
        {!isLoading && isEmpty && (
          // isAdmin ?
          //   <EmptyScreenContainer>
          //     <EmptyScreenImage src={EmptyScreen} alt="No records found" />
          //   </EmptyScreenContainer>
          //   :
          <InfoView
            image={FamilyImage}
            onCta={isAdmin ? "" : updateFormVisibilty}
            ctaText={isAdmin ? "No Records Found" : "Add Information"}
          />
        )}
        {isLoading && <RectangleSkeleton times={4} />}
      </ViewContainer>
    );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { isAdmin, dataIds: store.qualifications.dataIds };
};

export default connect(mapStateToProps, null)(QualificationsDisplayView);
