import React, { useState, useCallback } from "react";

import { RectangleSkeleton } from "components/Skeleton";

import ActionArea from "./ActionArea";
import TableData from "./TableData";

import InfoView from "components/InfoView";
import EmptyScreen from "assets/employee/BreaksBlank.png";

import { ViewContainer } from "../../../../GlobalStyles";

const BreaksDisplayView = () => {
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);
  return (
    <ViewContainer>
      <ActionArea
        onLoading={onDbDataChange}
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
      />
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        onEmptyData={onEmptyData}
      />

      {!isLoading && isEmpty && (
        <InfoView
          image={EmptyScreen}
          desc={"There are no breaks to display yet"}
        />
      )}
      {isLoading && <RectangleSkeleton times={4} />}
    </ViewContainer>
  );
};

export default BreaksDisplayView;
