import React, { useMemo } from "react";

import Tooltip from "../../Tooltip2";

import Mountain_Img from "assets/employee/mountain.svg";
import Video_Img from "assets/employee/video.svg";
import Poll_Img from "assets/employee/poll.svg";
import Quiz_Img from "assets/employee/quiz.svg";

import { postTypes } from "../constants";

import { PostIconItemContainer, PostIconItem } from "./style";

const Items = [
  { title: "Photo", image: Mountain_Img, type: "image" },
  {
    title: "Video",
    image: Video_Img,
    type: "video",
    disabled: true,
    tooltip: "Coming soon",
  },
  { title: postTypes.poll, image: Poll_Img, type: "poll" },
  { title: postTypes.quiz, image: Quiz_Img, type: "quiz" },
];

function PostIconItemList({
  image,
  onClick,
  imageInputId,
  videoInputId,
  ...rest
}) {
  const list = useMemo(() => {
    return Items.map((item, idx) => {
      const key = `${item.title}_${idx}`;
      if (item.type === "image" || item.type === "video") {
        const inputId = item.type === "image" ? imageInputId : videoInputId;
        return (
          <>
            <PostIconItem
              $disabled={item.disabled}
              data-tooltip={key}
              key={key}
            >
              <label htmlFor={item.disabled ? "" : inputId}>
                <img src={item.image} />
              </label>
            </PostIconItem>
            {!!item.tooltip && (
              <Tooltip tooltipId={key}>{item.tooltip}</Tooltip>
            )}
          </>
        );
      }
      return (
        <>
          <PostIconItem
            data-tooltip={key}
            key={key}
            $disabled={item.disabled}
            onClick={
              item.disabled ? null : () => onClick && onClick(item.title)
            }
          >
            <img src={item.image} />
          </PostIconItem>
          {!!item.tooltip && <Tooltip tooltipId={key}>{item.tooltip}</Tooltip>}
        </>
      );
    });
  }, [onClick, imageInputId, videoInputId]);

  return <PostIconItemContainer {...rest}>{list}</PostIconItemContainer>;
}

export default PostIconItemList;
