import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import DrawerLink from "../DrawerLink";
import TabsRenderer from "./TabsRenderer";

import { generateDrawerLinks } from "helper";

import Calendar_Img from "assets/Calendar.svg";
import E_Calendar_Img from "assets/employee/Calendar.svg";

const [tabLinks, LinkComponents] = generateDrawerLinks(
  Routes.authorized.leaves,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  DrawerLink
);

export const LeavesTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType("leaves_section")}
    >
      {/* <DrawerTitle title="Leaves Section" /> */}
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("leaves")}
        image={isAdmin ? Calendar_Img : E_Calendar_Img}
        title="Leaves"
        links={tabLinks}
      >
        {LinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
