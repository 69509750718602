import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";
import { createRouteObject } from "../../../Helper";

import UserCreateView from "../../../Modules/User/Views/Create";
import UserDisplayView from "../../../Modules/User/Views/Display";

const { user } = RoutePaths.authorized;
const pages = createRouteObject(
  user,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [UserCreateView, UserDisplayView]
);

const UserPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact
        path={item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default UserPages;
