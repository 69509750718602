import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Grid from "common/Components/Grid";
import RenderItem from "./RenderItem";

import { createTableDataSelector } from "common/Selectors/MTable";
import { isCurrentUserIsExecutive } from "common/Selectors/auth";

import { TableContainer } from "globalStyles";
import { calculateMaxLength } from "../../../../Helper";

const TableData = ({
  data,
  onCellClick,
  onEmptyData,
  isSearchOn,
  isAdmin,
  cellNameToColumnMap,
  updteApplyStatus,
  ...other
}) => {
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);

  useEffect(() => {
    if (typeof onEmptyData !== "function" || isAdmin) {
      return;
    }

    if (rows === 1 && !isSearchOn) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);

  // used to manage the apply button enambled/disabled status
  useEffect(() => {
    let isEnabled = true;
    if (data && !data[0].length > 0) {
      const statusColumn = cellNameToColumnMap["status"];
      for (let i = 1; i < data.length; i++) {
        const val = data[i][statusColumn];
        if (
          val &&
          (val.toLowerCase() === "applied" || val.toLowerCase() === "accepted")
        ) {
          isEnabled = false;
          break;
        }
      }
    }

    if (typeof updteApplyStatus === "function") {
      updteApplyStatus(isEnabled);
    }
  }, [data]);

  const maxStatusLength = useMemo(
    () => {
      return calculateMaxLength(cellNameToColumnMap, data);
    },
    [data],
  )

  return (
    <TableContainer {...other}>
      <Grid
        onClick={onCellClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        data={{
          rowData: data,
          cellNameToColumnMap,
          maxStatusLength: maxStatusLength
        }}
        dataKey="rowData"
      >
        {RenderItem}
      </Grid>
    </TableContainer>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "separation",
    blackList: { "Sl No": true },
  });
  const maker = (store, props) => {
    const { rows, cellNameToColumnMap } = getUserRows(store, props);
    const isAdmin = isCurrentUserIsExecutive(store, props);

    return {
      data: rows,
      isAdmin,
      cellNameToColumnMap,
      isSearchOn: store.separation.isSearchOn
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
