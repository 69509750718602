import { GET_EMPLOYEE_EMAIL, GET_USER_EMAIL, CREATE_USER_EMAIL } from "./actiontypes";
import { RESET_USER_ID } from "../User/actiontypes";

import { createInitialStoreState, createNewStoreOnGetData, createNewStoreOnCreate } from "helper";

const initialState = createInitialStoreState();

export const emailsReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${GET_EMPLOYEE_EMAIL}_SUCCESS`:
    case `${GET_USER_EMAIL}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);
    case `${CREATE_USER_EMAIL}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);

    case RESET_USER_ID: {
      return {
        ...store,
        onceFetched: false,
      };
    }

    default:
      return store;
  }
};
