import React, { useMemo, useCallback } from "react";
import { createYearMatrix } from "helper";

import { DataContainer, RowContainer, Cell } from "./style";

const YearRow = ({ data = [], onYearChange, currentYear, dateRange, isFromDate }) => {
  const onChange = useCallback(
    (e) => {
      const { year } = e.target.dataset;
      onYearChange(year);
    },
    [onYearChange]
  );

  const fromDateDay = useMemo(() => dateRange.fromDate.day, [dateRange.fromDate]);
  const fromDateYear = useMemo(() => dateRange.fromDate.year, [dateRange.fromDate]);

  const toDateDay = useMemo(() => dateRange.toDate.day, [dateRange.toDate]);
  const toDateYear = useMemo(() => dateRange.toDate.year, [dateRange.toDate]);

  const isButtonDisabled = useCallback((year) => {
    if (!fromDateDay && !toDateDay)
      return false;

    if (isFromDate && year) {
      if (year > toDateYear)
        return true;
      return false;
    }

    if (year && year < fromDateYear)
      return true;

    return false;
  }, [data, isFromDate, fromDateDay, toDateDay, fromDateYear, toDateYear])

  return (
    <RowContainer>
      {data.map((year) => {
        return (
          <Cell
            disabled={isButtonDisabled(year)}
            data-year={year}
            onClick={onChange}
            role="button"
            year={year}
            isActive={year == currentYear}
            cellType="year"
            key={`yearCell_${year}`}
          >
            {year}
          </Cell>
        );
      })}
    </RowContainer>
  );
};

const Years = React.forwardRef(
  (
    { startingYear, endingYear, onYearChange, currentYear, visibility, dateRange, isFromDate },
    ref
  ) => {
    const { years } = useMemo(() => {
      return {
        years: createYearMatrix({
          startingYear,
          endingYear,
        }),
      };
    }, [endingYear, startingYear]);
    return (
      <DataContainer $visibility={visibility} ref={ref}>
        {years.map((yearRows, index) => {
          return (
            <YearRow
              key={`yearRow_${index}`}
              currentYear={currentYear}
              onYearChange={onYearChange}
              data={yearRows}
              dateRange={dateRange}
              isFromDate={isFromDate}
            />
          );
        })}
      </DataContainer>
    );
  }
);

export default Years;
