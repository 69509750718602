import React from "react";
import Button from "../Button";
import Icon from "../Icon";
import { SliderContainer, BackButton } from "./style";
import styles from "./Slider.module.css";

const NoticePageSlider = ({ active, onClose, children, ...otherProps }) => {
  return active ? (
    <SliderContainer {...otherProps}>
      <BackButton>
        <Button
          outline
          size="full"
          className={styles.back_button}
          onClick={onClose}
        >
          <Icon name="fas fa-arrow-left" /> &nbsp;Back
        </Button>
      </BackButton>
      {children}
    </SliderContainer>
  ) : null;
};

export default NoticePageSlider;
