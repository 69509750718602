import React from "react";

import InsertInDom from "../InsertInDom";
import Drawer from "./Drawer";

import useTransition from "../../../Hooks/useTransition";

import { MobileDrawerWrapper, DrawerOverlay } from "./style";

const MobileDrawer = ({ toggleDrawer, isDrawerActive }) => {
  const { isVisible } = useTransition({ active: isDrawerActive, maxWait: 400 });
  return (
    isVisible && (
      <InsertInDom domId="overlay">
        <MobileDrawerWrapper>
          <DrawerOverlay
            isDrawerActive={isDrawerActive}
            role="button"
            onClick={toggleDrawer}
          />
          <Drawer isMobileView={true} isDrawerActive={isDrawerActive} />
        </MobileDrawerWrapper>
      </InsertInDom>
    )
  );
};

export default MobileDrawer;
