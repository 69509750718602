import React from "react";

import Button from "components/Button";

import { DescriptionContainer } from "./style";

function Description({ title, desc, onStart, onClose }) {
  return (
    <DescriptionContainer>
      <span className="heading">{title}</span>
      <span className="desc">{desc}</span>
      <div className="footer">
        <Button primary onClick={onStart}>
          Start Survey
        </Button>
        <Button outline onClick={onClose}>
          Not Now
        </Button>
      </div>
    </DescriptionContainer>
  );
}

export default Description;
