import { useMemo } from "react";
import { TabContainer, TabHeader, TabContent } from "components/Tabs2";
import AllReactionsSection from "./Sections/AllReactionsSection";
import { useFetchAllReactions } from "../PostReactionsModalView/Hooks/useFetchAllReactions";
import { getReactionsCount } from "../PostReactionsModalView/redux/Selector";
import { Container, Header, Section, InfoTab } from "./style";

import { postReactionImages } from "../../constants";
import Divider from "../../../Divider";
import Tooltip from "components/Tooltip2";
import { useSelector } from "react-redux";
import { ReactionsTabsMap } from "./constants";

export const ReactionHeader = ({ postId, initialReactionCount }) => {
  const reactionsCount = useSelector((store) =>
    getReactionsCount(store, { type: "total" })
  );
  const TabsList = useMemo(() => {
    const list = [];
    Object.keys(reactionsCount)?.map((type) => {
      if (reactionsCount[type] > 0) {
        list.push(ReactionsTabsMap[type.replace("Count", "")]);
      }
    });
    return list;
  }, [reactionsCount]);

  return (
    <Container>
      <TabContainer
        initialData={{
          activeTabId: ReactionsTabsMap.total.value,
        }}
      >
        <Header>
          <TabHeader>
            {TabsList.map((el) => (
              <ReactionTabHeader
                id={el.value}
                title={el.value}
                count={reactionsCount}
              />
            ))}
          </TabHeader>
        </Header>
        <Divider />
        <Section>
          <TabContent id={ReactionsTabsMap.total.value}>
            <AllReactionsSection
              postId={postId}
              reactionsType={ReactionsTabsMap.total.value}
              initialReactionCount={initialReactionCount}
              count={reactionsCount?.[`${ReactionsTabsMap.total.value}Count`]}
            />
          </TabContent>
          <TabContent id={ReactionsTabsMap.celebrate.value}>
            <AllReactionsSection
              postId={postId}
              reactionsType={ReactionsTabsMap.celebrate.value}
              count={
                reactionsCount?.[`${ReactionsTabsMap.celebrate.value}Count`]
              }
            />
          </TabContent>
          <TabContent id={ReactionsTabsMap.curious.value}>
            <AllReactionsSection
              postId={postId}
              reactionsType={ReactionsTabsMap.curious.value}
              count={reactionsCount?.[`${ReactionsTabsMap.curious.value}Count`]}
            />
          </TabContent>
          <TabContent id={ReactionsTabsMap.insightful.value}>
            <AllReactionsSection
              postId={postId}
              reactionsType={ReactionsTabsMap.insightful.value}
              count={
                reactionsCount?.[`${ReactionsTabsMap.insightful.value}Count`]
              }
            />
          </TabContent>
          <TabContent id={ReactionsTabsMap.like.value}>
            <AllReactionsSection
              postId={postId}
              reactionsType={ReactionsTabsMap.like.value}
              count={reactionsCount?.[`${ReactionsTabsMap.like.value}Count`]}
            />
          </TabContent>
          <TabContent id={ReactionsTabsMap.love.value}>
            <AllReactionsSection
              postId={postId}
              reactionsType={ReactionsTabsMap.love.value}
              count={reactionsCount?.[`${ReactionsTabsMap.love.value}Count`]}
            />
          </TabContent>
          <TabContent id={ReactionsTabsMap.support.value}>
            <AllReactionsSection
              postId={postId}
              reactionsType={ReactionsTabsMap.support.value}
              count={reactionsCount?.[`${ReactionsTabsMap.support.value}Count`]}
            />
          </TabContent>
        </Section>
      </TabContainer>
    </Container>
  );
};

function ReactionTabHeader({ id, title, count, active, ...rest }) {
  return (
    <InfoTab $active={active === title} id={id} {...rest}>
      {title === "total" && (
        <span className="title">{`All ${count[`${title}Count`]}`}</span>
      )}
      {title !== "total" && (
        <span
          style={{ textAlign: "center", marginLeft: "10px" }}
          data-tooltip={title}
        >
          <img
            style={{
              width: "17px",
              height: "17px",
            }}
            src={postReactionImages[title]}
          />
          <span
            style={{
              fontSize: "16px",
              fontWeight: "500",
              margin: "0 0 0 8px",
            }}
          >
            {count[`${title}Count`]}
          </span>
        </span>
      )}
      <Tooltip tooltipId={title}>{ReactionsTabsMap?.[title]?.name}</Tooltip>
    </InfoTab>
  );
}
