import React, { useCallback, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup";
import Icon from "components/Icon";
import Button from "components/Button";
import useAlert from "hooks/useAlert";

import { validateEmail } from "helper";
import { generateFormData } from "../../utils";

import { createUserEmail } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { StyledFormGroup } from "globalStyles";
import {
  FormHeading,
  Header,
  PersonalCheckBox,
} from "../../style/email";
import { isPersonalOptions } from "./options";

const Form = ({
  setIsFormVisible,
  userId,
  companyId,
  isAdmin,
  createUserEmail,
}) => {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const formData = generateFormData(values);
        await createUserEmail({ companyId, isAdmin, userId, formData });
        showAlert({ message: "Email Created" });
        resetForm();
        setIsFormVisible(false);
      } catch (err) {
        showAlert({ message: err[0], type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [companyId, isAdmin, userId]
  );

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Add new email</FormHeading>
        <Icon name="fas fa-times" onClick={updateFormVisibilty} />
      </Header>

      <InputGroup
        id="userEmail_email"
        fieldType="input"
        required
        validator={validateEmail}
        invalidMessage="Invalid Email"
        placeholder="Enter email"
        inputMode="email"
        label="Email"
      />
      <InputGroup
        id="userEmail_description"
        fieldType="textArea"
        label="Description"
        size="auto"
        placeholder="Add description here"
      />

      <PersonalCheckBox>
        <InputGroup
          id="userEmail_isPersonal"
          fieldType="checkboxGroup"
          label="Email Status"
          secondaryLabel
          options={isPersonalOptions}
          groupName="userEmail_isPersonal"
        />
      </PersonalCheckBox>

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create&nbsp; <Icon name="fas fa-arrow-right" />
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    isAdmin,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createUserEmail,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Form);
