import React, { useMemo } from "react";
import Icon from "components/Icon";
import {
  Container,
  CountBox,
  Header,
  List,
  Text,
  StyledEmptyData,
  ButtonContainer,
  StyledWrong,
  HeaderMainContent,
  HeaderRefreshContent,
} from "./style";
import Button from "../Button";
import { UserProfile } from "./UserProfile";

const AttendanceListBox = ({
  data = [],
  headingText = "",
  showIcon = true,
  onEndReached = null,
  loading = true,
  initialLoading = true,
  errorOccured,
  listCount,
  refreshData = null,
}) => {
  const count = useMemo(() => listCount, [listCount]);

  const list = useMemo(() => {
    return data.map((item, index) => (
      <UserProfile item={item} key={index} isLoading={initialLoading} />
    ));
  }, [data, initialLoading]);

  const renderButtonContainer = useMemo(() => {
    if (
      data.length === 0 ||
      initialLoading ||
      errorOccured ||
      typeof onEndReached !== "function"
    )
      return null;

    return (
      <ButtonContainer>
        <Button
          className="load-more-button"
          loading={loading && !errorOccured}
          onClick={onEndReached}
          outline
        >
          Load More
        </Button>
      </ButtonContainer>
    );
  }, [loading, errorOccured, onEndReached, initialLoading, data]);

  return (
    <Container>
      <Header>
        <HeaderMainContent>
          {count && <CountBox>{count}</CountBox>}
          <Text>{headingText}</Text>
          {showIcon && <Icon name="fas fa-users" />}
        </HeaderMainContent>

        {typeof refreshData === "function" && (
          <HeaderRefreshContent onClick={refreshData}>
            <Icon name="fas fa-sync" />
            <Text>Refresh</Text>
          </HeaderRefreshContent>
        )}
      </Header>
      {data.length === 0 && !initialLoading && !errorOccured && (
        <StyledEmptyData />
      )}
      {data.length > 0 && !errorOccured && <List>{list}</List>}
      {!initialLoading && errorOccured && <StyledWrong />}
      {renderButtonContainer}
    </Container>
  );
};

export default AttendanceListBox;
