import { createSelector } from "reselect";

const storeCurrentNoticeId = (store, props) => {
  return store.notice.draft.currentNoticeId;
};

export const getCurrentNoticeId = createSelector(
  storeCurrentNoticeId,
  (currNoticeId) => currNoticeId
);
