import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useMemo,
  useRef,
} from "react";

import Input from "../Input";
import ColorPicker from "../Input/colorPicker";
import TextArea from "../Input/TextArea";
import Select from "../Input/Select";
import AdvanceSelect from "../Input/AdvanceSelect";
import MultipleSelect from "../Input/MultipleSelect";
import AsyncMultipleSelect from "../Input/AsyncMultipleSelect";
import SearchSelect from "../Input/SearchSelect";
import AsyncSearchSelect from "../Input/AsyncSearchSelect";
import DatePicker from "../Input/DatePicker";
import RadioGroup from "../Input/RadioGroup";
import Radio from "../Input/RadioGroup/Radio";
import CheckBoxGroup from "../Input/CheckBoxGroup";
import ChipsGroup from "../Input/ChipsGroup";
import FilePicker from "../Input/FilePicker";
import GoogleFilePicker from "../Input/GoogleFilePicker";
import Error from "../Error";

import FormContext from "../../../Context/FormContext";

import { getSpacedString, removePrevUnderScoreString } from "../../../Helper";
import usePersistValue from "../../../Hooks/usePersistValue";

import { InputWrapper, Label } from "./style";
import LocationSearch from "../../../Modules/Map/Views/LocationSearch";
import DateRangePicker from "../Input/DateRangePicker";
/**
 * 1. id should always contain underscore before actual id name. eg u_title
 */
const InputGroup = ({
  id,
  label,
  invalidMessage,
  success,
  validator,
  onChange,
  onBlur,
  initialValue,
  required,
  fieldType,
  setClearButton,
  onClearField,
  wrapperClass,
  shouldPersist,
  options,
  children,
  secondaryLabel,
  wrapperStyle,
  ...otherProps
}) => {
  const setupInputForm = useContext(FormContext);
  const { persistedValue, updatePersistedValue } = usePersistValue({
    shouldPersist,
    id,
  });
  const [value, updateValue] = useState(persistedValue || initialValue || "");
  const [isValid, updateValidStatus] = useState(true);
  const [onceFocused, updateOnceFoucsed] = useState(false);
  const prevInitialVal = useRef();
  const isMountedRef = useRef(false);

  if (
    typeof prevInitialVal.current === "undefined" ||
    prevInitialVal.current === null
  ) {
    prevInitialVal.current = initialValue;
  }

  useEffect(() => {
    if (typeof initialValue === "boolean") {
      return;
    }

    if (
      initialValue &&
      typeof initialValue === "object" &&
      typeof prevInitialVal.current === "object"
    ) {
      const currKeys = Object.keys(initialValue);
      const prevKeys = Object.keys(prevInitialVal.current);
      let isSame = true;

      const arr = currKeys.length > prevKeys.length ? currKeys : prevKeys;

      for (let i = 0; i < arr.length; i++) {
        const key = arr[0];
        if (!initialValue[key] || !prevInitialVal.current[key]) {
          isSame = false;
          break;
        }
      }

      if (isSame) {
        return;
      }
    }

    if (initialValue !== prevInitialVal.current) {
      updateValue(initialValue);
      updatePersistedValue(initialValue);
      prevInitialVal.current = initialValue;
    }
  }, [initialValue, updatePersistedValue]);

  const validateInput = useCallback(() => {
    if (required) {
      if (!value || (typeof value === "string" && !value.trim())) {
        updateValidStatus(false);
        return false;
      }

      if (typeof validator !== "function") {
        updateValidStatus(true);
        return value;
      }

      if (validator(value)) {
        updateValidStatus(true);
        return value;
      } else {
        updateValidStatus(false);
        return false;
      }
    } else {
      if (typeof validator === "function" && value) {
        if (validator(value)) {
          updateValidStatus(true);
          return value;
        } else {
          updateValidStatus(false);
          return false;
        }
      }
      updateValidStatus(true);
      return value;
    }
  }, [required, validator, value]);

  useEffect(() => {
    if (isMountedRef.current && Array.isArray(options) && !initialValue) {
      updateValue("");
    }
    isMountedRef.current = true;
  }, [options, initialValue]);

  useEffect(() => {
    validateInput();
  }, [onceFocused, validateInput]);

  const onInputChange = useCallback(
    (e) => {
      let { value } = e.target;
      value = fieldType === "file" ? e.target.files : value;
      updateValue(value);
      updatePersistedValue(value);
      if (typeof onChange === "function") {
        onChange(e);
      }
    },
    [fieldType, onChange, updatePersistedValue]
  );

  const onInputBlured = useCallback(
    (e) => {
      updateOnceFoucsed(true);
      if (typeof onBlur === "function") {
        onBlur(e);
      }
    },
    [updateOnceFoucsed, onBlur]
  );

  const resetInput = useCallback(() => {
    updatePersistedValue("");
    updateValue(initialValue || "");
    updateValidStatus(true);
    updateOnceFoucsed(false);
  }, [initialValue, updatePersistedValue]);

  const focusInput = useCallback(() => {
    updateOnceFoucsed(true);
  }, []);

  useEffect(() => {
    setupInputForm(id, validateInput, resetInput, focusInput, required);
  }, [focusInput, id, required, resetInput, setupInputForm, validateInput]);

  const errorMessage = useMemo(() => {
    const newId = getSpacedString(removePrevUnderScoreString(id));
    if (
      onceFocused &&
      required &&
      (!value || (typeof value === "string" && !value.trim()))
    ) {
      return `${newId} is required.`;
    }

    if (onceFocused && required && !isValid) {
      return invalidMessage;
    } else if (onceFocused && !required && !isValid) {
      return invalidMessage;
    }
  }, [id, invalidMessage, isValid, onceFocused, required, value]);

  const clearField = useCallback(() => {
    if (!value) {
      return;
    }
    if (typeof onClearField === "function") {
      onClearField();
    }
    resetInput();
  }, [onClearField, resetInput, value]);
  const Field = useMemo(() => {
    switch (fieldType) {
      case "textArea":
        return TextArea;
      case "select":
        return Select;
      case "advanceSelect":
        return AdvanceSelect;
      case "multiSelect":
        return MultipleSelect;
      case "asyncMultiSelect":
        return AsyncMultipleSelect;
      case "searchSelect":
        return SearchSelect;
      case "asyncSearchSelect":
        return AsyncSearchSelect;
      case "datePicker":
        return DatePicker;
      case "radioGroup":
        return RadioGroup;
      case "radio":
        return Radio;
      case "checkboxGroup":
        return CheckBoxGroup;
      case "chipsGroup":
        return ChipsGroup;
      case "file":
        return FilePicker;
      case "googleFilePicker":
        return GoogleFilePicker;
      case "color":
        return ColorPicker;
      case "locationSearch":
        return LocationSearch;
      case "dateRangePicker":
        return DateRangePicker;
      default:
        return Input;
    }
  }, [fieldType]);

  return (
    <InputWrapper style={wrapperStyle} className={wrapperClass}>
      <Label secondaryLabel={secondaryLabel} htmlFor={id}>
        {label}
      </Label>
      <Field
        value={value}
        onChange={onInputChange}
        onBlur={onInputBlured}
        success={onceFocused && isValid}
        error={onceFocused && !isValid}
        id={id}
        setClearButton={setClearButton}
        clearField={clearField}
        options={options}
        {...otherProps}
      />
      {errorMessage && <Error>{errorMessage}</Error>}
      {children}
    </InputWrapper>
  );
};

InputGroup.defaultProps = {
  initialValue: false,
  required: false,
  fieldType: "text",
  setClearButton: false,
  onClearField: null,
  wrapperClass: "",
  shouldPersist: false,
  options: [],
  secondaryLabel: false,
  wrapperStyle: {},
};

export default InputGroup;
