export const assetCategoryOptions = [
  { name: "Laptop", value: "laptop" },
  { name: "Credentials", value:"credentials"}
];

export const statusOptions = [
  { name: "In Use", value: "in_use" },
  { name: "Submit Ok", value: "submit_ok" },
  { name: "Submit Not Ok", value: "submit_not_ok" },
]
