import React from "react";
import MultipleImageViewer from "../MultipleImageViewer";
import { DropIcon } from "./style";

export const ObjectCellContent = ({ containsOnlyImages, data }) => {
  if (containsOnlyImages) {
    return <MultipleImageViewer data={data} />;
  }
  return (
    <>
      View Details
      <DropIcon name="fas fa-chevron-down" />
    </>
  );
};
