import React, { useCallback, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Stepper from "components/Stepper";

import useForm from "hooks/useForm";
import useAlert from "hooks/useAlert";

import CredentialForm from "./Forms/Credentials";
import CollaboratorForm from "./Forms/Collaborator";
import EmployeeForm from "./Forms/Employee";
import EmploymentForm from "./Forms/Employment";

import { updateUser, createUser } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { generateFormData } from "../../utils";
import {
  validateFileSize,
  validateEmail,
  validateMobile,
  validatePassword8CharLongWithCharContainCheck,
  validateNumber,
} from "helper";

import { Spacer } from "../../../../GlobalStyles";
import { FormHeading, MainFormContainer } from "../../style/create";

const Form = ({
  isEditMode = false,
  createUser,
  updateUser,
  isAdmin,
  companyId,
  initialData = {},
  closeModal,
  userId,
}) => {
  const [isLoading, updateLoading] = useState(false);
  const [currentStep, updateStep] = useState(0);

  const { showAlert } = useAlert();
  const validateFile = validateFileSize.bind(this, 5242880);

  const {
    formData: credentialFormData,
    values: credentialValues,
    isFormValid: credentialFormValidity,
    resetForm: resetCredentialForm,
  } = useForm({
    fields: {
      email: {
        required: true,
        validator: validateEmail,
        invalidMessage: "Invalid Email",
      },

      password: {
        required: true,
        validator: validatePassword8CharLongWithCharContainCheck,
        invalidMessage:
          "Must be atleast 8 chars long and contain at least 1 uppercase char, 1 lowercase char, 1 digit and 1 special char",
      },
    },
  });

  const {
    formData: collaboratorFormData,
    values: collaboratorValues,
    isFormValid: collaboratorFormValidity,
    resetForm: resetCollaboratorForm,
  } = useForm({
    fields: {
      name: {
        required: true,
      },
      phoneNumber: {
        required: true,
        validator: validateMobile,
        invalidMessage: "Invalid phone number",
      },
      gender: {
        required: true,
      },
      avatar: {
        required: true,
        validator: validateFile,
        invalidMessage: "File uploaded is exceeding size limit",
      },
      role: {},
    },
  });

  const {
    formData: employeeFormData,
    values: employeeValues,
    isFormValid: employeeFormValidity,
    resetForm: resetEmployeeForm,
  } = useForm({
    fields: {
      employeeId: {
        required: true,
      },
      joiningDate: {
        required: true,
      },
    },
  });

  const {
    formData: employmentFormData,
    values: employmentValues,
    isFormValid: employmentFormValidity,
    resetForm: resetEmploymentForm,
  } = useForm({
    fields: {
      priorExperience: {
        validator: validateNumber,
        invalidMessage: "Enter valid number of years",
      },
      noticePeriod: {
        validator: validateNumber,
        invalidMessage: "Enter valid number of days",
      },
      probationEndDate: {},
      employmentStatus: {},
      employmentType: {},
      confirmationDate: {},
      externalResource: {},
    },
  });

  const resetForm = () => {
    resetCredentialForm();
    resetEmployeeForm();
    resetCollaboratorForm();
    resetEmploymentForm();
    updateStep(0);
  };

  const onFormSubmit = async () => {
    try {
      updateLoading(true);
      const data = {
        companyId,
        isAdmin,
        userId,
        ...generateFormData(
          credentialValues,
          collaboratorValues,
          employeeValues,
          employmentValues
        ),
      };
      isEditMode ? await updateUser(data) : await createUser(data);
      showAlert({ message: `User ${isEditMode ? "Updated" : "Created"}` });
      resetForm();
      if (isEditMode && typeof closeModal === "function") {
        closeModal();
      }
    } catch (err) {
      showAlert({ message: "User Create Failed", type: "error" });
    } finally {
      updateLoading(false);
    }
  };

  const onSubFormSubmit = () => {
    updateStep((val) => val + 1);
  };

  const onGoBack = () => {
    updateStep((val) => Math.max(0, val - 1));
  };

  return (
    <MainFormContainer>
      {!isEditMode && <FormHeading>Create User</FormHeading>}
      <Stepper
        currentStep={currentStep}
        steps={[
          "Credentials",
          "Collaborator Details",
          "Employee",
          "Employment",
        ]}
      />

      <Spacer />

      {currentStep === 0 && (
        <CredentialForm
          formData={credentialFormData}
          isFormValid={credentialFormValidity}
          onSubmit={onSubFormSubmit}
        />
      )}

      {currentStep === 1 && (
        <CollaboratorForm
          formData={collaboratorFormData}
          isFormValid={collaboratorFormValidity}
          onSubmit={onSubFormSubmit}
          onBack={onGoBack}
        />
      )}

      {currentStep === 2 && (
        <EmployeeForm
          formData={employeeFormData}
          isFormValid={employeeFormValidity}
          onSubmit={onSubFormSubmit}
          onBack={onGoBack}
        />
      )}

      {currentStep === 3 && (
        <EmploymentForm
          formData={employmentFormData}
          isFormValid={employmentFormValidity}
          onSubmit={onFormSubmit}
          onBack={onGoBack}
          isEditMode={isEditMode}
          loading={isLoading}
          disabled={isLoading}
        />
      )}
    </MainFormContainer>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    isAdmin,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createUser,
      updateUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
