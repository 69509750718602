import { useCallback } from "react";
import { isDateCombinationValid, validateNumber } from "helper";
import useForm from "hooks/useForm";

function useDebitForm() {
  const endDateValidator = useCallback((endDate, values) => {
    if (values?.from_date) {
      const { isValid } = isDateCombinationValid(values?.from_date, endDate);
      return isValid;
    }
    return true;
  }, []);

  const { formData, values, resetForm, isFormValid } = useForm({
    fields: {
      expense_type: {
        required: true
      },
      from_date: {
        required: true
      },
      to_date: {
        required: true,
        validator: endDateValidator,
        invalidMessage: "Invalid Date"
      },
      notes: {
        required: false
      },
      amount: {
        required: true,
        validator: validateNumber,
        invalidMessage: "Enter a valid amount"
      },
      invoices: {
        required: true
      }
    }
  });

  return { isFormValid, formData, values, resetForm };
}
export default useDebitForm;
