import React, { useCallback, useState } from "react";
import TabHeader from "./TabHeader";
import { TabContainer } from "./style";

const Tabs = ({ children, tabNames = [], secondary = false }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const setIndex = useCallback(
    (index) => {
      setCurrentTabIndex(index);
    },
    [setCurrentTabIndex]
  );
  return (
    <TabContainer>
      <TabHeader
        currentTabIndex={currentTabIndex}
        setIndex={setIndex}
        tabNames={tabNames}
        secondary={secondary}
      />
      {children(currentTabIndex + 1)}
    </TabContainer>
  );
};
export default Tabs;
