import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import NoticeView from "components/NoticeView";
import { createTableDataSelector } from "../../../../../Common/Selectors/MTable";
import { generateTableData } from "../../../utils";
import { isCurrentUserIsExecutive } from "common/Selectors/auth";

const TableData = ({
  data,
  cellNameToColumnMap,
  onCellClick,
  isSearchOn,
  isExecutive,
  onEmptyData,
  ...other
}) => {
  const tableData = generateTableData(data, cellNameToColumnMap, isExecutive);
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (rows === 1 && !isSearchOn) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);

  return (
    <NoticeView
      onClick={onCellClick}
      data={tableData}
      isDraft={false}
      {...other}
    />
  );
};

const mapStateToProps = (store, props) => {
  const getSentUserRows = createTableDataSelector({
    tableName: "notice.sent",
  });
  const getAllUserRows = createTableDataSelector({
    tableName: "notice.allNotices",
  });
  const maker = (store, props) => {
    const isAdmin = isCurrentUserIsExecutive(store);
    const { rows, cellNameToColumnMap } = !isAdmin
      ? getAllUserRows(store, props)
      : getSentUserRows(store, props);

    return {
      data: rows,
      cellNameToColumnMap,
      isSearchOn: store.notice.sent.isSearchOn,
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
