import { getCurrentDataAttributes } from "helper/selectors";
import { createSelector } from "reselect";

const storeActivities = (store, props) => store.attendance.statusActivity.data;
const storeActivityIds = (store, props) =>
  store.attendance.statusActivity.dataIds;
const storeActivityRelations = (store, props) =>
  store.attendance.statusActivity.relation;

export const getAllStatusActivities = createSelector(
  storeActivities,
  storeActivityIds,
  storeActivityRelations,
  (allActivities, activityIds, relations) => {
    let data = [];
    Object.keys(allActivities).map((key) => {
      if (
        typeof allActivities[key] != "function" &&
        activityIds?.includes(key)
      ) {
        const activity = allActivities[key];
        data.push(getCurrentDataAttributes(activity, relations, allActivities));
      }
    });
    return data;
  },
);
