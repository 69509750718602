import {
  CREATE_WORKSHIFT_DETAIL,
  DELETE_WORKSHIFT_DETAIL,
  EDIT_WORKSHIFT_DETAIL,
  GET_ALL_WORKSHIFT_DETAILS,
  RESET_WORKSHIFT_DETAIL,
} from "./actionType";

import {
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createInitialStoreState,
  createNewStoreOnUpdate,
  createNewStoreOnDelete,
} from "helper";

const initialState = createInitialStoreState({}, true);

export const workshiftDetailReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${GET_ALL_WORKSHIFT_DETAILS}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    case `${CREATE_WORKSHIFT_DETAIL}_SUCCESS`: {
      return createNewStoreOnCreate(payload, store, true);
    }

    case `${EDIT_WORKSHIFT_DETAIL}_SUCCESS`:
      return createNewStoreOnUpdate(payload, store, true);

    case `${DELETE_WORKSHIFT_DETAIL}_SUCCESS`:
      return createNewStoreOnDelete(payload, store);

    case RESET_WORKSHIFT_DETAIL:
      return initialState;
    default:
      return store;
  }
};
