import React from "react";

import Routes from "../../../../Routes/Path";
import AccessTypes from "../../../../Routes/AccessTypes";

import OnlyWhenAuthorized from "../../Auth/OnlyWhenAuthorized";

import DrawerLink from "../DrawerLink";
import TabsRenderer from "./TabsRenderer";
import { generateDrawerLinks } from "helper";

import Asset_Img from "assets/Document.svg";
import E_Asset_Img from "assets/employee/Document.svg";

const [followSubordinatesTabLinks, followSubordinatesLinkComponents] =
  generateDrawerLinks(
    Routes.authorized.followSubordinate,
    AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
    DrawerLink
  );

export const FollowSubordinatesTab = ({ isAdmin }) => {
  return (
    <OnlyWhenAuthorized
      requiredAccess={AccessTypes.section.getSetionAccessType(
        "follow_subordinate_section"
      )}
    >
      {/* <DrawerTitle title="Follow / Subordinates Section" /> */}
      <TabsRenderer
        isAdmin={isAdmin}
        accessType={AccessTypes.tabs.getTabAccessType("followSubordinate")}
        image={isAdmin ? Asset_Img : E_Asset_Img}
        title="Subordinate"
        links={followSubordinatesTabLinks}
      >
        {followSubordinatesLinkComponents}
      </TabsRenderer>
    </OnlyWhenAuthorized>
  );
};
