import styled from "styled-components";
import Icon from "components/Icon";

import ProfilePic from "../../ProfilePic";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: none;
  margin-left: auto;
  align-items: center;
  margin-right: 74px;
  cursor: pointer;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const UserImage = styled.img`
  width: 42px;
  height: 42px;
  position: relative;
  object-fit: contain;
`;

export const DefaultUserImage = styled(Icon)`
  font-size: 42px;
  color: white;
`;

export const UserName = styled.span`
  font-size: ${(props) => props.theme.text.para.medium};
  font-weight: 600;
  text-align: left;
  color: #ffffff;
  margin-left: 10px;
`;

export const StyledProfilePic = styled(ProfilePic)`
  background-color: white;
  width: 38px;
  position: relative;
  height: 38px;
  & > span {
    color: ${(props) => props.theme.colors.primaryColor};
  }
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const RedDot = styled.div`
  width: 11px;
  height: 11px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  border: 2px solid ${(props) => props.theme.colors.primaryColor};
  border-radius: 50%;
  background-color: #f03434;
`;

export const Tab = styled(Link)`
  width: 100%;
  height: 50px;
  padding: 10px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: #000;
  font-size: ${(props) => props.theme.text.title.medium};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightPrimaryColor};
`;
