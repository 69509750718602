export const expenseTypeOptions = [
  { name: "Hotel", value: "hotel" },
  { name: "Food", value: "food" },
  { name: "Travel", value: "travel" },
  { name: "Training or Certification", value: "training_or_certification" },
  { name: "Others", value: "others" }
];

export const statusOptions = [
  { name: "pending", value: "pending" },
  { name: "approved", value: "approved" },
  { name: "rejected", value: "rejected" }
];

export const transactionTypeOptions = [
  { name: "Credit", value: "credit" },
  { name: "Debit", value: "debit" }
];
