import { getDistanceBetweenTwoDateInWords } from "helper";

export const tranformToNotificationList = (
  data,
  cellNameToColumnMap,
  onMessageClick
) => {
  const idColumn = cellNameToColumnMap["Sl No"];
  const messageColumn = cellNameToColumnMap["message"];
  const typeColumn = cellNameToColumnMap["notifiableType"];
  const senderColumn = cellNameToColumnMap["sender"];
  const notificableIdColumn = cellNameToColumnMap["notifiableId"];
  const readColumn = cellNameToColumnMap["readAt"];
  const timeColumn = cellNameToColumnMap["createdAt"];

  const res = [];
  data.forEach((row, index) => {
    if (index === 0) {
      return;
    }

    const [noticeId, message, type, user, notifiableId, readAt, createTime] = [
      row[idColumn],
      row[messageColumn],
      row[typeColumn],
      row[senderColumn],
      row[notificableIdColumn],
      row[readColumn],
      row[timeColumn],
    ];
    if (message && type && user) {
      res.push({
        noticeId,
        userName: user.name,
        message,
        time: `${getDistanceBetweenTwoDateInWords(
          new Date(createTime),
          new Date()
        )} ago`,
        type,
        image: user.avatarUrl,
        imageDesc: "user pic",
        buttons: null,
        notifiableId,
        onClick: onMessageClick,
        readAt,
      });
    }
  });
  return res;
};
