import React, { useMemo } from "react";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import MultipleUsers from "components/MultipleUsers";
import { getStatusType } from "helper";

import { Cell } from "../../style/display";

const RenderItem = ({ data, columnIndex, rowIndex, style }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
    maxStatusLength
  } = data;

  const cellvalue = rowData[rowIndex][columnIndex];

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["status"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const isCcColumn = useMemo(() => {
    return (
      rowIndex > 0 &&
      cellNameToColumnMap["cc"] === columnIndex
    );
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const myChildren = useMemo(() => {
    if (isStatusColumn) {
      return (
        <Cell>
          <Status text={cellvalue} type={getStatusType(cellvalue)} style={{ width: `${maxStatusLength * 18}px`, padding: '6px' }} />
        </Cell>
      );
    }
    if (isCcColumn) {
      return (
        <Cell onClick={(e) => e.stopPropagation()}>
          {cellvalue !== 'NA' ?
            <MultipleUsers data={cellvalue} />
            : 'NA'
          }
        </Cell>
      );
    }
    return null;
  }, [isStatusColumn, isCcColumn, cellvalue, maxStatusLength]);

  return (
    <GridCell
      row={rowIndex}
      showHeading={showHeading}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

export default RenderItem;
