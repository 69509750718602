import { useEffect, useCallback, useMemo } from "react";
import usePagination from "hooks/usePagination";

export const useAttendanceListPagination = ({
  companyId,
  isAdmin,
  onceFetched,
  action,
  tableName,
}) => {
  const { loadingNextPage, getNextPage, allPagesFetched, errorOccured } =
    usePagination({
      actionCreator: action,
      tableName: tableName,
    });

  const extraParams = useMemo(
    () => ({ companyId, isAdmin }),
    [companyId, isAdmin]
  );

  const fetchFirstPage = useCallback(() => {
    getNextPage({
      isFirstPage: true,
      extraParams,
    });
  }, [getNextPage, extraParams]);

  const fetchNextPage = useCallback(() => {
    getNextPage({
      extraParams,
    });
  }, [getNextPage, extraParams]);

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  return {
    onEndReached: !allPagesFetched ? fetchNextPage : null,
    initialLoading: !onceFetched && loadingNextPage,
    loading: onceFetched && loadingNextPage && !allPagesFetched,
    errorOccured,
  };
};
