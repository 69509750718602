import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const getLedgersCategoriesApi = ({ companyId, pageNumber, isAdmin }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/ledger_categories?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const createLedgerCategoryApi = ({ companyId, formData, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/ledger_categories`,
  });

  return requestData(authUrl, {
    method: "POST",
    body: {
      ledger_category: {
        name: formData?.name,
        description: formData?.description || "",
        budget: formData?.budget,
        ledger_group_member_ids: Object.keys(formData?.collaborators),
      },
    },
    isFormData: true,
  });
};

export const getLedgersTransactionsApi = ({
  companyId,
  ledgerId,
  pageNumber,
  isAdmin,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/ledger_categories/${ledgerId}/ledger_transactions?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const createLedgerTransactionsApi = ({
  companyId,
  categoryId,
  isAdmin,
  formData,
}) => {
  let ledger_transaction = formData;

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/ledger_categories/${categoryId}/ledger_transactions`,
  });

  return requestData(url, {
    method: "POST",
    body: {
      ledger_transaction,
    },
    isFormData: true,
  });
};

export const updateLedgerTransactionsApi = ({
  companyId,
  categoryId,
  transactionId,
  isAdmin,
  formData,
}) => {
  let ledger_transaction = formData;

  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/ledger_categories/${categoryId}/ledger_transactions/${transactionId}`,
  });

  return requestData(url, {
    method: "PUT",
    body: {
      ledger_transaction,
    },
    isFormData: true,
  });
};
