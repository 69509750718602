import React, { useState, useCallback } from "react";
import { connect } from "react-redux";

import { isCurrentUserIsExecutive } from "common/Selectors/auth";

import Button from "components/Button";
import { RectangleSkeleton } from "components/Skeleton";
import InfoView from "components/InfoView";

import EmptyScreen from "assets/employee/EmptyScreen.png";
import UPIImage from "assets/common/UPI.png";

import UpiCreateView from "../Create/index";
import ActionArea from "./ActionArea";
import TableData from "./TableData";

import { ViewContainer } from "globalStyles";
import { EnhancedActionArea, EmptyScreenContainer, EmptyScreenImage } from "../../style/display";

const UpiDisplayView = ({ userId, isAdmin }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(true);
  }, []);

  if (isFormVisible) {
    return <UpiCreateView setIsFormVisible={setIsFormVisible} />;
  }

  return (
    <ViewContainer>
      <EnhancedActionArea
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
      >
        <ActionArea onLoading={onDbDataChange} userId={userId} />
        {!isAdmin &&
          <Button primary onClick={updateFormVisibilty}>
            Create New
          </Button>
        }
      </EnhancedActionArea>
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        onEmptyData={onEmptyData}
      />
      {!isLoading && isEmpty && (
        // isAdmin ?
        //   <EmptyScreenContainer>
        //     <EmptyScreenImage src={EmptyScreen} alt="No records found" />
        //   </EmptyScreenContainer>
        //   :
        <InfoView
          image={UPIImage}
          onCta={isAdmin ? "" : updateFormVisibilty}
          ctaText={isAdmin ? "No Records Found" : "Add UPI ID"} />
      )}
      {isLoading && <RectangleSkeleton times={4} />}
    </ViewContainer>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { isAdmin };
};

export default connect(mapStateToProps, null)(UpiDisplayView);
