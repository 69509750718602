import React from "react";
import WelcomeCard from "./WelcomeCard";
import NotificationCard from "./NotificationCard";
import TabCard from "./TabCard";

import useUserInformation from "hooks/useUserInformation";
import { getAllTabs, tabsAccessType } from "route/AccessTypes/tab";

import { data } from "./data";

import { Container } from "../../styles/admin";

const Tabs = getAllTabs().map((name) => ({
  name,
  access: tabsAccessType.getTabAccessType(name),
}));

const AdminWelcomePage = () => {
  const { userInfo } = useUserInformation();
  return (
    <Container>
      <WelcomeCard name={userInfo ? userInfo.name : ""} />
      <NotificationCard count={202} />

      {Tabs.map(({ name, access }, index) => {
        return (
          <TabCard
            key={`${name}_${index}`}
            accessType={access}
            name={name}
            to={data[name]?.to || "/"}
            desc={data[name]?.desc || ""}
            icon={data[name]?.icon || ""}
            backgroundColor={data[name]?.backgroundColor || ""}
            color={data[name]?.color || ""}
            overlay={data[name]?.overlay || ""}
          />
        );
      })}
    </Container>
  );
};
export default AdminWelcomePage;
