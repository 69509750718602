import React from "react";
import { Header, FormHeading, FormContainer } from "../../style/create";
import { getUserListData } from "common/Api";
import { ListItem } from "components/List";
import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import Icon from "components/Icon";
import { TeamStatus } from "../../Constants";
import { useTeamCreate } from "../../Hooks/useTeamCreate";
import { getDepartmentListData } from "../../../../Common/Api/elasticSearch";

const Form = ({ updateIsFormSubmitted, isEditOpen, initialData }) => {
  const {
    formData,
    onFormSubmit,
    onDeselectUsers,
    selectedUsersList,
    isLoading,
  } = useTeamCreate({
    updateIsFormSubmitted,
    isEditOpen,
    initialData,
  });

  return (
    <FormContainer onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Create Team</FormHeading>
      </Header>
      <InputGroup
        fieldType="input"
        label="Team Name"
        placeholder="Your team name"
        {...formData["name"]}
      />
      <InputGroup
        label="Team Status"
        fieldType="select"
        defaultSelectText="Select status"
        options={TeamStatus}
        {...formData["status"]}
      />
      <InputGroup
        label="Team Department"
        fieldType="asyncSearchSelect"
        placeholder="Select Department"
        api={getDepartmentListData}
        singleSelect
        {...formData["department"]}
      />
      <InputGroup
        label="Team Owners"
        fieldType="asyncSearchSelect"
        placeholder="Add Owners"
        maxSelectionCount={3}
        api={getUserListData}
        {...formData["owners"]}
      />

      {selectedUsersList &&
        selectedUsersList?.map((item) => {
          return (
            <ListItem
              key={item.id}
              data={item}
              onSelect={onDeselectUsers}
              showDeselectButton={true}
            />
          );
        })}

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        {isEditOpen ? (
          "Update"
        ) : (
          <>
            Create&nbsp; <Icon name="fas fa-arrow-right" />
          </>
        )}
      </Button>
    </FormContainer>
  );
};

export default Form;
