import requestData from "ApiHandler";
import { createAuthUrl } from "helper/api";

export const deleteCommentApi = ({ companyId, isAdmin, commentId }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/comments/${commentId}`,
  });

  return requestData(authUrl, {
    method: "DELETE",
  });
};

export const editCommentApi = ({ companyId, isAdmin, commentId, message }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/comments/${commentId}`,
  });

  return requestData(authUrl, {
    method: "PUT",
    body: {
      comment: {
        message: message,
      },
    },
    isFormData: true,
  });
};

export const fetchCommentsApi = ({
  pageNumber,
  companyId,
  isAdmin,
  commentableId,
  commentableType,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/comments?page=${pageNumber}&commentable_id=${commentableId}&commentable_type=${commentableType}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const createCommentApi = ({
  companyId,
  isAdmin,
  commentableId,
  commentableType,
  message,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/comments`,
  });
  return requestData(authUrl, {
    method: "POST",
    body: {
      comment: {
        message: message,
        commentable_id: commentableId,
        commentable_type: commentableType,
      },
    },
    isFormData: true,
  });
};
