import React from "react";
import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";

import { ButtonGroup, StyledForm } from "../../../style/create";

const CredentialForm = ({ onSubmit, formData, isFormValid }) => {
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (isFormValid() && typeof onSubmit === "function") {
      onSubmit();
    }
  };

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <InputGroup
        label="Email"
        placeholder="Enter email"
        inputMode="email"
        {...formData["email"]}
      />
      <InputGroup
        label="Password"
        placeholder="Enter password"
        type="password"
        {...formData["password"]}
      />
      <ButtonGroup>
        <Button type="submit" primary>
          Next
        </Button>
      </ButtonGroup>
    </StyledForm>
  );
};

export default CredentialForm;
