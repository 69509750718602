import React, { useState } from "react";
import AssignLeaveCreateView from "./Create";
import AssignLeaveView from "./Display";

const AssignLeave = () => {
  const [isFormVisible, setIsFormVisible] = useState(false)

  const updateFormVisibility = (status) => {
    setIsFormVisible(status);
  }

  if (isFormVisible) {
    return <AssignLeaveCreateView updateFormVisibility={updateFormVisibility} />
  }
  return (
    <AssignLeaveView updateFormVisibility={updateFormVisibility} />
  );
};

export default AssignLeave;
