import styled from "styled-components";
import { FadeInVertical } from "globalStyles";
const BottomFade = FadeInVertical("bottom");

export const MainContainer = styled.div`
  height: 100%;
  padding: 0 50px 20px 50px;
  display: flex;
  flex-flow: column nowrap;
  animation: ${BottomFade} ease-out 0.3s forwards;
`;

export const FormHeading = styled.h3`
  font-size: ${(props) => props.theme.text.title.large};
  color: ${(props) => props.theme.colors.primaryColor};
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 600;
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & i {
    color: ${(props) => props.theme.colors.primaryColor};
    font-size: ${(props) => props.theme.text.title.large};
    margin: 20px 0 20px;
    cursor: pointer;
  }
`;

export const StyledImageViewer = styled.div`
  text-align: left;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.label};
  font-size: ${(props) => props.theme.text.para.extraSmall};

  & label {
    font-size: ${(props) => props.theme.text.para.small};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  & button > i {
    margin-left: 10px;
  }
`;
