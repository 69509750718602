import requestData from "../../../../../../ApiHandler";
import { createAuthUrl } from "helper";

export const createWorkshiftDetailApi = ({
  companyId,
  formData,
  isAdmin,
  workshiftId,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/work_hour_shifts/${workshiftId}/collaborator_work_hour_shifts`,
  });

  return requestData(authUrl, {
    method: "POST",
    body: {
      collaborator_work_hour_shift: {
        collaborator_id: Object.keys(formData?.name)[0],
        start_date: formData?.start_date,
        end_date: formData?.end_date,
      },
    },
    isFormData: true,
  });
};

export const editWorkshiftDetailApi = ({
  workshiftId,
  companyId,
  isAdmin,
  formData,
  collaborator_workshift_id,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/work_hour_shifts/${workshiftId}/collaborator_work_hour_shifts/${collaborator_workshift_id}`,
  });
  return requestData(url, {
    method: "PUT",
    body: {
      collaborator_work_hour_shift: {
        start_date: formData?.start_date,
        end_date: formData?.end_date,
        status: formData?.status,
      },
    },
    isFormData: true,
  });
};
export const deleteWorkshiftDetailApi = ({
  workshiftId,
  companyId,
  isAdmin,
  collaborator_workshift_id,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/work_hour_shifts/${workshiftId}/collaborator_work_hour_shifts/${collaborator_workshift_id}`,
  });
  return requestData(url, {
    method: "DELETE",
  });
};

export const getAllWorkshiftDetailsApi = ({
  pageNumber,
  companyId,
  isAdmin,
  workshiftId,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/work_hour_shifts/${workshiftId}/collaborator_work_hour_shifts?page=${pageNumber}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};
