import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BreakItem from "./BreakItem";
import Icon from "../Icon";

import {
  BreaksHeader,
  Heading,
  StyledEmptyData,
  Showtime,
  Timer,
  ListContainer,
  PreviousBreaks,
  CountText,
  Count,
  Footer,
} from "./style";
import { useTimer } from "../../../Hooks/useTimer";
import NavbarPopover from "../NavbarPopover";
import { updateCollaboratorId } from "../../../Modules/FollowSubordinate/actionCreator";
import Routes from "../../../Routes/Path";

const BreaksCenter = ({
  elemIdOrElem,
  dataList,
  onClose,
  initialLoading,
  onClick,
  ongoingBreak,
  currentActiveSnap,
  updateCollaboratorId,
  todaysBreakCount,
  isUserLoggedIn,
}) => {
  const { time, startTimer, stopTimer } = useTimer();

  useEffect(() => {
    if (ongoingBreak) {
      const timeString = ongoingBreak.startTime?.split(" ");
      const startTime = `${timeString[0]} ${timeString[1]}`;
      startTimer(startTime);
    } else {
      stopTimer();
    }
  }, [ongoingBreak]);

  const { list } = useMemo(() => {
    if (!Array.isArray(dataList)) {
      return { list: null };
    }

    const list = dataList.map((data, index) => {
      return (
        <BreakItem
          isLoading={initialLoading}
          key={`${data.id}_${index}`}
          data={data}
          onClick={onClick}
          ongoingBreak={ongoingBreak}
          style={{
            animationDelay: `${(index + 1) * 50}ms`,
          }}
          isUserLoggedIn={isUserLoggedIn}
        />
      );
    });

    return { list };
  }, [dataList, initialLoading, onClick, ongoingBreak, isUserLoggedIn]);

  const getTime = useMemo(() => {
    let timeString = `${time.minutes}:${time.seconds}`;
    timeString = (time.hours !== "00" ? `${time.hours}:` : "") + timeString;
    return timeString;
  });

  const setCollaboratorId = () => {
    updateCollaboratorId("");
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <NavbarPopover onClose={onClose} elemIdOrElem={elemIdOrElem}>
      {(hasScrolled) => {
        return (
          <>
            {list.length > 0 && (
              <BreaksHeader $showShadow={hasScrolled}>
                <Heading>Take a break</Heading>
                <CountText>
                  Todays Break : <Count>{todaysBreakCount}</Count>
                </CountText>
              </BreaksHeader>
            )}
            {list.length > 0 && (
              <ListContainer className="list-item">{list}</ListContainer>
            )}
            {list.length === 0 && !initialLoading && <StyledEmptyData />}
            {list.length > 0 && !initialLoading && (
              <Footer $showShadow={hasScrolled}>
                <PreviousBreaks
                  to={Routes.authorized.breaks.view.path}
                  onClick={setCollaboratorId}
                >
                  View all previous breaks <Icon name="fa fa-chevron-right" />
                </PreviousBreaks>
                {ongoingBreak && currentActiveSnap && (
                  <Showtime>
                    <Heading>{currentActiveSnap.title}</Heading>
                    <Timer>{getTime}</Timer>
                  </Showtime>
                )}
              </Footer>
            )}
          </>
        );
      }}
    </NavbarPopover>
  );
};

BreaksCenter.defaultProps = {
  dataList: [],
  initialLoading: false,
  onClose: () => {},
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCollaboratorId,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(BreaksCenter);
