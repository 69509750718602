import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getUserPersonalInfo } from "../../../selector";
import { addressProofType, maritalStatusOptions, } from "../options";
import { convertDateToYYYYMMDD } from "helper";
import { getOptionValueByOptionName } from "../../../../../Helper/view";

function useInitialData() {

  const { personalInfoData } = useSelector(getUserPersonalInfo);

  const initialDate = useMemo(() => {
    if (!!personalInfoData?.dateOfBirth) {
      let formattedDate = convertDateToYYYYMMDD(personalInfoData?.dateOfBirth, "-");
      return new Date(formattedDate);
    } else return "";
  }, [personalInfoData]);

  const initialMaritalStatus = useMemo(() => {
    return getOptionValueByOptionName(maritalStatusOptions, personalInfoData?.maritalStatus)
  }, [personalInfoData])

  const initialPermanentAddressProofType = useMemo(() => {
    return getOptionValueByOptionName(addressProofType, personalInfoData?.permanentAddressProofType)
  }, [personalInfoData])

  const initialPresentAddressProofType = useMemo(() => {
    return getOptionValueByOptionName(addressProofType, personalInfoData?.presentAddressProofType)
  }, [personalInfoData])


  return { initialDate, initialMaritalStatus, initialPermanentAddressProofType, initialPresentAddressProofType };
}

export default useInitialData;