import React, { useCallback, useMemo, useEffect, useState } from "react";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import Icon from "components/Icon";
import { ListItem } from "components/List";
import useForm from "hooks/useForm";
import { getUserListData } from "common/Api";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive
} from "common/Selectors/auth";
import {
  FormRow,
  SlotContainer,
  StyledForm,
  MainContainer
} from "../../style/create";
import useCreateEditWorkLog from "../../Hooks/useCreateEditWorkLog";

const Form = ({
  updateFormVisibility,
  companyId,
  isAdmin,
  attendanceId,
  dailyWorkReport
}) => {
  const [referenceLinks, setLinks] = useState([]);

  const { formData, values, resetForm, isFormValid, updateField } = useForm({
    fields: {
      accomplishments: {
        required: true
      },
      challenges: {
        required: false
      },
      todo_plans: {
        required: false
      },
      reference_links: {
        required: false
      },
      notifyTo: {
        required: false
      },
      documents: {
        required: false
      }
    }
  });

  useEffect(() => {
    if (dailyWorkReport && dailyWorkReport.id) {
      const files = dailyWorkReport?.documentUrls?.map((file) => {
        return {
          name: file.fileName,
          url: file.url,
          id: file.id
        };
      });

      let notifyTo = {};

      if (dailyWorkReport?.allocatedCarbonCopyMembers?.length > 0) {
        dailyWorkReport?.allocatedCarbonCopyMembers.forEach((item) => {
          notifyTo[item.id] = {
            id: item.id,
            heading: item.name,
            image: item?.avatarUrl?.url
          };
        });
      }

      updateField("accomplishments", dailyWorkReport?.accomplishments);
      updateField("challenges", dailyWorkReport?.challenges);
      updateField("todo_plans", dailyWorkReport?.todoPlans);
      updateField("notifyTo", notifyTo);
      updateField("documents", files);
      setLinks(dailyWorkReport?.referenceLinks);
    }
  }, [dailyWorkReport]);

  const { isLoading, logWork } = useCreateEditWorkLog();

  const onReset = () => {
    resetForm();
    setLinks([]);
  };

  const onFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isFormValid()) {
        return;
      }

      values.referenceLinks = referenceLinks.join(",");

      let purgedDocuments = [];
      let purgedNotifyTo = [];

      if (dailyWorkReport?.id) {
        purgedDocuments = dailyWorkReport?.documentUrls?.filter(
          (n) => !values?.documents?.some((n2) => n.id == n2.id)
        );

        let currentNotifyTo =
          typeof values?.notifyTo === "object"
            ? Object.values(values?.notifyTo)
            : [];
        purgedNotifyTo = dailyWorkReport?.allocatedCarbonCopyMembers?.filter(
          (n) => !currentNotifyTo?.some((n2) => n.id == n2.id)
        );

        values.purgedDocuments =
          purgedDocuments?.length > 0
            ? purgedDocuments.map((item) => item.id)
            : [];
        values.purgedNotifyTo =
          purgedNotifyTo?.length > 0
            ? purgedNotifyTo.map((item) => item.id)
            : [];

        logWork(values, onReset, attendanceId, true, dailyWorkReport.id);
      } else {
        logWork(values, onReset, attendanceId, false);
      }
    },
    [isFormValid, values, onReset, attendanceId, dailyWorkReport],
  );

  const addLink = () => {
    setLinks([...referenceLinks, values.reference_links]);
    updateField("reference_links", "");
  };

  const removeLink = (index) => {
    let linksList = [...referenceLinks];
    linksList.splice(index, 1);
    setLinks(linksList);
  };

  const onDeselectNotifyToUsers = useCallback(
    (itemId, item) => {
      const newListData = { ...values.notifyTo };
      if (newListData[itemId]) {
        delete newListData[itemId];
      }
      updateField("notifyTo", newListData);
    },
    [values]
  );

  const notifyToList = useMemo(() => {
    if (values.notifyTo) {
      return Object.values(values.notifyTo).map((item) => {
        return (
          <ListItem
            key={item.id}
            data={item}
            onSelect={onDeselectNotifyToUsers}
            selected={values.notifyTo && values.notifyTo[item.id]}
            showDeselectButton={true}
          />
        );
      });
    }
  }, [values.notifyTo]);

  return (
    <MainContainer>
      <StyledForm onSubmit={onFormSubmit}>
        <Icon name="fas fa-times" onClick={() => updateFormVisibility(false)} />

        <InputGroup
          label="Accomplishments"
          fieldType="textArea"
          placeholder="Enter today's accomplishments"
          size="lg"
          {...formData["accomplishments"]}
        />

        <InputGroup
          label="Issues or Challenges"
          fieldType="textArea"
          placeholder="Enter today's challenges"
          size="lg"
          {...formData["challenges"]}
        />

        <InputGroup
          label="Plans for the Following Day"
          fieldType="textArea"
          placeholder="Enter following day's plans"
          size="lg"
          {...formData["todo_plans"]}
        />

        <FormRow>
          <InputGroup
            label="Reference Links"
            fieldType="textArea"
            placeholder="Enter today's reference links"
            {...formData["reference_links"]}
          />
          <Button
            outline
            onClick={addLink}
            type="link"
            disabled={!values.reference_links}
            style={{ width: "55px", fontSize: "25px" }}
          >
            +
          </Button>
        </FormRow>

        {referenceLinks.map((link, index) => (
          <ListItem
            key={index}
            data={{ heading: link }}
            onSelect={() => removeLink(index)}
            selected={link}
            showDeselectButton={true}
            hideImage={true}
          />
        ))}

        <InputGroup
          label="Notify To"
          fieldType="asyncSearchSelect"
          placeholder="Search for users"
          api={getUserListData}
          {...formData["notifyTo"]}
        />

        {notifyToList}

        <InputGroup
          label="Documents"
          fieldType="googleFilePicker"
          multiple={true}
          {...formData["documents"]}
        />

        <Button primary loading={isLoading} disabled={isLoading} type="submit">
          {!!dailyWorkReport?.id ? "Edit" : "Create"}
        </Button>
      </StyledForm>
    </MainContainer>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const attendanceId = store.attendance.attendanceTable.attendanceId;
  return {
    companyId,
    isAdmin,
    attendanceId
  };
};

export default connect(mapStateToProps, null)(Form);
