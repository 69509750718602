import {
  CREATE_WORKSHIFT_DETAIL,
  DELETE_WORKSHIFT_DETAIL,
  EDIT_WORKSHIFT_DETAIL,
  GET_ALL_WORKSHIFT_DETAILS,
  RESET_WORKSHIFT_DETAIL,
} from "./actionType";
import { createAsyncActionCreator } from "helper";
import {
  createWorkshiftDetailApi,
  deleteWorkshiftDetailApi,
  editWorkshiftDetailApi,
  getAllWorkshiftDetailsApi,
} from "./api";

const createWorkshiftDetailGenrator = function* ({
  companyId,
  formData,
  isAdmin,
  workshiftId,
}) {
  yield {
    action: CREATE_WORKSHIFT_DETAIL,
    sideEffect: createWorkshiftDetailApi({
      companyId,
      formData,
      isAdmin,
      workshiftId,
    }),
  };
};

const getAllWorkshiftDetailsGenrator = function* ({
  pageNumber,
  companyId,
  isAdmin,
  workshiftId,
}) {
  yield {
    action: GET_ALL_WORKSHIFT_DETAILS,
    sideEffect: getAllWorkshiftDetailsApi({
      pageNumber,
      companyId,
      isAdmin,
      workshiftId,
    }),
  };
};
const editWorkshiftDetailGenrator = function* ({
  companyId,
  workshiftId,
  formData,
  status,
  isAdmin,
  collaborator_workshift_id,
}) {
  yield {
    action: EDIT_WORKSHIFT_DETAIL,
    sideEffect: editWorkshiftDetailApi({
      companyId,
      workshiftId,
      formData,
      status,
      isAdmin,
      collaborator_workshift_id,
    }),
  };
};

const deleteWorkshiftDetailGenrator = function* ({
  workshiftId,
  collaborator_workshift_id,
  companyId,
  isAdmin,
}) {
  yield {
    action: DELETE_WORKSHIFT_DETAIL,
    sideEffect: deleteWorkshiftDetailApi({
      workshiftId,
      companyId,
      isAdmin,
      collaborator_workshift_id,
    }),
  };
};

export const resetWorkshiftDetail = () => ({
  type: RESET_WORKSHIFT_DETAIL,
});

export const [
  createWorksihftDetail,
  getAllWorkshiftDetails,
  editWorkshiftDetail,
  deleteWorkshiftDetail,
] = createAsyncActionCreator([
  createWorkshiftDetailGenrator,
  getAllWorkshiftDetailsGenrator,
  editWorkshiftDetailGenrator,
  deleteWorkshiftDetailGenrator,
]);
