import styled from "styled-components";
import { flexColumnCenter, flexRowCenter } from "../../../GlobalStyles";

export const ViewPortContainer = styled(flexColumnCenter)`
  min-height: ${(props) => (props.isLoggedIn ? "100%" : "100vh")};
  flex-wrap: wrap;
  padding: 0 20px;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    padding: 20px;
    height: ${(props) => (props.isLoggedIn ? "100%" : "100vh")};
    min-height: ${(props) => (props.isLoggedIn ? "100%" : "100vh")};
  }
`;

export const TopContainer = styled(flexRowCenter)`
  width: 100%;
  height: ${(props) => (props.isLoggedIn ? "100%" : "85%")};
  padding: 20px 10px;
  justify-content: space-evenly;
  flex-direction: column;

  @media (min-width: 768px) {
    justify-content: space-between;
    padding: 20px 30px;
    flex-direction: row;
  }
`;

export const BottomContainer = styled(flexRowCenter)`
  width: 100%;
  height: 15%;
  padding: 15px 30px;
`;

export const Box1 = styled(flexColumnCenter)`
  width: 100%;
  align-items: flex-start;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    width: 49%;
    height: 100%;
    padding-left: 50px;
    margin-bottom: 0px;
  }
  @media (min-width: 1024px) {
    width: 38%;
  }
`;
export const Box2 = styled(flexColumnCenter)`
  width: 100%;

  @media (min-width: 768px) {
    width: 49%;
    height: 100%;
  }
  @media (min-width: 1024px) {
    width: 58%;
  }
`;

export const Heading = styled.span`
  font-weight: bold;
  font-size: ${(props) => props.theme.text.heading.large};
  text-align: left;
  color: ${(props) => props.theme.colors.primaryColor};
  color: ${(props) =>
    props.isLoggedIn ? props.theme.colors.primaryColor : "#01008A"};
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.text.heading.extraLarge};
  }
`;

export const SubHeading = styled.span`
  font-weight: bold;
  font-size: ${(props) => props.theme.text.title.large};
  text-align: left;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.text.title.extraLarge};
    margin-bottom: 30px;
  }
`;
export const Paragraph = styled.p`
  font-weight: normal;
  font-size: ${(props) => props.theme.text.para.large};
  text-align: left;
  margin: 0;
`;
export const Image = styled.img`
  width: 100%;
  max-width: 350px;

  @media (min-width: 768px) {
    max-width: 550px;
    width: 85%;
  }
`;
export const HomeButton = styled.button`
  background-color: #01008a;
  font-size: ${(props) => props.theme.text.para.large};
  color: white;
  border: none;
  border-radius: 6px;
  padding: 15px 35px;
`;
export const BackgroundImage = styled.img`
  position: absolute;
  z-index: -2;
  top: ${(props) => (props.isLoggedIn ? "220px" : "160px")};
  right: 0px;
  width: auto;
  height: ${(props) =>
    props.isLoggedIn ? "calc(100% - 220px)" : "calc(100% - 160px)"};

  @media (min-width: 768px) {
    top: 0;
    height: 100%;
  }
`;
