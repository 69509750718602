import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import useForm from "hooks/useForm";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import { isDateCombinationValid, extractDateInYYYYMMDD } from "helper";

import { updateSearchParams, resetSearchParams } from "../../actionCreator";

import { StyledForm } from "../../styles/view";

const SearchFilter = ({
  updateSearchParams,
  resetSearchParams,
  title,
  is_published,
  from_date,
  to_date,
}) => {
  const [startDate, setStartDate] = useState("");
  const [InvalidMessage, setInvalidMessage] = useState("");

  const onStartDateChange = useCallback((e) => {
    setStartDate(e.target.value);
  }, []);

  const endDateValidator = useCallback(
    (endDate) => {
      if (startDate) {
        const { isValid, message } = isDateCombinationValid(
          startDate,
          endDate,
          "Start",
          "End"
        );
        setInvalidMessage(message);
        return isValid;
      }
      return true;
    },
    [startDate]
  );

  const { formData, isFormValid, values, resetForm } = useForm({
    fields: {
      noticeSearch_noticeTitle: {
        required: true,
      },
      noticeSearch_noticeFromDate: {
        onChange: onStartDateChange
      },
      noticeSearch_noticeToDate: {
        validator: endDateValidator,
        invalidMessage: InvalidMessage
      },
    },
  });

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid())
      return
    updateSearchParams({
      title: values.noticeSearch_noticeTitle,
      is_published: is_published,
      from_date: extractDateInYYYYMMDD(values.noticeSearch_noticeFromDate),
      to_date: extractDateInYYYYMMDD(values.noticeSearch_noticeToDate),
    })
  };

  const onResetClick = useCallback(
    () => {
      resetSearchParams({ is_published: is_published });
      setStartDate("");
      resetForm()
    },
    [],
  );

  return (
    <>
      <StyledForm onSubmit={onFormSubmit} onReset={onResetClick}>
        <InputGroup
          fieldType="input"
          placeholder="Enter title"
          {...formData["noticeSearch_noticeTitle"]}
        />

        <InputGroup
          fieldType="datePicker"
          placeholder="Start date"
          {...formData["noticeSearch_noticeFromDate"]}
        />

        <InputGroup
          fieldType="datePicker"
          placeholder="End date"
          {...formData["noticeSearch_noticeToDate"]}
        />

        <Button primary type="submit" size="lg">
          Search
        </Button>
        <Button
          outline
          disabled={!title && !from_date && !to_date}
          type="reset"
          size="lg"
        >
          Reset
        </Button>
      </StyledForm>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSearchParams,
      resetSearchParams,
    },
    dispatch
  );


export default connect(null, mapDispatchToProps)(SearchFilter);
