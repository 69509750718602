import { IMAGE_URL } from "../config.local";

import IMG_ICON from "assets/FileIcons/Img.png";
import CDR_ICON from "assets/FileIcons/CDR.png";
import CSS_ICON from "assets/FileIcons/CSS.png";
import EPS_ICON from "assets/FileIcons/EPS.png";
import GIF_ICON from "assets/FileIcons/GIF.png";
import HTML_ICON from "assets/FileIcons/HTML.png";
import MP4_ICON from "assets/FileIcons/MP4.png";
import PDF_ICON from "assets/FileIcons/PDF.png";
import PPT_ICON from "assets/FileIcons/PPT.png";
import TXT_ICON from "assets/FileIcons/TXT.png";
import XLS_ICON from "assets/FileIcons/XLS.png";
import ZIP_ICON from "assets/FileIcons/ZIP.png";
import FILE_ICON from "assets/FileIcons/FILE.png";
import PPTDefault from "assets/FileIcons/PptDefault.png";
import TXTDefault from "assets/FileIcons/TxtDefault.png";
import SheetDefault from "assets/FileIcons/SheetDefault.png";
import ZIPDefault from "assets/FileIcons/ZipDefault.png";
import ImgDefault from "assets/FileIcons/ImgDefault.png";

export const debounce = (callback, timeout = 500) => {
  let id = null;
  return (...args) => {
    if (id) {
      clearTimeout(id);
    }

    id = setTimeout(() => {
      typeof callback === "function" && callback(...args);
    }, timeout);
  };
};

export const converArrayToObject = (data = []) => {
  const obj = {};
  data.forEach((item) => {
    obj[item.id] = { ...item };
  });
  return obj;
};

export const createOuterClickListner = (callback) => {
  document.body.addEventListener("click", callback);
  return () => {
    document.body.removeEventListener("click", callback);
  };
};

export const spreadData = (data, key) => {
  return data ? { [key]: data } : {};
};

export const appendArrayToUrl = (sourceUrl, keyName, values) => {
  const hasAlreadyParams = sourceUrl.includes("?");

  if (!hasAlreadyParams) {
    sourceUrl += "?";
  }

  values.forEach((value, index) => {
    if (index === 0 && !hasAlreadyParams) {
      sourceUrl += `${keyName}=${value}`;
    } else {
      sourceUrl += `&${keyName}=${value}`;
    }
  });

  return sourceUrl;
};

export const checkIfArrayConsistsOfImagesOnly = (array) => {
  if (Array.isArray(array)) {
    let res = array.map(
      (item) =>
        ((Object.keys(item).length >= 2 && item.url && item.cdnUrl) ||
          (Object.keys(item).length === 1 && item.url)) &&
        Object.keys(item)[0]
    );

    res = res.filter((el) => el !== false);
    const set = new Set(res);

    return set.size === 1;
  }
  return false;
};

export const checkIfUrlIsPDF = (url) => {
  const splitUrl = url?.split("?")[0];
  const isPDF = splitUrl?.endsWith(".pdf");
  return isPDF;
};

export const getImageUrl = (url) => {
  if (url && typeof url === "string") {
    return url.charAt(0) === "/" ? `${IMAGE_URL}${url}` : url;
  }
  return "";
};

export const getCDNImageUrl = (url) => {
  if (url && typeof url === "string") {
    return url.indexOf("https://") >= 0 ? url : `https://${url}`;
  }
  return "";
};

export const callAllFunction = (...functions) => {
  return function (...args) {
    functions.forEach((func) => {
      typeof func === "function" && func(...args);
    });
  };
};

export const isNotificationSupported = () => {
  if (!("Notification" in window)) {
    return false;
  }
  return true;
};

export const displayNotification = (title, options = {}) => {
  if (!isNotificationSupported()) {
    console.error("Notification is not supported by the browser");
    return;
  }
  if (title) {
    new Notification(title, options);
  } else {
    console.error("Notification Title is required");
  }
};

export function mergeProps(...rest) {
  let result = {};
  rest.forEach((item) => {
    result = {
      ...result,
      ...item
    };
  });
  return result;
}

export function callAllFunctions(...funcs) {
  return (props) => {
    funcs.forEach((myFunc) => {
      typeof myFunc === "function" && myFunc(props);
    });
  };
}

export const checkIsImageUrl = (url = "") => {
  if (typeof url == "string") {
    const regex = /\.(png|jpg|jpeg|wbep|gif)$/gim;
    return !!url.match(regex);
  }
  return false;
};

export const getFileNameAndExtensionFromUrl = (url) => {
  let decodedUrl = decodeURIComponent(url.substring(0, url.indexOf("?")));
  let fileName = "";
  let extension = "";
  if (decodedUrl) {
    fileName = decodedUrl.split("/").pop();
    extension = fileName.split(".").pop();
  }
  return [fileName, extension];
};

export function getFileTypeIcon(filename) {
  let [fileName, extension] = getFileNameAndExtensionFromUrl(filename);
  switch (extension) {
    case "png":
    case "wbep":
    case "jpe":
    case "jpg":
    case "jpeg":
      return IMG_ICON;
    case "cdr":
      return CDR_ICON;
    case "css":
      return CSS_ICON;
    case "eps":
      return EPS_ICON;
    case "gif":
      return GIF_ICON;
    case "html":
      return HTML_ICON;
    case "mp4":
      return MP4_ICON;
    case "pdf":
      return PDF_ICON;
    case "ppt":
    case "pptx":
      return PPT_ICON;
    case "txt":
      return TXT_ICON;
    case "xls":
    case "xlsx":
      return XLS_ICON;
    case "zip":
      return ZIP_ICON;
    default:
      return FILE_ICON;
  }
}

export const getFileTypeThumbnail = (fileName) => {
  const [name, extension] = getFileNameAndExtensionFromUrl(fileName);

  switch (extension.toUpperCase()) {
    case "PPT":
    case "PPTX":
      return PPTDefault;
    case "TXT":
      return TXTDefault;
    case "XLS":
    case "XLSX":
      return SheetDefault;
    case "ZIP":
      return ZIPDefault;
    case "PNG":
    case "JPG":
    case "JPEG":
    case "WBEP":
      return ImgDefault;
    default:
      return TXTDefault;
  }
};

export const copyToClipBoard = (text) => {
  return new Promise((res, rej) => {
    navigator.clipboard.writeText(text).then(
      function () {
        /* clipboard successfully set */
        res();
        return;
      },
      function (err) {
        /* clipboard write failed */
        rej(err);
        return;
      }
    );
  });
};
