import Icon from "components/Icon";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CommentView from "components/Comment/CommentView";
import { CreateComment } from "components/Comment";
import useCommentDetails from "hooks/useCommentDetails";

import {
  DetailsLeftContainer,
  DetailsRightContainer,
  DetailsContainer,
  MainContainer,
  StatusContainer,
  StyledDivider,
  StyledHeading,
  StatusUpdateContainer,
} from "../../style/details";

import { TabContainer, TabContent, TabHeader } from "components/Tabs2";
import Divider from "components/Divider";
import { createTableDataSelector } from "common/Selectors/MTable";
import { getCurrentIndexRowDataAndColumn } from "../../utils";

import { tranformMatrixToArray } from "helper/matrix";
import ActivityIndicator from "components/ActivityIndicator";
import StatusActivityView from "./StatusActivityView";
import useUpdateSubordinateAttendanceStatus from "modules/FollowSubordinate/Hooks/useUpdateSubordinateAttendanceStatus";
import {
  ApproveStatusOption,
  PendingStatusOption,
  RejectStatusOption,
} from "./options";
import WorklogView from "./WorklogView";

const TabsMap = {
  comments: { name: "Comments", value: "comments" },
  workLogs: { name: "Work Logs", value: "workLogs" },
  activity: { name: "Activity", value: "activity" },
};

const getAttendanceRows = createTableDataSelector({
  tableName: "attendance.attendanceTable",
  reverse: false,
});

const getSubordinateAttendanceRows = createTableDataSelector({
  tableName: "followSubordinate.attendance",
  reverse: false,
});

const getNotifiedReportAttendanceRows = createTableDataSelector({
  tableName: "notifiedWorkReports",
  blackList: { "Sl No": true },
  reverse: false,
});

const AttendanceDetailsView = ({
  rowId,
  isSubordinateAttendance,
  subordinateId,
  isNotifiedWorkReportAttendance,
}) => {
  const [updatedStatus, setUpdatedStatus] = useState();
  const [isCreateFormVisible, setIsCreateFormVisible] = useState(false);
  const { rowIndex } = rowId;
  const { rows, cellNameToColumnMap, rowIdMap } = useSelector(
    isSubordinateAttendance
      ? getSubordinateAttendanceRows
      : isNotifiedWorkReportAttendance
      ? getNotifiedReportAttendanceRows
      : getAttendanceRows
  );
  const { createComment, isCreating } = useCommentDetails();

  const { updateStatus, isLoading } = useUpdateSubordinateAttendanceStatus();

  const { data = [] } = useMemo(
    () => getCurrentIndexRowDataAndColumn(rows, rowIndex),
    [rows, rowIndex]
  );

  const dailyWorkReport = useMemo(() => {
    if (isNotifiedWorkReportAttendance) {
      let allData = tranformMatrixToArray(rows, rowIdMap);
      return allData.find((item) => item.id === rowIndex);
    }
    return data[cellNameToColumnMap?.dailyWorkReport];
  }, [data]);

  const attendanceData = useCallback(
    (key) => {
      return isNotifiedWorkReportAttendance
        ? dailyWorkReport?.modelable[key]
        : data[cellNameToColumnMap[key]];
    },
    [isNotifiedWorkReportAttendance, data, cellNameToColumnMap, dailyWorkReport]
  );

  const TabsList = useMemo(() => {
    let list = [TabsMap.workLogs];
    if (!isNotifiedWorkReportAttendance) {
      list = [TabsMap.comments, ...list, TabsMap.activity];
    }
    return list;
  }, [isNotifiedWorkReportAttendance]);

  const getStatusUpdateOptions = useMemo(() => {
    switch (attendanceData("status")) {
      case "Pending":
        return PendingStatusOption;
      case "Approved":
        return RejectStatusOption;
      case "Rejected":
        return ApproveStatusOption;
    }
  }, [attendanceData]);

  const updateAttendanceStatus = (status) => {
    if (
      window.confirm(`Are you sure you want to ${status?.name.toLowerCase()}?`)
    ) {
      setUpdatedStatus(status?.value);
      updateStatus({
        attendanceId: attendanceData("Sl No"),
        subordinateId: subordinateId,
        status: status?.value,
      });
    }
  };

  const onComment = (comment, onSuccess) => {
    createComment({
      commentableId: attendanceData("Sl No"),
      commentableType: "WorkingHourLog",
      message: comment,
      onSuccess,
    });
  };
  const onCommentWorkLog = (comment, onSuccess) => {
    createComment({
      commentableId: dailyWorkReport.id,
      commentableType: "DailyWorkReport",
      message: comment,
      onSuccess,
    });
  };
  return (
    <MainContainer>
      <DetailsContainer>
        <DetailsLeftContainer>
          <TabContainer initialData={{ activeTabId: TabsMap.workLogs.value }}>
            <TabHeader tabs={TabsList} />
            <Divider />
            <TabContent id={TabsMap.comments.value}>
              <CreateComment
                onComment={onComment}
                isCreatingComment={isCreating}
              />
              <CommentView
                commentableId={attendanceData("Sl No")}
                commentableType="WorkingHourLog"
              />
            </TabContent>

            <TabContent id={TabsMap.workLogs.value}>
              <WorklogView
                isSubordinateAttendance={isSubordinateAttendance}
                isNotifiedWorkReportAttendance={isNotifiedWorkReportAttendance}
                dailyWorkReport={dailyWorkReport}
                onCommentWorkLog={onCommentWorkLog}
                isCreating={isCreating}
                isCreateFormVisible={isCreateFormVisible}
                setIsCreateFormVisible={setIsCreateFormVisible}
              />
            </TabContent>
            <TabContent id={TabsMap.activity.value}>
              <StatusActivityView attendanceId={attendanceData("Sl No")} />
            </TabContent>
          </TabContainer>
        </DetailsLeftContainer>
        {!isCreateFormVisible && (
          <DetailsRightContainer>
            <StyledHeading>Date: {attendanceData("workDate")}</StyledHeading>
            <StyledHeading $workLogTypeColor="#bd1a08">
              Shift: {attendanceData("workHourShift")}
            </StyledHeading>
            <StyledHeading $workLogTypeColor="#76AD06">
              Origin: {attendanceData("workOrigin")}
            </StyledHeading>
            <StyledDivider />
            {!isNotifiedWorkReportAttendance && (
              <StatusContainer $type={attendanceData("status")}>
                Status: {attendanceData("status")}
                <Icon name="fas fa-check-circle" />
              </StatusContainer>
            )}
            {isSubordinateAttendance &&
              getStatusUpdateOptions.map((status) => (
                <StatusUpdateContainer
                  $type={status?.value}
                  onClick={() => updateAttendanceStatus(status)}
                >
                  {status?.name}
                  {isLoading && updatedStatus == status?.value && (
                    <ActivityIndicator color="#49505780" size="s" />
                  )}
                </StatusUpdateContainer>
              ))}
          </DetailsRightContainer>
        )}
      </DetailsContainer>
    </MainContainer>
  );
};

export default AttendanceDetailsView;
