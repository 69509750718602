import { extractDateInYYYYMMDD } from "../../Helper";

export const generateFormData = (values, selectedUser) => {
  const {
    assignedTo,
    category,
    status,
    description,
    issuedOn,
    submittedOn,
    documents
  } = values;

  return {
    assignedTo: values?.assignedTo && Object.values(values?.assignedTo),
    category: category,
    status:status,
    description: description,
    issued_on: extractDateInYYYYMMDD(issuedOn),
    submitted_on: extractDateInYYYYMMDD(submittedOn),
    documents: values?.documents && Object.values(values?.documents),
  }
};

export const generateSearchFormData = (values) => {
  const {
    assetSearch_assetCategory,
    assetSearch_assetFromDate,
    assetSearch_assetToDate,
    assetSearch_assetStatus,
  } = values;

  const assetSearch_assetAssignee = values?.assetSearch_assetAssignee || ""

  return {
    assigned_to_id: Object.keys(assetSearch_assetAssignee).length > 0 ? Object.keys(assetSearch_assetAssignee)[0] : '',
    category: assetSearch_assetCategory,
    from_date: extractDateInYYYYMMDD(assetSearch_assetFromDate),
    to_date: extractDateInYYYYMMDD(assetSearch_assetToDate),
    status: assetSearch_assetStatus,
  }
};
