import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";

import { getAllCollaboratorLeaves } from "../../actionCreator";
import { reducerStore } from "types/index";

function useCollaboratorLeaves() {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const companyId = useSelector((store: reducerStore) =>
    getCurrentUserCompany(store, null)
  );
  const isAdmin = useSelector((store: reducerStore) =>
    isCurrentUserIsExecutive(store, null)
  );

  useEffect(() => {
    (async () => {
      try {
        updateLoading(true);
        await dispatch(getAllCollaboratorLeaves({ companyId, isAdmin }));
      } catch (err) {
        if (err instanceof Array) {
          showAlert({ message: err[0], type: "error" });
        }
      } finally {
        updateLoading(false);
      }
    })();

    return () => {
      updateLoading(false);
    };
  }, []);

  return { isLoading } as const;
}

export default useCollaboratorLeaves;
