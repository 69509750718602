import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useNodeDimensions from "hooks/useNodeDimensions";
import GenericModal from "components/GenericModal";
import Routes from "../../../Routes/Path";

import { Container, Triangle, StyledComingSoon, Tab, Counter } from "./style";

const TRIANGLE_WIDTH = 20;
const TRIANGLE_HEIGHT = 20;

const Settings = ({ elemIdOrElem, onClose }) => {
  const [isActive, updateActiveStatus] = useState(false);
  const [myPos, updateMyPos] = useState({ top: 0, left: 0 });
  const targetElemDimensions = useNodeDimensions({ elemIdOrElem });
  const containerRef = useRef(null);

  /**
   * sets the postion of settings panel according to the target element position
   */
  const calculatePosition = useCallback(() => {
    if (targetElemDimensions && containerRef.current) {
      const { top, left, height, right } = targetElemDimensions;
      const source = containerRef.current.getBoundingClientRect();
      const startLeft = left - source.width / 2;
      const pos = {};
      const windowDim = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      if (startLeft + source.width > windowDim.width) {
        pos.right = windowDim.width - right;

        if (pos.right + source.width > windowDim.width) {
          pos.right = 0;
        }
      } else {
        pos.left = startLeft;
      }
      updateMyPos({ top: top + height, ...pos, maxWidth: windowDim.width });
    }
  }, [targetElemDimensions]);

  const setRef = useCallback(
    (ref) => {
      if (!ref) {
        return;
      }
      containerRef.current = ref;
      calculatePosition();
    },
    [calculatePosition]
  );

  const toggleSettings = useCallback((status) => {
    updateActiveStatus((val) => {
      if (typeof status === "boolean") {
        return status;
      }
      return !val;
    });
  }, []);

  useEffect(() => {
    if (!targetElemDimensions) {
      toggleSettings(false);
    } else {
      toggleSettings(true);
    }
  }, [targetElemDimensions]);

  const trianglePos = useMemo(() => {
    if (!targetElemDimensions) {
      return {};
    }
    const { left, width, top, height } = targetElemDimensions;
    return {
      left: `${left + width / 2 - TRIANGLE_WIDTH / 2}px`,
      top: `${top + height - TRIANGLE_HEIGHT / 2}px`,
      width: `${TRIANGLE_WIDTH}px`,
      height: `${TRIANGLE_HEIGHT}px`,
    };
  }, [targetElemDimensions]);

  return isActive ? (
    <GenericModal onClose={onClose}>
      <Container style={myPos} ref={setRef}>
        {/* <StyledComingSoon /> */}
        <Tab
          to={`${Routes.authorized.leaveManagement.view.path}/pending`}
          onClick={onClose}
        >
          Leave Management
          {/* <Counter>20</Counter> */}
        </Tab>
        <Tab
          to={Routes.authorized.notifiedWorkReport.view.path}
          onClick={onClose}
        >
          Notified Work Report
        </Tab>
      </Container>
      <Triangle $pos={trianglePos} />
    </GenericModal>
  ) : null;
};

Settings.defaultProps = {
  onClose: () => {},
};
export default Settings;
