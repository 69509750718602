import styled, { css, keyframes } from "styled-components";

const warm = keyframes`
 0%{
   opacity:1;
 }
 50%{
   opacity:0.5;
 }
 100%{
   opacity:1;
 }
`;

const base = css`
  background-color: #f3f3f3;
  animation: ${warm} ease-in 1s forwards infinite;
`;

export const Container = styled.div`
  height: auto;
  width: 100%;
`;

export const RectangleLoader = styled.div`
  height: 80px;
  border-radius: 10px;
  margin-bottom: 10px;
  ${base}
`;

export const RectangleLoader2 = styled.div`
  width: 50%;
  height: 10px;
  border-radius: 10px;
  ${base}
`;

export const CircleLoader = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  ${base}
`;
