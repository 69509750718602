import BackButton from "components/BackButton";
import Icon from "components/Icon";
import MultipleUsers from "components/MultipleUsers";
import ProfilePic from "components/ProfilePic";
import { convertToTextDateStringMMDDYYYY } from "helper/date";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getCurrentLeaveDetails } from "../redux/selector";
import useUpdateLeaveStatus from "../hooks/useUpdateLeaveStatus";
import {
  BoldText,
  CollaboratorContainer,
  CollaboratorSubContainer,
  DetailsLeftContainer,
  DetailsRightContainer,
  LeaveDetailsContainer,
  LeaveRecordSubContainer,
  MainContainer,
  StatusContainer,
  StatusUpdateContainer,
  StyledDivider,
  StyledHeading,
  SubText,
  TextContainer,
  TextContent,
} from "../style";
import DocumentView from "./DocumentView";
import ActivityIndicator from "components/ActivityIndicator";
import Routes from "route/Path";
import { getCurrentCollaboratorLeaveDetails } from "modules/Leave/Views/selector";
import { CreateComment } from "components/Comment";

import { TabContainer, TabContent, TabHeader } from "components/Tabs2";
import Divider from "components/Divider";
import Activity from "./ActivityView";
import CommentView from "components/Comment/CommentView";
import useCommentDetails from "hooks/useCommentDetails";
import {
  PendingStatusOption,
  ApproveStatusOption,
  RejectStatusOption,
  CancelStatusOption,
} from "./options";

const TabsMap = {
  comments: { name: "Comments", value: "comments" },
  activity: { name: "Activity", value: "activity" },
};

const TabsList = [TabsMap.comments, TabsMap.activity];

const statusOptions = {
  pending: PendingStatusOption,
  approved: RejectStatusOption,
  rejected: ApproveStatusOption,
};

const LeaveDetailsView = () => {
  const [updatedStatus, setUpdatedStatus] = useState();
  const location = useLocation();
  const history = useHistory();
  const url = location.pathname.split("/");
  const leaveId = url.pop();
  const activeTab = url.pop();
  const isCollaboratorLeaveView = location.state?.isCollaboratorLeaveView;

  const { updateStatus, cancelLeave, isLoading, success } =
    useUpdateLeaveStatus();
  const { createComment, isCreating } = useCommentDetails();

  const leaveDetails = useSelector((store) =>
    isCollaboratorLeaveView
      ? getCurrentCollaboratorLeaveDetails(store, { leaveId })
      : getCurrentLeaveDetails(store, { activeTab, leaveId }),
  );

  const [fromDate, toDate] = useMemo(() => {
    const fromDate = leaveDetails?.fromDate
      ? convertToTextDateStringMMDDYYYY(leaveDetails?.fromDate)
      : "";
    const toDate = leaveDetails?.toDate
      ? convertToTextDateStringMMDDYYYY(leaveDetails?.toDate)
      : "";
    return [fromDate, toDate];
  }, [leaveDetails]);

  useEffect(() => {
    if (Object.keys(leaveDetails).length == 0 && !success) {
      history.goBack();
    }
  }, [leaveDetails, success]);

  useEffect(() => {
    if (success && !isCollaboratorLeaveView) {
      history.push(
        `${Routes.authorized.leaveManagement.details.path}/${updatedStatus}/${leaveId}`,
      );
    }
  }, [updatedStatus, leaveId, success, isCollaboratorLeaveView]);

  const updateLeaveStatus = (status) => {
    if (
      window.confirm(`Are you sure you want to ${status?.name.toLowerCase()}?`)
    ) {
      setUpdatedStatus(status?.value);
      isCollaboratorLeaveView
        ? cancelLeave({ leaveId, status: status?.value })
        : updateStatus({
            leaveId,
            status: status?.value,
          });
    }
  };

  const onComment = (comment, onSuccess) => {
    createComment({
      commentableId: leaveId,
      commentableType: "LeaveRequisition",
      message: comment,
      onSuccess,
    });
  };

  const StatusUpdateOptions = useMemo(() => {
    if (
      isCollaboratorLeaveView &&
      leaveDetails?.status.toLowerCase() == "pending"
    ) {
      return CancelStatusOption;
    }
    return statusOptions[activeTab];
  }, [activeTab, isCollaboratorLeaveView, leaveDetails]);

  return (
    <MainContainer>
      <BackButton
        showIcon
        style={{
          position: "relative",
          width: "fit-content",
          margin: "10px 30px 40px",
        }}
      />
      <LeaveDetailsContainer>
        <DetailsLeftContainer>
          <LeaveRecordSubContainer>
            <ProfilePic
              img={leaveDetails?.collaborator?.avatarUrl?.url}
              userName={leaveDetails?.collaborator?.name}
              style={{ width: "30px", height: "30px" }}
            />
            <TextContent>
              <BoldText>
                {isCollaboratorLeaveView
                  ? "You"
                  : leaveDetails?.collaborator?.name}
              </BoldText>
              <SubText>
                {" "}
                {isCollaboratorLeaveView ? "have" : "has"} applied leave for{" "}
              </SubText>
              <BoldText>{fromDate}</BoldText>
              {leaveDetails?.fromSlot == "half_day" && (
                <SubText>
                  {" "}
                  (<span style={{ color: "#f00" }}>half day</span>){" "}
                </SubText>
              )}
              {leaveDetails?.toDate !== "NA" && (
                <>
                  <SubText> to </SubText>
                  <BoldText>{toDate} </BoldText>
                  {leaveDetails?.toSlot == "half_day" && (
                    <SubText>
                      (<span style={{ color: "#f00" }}>half day</span>)
                    </SubText>
                  )}
                </>
              )}
            </TextContent>
          </LeaveRecordSubContainer>
          <h3>Reason</h3>
          <TextContainer>{leaveDetails?.reason}</TextContainer>
          <CollaboratorContainer>
            {leaveDetails?.leaveRequisitionEditors?.length > 0 && (
              <CollaboratorSubContainer>
                <h4>Applied To: </h4>
                <MultipleUsers data={leaveDetails?.leaveRequisitionEditors} />
              </CollaboratorSubContainer>
            )}
            {leaveDetails?.leaveRequisitionViewers?.length > 0 && (
              <CollaboratorSubContainer>
                <h4>Notified To: </h4>
                <MultipleUsers data={leaveDetails?.leaveRequisitionViewers} />
              </CollaboratorSubContainer>
            )}
          </CollaboratorContainer>
          {leaveDetails?.leaveDocumentUrls?.length > 0 && (
            <>
              <h3>Attachment ({leaveDetails?.leaveDocumentCount})</h3>
              <DocumentView data={leaveDetails?.leaveDocumentUrls} />
            </>
          )}
          <TabContainer initialData={{ activeTabId: TabsMap.comments.value }}>
            <TabHeader tabs={TabsList} />
            <Divider />
            <TabContent id={TabsMap.comments.value}>
              <CreateComment
                onComment={onComment}
                isCreatingComment={isCreating}
              />
              <CommentView
                commentableId={leaveId}
                commentableType="LeaveRequisition"
              />
            </TabContent>
            <TabContent id={TabsMap.activity.value}>
              <Activity data={leaveDetails?.statusActivities} />
            </TabContent>
          </TabContainer>
        </DetailsLeftContainer>
        <DetailsRightContainer>
          <StyledHeading $leaveTypeColor={leaveDetails?.leaveTypeColor}>
            {leaveDetails?.leaveType}
          </StyledHeading>
          <StyledHeading>Leave Count: {leaveDetails?.leaveCount}</StyledHeading>
          <StyledDivider />
          <StatusContainer $type={leaveDetails?.status}>
            {leaveDetails?.status}
            <Icon name="fas fa-check-circle" />
          </StatusContainer>
          {!!StatusUpdateOptions &&
            StatusUpdateOptions.map((status) => (
              <StatusUpdateContainer
                $type={status?.value}
                onClick={() => updateLeaveStatus(status)}
              >
                {status?.name}
                {isLoading && updatedStatus == status?.value && (
                  <ActivityIndicator color="#49505780" size="s" />
                )}
              </StatusUpdateContainer>
            ))}
        </DetailsRightContainer>
      </LeaveDetailsContainer>
    </MainContainer>
  );
};

export default LeaveDetailsView;
