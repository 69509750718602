import styled, { css, keyframes } from "styled-components";

const warm = keyframes`
 0%{
   opacity:1;
 }
 50%{
   opacity:0.5;
 }
 100%{
   opacity:1;
 }
`;

const animation = css`
  ${warm} ease-in 1s forwards infinite;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Loader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  z-index: -1;
  animation: ${({ $loading }) => ($loading ? animation : "none")};
  visibility: ${({ $loading }) => ($loading ? "visible" : "hidden")};
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: ${({ $loading }) => ($loading ? "0" : "1")};
`;
