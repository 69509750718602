import React from "react";

import { PostQuizCreateModal as PostModal } from "components/Posts";

import useCreatePost from "../../../../hooks/Posts/useCreatePost";

function PostQuizCreateModal({ onClose }) {
  const { isLoading, createQuiz } = useCreatePost();

  const onCreateQuiz = (values) => {
    createQuiz(values, (success) => success && onClose());
  };

  return (
    <PostModal
      onClose={onClose}
      onCreateQuiz={onCreateQuiz}
      isLoading={isLoading}
    />
  );
}

export default PostQuizCreateModal;
