import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Grid from "components/Grid";
import { createTableDataSelector } from "common/Selectors/MTable";

import { TableContainer } from "globalStyles";
import RenderItem from "./RenderItem";
import { calculateMaxLength, copyToClipBoard } from "../../../../Helper";
import useAlert from "hooks/useAlert";

const TableData = ({
  data,
  onCellClick,
  onEmptyData,
  cellNameToColumnMap,
  ...other
}) => {
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);

  const { showAlert } = useAlert();

  const copyDataToClipboard = (data) => {
    copyToClipBoard(JSON.stringify(data)).then(() => {
      showAlert({ message: "Copied to clipboard", type: "success" });
    });
  };

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (rows === 1) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);

  const maxStatusLength = useMemo(() => {
    return calculateMaxLength(cellNameToColumnMap, data);
  }, [data]);

  return (
    <TableContainer {...other}>
      <Grid
        onClick={onCellClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        data={{
          rowData: data,
          cellNameToColumnMap,
          maxStatusLength: maxStatusLength,
          copyDataToClipboard,
        }}
        dataKey="rowData"
      >
        {RenderItem}
      </Grid>
    </TableContainer>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "bankAccounts",
    blackList: { "Sl No": true },
  });
  const maker = (store, props) => {
    const { rows, cellNameToColumnMap } = getUserRows(store, props);
    return {
      data: rows,
      cellNameToColumnMap,
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
