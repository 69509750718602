import React, { useMemo } from "react";
import { TablePaginator } from "./Paginator";

import {
  TableHeaderContainer,
  TableTabsContainer,
  TableTabsContent,
} from "./style";

export const TableHeader = ({
  children,
  pagniatorProps = {},
  showPagninator = true,
  ...rest
}) => {
  return (
    <TableHeaderContainer {...rest}>
      {children}
      {showPagninator && (
        <TablePaginator style={{ marginLeft: "auto" }} {...pagniatorProps} />
      )}
    </TableHeaderContainer>
  );
};

export const TableTabs = ({ tabs = [], activeId, onTabClicked }) => {
  const list = useMemo(() => {
    return tabs.map((tab, idx) => {
      return (
        <TableTabsContent
          onClick={() => onTabClicked && onTabClicked(tab.id)}
          $active={activeId === tab.id}
          key={`table_tabs_${tab.id}_${idx}`}
        >
          {tab.data}
        </TableTabsContent>
      );
    });
  }, [tabs, activeId, onTabClicked]);
  return <TableTabsContainer>{list}</TableTabsContainer>;
};
