import { useState, useCallback } from "react";
import Modal from "components/Modal";

import {
  CardsContainer,
  DocumentContainer,
  BigImage,
} from "../../style/details";
import DocumentCard from "./DocumentCard";

const DocumentView = ({ data }) => {
  const [isModalActive, setModalActive] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState("");

  const toggleModal = useCallback((url = null) => {
    setModalActive((prevValue) => {
      if (typeof url === "string" && !prevValue) {
        setCurrentImageUrl(url);
      }
      return !prevValue;
    });
  }, []);

  return (
    <DocumentContainer>
      <CardsContainer>
        {data?.map((item) => {
          return <DocumentCard data={item} toggleModal={toggleModal} />;
        })}
      </CardsContainer>
      <Modal active={isModalActive} closeModal={toggleModal}>
        <BigImage src={currentImageUrl} />
      </Modal>
    </DocumentContainer>
  );
};

export default DocumentView;
