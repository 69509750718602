import { useMemo } from "react";

import useWindowDimensions from "./useWindowDimensions";
const useAspectRatio = ({ aspectRatio = 1.33 }) => {
  const { dimesnions } = useWindowDimensions();

  const { aspectWidth, aspectHeight } = useMemo(() => {
    const { width, height } = dimesnions;
    const newWidth = Math.max(400, width * 0.5);
    const newHeight = Math.min(newWidth / aspectRatio, height);
    return {
      aspectWidth: newWidth,
      aspectHeight: newHeight,
    };
  }, [dimesnions, aspectRatio]);

  return { aspectWidth, aspectHeight };
};
export default useAspectRatio;
