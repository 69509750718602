export const ApproveStatusOption = [{ name: "Approve", value: "approved" }];

export const RejectStatusOption = [{ name: "Reject", value: "rejected" }];

export const PendingStatusOption = [
  ...ApproveStatusOption,
  ...RejectStatusOption,
];

export const CancelStatusOption = [{ name: "Cancel", value: "cancelled" }];
