import React, { useMemo, useCallback } from "react";
import { createMonthMatrix } from "helper";

import { DataContainer, RowContainer, Cell } from "./style";

const MonthRow = ({ data = [], row, onMonthChange, currentMonth, isFromDate, dateRange, year }) => {
  const onChange = useCallback(
    (e) => {
      const { month } = e.target.dataset;
      onMonthChange(month);
    },
    [onMonthChange]
  );

  const fromDateDay = useMemo(() => dateRange.fromDate.day, [dateRange.fromDate]);
  const fromDateMonth = useMemo(() => dateRange.fromDate.month, [dateRange.fromDate]);
  const fromDateYear = useMemo(() => dateRange.fromDate.year, [dateRange.fromDate]);

  const toDateDay = useMemo(() => dateRange.toDate.day, [dateRange.toDate]);
  const toDateMonth = useMemo(() => dateRange.toDate.month, [dateRange.toDate]);
  const toDateYear = useMemo(() => dateRange.toDate.year, [dateRange.toDate]);

  const isButtonDisabled = useCallback((month) => {
    if (!fromDateDay && !toDateDay)
      return false;

    if (isFromDate) {
      if (month.value + 1 > toDateMonth + 1 && year >= toDateYear) {
        return true;
      }
      return false;
    }

    if (month.value + 1 < fromDateMonth + 1 && year <= fromDateYear) {
      return true;
    }
    return false;
  }, [data, year, fromDateDay, toDateDay, isFromDate, fromDateYear, toDateYear, fromDateMonth, toDateMonth])

  return (
    <RowContainer>
      {data.map((month, index) => {
        return (
          <Cell
            disabled={isButtonDisabled(month)}
            data-month={month.value || ""}
            onClick={month ? onChange : null}
            role="button"
            month={month}
            isActive={month && month.value == currentMonth}
            cellType="month"
            key={`monthCell_${row}_${index}`}
          >
            {month.name || ""}
          </Cell>
        );
      })}
    </RowContainer>
  );
};

const Months = React.forwardRef(
  ({ onMonthChange, currentMonth, visibility, dateRange, isFromDate, year }, ref) => {
    const { months } = useMemo(() => {
      return { months: createMonthMatrix({ rows: 5, columns: 4 }) };
    }, []);
    return (
      <DataContainer $visibility={visibility} ref={ref}>
        {months.map((monthRow, index) => {
          return (
            <MonthRow
              key={`monthRow_${index}`}
              onMonthChange={onMonthChange}
              data={monthRow}
              row={index}
              currentMonth={currentMonth}
              isFromDate={isFromDate}
              dateRange={dateRange}
              year={year}
            />
          );
        })}
      </DataContainer>
    );
  }
);

export default Months;
