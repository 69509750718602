export const MockData = [
  {
    id: "1",
    userName: "Sourabh Singh",
    message: "Abhijit Das create a new notice for all",
    time: "1 days ago",
    image: null,
    imageDesc: null,
    buttons: null,
  },

  {
    id: "2",
    userName: "Warish Ansari",
    message: "Abhijit Das create a new notice for all",
    time: "1 days ago",
    image: null, // "a",
    imageDesc: "asd",
    buttons: null,
  },

  {
    id: "3",
    userName: "Palak Gandhi",
    message: "Abhijit Das create a new notice for all",
    time: "1 days ago",
    image: null,
    imageDesc: null,
    buttons: null,
    // buttons: [
    //   {
    //     id: "decline",
    //     text: "Decline",
    //     otherProps: {
    //       outline: true,
    //     },
    //   },

    //   {
    //     id: "accept",
    //     text: "Accept",
    //     otherProps: {
    //       primary: true,
    //     },
    //   },
    // ],
  },
  {
    id: "4",
    userName: "Harshita Narnoli",
    message: "Abhijit Das create a new notice for all",
    time: "1 days ago",
    image: null,
    imageDesc: null,
    buttons: null,
  },
];
