import React, { PureComponent, useMemo, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import {
  PieChartContainer,
  LegendContainer,
  Square,
  LegendItemContainer,
  LegendName,
  SquareOuter,
  TextContainer,
  Heading,
} from "./style.js";

export const LegendCell = ({ name, color, percent }) => {
  return (
    <LegendItemContainer>
      <SquareOuter $color={color}>
        <Square $color={color} />
      </SquareOuter>
      <TextContainer>
        <LegendName $color={color}>{name}</LegendName>
        {percent && <p>{`${(percent * 100).toFixed(2)}%`}</p>}
      </TextContainer>
    </LegendItemContainer>
  );
};
export const LegendComponent = ({ data, totalValue }) => {
  return (
    <LegendContainer>
      {data.map((item) => (
        <LegendCell
          color={item.colorCode}
          name={item.name}
          percent={item.value / totalValue}
        />
      ))}
    </LegendContainer>
  );
};

const PiechartComponent = ({
  data = [],
  isLegendVisible = false,
  children,
  heading,
  ...otherProps
}) => {
  const [activeIndex, setActiveIndex] = useState();

  const totalValue = useMemo(() => {
    const values = data.map((item) => item.value);
    const sum = values.reduce((partialSum, a) => partialSum + a, 0);
    return sum;
  }, [data]);

  return (
    <PieChartContainer>
      {heading && <Heading>{heading}</Heading>}
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={100}
            outerRadius={130}
            fill="#8884d8"
            activeShape={children}
            activeIndex={activeIndex}
            onMouseEnter={(e, index) => setActiveIndex(index)}
            paddingAngle={4}
            dataKey="value"
            {...otherProps}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${entry.id}`} fill={entry.colorCode} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {isLegendVisible && (
        <LegendComponent data={data} totalValue={totalValue} />
      )}
    </PieChartContainer>
  );
};

export default PiechartComponent;
