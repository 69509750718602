export const FormatNumbers = (value) => {
  let formattedNumber = value.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  return formattedNumber;
};

export const statusOptions = [
  { name: "Active", value: "active" },
  { name: "Inactive", value: "inactive" },
];
