import { useEffect, useMemo } from "react";
import { getOncefetched } from "common/Selectors/store";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { createTableDataSelector } from "common/Selectors/MTable";

import usePaginator from "hooks/usePaginator";
import usePagination from "hooks/usePagination";

import { fetchTodaysBirthday } from "../../redux/Birthday/actionCreator";
import { useSelector } from "react-redux";
import { transformCollaboratorMatrixToArray } from "../../utils";

const onceFetchedSelector = getOncefetched("birthdays.today");
const todaysBirthdaySelector = createTableDataSelector({
  tableName: "birthdays.today",
  blackList: { "Sl No": true },
  fetchAllData: true,
});

export function useFetchTodayBirthdays() {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    allPagesFetched,
    errorOccured,
  } = usePagination({
    actionCreator: fetchTodaysBirthday,
    tableName: "birthdays.today",
  });

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const isOnceFetched = useSelector(onceFetchedSelector);
  const { rows: data, rowIdMap } = useSelector(todaysBirthdaySelector);

  const { fetchFirstPage, fetchNextPage } = usePaginator({
    paginatorApi: getNextPage,
    extraParams: { companyId, isAdmin },
  });

  useEffect(() => {
    if (!isOnceFetched) {
      fetchFirstPage();
    }
  }, []);

  const allTodayBirthdays = useMemo(() => {
    const allData = transformCollaboratorMatrixToArray(data, rowIdMap);
    return allData.reverse();
  }, [data]);

  return {
    isLoading: loadingNextPage || loadingPrevPage,
    allTodayBirthdays,
    fetchNextPage,
    allDataFethced: allPagesFetched,
    hasError: errorOccured,
  };
}
