import React, { useMemo } from "react";
import Icon from "../Icon";

import { Container, IconItem } from "./style";

function GenericSettings({ iconList }) {
  const iconItems = useMemo(() => {
    return iconList.map(({ name, onClick, ...other }, idx) => {
      return (
        <IconItem key={`${name}_${idx}`} onClick={onClick} {...other}>
          <Icon name={name} />
        </IconItem>
      );
    });
  }, [iconList]);
  return <Container>{iconItems}</Container>;
}

export default GenericSettings;
