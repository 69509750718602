import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import useAlert from "hooks/useAlert";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";
import { createHolidayYear } from "../../actionCreator";

import { StyledFormGroup } from "../../../../GlobalStyles";
import { FormHeading } from "../../styles/create";

import { isDateCombinationValid } from "../../../../Helper";
import { generateFormData } from "../../utils";

import InputGroup from "components/InputGroup";
import Button from "components/Button";
import { ongoingYearOptions } from "./options";

const Form = ({ companyId, isAdmin, createHolidayYear }) => {
  const [isLoading, setLoading] = useState("");
  const { showAlert } = useAlert();
  const [startDate, setStartDate] = useState("");
  const [InvalidMessage, setInvalidMessage] = useState("");

  const onStartDateChange = useCallback((e) => {
    setStartDate(e.target.value);
  }, []);

  const endDateValidator = useCallback(
    (endDate) => {
      const { isValid, message } = isDateCombinationValid(
        startDate,
        endDate,
        "Start",
        "End"
      );
      setInvalidMessage(message);
      return isValid;
    },
    [startDate]
  );

  const onFormSubmit = useCallback(async (values, resetForm) => {
    try {
      setLoading(true);
      const data = generateFormData(values);
      await createHolidayYear({ companyId, isAdmin, formData: data });
      showAlert({ message: "Holiday year created" });
      resetForm();
      setStartDate("");
    } catch (err) {
      showAlert({ message: "Holiday Year creation failed", type: "error" });
    } finally {
      setLoading(false);
    }
  });

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <FormHeading>Create new one</FormHeading>

      <InputGroup
        id="holidayYearCreate_name"
        label="Name"
        placeholder="Name of Year"
        required
      />
      <InputGroup
        id="holidayYearCreate_startDate"
        fieldType="datePicker"
        required
        placeholder="Select Start Date"
        label="Start Date"
        onChange={onStartDateChange}
      />
      <InputGroup
        id="holidayYearCreate_endDate"
        fieldType="datePicker"
        required
        placeholder="Select End Date"
        label="End Date"
        validator={endDateValidator}
        invalidMessage={InvalidMessage}
      />
      <InputGroup
        id="holidayYearCreate_ongoingYear"
        fieldType="checkboxGroup"
        secondaryLabel
        options={ongoingYearOptions}
        groupName="holidayYearCreate_ongoingYear"
      />

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create
      </Button>
    </StyledFormGroup>
  );
};
const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    isAdmin,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createHolidayYear,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Form);
