import React from "react";
import Icon from "components/Icon";

import Desc_Img from "assets/common/desc.svg";
import Location_Img from "assets/common/location.svg";
import Link_Img from "assets/common/link.svg";

import {
  Container,
  Header,
  CloseButton,
  EventContentArea,
  Heading,
  EventItem,
  ItemContent,
  ItemIcon,
} from "../../styles/event";
import { convertToTextDateString } from "../../../../Helper";

function Event({ icon, children }) {
  return (
    <EventItem>
      <ItemIcon>
        <img src={icon} />
      </ItemIcon>
      <ItemContent>{children}</ItemContent>
    </EventItem>
  );
}

function FormView({ className, style, onClose, eventData, ...otherProps }) {
  const { title, meetingLink, description, venue, endAt, startAt } = eventData;
  return (
    <Container {...otherProps} style={style} className={className}>
      <Header>
        <CloseButton onClick={onClose} size="auto">
          <Icon name="fas fa-times" />
        </CloseButton>
      </Header>
      <EventContentArea>
        <Heading>{title}</Heading>
        <EventItem>
          <span>{convertToTextDateString(startAt)}</span>
          <span style={{ margin: "0 10px" }}>-</span>
          <span>{convertToTextDateString(endAt)}</span>
        </EventItem>

        {!!meetingLink && (
          <Event icon={Link_Img}>
            <a href={meetingLink} target="_blank" rel="noopener noreferrer">
              {meetingLink}
            </a>
          </Event>
        )}
        {!!venue && <Event icon={Location_Img}>{venue}</Event>}
        {!!description && <Event icon={Desc_Img}>{description}</Event>}
      </EventContentArea>
    </Container>
  );
}

export default FormView;
