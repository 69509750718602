import React, { useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";

import Button from "components/Button";
import { RectangleSkeleton } from "components/Skeleton";

import ReimbursementCreateView from "../Create/index";
import ActionArea from "./ActionArea";
import TableData from "./TableData";

import EmptyScreen from "assets/employee/ReimbursementBlank.png";

import { isCurrentUserIsExecutive } from "common/Selectors/auth";

import { ViewContainer } from "globalStyles";
import { CreateButton } from "../../style/create";
import {
  EmptyScreenContainer,
  EmptyScreenImage,
  EmptyScreenMessage,
} from "../../style/display";

const ReimbursementDisplayView = ({ isAdmin }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(true);
  }, []);

  const showCreateButton = useMemo(() => {
    return !isAdmin && !isLoading && !isEmpty;
  }, [isAdmin, isLoading, isEmpty]);

  if (isFormVisible) {
    return <ReimbursementCreateView setIsFormVisible={setIsFormVisible} />;
  }

  return (
    <ViewContainer>
      <ActionArea
        onLoading={onDbDataChange}
        showCreateButton={showCreateButton}
        updateFormVisibilty={updateFormVisibilty}
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
      />
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit",
        }}
        onEmptyData={onEmptyData}
      />
      {!isLoading && isEmpty && (
        <EmptyScreenContainer>
          <EmptyScreenImage src={EmptyScreen} alt="No records found" />
          <EmptyScreenMessage>
            {isAdmin
              ? "There are no reimbursements"
              : "You have not applied for any reimbursement"}
          </EmptyScreenMessage>
          {!isAdmin && (
            <Button primary size="lg" onClick={updateFormVisibilty}>
              Create New
            </Button>
          )}
        </EmptyScreenContainer>
      )}
      {isLoading && <RectangleSkeleton times={4} />}
    </ViewContainer>
  );
};

const mapStateToProps = (store, props) => {
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { isAdmin };
};

export default connect(mapStateToProps, null)(ReimbursementDisplayView);
