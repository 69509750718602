import { useMemo } from "react";
import Icon from "components/Icon";
import ProfilePic from "components/ProfilePic";
import {
  changeToDateFormat,
  convertToTextDateStringMMDDYYYY
} from "helper/date";
import {
  AttachmentContainer,
  BoldText,
  LeaveRecord,
  LeaveRecordSubContainer,
  SubText,
  Tag,
  TextContent
} from "../style";

const LeaveView = ({ leave, onClick, activeTab, isCollaboratorLeaveView }) => {
  const [fromDate, toDate, createdAt] = useMemo(() => {
    const fromDate = convertToTextDateStringMMDDYYYY(leave?.fromDate) || "";
    const toDate = convertToTextDateStringMMDDYYYY(leave?.toDate) || "";
    const createdAt = changeToDateFormat(leave?.createdAt) || "";
    return [fromDate, toDate, createdAt];
  });

  return (
    <LeaveRecord onClick={() => onClick(leave?.id)}>
      <LeaveRecordSubContainer>
        <ProfilePic
          img={leave?.collaborator?.avatarUrl?.url}
          userName={leave?.collaborator?.name}
          style={{ width: "30px", height: "30px" }}
        />
        <TextContent>
          <BoldText>
            {isCollaboratorLeaveView ? "You" : leave?.collaborator?.name}
          </BoldText>
          <SubText>
            {" "}
            {isCollaboratorLeaveView ? "have" : "has"} applied leave for{" "}
          </SubText>
          <BoldText>{fromDate}</BoldText>
          {leave?.fromSlot == "half_day" && (
            <SubText>
              {" "}
              (<span style={{ color: "#f00" }}>half day</span>){" "}
            </SubText>
          )}
          {leave?.toDate !== "NA" && (
            <>
              <SubText> to </SubText>
              <BoldText>{toDate} </BoldText>
              {leave?.toSlot == "half_day" && (
                <SubText>
                  (<span style={{ color: "#f00" }}>half day</span>)
                </SubText>
              )}
            </>
          )}
        </TextContent>
      </LeaveRecordSubContainer>
      <LeaveRecordSubContainer>
        {(isCollaboratorLeaveView || activeTab == "notified") && (
          <Tag $type={leave?.status}>{leave?.status}</Tag>
        )}
        <Tag $leaveTypeColor={leave?.leaveTypeColor}>{leave?.leaveType}</Tag>
        <SubText>{createdAt}</SubText>
        <AttachmentContainer>
          {leave?.leaveDocumentUrls?.length > 0 && (
            <Icon name="fas fa-paperclip" style={{ color: "#707070" }} />
          )}
        </AttachmentContainer>
      </LeaveRecordSubContainer>
    </LeaveRecord>
  );
};

export default LeaveView;
