import React, { useMemo } from "react";
import { connect } from "react-redux";

import { createTableDataSelector } from "../../../../../Common/Selectors/MTable";

import AttendanceListBox from "../../../../../Common/Components/AttendanceListBox";
import { getAllPresentees, resetPresentees } from "../../../actionCreator";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { useAttendanceListPagination } from "../../../Hooks/useAttendanceList";
import { tranformToAttendanceList } from "../../../utils";
import { bindActionCreators } from "redux";
import { MockData } from "../../../constants";

const PresenteesData = ({
  data,
  onceFetched,
  companyId,
  isAdmin,
  cellNameToColumnMap,
  resetPresentees,
  count,
}) => {
  const {
    initialLoading: initialPresentLoading,
    loading: presentLoading,
    errorOccured: presentError,
    onEndReached: presentEndReached,
  } = useAttendanceListPagination({
    onceFetched,
    companyId,
    isAdmin,
    action: getAllPresentees,
    tableName: "attendance.presentees",
  });

  const list = useMemo(() => {
    return tranformToAttendanceList(data, cellNameToColumnMap);
  }, [data, cellNameToColumnMap]);

  return (
    <AttendanceListBox
      headingText="Present"
      onEndReached={presentEndReached}
      initialLoading={initialPresentLoading}
      loading={presentLoading}
      errorOccured={presentError}
      data={!onceFetched ? MockData : list}
      refreshData={resetPresentees}
      listCount={count}
    />
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "attendance.presentees",
    fetchAllData: true,
    reverse: false,
  });

  return (store, props) => {
    const companyId = getCurrentUserCompany(store, props);
    const isAdmin = isCurrentUserIsExecutive(store, props);
    const { rows, cellNameToColumnMap } = getUserRows(store, props);

    return {
      onceFetched: store.attendance.presentees.onceFetched,
      companyId,
      isAdmin,
      data: rows,
      cellNameToColumnMap,
      count: store.attendance.presentees?.count,
    };
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPresentees,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PresenteesData);
