import styled, { keyframes, css } from "styled-components";

export const tooltipContentCss = css`
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  font-size: 11px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 100000;
`;

const expand = keyframes`
 0% {
  transform: scale(0.5);
 }
 100%{
  transform: scale(1);
 }
`;

export const Content = styled.div`
  position: fixed;
  //animation: ${expand} ease-out 0.2s forwards;
  ${({ $applyDefaultStyle }) => {
    if ($applyDefaultStyle) {
      return tooltipContentCss;
    }
  }}
`;
