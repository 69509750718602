import { getCurrentDataAttributes } from "helper/selectors";
import { createSelector } from "reselect";

const userProfileData = (store) => store.userProfile.data;
const userProfileRelation = (store) => store.userProfile.userProfileRelation;

export const userProfileDataSelector = createSelector(
  userProfileData,
  userProfileRelation,
  (_, props) => props.id,
  (data, relation, id) => {
    return data[id] ? getCurrentDataAttributes(data[id], relation, data) : {};
  },
);
