import React, { useCallback, useState } from "react";
import { useLocation } from "react-router";
import ActionArea from "./ActionArea";
import TableData from "./TableData";

import InfoView from "components/InfoView";
import NoLeaves from "assets/employee/noLeaves.png";
import { RectangleSkeleton } from "components/Skeleton";
import { ViewContainer } from "globalStyles/view";

const AssignedLeavesView = () => {
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);

  const location = useLocation();
  const activeTab = location.pathname.split("/").pop();

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  return (
    <>
      <ActionArea
        onLoading={onDbDataChange}
        style={{
          display: isLoading || isEmpty ? "none" : "inherit"
        }}
        activeTab={activeTab}
      />
      <TableData
        style={{
          display: isLoading || isEmpty ? "none" : "inherit"
        }}
        onEmptyData={onEmptyData}
        activeTab={activeTab}
      />
      {!isLoading && isEmpty && (
        <InfoView
          image={NoLeaves}
          heading={`No ${activeTab.replace("_", " ")} leave to show here`}
        />
      )}
      {isLoading && (
        <ViewContainer>
          <RectangleSkeleton times={4} />
        </ViewContainer>
      )}
    </>
  );
};

export default AssignedLeavesView;
