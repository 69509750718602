import React, { useCallback, useMemo } from "react";
import Task from "../Task";

import { getTasksOfThisDate, getTaskPositionInWeek } from "../utils";
import {
  WeekColumn,
  DayBox,
  DayDate,
  DayName,
  TaskListContainer,
  NewTask,
} from "./style";

const DayItem = ({ day, date, isTodayDate, style }) => {
  return (
    <DayBox style={style}>
      <DayName>{day}</DayName>
      <DayDate isTodayDate={isTodayDate}>{date}</DayDate>
    </DayBox>
  );
};

const WeekDay = ({
  id,
  tasks,
  day,
  date,
  isTodayDate,
  dateObj,
  cellHeight,
  totalTimeRows,
  style,
  className,
  headingHeight,
  onClick,
  newTaskPos,
  onTaskView,
}) => {
  const onColumnClicked = useCallback(
    (e) => {
      if (typeof onClick === "function") {
        onClick(e, id);
      }
    },
    [id]
  );

  const onTaskClick = (taskData, e) => {
    e.stopPropagation();
    typeof onTaskView === "function" && onTaskView(e.target, taskData);
  };

  const taskList = useMemo(() => {
    const todaysTask = getTasksOfThisDate(tasks, dateObj);
    return todaysTask.map((task) => {
      const startTime = new Date(task.startAt);
      const endTime = new Date(task.endAt);
      const style = getTaskPositionInWeek(
        startTime,
        endTime,
        cellHeight,
        dateObj
      );
      const clickFunc = onTaskClick.bind(this, task);

      return (
        <Task
          title={task.title}
          key={task.id}
          onClick={clickFunc}
          style={{
            ...style,
            height: style.height,
            backgroundColor: task.color,
          }}
        />
      );
    });
  }, [tasks, dateObj, cellHeight, totalTimeRows, onTaskClick]);
  return (
    <WeekColumn style={style} className={className}>
      <DayItem
        style={{ height: headingHeight }}
        key={`${day}_${date}`}
        day={day}
        date={date}
        isTodayDate={isTodayDate}
      />
      <TaskListContainer onClick={onColumnClicked}>
        {taskList}
      </TaskListContainer>
      {!!newTaskPos && (
        <NewTask id="calendar_new_task" style={newTaskPos}>
          <span>(No Title)</span>
        </NewTask>
      )}
    </WeekColumn>
  );
};

export default WeekDay;
