import {
  BigCupIcon,
  FoodIcon,
  TeaIcon,
  TeaTimeIcon,
  MeetingIcon,
} from "../AssetComponents";

export const getIcon = (key, color) => {
  switch (key) {
    case "short-break":
      return <TeaTimeIcon color={color} />;
    case "lunch-break":
      return <FoodIcon color={color} />;
    case "long-break":
      return <BigCupIcon color={color} />;
    case "meeting-break":
      return <MeetingIcon color={color} />;
    case "tea-break":
      return <TeaIcon color={color} />;
    default:
      return <TeaIcon color={color} />;
  }
};
