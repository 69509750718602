import React, { useCallback, useEffect, useRef, useState } from "react";

import ListGroup from "./ListGroup";

import useInput from "../useInput";
import useWindowDimensions from "hooks/useWindowDimensions";
import { createOuterClickListner } from "helper";

import { InputContainer, InputIcon, GlobalInput } from "../style";

const SearchSelect = ({
  size = "md",
  success = "",
  secondary = false,
  error = "",
  data = [],
  placeholder,
  value,
  onBlur,
  onChange,
  onSearch,
  searchValue,
  singleSelect = false,
  isLoading = false,
  emptySearchInput,
  fieldClass = "",
  name,
  maxSelectionCount = null,
  ...otherprops
}) => {
  const { icon, restInputProps } = useInput({
    ...otherprops,
    success,
    error,
  });
  success = success ? "true" : undefined;
  error = error ? "true" : undefined;

  const { dimesnions } = useWindowDimensions();
  const [isListOpen, updateListStatus] = useState(false);
  const [isInBottom, toggleBottomPosition] = useState(true);
  const removeClickListnerRef = useRef(null);
  const popupRef = useRef(null);
  const popupBtnRef = useRef(null);
  const tempDataRef = useRef(null);
  tempDataRef.current = value;

  const toggleList = useCallback((status) => {
    updateListStatus((val) => {
      if (typeof status === "boolean") {
        return status;
      }
      return !val;
    });
  }, []);

  const onClickOutside = useCallback(
    (e) => {
      if (
        e.target !== popupBtnRef.current &&
        !popupRef.current.contains(e.target)
      ) {
        toggleList(false);
        if (!singleSelect && typeof emptySearchInput === "function") {
          emptySearchInput();
        }
        if (typeof onBlur === "function") {
          onBlur({ target: { name, value: tempDataRef.current } });
        }
      }
    },
    [emptySearchInput, singleSelect, onBlur]
  );

  useEffect(() => {
    if (searchValue) {
      toggleList(true);
    } else {
      toggleList(false);
    }
  }, [searchValue]);

  /**
   * Updates the position of datePicker popup box according to the window dimensions
   */
  const updatePosition = useCallback(() => {
    if (!popupRef.current) {
      return;
    }
    const { height } = popupRef.current.getBoundingClientRect();
    const { top, height: inputHeight } =
      popupBtnRef.current.getBoundingClientRect();
    if (top + inputHeight + height + 5 > dimesnions.height) {
      toggleBottomPosition(false);
    } else {
      toggleBottomPosition(true);
    }
  }, []);

  const setPopupRef = useCallback((ref) => {
    if (!ref) {
      return;
    }
    popupRef.current = ref;
    updatePosition();
  }, []);

  useEffect(() => {
    updatePosition();
  }, [dimesnions, data]);

  // setting outer listner when list gets open
  useEffect(() => {
    if (!isListOpen && removeClickListnerRef.current) {
      removeClickListnerRef.current();
      return;
    }
    if (isListOpen) {
      removeClickListnerRef.current = createOuterClickListner(onClickOutside);
    }
  }, [isListOpen, onClickOutside]);

  const onListGroupClick = useCallback(
    (listItemId, listItem) => {
      if (typeof onChange === "function") {
        const maxCount = (!singleSelect && maxSelectionCount) || null;
        const currentListCount = value && Object.values(value).length;

        let newValue = value ? { ...value } : {};
        if (singleSelect) {
          newValue = {};
        }

        if (newValue[listItemId]) {
          delete newValue[listItemId];
        } else if (maxCount === null || currentListCount < maxCount) {
          newValue[listItemId] = listItem;
        }
        const selectedItems = Object.keys(newValue).length;
        onChange({
          target: { value: selectedItems === 0 ? null : newValue, name },
        });

        if (singleSelect) {
          onSearch(
            { target: { value: Object.values(newValue)[0].heading, name } },
            false
          );
        }
      }
    },
    [onChange, value, emptySearchInput, singleSelect, name, maxSelectionCount]
  );

  return (
    <InputContainer className={`ab_multi_select ${fieldClass}`} size={size}>
      <GlobalInput
        success={success}
        error={error}
        type="text"
        placeholder={`${placeholder} ${
          !singleSelect && maxSelectionCount
            ? ` (Maximum ${maxSelectionCount} allowed)`
            : ""
        }`}
        ref={popupBtnRef}
        onChange={onSearch}
        value={searchValue}
        {...restInputProps}
      />

      {isListOpen && (
        <ListGroup
          isInBottom={isInBottom}
          ref={setPopupRef}
          data={data}
          selectedData={value}
          onChange={onListGroupClick}
          isLoading={isLoading}
        />
      )}

      {!!icon && <InputIcon success={success} error={error} name={icon} />}
    </InputContainer>
  );
};

export default SearchSelect;
