import {
  FETCH_LEAVES_TODAY,
  FETCH_PRESENT_TODAY,
  FETCH_ABSENT_TODAY,
  RESET_ABSENTEES,
  RESET_PRESENTEES,
  RESET_LEAVES,
} from "./actionTypes";
import { createNewStoreOnGetData, createInitialStoreState } from "helper";
import { combineReducers } from "redux";

const todayInitialState = createInitialStoreState({}, true);

function todayLeaveReducer(store = todayInitialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_LEAVES_TODAY}_SUCCESS`: {
      const data = createNewStoreOnGetData(payload, store, true);
      const count = payload?.meta?.onLeaveCollaboratorsCount || 0;
      return {
        ...data,
        count,
      };
    }
    case RESET_LEAVES: {
      return {
        ...todayInitialState,
      };
    }

    default:
      return store;
  }
}

const nextInitialState = createInitialStoreState({ count: 0 }, true);
function todayPresentReducer(store = nextInitialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_PRESENT_TODAY}_SUCCESS`: {
      const data = createNewStoreOnGetData(payload, store, true);
      const count = payload?.meta?.presenteesCount || 0;
      return {
        ...data,
        count,
      };
    }
    case RESET_PRESENTEES: {
      return {
        ...nextInitialState,
      };
    }

    default:
      return store;
  }
}
const AbsenteeInitialState = createInitialStoreState({ count: 0 }, true);
function todayAbsentReducer(store = AbsenteeInitialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_ABSENT_TODAY}_SUCCESS`: {
      const data = createNewStoreOnGetData(payload, store, true);
      const count = payload?.meta?.absenteesCount || 0;
      return {
        ...data,
        count,
      };
    }
    case RESET_ABSENTEES: {
      return {
        ...AbsenteeInitialState,
      };
    }

    default:
      return store;
  }
}

const attendanceReducer = combineReducers({
  leave: todayLeaveReducer,
  present: todayPresentReducer,
  absent: todayAbsentReducer,
});

export default attendanceReducer;
