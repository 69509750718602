import React from "react";
import AdvancedRoute from "../../../Common/Components/AdvancedRoute";
import RoutePaths from "../../Path";
import AccessTypes from "../../AccessTypes";

import TeamsCreateView from "../../../Modules/Teams/Views/Create";
import TeamsDisplayView from "../../../Modules/Teams/Views/Display";

import { createRouteObject } from "../../../Helper";

const { teams } = RoutePaths.authorized;

const pages = createRouteObject(
  teams,
  AccessTypes.pages.getPageAccessType.bind(AccessTypes.pages),
  [TeamsCreateView, TeamsDisplayView]
);

const TeamsPages = () => {
  return pages.map((item) => {
    return (
      <AdvancedRoute
        key={item.path}
        exact
        path={item.path}
        accessType={item.accessTypes}
        component={item.component}
      />
    );
  });
};

export default TeamsPages;
