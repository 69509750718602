export const AdminColors = {
  activityIndicator: {
    primary: "#4ac501",
    secondary: "#007bff",
  },
  navbar: {
    background: "#4ac501",
  },
  content: {
    background: "#f0f3f6",
  },
  button: {
    primary: {
      background: "#4ac501",
      color: "white",
    },
    secondary: {
      background: "white",
    },
    outline: {
      background: "#f6fcf2",
      border: "#b7e899",
      color: "#4ac501",
    },
    shadowColor: "rgba(74, 197, 1, 0.15)",
  },
  text: {
    primary: "#c9dbe9",
  },
  alert: {
    success: "#4ac501",
    error: "#ff0000",
    warning: "#fdc300",
    info: "#006ff0",
    heading: "#535353",
    desc: "#a0a0a0",
    errorLite: "#ff000033",
    successLite: "#4ac50133",
    warningLite: "#fdc30033",
    infoLite: "#006ff033",
  },
  label: "#82869a",
  input: {
    primary: {
      borderColor: "#ebebeb",
      textColor: "#373c56",
    },
    secondary: {
      borderColor: "#e0e5e9",
      textColor: "#222a33",
    },
  },
  chip: {
    borderColor: "#4ac501",
    textColor: "white",
    background: "#4ac501",
  },
  radio: {
    borderColor: "#dee2e6",
    activeBorderColor: "#4ac501",
    overlay: "#4ac501",
  },
  checkBox: {
    checked: {
      background: "#4ac501",
      color: "white",
    },
    unChecked: {
      background: "#f3f3f3",
      border: "#d8d8d8",
    },
    borderColor: "#dee2e6",
    activeBorderColor: "#4ac501",
    overlay: "#4ac501",
  },
  filePicker: {
    attachment: "#495057",
    text: "#4ac501",
  },
  drawer: {
    title: "#4ac501",
    tab: {
      activeBackground: "rgba( 74, 197, 1,0.12)",
      text: "#000000",
      icon: "#4ac501",
    },
    link: {
      background: "rgba( 74, 197, 1,0.12)",
      text: "#000000",
      activeText: "#4ac501",
    },
  },
  collapse: {
    text: "#dee2e6",
    border: "#e0f3ff",
  },
  fallback: {
    text: "#495057",
  },
  popover: {
    item: {
      background: "#1a2c64",
      title: "#1a2c64",
      text: "#343a40",
    },
    heading: {
      background: "#17a2b8",
    },
  },
  grid: {
    border: "#b7e899",
    header: {
      color: "#4ac501",
    },
    cell: {
      activeBorder: "#4ac501",
      text: "#535a60",
      activeBackground: "#f7f7f7",
      objectText: "#17a2b8",
    },
  },
  paginator: {
    page: "#4ac501",
    color: "#4ac501",
    cta: "#4ac501",
  },
  legend: {
    border: "#6c757d",
  },
  modal: {
    background: "#dee2e6",
  },
  tab: {
    border: "#dee2e6",
    active: {
      text: "#4ac501",
      border: "#4ac501",
    },
  },
  notification: {
    countColor: "#FF3874",
  },
  lightPrimaryColor: "#F6FCF2",
  primaryColor: "#4ac501",
  secondaryColor: "#6c757d",
  successColor: "#28a745",
  errorColor: "#dc3545",
  lightBlueColor: "#f9fbfc",
  mediumBlueColor: "#e0f3ff",
  warningColor: "#ffc107",
  infoColor: "#17a2b8",
  lightGrayColor: "#f8f9fa",
  lightPurpleColor: "#f7f9fa",
  mediumPurpleColor: "#f0f3f6",
  mediumGrayColor: "#dee2e6",
  tableCellColor: "#f7f7f7",
  tableTextColor: "#535a60",
  darkGrayColor: "#495057",
  textColor: "#c9dbe9",
  lightBlackColor: "#343a40",
  darkBlueColor: "#1a2c64",
};

/**
 *  ====================== employee colours =============
 */

export const EmployeeColors = {
  activityIndicator: {
    primary: "#01008A",
    secondary: "#007bff",
  },
  navbar: {
    background: "#01008A",
  },
  content: {
    background: "#f0f3f6",
  },
  button: {
    primary: {
      background: "#01008A",
      color: "white",
    },
    secondary: {
      background: "white",
    },
    outline: {
      background: "#E8E8FF",
      border: "#7676DC",
      color: "#01008A",
    },
    shadowColor: "#dfdfff",
  },
  text: {
    primary: "#c9dbe9",
  },
  alert: {
    success: "#4ac501",
    error: "#ff0000",
    warning: "#fdc300",
    info: "#006ff0",
    heading: "#535353",
    desc: "#a0a0a0",
    errorLite: "#ff000033",
    successLite: "#4ac50133",
    warningLite: "#fdc30033",
    infoLite: "#006ff033",
  },
  label: "#82869a",
  input: {
    primary: {
      borderColor: "#ebebeb",
      textColor: "#373c56",
    },
    secondary: {
      borderColor: "#e0e5e9",
      textColor: "#222a33",
    },
  },
  chip: {
    borderColor: "#01008A",
    textColor: "white",
    background: "#01008A",
  },
  radio: {
    borderColor: "#dee2e6",
    activeBorderColor: "#01008A",
    overlay: "#bdbdff",
  },
  checkBox: {
    checked: {
      background: "#01008A",
      color: "white",
    },
    unChecked: {
      background: "#f3f3f3",
      border: "#d8d8d8",
    },
    borderColor: "#dee2e6",
    activeBorderColor: "#01008A",
    overlay: "#bdbdff",
  },
  filePicker: {
    attachment: "#495057",
    text: "#01008A",
  },
  drawer: {
    title: "#01008A",
    tab: {
      activeBackground: "#E8E8FF",
      text: "#01008A",
      icon: "#01008A",
    },
    link: {
      background: "#E8E8FF",
      text: "#000000",
      activeText: "#01008A",
    },
  },
  collapse: {
    text: "#dee2e6",
    border: "#e0f3ff",
  },
  fallback: {
    text: "#495057",
  },
  popover: {
    item: {
      background: "#1a2c64",
      title: "#1a2c64",
      text: "#343a40",
    },
    heading: {
      background: "#17a2b8",
    },
  },
  grid: {
    border: "#EEEEFF",
    header: {
      color: "#01008A",
    },
    cell: {
      activeBorder: "#01008A",
      text: "#535a60",
      activeBackground: "#f7f7f7",
      objectText: "#17a2b8",
    },
  },
  paginator: {
    page: "#01008A",
    color: "#01008A",
    cta: "#01008A",
  },
  legend: {
    border: "#6c757d",
  },
  modal: {
    background: "#dee2e6",
  },
  tab: {
    border: "#dee2e6",
    active: {
      text: "#01008A",
      border: "#01008A",
    },
  },
  notification: {
    countColor: "#FF3874",
  },
  lightPrimaryColor: "#dfdfff",
  primaryColor: "#01008A",
  secondaryColor: "#6c757d",
  successColor: "#28a745",
  errorColor: "#dc3545",
  lightBlueColor: "#f9fbfc",
  mediumBlueColor: "#e0f3ff",
  warningColor: "#ffc107",
  infoColor: "#17a2b8",
  lightGrayColor: "#f8f9fa",
  lightPurpleColor: "#f7f9fa",
  mediumPurpleColor: "#f0f3f6",
  mediumGrayColor: "#dee2e6",
  tableCellColor: "#f7f7f7",
  tableTextColor: "#535a60",
  darkGrayColor: "#495057",
  textColor: "#c9dbe9",
  lightBlackColor: "#343a40",
  darkBlueColor: "#1a2c64",
};
