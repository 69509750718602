import React, { useEffect, useMemo, useState } from "react";

import PostHeader from "./PostHeader";
import InputGroup from "components/InputGroup/inputGroup2";
import Radio from "components/Input/RadioGroup/Radio";
import Error from "components/Error";

import useForm from "hooks/useForm";
import { generateRandomId } from "helper";

import { MAX_MORE_OPTIONS, PollOptions, postTypes } from "../constants";

import {
  PollFooter,
  PollFormArea,
  Section,
  PostButton,
  AddMoreOption,
  PostCreateContainer,
  OptionInputWrapper,
  RemoveButton,
  PostBackButton,
  StyledLabelArea,
  StyledPollInputGroup,
} from "./style";

function PostQuizCreate({
  onClose,
  onCreateQuiz,
  onPostTypeChange, // indicates whether back button should be present or not
  isLoading,
  ...rest
}) {
  const [moreOptions, updateMoreOptions] = useState([]);
  const [moreOptionValues, updateMoreOptionValues] = useState({});

  const { formData, values, isFormValid, errors } = useForm({
    fields: {
      question: {
        required: true,
      },
      option1: {
        required: true,
      },
      option2: {
        required: true,
      },
      answere: {
        required: true,
      },
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }
    const extraOptions = moreOptions.map((id) => moreOptionValues[id]);
    const options = [values.option1, values.option2, ...extraOptions];
    onCreateQuiz &&
      onCreateQuiz({
        question: values.question,
        options,
        answereIndex: Number(values.answere) - 1,
      });
  };

  /**
   * Effect to create a new place for options values based upon
   * last id entered into moreOptions
   */
  useEffect(() => {
    if (moreOptions.length === 0) {
      return;
    }

    const lastId = moreOptions[moreOptions.length - 1];
    updateMoreOptionValues((prevVal) => {
      if (prevVal[lastId]) {
        return prevVal;
      }
      return { ...prevVal, [lastId]: "" };
    });
  }, [moreOptions]);

  const onAddMoreOption = () => {
    let id = "";
    updateMoreOptions((prevVal) => {
      id = generateRandomId();
      while (prevVal[id]) {
        id = generateRandomId();
      }
      return [...prevVal, id];
    });
  };

  const onRemoveOption = (idToDelete) => {
    updateMoreOptions((prev) => {
      const val = prev.filter((id) => id !== idToDelete);
      return val;
    });
    updateMoreOptionValues((prevVal) => {
      const newVal = { ...prevVal };
      delete newVal[idToDelete];
      return newVal;
    });
  };

  const onOptionFieldChange = (id, e) => {
    updateMoreOptionValues((prevVal) => {
      return { ...prevVal, [id]: e.target.value };
    });
  };

  const extraFields = useMemo(() => {
    let start_count = 3; // because already 2 options are required
    return moreOptions.map((key, index) => {
      const onChange = onOptionFieldChange.bind(this, key);
      return (
        <OptionInputWrapper key={key}>
          <StyledPollInputGroup
            label={
              <LabelArea
                title={`Option ${index + start_count}`}
                formData={formData}
                optionIndex={String(index + start_count)}
              />
            }
            fieldClass="poll_options_field"
            size="sm"
            value={moreOptionValues[key] || ""}
            onChange={onChange}
            name={`Option ${index + start_count}`}
          />
          <RemoveButton onClick={() => onRemoveOption(key)}>
            Remove
          </RemoveButton>
        </OptionInputWrapper>
      );
    });
  }, [moreOptions, moreOptionValues, formData["answere"]]);

  const moreOptionsCount = Object.keys(moreOptions).length;

  return (
    <PostCreateContainer {...rest}>
      <PostHeader title="Create a Quiz" onClose={onClose} />

      <Section>
        <PollFormArea className="poll-area">
          <InputGroup
            label="Your question"
            placeholder="Enter your question"
            fieldType="textArea"
            fillParent={true}
            wrapperStyle={{ minHeight: "100px", marginBottom: "30px" }}
            {...formData["question"]}
          />

          <StyledPollInputGroup
            size="sm"
            label={
              <LabelArea title="Option 1" formData={formData} optionIndex="1" />
            }
            {...formData["option1"]}
          />
          <StyledPollInputGroup
            size="sm"
            label={
              <LabelArea title="Option 2" formData={formData} optionIndex="2" />
            }
            {...formData["option2"]}
          />

          {extraFields}

          {moreOptionsCount < MAX_MORE_OPTIONS && (
            <AddMoreOption type="button" onClick={onAddMoreOption}>
              Add option
            </AddMoreOption>
          )}
        </PollFormArea>
      </Section>
      <PollFooter>
        {errors.answere && (
          <Error style={{ marginRight: "auto" }}>
            Please select the answere
          </Error>
        )}
        {!!onPostTypeChange && (
          <PostBackButton onClick={() => onPostTypeChange(postTypes.post)}>
            Back
          </PostBackButton>
        )}
        <PostButton
          onClick={onSubmit}
          loading={isLoading}
          disabled={isLoading}
          type="button"
        >
          Post
        </PostButton>
      </PollFooter>
    </PostCreateContainer>
  );
}

function LabelArea({ title, formData, optionIndex }) {
  return (
    <StyledLabelArea>
      <div className="radio-area">
        <Radio
          {...formData["answere"]}
          checked={formData["answere"].value === optionIndex}
          value={optionIndex}
          label={title}
        />
      </div>
    </StyledLabelArea>
  );
}

export default PostQuizCreate;
