import React from "react";
import Computer_Img from "assets/employee/computer.svg";
import { Container, Heading, Desc, StyledButton, Image } from "./style";

const InfoView = ({ onCta, ctaText, heading, desc, image, className }) => {
  return (
    <Container className={className}>
      <Image src={image || Computer_Img} alt="computer on a desk" />
      {!!heading && <Heading>{heading}</Heading>}
      {!!desc && <Desc>{desc}</Desc>}
      {ctaText && onCta && (
        <StyledButton primary onClick={onCta}>
          {ctaText}
        </StyledButton>
      )}
    </Container>
  );
};
InfoView.defaultProps = {
  heading: null,
  desc: null,
  image: null,
  className: ""
};

export default InfoView;
