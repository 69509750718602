import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import EmptyData from "../EmptyData";
import { FadeInVertical } from "globalStyles";
const TopFade = FadeInVertical("top");

const profileStyle = css`
  width: 36px;
  height: 36px;
  flex-shrink: 0;
`;

const warm = keyframes`
 0%{
   opacity:1;
 }
 50%{
   opacity:0.5;
 }
 100%{
   opacity:1;
 }
`;

export const ListContainer = styled.div`
  padding: 10px 0px;
`;

export const ListItem = styled.div`
  min-height: 65px;
  align-items: center;
  display: flex;
  padding: 16px 35px;
  border-top: 1px solid #0000001a;
  opacity: 0;
  animation: ${TopFade} ease-out 0.3s forwards;
`;

export const ListTextContainer = styled.div`
  display: flex;
  color: #333333;
  flex-flow: column nowrap;
  padding: 0px 15px 0 10px;
  flex: 1;
`;

export const LoaderIcon = styled.div`
  ${profileStyle}
  border-radius: 50%;
  background-color: #f3f3f3;
`;

export const LoaderText = styled.div`
  height: 40px;
  background-color: #f3f3f3;
  animation: ${warm} ease-in 1s forwards infinite;
`;

export const Message = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.text.para.medium};
`;

export const BreaksHeader = styled.div`
  height: 70px;
  padding: 0 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  padding-top: 5px;
  background-color: white;
  border-bottom: 1px solid #0000001a;
  z-index: 1;
  ${({ $showShadow }) => {
    if ($showShadow) {
      return `box-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.2);`;
    }
  }}
`;

export const Showtime = styled.div`
  height: 65px;
  padding: 0 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: ${(props) => props.theme.colors.primaryColor};
`;

export const Heading = styled.span`
  font-style: normal;
  font-weight: 600;
  margin-left: 10px;
  margin-bottom: 3px;
  font-size: ${(props) => props.theme.text.para.large};
`;

export const Timer = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.large};
`;

export const StyledEmptyData = styled(EmptyData)`
  & > img {
    width: 100px;
    height: 100px;
  }
  & > span {
    font-size: ${(props) => props.theme.text.para.small};
  }
  margin: 80px 0px;
`;

export const IconContainer = styled.div`
  display: flex;
  width: 40px;
  justify-content: center;
`;

export const PlayIconContainer = styled.button`
  outline: none;
  background: none;
  cursor: pointer;
  display: flex;
  width: 30px;
  height: 30px;
  padding: 0px 3px;
  justify-content: center;
  align-items: center;
  border: 1px solid #cecece;
  box-sizing: border-box;
  border-radius: 4px;
  .green-color {
    color: #44d953;
  }
  .red-color {
    color: #f03434;
  }
  ${({ disabled }) => {
    return (
      disabled &&
      `
        cursor: not-allowed;
      `
    );
  }}
`;

export const PreviousBreaks = styled(Link)`
  display: flex;
  justify-content: space-between;
  background: #e8e8ff;
  border-radius: 3px;
  font-weight: 500;
  width: 290px;
  text-decoration: none;
  align-self: center;
  margin: 0px auto 20px;
  padding: 8px 16px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primaryColor};
  font-size: ${(props) => props.theme.text.para.small};
`;

export const CountText = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.text.para.small};
  color: ${(props) => props.theme.colors.primaryColor};
`;

export const Count = styled.div`
  font-weight: 600;
  width: 20.91px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  align-items: center;
  height: 22.27px;
  border-radius: 4.54545px;
  font-size: ${(props) => props.theme.text.para.small};
  color: white;
  background-color: ${(props) => props.theme.colors.primaryColor};
`;

export const Footer = styled.div`
  padding: 15px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: white;
  ${({ $showShadow }) => {
    if ($showShadow) {
      return `box-shadow: -1px 2px 10px 0px rgba(0, 0, 0, 0.2);`;
    }
  }}
`;
