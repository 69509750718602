import requestData from "../../../ApiHandler";

export const getAssignedLeavesApi = ({ pageNumber, companyId, status }) => {
  const url = `/employee/companies/${companyId}/fetch_assigned_leave_requisitions?page=${pageNumber}&status=${status}`;
  return requestData(url, {
    method: "GET"
  });
};

export const getNotifiedLeavesApi = ({ pageNumber, companyId }) => {
  const url = `/employee/companies/${companyId}/fetch_notified_leave_requisitions?page=${pageNumber}`;
  return requestData(url, {
    method: "GET"
  });
};

export const updateLeaveStatusApi = ({ companyId, leaveId, status }) => {
  const url = `/employee/companies/${companyId}/update_leave_requisition_status/${leaveId}`;
  return requestData(url, {
    method: "PUT",
    body: {
      leave_requisition: {
        status: status
      }
    },
    isFormData: true
  });
};

export const cancelLeaveApi = ({ companyId, leaveId, status }) => {
  const url = `/employee/companies/${companyId}/leave_requisitions/${leaveId}`;
  return requestData(url, {
    method: "PUT",
    body: {
      leave_requisition: {
        status: status
      },
    },
    isFormData: true
  });
};

export const createLeaveCommentApi = ({ companyId, leaveId, message }) => {
  const url = `/employee/companies/${companyId}/comments`;
  return requestData(url, {
    method: "POST",
    body: {
      comment: {
        message: message,
        commentable_id: leaveId,
        commentable_type: "LeaveRequisition"
      }
    },
    isFormData: true
  });
};
