import InputGroup from "components/InputGroup";
import { isChronicOption } from "../options";

export const HealthDetailsForm = ({ presentData }) => {
  return (
    <>
      <InputGroup
        id="personalInfoCreate_currentHealthCondition"
        fieldType="textArea"
        required
        placeholder="Enter your Current Health Condition, else Enter NA"
        label="Current Health Condition"
        size="lg"
        initialValue={presentData?.currentHealthCondition}
      />

      <InputGroup
        id="personalInfoCreate_isChronic"
        fieldType="checkboxGroup"
        options={isChronicOption}
        groupName="emergencyContactCreate_isChronic"
        initialValue={presentData?.isChronic}
      />
    </>
  );
};
