import { createSelector } from "reselect";

const teamData = (store, props) => store.teams.owners.data;
const teamRelations = (store, props) => store.teams.owners.relation;
const teamId = (store, props) => store.teams.owners.teamId;

export const getTeamOwners = createSelector(
  teamData,
  teamRelations,
  teamId,
  (data, relations, id) => {
    let res = {};
    const dataRelation = data[id]?.relationships;

    const teamRelation = dataRelation?.ownerTeamCollaborators.data;

    const teamCollabs = teamRelation?.map((el) => {
      return relations[`${el.id}_${el.type}`];
    });

    teamCollabs?.forEach((el) => {
      const relsData = el?.relationships?.collaborator?.data;
      const rels = relations[`${relsData.id}_${relsData.type}`];
      const attr = rels.attributes;
      res = {
        ...res,
        [rels.id]: {
          id: rels.id,
          heading: attr?.name,
          name: attr?.name,
          desc: attr?.workMail,
          image: attr?.avatarUrl,
          avatarUrl: attr?.avatarUrl,
        },
      };
    });

    return res;
  }
);

export const getTeamDepartments = createSelector(
  teamData,
  teamRelations,
  teamId,
  (data, relations, id) => {
    let res = {};
    const dataRelation = data[id]?.relationships;

    const teamRelation = dataRelation?.department.data;

    const teamDept =
      teamRelation && relations[`${teamRelation.id}_${teamRelation.type}`];

    res = teamDept && {
      [teamDept.id]: {
        id: teamDept.id,
        heading: teamDept?.attributes?.name,
        desc: teamDept?.attributes?.workMail,
        image: teamDept?.attributes?.avatarUrl,
      },
    };

    return res;
  }
);
