import Divider from "components/Divider";
import styled from "styled-components";
import TextWithLinks from "components/TextWithLinks";
import { RectangleSkeleton } from "components/Skeleton";
import { scrollbar1 } from "globalStyles/view";

const getStatusColor = ({ $type }) => {
  switch ($type?.toLowerCase()) {
    case "pending":
      return "#7A3BCB";
    case "approved":
      return "#02C467";
    case "notified":
      return "#FF9600";
    case "cancelled":
      return "#0094E7";
    case "rejected":
      return "#FF0743";
    default:
      return "#FF9600";
  }
};

export const MainContainer = styled.div`
  height: 100%;
  padding: 10px 50px 20px 50px;
  margin-top: 60px;
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
`;

export const DetailsContainer = styled.div`
  display: flex;
`;

export const DetailsLeftContainer = styled.div`
  flex: 0.7;
  max-width: 50vw;
`;

export const DetailsRightContainer = styled.div`
  flex: 0.3;
  padding: 0px 50px;
`;

export const TextContainer = styled.div`
  white-space: pre-wrap;
`;

export const CollaboratorContainer = styled.div`
  display: flex;
  column-gap: 100px;
  flex-wrap: wrap;
`;

export const CollaboratorSubContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  & * {
    flex-shrink: 0;
  }
`;

export const DocumentContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
  margin-bottom: 40px;
`;

export const CardsContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 5px;
  ${scrollbar1}

  & .no-document {
    gap: 10px;
    & img {
      width: 185px;
      height: 125px;
      margin-top: 10px;
    }
    & span {
      text-align: left;
      font-size: ${(props) => props.theme.text.para.medium};
    }
  }
`;

export const EachCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  font-size: ${(props) => props.theme.text.para.medium};
  font-weight: 500;
  word-wrap: break-word;
  margin: 20px 20px 20px 0px;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadow.medium};
`;

export const ImageContainer = styled.div`
  display: flex;
  position: relative;

  & div,
  img {
    width: 150px;
    height: 95px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px 5px 0px 0px;
  }

  & div:hover {
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  z-index: 10;
  display: flex;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.23);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

export const OtherImage = styled.img`
  object-fit: contain;
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`;

export const SmallImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

export const BigImage = styled.img`
  max-width: 500px;
  min-width: 280px;
  min-height: 200px;
  height: 100%;
  object-fit: contain;
  width: 100%;
  max-height: 500px;
`;

export const NameContainer = styled.div`
  background-color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 0px 0px 12px 12px;
  display: flex;
`;

export const FileName = styled.div`
  font-size: 1rem;
  margin-left: 8px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StatusContainer = styled.div`
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background: ${getStatusColor};
  color: #fff;
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const StatusUpdateContainer = styled(StatusContainer)`
  background: #fff;
  color: ${(props) => props.theme.colors.darkGrayColor}80;
  border: 1px solid ${(props) => props.theme.colors.mediumGrayColor};
  cursor: pointer;

  &:hover {
    background: ${getStatusColor};
    color: #fff;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 15px 0;
  max-width: 300px;
`;

export const StyledHeading = styled.div`
  max-width: 300px;
  color: ${(props) =>
    props.$workLogTypeColor || props.theme.colors.primaryColor};
  background: ${(props) =>
    props.$workLogTypeColor
      ? `${props.$workLogTypeColor}30`
      : props.theme.colors.lightPrimaryColor};
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const AttachmentContainer = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
`;

export const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
export const BackButton = styled.button`
  cursor: pointer;
  border: none;
  margin-right: 20px;
  font-size: large;
  color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.button.primary.background};
`;

export const ContentContainer = styled.div`
  text-align: left;
  margin-bottom: 20px;
  overflow-wrap: break-word;
`;

export const ContentHeader = styled.p`
  font-weight: 700;
  color: ${(props) =>
    props.$workLogTypeColor || props.theme.colors.primaryColor};
  font-size: ${(props) => props.theme.text.para.medium};
`;

export const ContentDescription = styled(TextWithLinks)`
  margin: 5px;
  background: ${(props) =>
    props.$workLogTypeColor
      ? `${props.$workLogTypeColor}15`
      : props.theme.colors.lightPrimaryColor};
  padding: 10px;
  white-space: pre;
  border-radius: 10px;
`;

export const LogWorkButton = styled.button`
  outline: none;
  color: white;
  font-weight: bold;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.primaryColor};
  border: none;
`;

export const StyledSkeleton = styled(RectangleSkeleton)`
  margin: 10px 0;
  & > div {
    height: 60px;
  }
`;
