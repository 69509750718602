import React, { useCallback, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup";
import Icon from "components/Icon";
import Button from "components/Button";
import useAlert from "hooks/useAlert";

import { createUpi } from "../../actionCreator";
import { getCurrentUserCompany, isCurrentUserIsExecutive } from "common/Selectors/auth";

import { StyledFormGroup } from "globalStyles";
import { FormHeading, Header } from "../../style/create";
import { generateFormData } from "../../utils";

const Form = ({ setIsFormVisible, createUpi, isAdmin, companyId }) => {
  const [isLoading, updateLoading] = useState(false);
  const [upiId, setUpiId] = useState();
  const [isUpiIdFocused, setUpiIdFocused] = useState();
  const { showAlert } = useAlert();

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      try {
        updateLoading(true);
        const data = generateFormData(values);
        await createUpi({ companyId, isAdmin, formData: data });
        showAlert({ message: `UPI Detail Created` });
        resetForm();
        setIsFormVisible(false);
      } catch (err) {
        showAlert({ message: "UPI Create Failed", type: "error" });
      } finally {
        updateLoading(false);
      }
    },
    [companyId]
  );

  const setUpi = useCallback((e) => {
    setUpiId(e.target.value);
  }, []);

  const equalityCheck = useCallback(
    (e) => {
      if (upiId !== e) {
        return false;
      }
      return true;
    },
    [upiId]
  );

  const onFocus = useCallback(() => {
    setUpiIdFocused(true);
  }, []);

  const onBlur = useCallback((event) => {
    setUpiIdFocused(false);
    setUpi(event);
  }, []);

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Add new UPI ID</FormHeading>
        <Icon name="fas fa-times" onClick={updateFormVisibilty} />
      </Header>

      <InputGroup
        id="upiCreate_upiHolderName"
        fieldType="input"
        required
        placeholder="Enter UPI holder name"
        label="UPI Holder Name"
      />

      <InputGroup
        id="upiCreate_upiId"
        fieldType="input"
        required
        placeholder="Enter UPI ID"
        label="UPI ID"
        onFocus={onFocus}
        onBlur={onBlur}
        style={isUpiIdFocused ? null : { webkitTextSecurity: "disc" }}
      />

      <InputGroup
        id="upiCreate_upiId"
        fieldType="input"
        required
        placeholder="Re-enter UPI ID"
        label="Re-enter UPI ID"
        validator={equalityCheck}
        invalidMessage="UPI ID must be same as before"
      />

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Create
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return { companyId, isAdmin };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createUpi,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
