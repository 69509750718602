import styled from "styled-components";

export const ListGroupContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  ${({ isInBottom }) => {
    if (isInBottom) {
      return ` top: calc(100% + 5px);`;
    }
    return ` bottom: calc(100% + 5px);`;
  }}
  border-radius: 6px;
  box-shadow: ${(props) => props.theme.shadow.medium};
  background: ${(props) => props.theme.colors.lightPrimaryColor};
  border: 1px solid ${(props) => props.theme.colors.primaryColor};
  max-height: 200px;
  overflow-y: scroll;
  z-index: 10000;
  min-width: 240px;
  min-height: 100px;
  padding: 22px 27px 0 22px;
`;

export const EmptyResultBox = styled.div`
   {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LoadingContainer = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const EmptyResultText = styled.div`
   {
    font-size: ${(props) => props.theme.text.para.small};
    color: ${(props) => props.theme.colors.darkGrayColor};
  }
`;
