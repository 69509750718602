import React, { useCallback, useMemo, useState } from "react";
import { getIcon } from "../../../Helper";
import ActivityIndicator from "../ActivityIndicator";
import Icon from "../Icon";
import Tooltip from "../Tooltip2";

import {
  ListItem,
  PlayIconContainer,
  ListTextContainer,
  IconContainer,
  Message,
  LoaderIcon,
  LoaderText,
} from "./style";

const BreakItem = ({
  data,
  isLoading,
  onClick,
  ongoingBreak,
  isUserLoggedIn,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const { iconCode, title, snapId } = data;

  const onMessageClick = useCallback(async () => {
    if (typeof onClick === "function") {
      setLoading(true);
      await onClick(data);
      setLoading(false);
    }
  }, [data, onClick]);

  const play = useMemo(
    () => ongoingBreak && ongoingBreak.snapId === snapId,
    [ongoingBreak, snapId]
  );

  return (
    <ListItem {...rest}>
      <IconContainer>
        {isLoading ? <LoaderIcon /> : getIcon(iconCode)}
      </IconContainer>

      <ListTextContainer>
        {isLoading ? <LoaderText /> : <Message>{title}</Message>}
      </ListTextContainer>

      {!isLoading && (
        <>
          <div data-tooltip="nav-breaks-btn">
            <PlayIconContainer
              onClick={onMessageClick}
              disabled={(ongoingBreak && !play) || !isUserLoggedIn}
            >
              {loading && <ActivityIndicator size="s" color="gray" />}
              {!loading && (
                <Icon
                  name={`fa fa-${!play ? "play" : "stop"}`}
                  className={play ? "red-color" : "green-color"}
                />
              )}
            </PlayIconContainer>
          </div>
          {!isUserLoggedIn && (
            <Tooltip tooltipId="nav-breaks-btn">You need to Sign In</Tooltip>
          )}
        </>
      )}
    </ListItem>
  );
};

export default BreakItem;
