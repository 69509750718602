import { CREATE_BREAK, GET_ACTIVE_BREAKS, GET_BREAKS } from "../actiontypes";

import {
  createInitialStoreState,
  createNewStoreOnGetData,
  createNewStoreOnCreate,
} from "helper";

const initialState = createInitialStoreState();

export const snapsReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case `${CREATE_BREAK}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);
    case `${GET_BREAKS}_SUCCESS`:
    case `${GET_ACTIVE_BREAKS}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    default:
      return store;
  }
};
