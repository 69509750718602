import { createSelector } from "reselect";

const workshiftIds = (store) => store.workshift.type.allIds;
const workshiftData = (store) => store.workshift.type.allData;

export const getAllWorkshifts = createSelector(
  workshiftIds,
  workshiftData,
  (ids, data) =>
    ids && data && ids.map((id) => ({ ...data[id].attributes, id }))
);
export const workshiftDataSelector = createSelector(
  workshiftData,
  (_, props) => props.id,
  (data, id) => {
    return data[id] ? data[id].attributes : {};
  }
);
