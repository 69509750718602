import {
  FETCH_TODAY_ANNIVERSARY,
  FETCH_UPCOMINGS_ANNIVERSARY,
} from "./actionTypes";
import { createNewStoreOnGetData, createInitialStoreState } from "helper";
import { combineReducers } from "redux";

const todayInitialState = createInitialStoreState({}, true);

function todayAnniversaryReducer(store = todayInitialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_TODAY_ANNIVERSARY}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    default:
      return store;
  }
}

const nextInitialState = createInitialStoreState({}, true);
function upcomingAnniversaryReducer(store = nextInitialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_UPCOMINGS_ANNIVERSARY}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store, true);
    }

    default:
      return store;
  }
}

const AnniversaryReducer = combineReducers({
  today: todayAnniversaryReducer,
  upcoming: upcomingAnniversaryReducer,
});

export default AnniversaryReducer;
