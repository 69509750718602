import { useMemo } from "react";
import {
  Container,
  Header,
  Content,
  StyledSkeleton,
} from "../../../style/display";
import { TableContainer } from "../../../../../GlobalStyles/view";
import LeavesContent from "./LeavesContent";
import useCollaboratorLeaves from "./useCollaboratorLeaves";
import { createTableDataSelector } from "common/Selectors/MTable";
import { connect } from "react-redux";
import { ILeavesAppliedProps } from "./types";
import { transformLeavesData } from "./utils";
import { MockCollaboratorLeaves } from "./constants";

const LeavesApplied = ({ data, cellNameToColumnMap }: ILeavesAppliedProps) => {
  const { isLoading } = useCollaboratorLeaves();
  const { leaves } = useMemo(
    () => transformLeavesData({ data, cellNameToColumnMap }),
    [data, cellNameToColumnMap]
  );

  return (
    <TableContainer>
      <Container>
        <Header>Leaves Balance</Header>
        <Content>
          {isLoading &&
            MockCollaboratorLeaves.map((item, index) => (
              <StyledSkeleton times={1} key={index} />
            ))}
          {!isLoading &&
            leaves.map((item) => (
              <LeavesContent
                key={item.leavePlan.id}
                assignedQuota={item.assignedQuota}
                leavePlan={item.leavePlan}
                leaveRequisitionCount={item.leaveRequisitionCount}
              />
            ))}
        </Content>
      </Container>
    </TableContainer>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "collaboratorLeaves",
    blackList: { "Sl No": true },
  });

  const maker = (store: object, props: object) => {
    const { rows, cellNameToColumnMap } = getUserRows(store);
    return {
      data: rows,
      cellNameToColumnMap,
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(LeavesApplied);
