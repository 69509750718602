import React from "react";
import { getImageUrl } from "../../../Helper";

import { ProfileCell, ProfileCellName, StyledProfilePic } from "./style";

const Profile = ({ data, color, showUsername = true, className }) => {
  const { avatarUrl, name } = data;
  return (
    <ProfileCell className={className}>
      <StyledProfilePic
        color={color}
        img={getImageUrl(avatarUrl?.url)}
        userName={name}
      />
      {showUsername && <ProfileCellName>{name}</ProfileCellName>}
    </ProfileCell>
  );
};

export default Profile;
