import { SET_CURRENT_VENUE } from "./actionTypes";

export const setCurrentVenue = (coordinates, address, name) => ({
  type: SET_CURRENT_VENUE,
  payload: {
    latitude: coordinates.lat,
    longitude: coordinates.lng,
    address,
    name,
  },
});
