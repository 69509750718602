import styled from "styled-components";
import ProfilePic from "../../../Common/Components/ProfilePic";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 25px 50px 10px 0;
  width: 100%;
`;

export const NoticeSentHeading = styled.h3`
  font-size: 34px;
  color: ${(props) => props.theme.colors.primaryColor};
  margin: 0;
  margin-bottom: 29px;
  font-weight: 600;
  text-align: left;
`;

export const Heading = styled.p`
  font-size: ${(props) => props.theme.text.title.large};
  font-weight: 500;
  margin: 0 0 12px;
  text-align: left;
`;

export const SubHeading = styled.p`
  font-size: ${(props) => props.theme.text.title.medium};
  font-weight: 500;
  margin: 0;
  text-align: left;
`;

export const NoticeBody = styled.p`
  white-space: pre-line;
  font-size: ${(props) => props.theme.text.para.large};
  text-align: left;
  margin: 10px 0 0;
  min-height: 225px;
  @media (min-width: 768px) {
    min-height: 250px;
  }
`;
export const Footer = styled.div`
  margin: 40px 0 20px 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }
`;

export const TimeContainer = styled.p`
  font-size: ${(props) => props.theme.text.para.medium};
  color: ${(props) => props.theme.colors.primaryColor};
  text-align: left;
  margin: 0;
  @media (min-width: 768px) {
    text-align: right;
  }
`;

export const CollaboratorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const SentToText = styled.span`
  font-size: ${(props) => props.theme.text.para.large};
  text-align: left;
  margin: 0 0 8px 3px;
  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.text.para.medium};
  }
`;

export const CollaboratorsPicContainer = styled.div`
  display: flex;
`;

export const CollaboratorPic = styled(ProfilePic)`
  width: 40px;
  height: 40px;
  border: 2px solid white;
  position: relative;
  right: ${({ right }) => `${right}px`};
  z-index: ${({ zIndex }) => zIndex};
`;
