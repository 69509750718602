import styled from "styled-components";
import { StyledInputGroups, FadeInHorizontal } from "globalStyles";
const LeftFade = FadeInHorizontal("left");

export const MainContainer = styled.div`
  height: 100%;
  padding: 0 50px 20px 50px;
  display: flex;
  flex-flow: column nowrap;
`;

export const FormHeading = styled.h3`
  font-size: ${(props) => props.theme.text.title.large};
  color: ${(props) => props.theme.colors.primaryColor};
  margin-bottom: 20px;
  margin-top: 15px;
  font-weight: 600;
  text-align: left;
`;

export const Footer = styled.div`
  flex-grow: 1;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;
export const ExecutiveBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;

  & label {
    padding: 0;
  }
`;

export const StyledTableGroup = styled(StyledInputGroups)`
  align-items: stretch;
  & > div {
    flex-grow: 5;
  }

  & > button {
    flex-grow: 1;
    margin-bottom: 0px;
    margin-top: 8px !important;
  }
`;

export const StyledTablePopoverContent = styled.div`
  width: 100%;
  padding: 10px;
`;

export const FieldInfoTag = styled.div`
  align-self: flex-start;
  margin-bottom: 25px;
  color: ${(props) => props.theme.colors.label};
  font-size: ${(props) => props.theme.text.para.extraSmall};

  & span {
    margin-left: 10px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 20px;
  & > button + button {
    margin-left: 10px;
  }
`;

export const MainFormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  width: 100%;
  max-width: 780px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  width: 100%;
  animation: ${LeftFade} ease-out 0.3s forwards;
`;
