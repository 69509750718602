import React from "react";
import { Navigator } from "./style";
import TabLink from "./TabLink";

const TabHeader = ({ currentTabIndex, setIndex, tabNames, secondary }) => {
  return (
    <Navigator>
      {tabNames.map((item, index) => (
        <TabLink
          key={index}
          item={item}
          index={index}
          currentTabIndex={currentTabIndex}
          setIndex={setIndex}
          secondary={secondary}
        />
      ))}
    </Navigator>
  );
};
export default TabHeader;
