import { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { generateRandomId } from "../Helper";
import { getAlerts } from "../Common/Selectors/alert";
import { DE_ACTIVATE_ALERT, ACTIVATE_ALERT } from "../Common/ActionTypes/alert";

interface IshowAlert {
  message: string;
  type?: "success" | "info" | "warning" | "error";
  verticalPosition?: "top" | "bottom";
  horizontalPosition?: "right" | "left";
  fixed?: boolean;
  maxTime?: number;
}

type RsetTimeout = ReturnType<typeof setTimeout>;
type timerType = {
  [key: string]: RsetTimeout | null;
};

const useAlert = () => {
  const timerRef = useRef<timerType>({});
  const dispatch = useDispatch();
  const alertsList = useSelector(getAlerts);

  const hideAlert = useCallback(
    (id: string) => {
      if (timerRef.current[id]) {
        clearTimeout(timerRef.current[id] as RsetTimeout);
        timerRef.current[id] = null;
      }
      dispatch({
        type: DE_ACTIVATE_ALERT,
        payload: { id },
      });
    },
    [dispatch]
  );

  const showAlert = useCallback(
    ({
      message,
      type = "success",
      verticalPosition = "top",
      horizontalPosition = "right",
      fixed = false,
      maxTime = 3000,
    }: IshowAlert): string => {
      const id = generateRandomId();
      dispatch({
        type: ACTIVATE_ALERT,
        payload: {
          message: String(message),
          type,
          verticalPosition,
          horizontalPosition,
          id,
        },
      });

      if (!fixed) {
        const timer = setTimeout(() => {
          hideAlert(id);
        }, maxTime);
        timerRef.current[id] = timer;
      }
      return id;
    },
    [dispatch, hideAlert]
  );

  return {
    showAlert,
    hideAlert,
    alertsList,
  };
};

export default useAlert;
