import { MonthIndex } from "../Constants";

export const isOngoingYear = (startDateString, endDateString) => {
  const dateToday = new Date();
  if (
    new Date(startDateString) <= dateToday &&
    dateToday <= new Date(endDateString)
  ) {
    return true;
  }
  return false;
};

export const changeToDateFormat = (dateString, formatType = "/") => {
  const date = new Date(dateString);
  //changing date to DD/MM/YYYY format or any other format depending upon the type passed
  const day = date.getDate();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDateString = `${day}${formatType}${month}${formatType}${year}`;
  return formattedDateString;
};

export const isToday = (providedDate) => {
  const today = new Date();
  return isTwoDateSame(today, providedDate);
};

export const isTwoDateSame = (firstDate, secondDate) => {
  const dd = firstDate.getDate() === secondDate.getDate();
  const mm = firstDate.getMonth() === secondDate.getMonth();
  const yy = firstDate.getFullYear() === secondDate.getFullYear();
  return dd && mm && yy;
};

export const get12HourString = (hr, min) => {
  let res = "";

  if (hr === 0) {
    res += `12:${min < 10 ? "0" + min : min}`;
  } else {
    res += `${hr > 12 ? hr - 12 : hr}:${min < 10 ? "0" + min : min}`;
  }

  if (hr < 12) {
    res += " am";
  } else {
    res += " pm";
  }

  return res;
};

export const get24HourString = (hr, min = 0, sec = 0) => {
  return `${hr < 10 ? "0" + hr : hr}:${min < 10 ? "0" + min : min}:${
    sec < 10 ? "0" + sec : sec
  }`;
};

export const createTimeOptions = (startHr = 0, interval = 15) => {
  const result = [];
  let min = 0;
  let i = 0;
  while (i < 24) {
    result.push({
      name: get12HourString(i, min),
      value: get24HourString(i, min),
      hour: i,
      min,
    });
    min += interval;
    if (min === 60) {
      min = 0;
      i++;
    }
  }
  return result;
};

export const isDateCombinationValid = (
  startDate,
  endDate,
  stDateName = "Start",
  endDateName = "End"
) => {
  let message, isValid;
  if (startDate.length === 0) {
    message = `Please select ${stDateName} date first`;
    isValid = false;
  } else if (new Date(startDate) > new Date(endDate)) {
    message = `${endDateName} Date should be greater than the ${stDateName} Date`;
    isValid = false;
  } else {
    message = "";
    isValid = true;
  }
  return { isValid, message };
};

export const getCurrentMonthFirstDate = (d) => {
  const td = d ? new Date(d) : new Date();
  td.setDate(1);
  return td;
};

export const convertDateToAllBrowserReadableFormat = (date) => {
  //Safari doesn't recognise '-' in date and returns invalid date when it encounters the format 'new Date(someDate)'. Hence, all the '-' is replaced by '/'.
  let formattedDate = date.toString().replace(/-/g, "/");
  return formattedDate;
};

export const getDistanceBetweenTwoDateInWords = (start, end) => {
  const startDate = new Date(convertDateToAllBrowserReadableFormat(start));
  const endDate = new Date(end);

  startDate.setMilliseconds(0);
  endDate.setMilliseconds(0);

  const diff = endDate - startDate;

  const seconds = Math.trunc(diff / 1000);
  if (seconds < 60) {
    return getDistanceText("sec", seconds);
  }

  const minutes = Math.trunc(seconds / 60);
  if (minutes < 60) {
    return getDistanceText("min", minutes);
  }

  const hour = Math.trunc(minutes / 60);
  if (hour < 24) {
    return getDistanceText("hr", hour);
  }

  const day = Math.trunc(hour / 24);
  if (day < 32) {
    return getDistanceText("day", day);
  }

  if (startDate.getFullYear() === endDate.getFullYear()) {
    const monthDiff = endDate.getMonth() - startDate.getMonth();
    return getDistanceText("month", monthDiff);
  } else {
    const yearDiff = endDate.getFullYear() - startDate.getFullYear();
    return getDistanceText("year", yearDiff);
  }
};

const getDistanceText = (type, time) => {
  switch (type) {
    case "sec":
      return `${time} sec`;
    case "min":
      return `${time} ${time <= 1 ? "min" : "mins"}`;
    case "hr":
      return `${time} ${time <= 1 ? "hour" : "hours"}`;
    case "day":
      return `${time} ${time <= 1 ? "day" : "days"}`;
    case "month":
      return `${time} ${time <= 1 ? "month" : "months"}`;
    case "year":
      return `${time} ${time <= 1 ? "year" : "years"}`;
    default:
      return "";
  }
};

export const getDayMonthYear = (date) => {
  return {
    day: (date && new Date(date).getDate()) || "",
    month:
      date && new Date(date).getMonth() + 1 ? new Date(date).getMonth() : "",
    year: (date && new Date(date).getFullYear()) || "",
  };
};

export const getTimeFromDate = (month, day, year) => {
  return (
    day &&
    month !== "" &&
    year &&
    new Date(`${month + 1}/${day}/${year}`).getTime()
  );
};

export const convertDateToMMDDYYYY = (date = "", formatType = "/") => {
  const dateStr = date.split(formatType);
  if (!dateStr[0] && !dateStr[1] && !dateStr[2]) return date;

  return `${dateStr[1]}${formatType}${dateStr[0]}${formatType}${dateStr[2]}`;
};

export const convertDateToYYYYMMDD = (date = "", formatType = "/") => {
  const dateStr = date.split(formatType);
  if (!dateStr[0] && !dateStr[1] && !dateStr[2]) return date;

  return `${dateStr[2]}${formatType}${dateStr[1]}${formatType}${dateStr[0]}`;
};

export const convertToTextDateString = (date) => {
  const d = new Date(convertDateToAllBrowserReadableFormat(date));
  const options = {
    weekday: "long",
    // year: "numeric",
    month: "long",
    day: "numeric",
  };
  const timeOptions = { hour: "2-digit", minute: "2-digit" };

  return `${d.toLocaleString("en-US", options)} , ${d.toLocaleTimeString(
    "en-US",
    timeOptions
  )}`;
};

export const convertToTextDateStringMMDDYYYY = (date) => {
  const d = new Date(convertDateToAllBrowserReadableFormat(date));
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return `${d.toLocaleString("en-US", options)}`;
};

export const getDateDetails = (date) => {
  const dt = new Date(date);
  let todayDate = dt.getDate();
  todayDate = todayDate < 10 ? `0${todayDate}` : todayDate;
  const today = dt.getDay();
  let monthNumber = dt.getMonth();
  const month = MonthIndex[monthNumber];
  // increasing by one to relate to actual world values
  monthNumber++;
  monthNumber = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
  const year = dt.getFullYear();
  return {
    todayDate,
    today,
    month,
    monthIndex: monthNumber,
    year,
    ...getTimeDetails(date),
  };
};

export const getTimeDetails = (date) => {
  const dt = new Date(date);
  let hour = dt.getHours();
  hour = hour < 10 ? `0${hour}` : hour;

  let min = dt.getMinutes();
  min = min < 10 ? `0${min}` : min;

  let sec = dt.getSeconds();
  sec = sec < 10 ? `0${sec}` : sec;

  return {
    hour,
    min,
    sec,
  };
};

export const extractDateInYYYYMMDD = (date) => {
  date = typeof date == "string" && new Date(date);

  if (date != "Invalid Date") {
    const { todayDate, monthIndex, year } = getDateDetails(date);
    return year + "-" + monthIndex + "-" + todayDate;
  } else {
    return "";
  }
};

export const convertMilisecondsToDateArray = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  const addZero = (num) => {
    if (num < 10) {
      return "0" + num;
    }

    return num;
  };
  return [addZero(days), addZero(hours), addZero(minutes), addZero(seconds)];
};
