import {
  GET_FOLLOW_SUBORDINATES,
  FETCH_ALL_ATTENDANCE,
  SEARCH_ATTENDANCE_DATA,
  RESET_ATTENDANCE_DATA,
  SET_SEARCH_DATA,
  UPDATE_COLLABORATOR_ID,
  UPDATE_SUBORDINATE_ATTENDANCE_STATUS,
} from "./actiontypes";

import { createAsyncActionCreator } from "../../Helper";

import {
  getFollowSubordinatesApi,
  getAllCollaboratorAttendanceApi,
  getSpecificCollaboratorAttendanceApi,
  updateSubordinateAttendanceStatusApi,
} from "./api";

const getFollowSubordinatesGenerator = function* ({
  companyId,
  pageNumber,
  isAdmin,
}) {
  yield {
    action: GET_FOLLOW_SUBORDINATES,
    sideEffect: getFollowSubordinatesApi({ companyId, pageNumber, isAdmin }),
  };
};

const getSpecificCollaboratorAttendanceGenerator = function* ({
  pageNumber,
  companyId,
  isAdmin,
  collaboratorId,
  startDate,
  endDate,
  status,
  workOrigin,
}) {
  yield {
    action: SEARCH_ATTENDANCE_DATA,
    sideEffect: getSpecificCollaboratorAttendanceApi({
      pageNumber,
      companyId,
      isAdmin,
      collaboratorId,
      startDate,
      endDate,
      status,
      workOrigin,
    }),
  };
};

const getAllCollaboratorAttendanceGenerator = function* ({
  pageNumber,
  companyId,
  isAdmin,
  collaboratorId,
}) {
  yield {
    action: FETCH_ALL_ATTENDANCE,
    sideEffect: getAllCollaboratorAttendanceApi({
      pageNumber,
      companyId,
      isAdmin,
      collaboratorId,
    }),
  };
};

const updateSubordinateAttendanceStatusGenerator = function* ({
  companyId,
  attendanceId,
  subordinateId,
  status,
}) {
  yield {
    action: UPDATE_SUBORDINATE_ATTENDANCE_STATUS,
    sideEffect: updateSubordinateAttendanceStatusApi({
      companyId,
      attendanceId,
      subordinateId,
      status,
    }),
  };
};

export const resetTableData = () => ({
  type: RESET_ATTENDANCE_DATA,
});

export const setSearchData = ({ startDate, endDate, status, workOrigin }) => ({
  type: SET_SEARCH_DATA,
  payload: { startDate, endDate, status, workOrigin },
});

export const updateCollaboratorId = (id) => ({
  type: UPDATE_COLLABORATOR_ID,
  payload: { id },
});

export const [
  getFollowSubordinates,
  getAllCollaboratorAttendance,
  getSpecificCollaboratorAttendance,
  updateSubordinateAttendanceStatus,
] = createAsyncActionCreator([
  getFollowSubordinatesGenerator,
  getAllCollaboratorAttendanceGenerator,
  getSpecificCollaboratorAttendanceGenerator,
  updateSubordinateAttendanceStatusGenerator,
]);
