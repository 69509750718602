import React from "react";
import Form from "./Form";
import { MainContainer } from "../../style/create";
import { useParams } from "react-router";


const AccessAssetsCreateView = () => {
  const params = useParams()
  return (
    <MainContainer>
      <Form editId={params.id} />
    </MainContainer>
  );
};

export default AccessAssetsCreateView;
