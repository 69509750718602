import React from "react";

import { PostCreateModal as PostModal } from "components/Posts";

import useCreatePost from "../../../../hooks/Posts/useCreatePost";

function PostCreateModal({ onClose }) {
  const { createPost, createPoll, isLoading, createQuiz } = useCreatePost();

  const onCreatePost = (values) => {
    createPost(values, (success) => success && onClose());
  };
  const onCreatePoll = (values) => {
    createPoll(values, (success) => success && onClose());
  };

  const onCreateQuiz = (values) => {
    createQuiz(values, (success) => success && onClose());
  };

  return (
    <PostModal
      onClose={onClose}
      onCreatePost={onCreatePost}
      onCreatePoll={onCreatePoll}
      onCreateQuiz={onCreateQuiz}
      isLoading={isLoading}
    />
  );
}

export default PostCreateModal;
