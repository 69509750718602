import {
  GET_HOLIDAY_YEARS,
  CREATE_HOLIDAY,
  GET_HOLIDAYS,
  GET_HOLIDAYS_BY_YEAR,
  UPDATE_YEAR_ID,
  SEARCH_HOLIDAYS,
  UPDATE_SEARCH_PARAMS,
  RESET_SEARCH_PARAMS,
  EDIT_HOLIDAY,
  DELETE_HOLIDAY,
} from "./actiontypes";
import {
  getHolidayYearsApi,
  createHolidayApi,
  getHolidaysApi,
  getHolidaysByYearApi,
  searchHolidaysApi,
  editHolidayApi,
  deleteHolidayApi,
} from "./api";

import { createAsyncActionCreator } from "helper";

const getHolidayYearsGenerator = function* ({ companyId, isAdmin }) {
  yield {
    action: GET_HOLIDAY_YEARS,
    sideEffect: getHolidayYearsApi({ companyId, isAdmin }),
  };
};

const createHolidayGenerator = function* ({ companyId, isAdmin, formData }) {
  yield {
    action: CREATE_HOLIDAY,
    sideEffect: createHolidayApi({ companyId, isAdmin, formData }),
  };
};

const editHolidayGenerator = function* ({
  holidayId,
  companyId,
  isAdmin,
  formData,
}) {
  yield {
    action: EDIT_HOLIDAY,
    sideEffect: editHolidayApi({ holidayId, companyId, isAdmin, formData }),
  };
};

const deleteHolidayGenerator = function* ({ holidayId, companyId, isAdmin }) {
  yield {
    action: DELETE_HOLIDAY,
    sideEffect: deleteHolidayApi({ holidayId, companyId, isAdmin }),
  };
};

const getHolidaysGenerator = function* ({ companyId, isAdmin, pageNumber }) {
  yield {
    action: GET_HOLIDAYS,
    sideEffect: getHolidaysApi({ companyId, isAdmin, pageNumber }),
  };
};

const getHolidaysByYearGenerator = function* ({
  companyId,
  isAdmin,
  pageNumber,
  currentYearId,
}) {
  yield {
    action: GET_HOLIDAYS_BY_YEAR,
    sideEffect: getHolidaysByYearApi({
      companyId,
      isAdmin,
      pageNumber,
      currentYearId,
    }),
  };
};

export const updateYearId = ({ holiday_year_id }) => ({
  type: UPDATE_YEAR_ID,
  payload: { holiday_year_id },
});

const searchHolidaysGenerator = function* ({
  companyId,
  isAdmin,
  pageNumber,
  currentYearId,
  currentFromDate,
  currentToDate,
  currentIsOptional,
}) {
  yield {
    action: SEARCH_HOLIDAYS,
    sideEffect: searchHolidaysApi({
      companyId,
      isAdmin,
      pageNumber,
      currentYearId,
      currentFromDate,
      currentToDate,
      currentIsOptional,
    }),
  };
};

export const updateSearchParams = ({ from_date, to_date, is_optional }) => ({
  type: UPDATE_SEARCH_PARAMS,
  payload: { from_date, to_date, is_optional },
});

export const resetSearchParams = () => ({
  type: RESET_SEARCH_PARAMS,
});

export const [
  getHolidayYears,
  createHoliday,
  editHoliday,
  getHolidays,
  getHolidaysByYear,
  searchHolidays,
  deleteHoliday,
] = createAsyncActionCreator([
  getHolidayYearsGenerator,
  createHolidayGenerator,
  editHolidayGenerator,
  getHolidaysGenerator,
  getHolidaysByYearGenerator,
  searchHolidaysGenerator,
  deleteHolidayGenerator,
]);
