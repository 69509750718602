import React from "react";
import { withTheme } from "styled-components";

const Icon = ({ color, theme, ...otherProps }) => {
  return (
    <svg
      {...otherProps}
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill={theme.colors.primaryColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          d="M11.1144 0.221954C11.4493 0.221954 11.7261 0.47214 11.77 0.796739L11.776 0.886979V3.03279C11.776 3.40007 11.4798 3.69782 11.1144 3.69782C10.7795 3.69782 10.5027 3.44763 10.4589 3.12303L10.4528 3.03279V0.886979C10.4528 0.519696 10.749 0.221954 11.1144 0.221954Z"
          fill={theme.colors.primaryColor}
        />
        <path
          d="M11.1144 12.2011C11.4493 12.2011 11.7261 12.4513 11.77 12.7759L11.776 12.8661V14.6608C11.776 15.0281 11.4798 15.3258 11.1144 15.3258C10.7795 15.3258 10.5027 15.0756 10.4589 14.751L10.4528 14.6608V12.8661C10.4528 12.4988 10.749 12.2011 11.1144 12.2011Z"
          fill={theme.colors.primaryColor}
        />
        <path
          d="M11.1144 4.88004C11.4493 4.88004 11.7261 5.13022 11.77 5.45482L11.776 5.54506V9.81984C11.776 10.1871 11.4798 10.4849 11.1144 10.4849C10.7795 10.4849 10.5027 10.2347 10.4589 9.91008L10.4528 9.81984V5.54506C10.4528 5.17778 10.749 4.88004 11.1144 4.88004Z"
          fill={theme.colors.primaryColor}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.57162 0H15.3939C17.3641 0 18.9646 1.58847 18.9646 3.55261L18.9655 5.8523C18.9657 6.21969 18.6694 6.51759 18.3039 6.51759C17.6042 6.51759 17.0454 7.07264 17.0454 7.75953C17.0454 8.44615 17.6039 9.00057 18.3039 9.00057C18.6693 9.00057 18.9655 9.29831 18.9655 9.66559V11.9644C18.9655 13.9291 17.3656 15.5172 15.3948 15.5172H3.5707C1.59987 15.5172 5.12332e-08 13.9291 5.12332e-08 11.9644V9.66559C5.12332e-08 9.29831 0.296203 9.00057 0.661588 9.00057C1.36159 9.00057 1.92009 8.44615 1.92009 7.75953C1.92009 7.09565 1.38446 6.59248 0.661588 6.59248L0.57179 6.58641C0.248786 6.54235 -0.000130377 6.26397 5.12332e-08 5.9272L0.000920074 3.55288C0.000920074 1.58847 1.60143 0 3.57162 0ZM15.5419 1.33478L15.3939 1.33005H3.57163C2.32846 1.33005 1.32411 2.32685 1.32411 3.55313L1.3223 5.34236L1.39765 5.36134C2.42255 5.64533 3.17539 6.51352 3.23892 7.6088L3.24327 7.75953C3.24327 8.93001 2.46422 9.91238 1.39502 10.2259L1.32319 10.244V11.9644C1.32319 13.1417 2.24828 14.1071 3.42266 14.1825L3.57071 14.1872H15.3948C16.6386 14.1872 17.6424 13.1907 17.6424 11.9644V10.2449L17.5705 10.2259C16.5478 9.92602 15.7906 9.01417 15.7266 7.9111L15.7223 7.75953C15.7223 6.58899 16.5014 5.60604 17.5705 5.29232L17.6415 5.2732L17.6414 3.55288C17.6414 2.37589 16.7158 1.41016 15.5419 1.33478Z"
        fill={theme.colors.primaryColor}
      />
    </svg>
  );
};

export const SlipIcon = withTheme(Icon);
