import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CheckBoxGroup from "components/Input/CheckBoxGroup";

import { getAllCalendars } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { getCalendarList } from "../../selector";

import useAlert from "hooks/useAlert";

import { CalendarListContainer, ListLoader } from "../../styles/display";

const CalendarList = ({
  companyId,
  getAllCalendars,
  calendarsOptions,
  selectedCalendars,
  onChange,
  onceFetched,
  isAdmin,
  style,
}) => {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();
  const isFirstTimeUpdatedRef = useRef(false);

  useEffect(async () => {
    if (onceFetched) {
      return;
    }
    try {
      updateLoading(true);
      await getAllCalendars({ companyId, isAdmin });
    } catch (err) {
      showAlert({ message: err, type: "error" });
    } finally {
      updateLoading(false);
    }
  }, [companyId, onceFetched]);

  const onCalendarChange = useCallback(
    (e) => {
      if (typeof onChange === "function") {
        onChange({ calendar: e.target.value });
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (isFirstTimeUpdatedRef.current || !calendarsOptions.length) {
      return;
    }

    const res = {};
    calendarsOptions.forEach((item) => {
      res[item.value] = true;
    });

    if (typeof onChange === "function") {
      onChange({ calendar: res });
    }
    isFirstTimeUpdatedRef.current = true;
  }, [calendarsOptions]);

  return (
    <CalendarListContainer style={style}>
      {isLoading ? (
        <ListLoader primary size="s" />
      ) : (
        <CheckBoxGroup
          fieldType="checkboxGroup"
          groupName="calendarType"
          id="calendar_type"
          value={selectedCalendars}
          options={calendarsOptions}
          onChange={onCalendarChange}
        />
      )}
    </CalendarListContainer>
  );
};

const mapStateToProps = (store, props) => {
  return {
    companyId: getCurrentUserCompany(store, props),
    calendarsOptions: getCalendarList(store, props),
    isAdmin: isCurrentUserIsExecutive(store, props),
    onceFetched: store.calendar.list.onceFetched,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllCalendars,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CalendarList);
