import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import useAlert from "hooks/useAlert";

import {
  createTeamsWorklog,
  editTeamsWorklog,
} from "../Views/Tabs/Worklog/redux/actionCreator";

function useCreateEditWorkLog() {
  const dispatch = useDispatch();
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const logWork = async (
    values,
    onFormReset,
    id,
    isEditMode = false,
    modelableId
  ) => {
    try {
      updateLoading(true);
      if (isEditMode) {
        await dispatch(
          editTeamsWorklog({
            companyId,
            isAdmin,
            modelableId,
            formData: values,
            dailyReportId: id,
          })
        );
      } else {
        await dispatch(
          createTeamsWorklog({
            companyId,
            isAdmin,
            formData: values,
            modelableId: id,
          })
        );
      }

      showAlert({ message: `Work log ${isEditMode ? "updated" : "added"}` });
      typeof onFormReset === "function" && onFormReset();
    } catch (err) {
      showAlert({ message: err[0], type: "error" });
    } finally {
      updateLoading(false);
    }
  };
  return { isLoading, logWork };
}

export default useCreateEditWorkLog;
