import React from "react";
import {
  AutoCompleteDropdownContainer,
  SuggestionItem,
  SuggestionLoaderContainer,
} from "../../styles/autocomplete";

const LocationSearchDropdown = ({ suggestions = [], loading }) => {
  return (
    <AutoCompleteDropdownContainer
      areSuggestionsLoaded={suggestions.length > 0}
    >
      {loading && <SuggestionLoaderContainer secondary size="s" />}
      {suggestions?.map((suggestion) => {
        return (
          <SuggestionItem active={suggestion.active}>
            {suggestion.description}
          </SuggestionItem>
        );
      })}
    </AutoCompleteDropdownContainer>
  );
};

export default LocationSearchDropdown;
