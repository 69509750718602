import requestData from "../../../../ApiHandler";
import { createAuthUrl } from "helper";

export const fetchPeopleOnLeaveTodayApi = ({
  companyId,
  isAdmin,
  pageNumber,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_on_leave_collaborators?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const fetchPeoplePresentTodayApi = ({
  companyId,
  isAdmin,
  pageNumber,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_presentees?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const fetchPeopleAbsentTodayApi = ({
  companyId,
  isAdmin,
  pageNumber,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_absentees?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
