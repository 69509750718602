import {
  FETCH_ALL_ATTENDANCE,
  GET_WORKING_SHIFT,
  MARK_SIGN_IN,
  MARK_SIGN_OUT,
  SEARCH_ATTENDANCE_DATA,
  RESET_ATTENDANCE_DATA,
  SET_SEARCH_DATA,
  GET_PRESENTEES,
  GET_ABSENTEES,
  RESET_ABSENTEES,
  RESET_PRESENTEES,
  CREATE_WORK_LOG,
  UPDATE_ATTENDANCE_ID,
  EDIT_WORK_LOG,
  GET_WORKING_HOUR_LOG_ACTIVITY,
  CLEAR_WORKING_HOUR_LOG_ACTIVITY,
} from "./actiontypes";

import { createAsyncActionCreator } from "../../Helper";
import {
  getAbsentees,
  getAllAttendanceApi,
  getCurrentWorkingShiftApi,
  getPresentees,
  getSpecificAttendanceApi,
  markSignInApi,
  markSignOutApi,
  createWorkLogApi,
  editWorkLogApi,
  getWorkingHourLogActivityApi,
} from "./api";

const getSpecificAttendanceGenrator = function* ({
  pageNumber,
  companyId,
  isAdmin,
  startDate,
  endDate,
  status,
  workOrigin,
}) {
  yield {
    action: SEARCH_ATTENDANCE_DATA,
    sideEffect: getSpecificAttendanceApi({
      pageNumber,
      companyId,
      isAdmin,
      startDate,
      endDate,
      status,
      workOrigin,
    }),
  };
};

const getAllAttendanceGenrator = function* ({
  pageNumber,
  companyId,
  isAdmin,
}) {
  yield {
    action: FETCH_ALL_ATTENDANCE,
    sideEffect: getAllAttendanceApi({
      pageNumber,
      companyId,
      isAdmin,
    }),
  };
};

const getWorkingShiftGenrator = function* ({ companyId, isAdmin }) {
  yield {
    action: GET_WORKING_SHIFT,
    sideEffect: getCurrentWorkingShiftApi({
      companyId,
      isAdmin,
    }),
  };
};

const markSignOutGenrator = function* ({ companyId, isAdmin, workHourLogId }) {
  yield {
    action: MARK_SIGN_OUT,
    sideEffect: markSignOutApi({
      companyId,
      isAdmin,
      workHourLogId,
    }),
  };
};

const markSignInGenrator = function* ({ companyId, isAdmin, workOrigin }) {
  yield {
    action: MARK_SIGN_IN,
    sideEffect: markSignInApi({
      companyId,
      isAdmin,
      workOrigin,
    }),
  };
};

export const resetTableData = () => ({
  type: RESET_ATTENDANCE_DATA,
});

export const setSearchData = ({ startDate, endDate, status }) => ({
  type: SET_SEARCH_DATA,
  payload: { startDate, endDate, status },
});

const getPresenteesGenerator = function* ({ pageNumber, companyId, isAdmin }) {
  yield {
    action: GET_PRESENTEES,
    sideEffect: getPresentees({
      pageNumber,
      companyId,
      isAdmin,
    }),
  };
};

const getAbsenteesGenerator = function* ({ pageNumber, companyId, isAdmin }) {
  yield {
    action: GET_ABSENTEES,
    sideEffect: getAbsentees({
      pageNumber,
      companyId,
      isAdmin,
    }),
  };
};

const createWorkLogGenerator = function* ({
  companyId,
  isAdmin,
  formData,
  modelableId,
  modelableType,
}) {
  yield {
    action: CREATE_WORK_LOG,
    sideEffect: createWorkLogApi({
      companyId,
      isAdmin,
      formData,
      modelableId,
      modelableType,
    }),
  };
};

const getWorkingHourLogActivityGenerator = function* ({
  companyId,
  isAdmin,
  modelableId,
}) {
  yield {
    action: GET_WORKING_HOUR_LOG_ACTIVITY,
    sideEffect: getWorkingHourLogActivityApi({
      companyId,
      isAdmin,
      modelableId,
    }),
  };
};

export const resetAbsentees = () => ({
  type: RESET_ABSENTEES,
});

export const resetPresentees = () => ({
  type: RESET_PRESENTEES,
});

export const updateAttendanceId = ({ attendanceId }) => ({
  type: UPDATE_ATTENDANCE_ID,
  payload: { attendanceId },
});

const editWorkLogGenerator = function* ({
  companyId,
  isAdmin,
  formData,
  dailyReportId,
  modelableId,
  modelableType,
}) {
  yield {
    action: EDIT_WORK_LOG,
    sideEffect: editWorkLogApi({
      companyId,
      isAdmin,
      formData,
      dailyReportId,
      modelableId,
      modelableType,
    }),
  };
};

export const clearWorkinkingHourLogActivity = () => ({
  type: CLEAR_WORKING_HOUR_LOG_ACTIVITY,
});

export const [
  getAllAttendance,
  getSpecificAttendance,
  getCurrentWorkingShift,
  markSignOut,
  markSignIn,
  getAllAbsentees,
  getAllPresentees,
  createWorkLog,
  editWorkLog,
  getWorkingHourLogActivity,
] = createAsyncActionCreator([
  getAllAttendanceGenrator,
  getSpecificAttendanceGenrator,
  getWorkingShiftGenrator,
  markSignOutGenrator,
  markSignInGenrator,
  getAbsenteesGenerator,
  getPresenteesGenerator,
  createWorkLogGenerator,
  editWorkLogGenerator,
  getWorkingHourLogActivityGenerator,
]);
