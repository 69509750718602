import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { getAllLeaves } from "../redux/selector";

import LeaveView from "./leaveView";
import Routes from "route/Path";

const TableData = ({ activeTab, onEmptyData, ...other }) => {
  const history = useHistory();

  const data = useSelector((store) =>
    getAllLeaves(store, { activeTab: activeTab })
  );

  const onClick = (leaveId) => {
    history.push(
      `${Routes.authorized.leaveManagement.details.path}/${activeTab}/${leaveId}`
    );
  };

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (!data.length) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [data]);

  return (
    <>
      {data.map((leave) => {
        return (
          <LeaveView leave={leave} onClick={onClick} activeTab={activeTab} />
        );
      })}
    </>
  );
};

export default TableData;
