import { FETCH_TODAY_HOLIDAY } from "./actionTypes";
import { createNewStoreOnGetData, createInitialStoreState } from "helper";

const todayInitialState = createInitialStoreState({});

function holidayReducer(store = todayInitialState, action) {
  const { type, payload } = action;
  switch (type) {
    case `${FETCH_TODAY_HOLIDAY}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    default:
      return store;
  }
}
export default holidayReducer;
