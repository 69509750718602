import {
  tranformMatrixToArray,
  getDistanceBetweenTwoDateInWords,
} from "helper/index";
import { IMAGE_URL } from "../../config.local";

export const transformPostsMatrixToArray = (data, rowIdMap) => {
  if (!data || !data[0]) {
    return null;
  }
  const result = tranformMatrixToArray(data, rowIdMap);
  return transformPostsRawDataToPostData(result);
};

const coverPhotoUrlToActualUrl = (data) => {
  if (!Array.isArray(data)) {
    return null;
  }

  return data.map(({ url }) => {
    return `${IMAGE_URL}${url}`;
  });
};

export const transformPostsRawDataToPostData = (data) => {
  const result = [];
  data.forEach(
    ({
      regularPost,
      collaborator,
      pollPost,
      qaPost,
      currentCollaboratorReaction,
      id,
      commentCount,
      latestComments,
      reactionCount,
      createdAt: postCreatedAt,
    }) => {
      const postComments = createPostComments(latestComments);

      const post = {
        id,
        userName: collaborator.name,
        userImage: collaborator.avatarUrl,
        images: regularPost && coverPhotoUrlToActualUrl(regularPost.photosUrls),
        description: regularPost && regularPost.description,
        video: regularPost && regularPost.videoUrl,
        totalCommentsCount: commentCount === "NA" ? 0 : commentCount,
        reactionData: {
          reactionType:
            currentCollaboratorReaction === "NA"
              ? ""
              : currentCollaboratorReaction?.reactionType,
          reactionId:
            currentCollaboratorReaction === "NA"
              ? ""
              : currentCollaboratorReaction?.id,
          reactionCount: reactionCount === "NA" ? 0 : reactionCount,
        },
        comments: postComments,
        postTime: `${getDistanceBetweenTwoDateInWords(
          postCreatedAt,
          new Date()
        )} ago`,
        pollData: null,
        collaboratorId: collaborator.id
      };

      if (pollPost && pollPost !== "NA") {
        post.pollData = createPollPostData(pollPost);
        post.description = pollPost.description;
      }

      if (qaPost && qaPost !== "NA") {
        post.quizData = createQuizData(qaPost);
      }

      result.push(post);
    }
  );
  return result;
};

const createPollPostData = (pollPost) => {
  const today = new Date();
  const pollLastData = new Date(pollPost.enableTill);
  return {
    heading: pollPost.question,
    timeLeft:
      pollPost.enableTill && pollLastData >= today
        ? getDistanceBetweenTwoDateInWords(today, pollLastData)
        : "",
    votes: Array.isArray(pollPost.pollPostOptions)
      ? pollPost.pollPostOptions.reduce(
          (prev, curr) => prev + curr.answerCount,
          0
        )
      : 0,
    enabled: pollPost.isEnable,
    options: Array.isArray(pollPost.pollPostOptions)
      ? pollPost.pollPostOptions.map(({ option, id }) => ({
          name: option,
          value: id,
        }))
      : null,
    pollStatistics: Array.isArray(pollPost.pollPostOptions)
      ? pollPost.pollPostOptions.map(({ option, id, answerCount }) => ({
          name: option,
          value: Number(answerCount),
          optionId: id,
        }))
      : null,
    answereOptionId: pollPost.currentCollaboratorPollPostAnswer
      ? pollPost.currentCollaboratorPollPostAnswer.pollPostOptionId
      : null,
  };
};

const createPostComments = (latestComments) => {
  let postComments = [];

  if (Array.isArray(latestComments)) {
    postComments = latestComments.map((item) => {
      const { collaborator, id, message, createdAt } = item;
      return {
        id: id,
        userName: collaborator.name,
        userImage: collaborator?.avatarUrl?.url,
        designation: collaborator.role,
        comment: message,
        time: `${getDistanceBetweenTwoDateInWords(createdAt, new Date())} ago`,
        collaboratorId: collaborator.id,
      };
    });
  }

  return postComments;
};

const createQuizData = (qaPost) => {
  let correctOptionValue = "";
  let options = [];
  if (Array.isArray(qaPost.qaPostOptions)) {
    qaPost.qaPostOptions.map(({ option, correctOptionFlag, id }) => {
      if (correctOptionFlag) {
        correctOptionValue = id;
      }
      options.push({
        name: option,
        value: id,
      });
    });
  }
  return {
    question: qaPost.question,
    votes: 2000,
    enabled: true,
    options,
    correctOptionValue,
    answeredValue: qaPost.currentCollaboratorQaPostAnswer?.qaPostOptionId || "",
  };
};

export const transformCollaboratorMatrixToArray = (data, rowIdMap) => {
  if (!data || !data[0]) {
    return null;
  }
  return tranformMatrixToArray(data, rowIdMap);
};

export const transformCommentsMatrixToArray = (data, rowIdMap) => {
  if (!data || !data[0]) {
    return null;
  }
  const result = tranformMatrixToArray(data, rowIdMap);
  return transformCommentRawDataToCommentData(result);
};

const transformCommentRawDataToCommentData = (data) => {
  return data.map(({ id, message, collaborator }) => {
    return {
      id,
      userName: collaborator.name || "",
      userImage: collaborator?.avatarUrl?.url,
      designation: collaborator.role || "",
      comment: message,
    };
  });
};
