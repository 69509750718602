import React from "react";
import Icon from "components/Icon";
import { ControlArea, DateText, ArrowButton } from "../../styles/display";
const ControlButtons = ({ currentDate, onPrevDateClick, onNextDateClick }) => {
  return (
    <ControlArea>
      <ArrowButton
        style={{ backgroundColor: "transparent", fontSize: "15px" }}
        onClick={onPrevDateClick}
      >
        <Icon name="fas fa-chevron-left" />
      </ArrowButton>

      <ArrowButton
        style={{
          marginLeft: "10px",
          backgroundColor: "transparent",
          fontSize: "15px",
        }}
        onClick={onNextDateClick}
      >
        <Icon name="fas fa-chevron-right" />
      </ArrowButton>

      <DateText>{currentDate}</DateText>
    </ControlArea>
  );
};
export default ControlButtons;
