import { useState, useEffect, useRef, useMemo, useCallback } from "react";

import useWindowDimensions from "./useWindowDimensions";
import useDrawer from "./useDrawer";

const useGrid = ({ data, dataKey, onClick, requiredColumns = 1 }) => {
  const containerRef = useRef();
  const [dimensions, updateDimensions] = useState({ width: 0, height: 0 });
  const { dimesnions: windowDimensions } = useWindowDimensions();
  const { isDrawerActive } = useDrawer();

  useEffect(() => {
    updateDimensions({ width: 0, height: 0 });
  }, [isDrawerActive, windowDimensions]);

  useEffect(() => {
    let id = null;
    id = setTimeout(() => {
      const container = containerRef.current;
      const { clientWidth, clientHeight } = container;
      updateDimensions({ width: clientWidth, height: clientHeight });
    }, 500);

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [windowDimensions, isDrawerActive, data]);

  const onCellClick = useCallback(
    (rowNumber) => {
      if (typeof onClick !== "function" || !rowNumber) {
        return;
      }
      const actualData = dataKey ? data[dataKey] : data;
      onClick({ data: actualData[rowNumber], columns: actualData[0] });
    },
    [data, dataKey, onClick]
  );

  const actualColumnWidth = useMemo(() => {
    return dimensions.width / requiredColumns;
  }, [dimensions, requiredColumns]);

  return {
    onCellClick,
    actualColumnWidth,
    dimensions,
    containerRef,
  };
};

export default useGrid;
