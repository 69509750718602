import { createSelector } from "reselect";

const holidayYearsData = (store, props) =>
  store.holidays.holidayYears?.data || [];

export const getHolidayYearsOptions = createSelector(
  holidayYearsData,
  (yearData) => {
    let options = [];
    Object.keys(yearData).forEach((key) => {
      if (typeof yearData[key] === "object") {
        options.push({
          name:
            yearData[key].attributes.startDate +
            " to " +
            yearData[key].attributes.endDate,

          value: yearData[key].id,
          startDate: yearData[key].attributes.startDate,
          endDate: yearData[key].attributes.endDate,
        });
      }
    });
    return options;
  }
);
