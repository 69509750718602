import Route from "route/Path";

import Profile_Img from "assets/Profile.svg";
import Dep_Img from "assets/Activity.svg";
import Not_Img from "assets/Document.svg";
import Job_Img from "assets/Bookmark.svg";
import Asset_Img from "assets/Folder.svg";
import Calendar_Img from "assets/Calendar.svg";
import Reimbursement_Img from "assets/Ticket.svg";
import Helpdesk_Img from "assets/Helpdesk.svg";
import Break_Img from "assets/Tea.svg";
import Team_Img from "assets/Team.svg";

const AuthPaths = Route.authorized;

export const data = {
  attendance: {
    backgroundColor: "#e0e5fd",
    color: "#6f7cb8",
    overlay: "#6f7cb8",
    to: AuthPaths.attendence.view.path,
  },
  department: {
    desc: "Department Activity",
    icon: Dep_Img,
    backgroundColor: "#e0e5fd",
    color: "#6f7cb8",
    overlay: "#6f7cb8",
    to: AuthPaths.department.view.path,
  },
  notice: {
    desc: "Send & Manage Notice",
    icon: Not_Img,
    backgroundColor: "#fbebe0",
    color: "#c17744",
    overlay: "#c17744",
    to: AuthPaths.notice.view.path,
  },
  jobTitle: {
    desc: "Add & manage job title",
    icon: Job_Img,
    backgroundColor: "#fae2fb",
    color: "#bb79be",
    overlay: "#bb79be",
    to: AuthPaths.jobTitle.view.path,
  },
  user: {
    desc: "Manage your users",
    icon: Profile_Img,
    backgroundColor: "#e7fdfa",
    color: "#00af97",
    overlay: "#189584",
    to: AuthPaths.user.view.path,
  },
  accessAssets: {
    desc: "Manage all your access and assets",
    icon: Asset_Img,
    backgroundColor: "#fffde7",
    color: "#e5b300",
    overlay: "#e5b300",
    to: AuthPaths.accessAssets.view.path,
  },
  holiday: {
    desc: "Holiday",
    icon: Dep_Img,
    backgroundColor: "#e0e5fd",
    color: "#6f7cb8",
    overlay: "#6f7cb8",
    to: AuthPaths.holiday.view.path,
  },
  holidayYear: {
    desc: "Holiday Year",
    icon: Not_Img,
    backgroundColor: "#fae2fb",
    color: "#bb79be",
    overlay: "#bb79be",
    to: AuthPaths.holidayYear.view.path,
  },
  calendar: {
    desc: "See all your events",
    icon: Calendar_Img,
    backgroundColor: "#e0e5fd",
    color: "#6f7cb8",
    overlay: "#6f7cb8",
    to: AuthPaths.calendar.view.path,
  },
  reimbursement: {
    desc: "Manage your reimbursements",
    icon: Reimbursement_Img,
    backgroundColor: "#e7fdfa",
    color: "#00af97",
    overlay: "#00af97",
    to: AuthPaths.reimbursement.view.path,
  },
  profile: {
    desc: "See your profile",
    icon: Profile_Img,
    backgroundColor: "#e7fdfa",
    color: "#00af97",
    overlay: "#189584",
    to: AuthPaths.profile.view.path,
  },
  followSubordinate: {
    desc: "See your subordinates",
    icon: Not_Img,
    backgroundColor: "#fae2fb",
    color: "#bb79be",
    overlay: "#bb79be",
    to: AuthPaths.followSubordinate.view.path,
  },
  helpdesk: {
    desc: "Manage your enquiries",
    icon: Helpdesk_Img,
    backgroundColor: "#e7fdfa",
    color: "#00af97",
    overlay: "#00af97",
    to: AuthPaths.helpdesk.view.path,
  },
  breaks: {
    desc: "Manage your Breaks",
    icon: Break_Img,
    backgroundColor: "#e0e5fd",
    color: "#6f7cb8",
    overlay: "#6f7cb8",
    to: AuthPaths.breaks.view.path,
  },
  companyBranch: {
    desc: "Manage your company branches",
    icon: Dep_Img,
    backgroundColor: "#fffde7",
    color: "#e5b300",
    overlay: "#e5b300",
    to: AuthPaths.companyBranch.view.path,
  },
  teams: {
    desc: "Manage your team",
    icon: Team_Img,
    backgroundColor: "#fffde7",
    color: "#e5b300",
    overlay: "#e5b300",
    to: AuthPaths.teams.view.path,
  },
  leaves: {
    desc: "Manage leaves",
    icon: Calendar_Img,
    backgroundColor: "#fbebe0",
    color: "#c17744",
    overlay: "#c17744",
    to: AuthPaths.leaves.view.path,
  },
};
