import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";

import { PaginatorBox, StyledActionArea } from "../../../../GlobalStyles";
import Paginator from "components/Paginator";
import usePagination from "hooks/usePagination";
import usePaginator from "hooks/usePaginator";

import { getAccessAssets, searchAccessAsset } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";

import SearchFilter from "./SearchFilter";

import { EnhancedActionArea, SearchArea } from "../../style/display";

const ActionArea = ({
  assignedTo,
  category,
  currentFromDate,
  currentToDate,
  currentStatus,
  isSearchOn,
  onceFetched,
  onLoading,
  style,
  companyId,
  isAdmin
}) => {
  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: assignedTo || category || currentFromDate || currentToDate || currentStatus ? searchAccessAsset : getAccessAssets,
    tableName: "accessAssets",
  });

  const extraParams = { companyId, assignedTo, category, currentFromDate, currentToDate, currentStatus, isAdmin }

  const { fetchFirstPage, fetchNextPage, fetchPrevPage, fetchSpecificPage } =
    usePaginator({ extraParams, paginatorApi: getNextPage });

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
  }, [onceFetched]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched && !isSearchOn) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <EnhancedActionArea style={style}>
      <SearchArea>
        <SearchFilter
          assigned_to_id={assignedTo}
          category={category}
          from_date={currentFromDate}
          to_date={currentToDate}
          status={currentStatus}
          isAdmin={isAdmin}
        />
      </SearchArea>
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    onceFetched: store.accessAssets.onceFetched,
    assignedTo: store.accessAssets.assignedTo,
    category: store.accessAssets.category,
    currentFromDate: store.accessAssets.currentFromDate,
    currentToDate: store.accessAssets.currentToDate,
    currentStatus: store.accessAssets.currentStatus,
    isSearchOn: store.accessAssets.isSearchOn,
    isAdmin,
  };
};
export default connect(mapStateToProps, null)(ActionArea);
