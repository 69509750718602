import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import { StyledForm } from "../../../style/create"
import { educationOptions } from "../options";

const AcademicForm = ({ formData, isFormValid, onSubmit }) => {
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (isFormValid() && typeof onSubmit === "function") {
      onSubmit();
    }
  };

  return (
    <StyledForm onSubmit={onFormSubmit}>
      <InputGroup
        fieldType="input"
        placeholder='Enter your course name'
        label="Course"
        {...formData["course"]}
      />
      <InputGroup
        fieldType="select"
        placeholder='Enter your education qualification'
        label="Education Qualification"
        defaultSelectText="Select education"
        options={educationOptions}
        {...formData["education"]}
      />
      <InputGroup
        fieldType="input"
        placeholder='e.g. 95, 10 (GPA)'
        label="Div / class / grade with (%)"
        {...formData["grade"]}
      />
      <InputGroup
        fieldType="input"
        placeholder='Enter institution name'
        label="Institution / university"
        {...formData["institution"]}
      />
      <InputGroup
        fieldType="input"
        placeholder='Enter your specialization'
        label="Specialization"
        {...formData["specialization"]}
      />
      <InputGroup
        fieldType="input"
        placeholder='e.g. 20XX'
        label="Year of passing"
        {...formData["yearOfPassing"]}
      />
      <InputGroup
        fieldType="googleFilePicker"
        label="Upload degree"
        {...formData["degreeDocument"]}
      />

      <Button primary type="submit">
        Create
      </Button>
    </StyledForm>
  )
}

export default AcademicForm;