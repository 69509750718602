import React from "react";

import { EmptyResultBox, EmptyResultText } from "./style";

const EmptyResult = () => {
  return (
    <EmptyResultBox>
      <EmptyResultText>Empty Data !!</EmptyResultText>
    </EmptyResultBox>
  );
};

export default EmptyResult;
