import React, { useEffect, useContext, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import AuthRoutes from "./Authorized";
import UnAuthorizedRoutes from "./UnAuthorized";
import Routes from "./Path";

import { ThemeContext } from "context/ThemeContext";

import OnlyWhenAuthenticated from "../Common/Components/Auth/OnlyWhenAuthenticated";
import WhenNotAuthenticated from "../Common/Components/Auth/WhenNotAuthenticated";

import {
  isCurrentUserIsExecutive,
  isUserLoggedIn,
  getCurrentUserCompany,
  isLoggedInAsAdmin,
} from "../Common/Selectors/auth";

import listenToNotifications, {
  authorize,
  getToken,
} from "../Firebase/notification";
import { useAttendanceFunctions } from "modules/Attendence/Hooks/useAttendanceFunctions";
import { AttendanceSignInModal } from "modules/Attendence/Views/Display/SignInModal";
import { getDateDetails } from "helper/date";

const WelcomePaths = Routes.authorized.welcome;

const authorizedPathNames = {};
const unAuthorizedPathNames = {};

Object.keys(Routes.authorized).forEach((key) => {
  const tabs = Routes.authorized[key];

  Object.keys(tabs).forEach((page) => {
    const item = tabs[page];
    authorizedPathNames[item.path] = true;
  });
});

Object.keys(Routes.unAuthorized).map((key) => {
  const page = Routes.unAuthorized[key];
  unAuthorizedPathNames[page.path] = true;
});

const RootRoute = () => {
  const updateTheme = useContext(ThemeContext);
  const isLoggedIn = useSelector(isUserLoggedIn);
  const isExecutive = useSelector(isCurrentUserIsExecutive);
  const isAdminTypeLogin = useSelector(isLoggedInAsAdmin);
  const currCompany = useSelector(getCurrentUserCompany);
  const { pathname } = useLocation();
  const history = useHistory();

  const {
    isModalActive: isAttendanceModalActive,
    callSignIn,
    getTodaysWorkingHourLog,
    signInLoadingType,
    toggleModal: toggleAttendanceModal,
  } = useAttendanceFunctions();

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const getWorkingLogData = async () => {
      const result = await getTodaysWorkingHourLog();
      if (Array.isArray(result) && result[0]) {
        const data = result[0].data;
        if (Array.isArray(data) && data.length === 0) {
          //  timer is added because the company selection page takes some time to navigate to dashboard
          setTimeout(() => {
            toggleAttendanceModal();
          }, 5000);
        }
      }
    };

    if (currCompany && !isExecutive) {
      getWorkingLogData();
    }
  }, [isLoggedIn, currCompany]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (isLoggedIn) {
      authorize()
        .then(() => getToken())
        .then(() => listenToNotifications());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      //if company is not selected then redirect to select any company
      if (!currCompany) {
        return;
      }
      // else move to welcome page
      if (unAuthorizedPathNames[pathname]) {
        const adminPath = WelcomePaths.adminWelcome.path;
        const employeePath = WelcomePaths.employeeWelcome.path;
        history.replace(isExecutive ? adminPath : employeePath);
      }
    } else {
      if (authorizedPathNames[pathname]) {
        history.replace("/");
      }
    }
  }, [history, isLoggedIn, pathname, isExecutive, currCompany]);

  useEffect(() => {
    const status =
      typeof isAdminTypeLogin === "boolean" && isLoggedIn
        ? isAdminTypeLogin
          ? "admin"
          : "employee"
        : undefined;
    updateTheme(status);
  }, [isAdminTypeLogin, isLoggedIn]);

  const dateOfToday = useMemo(() => {
    const { todayDate, monthIndex, year } = getDateDetails(Date.now());
    return `${todayDate}/${monthIndex}/${year}`;
  }, []);
  return (
    <>
      <WhenNotAuthenticated>
        <UnAuthorizedRoutes />
      </WhenNotAuthenticated>
      <OnlyWhenAuthenticated>
        <AuthRoutes />
        {!isAdminTypeLogin && (
          <AttendanceSignInModal
            active={isAttendanceModalActive}
            onClose={toggleAttendanceModal}
            heading={`Login for today (${dateOfToday})`}
            onSignIn={callSignIn}
            signInLoadingType={signInLoadingType}
          />
        )}
      </OnlyWhenAuthenticated>
    </>
  );
};

export default RootRoute;
