import { Link } from "react-router-dom";
import styled from "styled-components";

import ProfilePic from "../../ProfilePic";

export const Container = styled.div`
  min-width: 185px;
  display: flex;
  flex-flow: column nowrap;
`;

export const StyledHeader = styled.div`
  flex-basis: 50px;
  background-color: ${(props) => props.theme.colors.primaryColor};
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  padding-left: 80px;
  justify-content: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

export const StyledProfilePic = styled(ProfilePic)`
  position: absolute;
  top: 18px;
  left: 14px;
  border: 2px white solid;
`;

export const ProfileContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  padding-left: 80px;
  padding-right: 10px;
  padding-top: 14px;
  padding-bottom: 10px;
`;

export const UserName = styled.span`
  color: white;
  font-weight: 600;
  font-size: ${(props) => props.theme.text.para.small};
`;

export const UserDesignation = styled.span`
  color: white;
  font-weight: 500;
  font-size: ${(props) => props.theme.text.para.extraSmall};
`;

export const ListItem = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
`;

export const ListIcon = styled.img`
  width: 15px;
  height: 15px;
`;

export const ListText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  margin-left: 5px;
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
  color: #000;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  & img {
    margin-right: 5px;
  }
`;
