import React from "react";
import { withTheme } from "styled-components";

const Icon = ({ color, theme, ...otherProps }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      {...otherProps}
      fill={theme.colors.primaryColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52622 17.8044C3.85333 17.8604 4.20089 17.8889 4.56711 17.8889H11.4338C14.2969 17.8889 16 16.1538 16 13.2587V4.74134V4.67289C15.9733 1.81778 14.2747 0.111115 11.4338 0.111115H4.56711C2.00356 0.111115 0.363556 1.50134 0.0533333 3.888C0.0177778 4.16 0 4.44445 0 4.74134V13.2587C0 15.7156 1.22489 17.3298 3.33244 17.768L3.52622 17.8044ZM1.27729 4.74133C1.27729 2.52888 2.38396 1.40622 4.56707 1.40622H11.4257C13.608 1.40622 14.7075 2.52888 14.7075 4.74133V13.2587C14.7075 15.4711 13.608 16.5938 11.4257 16.5938H4.56707C2.38396 16.5938 1.27729 15.4711 1.27729 13.2587V4.74133Z"
        fill={theme.colors.primaryColor}
      />
      <path
        opacity="0.4"
        d="M10.2014 12.2399H11.6628L8.6235 4.9647H7.15151L4.11222 12.2399H5.58421L6.13488 10.9268H9.65071L10.2014 12.2399ZM6.61142 9.78308L7.8928 6.72261L9.17417 9.78308H6.61142Z"
        fill={theme.colors.primaryColor}
      />
    </svg>
  );
};

export const AttendanceIcon = withTheme(Icon);
