import {
  CREATE_NOTICE,
  PUBLISH_NOTICE,
  GET_DRAFT_NOTICES,
  SET_NOTICE_ID,
  CLEAR_NOTICE_ID,
  UPDATE_DRAFT_NOTICE,
  SEARCH_NOTICES,
  UPDATE_SEARCH_PARAMS,
  RESET_SEARCH_PARAMS,
} from "../actiontypes";
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  removeNoticeFromDraftMode,
  createNewStoreOnUpdate,
} from "../../../Helper";

const initialData = createInitialStoreState({
  currentNoticeId: null,
  isSearchOn: false
});

export const draftNoticesReducer = (store = initialData, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_DRAFT_NOTICES}_SUCCESS`:
      return createNewStoreOnGetData(payload, store);

    case `${CREATE_NOTICE}_SUCCESS`:
      const { data } = payload;
      const currentCreatedNoticeId = Object.keys(data).find(
        (attr) => typeof data[attr] !== "function"
      );
      return createNewStoreOnCreate(payload, {
        ...store,
        currentNoticeId: currentCreatedNoticeId,
      });

    case `${UPDATE_DRAFT_NOTICE}_SUCCESS`:
      return createNewStoreOnUpdate(payload, store);

    case `${PUBLISH_NOTICE}_SUCCESS`:
      if (store.data[store.currentNoticeId]) {
        return removeNoticeFromDraftMode(store);
      }

    case SET_NOTICE_ID:
      const { noticeId } = payload;
      return {
        ...store,
        currentNoticeId: noticeId,
      };

    case CLEAR_NOTICE_ID:
      return {
        ...store,
        currentNoticeId: null,
      };

    case `${SEARCH_NOTICES}_SUCCESS`:
      const { isPublished } = payload;
      if (!isPublished) {
        return createNewStoreOnGetData(payload, store);
      }
      return store;

    case `${UPDATE_SEARCH_PARAMS}`: {
      const { title, is_published, from_date, to_date } = payload;
      if (!is_published && (title || from_date || to_date)) {
        return {
          ...store,
          onceFetched: false,
          currentTitle: title,
          isPublished: is_published,
          currentFromDate: from_date,
          currentToDate: to_date,
          isSearchOn: true
        };
      }
      return store;
    }

    case `${RESET_SEARCH_PARAMS}`: {
      const { is_published } = payload;
      if (!is_published) {
        return {
          ...store,
          onceFetched: false,
          currentTitle: '',
          isPublished: '',
          currentFromDate: '',
          currentToDate: '',
          isSearchOn: false
        };
      }
      return store;
    }

    default:
      return store;
  }
};
