import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Form from "./Form";
import useForm from "hooks/useForm";
import useAlert from "hooks/useAlert";

import { applyForResignation } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

const SeparationCreateForm = ({
  companyId,
  isAdmin,
  applyForResignation,
  onFormSubmitCompleted,
}) => {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();
  const { formData, isFormValid, values, resetForm, updateField } = useForm({
    fields: {
      reason: {
        required: true,
      },
      letter: {
        required: true,
      },
      cc: {
        required: false,
      },
    },
  });

  const onApply = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }
    try {
      updateLoading(true);
      const cc = values?.cc && Object.keys(values.cc);
      await applyForResignation({
        ccIds: cc,
        reason: values.reason || "",
        letter: values.letter || "",
        isAdmin,
        companyId,
      });
      updateLoading(false);
      showAlert({ message: "Successfully applied for separation" });
      resetForm();
      if (typeof onFormSubmitCompleted === "function") {
        onFormSubmitCompleted();
      }
    } catch (err) {
      updateLoading(false);
      showAlert({ message: "Failed to apply for separation", type: "error" });
    }
  };

  return (
    <Form
      formData={formData}
      onApply={onApply}
      isLoading={isLoading}
      updateField={updateField}
    />
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      applyForResignation,
    },
    dispatch
  );

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    isAdmin,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeparationCreateForm);
