function usePaginator({ paginatorApi, extraParams, otherProps = {} }) {
  const fetchFirstPage = () => {
    paginatorApi({
      isFirstPage: true,
      extraParams,
      ...otherProps,
    });
  };

  const fetchNextPage = () => {
    paginatorApi({ extraParams, ...otherProps });
  };
  const fetchPrevPage = () => {
    paginatorApi({
      fetchPrevPage: true,
      extraParams,
      ...otherProps,
    });
  };

  const fetchSpecificPage = (pageNumber) => {
    paginatorApi({
      pageNumber,
      extraParams,
      ...otherProps,
    });
  };

  return { fetchFirstPage, fetchNextPage, fetchPrevPage, fetchSpecificPage };
}

export default usePaginator;
