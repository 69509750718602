import styled from "styled-components";
import Button from "../Button";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  padding-left: 15px;
`;
export const Heading = styled.span`
  font-weight: bold;
  color: black;
  font-size: ${(props) => props.theme.text.para.large};
`;

export const DescriptionContainer = styled.div`
  margin-top: 5px;
  display: flex;
`;
export const Description = styled.div`
  display: flex;
  color: black;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  & + div {
    margin-left: 10px;
  }
  & > span {
    color: gray;
    margin-right: 5px;
  }
  @media (min-width: 1131px) {
    font-size: ${(props) => props.theme.text.para.small};
  }
`;

export const BackButton = styled(Button).attrs(() => ({
  size: "auto",
  variant: "primary",
}))`
  width: 35px;
  height: 35px;
  margin: 8px;
  & > img {
    width: 9px;
    height: 16px;
  }
  border-radius: 6px;
  color: ${(props) => props.theme.colors.button.outline.color};
  background-color: ${(props) => props.theme.colors.button.outline.background};
  border: solid 1px ${(props) => props.theme.colors.button.outline.border};
`;
