import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import useAlert from "hooks/useAlert";

import {
  reactToPost as reactToPostActionCreator,
  removeReactFromPost as removeReactFromPostActionCreator,
} from "../../redux/Posts/actionCreator";

function useReactToPost() {
  const [isLoading, updateLoading] = useState(false);
  const [hasError, updateError] = useState(false);

  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const companyId = useSelector(getCurrentUserCompany);
  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const removeReactFromPost = async (data, callback) => {
    let success = false;
    try {
      const { reactionId, postId } = data;

      if (!reactionId) return;

      updateLoading(true);
      await dispatch(
        removeReactFromPostActionCreator({
          companyId,
          isAdmin,
          formData: {
            postId,
            reactionId,
            reactableType: "Post",
          },
        })
      );
      success = true;
    } catch (err) {
      showAlert({ message: "Failed to remove react", type: "error" });
      updateError(true);
    } finally {
      updateLoading(false);
    }

    typeof callback === "function" && callback(success);
  };

  const reactToPost = async (data, callback) => {
    let success = false;
    try {
      updateLoading(true);
      await dispatch(
        reactToPostActionCreator({
          companyId,
          isAdmin,
          formData: {
            postId: data.postId,
            type: data.type,
          },
        })
      );
      success = true;
    } catch (err) {
      showAlert({ message: "Failed to react", type: "error" });
      updateError(true);
    } finally {
      updateLoading(false);
    }

    typeof callback === "function" && callback(success);
  };

  return {
    isLoading,
    reactToPost,
    removeReactFromPost,
    hasError,
  };
}

export default useReactToPost;
