import React, { useMemo, useRef, useState } from "react";
import Header from "./Header";
import ProfileItem from "./ProfileItem";

import Popover from "../../../PopoverAdvanced2";

import Mail_Icon from "assets/common/mail.svg";
import Clock_Icon from "assets/common/clock.svg";

import { Container, NameButton, ProfileContent } from "./style";

const CollaboratorPopoverContent = ({ data, color, ...rest }) => {
  const { name, roleType, email, joinedOn, avatarUrl } = data;

  return (
    <Container {...rest}>
      <Header
        color={color}
        image={avatarUrl}
        name={name}
        designation={roleType}
      />
      <ProfileContent>
        <ProfileItem icon={Mail_Icon} text={email} iconDesc="email icon" />
        <ProfileItem icon={Clock_Icon} text={joinedOn} iconDesc="clock icon" />
      </ProfileContent>
    </Container>
  );
};

CollaboratorPopoverContent.deaultProps = {
  color: null,
};

const CollaboratorPopover = ({ data, color, position = "right" }) => {
  const [isActive, setActive] = useState(false);
  const btnRef = useRef(null);

  const toggleActive = (e) => {
    e.stopPropagation();
    setActive((val) => !val);
  };

  return (
    <NameButton ref={btnRef} onClick={toggleActive}>
      {data.name}
      <Popover
        onClose={toggleActive}
        active={isActive}
        anchorElem={btnRef.current}
        userPreferedDirection={position}
      >
        <CollaboratorPopoverContent data={data} color={color} />
      </Popover>
    </NameButton>
  );
};

export default CollaboratorPopover;
