import { useSelector } from "react-redux";
import { getCurrentUserAccessType } from "../../Selectors/auth";
const OnlyWhenAuthorized = ({
  children,
  forcePass = false,
  requiredAccess = "",
}) => {
  const userAccessType = useSelector(getCurrentUserAccessType);
  if (forcePass) {
    return children;
  }

  let requiredTypes = [];
  if (typeof requiredAccess === "string") {
    requiredTypes = [requiredAccess];
  }
  if (Array.isArray(requiredAccess)) {
    requiredTypes = [...requiredAccess];
  }
  return requiredTypes.includes(userAccessType) || requiredTypes.includes("*")
    ? children
    : null;
};
export default OnlyWhenAuthorized;
