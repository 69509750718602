import {
  GET_ALL_ACTIVE_USERS,
  GET_ALL_IN_ACTIVE_USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  SET_EXECUTIVE_STATUS,
  CHANGE_PAGE_CATEGORY,
  SET_CURRENT_USER_ID,
  RESET_USER_ID,
  UPDATE_USER_STATUS,
} from "./actiontypes";
import { createAsyncActionCreator } from "../../Helper";
import {
  createUserApi,
  updateUserApi,
  getAllActiveUsersApi,
  getAllInActiveUsersApi,
  deleteUserApi,
  updateUserStatusApi,
} from "./api";

const createUserGenrator = function* ({
  companyId,
  isAdmin,
  userData,
  collaboratorData,
}) {
  yield {
    action: CREATE_USER,
    // payload: {
    //   userCategory: collaboratorData.status,
    // },
    sideEffect: createUserApi({
      companyId,
      isAdmin,
      userData,
      collaboratorData,
    }),
  };
};

const updateUserGenrator = function* ({
  companyId,
  isAdmin,
  userData,
  collaboratorData,
  userId,
}) {
  yield {
    action: UPDATE_USER,
    sideEffect: updateUserApi({
      userId,
      companyId,
      isAdmin,
      userData,
      collaboratorData,
    }),
  };
};

const getAllActiveUsersGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: GET_ALL_ACTIVE_USERS,
    sideEffect: getAllActiveUsersApi({ companyId, isAdmin, pageNumber }),
  };
};

const getAllInActiveUsersGenrator = function* ({
  companyId,
  isAdmin,
  pageNumber,
}) {
  yield {
    action: GET_ALL_IN_ACTIVE_USERS,
    sideEffect: getAllInActiveUsersApi({ companyId, isAdmin, pageNumber }),
  };
};

const deleteUserGenrator = function* ({ companyId, isAdmin, userId }) {
  yield {
    action: DELETE_USER,
    sideEffect: deleteUserApi({ companyId, isAdmin, userId }),
  };
};

export const setExecutiveSatus = ({ isExecutive }) => ({
  type: SET_EXECUTIVE_STATUS,
  payload: { isExecutive },
});

export const changePageCategory = ({ category }) => ({
  type: CHANGE_PAGE_CATEGORY,
  payload: { category },
});

export const setCurrentUserId = ({ userId }) => ({
  type: SET_CURRENT_USER_ID,
  payload: { userId },
});

export const resetToPreviousData = () => ({
  type: RESET_USER_ID,
});

const updateUserStatusGenrator = function* ({
  companyId,
  isAdmin,
  status,
  userId,
}) {
  yield {
    action: UPDATE_USER_STATUS,
    sideEffect: updateUserStatusApi({
      userId,
      companyId,
      isAdmin,
      status,
    }),
  };
};

export const [
  getActiveUsers,
  getInactiveUsers,
  createUser,
  updateUser,
  deleteUser,
  updateUserStatus,
] = createAsyncActionCreator([
  getAllActiveUsersGenrator,
  getAllInActiveUsersGenrator,
  createUserGenrator,
  updateUserGenrator,
  deleteUserGenrator,
  updateUserStatusGenrator,
]);
