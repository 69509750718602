import React, { useCallback, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Paginator from "components/Paginator";
import usePagination from "../../../../Hooks/usePagination";

import {
  getHolidays,
  getHolidaysByYear,
  searchHolidays,
  updateSearchParams,
  resetSearchParams,
  updateYearId,
} from "../../actionCreator";
import { getCurrentUserCompany } from "../../../../Common/Selectors/auth";

import SearchFilter from "./SearchFilter";

import { PaginatorBox } from "../../../../GlobalStyles";
import {
  SearchArea,
  EnhancedActionArea,
  HolidayYearTitle,
} from "../../style/display";
import { extractDateInYYYYMMDD } from "../../../../Helper";

const ActionArea = ({
  updateYearId,
  updateSearchParams,
  resetSearchParams,
  currentYearId,
  currentFromDate,
  currentToDate,
  currentIsOptional,
  onceFetched,
  currentHolidayYear,
  companyId,
  isAdmin,
  onLoading,
}) => {
  const [
    currentHolidayYearName,
    currentHolidayYearStart,
    currentHolidayYearEnd,
  ] = useMemo(() => {
    return [
      currentHolidayYear?.name,
      currentHolidayYear?.start_date,
      currentHolidayYear?.end_date,
    ];
  }, [currentHolidayYear]);

  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator:
      currentFromDate || currentToDate || currentIsOptional
        ? searchHolidays
        : isAdmin
        ? getHolidaysByYear
        : getHolidays,
    tableName: "holidays",
  });
  const extraParams = {
    companyId,
    isAdmin,
    currentYearId,
    currentFromDate,
    currentToDate,
    currentIsOptional,
  };
  const fetchFirstPage = useCallback(() => {
    getNextPage({
      isFirstPage: true,
      extraParams: extraParams,
    });
  }, [getNextPage, extraParams]);

  const fetchPrevPage = useCallback(() => {
    getNextPage({
      fetchPrevPage: true,
      extraParams: extraParams,
    });
  }, [getNextPage, extraParams]);

  const fetchNextPage = useCallback(() => {
    getNextPage({
      extraParams: extraParams,
    });
  }, [getNextPage, extraParams]);

  const fetchSpecificPage = useCallback(
    (pageNumber) => {
      getNextPage({
        pageNumber,
        extraParams: extraParams,
      });
    },
    [getNextPage, extraParams]
  );

  const setCurrentYearId = useCallback(() => {
    if (onceFetched && currentHolidayYear && !isAdmin && !currentYearId)
      updateYearId({
        holiday_year_id: currentHolidayYear.id,
      });
  }, [onceFetched, currentHolidayYear, isAdmin, currentYearId]);

  useEffect(() => {
    if (!onceFetched) {
      fetchFirstPage();
    }
    setCurrentYearId(currentHolidayYear);
  }, [fetchFirstPage, onceFetched, currentHolidayYear]);
  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  const onFormSubmit = useCallback(async (values, resetForm) => {
    updateSearchParams({
      from_date: extractDateInYYYYMMDD(values.holidaySearch_holidayFromDate),
      to_date: extractDateInYYYYMMDD(values.holidaySearch_holidayToDate),
      is_optional: values.holidaySearch_holidayIsOptional,
    });
  }, []);

  const onResetClick = () => {
    resetSearchParams();
  };

  return (
    <EnhancedActionArea>
      {!isAdmin && currentHolidayYear && (
        <HolidayYearTitle>
          <span>{currentHolidayYearName}</span>
          <span>
            ( {currentHolidayYearStart} - {currentHolidayYearEnd} )
          </span>
        </HolidayYearTitle>
      )}
      <SearchArea>
        <SearchFilter
          onFormSubmit={onFormSubmit}
          onResetClick={onResetClick}
          from_date={currentFromDate}
          to_date={currentToDate}
          is_optional={currentIsOptional}
        />
      </SearchArea>
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  return {
    companyId,
    currentHolidayYear: store.holidays.currentHolidayYear,
    onceFetched: store.holidays.onceFetched,
    currentYearId: store.holidays.currentYearId,
    currentFromDate: store.holidays.currentFromDate,
    currentToDate: store.holidays.currentToDate,
    currentIsOptional: store.holidays.currentIsOptional,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateYearId,
      updateSearchParams,
      resetSearchParams,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActionArea);
