import { CREATE_LEAD, GET_LEADS, GET_LEADS_BY_COLLABORATOR, RESET_TO_PREV_DATA, SEARCH_LEADS, SET_SEARCH_PARAMS } from "./actiontypes";
import { createAsyncActionCreator } from "../../Helper";
import { createLeadApi, getLeadsApi, getLeadsByCollaboratorApi, searchLeadsApi } from "./api";

const getLeadsGenerator = function* ({ companyId, isAdmin, pageNumber }) {
  yield {
    action: GET_LEADS,
    sideEffect: getLeadsApi({ companyId, isAdmin, pageNumber }),
  };
};

const getLeadsByCollaboratorGenerator = function* ({ companyId, isAdmin, pageNumber, userId }) {
  yield {
    action: GET_LEADS_BY_COLLABORATOR,
    sideEffect: getLeadsByCollaboratorApi({ companyId, isAdmin, pageNumber, userId }),
  };
};

const createLeadGenrator = function* ({ companyId, data, isAdmin }) {
  yield {
    action: CREATE_LEAD,
    sideEffect: createLeadApi({ companyId, data, isAdmin }),
  };
};

const searchLeadsGenrator = function* ({
  pageNumber,
  companyId,
  isAdmin,
  userId,
  fromStartDate,
  toStartDate,
  fromEndDate,
  toEndDate,
  status,
  leadType,
  leadById,
}) {
  yield {
    action: SEARCH_LEADS,
    sideEffect: searchLeadsApi({
      pageNumber,
      companyId,
      isAdmin,
      userId,
      fromStartDate,
      toStartDate,
      fromEndDate,
      toEndDate,
      status,
      leadType,
      leadById,
    }),
  };
};

export const setSearchParams = ({ fromStartDate, toStartDate, fromEndDate, toEndDate, status, leadType, leadBy }) => ({
  type: SET_SEARCH_PARAMS,
  payload: { fromStartDate, toStartDate, fromEndDate, toEndDate, status, leadType, leadBy },
});

export const resetToPrevData = () => ({
  type: RESET_TO_PREV_DATA,
});

export const [
  getLeads,
  getLeadsByCollaborator,
  createLead,
  searchLeads,
] = createAsyncActionCreator([
  getLeadsGenerator,
  getLeadsByCollaboratorGenerator,
  createLeadGenrator,
  searchLeadsGenrator,
]);
