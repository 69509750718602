import {
  LOGIN_USER,
  SET_USER_COMAPNY,
  UPDATE_LOGIN_TYPE,
  FETCH_TODAYS_WORKING_HOUR_LOG,
} from "../ActionTypes";
import { converArrayToObject, getCurrentDataAttributes } from "../../Helper";
import { createUserMappingBasedOnCompany } from "../utils";
import { DELETE_IMAGE, UPLOAD_IMAGE } from "../../Modules/Profile/actiontypes";

import {
  IauthStore,
  reducerProp,
  IuserRelationships,
  IuserData,
  ILoginData,
} from "types/index";

const initialState = {
  isLoggedIn: false,
  userCompanies: {},
  userData: {},
  isLoggedInAsAdmin: null,
  currentCompany: null,
  allData: {},
  todaysWorkingHourLog: null,
};

type Irelation = { relation: IuserRelationships };
type loginPayload = {
  dataIds: string[];
  data: {
    [key: string]: ILoginData;
  };
} & Irelation;

export const authReducer = (
  store: IauthStore = initialState,
  props: reducerProp
): IauthStore => {
  const { type, payload } = props;

  switch (type) {
    case `${LOGIN_USER}_SUCCESS`: {
      const { relation, data, dataIds }: loginPayload = payload;
      const userCompanies = converArrayToObject(
        Object.values(relation).filter((item) => item.type === "companies")
      );
      const userData = createUserMappingBasedOnCompany(
        Object.values(relation).filter((item) => item.type === "collaborators"),
        store.isLoggedInAsAdmin
      ) as IuserData;

      const allData = {} as { [key: string]: any };
      dataIds.forEach((id: string) => {
        const item = data[id];
        const allUserData = getCurrentDataAttributes(item, relation, data);
        allData[id] = { ...allUserData };
      });

      return {
        ...store,
        userCompanies,
        userData,
        isLoggedIn: true,
        allData,
      };
    }

    case UPDATE_LOGIN_TYPE: {
      const { isLoggedInAsAdmin } = payload;
      return {
        ...store,
        isLoggedInAsAdmin,
      };
    }

    case SET_USER_COMAPNY: {
      const { companyId } = payload;
      return {
        ...store,
        currentCompany: companyId,
      };
    }

    case `${FETCH_TODAYS_WORKING_HOUR_LOG}_SUCCESS`: {
      const data = payload;
      return {
        ...store,
        todaysWorkingHourLog: data,
      };
    }

    case `${UPLOAD_IMAGE}_SUCCESS`:
    case `${DELETE_IMAGE}_SUCCESS`: {
      const { dataIds, data } = payload;
      const userId = dataIds[0];
      const userData = data[userId];

      if (!store.currentCompany) {
        return store;
      }

      const prevUserData = store.userData[store.currentCompany];

      return {
        ...store,
        userData: {
          ...store.userData,
          [store.currentCompany]: {
            ...prevUserData,
            attributes: {
              ...prevUserData?.attributes,
              avatarUrl: userData?.attributes?.avatarUrl,
            },
          },
        },
      };
    }

    default:
      return store;
  }
};
