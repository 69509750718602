import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const fetchCollaboratorDetailsApi = ({
  companyId,
  collaboratorId,
  isAdmin,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_profile_details_by_collaborator?collaborator_id=${collaboratorId}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};
