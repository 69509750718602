import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const fetchNotificationsApi = ({ companyId, pageNumber, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_notifications?page=${pageNumber}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const markAllAsReadApi = ({ companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/mark_all_as_read`,
  });

  return requestData(authUrl, {
    method: "PUT",
  });
};

export const markAsReadApi = ({ companyId, isAdmin, noticeId }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/mark_as_read?notification_id=${noticeId}`,
  });

  return requestData(authUrl, {
    method: "PUT",
  });
};
