import styled from "styled-components";
import { StyledActionArea } from "globalStyles";
import Button from "components/Button";
import Icon from "components/Icon";

export const EnhancedActionArea = styled(StyledActionArea)`
  flex-flow: row wrap;
`;
export const SelectArea = styled.div`
  flex-basis: 100%;

  @media (min-width: 768px) {
    flex-basis: 200px;
  }
`;

export const Cell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const CellIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.primaryColor};
  margin-left: 10px;
  font-size: 15px;
`;
export const UpdateButton = styled(Button)`
  padding: 5px 15px;
  color: white;
  font-weight: bold;
  background-color: ${(props) => props.theme.colors.primaryColor};
`;

export const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-flow: column;
`;

export const TabContainer = styled.div`
  width: 100%;
  margin: auto;
  height: 100%;
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? "green" : "red")};
`;

export const DataItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0;
  flex-basis: auto;
  min-width: 200px;
`;

export const DetailHead = styled.h3`
  font-size: ${(props) => props.theme.text.para.medium};
  text-align: left;
  text-transform: capitalize;
  font-weight: bolder;
  margin-right: 10px;
  width: 200px;
`;

export const DetailContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  color: white;
  font-weight: bold;
  height: 25px;
  margin: 0px 10px;
  font-size: ${(props) => props.theme.text.para.extraSmall};
  background-color: ${({ active, theme }) =>
    !active ? theme.colors.primaryColor : "red"};
`;
