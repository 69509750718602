import React, { useMemo } from "react";
import { connect } from "react-redux";

import { createTableDataSelector } from "../../../../../Common/Selectors/MTable";

import AttendanceListBox from "../../../../../Common/Components/AttendanceListBox";
import { getAllAbsentees, resetAbsentees } from "../../../actionCreator";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { useAttendanceListPagination } from "../../../Hooks/useAttendanceList";
import { tranformToAttendanceList } from "../../../utils";
import { bindActionCreators } from "redux";
import { MockData } from "../../../constants";

const AbsenteesData = ({
  data,
  onceFetched,
  companyId,
  isAdmin,
  cellNameToColumnMap,
  resetAbsentees,
  count,
}) => {
  const {
    initialLoading: initialAbsentLoading,
    loading: absentLoading,
    errorOccured: absentError,
    onEndReached: absentEndReached,
  } = useAttendanceListPagination({
    onceFetched,
    companyId,
    isAdmin,
    action: getAllAbsentees,
    tableName: "attendance.absentees",
  });

  const list = useMemo(() => {
    return tranformToAttendanceList(data, cellNameToColumnMap);
  }, [data, cellNameToColumnMap]);

  return (
    <AttendanceListBox
      headingText="Absent"
      onEndReached={absentEndReached}
      initialLoading={initialAbsentLoading}
      loading={absentLoading}
      errorOccured={absentError}
      data={!onceFetched ? MockData : list}
      refreshData={resetAbsentees}
      listCount={count}
    />
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "attendance.absentees",
    fetchAllData: true,
    reverse: false,
  });

  return (store, props) => {
    const companyId = getCurrentUserCompany(store, props);
    const isAdmin = isCurrentUserIsExecutive(store, props);
    const { rows, cellNameToColumnMap } = getUserRows(store, props);

    return {
      onceFetched: store.attendance.absentees.onceFetched,
      companyId,
      isAdmin,
      data: rows,
      cellNameToColumnMap,
      count: store.attendance.absentees?.count,
    };
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetAbsentees,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AbsenteesData);
