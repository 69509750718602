import { useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { actionTypes, dispatchTabAction } from "components/Tabs2";

function useTabRoutes() {
  const history = useHistory();
  const location = useLocation();
  const initialPathNameRef = useRef(null);

  const getInitialLocation = () => {
    let list = location.pathname.split("?");
    list = list[0].split("/");
    list.pop();
    return list.join("/");
  };

  const getTabIdFromLocation = () => {
    const list = location.pathname.split("/");
    return list.pop();
  };
  /**
   * setting the  window location based on the tab being clicked
   */
  const onTabChanged = (action, payload) => {
    if (action !== actionTypes.ACTIVATE_TAB) {
      return;
    }
    const tab = payload.id;
    history.push(`${initialPathNameRef.current}/${tab}${location.search}`);
  };

  //  setting the tab active based on the route
  useEffect(() => {
    if (!initialPathNameRef.current) {
      initialPathNameRef.current = getInitialLocation();
    }
    const currTabId = getTabIdFromLocation();

    currTabId &&
      dispatchTabAction(actionTypes.ACTIVATE_TAB, {
        id: currTabId,
      });
  }, [location]);

  return {
    onTabChanged,
  };
}

export default useTabRoutes;
