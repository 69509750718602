import React, { useMemo } from "react";
import PofilePicWithTag from "./ProfilePicWithTag";
import { IMAGE_URL } from "../../../../../../config.local";

import down_icon from "assets/downArrow.svg";

import { MorePic } from "./style";

function ProfilePicTagLists({
  data,
  tagImage,
  showDOB,
  showMore,
  onMoreClick,
}) {
  const list = useMemo(() => {
    const result = data.map((item) => {
      const { cdnUrl, url } =
        item.avatarUrl && item.avatarUrl !== "NA" ? item.avatarUrl : {};
      const imageUrl =
        item.avatarUrl && item.avatarUrl !== "NA" ? `${IMAGE_URL}${url}` : null;
      return (
        <PofilePicWithTag
          key={item.id}
          userName={item.name}
          img={imageUrl}
          tagImage={tagImage}
          userDate={
            showDOB ? item?.personalInformation?.dateOfBirth : item?.joinedOn
          }
          collaboratorId={item.id}
        />
      );
    });

    if (showMore) {
      result.push(
        <MorePic key={"more_btn_pic"} onClick={onMoreClick}>
          more
          <img
            src={down_icon}
            alt="down arrow"
            style={{ width: "10px", marginTop: "2px" }}
          />
        </MorePic>
      );
    }
    return result;
  }, [data, showMore]);

  return list;
}

export default ProfilePicTagLists;
