import React, { useEffect, useState, useCallback, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import useAlert from "hooks/useAlert";
import InfoView from "components/InfoView";
import { RectangleSkeleton } from "components/Skeleton";

import EmptyScreen from "assets/employee/EmptyScreen.png";
import InfoImage from "assets/common/Info.png";

import PersonalInfoCreateView from "../Create/index";
import DetailView from "./DetailView";

import { ViewContainer } from "globalStyles";
import {
  getPersonalInfo,
  getPersonalInfoByCollaborator,
} from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { EmptyScreenContainer, EmptyScreenImage } from "../../style/display";
import { formTags } from "../Create/options";
import { getUserPersonalInfo } from "../../selector";

const PersonalInfoDisplayView = ({
  userId,
  getPersonalInfo,
  getPersonalInfoByCollaborator,
  personalInfoData,
  currentStep,
  onceFetched,
  isAdmin,
  companyId,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { showAlert } = useAlert();

  const detailData = useMemo(() => {
    if (personalInfoData)
      return {
        data: Object.values(personalInfoData).map((value) => {
          if (typeof value !== "boolean") {
            return value || "---";
          } else {
            return value.toString();
          }
        }),
        columns: Object.keys(personalInfoData),
      };
    return null;
  }, [personalInfoData]);

  const currentStepCheck = useCallback(
    () => {
      const formTagOptions = Object.values(formTags);
      if (formTagOptions.slice(0, formTagOptions.length - 1).includes(currentStep)) {
        updateFormVisibilty()
      }
    }, [currentStep])

  const fetchPersonalInfo = useCallback(async () => {
    try {
      setLoading(true);
      isAdmin
        ? await getPersonalInfoByCollaborator({ companyId, userId, isAdmin })
        : await getPersonalInfo({ companyId });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showAlert({
        message: "Personal Information Fetch Failed",
        type: "error",
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (!personalInfoData || !onceFetched) {
      fetchPersonalInfo();
    }
    currentStepCheck();
  }, [personalInfoData, onceFetched, currentStep]);

  const updateFormVisibilty = useCallback(() => {
    setIsFormVisible(true);
  }, []);

  if (isFormVisible) {
    return <PersonalInfoCreateView setIsFormVisible={setIsFormVisible} stepsCompleted={currentStep} />;
  }

  return (
    <ViewContainer style={!!detailData && !isLoading ? { justifyContent: 'flex-start' } : null}>
      {isLoading && <RectangleSkeleton times={4} />}
      {!!detailData && !isLoading && (
        <DetailView data={detailData.data} columns={detailData.columns} currentStep={currentStep} updateFormVisibilty={updateFormVisibilty} />
      )}

      {!detailData && !isLoading && (
        // isAdmin ?
        //   <EmptyScreenContainer>
        //     <EmptyScreenImage src={EmptyScreen} alt="No records found" />
        //   </EmptyScreenContainer>
        //   :
        <InfoView
          image={InfoImage}
          onCta={isAdmin ? "" : updateFormVisibilty}
          ctaText={isAdmin ? "No Records Found" : personalInfoData ? "Update Information" : "Add Information"}
        />
      )}
    </ViewContainer>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  const { personalInfoData, currentStep } = getUserPersonalInfo(store, props);
  return {
    companyId,
    isAdmin,
    personalInfoData,
    currentStep,
    onceFetched: store.personalInfo.onceFetched
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPersonalInfo,
      getPersonalInfoByCollaborator,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInfoDisplayView);
