import React from "react";
import { CircleSkeleton, SingleRectangleSkeleton } from "../../Skeleton";
import { CardContainer, Header, Footer } from "./style";

export function PostCardSkeleton({ ...rest }) {
  return (
    <CardContainer {...rest}>
      <Header style={{ justifyContent: "flex-start" }}>
        <CircleSkeleton />
        <div style={{ width: "50%" }} className="content d-f f-c">
          <SingleRectangleSkeleton style={{ marginBottom: "5px" }} />
          <SingleRectangleSkeleton style={{ width: "30%" }} />
        </div>
      </Header>

      <SingleRectangleSkeleton style={{ marginBottom: "10px" }} />
      <SingleRectangleSkeleton style={{ marginBottom: "10px", width: "30%" }} />
      <SingleRectangleSkeleton />

      <Footer>
        <SingleRectangleSkeleton style={{ width: "30%" }} />
        <SingleRectangleSkeleton style={{ marginLeft: "10px", width: "30%" }} />
      </Footer>
    </CardContainer>
  );
}
