import React, { useMemo, useReducer } from "react";
import { initialStore, tooltipReducer } from "./redux/reducer";
import { TooltipContext } from "./TooltipContext";
import { useTooltipLogic } from "./hooks/useTooltipLogic";
import { callAllFunction } from "../../../Helper";

export function TooltipContainer({
  children,
  reducer,
  initialState,
  position,
}) {
  const [store, dispatch] = useReducer(
    reducer || tooltipReducer,
    initialState || initialStore
  );
  const { onMouseEnter, onMouseLeave } = useTooltipLogic(dispatch, position);

  const modifiedChildren = useMemo(() => {
    return React.Children.map(children, (child) => {
      const isTooltipChild = child.type.displayName === "TooltipContent";
      const isNormalElemet = typeof child.type === "string";
      if (isTooltipChild || isNormalElemet) {
        return child;
      }

      return React.cloneElement(child, {
        onMouseEnter: callAllFunction(onMouseEnter, child.props.onMouseEnter),
        onMouseLeave: callAllFunction(onMouseLeave, child.props.onMouseLeave),
      });
    });
  }, [children]);

  return (
    <TooltipContext.Provider value={store}>
      {modifiedChildren}
    </TooltipContext.Provider>
  );
}

TooltipContainer.displayName = "TooltipContainer";
TooltipContainer.defaultProps = {
  position: "left",
  reducer: null,
  initialState: null,
};
