import React, { useState } from "react";

import Form from "./Form";
import Member from "./Member";
import { MainContainer } from "../../style/create";

const TeamCreateView = () => {
  const [isFormSubmitted, updateIsFormSubmitted] = useState(false);

  return (
    <MainContainer>
      {isFormSubmitted ? (
        <Member updateIsFormSubmitted={updateIsFormSubmitted} />
      ) : (
        <Form updateIsFormSubmitted={updateIsFormSubmitted} />
      )}
    </MainContainer>
  );
};

export default TeamCreateView;
