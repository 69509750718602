import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendPasswordResetEmail } from "../actionCreator";
import { Header, ReStyledFormGroup, StyledButton } from "../styles";
import InputGroup from "../../../Common/Components/InputGroup";
import { validateEmail } from "../../../Helper";
import useAlert from "../../../Hooks/useAlert";

const ForgotPassword = ({ setsuccessStatus, sendPasswordResetEmail }) => {
  const [isLoading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const onFormSubmit = useCallback(async (values, resetForm) => {
    try {
      setLoading(true);
      const { forgotPassword_userEmail } = values;
      await sendPasswordResetEmail({
        email: forgotPassword_userEmail.toLowerCase(),
      });
      setLoading(false);
      resetForm();
      setsuccessStatus(true);
    } catch (err) {
      setLoading(false);
      showAlert({ message: "Reset Password failed", type: "error" });
    }
  }, []);

  return (
    <>
      <Header>Forgot Password</Header>
      <ReStyledFormGroup onSubmit={onFormSubmit}>
        <InputGroup
          id="forgotPassword_userEmail"
          fieldType="input"
          required
          validator={validateEmail}
          invalidMessage="Invalid Email"
          placeholder="Enter email"
          inputMode="email"
          label="Email"
        />
        <StyledButton
          primary
          size="full"
          loading={isLoading}
          disabled={isLoading}
          type="submit"
        >
          Submit
        </StyledButton>
      </ReStyledFormGroup>
    </>
  );
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendPasswordResetEmail,
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(ForgotPassword);
