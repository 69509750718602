export const expenseTypeOptions = [
  { name: "Hotel", value: "hotel" },
  { name: "Food", value: "food" },
  { name: "Travel", value: "travel" },
  { name: "Training or Certification", value: "training_or_certification" },
  { name: "Other", value: "other" }
];

export const statusOptions = [
  { name: "pending", value: "pending" },
  { name: "approved", value: "approved" },
  { name: "reimbursed", value: "reimbursed" },
  { name: "rejected", value: "rejected" }
]
