import React, { useMemo } from "react";
import Icon from "../Icon";

import { Container, Step, Circle, StepText, Divider } from "./style";

const Stepper = ({ steps, currentStep = 0, className, center = false }) => {
  const stepList = useMemo(() => {
    return steps.map((stepName, index) => {
      const isActive = index <= currentStep;
      const isCompleted = currentStep > index;
      return (
        <Step key={`${stepName}_${index}`} $center={center}>
          <Circle $active={isActive}>
            {isCompleted ? <Icon name="fas fa-check" /> : index + 1}
          </Circle>
          <StepText $active={isActive}>{stepName}</StepText>
          {index < steps.length - 1 && <Divider $center={center} />}
        </Step>
      );
    });
  }, [steps, currentStep]);

  return <Container className={className}>{stepList}</Container>;
};

export default Stepper;
