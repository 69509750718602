import React, { useCallback, useMemo, useState } from "react";
import { ItemView } from "components/DetailsView";
import { createTableDataSelector } from "common/Selectors/MTable";

import { transform2dMatrixTo1D } from "helper";
import { DetailsContainer, StyledEditDetails } from "../../../style/display";
import { MainContainer } from "../../../style/create";
import Form from "../../Create/Form";
import {
  getInitialData,
  getCurrentIndexRowDataAndColumn,
} from "../../../utils";
import { getTeamDepartments, getTeamOwners } from "../../../selector";
import { useSelector } from "react-redux";

const getUserRows = createTableDataSelector({
  tableName: "teams.owners",
  reverse: false,
  blackList: { "Sl No": true },
});

const DetailView = ({ data: info }) => {
  const { rowIndex } = info;

  const [isEditOpen, setEditOpen] = useState(false);

  const toggleEdit = useCallback(() => {
    setEditOpen((val) => !val);
  }, []);

  const { rows } = useSelector(getUserRows);

  const { data = [], columns = [] } = useMemo(
    () => getCurrentIndexRowDataAndColumn(rows, rowIndex),
    [rows, rowIndex]
  );

  const teamOwners = useSelector(getTeamOwners);
  const teamDepartments = useSelector(getTeamDepartments);

  const modifiedData = useMemo(() => {
    return transform2dMatrixTo1D(columns, data);
  }, [columns, data]);

  const initialData = useMemo(() => {
    return getInitialData(data, teamOwners, teamDepartments);
  }, [data, teamOwners, teamDepartments]);

  const renderItem = useCallback(() => {
    return modifiedData.map((item, index) => {
      if (item.result) {
        return (
          <ItemView
            key={`${item.heading}_${index}`}
            heading={item.heading}
            isTable={item.isTable}
            result={item.result}
          />
        );
      }
    });
  }, [modifiedData]);

  if (isEditOpen) {
    return (
      <MainContainer>
        {isEditOpen && <StyledEditDetails onEdit={toggleEdit} />}
        <Form
          updateIsFormSubmitted={setEditOpen}
          isEditOpen={true}
          initialData={initialData}
        />
      </MainContainer>
    );
  }

  return (
    <DetailsContainer>
      {!isEditOpen && <StyledEditDetails onEdit={toggleEdit} />}
      {renderItem()}
    </DetailsContainer>
  );
};

export default DetailView;
