import React from "react";

import { CircleSkeleton, SingleRectangleSkeleton } from "../../Skeleton";

import { CommentContainer } from "./style";

export function CommentSkeleton(props) {
  return (
    <CommentContainer {...props}>
      <CircleSkeleton style={{ width: "38px", height: "38px" }} />
      <SingleRectangleSkeleton
        style={{ marginLeft: "5px", height: "86px", width: "90%" }}
      />
    </CommentContainer>
  );
}
