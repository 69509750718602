import requestData from "../../../../ApiHandler";
import { createAuthUrl } from "helper";

export const fetchTodaysBirthdayApi = ({ companyId, isAdmin, pageNumber }) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_today_birthdays?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};

export const fetchUpcomingBirthdaysApi = ({
  companyId,
  isAdmin,
  pageNumber,
}) => {
  const url = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_upcoming_birthdays?page=${pageNumber}`,
  });
  return requestData(url, {
    method: "GET",
  });
};
