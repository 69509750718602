import { GET_ABSENTEES, RESET_ABSENTEES } from "../actiontypes";

import {
  createNewStoreOnGetData,
  createInitialStoreState,
} from "../../../Helper";

const initialState = createInitialStoreState({ count: 0 }, true);

export const absenteesReducer = (store = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_ABSENTEES}_SUCCESS`: {
      const data = createNewStoreOnGetData(payload, store, true);
      const count = payload?.meta?.absenteesCount || 0;
      return {
        ...data,
        count,
      };
    }

    case RESET_ABSENTEES: {
      return {
        ...initialState,
      };
    }

    default:
      return store;
  }
};
