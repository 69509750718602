import styled from "styled-components";
import EmptyData from "components/EmptyData";

export const MainContainer = styled.div`
  height: 100%;
  padding: 0 25px 15px 25px;
  display: flex;
  flex-flow: column no-wrap;

  @media (min-width: 768px) {
    padding: 0 50px 20px 50px;
  }
`;
export const StyledForm = styled.form`
  max-width: 780px;
  width: 100%;

  & > i {
    color: ${(props) => props.theme.colors.primaryColor};
    font-size: ${(props) => props.theme.text.title.large};
    margin: 20px 0 20px;
    cursor: pointer;
    float: right;
  }
`;
export const FormContainer = styled.form`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  width: 100%;
  max-width: 780px;
`;

export const FormHeading = styled.h3`
  font-size: ${(props) => props.theme.text.title.large};
  color: ${(props) => props.theme.colors.primaryColor};
  margin-bottom: 24px;
  margin-top: 16px;
  font-weight: 600;
  text-align: left;

  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.text.title.extraLarge};
    margin-bottom: 40px;
    margin-top: 45px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & i {
    color: ${(props) => props.theme.colors.primaryColor};
    font-size: ${(props) => props.theme.text.title.large};
    margin: 45px 0 20px;
    cursor: pointer;
  }
`;

export const ListBox = styled.div`
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid ${({ theme }) => theme.colors.primaryColor};
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const StyledEmptyData = styled(EmptyData)`
  & > img {
    width: 100px;
    height: 100px;
  }
  & > span {
    font-size: ${(props) => props.theme.text.para.small};
  }
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;
