import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import useAlert from "hooks/useAlert";

import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "../../../../Common/Selectors/auth";
import InputGroup from "components/InputGroup";
import Button from "components/Button";

import { getHolidayYearsOptions } from "../../../../Common/Selectors/holiday";
import Routes from "route/Path";

import {
  getHolidayYears,
  createHoliday,
  editHoliday,
} from "../../actionCreator";
import {
  holidayDataSelector,
  storeCurrentYearIdSelector,
} from "../../selector";

import { optionalOptions } from "./options";
import { isDateValid, generateFormData } from "../../utils";

import { StyledFormGroup } from "../../../../GlobalStyles";
import {
  FormHeading,
  HolidayCheckbox,
  StyledBackButton,
} from "../../styles/create";

const Form = ({
  companyId,
  yearOptions,
  isAdmin,
  getHolidayYears,
  createHoliday,
  editId,
  editHoliday,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [yearValue, setYearValue] = useState("");
  const history = useHistory();
  const [invalidDateMessage, setInvalidDateMessage] = useState(
    "Holiday Date is required"
  );
  const { showAlert } = useAlert();
  const currentYear = useSelector(storeCurrentYearIdSelector);
  const editHolidayData = useSelector((store) =>
    holidayDataSelector(store, { id: editId })
  );
  const isEditMode = !!editId;

  useEffect(() => {
    getHolidayYears({ companyId, isAdmin });
  }, [companyId]);

  useEffect(() => {
    if (isEditMode) {
      setYearValue(currentYear);
    }
  }, [isEditMode]);

  const { minDate, maxDate } = useMemo(() => {
    const res = yearOptions.find((option) => {
      return option.value === yearValue;
    });
    return {
      minDate: res?.startDate || "",
      maxDate: res?.endDate || "",
    };
  }, [yearValue]);

  const goBack = () => {
    history.replace(Routes.authorized.holiday.view.path, {
      currentYear,
    });
  };

  const DateValidator = useCallback(
    (value) => {
      if (yearValue === "") {
        setInvalidDateMessage("Please select an year first");
        return false;
      }
      if (isDateValid(value, minDate, maxDate)) {
        return true;
      }
      setInvalidDateMessage("Please select a date from the selected year");
      return false;
    },
    [yearValue, minDate, maxDate]
  );

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      const action = isEditMode ? editHoliday : createHoliday;
      try {
        setLoading(true);
        const data = generateFormData(values);
        await action({ holidayId: editId, companyId, isAdmin, formData: data });
        showAlert({ message: "Holiday Created" });

        if (isEditMode) {
          goBack();
          return;
        }
        resetForm();
        setYearValue("");
        setInvalidDateMessage("Holiday Date is required");
      } catch (err) {
        showAlert({ message: "Holiday creation failed", type: "error" });
      } finally {
        setLoading(false);
      }
    },
    [companyId, isEditMode, editId]
  );

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <FormHeading>
        {isEditMode ? "Edit Holiday" : "Create new one"}
        {isEditMode && <StyledBackButton onLeft showIcon onClick={goBack} />}
      </FormHeading>

      <InputGroup
        id="holidayCreate_holidayYear"
        fieldType="select"
        required
        options={yearOptions}
        defaultSelectText="Select Year"
        label="Year"
        disabled={isEditMode}
        onChange={(event) => setYearValue(event.target.value)}
        initialValue={currentYear || ""}
      />

      <InputGroup
        id="holidayCreate_holidayDate"
        fieldType="datePicker"
        required
        defaultSelectText="Select Date"
        label="Date"
        validator={DateValidator}
        placeholder="Please select a date"
        invalidMessage={invalidDateMessage}
        initialValue={editHolidayData.holidayDate || ""}
      />

      <InputGroup
        id="holidayCreate_holidayReason"
        fieldType="input"
        label="Reason"
        fieldType="textArea"
        placeholder="Reason for Holiday"
        wrapperStyle={{ height: "150px" }}
        fillParent={true}
        required
        initialValue={editHolidayData.reason || ""}
      />

      <InputGroup
        id="holidayCreate_holidayCardImage"
        fieldType="input"
        label="Holiday card image"
        fieldType="googleFilePicker"
        accept="image/*"
      />

      <HolidayCheckbox>
        <InputGroup
          id="holidayCreate_optionalStatus"
          fieldType="checkboxGroup"
          secondaryLabel
          options={optionalOptions}
          groupName="optionalStatus"
          initialValue={editHolidayData.isOptional ? { isOptional: true } : ""}
        />
      </HolidayCheckbox>

      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        {isEditMode ? "Save" : "Create"}
      </Button>
    </StyledFormGroup>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const yearOptions = getHolidayYearsOptions(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    yearOptions,
    isAdmin,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHolidayYears,
      createHoliday,
      editHoliday,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Form);
