import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import useAlert from "hooks/useAlert";

import {
  getCurrentWorkingShift,
  markSignIn,
  markSignOut,
} from "../actionCreator";

import { getTodaysWorkingHourLog as getTodaysWorkingHourLogActionCreator } from "../../../Common/ActionCreators";

import {
  isCurrentUserIsExecutive,
  getCurrentUserCompany,
} from "../../../Common/Selectors/auth";

export const useAttendanceFunctions = () => {
  const [isLoading, updateLoading] = useState(false);
  const [signInLoadingType, updateSignInLoading] = useState("");
  const [isSignOutLoading, updateSignOutLoading] = useState(false);
  const { showAlert } = useAlert();
  const [isModalActive, setModalActive] = useState(false);
  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const companyId = useSelector(getCurrentUserCompany);
  const currentLogId = useSelector(
    (store) => store.attendance.attendanceTable.currentLogId
  );
  const dispatch = useDispatch();

  const toggleModal = () => {
    setModalActive((prevValue) => {
      return !prevValue;
    });
  };

  const getshiftData = useCallback(async () => {
    try {
      updateLoading(true);
      await dispatch(getCurrentWorkingShift({ companyId, isAdmin }));
      updateLoading(false);
    } catch (err) {
      updateLoading(false);
    }
  }, [companyId, isAdmin]);

  useEffect(() => {
    !isAdmin && getshiftData();
  }, [getshiftData, isAdmin]);

  const callSignIn = useCallback(
    async (workOrigin) => {
      try {
        updateSignInLoading(workOrigin);
        await dispatch(markSignIn({ companyId, isAdmin, workOrigin }));
        updateSignInLoading("");
        showAlert({ message: "Sign in Success" });
        toggleModal();
      } catch (err) {
        updateSignInLoading("");
        showAlert({ message: err[0] || "Sign in failed", type: "error" });
      }
    },
    [companyId, isAdmin]
  );

  const getTodaysWorkingHourLog = useCallback(async () => {
    let result = null;
    try {
      result = await dispatch(
        getTodaysWorkingHourLogActionCreator({ companyId, isAdmin })
      );
    } catch (err) {
      showAlert({ message: err[0] || "Something went wrong", type: "error" });
    }
    return result;
  }, [companyId, isAdmin]);

  const callSignOut = useCallback(async () => {
    try {
      updateSignOutLoading(true);
      await dispatch(
        markSignOut({ companyId, isAdmin, workHourLogId: currentLogId })
      );
      updateSignOutLoading(false);
      showAlert({ message: "Sign out Success" });
    } catch (err) {
      updateSignOutLoading(false);
      showAlert({ message: "Sign out failed", type: "error" });
    }
  }, [currentLogId, companyId, isAdmin]);

  return {
    isLoading,
    signInLoadingType,
    isSignOutLoading,
    isModalActive,
    toggleModal,
    callSignIn,
    callSignOut,
    getTodaysWorkingHourLog,
  };
};
