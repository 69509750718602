import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const getAllDepartmentsApi = ({ pageNumber, companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/departments?page=${pageNumber}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const deleteDepartmentApi = ({ companyId, departmentId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/departments/${departmentId}`,
  });

  return requestData(authUrl, {
    method: "DELETE",
  });
};

export const createDepartmentApi = ({ companyId, departmentName, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/departments`,
  });

  return requestData(authUrl, {
    method: "POST",
    body: {
      department: {
        name: departmentName,
      },
    },
    isFormData: true,
  });
};

export const updateDepartmentApi = ({
  companyId,
  departmentName,
  departmentId,
  isAdmin,
}) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/departments/${departmentId}`,
  });

  return requestData(authUrl, {
    method: "PUT",
    body: {
      department: {
        name: departmentName,
      },
    },
    isFormData: true,
  });
};
