import React, { useMemo, useCallback, useState } from "react";

import Icon from "../../Icon";
import useInput from "../useInput";
import useClipText from "../../../../Hooks/useClipText";
import styles from "./FilePicker.module.css";

import {
  FilePickerContainer,
  FileInputContainer,
  Attachment,
  AttachmentText,
  UploadText,
} from "./style";
import { InputIcon } from "../style";

const FileAttachment = ({ name }) => {
  const { clippedText } = useClipText({ text: name, maxChar: 10 });
  return (
    <Attachment>
      <Icon name="far fa-file" />
      <AttachmentText>{clippedText}</AttachmentText>
    </Attachment>
  );
};

const FilePicker = ({
  size = "md",
  success = "",
  secondary = false,
  error = "",
  fieldClass = '',
  ...otherprops
}) => {
  const { icon, restInputProps } = useInput({
    ...otherprops,
    success,
    error,
  });

  success = success ? "true" : undefined;
  error = error ? "true" : undefined;

  const [maxWidth, updateMaxWidth] = useState("auto");
  const { value, ...remainingProps } = restInputProps;
  const fileName = useMemo(() => {
    if (!value || value.length === 0) {
      return null;
    }

    const names = [];

    for (let i = 0; i < value.length; i++) {
      names.push(value[i].name);
    }

    return names;
  }, [value]);

  const setRef = useCallback((r) => {
    if (!r) {
      return;
    }
    updateMaxWidth(r.clientWidth);
  }, []);

  return (
    <FilePickerContainer size={size} className={fieldClass}>
      <FileInputContainer
        as="label"
        style={{ maxWidth }}
        ref={setRef}
        htmlFor={restInputProps.id}
        hasFiles={Array.isArray(fileName) && fileName.length > 0}
      >
        {fileName ? (
          fileName.map((fileName) => (
            <FileAttachment key={fileName} name={fileName} />
          ))
        ) : (
            <>
              <Icon name="fas fa-cloud-upload-alt" />
              <UploadText>Upload File</UploadText>
            </>
          )}
      </FileInputContainer>
      <input style={{ display: "none" }} type="file" {...remainingProps} />
      {!!icon && <InputIcon success={success} error={error} name={icon} />}
    </FilePickerContainer>
  );
};

export default FilePicker;
