import styled from "styled-components";

export const Container = styled.div`
  flex-basis: 300px;
  flex-grow: 1;
  border-radius: 15px;
  background-color: #f3f3f3;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 42px 55px 40px 58px;
  height: 250px;
`;

export const Count = styled.span`
  font-size: ${(props) => props.theme.text.title.extraLarge};
  font-size: 38px;
  font-weight: bold;
  color: #1c1c1c;
  margin: 17px 0 6px 0;
`;

export const SubText = styled.span`
  opacity: 0.6;
  font-size: ${(props) => props.theme.text.para.medium};
  text-align: left;
  color: #000000;
`;

export const ImageBox = styled.div`
  width: 58px;
  height: 58px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
