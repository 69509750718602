import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import GridCell from "./GridCell";

import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import useDrawer from "../../../Hooks/useDrawer";
import { GridContainer, StyledFixedSizedGrid } from "./style";

const ItemRender = memo(({ data, columnIndex, rowIndex, style }) => {
  const {
    data: newData,
    showHeading,
    currentActiveRow,
    activateRow,
    onClick,
    actionCellProps = {},
  } = data;
  return (
    <GridCell
      row={rowIndex}
      onClick={onClick}
      showHeading={showHeading}
      key={`${rowIndex}_${columnIndex}`}
      columnName={newData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      actionCellProps={actionCellProps}
      data={
        newData[rowIndex] && newData[rowIndex][columnIndex]
          ? newData[rowIndex][columnIndex]
          : ""
      }
      style={style}
    />
  );
});

const Grid = ({
  columns,
  rows,
  columnWidth,
  rowHeight,
  data,
  dataKey = null, // used to indentify in which key the actual data matrix is stored in the data Object
  onClick = null,
  showHeading = false,
  children = null,
  actionCellProps = {},
}) => {
  const containerRef = useRef();
  const [dimensions, updateDimensions] = useState({ width: 0, height: 0 });
  const [currentActiveRow, activateRow] = useState(-1);
  const { dimesnions: windowDimensions } = useWindowDimensions();
  const { isDrawerActive } = useDrawer();

  const actualData = dataKey ? data[dataKey] : data;

  useEffect(() => {
    updateDimensions({ width: 0, height: 0 });
  }, [isDrawerActive, windowDimensions]);

  useEffect(() => {
    let id = null;
    id = setTimeout(() => {
      const container = containerRef.current;
      const { clientWidth, clientHeight } = container;
      const newHeight = Math.min(actualData.length * rowHeight, clientHeight);

      updateDimensions({ width: clientWidth, height: newHeight });
    }, 500);

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [windowDimensions, isDrawerActive, actualData, rowHeight]);

  const onCellClick = useCallback(
    (rowNumber) => {
      if (typeof onClick !== "function" || !rowNumber) {
        return;
      }
      onClick({ data: actualData[rowNumber], columns: actualData[0] });
    },
    [actualData]
  );

  const actualColumnWidth = useMemo(() => {
    if (
      !dimensions.width ||
      !actualData ||
      !Array.isArray(actualData) ||
      actualData.length === 0 ||
      actualData[0].length === 0
    ) {
      return columnWidth;
    }

    const noOfColumns = actualData[0].length;
    const currentDataColumnWidth = noOfColumns * columnWidth;

    if (currentDataColumnWidth < dimensions.width) {
      return dimensions.width / noOfColumns;
    }
    return columnWidth;
  }, [dimensions, columnWidth, actualData]);

  return (
    <GridContainer
      $showBorder={dimensions.height <= rowHeight}
      ref={containerRef}
    >
      <StyledFixedSizedGrid
        style={{
          overflow: rows > 1 ? "auto" : "hidden",
        }}
        width={dimensions.width}
        height={dimensions.height}
        columnCount={columns}
        rowCount={rows}
        columnWidth={actualColumnWidth}
        itemKey={({ columnIndex, rowIndex }) => `${rowIndex}_${columnIndex}`}
        rowHeight={rowHeight}
        itemData={
          !children
            ? {
                data,
                showHeading,
                currentActiveRow,
                activateRow,
                onClick: onCellClick,
                actionCellProps,
              }
            : {
                ...data,
                currentActiveRow,
                actionCellProps,
                activateRow,
                onClick: onCellClick,
              }
        }
      >
        {children ? children : ItemRender}
      </StyledFixedSizedGrid>
    </GridContainer>
  );
};

export default Grid;
