import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Grid from "components/Grid";
import { createTableDataSelector } from "common/Selectors/MTable";

import { TableContainer } from "globalStyles";
import RenderItem from "./RenderItem";
import { calculateMaxLength } from "helper";

const TableData = ({ data, onCellClick, cellNameToColumnMap, rowIdMap, onEmptyData, ...other }) => {
  const { rows, cols } = useMemo(() => {
    const rows = data.length;
    const cols = data[0] && data[0].length > 0 ? data[0].length : 6;
    return { rows, cols };
  }, [data]);

  const maxStatusLength = useMemo(
    () => {
      return calculateMaxLength(cellNameToColumnMap, data);
    },
    [data],
  )

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (rows === 1) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [rows]);

  return (
    <TableContainer {...other}>
      <Grid
        onClick={onCellClick}
        rows={rows}
        columns={cols}
        columnWidth={300}
        rowHeight={60}
        data={{
          rowData: data,
          cellNameToColumnMap,
          maxStatusLength: maxStatusLength,
          rowIdMap,
        }}
        dataKey="rowData"
      >
        {RenderItem}
      </Grid>
    </TableContainer>
  );
};

const mapStateToProps = () => {
  const getUserRows = createTableDataSelector({
    tableName: "leaveTypes",
    blackList: { "Sl No": true },
    extraColumns: [{ columnName: "actions", position: 0 }],
  });
  const maker = (store, props) => {
    const { rows, cellNameToColumnMap, rowIdMap } = getUserRows(store, props);
    return {
      data: rows,
      cellNameToColumnMap,
      rowIdMap
    };
  };
  return maker;
};

export default connect(mapStateToProps, null)(TableData);
