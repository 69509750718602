import React from "react";
import { ListItem, ListText } from "../../style/display";
import Icon from "common/Components/Icon";

const ProfileItem = ({ icon, text, iconDesc }) => {
  return (
    <ListItem>
      <Icon style={{ fontSize: "15px", color: "#6867B9" }} name={icon} />
      <ListText>{text}</ListText>
    </ListItem>
  );
};

export default ProfileItem;
