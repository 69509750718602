import { useEffect, useState } from "react";
import useWindowDimensions from "./useWindowDimensions";

const useNodeDimensions = ({ elemIdOrElem }) => {
  const [elemPosition, updatePosition] = useState(null);
  const { dimesnions: windowDim } = useWindowDimensions();

  useEffect(() => {
    const elem =
      typeof elemIdOrElem === "string"
        ? document.getElementById(elemIdOrElem)
        : elemIdOrElem;
    if (!elem) {
      updatePosition(null);
      return;
    }

    const { top, left, width, height, right } = elem.getBoundingClientRect();
    updatePosition({ top, left, width, height, right });
  }, [elemIdOrElem, windowDim]);

  return elemPosition;
};

export default useNodeDimensions;
