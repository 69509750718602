import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const getAllJobTitlesApi = ({ pageNumber, companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/job_titles?page=${pageNumber}`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};

export const deleteJobTitleApi = ({ companyId, jobId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/job_titles/${jobId}`,
  });

  return requestData(authUrl, {
    method: "DELETE",
  });
};

export const createJobTitleApi = ({ companyId, jobTitle, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/job_titles`,
  });

  return requestData(authUrl, {
    method: "POST",
    body: {
      job_title: {
        name: jobTitle,
      },
    },
    isFormData: true,
  });
};

export const updateJobTitleApi = ({ companyId, jobTitle, jobId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/job_titles/${jobId}`,
  });

  return requestData(authUrl, {
    method: "PUT",
    body: {
      job_title: {
        name: jobTitle,
      },
    },
    isFormData: true,
  });
};
