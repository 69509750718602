import styled from "styled-components";
import { StyledActionArea } from "globalStyles";
import Icon from "../../../Common/Components/Icon";

export const EnhancedActionArea = styled(StyledActionArea)`
  display: flex;
  margin: 0px;
  padding: 0px 10px;
  @media (min-width: 768px) {
    justify-content: flex-end;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;

export const PaginatorAndCreateBox = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > button {
    height: 40px;
  }

  @media (min-width: 767px) {
    flex-wrap: nowrap;
  }
`;

export const Cell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const CellIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.primaryColor};
  margin-left: 10px;
  font-size: 15px;
`;
