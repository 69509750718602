import { useState, useCallback } from "react";

export function useTaskCreate() {
  const [newTaskPos, updateNewTaskPos] = useState(null);
  const [createModalData, updateModalData] = useState({
    active: false,
    elem: null,
  });

  const toggleModal = useCallback((elem) => {
    updateModalData((val) => ({ active: !val.active, elem }));
  }, []);

  const activateForm = (elem) => {
    toggleModal(elem);
  };

  const clearNewTask = () => {
    updateNewTaskPos(null);
    toggleModal(null);
  };

  return {
    activateForm,
    clearNewTask,
    createModalData,
    updateNewTaskPos,
    newTaskPos,
  };
}
