import styled, { css, keyframes } from "styled-components";
import ProfilePic from "components/ProfilePic";
import { scrollbar } from "globalStyles";
import EmptyData from "components/EmptyData";
import SomethingWrong from "components/SomethingWrong";

const profileStyle = css`
  width: 50px;
  height: 50px;
  flex-shrink: 0;
`;

const warm = keyframes`
 0%{
   opacity:1;
 }
 50%{
   opacity:0.5;
 }
 100%{
   opacity:1;
 }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
  margin: 20px;
  max-height: 650px;
  overflow-y: scroll;
  ${scrollbar}
  position: relative;
  border: ${({ theme }) => `1px ${theme.colors.grid.border} solid`};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  .load-more-button {
    font-size: ${(props) => props.theme.text.para.extraSmall};
    height: 30px;
    margin: 20px 0px;
  }
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryColor};
  color: white;
  font-size: ${({ theme }) => theme.text.para.medium};
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 10px;
  position: sticky;
  z-index: 2;
  top: 0;
  justify-content: space-between;
`;

export const HeaderMainContent = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderRefreshContent = styled.div`
  background-color: white;
  color: black;
  border-radius: 4px;
  padding: 4px 4px 4px 14px;
  cursor: pointer;
  transition: transform linear 0.2s;
  &:active {
    transform: scale(0.9);
  }
`;

export const CountBox = styled.div`
  padding: 4px 8px;
  border-radius: 3px;
  display: flex;
  background-color: white;
  color: black;
`;

export const Text = styled.span`
  margin: 0px 10px;
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

export const ProfileContent = styled.div`
  display: flex;
  width: 120px;
  min-height: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  .username-text {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledProfilePic = styled(ProfilePic)`
  width: 50px;
  height: 50px;
  margin: 10px 0px;
`;

export const StyledEmptyData = styled(EmptyData)`
  height: auto;
  & > img {
    width: 100px;
    height: 100px;
  }
  & > span {
    font-size: ${(props) => props.theme.text.para.small};
  }
  margin: 80px 0px;
`;

export const StyledWrong = styled(SomethingWrong)`
  height: auto;
  & > i {
    font-size: 60px;
  }
  & > span {
    font-size: ${(props) => props.theme.text.para.small};
  }
  margin: 80px 0px;
`;

export const LoaderImage = styled.div`
  ${profileStyle}
  border-radius: 50%;
  background-color: #f3f3f3;
`;

export const LoaderText = styled.div`
  height: 15px;
  margin-top: 10px;
  width: 100px;
  background-color: #f3f3f3;
  animation: ${warm} ease-in 1s forwards infinite;
`;

export const ProfilePicContainer = styled.div`
  position: relative;
  .icon {
    position: absolute;
    top: 10px;
    right: 0px;
    border-radius: 50%;
    background-color: white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.theme.text.para.extraSmall};
    border: 1px black solid;
  }
`;
