import React, { useMemo, useCallback } from "react";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import { getStatusType } from "helper";

import { Cell, CellIcon, UpdateStatusButton } from "../../style/display";
import Button from "components/Button";
import Icon from "components/Icon";
import { useSelector } from "react-redux";
import { isCurrentUserIsExecutive } from "common/Selectors/auth";

const colorCodes = {
  Hotel: "#7863CD",
  Food: "#CCB64D",
  Travel: "#C13C3B",
  "Training Or Certification": "#3EBCE0",
  Others: "#51546F",
};
const RenderItem = ({ data, columnIndex, rowIndex, style }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
    maxStatusLength,
    toggleModal,
  } = data;

  const cellvalue = rowData[rowIndex][columnIndex];

  const isAdmin = useSelector(isCurrentUserIsExecutive);

  const updateStatus = () => {
    toggleModal({ id: rowIndex });
  };

  const isDebit = useMemo(() => {
    return (
      rowIndex > 0 &&
      rowData[rowIndex][cellNameToColumnMap["amount"]].includes("-")
    );
  }, [rowData, cellNameToColumnMap, rowIndex]);

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["status"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const isAmountColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["amount"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const isExpenseTypeColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["expenseType"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const myChildren = useMemo(() => {
    if (isExpenseTypeColumn) {
      return (
        <Cell
          style={{
            color: colorCodes[cellvalue],
            fontWeight: "bold",
          }}
        >
          {cellvalue}
        </Cell>
      );
    }
    if (isAmountColumn) {
      return (
        <Cell
          style={{
            color: isDebit ? "red" : "green",
            fontWeight: "bold",
          }}
        >
          {cellvalue}
        </Cell>
      );
    }
    if (isStatusColumn) {
      return (
        <Cell>
          <Status
            text={cellvalue}
            type={getStatusType(cellvalue)}
            style={{ width: `${maxStatusLength * 18}px`, padding: "6px" }}
          />
          {isAdmin && (
            <UpdateStatusButton onClick={updateStatus}>
              <Icon name="fas fa-angle-down" />
            </UpdateStatusButton>
          )}
        </Cell>
      );
    }
    return null;
  }, [isStatusColumn, cellvalue]);

  return (
    <GridCell
      row={rowIndex}
      showHeading={showHeading}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

export default RenderItem;
