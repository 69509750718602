import { createAsyncActionCreator } from "helper";
import { SEND_PASSWORD_RESET_EMAIL } from "./actionTypes";
import { sendPasswordResetEmailApi } from "./api";

const sendPasswordResetEmailGenerator = function* ({ email }) {
  yield {
    action: SEND_PASSWORD_RESET_EMAIL,
    sideEffect: sendPasswordResetEmailApi({ email }),
  };
};
export const sendPasswordResetEmail = createAsyncActionCreator(
  sendPasswordResetEmailGenerator
);
