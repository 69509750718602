import { useCallback, useState } from "react";

const useChildDimensions = () => {
  const [elemPosition, setPosition] = useState(null);

  const updatePosition = (target) => {
    const { top, left, width, height, right } = target.getBoundingClientRect();
    setPosition({ top, left, width, height, right });
  };

  const setRef = useCallback((ref) => {
    if (!ref) {
      return;
    }
    updatePosition(ref);
  }, []);

  return [setRef, elemPosition];
};

export default useChildDimensions;
