import { GET_TEAM_MEMBERS, RESET_MEMBERS_DATA } from "../actiontypes";
import {
  createNewStoreOnGetData,
  createInitialStoreState,
} from "../../../Helper";

const initialData = createInitialStoreState({ count: null }, true);

export const membersReducer = (store = initialData, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${GET_TEAM_MEMBERS}_SUCCESS`: {
      const data = createNewStoreOnGetData(payload, store, true);
      return {
        ...data,
        count: payload?.meta?.memberCount,
      };
    }
    case RESET_MEMBERS_DATA:
      return initialData;

    default:
      return store;
  }
};
