import React, { useCallback } from "react";
import {
  NoticeContainer,
  NoticeDateTimeCont,
  NoticeDateCont,
  NoticeTimeCont,
  NoticeTitle,
  DeleteButton,
} from "./style";
import { NoticeViewList } from "../../../GlobalStyles";
import { getShortString } from "../../../Helper";
import Icon from "components/Icon";
import { useDeleteNotice } from "modules/Notice/Hooks/useDeleteNotice";

const NoticeView = ({ data, onClick, isDraft, ...other }) => {
  const { isLoadingId, deleteNotice } = useDeleteNotice();
  const onCellClick = useCallback(
    ({ notice, index }) => {
      if (typeof onClick !== "function") {
        return;
      }
      onClick({ notice, index });
    },
    [onClick]
  );

  const handleDelete = (e, { id }) => {
    e.stopPropagation();
    if (window.confirm("Are you sure you want to delete this notice?")) {
      deleteNotice(id);
    }
  };

  return (
    <NoticeViewList {...other}>
      {data.map((notice, index, data) => {
        const { noticeId, noticeTitle, time, date, published } = notice;
        const key = noticeId + index;
        let border = true;
        if (index === data.length - 1) {
          border = false;
        }
        if (published === "NA") {
          isDraft = true;
        } else {
          isDraft = false;
        }

        return (
          <NoticeContainer
            key={key}
            border={border}
            isDraft={isDraft}
            onClick={() => {
              onCellClick({ notice, index });
            }}
          >
            <NoticeTitle>{getShortString(noticeTitle, 25, " ", 5)}</NoticeTitle>
            <NoticeDateTimeCont>
              <NoticeTimeCont isDraft={isDraft}>{time}</NoticeTimeCont>
              <NoticeDateCont>{date}</NoticeDateCont>
            </NoticeDateTimeCont>
            {published === "NA" && (
              <DeleteButton
                size="sm"
                key={key}
                loading={isLoadingId === noticeId}
                onClick={(e) => handleDelete(e, { id: noticeId })}
              >
                <Icon
                  name="fas fa-trash"
                  onClick={(e) => handleDelete(e, { id: noticeId })}
                />
              </DeleteButton>
            )}
          </NoticeContainer>
        );
      })}
    </NoticeViewList>
  );
};

export default NoticeView;
