import React, { useCallback } from "react";
import Icon from "components/Icon";

import InputGroup from "components/InputGroup/inputGroup2";
import Button from "components/Button";
import { ListItem } from "components/List";

import {
  Header,
  FormHeading,
  ListBox,
  StyledEmptyData,
  LoaderContainer,
} from "../../style/create";

import ActivityIndicator from "../../../../Common/Components/ActivityIndicator";
import { FormContainer } from "../../style/create";
import { useGetMembers } from "../../Hooks/useGetMembers";
import { useMemberCreate } from "../../Hooks/useMemberCreate";

const Members = ({ updateIsFormSubmitted }) => {
  const { onFormSubmit, formData, values, isLoading, onSelectUser } =
    useMemberCreate({
      updateIsFormSubmitted,
    });

  const {
    selectedUsersList,
    notificationList,
    loadingNextPage,
    onEndReached,
    onceFetched,
  } = useGetMembers({
    values,
  });

  const renderSelectedUsers = useCallback(() => {
    return (
      selectedUsersList &&
      selectedUsersList?.map((item) => {
        return (
          <ListItem
            key={item.id}
            data={item}
            onSelect={onSelectUser}
            showDeselectButton={true}
          />
        );
      })
    );
  }, [selectedUsersList]);

  const renderListBox = useCallback(() => {
    const listConditions = !!(
      notificationList &&
      notificationList?.length > 0 &&
      ((!loadingNextPage && values?.memberName) || !values?.memberName)
    );

    return (
      <ListBox onScroll={onEndReached}>
        {notificationList?.length === 0 && !loadingNextPage && (
          <StyledEmptyData />
        )}
        {loadingNextPage && (!onceFetched || values?.memberName) && (
          <LoaderContainer>
            <ActivityIndicator size="m" />
          </LoaderContainer>
        )}
        {listConditions &&
          notificationList?.map((item) => {
            return (
              <ListItem
                key={item.id}
                data={item}
                onSelect={onSelectUser}
                showSelectButton={true}
              />
            );
          })}
        {loadingNextPage && onceFetched && !values.memberName && (
          <div
            style={{ display: "flex", margin: 40, justifyContent: "center" }}
          >
            <ActivityIndicator size="s" />
          </div>
        )}
      </ListBox>
    );
  }, [onEndReached, notificationList, loadingNextPage, values]);

  return (
    <FormContainer onSubmit={onFormSubmit}>
      <Header>
        <FormHeading>Add Members</FormHeading>
        <Icon
          name="fas fa-times"
          onClick={() => updateIsFormSubmitted(false)}
        />
      </Header>
      <InputGroup
        fieldType="input"
        label="Add member"
        placeholder="Your team member"
        {...formData["memberName"]}
        errorMessage="Collaborator is required"
      />
      {renderSelectedUsers()}
      {renderListBox()}
      <Button primary loading={isLoading} disabled={isLoading} type="submit">
        Add
      </Button>
    </FormContainer>
  );
};

export default Members;
