import styled from "styled-components";

export const InputWrapper = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;
export const Label = styled.label`
  font-size: ${(props) => props.theme.text.para.small};
  font-weight: normal;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.label};

  ${({ secondaryLabel, theme }) => {
    if (secondaryLabel) {
      return `
      font-size: ${theme.text.para.large};
      font-weight: 600;
      color: #1c1c1c;
      `;
    }
  }}
`;
