import styled, { keyframes } from "styled-components";
import Button from "components/Button";

const SlideIn = keyframes`
from {
    transform: translateY(-200%);
  }
  to {
    transform: translateY(0%);
  }
`;

const SlideOut = keyframes`
 from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-200%);
  }
 `;

const FadeIn = keyframes`
 from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
 `;

const FadeOut = keyframes`
 from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
 `;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => props.theme.zIndex.modal};
`;

export const Content = styled.div`
  position: absolute;
  z-index: calc(${(props) => props.theme.zIndex.modal} - 100);
  width: auto;
  height: auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadow.medium};
  transform: translateY(-200%);
  animation: ${({ active }) => (active ? SlideIn : SlideOut)} 0.3s linear 0s
    forwards;
`;

export const HeadingBox = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  padding: 10px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.modal.background};
  font-size: ${(props) => props.theme.text.title.medium};
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 5px;
  width: 30px;
  height: 30px;
  background-color: transparent;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: calc(${(props) => props.theme.zIndex.modal} - 500);
  animation: ${({ active }) => (active ? FadeIn : FadeOut)} 0.3s ease forwards;
`;
