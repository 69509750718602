import { combineReducers } from "redux";
import { sentNoticesReducer } from "./sentNotices";
import { draftNoticesReducer } from "./draftNotices";
import { singleNoticeReducer } from "./singleNotice";
import { allNoticesReducer } from "./allNotices";

export const noticesReducer = combineReducers({
  sent: sentNoticesReducer,
  draft: draftNoticesReducer,
  single: singleNoticeReducer,
  allNotices: allNoticesReducer,
});
