import StatusActivity from "./StatusActivityView";
import { ActivityMainContainer } from "../style";

const Activity = ({ data = [] }) => {
  return (
    <ActivityMainContainer>
      {data.length ? (
        data.map((activity) => {
          return <StatusActivity activity={activity} />;
        })
      ) : (
        <span>No activity to show here</span>
      )}
    </ActivityMainContainer>
  );
};

export default Activity;
