import React from "react";

import {
  RadioBoxWrapper,
  RadioBox,
  RadioLabel,
  Circle,
  CircleBox,
  CircleWrapper,
  Overlay,
} from "./style";
const Radio = ({
  name,
  disabled,
  value,
  label,
  checked = false,
  ...restProps
}) => {
  return (
    <RadioBoxWrapper>
      <input
        style={{
          display: "none",
        }}
        {...restProps}
        type="radio"
        id={label}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <RadioBox htmlFor={!disabled ? label : ""}>
        <CircleWrapper className="circle_out">
          <Overlay className="overlay" />
          <CircleBox>{checked && <Circle />}</CircleBox>
        </CircleWrapper>

        <RadioLabel>{label}</RadioLabel>
      </RadioBox>
    </RadioBoxWrapper>
  );
};
export default Radio;
