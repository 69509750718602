import React, {
  useCallback,
} from "react";

import useInput from "../useInput";

import { Container } from "./style";
import DatePicker from "./DatePicker";

const Views = {
  DAY: "day",
  MONTH: "month",
  YEAR: "year",
};

const DateRangePicker = ({
  size = "md",
  success = "",
  secondary = false,
  error = "",
  placeholder = "",
  listStyle = {},
  showPickerIcon = true,
  fieldClass = "",
  onBlur,
  name,
  fromDateClassName = '',
  toDateClassName = '',
  ...otherprops
}) => {
  const { icon, restInputProps } = useInput({
    ...otherprops,
    success,
    error,
  });
  success = success ? "true" : undefined;
  error = error ? "true" : undefined;

  const { onChange, value, ...rest } = restInputProps;

  const onDayChangeFrom = useCallback(
    (e) => {
      const d = e.target.value;
      onChange({
        target: {
          value: {
            ...value,
            toDate: value.toDate || d,
            fromDate: d
          },
          name
        }
      })
    },
    [
      onChange,
      value.fromDate,
      value.toDate
    ]
  );

  const onDayChangeTo = useCallback(
    (e) => {
      const d = e.target.value;
      onChange({
        target: {
          value: {
            ...value,
            fromDate: value.fromDate || d,
            toDate: d
          },
          name
        }
      })
    },
    [
      onChange,
      value.fromDate,
      value.toDate
    ]
  );

  return (
    <Container size={size} className={fieldClass}>
      <DatePicker
        size={size}
        success={success}
        secondary={secondary}
        error={error}
        placeholder="Start Date"
        listStyle={listStyle}
        showPickerIcon={showPickerIcon}
        fieldClass={`from-date-picker ${fromDateClassName}`}
        onBlur={onBlur}
        name={name}
        value={value.fromDate}
        toDate={value.toDate}
        isFromDate
        icon={icon}
        onChange={onDayChangeFrom}
      />
      <DatePicker
        size={size}
        success={success}
        secondary={secondary}
        error={error}
        placeholder="End Date"
        listStyle={listStyle}
        showPickerIcon={showPickerIcon}
        fieldClass={toDateClassName}
        onBlur={onBlur}
        name={name}
        value={value.toDate}
        fromDate={value.fromDate}
        isToDate
        icon={icon}
        onChange={onDayChangeTo}
      />
    </Container>
  );
};

export default DateRangePicker;
