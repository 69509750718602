import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getAllNotifiedWorkReports,
  isWorkReportSearchOn,
} from "../redux/selector";

import WorkReportView from "./WorkReportView";

const TableData = ({ onEmptyData, onCellClick, ...other }) => {
  const data = useSelector((store) => getAllNotifiedWorkReports(store));
  const isSearchOn = useSelector(isWorkReportSearchOn);

  const onClick = (reportId) => {
    if (typeof onCellClick === "function") {
      onCellClick({ rowIndex: reportId });
    }
  };

  useEffect(() => {
    if (typeof onEmptyData !== "function") {
      return;
    }

    if (!data.length && !isSearchOn) {
      onEmptyData(true);
    } else {
      onEmptyData(false);
    }
  }, [data]);

  return (
    <>
      {data.map((workReport) => {
        return <WorkReportView workReport={workReport} onClick={onClick} />;
      })}
    </>
  );
};

export default TableData;
