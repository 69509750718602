import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getCurrentUserCompany } from "../../../../Common/Selectors/auth";
import { getHolidayYearsOptions } from "../../../../Common/Selectors/holiday";

import { getHolidayYears, updateYearId } from "../../actionCreator";

import { StyledFormGroup } from "../../../../GlobalStyles";
import { FormHeading } from "../../styles/create";

import InputGroup from "components/InputGroup";
import Button from "components/Button";

const HolidayYearSelector = ({ updateYearId, companyId, yearOptions, getHolidayYears, setIsYearSelected, isAdmin }) => {

  useEffect(() => {
    getHolidayYears({ companyId, isAdmin });
  }, [companyId]);

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      await updateYearId({
        holiday_year_id: values.holidayCreate_holidayYear,
      })
      await setIsYearSelected(true);
    },
    [],
  );

  return (
    <StyledFormGroup onSubmit={onFormSubmit}>
      <FormHeading>Select year</FormHeading>

      <InputGroup
        id="holidayCreate_holidayYear"
        fieldType="select"
        required
        options={yearOptions}
        defaultSelectText="Select Year"
      />

      <Button primary
        type="submit">
        View Holiday List
      </Button>
    </StyledFormGroup>
  )
}

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const yearOptions = getHolidayYearsOptions(store, props);
  return {
    companyId,
    yearOptions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHolidayYears,
      updateYearId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HolidayYearSelector);