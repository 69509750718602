import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";

import useAnswereToPoll from "./useAnswereToPoll";
import { useDeletePost } from "./useDeletePost";
import useCreatePostComment from "./useCreatePostComment";
import useReactToPost from "./useReactToPost";
import useAnswereToQuiz from "./useAnswereToQuiz";

import { createCommentStore } from "../../redux/PostComments/actionCreator";

export const usePostFunctionality = () => {
  const dispatch = useDispatch();

  const [commentPostId, setCommentPostId] = useState();
  const [commentsModal, updateCommentsModal] = useState({
    active: false,
    postId: null,
  });

  const { postDelete, isLoading: isPostDeleting } = useDeletePost();

  const { createPostComment, isLoading: isCreatingComment } =
    useCreatePostComment();

  const {
    reactToPost,
    removeReactFromPost,
    hasError: hasErrorinReacting,
    isLoading: isReactingToPost,
  } = useReactToPost();

  const { answereToQuiz } = useAnswereToQuiz();
  const { answereToPoll } = useAnswereToPoll();

  const onLoadMoreComments = (postId) => {
    dispatch(createCommentStore({ postId }));
    updateCommentsModal({ postId, active: true });
  };

  const onCloseMoreComments = () => {
    updateCommentsModal({ postId: null, active: false });
  };

  const onComment = (postId, comment, callback) => {
    setCommentPostId(postId);
    createPostComment({ postId, comment }, callback);
  };

  const getCreateReactionProps = () => {
    return {
      reactToPost,
      removeReactFromPost,
      hasError: hasErrorinReacting,
      isLoading: isReactingToPost,
    };
  };

  const createQuizData = (postData) => {
    return postData.quizData
      ? {
          ...postData.quizData,
          onAnswere: answereToQuiz,
        }
      : null;
  };

  const createPollData = (postData) => {
    return postData.pollData
      ? {
          ...postData.pollData,
          onPoll: answereToPoll,
        }
      : null;
  };

  const onDelete = (postId) => {
    if (window.confirm("Are you sure you want to delete?")) {
      postDelete(postId);
    }
  };

  const postActionBtnProps = useMemo(() => {
    return [
      {
        icon: "fa fa-trash",
        btnText: "Delete Post",
        btnProps: {
          onClick: (postId) => onDelete(postId),
          loading: isPostDeleting,
        },
        iconProps: {
          style: { color: "red", fontSize: "13px" },
        },
      },
    ];
  }, [isPostDeleting]);

  return {
    postActionBtnProps,
    createPollData,
    createQuizData,
    getCreateReactionProps,
    onComment,
    onCloseMoreComments,
    onLoadMoreComments,
    isCreatingComment,
    commentPostId,
    commentsModal,
  };
};
