import React from "react";
import Collaborators from "./Collaborators";
import {
  MainContainer,
  NoticeSentHeading,
  Container,
  Heading,
  NoticeBody,
  Footer,
  TimeContainer,
  SubHeading
} from "../../../styles/sentNotice";
import DocumentView from "modules/LeaveManagement/Views/DocumentView";

const SentNoticePage = ({ isExecutive, noticeData }) => {
  const {
    noticeTitle: title,
    noticeBody: body,
    time,
    date,
    sentTo,
    sentToCollaborators,
    documents
  } = noticeData;

  return (
    <MainContainer>
      <Container>
        {isExecutive && <NoticeSentHeading>Notice Sent</NoticeSentHeading>}
        <Heading>{title}</Heading>
        <NoticeBody>{body}</NoticeBody>
        <Footer>
          <TimeContainer>
            {time}, {date}
          </TimeContainer>
          <Collaborators
            sentTo={sentTo}
            sentToCollaborators={sentToCollaborators}
          />
        </Footer>
        {documents != "NA" && (
          <>
            <SubHeading>Attachment ({documents.length})</SubHeading>
            <DocumentView data={documents} />
          </>
        )}
      </Container>
    </MainContainer>
  );
};
export default SentNoticePage;
