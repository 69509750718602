import { extractDateInYYYYMMDD } from "../../Helper";

export const generateFormData = (
  credentialValues,
  collaboratorValues,
  employeeValues,
  employmentValues
) => {
  const { email, password } = credentialValues;
  const { name, phoneNumber, gender, avatar, role } = collaboratorValues;
  const { employeeId, joiningDate } = employeeValues;
  const {
    priorExperience,
    noticePeriod,
    probationEndDate,
    employmentStatus,
    employmentType,
    confirmationDate,
    externalResource,
  } = employmentValues;

  const userData = {
    email: email.toLowerCase(),
    gender,
    password,
  };
  const collaboratorData = {
    name,
    phone: phoneNumber,
    role: role || "",
    employeeId,
    joinedOn: extractDateInYYYYMMDD(joiningDate),
    avatar: avatar["0"],
    priorExperience: priorExperience || "",
    probationEndDate: extractDateInYYYYMMDD(probationEndDate),
    employmentStatus: employmentStatus || "",
    employmentType: employmentType || "",
    noticePeriod: noticePeriod || "",
    expectedConfirmationDate: extractDateInYYYYMMDD(confirmationDate),
    externalResource: externalResource || "",
  };

  return { userData, collaboratorData };
};
