import { DELETE_COMMENT, EDIT_COMMENT } from "../ActionTypes";
import { createAsyncActionCreator } from "../../Helper";
import {
  createCommentApi,
  deleteCommentApi,
  editCommentApi,
  fetchCommentsApi,
} from "../Api";
import {
  CREATE_COMMENT,
  FETCH_ALL_COMMENTS,
  RESET_COMMENTS,
} from "../ActionTypes/comment";

const deleteCommentGenerator = function* ({ companyId, isAdmin, commentId }) {
  yield {
    action: DELETE_COMMENT,
    sideEffect: deleteCommentApi({ companyId, isAdmin, commentId }),
  };
};

const editCommentGenerator = function* ({
  companyId,
  isAdmin,
  commentId,
  message,
}) {
  yield {
    action: EDIT_COMMENT,
    sideEffect: editCommentApi({ companyId, isAdmin, commentId, message }),
  };
};

const fetchCommenstGenerator = function* ({
  pageNumber,
  companyId,
  isAdmin,
  commentableId,
  commentableType,
}) {
  yield {
    action: FETCH_ALL_COMMENTS,
    sideEffect: fetchCommentsApi({
      pageNumber,
      companyId,
      isAdmin,
      commentableId,
      commentableType,
    }),
  };
};

const createCommenstGenerator = function* ({
  companyId,
  isAdmin,
  commentableId,
  commentableType,
  message,
}) {
  yield {
    action: CREATE_COMMENT,
    sideEffect: createCommentApi({
      companyId,
      isAdmin,
      commentableId,
      commentableType,
      message,
    }),
  };
};

export const resetComments = () => ({
  type: RESET_COMMENTS,
});

export const [deleteComment, editComment, fetchComments, createComment] =
  createAsyncActionCreator([
    deleteCommentGenerator,
    editCommentGenerator,
    fetchCommenstGenerator,
    createCommenstGenerator,
  ]);
