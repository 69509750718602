import { useCallback, useEffect, useRef, useState } from "react";

import useWindowDimenions from "./useWindowDimensions";

const useElementDimensions = ({ updateWith }) => {
  const [elemnentDimension, updateDimenion] = useState({
    width: "auto",
    height: "auto",
  });
  const { dimesnions } = useWindowDimenions();
  const myEelemRef = useRef(null);
  const isFirstTimeRef = useRef(true);

  const updateElementDimenion = useCallback(() => {
    const { width, height } = myEelemRef.current.getBoundingClientRect();
    updateDimenion({ width, height });
  }, []);

  const setRef = useCallback((ref) => {
    if (!ref) {
      return;
    }

    myEelemRef.current = ref;
    updateElementDimenion();
  }, []);

  useEffect(() => {
    if (isFirstTimeRef.current) {
      isFirstTimeRef.current = false;
      return;
    }
    updateElementDimenion();
  }, [dimesnions, updateWith]);

  return {
    setRef,
    elemnentDimension,
    elemRef: myEelemRef,
  };
};

export default useElementDimensions;
