import React, { useMemo, useState } from "react";
import useInput from "./useInput";
import Icon from "../Icon";
import usePasswordViewer from "hooks/usePasswordPreview";

import {
  InputContainer,
  InputIcon,
  GlobalInput,
  ClearButton,
  SecondaryInputIcon
} from "./style";

const Input = ({
  onGivenKeyPressed = null,
  size = "md",
  success = "",
  secondary = false,
  error = "",
  fieldClass = "",
  ...otherprops
}) => {
  const { icon, restInputProps, showClearButton, onClear } = useInput({
    ...otherprops,
    success,
    error
  });
  const { type } = restInputProps;
  success = success ? "true" : undefined;
  error = error ? "true" : undefined;

  const { getFieldType, showPassword, toggleShowPassword } =
    usePasswordViewer();

  const [fieldType, isPasswordType] = useMemo(
    () => getFieldType(type),
    [getFieldType]
  );

  return (
    <InputContainer size={size} className={fieldClass}>
      <GlobalInput
        success={success}
        error={error}
        secondary={secondary}
        {...restInputProps}
        type={fieldType}
      />
      {isPasswordType && restInputProps?.value.length > 0 && (
        <SecondaryInputIcon
          name={showPassword ? "far fa-eye-slash" : "far fa-eye"}
          onClick={toggleShowPassword}
        />
      )}
      {!!icon && <InputIcon success={success} error={error} name={icon} />}
      {showClearButton && (
        <ClearButton secondary type="button" size="auto" onClick={onClear}>
          <Icon name="fas fa-times" />
        </ClearButton>
      )}
    </InputContainer>
  );
};

export default Input;
