import React, { useMemo } from "react";

import { ItemView } from "components/DetailsView";

import { transform2dMatrixTo1D } from "helper";
import { DetailsContainer } from "../../style/display";
import { UserAvatar } from "./UserAvatar";

const DetailView = ({ columns = [], data = [] }) => {
  let userName = "";
  const modifiedData = useMemo(() => {
    return transform2dMatrixTo1D(columns, data);
  }, [columns, data]);

  const filteredData = useMemo(() => {
    const blacklistColumns = [
      "status",
      "collaboratorType",
      "leftOn",
      "isExecutive",
      "actualCollaboratorType",
      "actualIsExecutive",
      "avatarUrl",
      "notificationCounter",
    ];
    let avtarData;
    const filterData = modifiedData.filter((item, index) => {
      if (item.heading === "name") {
        userName = item.result;
      } else if (item.heading === "avatarUrl") {
        avtarData = { ...item };
        avtarData.heading = "avatar";
        avtarData.isTable = false;
        avtarData.result = data[index];
      }
      return !blacklistColumns.includes(item.heading);
    });

    return avtarData ? [avtarData, ...filterData] : filterData;
  }, [modifiedData, data]);

  const DetailItem = useMemo(() => {
    return filteredData.map((item, index) => {
      if (item.heading === "avatar")
        return (
          <UserAvatar
            key={`${item.heading}_${index}`}
            heading={item.heading}
            result={item.result}
            userName={userName}
          />
        );
      if (item.result) {
        return (
          <ItemView
            key={`${item.heading}_${index}`}
            heading={item.heading}
            isTable={item.isTable}
            result={item.result}
            showUserImage={item.heading === "avatar"}
            userName={userName}
          />
        );
      }
    });
  }, [filteredData]);

  return <DetailsContainer>{DetailItem}</DetailsContainer>;
};

export default DetailView;
