import { extractDateInYYYYMMDD } from "../../Helper";

export const generateFormData = (values, userId) => {
  const {
    leadById,
    leadType,
    startDate,
    status,
  } = values;

  return {
    collaborator_id: userId,
    lead_by_id: Object.keys(leadById)[0],
    lead_type: leadType,
    start_date: extractDateInYYYYMMDD(startDate),
    status: status,
  }
};
