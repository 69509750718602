export const generateFormData = (values) => {
  const { name, address1, pincode, city, state, country } = values;
  const formData = {
    name,
    address_line1: address1,
    address_line2: values?.address2 || "",
    pincode,
    city,
    state,
    country,
    is_headquarter: values?.isHeadquarter?.is_headquarter || false,
  };
  return formData;
};
