import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";

import Icon from "../Icon";
import usePrevValue from "../../../Hooks/usePrevValue";

import {
  CollapseContainer,
  CollapseHeader,
  CollapseHeaderWrapper,
  Content,
  DropIcon,
} from "./style";

const Collapse = React.forwardRef(
  (
    {
      shouldBeCollapsed = false,
      icon = "",
      heaidng,
      header = null,
      className = "",
      headingClass,
      children,
    },
    ref
  ) => {
    const activeRef = useRef(false);
    const prevShouldBeCollapsed = usePrevValue({ value: shouldBeCollapsed });
    const [contentStyle, updateStyle] = useState({
      height: "auto",
      opacity: 0,
    });
    const initialHeight = useRef("auto");
    const maxHeight = useRef(null);
    const contentRef = useRef();
    const headerRef = useRef();
    const isHeaderSet = useRef(false);

    const setContentHeight = useCallback(() => {
      if (!contentRef.current) {
        return;
      }

      if (activeRef.current) {
        const contentHeight = contentRef.current.clientHeight;

        const newHeight =
          contentHeight === 0
            ? maxHeight.current + initialHeight.current
            : initialHeight.current + contentHeight;

        if (!maxHeight.current) {
          maxHeight.current = newHeight;
        }
        updateStyle({
          height: `${newHeight}px`,
          opacity: 1,
        });
      } else {
        updateStyle({ height: `${initialHeight.current}px`, opacity: 0 });
      }
    }, []);

    const toggleCollapse = useCallback(() => {
      activeRef.current = !activeRef.current;
      setContentHeight();
    }, [setContentHeight]);

    const setHeaderHeight = useCallback(() => {
      // if(!headerRef.current){
      //   return
      // }
      const { height } = headerRef.current.getBoundingClientRect();

      initialHeight.current = height;
      if (!activeRef.current) {
        updateStyle({ height: `${height}px`, opacity: 1 });
      } else {
        setContentHeight();
      }
    }, [setContentHeight]);

    const setHeaderRef = useCallback(
      (ref) => {
        if (!ref) {
          return;
        }
        headerRef.current = ref;
        setTimeout(() => {
          setHeaderHeight();
          isHeaderSet.current = true;
        }, [10]);
      },
      [setHeaderHeight]
    );

    useEffect(() => {
      if (shouldBeCollapsed) {
        activeRef.current = true;
      } else {
        activeRef.current = false;
      }

      if (!isHeaderSet.current) {
        return;
      }

      setContentHeight();
    }, [shouldBeCollapsed]);

    useImperativeHandle(ref, () => ({
      container: contentRef,
    }));

    return (
      <CollapseContainer
        style={{
          height: contentStyle.height,
        }}
        className={className}
      >
        <CollapseHeaderWrapper
          id="collapse_header"
          ref={setHeaderRef}
          role="button"
          onClick={toggleCollapse}
        >
          {header || (
            <CollapseHeader className={headingClass}>
              {!!icon && <Icon name={icon} />}
              <span>{heaidng}</span>
            </CollapseHeader>
          )}

          <DropIcon
            name={`fas fa-chevron-${activeRef.current ? "up" : "down"}`}
          />
        </CollapseHeaderWrapper>

        <Content ref={contentRef}>{children}</Content>
      </CollapseContainer>
    );
  }
);

export default Collapse;
