import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useAlert from "hooks/useAlert";

import { updateSubordinateAttendanceStatus } from "../actionCreator";
import { getCurrentUserCompany } from "common/Selectors/auth";
import { clearWorkinkingHourLogActivity } from "modules/Attendence/actionCreator";

function useUpdateSubordinateAttendanceStatus() {
  const [isLoading, updateLoading] = useState(false);
  const { showAlert } = useAlert();

  const dispatch = useDispatch();
  const companyId = useSelector(getCurrentUserCompany);

  const updateStatus = async ({ attendanceId, subordinateId, status }) => {
    try {
      updateLoading(true);
      await dispatch(
        updateSubordinateAttendanceStatus({
          companyId,
          attendanceId,
          subordinateId,
          status,
        }),
      );
      await dispatch(clearWorkinkingHourLogActivity());
    } catch (err) {
      showAlert({ message: err, type: "error" });
    } finally {
      updateLoading(false);
    }
  };

  return {
    updateStatus,
    isLoading,
  };
}

export default useUpdateSubordinateAttendanceStatus;
