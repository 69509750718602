import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import InputGroup from "components/InputGroup";
import Button from "components/Button";
import AsyncSearchSelect from "components/Input/AsyncSearchSelect";

import { getUserListData } from "common/Api";
import { isDateCombinationValid, changeToDateFormat } from "helper";

import { assetCategoryOptions, statusOptions } from "../Create/options";
import { updateSearchParams, resetSearchParams } from "../../actionCreator";

import { StyledForm, AsyncSearchField } from "../../style/display";
import { generateSearchFormData } from "../../utils";

const SearchFilter = ({
  updateSearchParams,
  resetSearchParams,
  assigned_to_id,
  category,
  from_date,
  to_date,
  status,
  isAdmin,
}) => {
  const [startDate, setStartDate] = useState("");
  const [InvalidMessage, setInvalidMessage] = useState("");
  // const [selectedUsers, updateSelectedUsers] = useState({});

  const onStartDateChange = useCallback((e) => {
    setStartDate(e.target.value);
  }, []);

  const endDateValidator = useCallback(
    (endDate) => {
      if (startDate) {
        const { isValid, message } = isDateCombinationValid(
          startDate,
          endDate,
          "Start",
          "End"
        );
        setInvalidMessage(message);
        return isValid;
      }
      return true;
    },
    [startDate]
  );

  // const onUserSelected = useCallback((e) => {
  //   const { value } = e.target;
  //   updateSelectedUsers(value);
  // }, []);

  const onFormSubmit = useCallback(
    async (values, resetForm) => {
      const data = generateSearchFormData(values);
      updateSearchParams({ filterData: data });
      // updateSearchParams({
      //   assigned_to_id: Object.keys(values.assetSearch_assetAssignee).length > 0 ? Object.keys(values.assetSearch_assetAssignee)[0] : '',
      //   category: values.assetSearch_assetCategory,
      //   from_date: changeToDateFormat(values.assetSearch_assetFromDate),
      //   to_date: changeToDateFormat(values.assetSearch_assetToDate),
      //   status: values.assetSearch_assetStatus,
      // })
    },
    [],
  );

  const onResetClick = useCallback(
    () => {
      resetSearchParams();
      setStartDate("");
      // updateSelectedUsers("");
    },
    [],
  );

  return (
    <>
      <StyledForm onSubmit={onFormSubmit} onReset={onResetClick}>
        {isAdmin &&
          <AsyncSearchField>
            <InputGroup
              id="assetSearch_assetAssignee"
              fieldType="asyncSearchSelect"
              // value={selectedUsers}
              // onChange={onUserSelected}
              placeholder="Search for users"
              api={getUserListData}
              singleSelect
            />
          </AsyncSearchField>
        }

        <InputGroup
          id="assetSearch_assetCategory"
          fieldType="select"
          options={assetCategoryOptions}
          defaultSelectText="Select Category"
        />

        <InputGroup
          id="assetSearch_assetFromDate"
          fieldType="datePicker"
          placeholder="Start date"
          onChange={onStartDateChange}
        />

        <InputGroup
          id="assetSearch_assetToDate"
          fieldType="datePicker"
          placeholder="End date"
          validator={endDateValidator}
          invalidMessage={InvalidMessage}
        />

        <InputGroup
          id="assetSearch_assetStatus"
          fieldType="select"
          options={statusOptions}
          defaultSelectText="Select Status"
        />

        <Button primary type="submit" size="lg">
          Search
        </Button>
        <Button
          outline
          disabled={!assigned_to_id && !category && !from_date && !to_date && !status}
          type="reset"
          size="lg"
        >
          Reset
        </Button>
      </StyledForm>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSearchParams,
      resetSearchParams,
    },
    dispatch
  );


export default connect(null, mapDispatchToProps)(SearchFilter);
