import styled from "styled-components";

export const ChipContainer = styled.div`
  padding: 5px 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.chip.background};
  font-size: ${(props) => props.theme.text.para.small};
  color: ${(props) => props.theme.colors.chip.textColor};
  font-weight: 500;
  margin: 0 5px;
`;
