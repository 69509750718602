import { MonthIndex } from "constants";

export const createDayMatrix = ({ rows, columns, startingDay, totalDays }) => {
  const days = [];
  let day = 1;

  for (let i = 0; i <= rows; i++) {
    const row = [];
    for (let j = 0; j <= columns; j++) {
      if (i === 0) {
        if (j >= startingDay && day <= totalDays) {
          row.push(day++);
        } else {
          row.push("");
        }
        continue;
      }

      if (day <= totalDays) {
        row.push(day++);
      } else {
        row.push("");
      }
    }

    days.push(row);
  }

  return days;
};

export const createMonthMatrix = ({ rows, columns }) => {
  const months = [];
  let count = 0;

  for (let i = 0; i < rows; i++) {
    const row = [];
    for (let j = 0; j < columns; j++) {
      if (count <= 11) {
        row.push({ name: MonthIndex[count], value: count });
        count++;
      } else {
        row.push("");
      }
    }
    months.push(row);
  }

  return months;
};

export const createYearMatrix = ({ startingYear, endingYear }) => {
  const years = [];
  let start = startingYear;

  let row = [];
  let count = 0;

  while (start <= endingYear) {
    row.push(start++);
    count++;

    if (count === 4) {
      count = 0;
      years.push([...row]);
      row = [];
    }
  }
  return years;
};
