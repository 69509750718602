import React, { useCallback, useRef, useState } from "react";

import PostIconItemList from "./PostIconItemList";
import PostHeader from "./PostHeader";

import InputGroup from "components/InputGroup/inputGroup2";
import ImageSlider from "components/ImageSlider";
import GenericSettings from "components/GenericSettings";

import useUserInformation from "hooks/useUserInformation";
import useCdnUrl from "hooks/useCdnUrl";
import useForm from "hooks/useForm";

import {
  Footer,
  FormArea,
  SectionHeader,
  Section,
  StyledProfilepic,
  PostButton,
  ImageViewContainer,
  PostCreateContainer,
} from "./style";

function PostCreate({
  onClose,
  onCreatePost,
  onPostTypeChange,
  isLoading,
  ...rest
}) {
  const [imageUrls, updateImageUrls] = useState([]);
  const imageFilesRef = useRef([]);

  const { formData, values, isFormValid } = useForm({
    fields: {
      description: {
        required: true,
      },
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }
    onCreatePost &&
      onCreatePost({
        ...values,
        ...(imageFilesRef.current.length > 0
          ? { photos: imageFilesRef.current }
          : {}),
      });
  };

  const onImageFileChange = useCallback((e) => {
    const newUrls = [];
    for (const file of e.target.files) {
      newUrls.push(URL.createObjectURL(file));
      imageFilesRef.current.push(file);
    }
    updateImageUrls((val) => [...newUrls, ...val]);
  }, []);

  const deleteImage = (imageindex) => {
    imageFilesRef.current.splice(imageindex, 1);
    updateImageUrls((val) => {
      const cp = [...val];
      cp.splice(imageindex, 1);
      return cp;
    });
  };

  const renderSettings = useCallback((imageIndex) => {
    const list = [
      { name: "far fa-trash-alt", onClick: deleteImage.bind(this, imageIndex) },
    ];
    return <GenericSettings iconList={list} />;
  }, []);

  const { userInfo } = useUserInformation();
  const { cdnUrl, url } = userInfo.avatarUrl || {};

  return (
    <PostCreateContainer {...rest}>
      <PostHeader title="Create a post" onClose={onClose} />

      <Section>
        <SectionHeader>
          <StyledProfilepic
            cdnImg={cdnUrl}
            img={url}
            userName={userInfo.name}
          />
          <span className="title">{userInfo.name}</span>
        </SectionHeader>

        <FormArea onSubmit={onSubmit}>
          <InputGroup
            fieldType="textArea"
            placeholder="What do you want to post about?"
            fillParent={true}
            wrapperStyle={{ minHeight: "100px" }}
            $borderless={true}
            {...formData["description"]}
          />
          {imageUrls.length > 0 && (
            <ImageViewContainer>
              <ImageSlider images={imageUrls} renderSettings={renderSettings} />
            </ImageViewContainer>
          )}
          <Footer>
            <PostIconItemList
              imageInputId="post_image_picker"
              videoInputId="post_video_picker"
              onClick={onPostTypeChange}
            />
            <PostButton loading={isLoading} disabled={isLoading} type="submit">
              Post
            </PostButton>

            <input
              onChange={onImageFileChange}
              className="hide"
              type="file"
              id="post_image_picker"
              multiple
              accept="image/*"
            />
            <input
              accept="video/*"
              className="hide"
              type="file"
              id="post_video_picker"
            />
          </Footer>
        </FormArea>
      </Section>
    </PostCreateContainer>
  );
}

export default PostCreate;
