import { useState } from "react";

function useForceReRender() {
  const [tempVal, updateTemp] = useState(0);
  const forceReRender = () => {
    updateTemp((val) => val + 1);
  };
  return [tempVal, forceReRender];
}

export default useForceReRender;
