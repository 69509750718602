import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 250px;
  overflow-x: scroll;

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.primaryColor};
    border-radius: 10px;
  }
`;


export const BigImage = styled.img`
  max-width: 500px;
  min-width: 280px;
  min-height: 200px;
  height: 100%;
  object-fit: contain;
  width: 100%;
  max-height: 500px;
`;

export const ImageContainer = styled.div`
  display:flex;
  position: relative;

  & div, img {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #dbdbdb;
    margin: 4px;
  }

  & div:hover {
    opacity:1;
  }
`;

export const Overlay = styled.div`
  z-index: 10;
  display: flex;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.23);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

export const OtherImage = styled.img`
  object-fit: contain;
`;

export const Image = styled.img`
  width: 38px;
  height: 38px;
  object-fit: contain;
`;

export const Link = styled.a`
`;