import React from "react";
import { withTheme } from "styled-components";

const Icon = ({ color, theme, ...otherProps }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill={color || theme.colors.primaryColor}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M5.94663 26C5.48559 25.9009 5.01421 25.835 4.5646 25.6973C0.578 24.4791 -1.22424 19.7904 0.899149 16.2038C0.975898 16.0743 0.991139 15.8898 0.982974 15.7335C0.957391 15.2355 0.874654 14.7391 0.871933 14.2421C0.859413 11.999 0.865945 9.75528 0.866489 7.51213C0.866489 7.07123 1.00203 6.92917 1.43803 6.92917C8.59148 6.92808 15.7449 6.9259 22.8984 6.9308C24.4089 6.93189 25.6336 7.85941 25.9139 9.29152C26.0337 9.90388 25.9542 10.5554 25.958 11.1896C25.9586 11.3229 25.9406 11.4595 25.9079 11.5891C25.5759 12.9069 24.3713 13.8502 23.0122 13.86C22.5816 13.8633 22.151 13.8605 21.6808 13.8605C21.6808 13.9912 21.684 14.1055 21.6802 14.2198C21.5523 18.1639 19.7147 20.9955 16.1358 22.6649C14.8648 23.2577 13.498 23.4433 12.1023 23.3998C11.8166 23.391 11.6337 23.4732 11.4415 23.6991C10.3382 25.0011 8.92678 25.7485 7.23014 25.9548C7.17353 25.9619 7.11855 25.9842 7.06303 25.9994C6.69126 26 6.31895 26 5.94663 26ZM1.76026 15.0357C4.21896 12.5144 7.74942 12.5427 9.99964 14.0113C11.1378 14.7538 11.9902 15.7363 12.5046 16.9958C13.2677 18.865 13.1567 20.7075 12.2319 22.5963C12.9493 22.5125 13.603 22.4886 14.2328 22.3541C18.0001 21.5518 20.6514 18.4481 20.7859 14.5997C20.8632 12.3849 20.8082 10.1657 20.8115 7.94814C20.8115 7.90731 20.7995 7.86703 20.7908 7.81314C14.4407 7.81314 8.09887 7.81314 1.75972 7.81314C1.76026 10.2125 1.76026 12.5955 1.76026 15.0357ZM21.6954 12.9869C22.2011 12.9869 22.7008 13.0212 23.194 12.9798C24.1694 12.8982 24.9641 12.1606 25.0975 11.1934C25.1639 10.7116 25.172 10.2103 25.123 9.72643C25.0251 8.75591 24.3452 7.99604 23.3986 7.85179C22.8478 7.76797 22.2741 7.83655 21.696 7.83655C21.6954 9.54027 21.6954 11.2532 21.6954 12.9869ZM6.50401 25.1361C9.60718 25.1356 12.141 22.6001 12.1405 19.4964C12.1399 16.3905 9.60827 13.8622 6.5002 13.8633C3.39214 13.8644 0.867578 16.3922 0.867578 19.5019C0.867578 22.6089 3.39595 25.1367 6.50401 25.1361Z"
          fill={color || theme.colors.primaryColor}
        />
        <path
          d="M11.1623 5.17866C10.9647 5.17866 10.7045 5.17866 10.4264 5.17866C10.3937 4.88636 10.4944 4.63924 10.6343 4.40954C10.7121 4.28162 10.8177 4.16677 10.925 4.06063C11.3942 3.59741 11.3985 3.32634 10.9342 2.8702C10.2244 2.17293 10.2331 1.27425 10.9418 0.566093C11.0975 0.410417 11.2091 0.211196 11.3653 0C11.5727 0 11.8416 0 12.1312 0C12.1274 0.426203 11.9673 0.771846 11.6827 1.06741C11.5945 1.15886 11.4992 1.24377 11.4187 1.34066C11.2211 1.57853 11.2183 1.86375 11.4127 2.10597C11.4862 2.19797 11.5743 2.27852 11.6592 2.36072C12.2977 2.98124 12.2966 3.92945 11.669 4.55759C11.496 4.73069 11.3566 4.93753 11.1623 5.17866Z"
          fill={color || theme.colors.primaryColor}
        />
        <path
          d="M15.5054 5.17585C15.3035 5.17585 15.0357 5.17585 14.7483 5.17585C14.7548 4.79319 14.8811 4.47476 15.1217 4.19988C15.1995 4.11116 15.2893 4.03278 15.371 3.94732C15.6965 3.60821 15.703 3.30502 15.3688 2.97625C15.1119 2.72314 14.8794 2.45969 14.7918 2.0999C14.6519 1.52292 14.8223 1.03902 15.2267 0.615534C15.39 0.444618 15.5217 0.243763 15.7101 0.00317383C15.8913 0.00317383 16.1673 0.00317383 16.4618 0.00317383C16.4656 0.404338 16.3175 0.731474 16.0644 1.01833C16.0084 1.08147 15.9441 1.13699 15.8843 1.19687C15.5043 1.57953 15.4978 1.88652 15.8919 2.2572C16.2266 2.57182 16.4514 2.92889 16.4672 3.39973C16.483 3.85805 16.3137 4.23472 15.9969 4.5624C15.8271 4.7393 15.6878 4.94615 15.5054 5.17585Z"
          fill={color || theme.colors.primaryColor}
        />
        <path
          d="M6.08163 5.18794C6.08218 4.74704 6.24656 4.40847 6.52471 4.11726C6.60636 4.0318 6.69672 3.95342 6.77401 3.86415C6.98793 3.61703 6.98956 3.31058 6.77836 3.0591C6.70815 2.97528 6.62595 2.90071 6.54812 2.82341C5.91344 2.19255 5.91888 1.2574 6.5492 0.623271C6.71631 0.455076 6.84858 0.252044 7.04127 0.00927734C7.22308 0.00927734 7.49742 0.00927734 7.79189 0.00927734C7.79734 0.421328 7.63676 0.759895 7.36134 1.05219C7.2688 1.15072 7.16483 1.23999 7.07938 1.34395C6.88451 1.58236 6.89322 1.89535 7.09788 2.13104C7.17518 2.22031 7.26445 2.29923 7.34827 2.38306C7.94812 2.98072 7.94757 3.92022 7.3646 4.5304C7.20076 4.70241 7.08319 4.9185 6.94003 5.11065C6.9139 5.14548 6.86546 5.18359 6.82572 5.18467C6.58295 5.19121 6.33964 5.18794 6.08163 5.18794Z"
          fill={color || theme.colors.primaryColor}
        />
        <path
          d="M6.49968 24.6991C3.62132 24.6948 1.30251 22.3727 1.30469 19.4971C1.30687 16.6176 3.62839 14.2994 6.50512 14.3037C9.38567 14.3081 11.7012 16.6258 11.7001 19.5036C11.6996 22.3792 9.37315 24.7035 6.49968 24.6991ZM2.17288 19.484C2.16254 21.8768 4.07528 23.8097 6.4741 23.8304C8.87074 23.8511 10.8238 21.9171 10.833 19.5139C10.8417 17.1178 8.92408 15.1817 6.53071 15.1708C4.11991 15.1594 2.18377 17.0759 2.17288 19.484Z"
          fill={color || theme.colors.primaryColor}
        />
        <path
          d="M4.03436 18.5756C4.18133 18.3197 4.31523 18.0873 4.46492 17.826C4.80947 18.0236 5.14314 18.2152 5.47736 18.4052C5.73754 18.5533 6.06577 18.3633 6.06685 18.0617C6.06849 17.4864 6.0674 16.9116 6.0674 16.3362C6.0674 16.2453 6.0674 16.1539 6.0674 16.0499C6.36405 16.0499 6.63186 16.0499 6.93722 16.0499C6.93722 16.1435 6.93722 16.2328 6.93722 16.3221C6.93722 16.9056 6.93015 17.4897 6.9443 18.0726C6.94648 18.1652 7.01996 18.2942 7.09889 18.3404C7.68947 18.6877 7.94639 19.3278 7.72485 19.9451C7.50658 20.552 6.88279 20.9014 6.24594 20.7741C5.60364 20.6456 5.18941 20.0948 5.19921 19.4089C5.2003 19.3273 5.12464 19.2124 5.05115 19.1667C4.72293 18.9626 4.38382 18.7764 4.03436 18.5756ZM6.9296 19.4889C6.92416 19.2462 6.73038 19.0644 6.48544 19.0736C6.25465 19.0818 6.07393 19.2679 6.07339 19.4982C6.07284 19.7382 6.26771 19.9315 6.50721 19.9293C6.7478 19.9271 6.93505 19.7317 6.9296 19.4889Z"
          fill={color || theme.colors.primaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="25.9809"
            height="26"
            fill={color || theme.colors.primaryColor}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const TeaTimeIcon = withTheme(Icon);
