import {
  CREATE_COMPANY_BRANCH,
  GET_COMPANY_BRANCH,
  EDIT_COMPANY_BRANCH,
} from "./actiontypes";
import {
  createInitialStoreState,
  createNewStoreOnCreate,
  createNewStoreOnGetData,
  createNewStoreOnUpdate,
} from "../../Helper";

const initialState = createInitialStoreState();

export const branchReducer = (store = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${CREATE_COMPANY_BRANCH}_SUCCESS`:
      return createNewStoreOnCreate(payload, store);

    case `${EDIT_COMPANY_BRANCH}_SUCCESS`: {
      return createNewStoreOnUpdate(payload, store);
    }
    case `${GET_COMPANY_BRANCH}_SUCCESS`: {
      return createNewStoreOnGetData(payload, store);
    }

    default:
      return store;
  }
};
