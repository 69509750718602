import React from "react";
import Header from "./Header";
import ProfileItem from "./ProfileItem";

import Mail_Icon from "assets/common/mail.svg";
import Clock_Icon from "assets/common/clock.svg";
import Profile_Icon from "assets/common/Profile.svg";
import Routes from "route/Path";

import { Container, ListIcon, ProfileContent, StyledLink } from "./style";

const ProfilePopoverContent = ({ data, color }) => {
  const { name, roleType, email, joinedOn, avatarUrl } = data;
  return (
    <Container>
      <Header
        color={color}
        image={avatarUrl}
        name={name}
        designation={roleType}
      />
      <ProfileContent>
        <ProfileItem icon={Mail_Icon} text={email} iconDesc="email icon" />
        <ProfileItem icon={Clock_Icon} text={joinedOn} iconDesc="clock icon" />
        <StyledLink
          to={`${Routes.authorized.userProfile.view.path}/${data?.id}`}
        >
          <ListIcon src={Profile_Icon} />View Profile
        </StyledLink>
      </ProfileContent>
    </Container>
  );
};

ProfilePopoverContent.deaultProps = {
  color: null,
};
export default ProfilePopoverContent;
