import styled from "styled-components";
import { StyledActionArea, StyledFormGroup } from "globalStyles";
import FormGroup from "components/FormGroup";
import Button from "components/Button";

export const EnhancedActionArea = styled(StyledActionArea)`
  flex-flow: row wrap;
  align-items: center;

  @media (min-width: 768px) {
    justify-content: center;
  }
`;

export const SearchArea = styled.div`
  flex-basis: 100%;
  align-self: center;

  @media (min-width: 1024px) {
    flex-basis: 80%;
  }

  & > form > button {
    margin-top: 5px;
    flex-basis: 75%;
  }
`;

export const StyledForm = styled(FormGroup)`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 20px;

  & div {
    height: 35px;
  }

  & div > select {
    height: 35px;
    padding: 0 10px;
  }

  & button {
    height: 35px;
    padding: 7px;
  }

  @media (min-width: 768px) {
    flex-flow: row;
  }
`;

export const HolidayYearTitle = styled.h1`
  font-size: ${(props) => props.theme.text.title.large};
  aling-self: center;

  & span {
    display: inline-block;
    margin-right: 10px;
  }
`;
