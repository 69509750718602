import React from "react";
import Button from "components/Button";
import InputGroup from "components/InputGroup/inputGroup2";

import { getUserListData } from "common/Api";
import { StatusOptions } from "../../constants";

import { FormCont, FormButtons } from "../../style/display";

const ActionForm = ({ formData, onSearch, resetForm }) => {
  return (
    <FormCont onSubmit={onSearch} onReset={resetForm}>
      <InputGroup
        fieldType="asyncSearchSelect"
        placeholder="Search for users"
        api={getUserListData}
        singleSelect
        {...formData["user"]}
      />

      <InputGroup
        fieldType="datePicker"
        placeholder="Start Date"
        {...formData["startDate"]}
      />
      <InputGroup
        fieldType="datePicker"
        placeholder="End Date"
        {...formData["endDate"]}
      />

      <InputGroup
        fieldType="select"
        options={StatusOptions}
        defaultSelectText="Status"
        {...formData["status"]}
      />

      <FormButtons>
        <Button primary type="submit">
          Search
        </Button>
        <Button outline type="reset">
          Reset
        </Button>
      </FormButtons>
    </FormCont>
  );
};

export default ActionForm;
