import React from "react";
import { SpinnerContainer } from "./style";

const ActivityIndicator = ({ size, color = "", className, ...otherProps }) => {
  return (
    <SpinnerContainer
      {...otherProps}
      data-testid="activity_indicator"
      $primary={otherProps.primary}
      $secondary={otherProps.secondary}
      className={`${className} activity_indicator`}
      $size={size}
      style={{
        borderColor: color,
      }}
    />
  );
};

export default ActivityIndicator;
