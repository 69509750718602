import React, { useMemo } from "react";

import Tooltip from "components/Tooltip2";

import { postReactions } from "../constants";

import { PostReactionsContainer, PostReactionContainer } from "./style";

function PostReactions({ onReaction, ...rest }) {
  const list = useMemo(() => {
    return postReactions.map((item, idx) => {
      const tipKey = `reaction_tip_${item.id}`;
      return (
        <React.Fragment key={item.id}>
          <PostReaction
            image={item.image}
            onClick={() => onReaction(item.id)}
            data-tooltip={tipKey}
            data-testid={item.id}
            style={{ animationDelay: `${(idx + 1) * 50}ms` }}
          />
          <Tooltip
            style={{ textTransform: "capitalize" }}
            tooltipId={tipKey}
            position="top"
          >
            {item.id}
          </Tooltip>
        </React.Fragment>
      );
    });
  }, [postReactions]);
  return <PostReactionsContainer {...rest}>{list}</PostReactionsContainer>;
}

function PostReaction({ image, ...rest }) {
  return (
    <PostReactionContainer {...rest}>
      <img src={image} />
    </PostReactionContainer>
  );
}

export default PostReactions;
