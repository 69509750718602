import requestData from "../../ApiHandler";

export const updatePasswordApi = ({
  token,
  email,
  password,
  confirmPassword,
}) => {
  return requestData(
    `/update_password?password_reset_token=${token}&email=${email}`,
    {
      method: "POST",
      body: {
        password: {
          password: password,
          password_confirmation: confirmPassword,
        },
      },
      isFormData: true,
    }
  );
};
