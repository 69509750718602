import React, { useState } from "react";
import {
  Container,
  ViewPortContainer,
  BackgroundImage,
  GroupImage,
  StyledLink,
  StyledButton,
} from "../styles";
import GreenIrregular from "../assets/GreenIrregular.png";
import Group from "../assets/Group.png";
import ForgotPasswordPage from "./ForgotPasswordPage";
import SuccessfulPage from "./SuccessfulPage";

const ForgotPassword = () => {
  const [successStatus, setsuccessStatus] = useState(false);

  return (
    <ViewPortContainer>
      <BackgroundImage src={GreenIrregular} />
      <GroupImage src={Group} />
      <Container>
        {!successStatus ? (
          <ForgotPasswordPage setsuccessStatus={setsuccessStatus} />
        ) : (
          <SuccessfulPage />
        )}
      </Container>
      <StyledLink to="/">
        <StyledButton primary size="lg">Back To Home</StyledButton>
      </StyledLink>
    </ViewPortContainer>
  );
};
export default ForgotPassword;
