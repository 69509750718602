import React from "react";
import { TableView } from "./TableView";
import { DataItem, DetailHead } from "./style";
import { generateTableHeading } from "../../../Helper";
import ProfilePic from "../ProfilePic";

export const ItemView = ({
  heading,
  isTable,
  userName,
  result,
  showUserImage,
}) => {
  return (
    <DataItem isTable={isTable}>
      <DetailHead>{generateTableHeading(heading)}</DetailHead>
      {isTable && <TableView data={result} />}
      {showUserImage && <ProfilePic img={result.url} userName={userName} />}
      {!isTable && !showUserImage && <span>{result}</span>}
    </DataItem>
  );
};

ItemView.defaultProps = {
  isTable: false,
  userName: "",
  showUserImage: false,
};
