import { tooltip_offset } from "./constants";

export function getModifiedStyle(actualStyle, position, contentStyle) {
  const { width, height } = contentStyle;
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;

  switch (position) {
    case "top": {
      const outsourcingWindow = actualStyle.bottom + height > winHeight;
      return {
        left: actualStyle.left - width / 2,
        ...(outsourcingWindow ? { top: 0 } : { bottom: actualStyle.bottom }),
      };
    }
    case "bottom": {
      const outsourcingWindow = actualStyle.top + height > winHeight;
      return {
        left: actualStyle.left - width / 2,
        ...(outsourcingWindow ? { bottom: 0 } : { top: actualStyle.top }),
      };
    }

    case "left": {
      const outsourcingWindow = actualStyle.right + width > winWidth;
      return {
        top: actualStyle.top - height / 2,
        ...(outsourcingWindow ? { left: 0 } : { right: actualStyle.right }),
      };
    }
    case "right": {
      const outsourcingWindow = actualStyle.left + width > winWidth;
      return {
        top: actualStyle.top - height / 2,
        ...(outsourcingWindow ? { right: 0 } : { left: actualStyle.left }),
      };
    }
    default:
      return {};
  }
}

export function calcPosition(target, position) {
  let { top, left, width, height, right, bottom } =
    target.getBoundingClientRect();
  const winHeight = window.innerHeight;
  const winWidth = window.innerWidth;
  switch (position) {
    case "top":
      return {
        bottom: winHeight - bottom + height + tooltip_offset,
        left: left + width / 2,
      };
    case "bottom":
      return { top: top + height + tooltip_offset, left: left + width / 2 };
    case "left":
      return {
        top: top + height / 2,
        right: winWidth - right + width + tooltip_offset,
      };
    case "right":
      return { top: top + height / 2, left: left + width + tooltip_offset };
    default:
      return {};
  }
}
