import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ViewContainer } from "../../../../GlobalStyles";

import { updateYearId, resetSearchParams } from "../../actionCreator";

import HolidayYearSelector from "./HolidayYearSelector";
import ActionArea from "./ActionArea";
import TableData from "./TableData";

import InfoView from "components/InfoView";
import EmptyScreen from "assets/employee/HolidayBlank.png";
import { RectangleSkeleton } from "components/Skeleton";

import { isCurrentUserIsExecutive } from "../../../../Common/Selectors/auth";
import { useLocation } from "react-router";
import { useDeleteHoliday } from "modules/Holiday/hooks/useDeleteHoliday";

const HolidayDisplayView = ({ updateYearId, resetSearchParams, isAdmin }) => {
  const [isEmpty, updateEmptyStatus] = useState(false);
  const [isLoading, updateLoading] = useState(true);
  const [isYearSelected, setIsYearSelected] = useState(false);
  const { isLoading: deleteingHoliday, deleteHoliday } = useDeleteHoliday();
  const location = useLocation();
  const hasCurrentYearSelected = location?.state?.currentYear;

  useEffect(() => {
    if (!isAdmin || hasCurrentYearSelected) {
      setIsYearSelected(true);
    }

    if (!isYearSelected && !hasCurrentYearSelected) {
      resetSearchParams();
      updateYearId({ holiday_year_id: "" });
    }
  }, [isAdmin, isYearSelected, hasCurrentYearSelected]);

  const onEmptyData = useCallback((status) => {
    updateEmptyStatus(status);
  }, []);

  const onDbDataChange = useCallback((status) => {
    updateLoading(status);
  }, []);

  return (
    <ViewContainer>
      {isYearSelected ? (
        <>
          <ActionArea isAdmin={isAdmin} onLoading={onDbDataChange} />
          <TableData
            style={{
              display: isLoading || isEmpty ? "none" : "inherit",
            }}
            onEmptyData={onEmptyData}
            isDeletingHoliday={deleteingHoliday}
            onDeleteHoliday={deleteHoliday}
          />
          {!isLoading && isEmpty && (
            <InfoView
              image={EmptyScreen}
              desc={"There are no Holidays to display yet"}
            />
          )}
          {isLoading && <RectangleSkeleton times={4} />}
        </>
      ) : (
        isAdmin && (
          <HolidayYearSelector
            setIsYearSelected={setIsYearSelected}
            isAdmin={isAdmin}
          />
        )
      )}
    </ViewContainer>
  );
};

const mapStateToProps = (store, props) => {
  return {
    isAdmin: isCurrentUserIsExecutive(store, props),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateYearId,
      resetSearchParams,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HolidayDisplayView);
