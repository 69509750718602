import React from "react";
import Button from "components/Button";

import { DescriptionContainer } from "./style";

function EndView({ onClose }) {
  return (
    <DescriptionContainer
      data-testid="survey_end"
      style={{ textAlign: "center", alignItems: "center" }}
    >
      <span className="heading">Thank you 👍 </span>
      <span className="desc">for your feedback</span>
      <Button primary onClick={onClose}>
        Continue
      </Button>
    </DescriptionContainer>
  );
}

export default EndView;
