import React from "react";

import {
  StyledHeader,
  UserDesignation,
  UserName,
  StyledProfilePic,
} from "./style";

const Header = ({ name, designation, image, color }) => {
  return (
    <StyledHeader>
      <UserName>{name}</UserName>
      {!!designation && <UserDesignation>{designation}</UserDesignation>}
      <StyledProfilePic color={color} img={image} userName={name} />
    </StyledHeader>
  );
};

Header.deaultProps = {
  color: null,
};

export default Header;
