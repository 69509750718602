import React, { useMemo } from "react";

import GridCell from "components/Grid/GridCell";
import Status from "components/Status";
import { getStatusType } from "helper";

import { Cell } from "../../style/display";
import { getIcon } from "../../../../Helper";
import SnapViewer from "./SnapViewer";

const RenderItem = ({ data, columnIndex, rowIndex, style }) => {
  const {
    rowData,
    showHeading,
    currentActiveRow,
    activateRow,
    cellNameToColumnMap,
  } = data;

  const cellvalue = rowData[rowIndex][columnIndex];

  const isStatusColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["status"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const isIconCodeColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["iconCode"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const isSnapColumn = useMemo(() => {
    return rowIndex > 0 && cellNameToColumnMap["snap"] === columnIndex;
  }, [rowIndex, columnIndex, cellNameToColumnMap]);

  const myChildren = useMemo(() => {
    if (isSnapColumn) {
      return <SnapViewer data={cellvalue} />;
    }
    if (isIconCodeColumn) {
      return <Cell>{getIcon(cellvalue)}</Cell>;
    }
    if (isStatusColumn) {
      return (
        <Cell>
          <Status text={cellvalue} type={getStatusType(cellvalue)} />
        </Cell>
      );
    }
    return null;
  }, [isStatusColumn, cellvalue]);

  return (
    <GridCell
      row={rowIndex}
      showHeading={showHeading}
      key={`${rowIndex}_${columnIndex}`}
      columnName={rowData[0][columnIndex]}
      isInActiveRow={currentActiveRow === rowIndex}
      activateRow={activateRow}
      data={
        rowData[rowIndex] && rowData[rowIndex][columnIndex] ? cellvalue : ""
      }
      style={style}
    >
      {myChildren}
    </GridCell>
  );
};

export default RenderItem;
