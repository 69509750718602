import styled from "styled-components";
import { StyledActionArea } from "globalStyles";
import Icon from "../../../Common/Components/Icon";
import { ImageComponent } from "../../../Common/Components/Image";
import EditDetails from "components/EditDetails";

export const EnhancedActionArea = styled(StyledActionArea)`
  flex-flow: row wrap;
  align-items: flex-end;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;

export const Cell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const CellIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.primaryColor};
  margin-left: 10px;
  font-size: 15px;
`;

export const DetailsContainer = styled.div`
  width: 100%;
  height: 70%;
  padding: 20px;
  display: flex;
  flex-flow: column;
`;

export const TabContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const AdvancedActionArea = styled(StyledActionArea)`
  flex-flow: row wrap;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  & > button {
    height: 35px;
    padding: 0 20px;
    width: auto;
    margin-left: 10px;
  }
`;

export const EmptyScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    height: 35px;
  }
`;

export const EmptyScreenImage = styled(ImageComponent)`
  width: 40%;
  height: 40%;
  object-fit: contain;
`;

export const EmptyScreenMessage = styled.span`
  margin: 30px;
  font-size: ${(props) => props.theme.text.title.medium};
`;

export const StyledEditDetails = styled(EditDetails)`
  position: absolute;
  right: 40px;
  top: 80px;
  width: auto;
  z-index: 1000;
`;

export const HeaderRefreshContent = styled.div`
  background-color: ${(props) => props.theme.colors.primaryColor};
  color: white;
  top: 0px;
  position: relative;
  border-radius: 4px;
  margin-right: 10px;
  padding: 7px 7px 7px 17px;
  cursor: pointer;
  align-self: flex-end;
  transition: transform linear 0.2s;
  &:active {
    transform: scale(0.9);
  }
`;

export const Text = styled.span`
  margin: 0px 10px;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
`;

export const Item = styled.div`
  padding: 10px;
  display: flex;
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: black;
`;

export const ListContainer = styled.div`
  display: flex;
  margin: 10;
`;
