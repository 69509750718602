import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataItem, DetailHead, BigImage } from "../../style/display";
import { generateTableHeading, getImageUrl } from "helper";
import ProfilePic from "components/ProfilePic";
import EditDetails from "../../../../Common/Components/EditDetails";
import DeleteDetails from "../../../../Common/Components/DeleteDetails";
import Modal from "../../../../Common/Components/Modal";
import UpdateAvatarForm from "./UpdateAvatarForm";
import {
  isCurrentUserIsExecutive,
  getCurrentUserCompany,
} from "common/Selectors/auth";
import { deleteImage } from "../../actionCreator";

export const UserAvatar = ({ heading, isTable, userName, result }) => {
  const [isModalActive, setModalActive] = useState(false);
  const [isImageModalActive, setImageModalActive] = useState(false);

  const isAdmin = useSelector(isCurrentUserIsExecutive);
  const companyId = useSelector(getCurrentUserCompany);
  const dispatch = useDispatch();

  const toggleModal = useCallback(() => {
    setModalActive((val) => !val);
  }, []);

  const onUpdateComplete = (success) => {
    if (!success) {
      return;
    }
    toggleModal();
  };

  const avatarDelete = () => {
    if (window.confirm("Are you sure you want to delete")) {
      dispatch(deleteImage({ isAdmin, companyId }));
    }
  };
  const toggleImageModal = useCallback((url = null) => {
    setImageModalActive((prevValue) => {
      return !prevValue;
    });
  }, []);
  const imageUrl = useMemo(() => getImageUrl(result.url), [result.url]);

  return (
    <>
      <DataItem isTable={isTable}>
        <DetailHead>{generateTableHeading(heading)}</DetailHead>
        <ProfilePic
          img={result.url}
          userName={userName}
          onClick={toggleImageModal}
        />
        <EditDetails text="Edit" onEdit={toggleModal} />
        <DeleteDetails text="Delete" onClick={avatarDelete} />
      </DataItem>
      <Modal active={isImageModalActive} closeModal={toggleImageModal}>
        <BigImage src={imageUrl} />
      </Modal>
      <Modal active={isModalActive} closeModal={toggleModal}>
        <UpdateAvatarForm
          initialValue={result.url}
          onComplete={onUpdateComplete}
        />
      </Modal>
    </>
  );
};
