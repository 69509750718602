import { getCurrentDataAttributes } from "helper/selectors";
import { createSelector } from "reselect";

const storeWorkReportData = (store, props) => store.notifiedWorkReports.data;
const storeWorkReportIds = (store, props) => store.notifiedWorkReports.dataIds;
const storeWorkReportsRelations = (store, props) =>
  store.notifiedWorkReports.relation;
export const isWorkReportSearchOn = (store, props) =>
  store.notifiedWorkReports.isSearchOn;

export const storeWorkReportSearchData = (store, props) =>
  store.notifiedWorkReports.searchData;

export const getAllNotifiedWorkReports = createSelector(
  storeWorkReportData,
  storeWorkReportIds,
  storeWorkReportsRelations,
  (allReports, reportIds, relations) => {
    let data = [];
    Object.keys(allReports).map((key) => {
      if (typeof allReports[key] != "function" && reportIds?.includes(key)) {
        const report = allReports[key];
        data.push(getCurrentDataAttributes(report, relations, allReports));
      }
    });
    return data;
  },
);
