import styled from "styled-components";

export const Container = styled.div`
  width: 53px;
  height: 53px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.$background};
  position: relative;
`;

export const Pic = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;
export const Initials = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.theme.text.para.small};
  color: white;
  text-transform: capitalize;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #01008a;
  position: absolute;
  left: 0px;
  top: -10px;
`;
