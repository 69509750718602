import { ILeavesAppliedProps } from "./types";

export const transformLeavesData = ({
  data,
  cellNameToColumnMap,
}: ILeavesAppliedProps) => {
  const assignedQuotaCell = cellNameToColumnMap["assignedQuota"];
  const leavePlanCell = cellNameToColumnMap["leavePlan"];
  const leaveRequisitionCountCell =
    cellNameToColumnMap["leaveRequisitionCount"];

  const leaves: {
    assignedQuota: any;
    leavePlan: any;
    leaveRequisitionCount: any;
  }[] = [];

  data.forEach((row, index) => {
    if (index === 0) {
      return;
    }

    const [assignedQuota, leavePlan, leaveRequisitionCount] = [
      row[assignedQuotaCell],
      row[leavePlanCell],
      row[leaveRequisitionCountCell],
    ];
    if (leavePlan) {
      leaves.push({
        assignedQuota,
        leavePlan,
        leaveRequisitionCount,
      });
    }
  });
  return { leaves } as const;
};
