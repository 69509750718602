import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reducerStore } from "types/index";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";

import { deleteWorkshiftDetail as deleteWorkshiftActionCreator } from "../View/Display/WorkshiftType/WorkshiftDetails/actionCreator";
import useAlert from "hooks/useAlert";

export const useDeleteWorkshiftDetail = () => {
  const [isLoading, updateLoading] = useState<boolean>(false);
  const { showAlert } = useAlert();
  const dispatch = useDispatch();
  const companyId = useSelector((store: reducerStore) =>
    getCurrentUserCompany(store, null)
  );
  const isAdmin = useSelector((store: reducerStore) =>
    isCurrentUserIsExecutive(store, null)
  );

  const deleteWorkshift = async ({
    workshiftId,
    collaborator_workshift_id,
  }: {
    workshiftId: string;
    collaborator_workshift_id: string;
  }) => {
    try {
      updateLoading(true);
      await dispatch(
        deleteWorkshiftActionCreator({
          workshiftId,
          companyId,
          isAdmin,
          collaborator_workshift_id,
        })
      );
    } catch (err) {
      showAlert({ message: "Failed to delete workshift", type: "error" });
    } finally {
      updateLoading(false);
    }
  };

  return {
    isLoading,
    deleteWorkshift,
  };
};
