import requestData from "../../ApiHandler";
import { createAuthUrl } from "helper";

export const getTodaysWorkingLogApi = ({ companyId, isAdmin }) => {
  const authUrl = createAuthUrl({
    isAdmin,
    url: `/companies/${companyId}/get_todays_working_hour_log`,
  });

  return requestData(authUrl, {
    method: "GET",
  });
};
