import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";

import InputGroup from "components/InputGroup/inputGroup2";
import { PaginatorBox } from "globalStyles";
import Paginator from "components/Paginator";
import usePagination from "hooks/usePagination";
import useForm from "hooks/useForm";

import { getQualifications } from "../../actionCreator";
import {
  getCurrentUserCompany,
  isCurrentUserIsExecutive,
} from "common/Selectors/auth";
import { EnhancedActionArea } from "../../style/display";
import { qualificationsOptions } from "../Create/options";

const ActionArea = ({ onceFetched, companyId, isAdmin, onLoading }) => {
  const { formData, values, updateField } = useForm({
    fields: {
      qualificationType: {
        required: true,
      },
    },
  });
  useEffect(() => {
    updateField("qualificationType", qualificationsOptions[0].value);
  }, []);

  const {
    loadingNextPage,
    loadingPrevPage,
    getNextPage,
    currentPageNumber,
    totalPages,
  } = usePagination({
    actionCreator: getQualifications,
    tableName: "qualifications",
  });

  const extraParams = {
    companyId,
    qualificationType: values.qualificationType,
    isAdmin,
  };

  const fetchFirstPage = useCallback(() => {
    getNextPage({
      isFirstPage: true,
      extraParams: extraParams,
    });
  }, [getNextPage, extraParams]);

  const fetchPrevPage = useCallback(() => {
    getNextPage({
      fetchPrevPage: true,
      extraParams: extraParams,
    });
  }, [getNextPage, extraParams]);

  const fetchNextPage = useCallback(() => {
    getNextPage({
      extraParams: extraParams,
    });
  }, [getNextPage, extraParams]);

  const fetchSpecificPage = useCallback(
    (pageNumber) => {
      getNextPage({
        pageNumber,
        extraParams: extraParams,
      });
    },
    [getNextPage, extraParams]
  );

  useEffect(() => {
    // fetching depends on the qualification type and oncefetched
    if (values.qualificationType || !onceFetched) {
      fetchFirstPage();
    }
  }, [values.qualificationType]);

  useEffect(() => {
    if (typeof onLoading !== "function") {
      return;
    }

    if (!onceFetched) {
      onLoading(true);
    } else {
      onLoading(false);
    }
  }, [onceFetched]);

  return (
    <EnhancedActionArea>
      <PaginatorBox>
        <Paginator
          current={currentPageNumber}
          total={totalPages}
          onNext={fetchNextPage}
          onPrev={fetchPrevPage}
          onGoToPage={fetchSpecificPage}
          nextIsLoading={loadingNextPage}
          prevIsLoading={loadingPrevPage}
        />
      </PaginatorBox>
      <InputGroup
        fieldType="select"
        options={qualificationsOptions}
        {...formData["qualificationType"]}
      />
    </EnhancedActionArea>
  );
};

const mapStateToProps = (store, props) => {
  const companyId = getCurrentUserCompany(store, props);
  const isAdmin = isCurrentUserIsExecutive(store, props);
  return {
    companyId,
    onceFetched: store.qualifications.onceFetched,
    currentQualificationType: store.qualifications.currentQualificationType,
    isAdmin,
  };
};
export default connect(mapStateToProps, null)(ActionArea);
