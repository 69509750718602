import { createSelector } from "reselect";
import {
  storeSelector,
  IuserData,
  EmptyString,
  IuserCompanies,
} from "../types";

const storeLoggedIn: storeSelector<boolean> = (store, props) =>
  store.auth.isLoggedIn;
const storeUserData: storeSelector<IuserData> = (store, props) =>
  store.auth.userData;
const storeCurrentComapny: storeSelector<EmptyString> = (store, props) =>
  store.auth.currentCompany;
const storeCompanies: storeSelector<IuserCompanies> = (store, props) =>
  store.auth.userCompanies;
const storeIsLoggedInAsAdmin: storeSelector<null | boolean> = (store, props) =>
  store.auth.isLoggedInAsAdmin;

const storeUserAllData: storeSelector<{ [key: string]: any }> = (
  store,
  props
) => store.auth.allData;

// const storeNotificationCount: storeSelector = (store, props) =>
//   store.auth.isLoggedInAsAdmin;

export const isLoggedInAsAdmin = createSelector(
  storeIsLoggedInAsAdmin,
  (val) => val
);

export const isUserLoggedIn = createSelector(
  storeLoggedIn,
  (loggedIn) => loggedIn
);

export const getCurrentCompanyUserData = createSelector(
  storeUserData,
  storeCurrentComapny,
  (userData, currCompany) => (currCompany ? userData[currCompany] : null)
);

export const getCurrentUserCompany = createSelector(
  storeCurrentComapny,
  (currCompany) => currCompany
);

export const getCurrentUserAccessType = createSelector(
  storeUserData,
  storeCurrentComapny,
  (userData, currCompany) =>
    currCompany ? userData[currCompany].attributes.collaboratorType : null
);

export const getCurrentUserData = createSelector(
  storeUserData,
  storeCurrentComapny,
  (userData, currCompany) =>
    currCompany ? userData[currCompany].attributes : null
);

export const getCurrentUserId = createSelector(
  storeUserData,
  storeCurrentComapny,
  (userData, currCompany) => (currCompany ? userData[currCompany].id : null)
);

export const getAllUserData = createSelector(
  storeUserData,
  (userData) => userData || null
);

export const isCurrentUserIsExecutive = createSelector(
  storeUserData,
  storeCurrentComapny,
  (userData, currCompany) => {
    return currCompany
      ? userData[currCompany].attributes.isExecutive
      : undefined;
  }
);

export const getCurrentUserCompaniesOptions = createSelector(
  storeCompanies,
  (companies) =>
    Object.values(companies).map((data) => ({
      name: data?.attributes?.name,
      value: data.id,
    }))
);

export const getCurrentUserNotificationCount = createSelector(
  storeUserData,
  storeCurrentComapny,
  (userData, currCompany) => {
    if (currCompany) {
      const { attributes } = userData[currCompany];
      const { notificationCounter } = attributes;
      return notificationCounter;
    }
    return 0;
  }
);

export const currentCompanyUserDataSelector = createSelector(
  getCurrentUserCompany,
  storeUserAllData,
  (currentCompanyId, allData) => {
    for (const key in allData) {
      const value = allData[key];
      const collaborators = value.activeCollaborators;
      if (collaborators && Array.isArray(collaborators)) {
        const collaboratorOfCurrentCompany = collaborators.find(
          (item) => item.company.id === currentCompanyId
        );
        return collaboratorOfCurrentCompany;
      }
    }
    return null;
  }
);

export const currentCompanyUserAttendanceLogDataSelector = createSelector(
  currentCompanyUserDataSelector,
  (userData) => {
    return userData && userData.todaysWorkingHourLog;
  }
);
